export function getCookieConsent(): string {
  if (
    localStorage.getItem('useCookie') == null  ||
    localStorage.getItem('useCookie') === undefined
  ) {
    localStorage.setItem('useCookie', 'false')
  }

  return localStorage.getItem('useCookie')!
}

export function actualizarCookieConsent() {
  localStorage.setItem('useCookie', 'true')
}
