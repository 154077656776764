import { darPaisBastidor } from '../PaisPorBastidor/sacarPaisBastidor'
import { sacarPaisTable } from './SacarPaisBastidor/SacarPaisBastidor'

export function DatosHistorialMicrodatos(
  listadatos: Array<string>,
): Array<object> {
  let datosMapeados: Array<object> = []
  if (listadatos == null  && listadatos === undefined) return []
  listadatos?.forEach((bastidor: string) => {
    if (bastidor !== '') {
      datosMapeados.push({
        Fabricacion: sacarPaisTable(bastidor),
        Bastidor: bastidor,
        PaisDeOrigen: darPaisBastidor(bastidor),
      })
    }
  })

  return datosMapeados
}
