import axiosInstance from '../../../utils/axios'
import {
  checkStatusCode,
  NotifyType,
  showToast,
} from '../../../utils/sharedUitls'

async function getAllTasas() {
  let url = `${process.env.REACT_APP_API_URL_CORE}/TasasDgt`
  try {
    const response = await axiosInstance.get(url)
    checkStatusCode(response.status)
    return response.data
  } catch (error: any) {
    console.error('Error al obtener todas las tasas:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    throw error
  }
}

async function postTasas(formData: any) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/TasasDgt`
  try {
    const response = await axiosInstance.post(url, formData)
    checkStatusCode(response.status)
    showToast('Tasas importadas con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error en postTasas:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    throw error
  }
}

async function deleteTasas(id: number) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/TasasDgt/${id}`
  try {
    const response = await axiosInstance.delete(url)
    checkStatusCode(response.status)
    showToast('Tasa eliminada con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error al eliminar la tasa:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    } else {
      showToast(
        'La tasa no está libre, no se puede eliminar',
        NotifyType.error,
        2000,
      )
    }
    throw error
  }
}

async function postImportFileTasas(file: File) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/TasasDgt/Almacen`
  let formData = new FormData()
  formData.append('file', file)
  try {
    const response = await axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response.data.RegistrosErroneos > 0) {
      showToast(
        `Se han encontrado ${response.data.RegistrosErroneos} tasas erróneas en el archivo. No se han importado`,
        NotifyType.error,
        2000,
      )
      return
    }
    return response.data
  } catch (error: any) {
    throw new Error(`Error al importar archivo de tasas`, {
      cause: error.ErrorType,
    })
  }
}

async function patchTasas(id: number) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/TasasDgt/${id}`
  try {
    const response = await axiosInstance.patch(url, { estado: 0 })
    checkStatusCode(response.status)
    showToast('Tasa liberada con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error al liberar la tasa:', error)
    // showToast('Error al liberar la tasa', NotifyType.error, 500)
  }
}

export { getAllTasas, postTasas, deleteTasas, postImportFileTasas, patchTasas }
