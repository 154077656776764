import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { CustomForm } from 'src/pages/expedientePage/components/CustomForm';
import NoData from 'src/pages/expedientePage/components/NoData';

export default function NotariaTabBienes() {
  const { notaria } = useSelector((state: RootState) => state.escrituras);
  return !!notaria.Datos.length ? <CustomForm {...notaria} /> : <NoData />
};
