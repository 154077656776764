import {
  ADD_IDOCCAR_CONFIG_ALL,
  ADD_IDOCCAR_CONFIG,
  CLEAR_IDOCCAR_CONFIG,
  IdocCarConfigActionsTypes,
  IdocCarConfigState,
} from './idocCarConfigType'

const initialState: IdocCarConfigState = {
  idocCarConfig: undefined,
  idocCarConfigAll: undefined,
}

export const idocCarConfigReducer = (
  state = initialState,
  action: IdocCarConfigActionsTypes,
): IdocCarConfigState => {
  switch (action.type) {
    case ADD_IDOCCAR_CONFIG: {
      return { ...state, idocCarConfig: action.payload }
    }
    case ADD_IDOCCAR_CONFIG_ALL: {
      return { ...state, idocCarConfigAll: action.payload }
    }
    case CLEAR_IDOCCAR_CONFIG: {
      return { ...state, idocCarConfig: action.payload }
    }
    default:
      return state
  }
}
