import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RoleModel')
class RoleModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Name', String)
  Name: string = '';
};

export { RoleModel };
