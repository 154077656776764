import { Box, Button, Form, Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup'
import React, { useEffect, useState } from 'react'
import { FirmaExpedienteModel } from '../models/firmaExpedienteModels'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import {
  AsyncRule,
  EmailRule,
  GroupItem,
  SimpleItem,
} from 'devextreme-react/form'
import { DocumentTypeGridModel } from '../DocumentType/models/documentTypeGridModel'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getDocumentType } from '../DocumentType/services/documentTypeServices'
import { Item } from 'devextreme-react/box'
import { AddSujetosByExpediente } from 'src/pages/usuariosPage/redux/sujetoActions'
// import { getSujetosByExpediente } from 'src/pages/usuariosPage/service/sujetoService'
// import { SujetoByIdExpedienteModel } from 'src/pages/usuariosPage/models/sujetoByIdExpedienteModel'
import { Telemetry } from '@microsoft/applicationinsights-web'
import { RequiredRule } from 'devextreme-react/data-grid'
import '../styles/firmaStyle.css'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { postFirma } from '../services/firmaServices'
import { validarEmailsSeparadosPorComas } from 'src/utils/validators'
import { addShowLoader } from 'src/redux/actions/configActions'
import { SelectSujeto } from 'src/pages/expedientePage/vehiculos/sections/consultaVehiculo/ConsultaVehiculo'

class Ids {
  id: number | null = null
  idvehiculo: number | null = null
}

interface Props {
  verpopup: Boolean
  closePopUp: Function
  datos: Ids
  estadoFirma: FirmaExpedienteModel
  setEstadoFirma: Function
  iddocumento?: number
  listaDocumentType: DocumentTypeGridModel[]
  setListaDocumentType: Function
  datosSujetos: SelectSujeto[]
  texto: Function,
}

export default function FirmarExpediente({
  verpopup,
  closePopUp,
  datos,
  estadoFirma,
  setEstadoFirma,
  listaDocumentType,
  setListaDocumentType,
  datosSujetos,
  texto,
}: Props) {

  const sujetosByExpedienteState = useSelector((state: RootState) => state.sujeto.sujetosbyexpediente);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const enviarFirma = useMutation(
    async function () {
      
      await postFirma(estadoFirma).catch(() => {
        dispatch(addShowLoader(false))
        showToast('No se ha podido solicitar la firma', NotifyType.error, 5000)
      })
    },
    {
      onSuccess: function () {
        setEstadoFirma(new FirmaExpedienteModel())
        queryClient.invalidateQueries('HistoricoFirmaDigitalExpediente')
        closePopUp(false)
        setListaDocumentType([])
        showToast('Firma Solicitada', NotifyType.success, 200)
      },
      onError: function () {
        dispatch(addShowLoader(false))
        showToast(
          'Error al realizar la solicitud de la firma',
          NotifyType.error,
          400,
        )
      },
    },
  )

  return (
    <div hidden={(verpopup as boolean) ? false : true}>
      <Box
        direction='row'
        width={'100%'}
        height={140}
        align='center'
        crossAlign='center'
      >
        <Item ratio={0} baseSize={1100}>
          <Form
          
            formData={estadoFirma}
            labelLocation='top'
            validationGroup='firmaValidation'
          >
            <GroupItem colCount={2}>
              <SimpleItem
                label={{ text: 'Seleccionar tipo de documento' }}
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: listaDocumentType,
                  displayExpr: 'name',
                  hint: 'Tipo De Documento',
                  valueExpr: 'id',
                  placeholder: 'Seleccionar tipo de documento',
                  value: estadoFirma.documentTypeId !== 0 ? estadoFirma.documentTypeId : null,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      setEstadoFirma({
                        ...estadoFirma,
                        documentTypeId: event.value,
                      })
                    }
                  },
                  searchEnabled: true,
                  searchMode: 'contains',
                  searchExpr: 'name',
                  minSearchLength: 0,
                }}
              >
                <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
              </SimpleItem>
              <SimpleItem
                label={{ text: 'Sujeto que realizará la firma' }}
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: datosSujetos,
                  displayExpr: 'name',
                  hint: 'Sujeto',
                  valueExpr: 'id',
                  placeholder: 'Seleccionar sujeto',
                  value:
                    estadoFirma.sujetoId !== 0 ? estadoFirma.sujetoId : null,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      let email = sujetosByExpedienteState?.find(
                        (sujeto) => sujeto.Id === event.value,
                      )?.Direccion.Email!

                      let numberphone = sujetosByExpedienteState?.find(
                        (sujeto) => sujeto.Id === event.value,
                      )?.Direccion.PhoneNumber!

                      estadoFirma.notificationInfo.email = email
                      estadoFirma.notificationInfo.phoneNumber = numberphone

                      setEstadoFirma({
                        ...estadoFirma,
                        sujetoId: event.value,
                        notificationInfo: estadoFirma.notificationInfo,
                      })
                    }
                  },
                  searchEnabled: true,
                  searchMode: 'contains',
                  searchExpr: 'name',
                  minSearchLength: 0,
                }}
              >
                <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
              </SimpleItem>
            </GroupItem>
          </Form>
        </Item>
      </Box>
      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={1100}>
          <Form formData={estadoFirma} validationGroup='firmaValidation'>
            <GroupItem
              caption={
                'Indicar el email y/o teléfono del sujeto que realizará la firma'
              }
              colCount={4}
            >
              <SimpleItem
                cssClass={'labelCheck'}
                label={{ text: 'Por Email' }}
                editorType={'dxCheckBox'}
                editorOptions={{
                  value: estadoFirma.notificationInfo.sendNotificationByEmail,
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      estadoFirma.notificationInfo.sendNotificationByEmail =
                        event.value
                      setEstadoFirma({
                        ...estadoFirma,
                        notificationInfo: estadoFirma.notificationInfo,
                      })
                    }
                  },
                }}
              ></SimpleItem>
              <SimpleItem
                label={{ text: 'Email' }}
                editorType={'dxTextBox'}
                editorOptions={{
                  disabled: !estadoFirma.notificationInfo
                    .sendNotificationByEmail
                    ? true
                    : false,
                  mode: 'text',
                  hint: 'Email Sujeto',
                  value:
                    estadoFirma.notificationInfo.email !== ''
                      ? estadoFirma.notificationInfo.email
                      : null,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      estadoFirma.notificationInfo.email = event.value
                      setEstadoFirma({
                        ...estadoFirma,
                        notificationInfo: estadoFirma.notificationInfo,
                      })
                    }
                  },
                }}
              >
                {estadoFirma.notificationInfo.sendNotificationByEmail && (
                  <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
                )}
                <EmailRule message={'Formato de correo incorrecto'}></EmailRule>
              </SimpleItem>
              <SimpleItem
                cssClass={'labelCheck'}
                label={{ text: 'Por Sms' }}
                editorType={'dxCheckBox'}
                editorOptions={{
                  value: estadoFirma.notificationInfo.sendNotificationBySms,
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      estadoFirma.notificationInfo.sendNotificationBySms =
                        event.value
                      setEstadoFirma({
                        ...estadoFirma,
                        notificationInfo: estadoFirma.notificationInfo,
                      })
                    }
                  },
                }}
              ></SimpleItem>

              <SimpleItem
                label={{ text: 'Teléfono' }}
                editorType={'dxTextBox'}
                editorOptions={{
                  disabled: !estadoFirma.notificationInfo.sendNotificationBySms
                    ? true
                    : false,
                  mode: 'text',
                  hint: 'Telefono Sujeto',
                  value:
                    estadoFirma.notificationInfo.phoneNumber !== ''
                      ? estadoFirma.notificationInfo.phoneNumber
                      : null,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      estadoFirma.notificationInfo.phoneNumber = event.value
                      setEstadoFirma({
                        ...estadoFirma,
                        notificationInfo: estadoFirma.notificationInfo,
                      })
                    }
                  },
                }}
              >
                {estadoFirma.notificationInfo.sendNotificationBySms && (
                  <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
                )}
              </SimpleItem>
            </GroupItem>
            <GroupItem
              colCount={2}
              caption={'Emails para la notificación del estado de la firma'}
            >
              <SimpleItem
                label={{ text: 'Emails Adicionales' }}
                editorType={'dxTextArea'}
                editorOptions={{
                  mode: 'text',
                  hint: 'Emails adicionales',
                  placeholder: 'Emails adicionales separados por comas',
                  value:
                    estadoFirma.additionalEmails !== ''
                      ? estadoFirma.additionalEmails
                      : null,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      estadoFirma.additionalEmails = event.value
                      setEstadoFirma({
                        ...estadoFirma,
                        additionalEmails: estadoFirma.additionalEmails,
                      })
                    }
                  },
                }}
              >
                <AsyncRule
                  message={
                    'los emails deben tener un formato correcto y separado por comas'
                  }
                  validationCallback={(e: any) => {
                    return validarEmailsSeparadosPorComas(e.value)
                  }}
                />
              </SimpleItem>
            </GroupItem>
          </Form>
        </Item>
      </Box>
      <Box direction='row' width={1299} align='end' crossAlign='end'>
        <Item ratio={0} baseSize={160}>
          <Button 
            text={'Cancelar'}
            type="normal"
            stylingMode="outlined"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              closePopUp(false)
            }}

          />
        </Item>
        <Item ratio={0} baseSize={140}>
          <Button
            text={'Solicitar'}
            type='default'
            validationGroup='firmaValidation'
            onClick={(e: any) => {
              e.event.preventDefault()
              let result = e.validationGroup.validate()
              if (result.isValid) {
                // Verifica si expedienteId es nulo
                if (!estadoFirma.expedienteId && datos.id) {
                  // Asigna el último valor conocido
                  setEstadoFirma((prevState: any) => ({
                    ...prevState,
                    expedienteId: datos.id
                  }));
                }
                if (
                  estadoFirma.notificationInfo.sendNotificationByEmail ||
                  estadoFirma.notificationInfo.sendNotificationBySms
                ) {
                  dispatch(addShowLoader(true))
                  enviarFirma.mutate()
                } else {
                  showToast(
                    'No se ha seleccionado por donde notificar al sujeto ',
                    NotifyType.error,
                    5000,
                  )
                }
              }
            }}
          />
        </Item>
      </Box>
    </div>
  )
}
