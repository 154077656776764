import { Box, Button, Form } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { AsyncRule, GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form';
import React, { ReactElement, useState } from 'react';
import { NotifyType, getParameters, showToast } from 'src/utils/sharedUitls';
import { samePassword } from 'src/utils/validators';
import { ChangePasswordCall } from '../service/authService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';

class Passwords {
  password: string = '';
  repeatPassword: string = '';
};

function ChangePassword(): ReactElement {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [passwords, setPasswords] = useState(new Passwords());

  async function changePassword() {
    try {
      //Recupera contraseña
      dispatch(addShowLoader(true));
      let Username = getParameters('user');
      let Code = getParameters('code');

      let ChangePassword = await ChangePasswordCall(
        Username!,
        passwords.password,
        Code!,
      );

      if (ChangePassword === 200) {
        dispatch(addShowLoader(false));
        navigate('/login');
      } else {
        dispatch(addShowLoader(false));
        showToast(
          'Este enlace es de un unico uso, vuelva a solicitar el cambio de contraseña',
          NotifyType.error, 5000
        );
      };
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        height={380}
      >
        <Item ratio={0} baseSize={290}>
          <img src={require('src/assets/newlogo.png')}></img>
        </Item>
      </Box>
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        height={60}
      >
        <Item ratio={0} baseSize={400}>
          <Form
            validationGroup='CustomerData'
            labelLocation='top'
            formData={passwords}
          >
            <GroupItem colCount={1}>
              <SimpleItem
                dataField={'password'}
                label={{ text: 'Contraseña' }}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'password',
                  hint: 'Contraseña',
                  onValueChanged: (e: any) => {
                    setPasswords({ ...passwords, password: e.value });
                  },
                }}
              >
                <RequiredRule
                  message={'la contraseña es obligatoria'}
                ></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={1}>
              <SimpleItem
                dataField={'repeatPassword'}
                label={{ text: 'Repetir Contraseña' }}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'password',
                  hint: 'Repetir Contraseña',
                  onValueChanged: (e: any) => {
                    setPasswords({ ...passwords, repeatPassword: e.value });
                  },
                }}
              >
                <RequiredRule
                  message={'la contraseña es obligatoria'}
                ></RequiredRule>
                <AsyncRule
                  message='Las contraseñas deben coincidir'
                  validationCallback={(e: any) => {
                    return samePassword(passwords.password, e.value);
                  }}
                />
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={1}>
              <Box
                direction='row'
                align='center'
                crossAlign='center'
                height={40}
              >
                <Item ratio={0} baseSize={190}>
                  <Button
                    text={'Enviar'}
                    type='default'
                    validationGroup={'CustomerData'}
                    useSubmitBehavior={true}
                    onClick={(e: any) => {
                      e.event.preventDefault();
                      let result = e.validationGroup.validate();

                      if (result.isValid) {
                        changePassword();
                      } else {
                        showToast(
                          'Deben de rellenarse las contraseñas y deben coincidir',
                          NotifyType.error, 5000
                        );
                      };
                    }}
                  />
                </Item>
              </Box>
            </GroupItem>
          </Form>
        </Item>
      </Box>
    </>
  );
};

export { ChangePassword };
