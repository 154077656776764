import axios from 'axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { UserModel } from 'src/models/usuario/userModel'
import { DetalleVehiculoCampoDatoModel } from 'src/pages/expedientePage/models/vehiculo/detalleVehiculoModel'
import { deleteFakeDates } from 'src/utils/dateUtils'
import {
  checkStatusCode,
  customLog,
  isNotEmpty,
  logType,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { authHeader } from '../../../loginPage/service/authService'
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel'
import { StructureExpedienteModel } from '../../models/structureExpedienteModel'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import { isAdmin, isEmployeeUser } from 'src/utils/allowAuthUtil'
import firmaDigitalAxios from 'src/utils/firmadigitalaxios'
import { StateHistorySignedDataModel } from 'src/pages/firmadigital/StateHistorySigned/models/stateHistorySignedDataModel'
import informaAxios from '../../../../utils/serviciosRuanoAxios'

const serviceName = 'ExpedienteService'
const baseURL = process.env.REACT_APP_API_URL_CORE
const serviciosRuanoURL = process.env.REACT_APP_API_SERVICIOSRUANO_URL

export const getDetalleVehiculo = async (
  vehiculoId: number,
  user: UserModel,
): Promise<DetalleVehiculoCampoDatoModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `${process.env.REACT_APP_API_URL_CORE}/Vehiculos/GetDetalle/${vehiculoId}`
    let vehiculoGrid = new DetalleVehiculoCampoDatoModel()

    await axios({
      url: url,
      method: 'GET',
      headers: authHeader(user!),
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        vehiculoGrid = jsonConvert.deserializeObject(
          response.data,
          DetalleVehiculoCampoDatoModel,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido cargar los vehiculos',
          NotifyType.error,
          5000,
        )
      })
    return vehiculoGrid
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
  return new DetalleVehiculoCampoDatoModel()
}

async function getStructure(
  user: UserModel,
): Promise<StructureExpedienteModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `${process.env.REACT_APP_API_URL_CORE}/Expedientes/GetStructure`
  let expediente: StructureExpedienteModel = new StructureExpedienteModel()

  await axios({
    url: url,
    method: methodsEndPointType.GET,
    headers: authHeader(user),
  })
    .then((response: any) => {
      checkStatusCode(response.status)
      expediente = jsonConvert.deserializeObject(
        response.data,
        StructureExpedienteModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      checkStatusCode(error.request.status)
      return {}
    })

  return expediente
}

const getExpediente = async (
  expedienteId: number,
  user: UserModel,
): Promise<DetailExpedientFieldDataModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `${process.env.REACT_APP_API_URL_CORE}/Expedientes/GetDetalle/${expedienteId}`
    let Expediente = new DetailExpedientFieldDataModel()
    await axios({
      url: url,
      method: methodsEndPointType.GET,
      headers: authHeader(user),
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        Expediente = jsonConvert.deserializeObject(
          response.data,
          DetailExpedientFieldDataModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getExpediente.name,
          methodsEndPointType.GET,
        )
        return new DetailExpedientFieldDataModel()
      })
    return Expediente
  } catch (err) {
    return new DetailExpedientFieldDataModel()
  }
}

export const loadStore = (user: UserModel): any => {
  return function (loadOptions: any) {
    let params = '?'
    ;[
      'skip',
      'take',
      'requireTotalCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
    ].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`
      }
    })
    params = params.slice(0, -1)
    let url = ''
    if (params.includes('group')) {
      url = `${process.env.REACT_APP_API_URL}/Expedientes/FilterGroup/${params}`
    } else url = `${process.env.REACT_APP_API_URL}/Expedientes/${params}`
    return fetch(url, {
      method: methodsEndPointType.GET,
      headers: authHeader(user),
    })
      .then((response) => {
        checkStatusCode(response.status)
        return response.json()
      })
      .then((data) => {
        if (!!data.Datos) {
          return {
            data: data.Datos,
            totalCount: data.totalCount,
            summary: data.summary,
          }
        }
      })
      .catch((err) => {})
  }
}

export const getCampos = async (user: UserModel): Promise<any> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `${process.env.REACT_APP_API_URL}/Expedientes/Header`
    let ExpedienteTable: StructureExpedienteModel =
      new StructureExpedienteModel()
    user.Token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjMwQHJ1YW5vLmNvbSIsIm5iZiI6MTYyNzQ2NDg2NSwiZXhwIjoxNjU5MDAwODY1LCJpYXQiOjE2Mjc0NjQ4NjV9.jkAjvFYZIXyFDY9cjq1zP2M8HAts70kEY1u9qrHrD9s'
    await axios({
      url: url,
      method: methodsEndPointType.GET,
      headers: authHeader(user),
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        ExpedienteTable = jsonConvert.deserializeObject(
          response.data,
          StructureExpedienteModel,
        )
      })
      .catch((error: any) => {})

    return ExpedienteTable
  } catch (error) {
    return undefined
  }
}

export const getExpedienteFile = async (
  id: number,
  fileName: string,
  user: UserModel,
): Promise<any> => {
  try {
    let url = `${process.env.REACT_APP_API_URL_CORE}/documento/file/${id}`
    axios({
      url: url,
      method: methodsEndPointType.GET,
      headers: authHeader(user),
      responseType: 'blob', // important
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error: any) => {
        showToast(
          'El archivo no existe o ya no está disponible',
          NotifyType.error,
          5000,
        )
      })
  } catch (err) {}
}

let isLoading = false

// Método para obtener datos de vehículos con paginación
export const getVehiculos = async (
  user: UserModel,
  loadOptions: any,
  options: string[],
) => {
  // Objeto que almacenará los datos de vehículos
  let vehiculos: any = {}

  // URL de la API para obtener expedientes de vehículos
  const url = `${baseURL}/Expedientes/GetExpedientes`

  try {
    // Parámetros iniciales de la URL
    let params = '?'

    // Construye los parámetros de la URL a partir de loadOptions
    options.forEach((key) => {
      if (key in loadOptions && isNotEmpty(loadOptions[key])) {
        params += `${key}=${JSON.stringify(loadOptions[key])}&`
      }
    })

    // Elimina el último "&" de los parámetros
    params = params.slice(0, -1)

    // Verifica si hay un filtro en loadOptions
    if (loadOptions.filter !== undefined) {
      // Realiza la primera llamada a la API con el filtro
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          isLoading = false // ¿Esto debería ser manejado de otra manera?
          return data
        })
        .catch((error) => console.log('error', error))

      // Estructura los datos obtenidos
      vehiculos = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }

      // Retorna los datos de vehículos
      return vehiculos
    } else {
      // Si no hay filtro, realiza la llamada a la API sin filtro
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data
        })
        .catch((error) => console.log('error', error))

      // Estructura los datos obtenidos
      vehiculos = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }

      // Retorna los datos de vehículos
      return vehiculos
    }
  } catch (e) {
    // Manejo de errores, si es necesario
  }

  // Retorna los datos de vehículos (incluso si hubo un error)
  return vehiculos
}

export async function documentoFirmadoById(
  user: UserModel,
  id: number,
): Promise<Promise<Array<StateHistorySignedDataModel> | []>> {
  let documentoFirmado: Array<StateHistorySignedDataModel> | [] = []

  if (
    (IsModuloAllowed(user, Modules.FIRMADIGITAL) &&
      id  != null  &&
      isAdmin(user)) ||
    isEmployeeUser(user)
  ) {
    await firmaDigitalAxios
      .get(`SignRequests/Expediente/${id}`)
      .then((response: any) => {
        documentoFirmado = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los datos del histórico de la firma.' +
            error,
          NotifyType.error,
          5000,
        )
      })
  }

  return documentoFirmado
}

async function getByBastidor(user: UserModel, bastidor: string) {
  let vehiculoData: any = {}
  if (
    (IsModuloAllowed(user, Modules.MICRODATOS) &&
      bastidor  != null  &&
      isAdmin(user)) ||
    isEmployeeUser(user)
  ) {
    await informaAxios
      .get(
        `${serviciosRuanoURL}/eDgtVehiculos/GetByBastidor?bastidor=${bastidor.trim()}`,
      )
      .then((response: any) => {
        vehiculoData = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los datos del vehículo.' + error,
          NotifyType.error,
          5000,
        )
      })
  }

  return vehiculoData
}

export { getStructure, getExpediente, getByBastidor }
