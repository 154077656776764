import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { checkStatusCode, methodsEndPointType } from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { authHeader } from 'src/services/sharedServices'
import { FieldDataModelComponent } from '../models/modelComponent/fieldDataModelComponent'

async function _getStructure(
  user: UserModel,
): Promise<FieldDataModelComponent> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Componente/Structure`

  let component: FieldDataModelComponent = new FieldDataModelComponent()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      component = jsonConvert.deserializeObject(
        response.data,
        FieldDataModelComponent,
      )
    })
    .catch((error: any) => {
      return {};
    });

  return component;
};

export { _getStructure }
