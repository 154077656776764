import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilSetup } from './recoil/recoilSetup'
import themes from 'devextreme/ui/themes'
import { loadMessages, locale } from 'devextreme/localization'
import enMessages from 'devextreme/localization/messages/en.json'
import esMessages from 'devextreme/localization/messages/es.json'
import { App } from './app'
import { unregister } from './serviceWorker'
import 'devextreme/dist/js/dx.all.js'
import CacheBuster from 'react-cache-buster'
import version from '../package.json'

let currentLanguage = window.localStorage.getItem('language') || 'es-ES'
loadMessages(esMessages)
loadMessages(enMessages)
locale(currentLanguage)

// Configuración de temas
// Inicialización de temas
themes.initialized(() => {
  unregister() // Desregistrarse antes de volver a renderizar
  let currentTheme = window.localStorage.getItem('dx-theme') || 'generic.light'
  themes.current(currentTheme)

  const root = createRoot(document.getElementById('root')!)
  root.render(
    <CacheBuster
      currentVersion={version.version}
      isEnabled={true}
      isVerboseMode={false}
      metaFileDirectory={'.'}
    >
      <RecoilSetup>
        <App />
      </RecoilSetup>
    </CacheBuster>
  )
})
