import { DossierStatusElGestor } from '../models/idocCarEstadosExpedientesModel'

export interface IdocCarDossierStatusElGestorState {
  idocCarDossierStatusElGestor:
    | Partial<DossierStatusElGestor>
    | DossierStatusElGestor
    | undefined
  idocCarDossierStatusElGestorAll: DossierStatusElGestor[] | undefined
}

export const ADD_IDOCCAR_DOSSIER_STATUS_ALL = 'ADD_IDOCCAR_DOSSIER_STATUS_ALL'
export const ADD_IDOCCAR_DOSSIER_STATUS = 'ADD_IDOCCAR_DOSSIER_STATUS'
export const CLEAR_IDOCCAR_DOSSIER_STATUS = 'CLEAR_IDOCCAR_DOSSIER_STATUS'

interface AddIdocCarDossierStatusElGestorRequest {
  type: typeof ADD_IDOCCAR_DOSSIER_STATUS
  payload: Partial<DossierStatusElGestor> | DossierStatusElGestor | undefined
}
interface AddIdocCarDossierStatusElGestorRequestAll {
  type: typeof ADD_IDOCCAR_DOSSIER_STATUS_ALL
  payload: any | undefined
}
// interface ClearIdocCarDossierStatusElGestorRequest {
//   type: typeof CLEAR_IDOCCAR_DOSSIER_STATUS
//   payload: DossierStatusElGestor | undefined
// }

export type IdocCarDossierStatusElGestorActionsTypes =
  // | ClearIdocCarDossierStatusElGestorRequest
  | AddIdocCarDossierStatusElGestorRequest
  | AddIdocCarDossierStatusElGestorRequestAll
