import { Box, Item } from 'devextreme-react/box';
import React, { useEffect, useState } from 'react';
import TablaDocumentosSection from '../sections/DocumentTypeSections/TableDocumentTypeSection';
import { addShowLoader } from 'src/redux/actions/configActions';
import { useDispatch } from 'react-redux';
import autoTable from 'jspdf-autotable';

export default function DocumentTypePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addShowLoader(true));
    return () => {
    };
  }, [dispatch]);

  
  return (
    <>
    <div id='container-body'>
      <Box direction='col' width={'100%'} align='start' crossAlign='start'>
        <Item ratio={0} baseSize={'auto'}>
          <h2>Tipos de documento</h2>
        </Item>
      </Box>
      <Box
        direction='row'
        width={'100%'}
        height={'auto'}
        align='center'
        crossAlign='center'
      >
        <Item ratio={0} baseSize={autoTable}>
          <TablaDocumentosSection />
        </Item>
      </Box>
    </div>
    </ >
  );
}
