import { LinkModel } from 'src/models/company/linkModel'

import {
  ADD_LINKS,
  ADD_PARENT_LINKS,
  LinkActionsTypes,
} from './IlinkType'

const addParentLinks = (
  parentEnlaces: Partial<LinkModel[]> | LinkModel[] | undefined,
): LinkActionsTypes => ({
  type: ADD_PARENT_LINKS,
  payload: parentEnlaces,
});

const addLinks = (
  links: Partial<LinkModel[]> | LinkModel[] | undefined,
): LinkActionsTypes => ({
  type: ADD_LINKS,
  payload: links,
});

export { addParentLinks, addLinks }