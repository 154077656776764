import axios, { AxiosError } from 'axios'
import { logout } from './sharedUitls'

// ----------------------------------------------------------------------
export interface AxiosInstanceError {
  error: {
    code: number | null
    data: any
    details: any
    message: string
    valdiationErrors: any
  }
}
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_CORE || '',
})

export function setTokenAuth(token: string) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AxiosInstanceError>) => {
    if (error.response?.status === 401) {
      logout()
    }

    const responseError =
      (error.response && error.response?.data) ?? 'Something went wrong'
    return Promise.reject(responseError)
  },
)

export default axiosInstance
