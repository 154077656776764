import { atom } from 'recoil';
import { CompaniesStorageSizeModel } from '../models/CompaniesStorageSizeModel';

const companyStorageListState = atom<CompaniesStorageSizeModel[]>({
  key: 'companyStorageListState',
  default: [],
});

const companyStorageState = atom<CompaniesStorageSizeModel | null>({
  key: 'companyStorageState',
  default: null,
});

export { 
  companyStorageListState, 
  companyStorageState 
};
