import React, { ReactElement, useEffect } from 'react'
import Form, { Item, Label, SimpleItem } from 'devextreme-react/form'
import { ColumnModelCore } from '../../models/columnModel'
import {
  getColumnAlignment,
  getColumnType,
  getDxType,
} from '../../utils/sharedUitls'
import './styles/itemComponent.css'
import { Box } from 'devextreme-react'
import { Item as ItemBox } from 'devextreme-react/box'
import { GroupItem } from 'devextreme-react/form'

export default function GenericFormComponent(data: any): ReactElement {
  // let draw = true
  // let startDraw = data.range.start == null  ? true : false
  function checkType(field: any) {
    if (field.Nombre === 'NumeroExpediente') {
      return 'dxTextBox'
    }
    if (field.Nombre === 'Descripcion' || field.Nombre === 'Comentario') {
      return 'dxTextArea'
    }
    if (field.Tipo === 'boolean') {
      return 'dxCheckBox'
    } else {
      return getDxType(field?.Type)
    }
  }
  var optionPercentage = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  var formatterPercentage = new Intl.NumberFormat('es-ES', optionPercentage)
  var optionCurrency = {
    style: 'currency',
    currency: 'EUR',
  }
  var formatterCurrency = new Intl.NumberFormat('de-DE', optionCurrency)

  // const getJsonTransformFields = (fields: ColumnModelCore[] | undefined): any => {
  //   if (!fields) {
  //     return null
  //   }
  //   let jsonBody: any = {}
  //   fields.map((field) => {
  //     const key = field.Nombre
  //     jsonBody[key] = field.Value
  //   })

  //   return jsonBody
  // }

  return (
    <>
      <Box direction='row' width={'auto'} height={'auto'}>
        <ItemBox ratio={0} baseSize={'100%'}>
          <Form
            //onContentReady={this.validateForm}
            id='form'            
            labelLocation='top'
            colCount={'auto'}
          >
            {data.fields.Campos  != null 
              ? data.fields.Campos?.map((field: ColumnModelCore) => {
                  var value = data.fields.Datos[0][field.Nombre]
                  // if (field.Nombre === data.range.start) startDraw = true
                  // if (draw && startDraw) {
                  //   if (field.Nombre === data.range.end) {
                  //     draw = false
                  //   }

                  return (
                    <SimpleItem
                      label={{ text: field.Texto }}
                      key={field.Nombre}
                      //dataField={field.Nombre}
                      //cssClass={'align' + getColumnAlignment(field.Tipo)}
                      colSpan={
                        field.Size === 50
                          ? 'auto'
                          : field.Size === 30
                          ? 'auto'
                          : field.Size
                      }
                      visible={
                        field.Tipo === 'date' && field.Nombre == null 
                          ? false
                          : field.Visible
                      }
                      editorOptions={{
                        readOnly: true,
                        format: getColumnType(field.Tipo, field.Format),
                        fontWeight: 'blod',
                        color: 'black',
                        value:
                          value  != null 
                            ? field.Format === 'currency'
                              ? formatterCurrency.format(value)
                              : field.Format === 'percent'
                              ? formatterPercentage.format(value / 100)
                              : field.Tipo === 'datetime'
                              ? value.toString() === new Date(0).toString()
                                ? ''
                                : value.toLocaleDateString('es-ES', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })
                              : value
                            : '',
                        hint: field.Texto + ': ' + value,
                      }}
                      editorType={checkType(field)}
                    ></SimpleItem>
                  )
                  //}
                })
              : null}
          </Form>
        </ItemBox>
      </Box>
      {/* <Form
        //onContentReady={this.validateForm}
        colCount={'auto'}
        id="form"
        formData={getJsonTransformFields(data.fields)}
        labelLocation="top"
      >
        {data.fields  != null 
          ? data.fields?.map((field: FieldModel) => {
              if (field.Nombre === data.range.start) startDraw = true
              if (draw && startDraw) {
                if (field.Nombre === data.range.end) {
                  draw = false
                }

                return (
                  <Item
                    dataField={field.Nombre}
                    colSpan={getSize(field.Nombre)}
                    visible={
                      field.Type === 'date' && field.Nombre == null 
                        ? false
                        : field.Visible
                    }
                    editorOptions={{
                      readOnly: true,
                      format: getColumnType(field.Type, field.Format),
                      fontWeight: 'bold',
                      color: 'black',
                      hint: field.Value,
                    }}
                    editorType={checkType(field)}
                    itemType
                  >
                    <Label
                      text={field.Caption  != null  ? field.Caption : field.Nombre}
                    />
                  </Item>
                )
              }
            })
          : null}
      </Form> */}
    </>
  )
}
