import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('VehiculoModels')
export class VehicleModel {
    @JsonProperty('VIN', String, '')
    VIN: string = ''
    @JsonProperty('VehicleType', String, '')
    VehicleType: string = ''
    @JsonProperty('LicensePlate', String, '')
    LicensePlate: string = ''
    @JsonProperty('Brand', String, '')
    Brand: string = ''
    @JsonProperty('Model', String, '')
    Model: string = ''

}