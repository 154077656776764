import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DocumentModel')
export class DocumentModel{

    @JsonProperty('DocumentType', String)
    DocumentType:string=''
    @JsonProperty('Content', String)
    Content:string=''
    @JsonProperty('Format', String)
    Format:string=''
}