import {
  CosteCampoDatoModel,
  DetalleCosteCampoDatoModel,
} from '../../models/costeModel'
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel'
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel'
import { StructureExpedienteModel } from '../../models/structureExpedienteModel'
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel'
import { DetalleVehiculoCampoDatoModel } from '../../models/vehiculo/detalleVehiculoModel'

export interface ExpVehiculoState {
  costes: CosteCampoDatoModel
  detalleCoste: DetalleCosteCampoDatoModel
  detalleExpediente: DetailExpedientFieldDataModel
  detalleVehiculo: DetalleVehiculoCampoDatoModel
  documentos: DocumentoCampoDatoModel
  structure: StructureExpedienteModel
  tareasPendientes: TareaPendienteCampoDatoModel
}

export const ADD_COSTES = '@vehiculo/ADD_COSTES'
export const ADD_DETALLE_COSTE = '@vehiculo/ADD_DETALLE_COSTE'
export const ADD_DETALLE_EXPEDIENTE = '@vehiculo/ADD_DETALLE_EXPEDIENTE'
export const ADD_DETALLE_VEHICULO = '@vehiculo/ADD_VEHICULO'
export const ADD_DOCUMENTOS = '@vehiculo/ADD_DOCUMENTOS'
export const ADD_STRUCTURE = '@vehiculo/ADD_STRUCTURE'
export const ADD_TAREAS_PENDIENTES = '@vehiculo/ADD_TAREAS_PENDIENTES'

interface AddStructureRequest {
  type: typeof ADD_STRUCTURE
  payload: StructureExpedienteModel
}

interface AddDetalleExpedienteRequest {
  type: typeof ADD_DETALLE_EXPEDIENTE
  payload: DetailExpedientFieldDataModel
}

interface AddDetalleCosteRequest {
  type: typeof ADD_DETALLE_COSTE
  payload: DetalleCosteCampoDatoModel
}

interface AddDetalleVehiculoRequest {
  type: typeof ADD_DETALLE_VEHICULO
  payload: DetalleVehiculoCampoDatoModel
}

interface AddCostesRequest {
  type: typeof ADD_COSTES
  payload: CosteCampoDatoModel
}

interface AddTareasPendientesRequest {
  type: typeof ADD_TAREAS_PENDIENTES
  payload: TareaPendienteCampoDatoModel
}

interface AddDocumentosRequest {
  type: typeof ADD_DOCUMENTOS
  payload: DocumentoCampoDatoModel
}

export type ExpVehiculoActionsTypes =
  | AddDetalleCosteRequest
  | AddCostesRequest
  | AddDocumentosRequest
  | AddDetalleExpedienteRequest
  | AddStructureRequest
  | AddTareasPendientesRequest
  | AddDetalleVehiculoRequest
