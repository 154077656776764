import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../../../../components/notFound/notFound';
import { isOpenPopup } from '../../../../redux/actions/sharedUtilsActions';
import { RootState } from '../../../../redux/combineReducers';
import { PerfilesCampoDatoModel } from '../../../../models/perfilPage/perfilCampoDatoModel';
import { _getStructure } from '../service/profileService';
import { ProfileFormPage } from './ProfileFormPage';
import { addShowLoader } from 'src/redux/actions/configActions';
import { ProfileListPage } from './ProfileListPage';

function ProfilePage(): ReactElement {
  const dispatch = useDispatch();
  const [structure, setStructure] = useState(new PerfilesCampoDatoModel());
  const userState = useSelector((state: RootState) => state.user);
  const [showForm, setShowForm] = useState(false);
  const [rowSelected, setRow] = useState(0);
  const popupPerfilsRef = useRef(null);
  const [inicioGrid, setInicioGrid] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setStructure(await _getStructure(userState!.user!));
        dispatch(addShowLoader(false));
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
    setInicioGrid(!inicioGrid);
  }, [])

  function actualizarEstado() {
    setInicioGrid(!inicioGrid);
  }
  return (
    <>
      {/* {isAllowed(PermisoType.AccesoAdministracion, userState!.user) ? ( */}
      {true ? (
        <div
          id='container-body'
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.16%',
          }}
        >
          <h3 style={{ margin: 0, padding: 0 }}>Perfiles</h3>
          {structure?.Campos !== undefined && structure?.Campos.length > 0 ? (
            <ScrollView direction='vertical'>
              <div style={{ height: '75vh' }}>
                <ProfileListPage
                  verpopup={setShowForm}
                  estadoGrid={inicioGrid}
                  popupref={popupPerfilsRef}
                  showform={showForm}
                ></ProfileListPage>
              </div>
            </ScrollView>
          ) : null}
          <Popup
            visible={showForm}
            ref={popupPerfilsRef}
            onHiding={({}) => {
              setShowForm(!showForm);
              dispatch(isOpenPopup(false, popupPerfilsRef))
            }}
            dragEnabled={true}
            closeOnOutsideClick={false}
            showTitle={true}
            title='Formulario perfil'
            width={'60%'}
            height={'50%'}
          >
            <ProfileFormPage
              row={rowSelected}
              setShowForm={setShowForm}
              showForm={showForm}
              actualizarestado={actualizarEstado}
            ></ProfileFormPage>
          </Popup>
        </div>
      ) : (
        <div>
          <NotFound data={{ type: 1 }}></NotFound>
        </div>
      )}
      {}
    </>
  );
};

export { ProfilePage };
