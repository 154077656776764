import React, { useCallback, useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { companyStorageListState } from '../atoms/CompaniesStorageSizeAtoms'
import { CompaniesStorageSizeModel } from '../models/CompaniesStorageSizeModel'
import { _getCompaniesSizes } from '../services/CompaniesStorageSizeServices'
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid'
import 'devextreme/dist/css/dx.common.css'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import {
  ClaimsModule,
  isRolAllowed,
  isSuperAdmin,
} from 'src/utils/allowAuthUtil'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { OnExporting } from '../../../utils/export/ExportExcel'
import { checkScreenSize } from 'src/utils/sharedUitls'
import { addShowLoader } from 'src/redux/actions/configActions'

const CompaniesStorageSize = () => {
  const setCompanyStorageList = useSetRecoilState(companyStorageListState)
  const { user } = useSelector((state: RootState) => state.user!)

  const storageList: CompaniesStorageSizeModel[] = useRecoilValue(
    companyStorageListState,
  ) || [new CompaniesStorageSizeModel()]
  //const loading = useRecoilValue(loadingStateAtom);
  const [dataLoading, setDataLoading] = useState(false)
  const dispatch = useDispatch()

  // Lógica para determinar si el usuario es isAdmin o isSuperAdmin
  const isAdmin =
    IsModuloAllowed(user!, Modules.ADMINISTRACION) &&
    isRolAllowed(user!, Modules.ADMINISTRACION, [ClaimsModule.VIEW]) &&
    !isSuperAdmin(user!)

  // Método asincrónico para obtener datos de compañías o tamaño de compañía según el rol del usuario
  const fetchData = useCallback(async () => {
    try {
      setCompanyStorageList([])
      dispatch(addShowLoader(true))
      const sizes = await _getCompaniesSizes()
      if (sizes) {
        setCompanyStorageList(sizes)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      dispatch(addShowLoader(false))
    }
  }, [_getCompaniesSizes, setCompanyStorageList])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div style={{ position: 'relative' }}>
      <h2>Compañías y almacenamiento de Expedientes</h2>

      <DataGrid
        dataSource={storageList}
        showBorders={true}
        style={{
          width: '100%',
          minHeight: '250px',
        }}
        height='calc(100vh - 8rem - 46px)'
        allowColumnReordering
        allowColumnResizing
        export={{
          allowExportSelectedData: true,
          enabled: !isAdmin,
        }}
        searchPanel={{
          visible: !isAdmin,
          width: checkScreenSize() ? 240 : 120,
        }}
        columnResizingMode={'widget'}
        columnAutoWidth
        remoteOperations
        rowAlternationEnabled
        showRowLines
        loadPanel={{ enabled: dataLoading, text: 'Cargando...' }}
        onExporting={OnExporting}
      >
        <Column
          dataField='Nombre'
          caption='Compañía'
          alignment='center'
          cellRender={(data) => (
            <div style={{ textAlign: 'center' }}>{data.data.Nombre}</div>
          )}
        />
        <Column
          dataField='NumeroExpedientes'
          caption='Cantidad de Expedientes'
          alignment='center'
          cellRender={(data) => (
            <div style={{ textAlign: 'center' }}>
              {data.data.NumeroExpedientes}
            </div>
          )}
        />
        <Column
          dataField='AlmacenamientoArchivos'
          caption='Almacenamiento (GB)'
          alignment='center'
          cellRender={(data) => (
            <div style={{ textAlign: 'center' }}>
              {data.data.AlmacenamientoArchivos  != null 
                ? data.data.AlmacenamientoArchivos === 0
                  ? 'Vacío'
                  : (
                      data.data.AlmacenamientoArchivos /
                      (1024 * 1024 * 1024)
                    ).toFixed(2) + ' GB'
                : ''}
            </div>
          )}
        />
        {isSuperAdmin(user!) ? <FilterRow visible={true} /> : null}
      </DataGrid>
    </div>
  )
}

export { CompaniesStorageSize }
