import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
class ModuleModel {
  @JsonProperty('Id', Number, 0)
  Id: number = 0;

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('Descripcion', String)
  Descripcion: string = '';
}

export { ModuleModel };