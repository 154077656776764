import { CalculatorTransferModel } from '../../../../../models/company/calculatorTransferModel';

export interface CalculatorTransfersState {
  calculatorTransfers:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined;
}

export const ADD_CALCULATOR_TRANSFERS = 'ADD_CALCULADORA_TRANSFERENCIAS';

export const CLEAR_CALCULATOR_TRANSFERS =
  '@todo/CLEAR_CALCULADORA_TRANSFERENCIAS';

interface AddCalculatorTransfersRequest {
  type: typeof ADD_CALCULATOR_TRANSFERS;
  payload: Partial<CalculatorTransferModel> | undefined;
}
interface ClearCalculatorTransfersRequest {
  type: typeof CLEAR_CALCULATOR_TRANSFERS;
  payload: CalculatorTransferModel | undefined;
}

export type CalculatorTransfersActionsTypes =
  | AddCalculatorTransfersRequest
  | ClearCalculatorTransfersRequest;

