import informaAxios from 'src/utils/serviciosRuanoAxios'

const baseURL = process.env.REACT_APP_API_SERVICIOSRUANO_URL

// Método para enviar el bastidor a la API
export const sendBastidorService = async (
  bastidor: string | null,
  token: any,
) => {
  if (bastidor == null ) {
    throw new Error('Bastidor es null')
  }

  try {
    // Realiza la llamada a la API con el bastidor
    const request = `${baseURL}/eDgtVehiculos/GetByBastidor?bastidor=${bastidor.trim()}`
    const response = await informaAxios.get(request)

    if (response.data == null ) {
      throw new Error('Response data is null')
    }

    return response.data
  } catch (error) {
    throw error
  }
}
