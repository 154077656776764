import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('NotarioModel')
class NotarioModel {
  @JsonProperty('FechaFirma', DateConverter)
  FechaFirma: Date = new Date()

  @JsonProperty('FechaRecogidaNotaria', String)
  FechaRecogidaNotaria: Date | String = ''

  // @JsonProperty('FechaOtorgamientoNotaria', DateConverter)
  // FechaOtorgamientoNotaria: Date = new Date()

  @JsonProperty('Notario', String)
  Notario: string = ''

  @JsonProperty('CodigoNotario', Number)
  CodigoNotario: number = 0

  @JsonProperty('Protocolo', String)
  Protocolo: string = ''

  @JsonProperty('Notas', String)
  Notas: string = ''
}

@JsonObject('NotariaCampoDatoModel')
export class NotariaCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [NotarioModel])
  Datos: NotarioModel[] = []
}
