import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import axiosInstance from 'src/utils/axios'
import {
  checkStatusCode,
  methodsEndPointType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import axios from 'axios'

async function getCompanyDocumentation(companyId: string) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/company/${companyId}`
  let response = null
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function postCompanyDocument(
  documentoFile: File,
  companyId: string,
  tipoDocumentacion: number | undefined,
) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/company/${companyId}`
  let response = null
  let documento = {
    RgpdTipoDocumentacionId: tipoDocumentacion,
    Ruta: documentoFile.name.replace(/[^a-zA-Z0-9]+(?=\.)|[^a-zA-Z0-9.]+/g, ''),
    CompanyId: companyId,
  }
  try {
    response = await axiosInstance.post(url, documento)

    if (response.status === 200 && documentoFile) {
      uploadCompanyDocument(documentoFile, companyId)
    }
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }

  return response
}

async function uploadCompanyDocument(documentoFile: File, companyId: string) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/Upload`
  let response = null
  try {
    let filereader = new FileReader()
    let datosdocumentacion = {
      CompanyId: companyId,
      DocumentType: '',
      Format: '',
      Content: '',
    }
    filereader.readAsDataURL(documentoFile)
    filereader.onload = async function () {
      let pasara64 = []
      let base64: any = filereader.result ?? []
      pasara64 = base64.split(',')
      datosdocumentacion.Content = pasara64[1]
      let ubicacionextension = documentoFile.name.lastIndexOf('.')
      datosdocumentacion.Format = documentoFile.name.substring(
        ubicacionextension + 1,
        documentoFile.name.length,
      )
      datosdocumentacion.DocumentType = documentoFile.name.replace(
        /[^a-zA-Z0-9]+(?=\.)|[^a-zA-Z0-9.]+/g,
        '',
      )
      await axiosInstance.post(url, datosdocumentacion)
    }
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }

  return response
}

async function getTiposDocumentacion() {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/TipoDocumentacion/`
  let response = null
  try {
    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function getDocumentacionEmpresaFileLink(
  id: number,
  fileName: string,
  token: string,
) {
  try {
    let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/file/${id}`
    axios({
      url: url,
      method: methodsEndPointType.GET,
      headers: { Authorization: 'Bearer ' + token },
      responseType: 'blob', // important
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error: any) => {
        showToast(
          'El archivo no existe o ya no está disponible',
          NotifyType.error,
          5000,
        )
      })
  } catch (err) {}
}

async function getDocumentacionEmpresaFile(
  id: number,
  token: string,
  dataFile: any,
): Promise<File | undefined> {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/file/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })

    if (response.status === 200) {
      const blob = new Blob([response.data])
      const file = new File([blob], dataFile.Ruta)
      return file
    } else {
      console.error(
        'Error al obtener el archivo. Código de estado:',
        response.status,
      )
      return undefined
    }
  } catch (error) {
    console.error('Error al obtener el archivo:', error)
    return undefined
  }
}

async function getAllCompanyDocumentation() {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/`
  let response = null
  try {
    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {
    console.error('Error al obtener documetacionEmpresa:', error)
  }
  return response
}

async function deleteCompanyDocument(id: number, idCompany: string) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/company/${idCompany}/${id}`
  let response = null
  try {
    response = await axiosInstance.delete(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {}
  return response
}

export {
  getCompanyDocumentation,
  uploadCompanyDocument,
  postCompanyDocument,
  getTiposDocumentacion,
  getDocumentacionEmpresaFile,
  getDocumentacionEmpresaFileLink,
  getAllCompanyDocumentation,
  deleteCompanyDocument,
}
