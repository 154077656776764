import React from 'react'
import { DataGrid, ScrollView } from 'devextreme-react'
import { Column, MasterDetail, StateStoring } from 'devextreme-react/data-grid'
import { RemesasDetail } from './sections/RemesasDetail'
import { getAllRemesas } from '../../tasas/service/RemesasService'
import { useQuery } from 'react-query'
import { RootState } from '../../../redux/combineReducers'
import { useSelector } from 'react-redux'
import { checkScreenSize } from '../../../utils/sharedUitls'

export default function RemesasInformes() {
  const userState = useSelector((state: RootState) => state.user)
  const { data: RemesasInformes } = useQuery({
    queryKey: ['RemesasInformes'],
    queryFn: () => getAllRemesas(),
    refetchOnMount: true,
  })
  return (
    <div>
      <ScrollView>
        <div
          style={{
            minHeight: '900px',
            overflow: 'auto',
            paddingBottom: '50px',
            margin: '0 20px',
          }}
        >
          <h2>Remesas de solicitudes de informes</h2>
          <DataGrid
            id='gridRemesasInformes'
            dataSource={RemesasInformes}
            showBorders={true}
            allowColumnResizing={true}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            repaintChangesOnly={true}
            style={{margin: '2vh', height: '800px' }}
          >
            <StateStoring
              enabled
              type='localStorage'
              storageKey='gridRemesasInformes'
            />
            <Column alignment='left' dataField='Id' caption='Nº Remesa' />
            <Column
              alignment='left'
              dataField='UsuarioId'
              caption='Usuario'
              cellRender={(params) => {
                return userState?.user?.UserId === params.value
                  ? userState?.user?.UserName
                  : params.value
              }}
            />
            <Column
              alignment='left'
              dataField='FechaCreacion'
              caption='Fecha Remesa'
              cellRender={(params) => {
                return new Date(params.value).toLocaleDateString()
              }}
            />
            <MasterDetail enabled component={RemesasDetail} />
          </DataGrid>
        </div>
      </ScrollView>
    </div>
  )
}
