import { Box, Button } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React from 'react'

interface Props {
  actualizar: Function
}

export default function FooterSaldoSection({ actualizar }: Props) {
  return (
    <Box
      direction='row'
      width='100%'
      height={60}
      align='start'
      crossAlign='start'
    >
      <Item ratio={0} baseSize='21%'>
        <Button
          text={'Asignar Saldo'}
          type='default'
          validationGroup={'customerData'}
          //useSubmitBehavior={true}
          onClick={(e: any) => {
            e.event.preventDefault()
            let result = e.validationGroup.validate()
            if (result.isValid) {
              actualizar()
            }
          }}
        />
      </Item>
    </Box>
  )
}
