import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';
import { ModelComponent } from './modelComponent';

@JsonObject('')
class FieldDataModelComponent {
  @JsonProperty('Campos', [ColumnModelCore] || [])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [ModelComponent] || [])
  Datos: ModelComponent[] = [];
}

export { FieldDataModelComponent };