import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SujetoModel')
export class SujetoModel {
  @JsonProperty('Id', Number, 0)
  Id: number = 0
  @JsonProperty('NIF', String)
  NIF: string = ''
  @JsonProperty('Nombre', String)
  Nombre: string = ''
  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0
}
