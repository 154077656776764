import React, {
  MutableRefObject,
  useRef,
} from 'react'
import DatosPersonalesSection from './DatosPersonalesCompradorSection'
import DatosDireccionCompradorSection from './DatosDireccionCompradorSection'
import DatosCompradorRepresentanteSection from './DatosCompradorRepresentanteSection'
import { Box, Form } from 'devextreme-react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { GroupItem} from 'devextreme-react/form'
import FooterFormSection from '../FooterForm/FooterFormSection'
import { Item } from 'devextreme-react/box'

interface Props {
  sexo: Array<Object>
  validos: Function
  formDatosComprador: MutableRefObject<any>
}

function DatosCompradorSection({ sexo, validos, formDatosComprador }: Props) {
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  const paginas = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.paginas,
  )
  const representante = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.compradorrepresentante,
  )
  const tipovia = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.tipovias,
  )
  const ubicaciontab = useRef('')
  return (
    <form>
      <Form
        ref={formDatosComprador}
        id='formComprador'
        formData={solicitud}
        readOnly={false}
        showColonAfterLabel={true}
        showValidationSummary={true}
        validationGroup='customerData1'
      >
        {DatosPersonalesSection({
          sexo: sexo,
          ubicaciontab: ubicaciontab,
          formref: formDatosComprador,
        })}

        {DatosDireccionCompradorSection({
          ubicaciontab: ubicaciontab,
          formref: formDatosComprador,
        })}

        {representante &&
          DatosCompradorRepresentanteSection({
            solicitud: solicitud!,
            sexo: sexo,
            tipovia: tipovia!,
            ubicaciontab: ubicaciontab,
            formref: formDatosComprador,
          })}

        <GroupItem>
          {FooterFormSection({ validos: validos, validacion: 'customerData1' })}
        </GroupItem>
      </Form>
      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'auto'}>
          <p className='alinearinputs'>
            Página {pagina} de {paginas}{' '}
          </p>
        </Item>
      </Box>
    </form>
  )
}

export default DatosCompradorSection
