import React, { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';

interface RecoilSetupProps {
  children: ReactNode;
}

const RecoilSetup: React.FC<RecoilSetupProps> = ({ children }) => {
  return <RecoilRoot>{children}</RecoilRoot>;
};

export { RecoilSetup };
