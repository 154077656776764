import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import GenericFormComponent from 'src/components/form/itemComponent';

export default function DetallesEscritura(props: any) {
  const detalleExpedienteState = useSelector(
    (state: RootState) => state.escrituras.detalleExpediente,
  );

  const datosFormulario = useMemo(() => {
    return <GenericFormComponent fields={detalleExpedienteState} />
  }, [detalleExpedienteState]);

  useEffect(() => {
    if (detalleExpedienteState.Datos.length > 0) {
      props.setLoading(false);
    }
  }, [detalleExpedienteState]);

  return <>{!!detalleExpedienteState.Datos.length && datosFormulario}</>
};
