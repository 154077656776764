import { ModuleFieldDataModel } from '../../models/moduleModel/moduleFieldDataModel';

interface ModuleState {
  modules: ModuleFieldDataModel | undefined;
}

const ADD_MODULES = '@todo/ADD_MODULES';

interface AddModulesRequest {
  type: typeof ADD_MODULES;
  payload: ModuleFieldDataModel | undefined;
}

export type ModuleActionsTypes = AddModulesRequest;
export { 
  type  ModuleState,
  ADD_MODULES
};