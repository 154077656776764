import { JsonConverter, JsonCustomConvert } from 'json2typescript'

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date | null> {
  serialize(data: Date) {
    return data.toISOString()
  }

  deserialize(data: any): Date | null {
    if (data === '0001-01-01T00:00:00') {
      return new Date(0)
    }
    if (data == null ) {
      return null
    }
    return new Date(data ?? 0)
  }
}

// @JsonConverter
// export class DateConverterString implements JsonCustomConvert<Date | String> {
//   serialize(data: Date) {
//     return data.toISOString()
//   }

//   deserialize(data: any): Date {
//     if (data === '0001-01-01T00:00:00') {
//       return ''
//     }
//     return new Date(data ?? 0)
//   }
// }

export const deleteFakeDates = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '0001-01-01T00:00:00' || data[key] == null ) {
      data[key] = ''
    }
  })
  return data
}
// export const deleteFakeDatesType = (data: any) => {
//   Object.keys(data).forEach((key) => {
//     if (data[key] === '0001-01-01T00:00:00') {
//       //data[key] = new Date()
//       data[key] = ''
//     }
//     // if (data[key] instanceof Date) {
//     //   data[key] = new Date(0)
//     // }
//   })
//   return data
// }

export const deleteFakeDatesType = (data: any) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '0001-01-01T00:00:00') {
      data[key] = null; // Conserva la fecha nula en lugar de cadena vacía
    }
  });
  return data;
};