import React, { useEffect } from 'react'
import { LoadPanel, Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup'
import { useDispatch, useSelector } from 'react-redux'
import { showPopup } from 'src/redux/actions/sharedUtilsActions'
import { RootState } from 'src/redux/combineReducers'
import DetallesEscritura from './components/DetallesEscritura'
import { TabsDeed } from './components/TabsEscritura'
import { DetallesCostes } from './components/DetallesCostes'

const DetallesEscrituraMemo = React.memo(DetallesEscritura)
const TabsDeedMemo = React.memo(TabsDeed)
const DetallesCostesMemo = React.memo(DetallesCostes)

export default function ConsultaEscritura(props: any) {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user?.user)
  const showPopupState = useSelector(
    (state: RootState) => state.sharedUtils.showPopup,
  )
  const escriturasState = useSelector((state: RootState) => state.escrituras)

  const hasData = !!escriturasState.detalleExpediente.Datos.length

  useEffect(() => {
    if (showPopupState) {
      props.setLoading(true)
    }

    function handleOnEscape(e: any) {
      if (e.key === 'Escape') {
        dispatch(showPopup(false))
      }
    }

    document.addEventListener('keydown', handleOnEscape)

    return () => {
      document.removeEventListener('keydown', handleOnEscape)
    }
  }, [dispatch, props.Id, showPopupState])

  const ToolbarItemComponent = (
    <ToolbarItem
      widget='dxButton'
      location='after'
      options={{
        icon: 'close',
        onClick: () => {
          props.setLoading(false)
          dispatch(showPopup(false))
        },
      }}
    />
  )

  const ScrollViewComponent = (
    <ScrollView>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
          <TabsDeedMemo />
          <div style={{ borderBottomStyle: 'solid', marginTop: '1.5em' }}>
            Detalles escrituras
          </div>
          <DetallesCostesMemo />
          <div style={{ borderBottomStyle: 'solid' }}>Detalles costes</div>
          <DetallesEscrituraMemo setLoading={props.setLoading} />
          <div style={{ borderBottomStyle: 'solid' }}>Detalles expediente</div>
        </div>
      </div>
      <LoadPanel
        shadingColor='rgba(192,192,192,0.5)'
        position='center'
        visible={props.loading}
      />
    </ScrollView>
  )

  const renderTitle = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h4 style={{ fontWeight: 'bold' }}>Consulta de expedientes escritura</h4>
      <button
        onClick={() => {
          props.setLoading(false)
          dispatch(showPopup(false))
        }}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.5em',
          color: '#000',
        }}
      >
        X
      </button>
    </div>
  )

  return (
    <Popup
      visible={showPopupState}
      titleRender={renderTitle}
      width='95%'
      height='95%'
      margin-left='2%'
      closeOnOutsideClick={true}
      showCloseButton={true}
      onHiding={() => {
        props.setLoading(false)
        dispatch(showPopup(false))
      }}
    >
      {ToolbarItemComponent}
      {ScrollViewComponent}
    </Popup>
  )
}
