import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'devextreme-react'
import { SimpleItem } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSize } from '../../../../utils/media-query'
import {
  getIdocCarDossierStatusElGestorList,
  getIdocCarDossierStatusList,
  postIdocCarDossierStatusRelation,
  putIdocCarDossierStatusRelation,
} from '../service/idocCarEstadosExpedientesService'
import { refreshGrid } from '../../../../utils/sharedUitls'
import { addIdocCarDossierStatusElGestorAll } from '../redux/idocCarEstadosExpedientesActions'

function IdocCarEstadosExpedientesFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user)

  const idocCarDossierStatusElGestorState = useSelector(
    (state: RootState) => state.idocCarDossierStatusElGestor,
  )
  const { isLarge } = useScreenSize()
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const { handleSubmit } = useForm()
  const [selectDossier, setSelectDossier]: any = useState()

  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      setSelectDossier(await getIdocCarDossierStatusList(userState!.user!))
    }
    fetchData()
  })

  const onSubmit = async (data: any) => {
    const { IdDossierStatus, IdStatusElGestor } = formData.current as any
    if (idocCarDossierStatusElGestorState?.idocCarDossierStatusElGestor?.Id) {
      let formDataParsed = JSON.stringify({
        Id: idocCarDossierStatusElGestorState.idocCarDossierStatusElGestor?.Id,
        IdDossierStatus: IdDossierStatus,
        IdStatusElGestor: IdStatusElGestor,
      })
      let solicitud = await putIdocCarDossierStatusRelation(
        userState!.user!,
        formDataParsed,
      )
      if (solicitud) {
        dispatch(
          addIdocCarDossierStatusElGestorAll(
            await getIdocCarDossierStatusElGestorList(userState!.user!),
          ),
        )
      }
      refreshGrid(props.setShowFormIdocCarDossierStatus, true)

      props.setShowFormIdocCarDossierStatus(false)
    } else {
      let formDataParsed = JSON.stringify({
        IdDossierStatus: IdDossierStatus,
        IdStatusElGestor: IdStatusElGestor,
      })

      const solicitud = await postIdocCarDossierStatusRelation(
        userState!.user!,
        formDataParsed!,
      )
      if (solicitud) {
        refreshGrid(props.setShowFormIdocCarDossierStatus, true)
        dispatch(
          addIdocCarDossierStatusElGestorAll(
            await getIdocCarDossierStatusElGestorList(userState!.user!),
          ),
        )
        props.setShowFormIdocCarDossierStatus(false)
      }
    }
  }

  const selectedBoxOptions = {
    items: selectDossier,
    placeholder: 'Sin estado',
    displayExpr: 'Name',
    valueExpr: 'Id',
    value:
      idocCarDossierStatusElGestorState.idocCarDossierStatusElGestor !==
      undefined
        ? idocCarDossierStatusElGestorState!.idocCarDossierStatusElGestor!
            .DossierStatus!.Id
        : 'Sin estado',
  }
  return (
    <>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              formData={formData.current}
              disabled={loading}
              //ref={formSujetoRef}
              readOnly={
                props.isCreatingForm || props.isEditingForm ? false : true
              }
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='idocCarDossierStatusElGestorData'
              showColonAfterLabel={true}
            >
              <SimpleItem
                dataField='Id'
                editorType={'dxNumberBox'}
                label={{ text: 'Registro' }}
                //visible={props.isCreatingForm ? false : true}
                visible={false}
                editorOptions={{
                  value:
                    idocCarDossierStatusElGestorState.idocCarDossierStatusElGestor !==
                    undefined
                      ? idocCarDossierStatusElGestorState
                          .idocCarDossierStatusElGestor?.Id
                      : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'IdDossierStatus'}
                editorType={'dxSelectBox'}
                label={{ text: 'Estado El Portal' }}
                editorOptions={selectedBoxOptions}
              ></SimpleItem>

              <SimpleItem
                dataField={'IdStatusElGestor'}
                editorType={'dxTextBox'}
                label={{ text: 'Estado El Gestor' }}
                editorOptions={{
                  mode: 'text',
                  value:
                    idocCarDossierStatusElGestorState.idocCarDossierStatusElGestor !==
                    undefined
                      ? idocCarDossierStatusElGestorState
                          .idocCarDossierStatusElGestor?.Descripcion
                      : null,
                }}
              ></SimpleItem>
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export default IdocCarEstadosExpedientesFormPage
