import { UserModel } from 'src/models/usuario/userModel'
import informaAxios from '../../../utils/serviciosRuanoAxios'
import { authHeader } from 'src/services/sharedServices'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { SaldoInformaModel } from '../models/saldoInformaModel'
import { StockCompanyModel } from '../models/StockCompanyModel'
import { CompanyInformaModel } from '../models/companyInformaModel'
import { CompanyConsultaModel } from '../models/CompanyConsultaModel'
import { CompanyConsultaHistoricoGridModel } from '../models/CompanyConsultaHistoricoGridModel'
import { SaldoGeneralCompanyModel } from '../models/saldoGeneralCompanyModel'

export async function getSaldoCompanys({
  queryKey,
}: any): Promise<Array<StockCompanyModel> | StockCompanyModel | null> {
  const Company: StockCompanyModel | null = await informaAxios
    .get(
      queryKey[2]
        ? `StockCompany/All
`
        : `/StockCompany`,
      {
        headers: authHeader(queryKey[1]),
      },
    )
    .then((data: any) => {
      return data.data
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los datos de la empresa.',
        NotifyType.error,
        5000,
      )
      return null
    })

  return Company
}

export async function setCompanySaldo(
  user: UserModel,
  saldoCompany: SaldoInformaModel,
): Promise<Boolean> {
  const editCompany: boolean = await informaAxios
    .post(`StockCompany`, saldoCompany, {
      headers: authHeader(user),
    })
    .then((data: any) => {
      if (data.status === 200) {
        showToast('Saldo Actualizado', NotifyType.success, 5000)
        return true
      } else {
        showToast(
          'No se ha podido actualizar el saldo de la empresa.',
          NotifyType.error,
          5000,
        )
        return false
      }
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido actualizar el saldo de la empresa.',
        NotifyType.error,
        5000,
      )
      return false
    })

  return editCompany
}

export async function getCompanyDates({
  queryKey,
}: any): Promise<CompanyInformaModel | null> {
  const companyDates =
    queryKey[2] === ''
      ? null
      : await informaAxios
          .get(`CompaniesQuery/${queryKey[2]}`, {
            headers: authHeader(queryKey[1]),
          })
          .then((data: any) => {
            if (data.status === 200) {
              return data.data
            } else {
              showToast(
                'No se ha podido encontrar los datos de la empresa.',
                NotifyType.error,
                5000,
              )
              return null
            }
          })
          .catch((error: any) => {
            showToast(
              'No se ha podido encontrar los datos de la empresa.',
              NotifyType.error,
              5000,
            )
            return null
          })

  return companyDates
}

export async function getCompanyConsultaDates({
  queryKey,
}: any): Promise<Array<CompanyConsultaModel> | null> {
  const companyDates = await informaAxios
    .get(queryKey[2] ? `StockCompany/Total/All` : `StockCompany/Total`, {
      headers: authHeader(queryKey[1]),
    })
    .then((data: any) => {
      if (data.status === 200) {
        if (Array.isArray(data.data)) {
          return data.data
        } else {
          return [data.data]
        }
      } else {
        showToast(
          'No se ha podido encontrar los datos de los historicos de las empresas.',
          NotifyType.error,
          5000,
        )
        return null
      }
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido encontrar los datos de los historicos de las empresas.',
        NotifyType.error,
        5000,
      )
      return null
    })

  return companyDates
}

export async function getHistoryCompany(
  user: UserModel,
  id: number,
  superadministrador: boolean,
): Promise<Array<CompanyConsultaHistoricoGridModel> | null> {
  const dataHistory: Array<CompanyConsultaHistoricoGridModel> | null =
    await informaAxios
      .get(
        superadministrador
          ? `StockCompany/TotalGroupByUser/${id}`
          : 'StockCompany/TotalGroupByUser',
        {
          headers: authHeader(user),
        },
      )
      .then((data) => {
        return data.data
      })
      .catch((error) => {
        showToast(
          'No se han podido obtener los datos del historico de la empresa.',
          NotifyType.error,
          5000,
        )
        return null
      })

  return dataHistory
}
export async function getStockGeneral({
  queryKey,
}: any): Promise<SaldoGeneralCompanyModel | null> {
  const dataSaldoGeneral: SaldoGeneralCompanyModel | null = queryKey[2]
    ? await informaAxios
        .get('StockGeneral', {
          headers: authHeader(queryKey[1]),
        })
        .then((data) => {
          return data.data
        })
        .catch((error) => {
          showToast(
            'No se han podido obtener los datos del saldo general de la empresa.',
            NotifyType.error,
            5000,
          )
          return null
        })
    : null

  return dataSaldoGeneral
}
