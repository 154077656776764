import React, { useState } from 'react'
import { Accordion, Box, CheckBox, Form } from 'devextreme-react'
import {
  GroupItem,
  NumericRule,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import FooterEnviarFormSection from '../FooterForm/FooterEnviarFormSection'
import { Item } from 'devextreme-react/box'
import { PropsDatosVehiculoSection } from './propsDatosVehiculo.interface'
import { sendBastidorService } from './datosVehiculo.service'
import { RootState } from 'src/redux/combineReducers'
import { useSelector } from 'react-redux'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import DatosVehiculoBastidor from './datosVehiculoBastidor'
import { useQuery } from 'react-query'
import { isNotEmpty } from 'src/utils/sharedUitls'

export default function DatosVehiculoSection({
  validos,
  enviarsolicitud,
  validacion,
  editar,
  firmardocumento,
  formDatosVehiculo,
}: PropsDatosVehiculoSection) {
  const userState = useSelector((state: RootState) => state.user?.user)
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  const paginas = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.paginas,
  )
  const tipovehiculos = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.tipovehiculo,
  )
  const serviciodestino = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.serviciodestino,
  )
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [currentBastidor, setCurrentBastidor] = useState(solicitud!.Vehicle.VIN)

  const { data: dataVehiculo } = useQuery({
    queryKey: ['datosBastidor', currentBastidor],
    queryFn: () => {
      solicitud!.Vehicle.VIN = currentBastidor
      if (currentBastidor.length === 17) {
        return sendBastidorService(currentBastidor, userState!.Token)
      }
    },
    enabled: IsModuloAllowed(userState!, Modules.MICRODATOS),
    initialData: null,
    onSuccess: (data) => {
      if (isNotEmpty(data)) {
        setIsAccordionOpen(true)
        solicitud!.Vehicle.VIN = data.bastidor
        solicitud!.Vehicle.Brand = data.marcaItv
        solicitud!.Vehicle.Model = data.modeloItv
      }
    },
  })

  const myDataSource = [
    {
      id: 1,
      name: 'John',
    },
  ]

  return (
    <form>
      <Form
        id='formVehiculo'
        ref={formDatosVehiculo}
        formData={solicitud}
        readOnly={false}
        showColonAfterLabel={true}
        showValidationSummary={true}
        validationGroup='customerData3'
      >
        <GroupItem>
          <GroupItem colCount={3}>
            <SimpleItem
              label={{ text: 'Bastidor' }}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                mode: 'text',
                hint: 'Bastidor coche',
                value: currentBastidor,
                onValueChanged: (e: any) => {
                  solicitud!.Vehicle.VIN = e.value
                  setCurrentBastidor(e.value)
                },
              }}
            >
              <RequiredRule message={'Bastidor Necesario'}></RequiredRule>
            </SimpleItem>
            <SimpleItem
              label={{ text: 'Matrícula' }}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                mode: 'text',
                hint: 'Matricula coche',
                value:
                  solicitud!.Vehicle.LicensePlate !== ''
                    ? solicitud!.Vehicle.LicensePlate
                    : null,
                onValueChanged: ({ value }: any) => {
                  if (value !== undefined && value  != null ) {
                    solicitud!.Vehicle.LicensePlate = value.trim()
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Tipo de vehículo' }}
              editorType='dxSelectBox'
              editorOptions={{
                hint: 'Tipo de vehiculo',
                onValueChanged: (e: any) => {
                  if (e.value !== undefined && e.value  != null ) {
                    solicitud!.Vehicle.VehicleType = e.value
                  }
                },
                dataSource: tipovehiculos,
                displayExpr: 'Nombre',
                valueExpr: 'Id',
                value: Number(solicitud!.Vehicle.VehicleType),
                searchEnabled: true,
                searchMode: 'contains',
                searchExpr: 'Nombre',
                minSearchLength: 0,
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Marca' }}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                mode: 'text',
                hint: 'Marca coche',
                value:
                  solicitud!.Vehicle.Brand !== ''
                    ? solicitud!.Vehicle.Brand
                    : null,
                onValueChanged: ({ value }: any) => {
                  if (value !== undefined && value  != null ) {
                    solicitud!.Vehicle.Brand = value.trim()
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Modelo' }}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                mode: 'text',
                hint: 'Modelo coche',
                value:
                  solicitud!.Vehicle.Model !== ''
                    ? solicitud!.Vehicle.Model
                    : null,
                onValueChanged: ({ value }: any) => {
                  if (value !== undefined && value  != null ) {
                    solicitud!.Vehicle.Model = value.trim()
                  }
                },
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Servicio de destino' }}
              editorType='dxSelectBox'
              editorOptions={{
                onValueChanged: (e: any) => {
                  if (e.value !== undefined && e.value  != null ) {
                    solicitud!.DestinationService.IdElGestor = e.value
                  }
                },
                dataSource: serviciodestino,
                hint: 'Servicio de destino coche',
                displayExpr: 'Descripcion',
                valueExpr: 'IdElGestor',
                value:
                  solicitud!.Client.Nif !== ''
                    ? Number(solicitud!.DestinationService.IdElGestor)
                    : null,
                searchEnabled: true,
                searchMode: 'contains',
                searchExpr: 'Descripcion',
                minSearchLength: 0,
              }}
            ></SimpleItem>
            <SimpleItem
              label={{ text: 'Base 576' }}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                mode: 'text',
                hint: 'Base 576 coche',
                value:
                  solicitud!.Base576Amount !== -1
                    ? solicitud!.Base576Amount
                    : null,
                onValueChanged: ({ value }: any) => {
                  if (
                    value !== undefined &&
                    value  != null  &&
                    !isNaN(Number(value))
                  ) {
                    solicitud!.Base576Amount = Number(value)
                  } else {
                    solicitud!.Base576Amount = value
                  }
                },
              }}
            >
              <NumericRule message='El campo base 576 debe ser tipo numérico' />
            </SimpleItem>
          </GroupItem>
          <GroupItem colCount={2}>
            {!editar && (
              <SimpleItem
                label={{ text: '¿Firmar expediente?' }}
                editorType={'dxCheckBox'}
                editorOptions={{
                  autoFocus: true,
                  value: firmardocumento.realizarfirma,
                  onValueChanged: (e: any) => {
                    if (e.value !== undefined && e.value  != null ) {
                      firmardocumento.realizarfirma = e.value
                    }
                  },
                }}
              ></SimpleItem>
            )}
            <SimpleItem>
              <CheckBox
                text='¿Desea que se genere el informe en la DGT?'
                defaultValue={solicitud!.GenerarInformeDgt}
                enableThreeStateBehavior={false}
                onValueChanged={(e) => (solicitud!.GenerarInformeDgt = e.value)}
              />
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <br />
        <GroupItem>
          <Accordion
            dataSource={myDataSource}
            deferRendering={false}
            itemRender={() => (
              <DatosVehiculoBastidor
                bastidorVehiculo={currentBastidor}
                datosBastidor={dataVehiculo}
              />
            )}
            collapsible={true}
            hint={'Visualizar Datos del vehículo'}
            defaultSelectedItem={'none'}
            repaintChangesOnly={true}
            activeStateEnabled={true}
            multiple={false}
            selectedIndex={isAccordionOpen ? 0 : -1}
            onItemTitleClick={(e: any) => {
              setIsAccordionOpen(!isAccordionOpen)
            }}
            itemTitleRender={() => (
              <h4
                style={{
                  fontWeight: 'bold',
                  fontSize: 'x-large',
                  margin: '0',
                }}
              >
                Visualizar Datos del vehículo
              </h4>
            )}
          />
        </GroupItem>

        <GroupItem>
          {FooterEnviarFormSection({
            validacion: validacion,
            enviarsolicitud: enviarsolicitud,
            verificacion: 'customerData3',
            validos: validos,
            editar: editar,
          })}
        </GroupItem>
      </Form>
      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'auto'}>
          <p className='alinearinputs'>
            Página {pagina} de {paginas}
          </p>
        </Item>
      </Box>
    </form>
  )
}
