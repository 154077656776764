function DigitalSignature(reference: Object, data: Object) {
  let result = JSON.parse(JSON.stringify(reference));
  let values = JSON.parse(JSON.stringify(data));

  for (let key in values) {
    if (result[key] !== undefined) {
      result[key] = values[key];
    }
  }
  return result;
}

export { DigitalSignature };