import { ADD_MODULES, ModuleActionsTypes, ModuleState } from './moduleType';

const initialState: ModuleState = {
  modules: undefined,
}

const moduleReducer = (
  state = initialState,
  action: ModuleActionsTypes,
): ModuleState => {
  switch (action.type) {
    case ADD_MODULES: {
      return { ...state, modules: action.payload };
    }
    default:
      return state;
  };
};

export { moduleReducer };