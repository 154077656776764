import React from 'react'
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
  defaultClassNames,
} from 'react-dropzone-uploader'
import { postImportFileTasas } from '../service/AlmacenDeTasasService'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { ImportarTasasFileProps } from '../interfaces/InformesVehiculosInterfaces'

const ImportarTasasFile: React.FC<ImportarTasasFileProps> = ({
  closePopup,
}) => {
  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div>
        {previews}
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
        {files.length > 0 && submitButton}
      </div>
    )
  }

  const enviarArchivos: IDropzoneProps['onSubmit'] = async (
    files,
    allFiles,
  ) => {
    for (const f of allFiles) {
      try {
        const response = await postImportFileTasas(f.file)
        if (response) {
          showToast(
            'Archivo de Tasas importado con éxito',
            NotifyType.success,
            2000,
          )
          closePopup()
        }
      } catch (error: any) {
        console.error('Error al subir el archivo', error)
        showToast(error.message, NotifyType.error, 2000)
      }
      f.remove()
    }
  }

  return (
    <div>
      <h2>Selecciona o Arrastra un archivo hasta aquí</h2>
      <Dropzone
        LayoutComponent={Layout}
        onSubmit={enviarArchivos}
        accept='.txt, .xml'
        classNames={{
          inputLabelWithFiles: defaultClassNames.inputLabel,
        }}
        submitButtonContent='Subir'
        inputContent={'Seleccionar archivo'}
        inputWithFilesContent={(files) => `Agregar`}
        submitButtonDisabled={(files: any) => files.length < 1}
        styles={{
          dropzone: { minHeight: '6vh', maxHeight: '10vh', width: '70%' },
        }}
      />
    </div>
  )
}

export { ImportarTasasFile }
