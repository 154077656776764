import { Button } from 'devextreme-react'
import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import {
  getDestinoTipoVehiculo,
  getDocumento,
  getSolicitud,
  getSolicitudesList,
  getStructureSolicitudes,
  getTipoCalle,
  getTipoVehiculo,
} from '../service/solicitudService'
import { addSolicitudList, clearSolicitud } from '../redux/solicitudActions'
import { addShowLoader } from 'src/redux/actions/configActions'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  checkScreenSize,
  getAlignment,
  getColumnType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import DataSource from 'devextreme/data/data_source'
import { SolicitudListModel } from '../models/solicitudListModel'
import {
  actualizarArchivos,
  addActualizarListaArchivos,
  addCompradorRepresentante,
  addPagina,
  addVendedorRepresentante,
} from '../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { ListaArchivosModel } from '../models/ListaArchivosModel'
import { Firmar } from '../types/firmarType'
import ConsultaVehiculo from 'src/pages/expedientePage/vehiculos/sections/consultaVehiculo/ConsultaVehiculo'
import { OnExporting } from 'src/utils/export/ExportExcel'
import { VehicleModel } from '../models/vehiculoModels'
import { DestinoVehiculosModels } from '../models/DestinoVehiculosModels'
import CustomStore from 'devextreme/data/custom_store'
import { useQuery } from 'react-query'
import { getIdocCarDossierStatusRelationList } from '../../idocCarRelationshipsStatesFiles/service/idocCarRelacionesEstadosExpedientesService'
import RequestForm from './RequestForm'

const options = [
  'filter',
  'skip',
  'take',
  'requireTotalCount',
  'sort',
  'totalSummary',
  'group',
]

const _getStore = (user: any) =>
  new DataSource({
    store: new CustomStore({
      key: 'Id',
      load: async (loadOptions: any) =>
        await getSolicitudesList(user, loadOptions, options),
    }),
  })

export class Ids {
  id: number | null = null
  idvehiculo: number | null = null
}

function RequestsList(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const location = useLocation().state
  const store = React.useMemo(() => _getStore(userState?.user), [userState])
  const solicitudState = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const listaarchivos = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.listaarchivos,
  )
  const [tipoformulario, setTipoFormulario] = useState('')
  const [tiposvehiculos, setTiposVehiculos] = useState({})
  const [destinovehiculos, setDestinosVehiculos] = useState({})
  const [tipocalle, setTipoCalle] = useState({})
  const [editar, setEditar] = useState(false)
  const [verpopup, setVerpopup] = useState(false)
  const [archivosyaagregados, setArchivosYaAgregados] = useState(
    Array<ListaArchivosModel>(),
  )
  const [firmaDocumento, setFirmaDocumento] = useState({
    realizarfirma: false,
    idexpediente: '',
    idvehiculo: '',
  } as Firmar)

  const navigate = useNavigate()
  const dataGridSolicitudesListRef: MutableRefObject<any> = useRef(null)

  const { data: camposSolicitudes } = useQuery({
    queryKey: ['solicitudesListStructure'],
    queryFn: getStructureSolicitudes,
    staleTime: Infinity,
  })

  // Método asincrónico se utiliza para obtener y cargar datos relacionados con las solicitudes.
  const fetchData = useCallback(async () => {
    try {
      // Muestra el spinner de carga mientras se obtienen los datos.
      dispatch(addShowLoader(true))
      // Si no se han cargado los tipos de vehículos, realiza la carga.
      if (!tiposvehiculos) {
        await _getVehicles()
      }
      // Si no se han cargado los destinos de vehículos, realiza la carga.
      if (!destinovehiculos) {
        await _getDestinationVehicles()
      }
      // Si no se han cargado los tipos de calles, realiza la carga.
      if (!tipocalle) {
        await _getTypeStreet()
      }
    } catch (error) {
      console.error('Error en la carga de datos:', error)
    } finally {
      // Oculta el spinner de carga, independientemente de si la carga fue exitosa o no.
      dispatch(addShowLoader(false))
    }
  }, [tiposvehiculos, destinovehiculos, tipocalle, dispatch, userState])

  /* useEffect: Se ejecuta después de cada renderización del componente.
  Realiza operaciones asíncronas como la carga de datos y actualiza el estado en consecuencia.*/
  useEffect(() => {
    fetchData()
    // Verifica si hay un nuevo estado para firmar documento y actualiza el estado en consecuencia.
    if (
      location != null &&
      location.nuevoestadofirmardocumento !== undefined &&
      location.nuevoestadofirmardocumento != null
    ) {
      // dispatch(addShowLoader(true));
      setVerpopup(!verpopup)
      setFirmaDocumento(location.nuevoestadofirmardocumento)
    } else {
      // Si no hay nuevo estado, establece el estado de firma de documento a su valor predeterminado.
      setFirmaDocumento({
        realizarfirma: false,
        idexpediente: '',
        idvehiculo: '',
      })
    }
  }, [fetchData]) // Se ejecuta cuando cambia la función 'fetchData'.

  // Obtiene tipos de vehículos y actualiza el estado.
  async function _getVehicles() {
    try {
      let datos = await getTipoVehiculo(userState!.user!)
      const selectBoxDataSource = new DataSource({
        store: {
          type: 'array',
          data: datos,
          key: 'Id',
        },
      })
      setTiposVehiculos(selectBoxDataSource)
    } catch (error) {
      console.error('Error al obtener tipos de vehículos:', error)
    }
  }

  // Obtiene destinos de vehículos y actualiza el estado.
  async function _getDestinationVehicles() {
    try {
      let datos = await getDestinoTipoVehiculo(userState!.user!)
      const selectBoxDataSource2 = new DataSource({
        store: {
          type: 'array',
          data: datos,
          key: 'Id',
        },
      })
      setDestinosVehiculos(selectBoxDataSource2)
    } catch (error) {
      console.error('Error al obtener destinos de vehículos:', error)
    }
  }

  // Obtiene tipos de calle y actualiza el estado.
  async function _getTypeStreet() {
    try {
      let datos = await getTipoCalle(userState!.user!)
      const selectBoxDataSource3 = new DataSource({
        store: {
          type: 'array',
          data: datos,
          key: 'Id',
        },
      })
      setTipoCalle(selectBoxDataSource3)
    } catch (error) {
      console.error('Error al obtener tipos de calle:', error)
    }
  }

  /* Maneja la preparación de la barra de herramientas.
  Agrega botones a la barra de herramientas, como añadir nueva solicitud,
  filtros personalizados y refrescar contenido.*/
  const onToolbarPreparing = (event: any) => {
    event.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          hint: 'Añadir nueva solicitud',
          onClick: () => {
            reset()
            navigate('/solicitudes/list/opcionesSolicitudes', {
              state: {
                firmardocumento: firmaDocumento,
              },
            })
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            if (_e.event) {
              await fetchData()
            }
          },
        },
      },
    )
  }

  // Obtiene documentos y actualiza el estado con los archivos obtenidos.
  async function _getDocuments(idexpediente: number) {
    const documentsAdded = await getDocumento(userState!.user!, idexpediente)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return null
      })

    documentsAdded!.forEach((name) => {
      let file = new ListaArchivosModel()
      file.Id = name.Id
      file.Nombre = name.Nombre
      file.Subido = name.Subido
      listaarchivos!.push(file)
      archivosyaagregados!.push(file)
    })
    setArchivosYaAgregados(archivosyaagregados!)
    dispatch(addActualizarListaArchivos(listaarchivos!))
    dispatch(actualizarArchivos(Array<File>()))
  }

  /* Edita una fila de solicitud según el ID proporcionado.
  Actualiza el estado de carga y muestra un mensaje de error
  si la solicitud no se puede editar.*/
  async function editRow(id: number) {
    let editRequest = await getSolicitud(userState!.user!, id)
    dispatch(addSolicitudList(editRequest))
    setTimeout(function () {
      dispatch(addShowLoader(false))
    }, 5000)

    if (editRequest !== undefined) {
      if (editRequest.Vehicle == null) {
        editRequest.Vehicle = new VehicleModel()
      }
      if (editRequest.DestinationService !== undefined) {
        editRequest.DestinationService = new DestinoVehiculosModels()
      }
      if (editRequest.OriginType === 1) {
        await _getDocuments(id)
        setEditar(true)
      } else {
        showToast(
          'Tipo de Solicitud no permitida para su edición',
          NotifyType.error,
          5000,
        )
      }
    } else {
      showToast('Error al obtener la solicitud', NotifyType.error, 5000)
    }
  }

  // Cierra el popup y realiza operaciones para limpiar y restablecer el estado.
  function closePopUp(): void {
    dispatch(addActualizarListaArchivos(listaarchivos!))
    setFirmaDocumento({
      realizarfirma: false,
      idexpediente: '',
      idvehiculo: '',
    })
    while (listaarchivos!.length > 0) listaarchivos!.pop()
    setEditar(false)
    reset()
  }

  // Restablece el estado para una nueva solicitud.
  function reset() {
    dispatch(clearSolicitud(new SolicitudListModel()))
    let tienecompradorrepresentante =
      solicitudState?.ClientLegalRep !== undefined &&
      solicitudState!.ClientLegalRep != null &&
      solicitudState!.ClientLegalRep.Nif != null &&
      solicitudState!.ClientLegalRep.Nif !== ''
    let tienevendedorrepresentante =
      solicitudState?.SellerLegalRep !== undefined &&
      solicitudState!.SellerLegalRep != null &&
      solicitudState!.SellerLegalRep.Nif != null &&
      solicitudState!.SellerLegalRep.Nif !== ''
    dispatch(addPagina(1))
    dispatch(addCompradorRepresentante(tienecompradorrepresentante))
    dispatch(addVendedorRepresentante(tienevendedorrepresentante))
    dispatch(addActualizarListaArchivos(new Array<ListaArchivosModel>()))
    dispatch(actualizarArchivos(Array<File>()))
  }

  interface EstadoColor {
    IdDossierStatus: string
    EstadoGestor: string
    EstadoPortal: string
    Color: string
  }

  const { data: estadosYColores } = useQuery<EstadoColor[] | undefined>(
    'estadosYColores',
    async () => {
      const response = await getIdocCarDossierStatusRelationList(
        userState!.user!,
      )
      if (response && Array.isArray(response)) {
        return response.map((item) => ({
          IdDossierStatus: item.IdDossierStatus,
          EstadoGestor: item.DossierStatusElGestor.Description,
          EstadoPortal: item.DossierStatus.Name,
          Color: item.DossierStatusElGestor.Color,
        }))
      }
      return
    },
    {
      enabled: !!userState?.user,
      staleTime: Infinity,
    },
  )

  const getColorForStatus = useCallback(
    (idDossierStatus: string) => {
      const estado = estadosYColores?.find(
        (e) =>
          e.EstadoPortal === idDossierStatus ||
          e.EstadoGestor === idDossierStatus,
      )
      return estado && estado.Color && estado.Color.trim() !== ''
        ? estado.Color
        : null
    },
    [estadosYColores],
  )

  return (
    <div
      style={{
        borderColor: '#f4f9ff',
        borderWidth: '2px',
        margin: '0.3%',
        padding: '0.16%',
      }}
    >
      <h3 style={{ margin: 0, padding: 0 }}>Solicitudes de trámite</h3>
      <div>
        <DataGrid
          id='solicitudesList'
          ref={dataGridSolicitudesListRef}
          dataSource={store}
          style={{ width: '100%', minHeight: '250px' }}
          height='calc(110vh - 8rem - 46px)'
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          focusedRowEnabled
          remoteOperations={{ filtering: true, sorting: true }}
          repaintChangesOnly
          rowAlternationEnabled
          showBorders
          showRowLines
          columnResizingMode={'widget'}
          columnHidingEnabled={!checkScreenSize()}
          filterRow={{ visible: true }}
          filterPanel={{ visible: true }}
          headerFilter={{ visible: true, allowSearch: true }}
          loadPanel={{ enabled: true }}
          export={{
            allowExportSelectedData: false,
            enabled: true,
          }}
          searchPanel={{
            visible: true,
            width: checkScreenSize() ? 240 : 120,
          }}
          onToolbarPreparing={(event: any) => {
            onToolbarPreparing(event)
          }}
          onExporting={OnExporting}
        >
          <StateStoring
            enabled
            type={'localStorage'}
            storageKey={'solicitudesListGrid'}
            // storageKey={'solicitudesDataGrid'}
          />
          <Scrolling
            mode='virtual'
            rowRenderingMode='virtual'
            showScrollbar='always'
            scrollByThumb
            preloadEnabled
          />
          <Sorting mode={'multiple'} />
          <Paging pageSize={1000} />
          <Column
            visible={true}
            type='drag'
            width={50}
            cellRender={(row: any) => (
              <Button
                visible={row.data.Editable}
                width='32px'
                icon='edit'
                hint='Editar'
                onClick={async (e) => {
                  if (e.event) {
                    dispatch(addShowLoader(true))
                    await editRow(row.data.Id)
                    setTipoFormulario(row.data.TipoAsunto)
                  }
                }}
              />
            )}
          />
          {camposSolicitudes?.Campos.map((value) => (
            <Column
              sortOrder={value.Nombre === 'FechaInicio' ? 'desc' : undefined}
              visible={value.Visible}
              key={value.Nombre}
              dataField={value.Nombre}
              caption={value.Texto}
              dataType={
                value.Nombre === 'FechaInicio' ? 'datetime' : value.Tipo
              }
              alignment={getAlignment(value.Tipo)}
              format={getColumnType(
                value.Nombre === 'FechaInicio' ? 'datetime' : value.Tipo,
                value.Format,
              )}
              allowHeaderFiltering={value.Tipo !== 'datetime'}
              allowSorting
              showInColumnChooser={value.Visible}
              cellRender={
                value.Nombre === 'Estado'
                  ? (cellData: any) => {
                      if (!cellData.value || cellData.value.trim() === '') {
                        return null
                      }
                      const backgroundColor = getColorForStatus(cellData.value)
                      if (!backgroundColor) {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            {cellData.value}
                          </div>
                        )
                      }
                      return (
                        <div
                          style={{
                            backgroundColor,
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '5px',
                            boxShadow: '-1.5px -1.5px 4px rgba(0, 0, 0, 0.2)',
                            color: 'white',
                          }}
                        >
                          {cellData.value}
                        </div>
                      )
                    }
                  : undefined
              }
            />
          ))}
          <Summary>
            <TotalItem column='NumeroExpediente' summaryType='count' />
          </Summary>
        </DataGrid>
      </div>
      <div>
        {editar && tipoformulario !== '' && (
          <RequestForm
            cerrar={closePopUp}
            vehiculos={tiposvehiculos}
            destinos={destinovehiculos}
            calles={tipocalle}
            tipo={tipoformulario === 'TR' ? 'CTI' : tipoformulario}
            editar={editar}
            firmardocumento={firmaDocumento}
            documentosagregados={archivosyaagregados!}
          ></RequestForm>
        )}
        {firmaDocumento.idexpediente !== '' && firmaDocumento.realizarfirma && (
          <ConsultaVehiculo
            datos={{
              id: firmaDocumento.idexpediente,
              idvehiculo: firmaDocumento.idvehiculo,
            }}
            popupview={verpopup}
            setpopup={setVerpopup}
            firma={firmaDocumento}
            setfirma={setFirmaDocumento}
          />
        )}
      </div>
    </div>
  )
}

export { RequestsList }
