import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('HaciendaModel')
class HaciendaModel {
  // @JsonProperty('Id', Number)
  // Id: number = 0

  @JsonProperty('BaseImponible', Number)
  BaseImponible: number = 0

  @JsonProperty('CodigoConceptoLiquidacion', String)
  CodigoConceptoLiquidacion: string = ''

  @JsonProperty('Cuota', Number)
  Cuota: number = 0

  @JsonProperty('DescripcionConceptoLiquidacion', String)
  DescripcionConceptoLiquidacion: string = ''

  @JsonProperty('FechaPago', String)
  FechaPago: Date | String = ''

  @JsonProperty('FechaPagoMod211', String)
  FechaPagoMod211: Date | String = ''

  @JsonProperty('FechaPresentacion', String)
  FechaPresentacion: Date | String = ''

  @JsonProperty('FechaRecogida', String)
  FechaRecogida: Date | String = ''

  @JsonProperty('NumeroPago', String)
  NumeroPago: string = ''

  @JsonProperty('NumeroPresentacion', String)
  NumeroPresentacion: string = ''

  @JsonProperty('Pagado', Boolean)
  Pagado: boolean = false

  @JsonProperty('TipoImpositivo', Number)
  TipoImpositivo: number = 0

  @JsonProperty('Notas', String)
  Notas: string = ''
}

@JsonObject('HaciendaCampoDatoModel')
export class HaciendaCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [HaciendaModel])
  Datos: HaciendaModel[] = []
}
