import { Popup } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import React, { MutableRefObject, ReactElement,
  useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import { useScreenSizeGrid } from '../../../../utils/media-query';
import DataGrid, { Column, ColumnChooser, ColumnFixing,
  Export, FilterPanel, LoadPanel, SearchPanel, StateStoring } from 'devextreme-react/data-grid';
import { addIdocCarOrganizationalUnit, addIdocCarOrganizationalUnitAll,
  clearIdocCarOrganizationalUnit } from '../redux/idocCarOrganizationalUnitActions';
import Button from 'devextreme-react/button';
import { ToolbarItem } from 'devextreme-react/popup';
import { custom } from 'devextreme/ui/dialog';
import IdocCarOrganizationalUnitFormPage from './idocCarOrganizationalUnitFormPage';
import { deleteIdocCarOrganizationalUnit, getIdocCarOrganizationalUnitList} from '../service/idocCarOrganizationalUnitService';
import { OnExporting } from 'src/utils/export/ExportExcel';

function IdocCarOrganizationalUnitPage(): ReactElement {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const idocCarOrganizationalUnitState = useSelector(
    (state: RootState) => state.idocCarOrganizationalUnit,
  );
  const [rowFocused, setRowFocused] = useState('');
  const [
    showFormIdocCarOrganizationalUnit,
    setShowFormIdocCarOrganizationalUnit,
  ] = useState(false);
  const [isCreatingForm, setIsCreatingForm] = useState(false);
  const [isEditingForm, setIsEditingForm] = useState(false);

  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  );
  const dataGridIdocCarOrganizationalUnitRef: MutableRefObject<any> =
    useRef(null);
  const gridheight = useScreenSizeGrid();
  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setShowFormIdocCarOrganizationalUnit(false);
        setIsCreatingForm(false);
        setIsEditingForm(false);
      };
    });
    async function fetchData() {
      try {
        dispatch(
          addIdocCarOrganizationalUnitAll(
            await getIdocCarOrganizationalUnitList(userState!.user!),
          ),
        );
      } catch (e) {
        console.error(e);
      };
    };
    fetchData();
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowFormIdocCarOrganizationalUnit(false);
          setIsCreatingForm(false);
          setIsEditingForm(false);
        };
      });
    };
  }, []);

  const renderGridCell = (data: any) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon='edit'
          type='normal'
          stylingMode='contained'
          onClick={(e) => {
            setIsEditingForm(true);
            setShowFormIdocCarOrganizationalUnit(true);
          }}
        />
        <Button
          icon='trash'
          style={{ marginLeft: '2%' }}
          type='normal'
          stylingMode='contained'
          onClick={async (e) => {
            const confirmDialog = custom({
              messageHtml: '¿Desear eliminar este registro?',
              buttons: [
                {
                  text: 'Continuar',
                  onClick: async (e) => {
                    await deleteIdocCarOrganizationalUnit(
                      userState!.user!,
                      parseInt(rowFocused),
                    );
                    dispatch(
                      addIdocCarOrganizationalUnitAll(
                        await getIdocCarOrganizationalUnitList(
                          userState!.user!,
                        ),
                      ),
                    );
                    confirmDialog.hide();
                  },
                },
                {
                  text: 'Cancelar',
                  elementAttr: {
                    id: 'usuarioCancelButton',
                    class: 'usuarioCancelButtonClass',
                  },
                  onClick: (e) => confirmDialog.hide(),
                },
              ],
            });
            confirmDialog.show();
          }}
        />
      </div>
    );
  };

  // const openCreatePopup = async () => {
  //   setShowForm(!showForm);
  //   dispatch(isOpenPopup(!showForm, popupSujetosRef));
  // }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear',
          icon: 'add',
          // visible: false,
          onClick: (_e: any) => {
            dispatch(clearIdocCarOrganizationalUnit(undefined));
            setShowFormIdocCarOrganizationalUnit(true);
            setIsCreatingForm(true);
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement;
            elementFilterPanel.click();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            await dispatch(
              addIdocCarOrganizationalUnitAll(
                await getIdocCarOrganizationalUnitList(userState!.user!),
              ),
            );
          },
        },
      },
    );
  };

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>
          iDocCar - unidades organizativas
        </h3>
        <div>
          <DataGrid
            dataSource={
              idocCarOrganizationalUnitState.idocCarOrganizationalUnitAll
            }
            keyExpr='Id'
            allowColumnResizing={true}
            columnResizingMode='nextColumn'
            columnMinWidth={25}
            ref={dataGridIdocCarOrganizationalUnitRef}
            allowColumnReordering={true}
            height={gridheight}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event);
            }}
            showBorders={true}
            focusedRowEnabled={true}
            onRowDblClick={(e: any) => {
              if (e.key) {
                setRowFocused(e.key);
                dispatch(addIdocCarOrganizationalUnit(e.data));
              };
              setShowFormIdocCarOrganizationalUnit(true);
            }}
            onRowClick={(e: any) => {
              if (e.key) {
                setRowFocused(e.key);
                dispatch(addIdocCarOrganizationalUnit(e.data));
              };
            }}
            onFocusedRowChanged={(e: any) => {
              if (e.row) {
                setRowFocused(e.row.key);
                dispatch(addIdocCarOrganizationalUnit(e.row.data));
              };
            }}
            onExporting={OnExporting}
            headerFilter={{ visible: true, allowSearch: true }}
          >
            <Export enabled={true} />
            <SearchPanel visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <LoadPanel enabled />
            <StateStoring
              enabled={true}
              type='localStorage'
              storageKey='idocCarOrganizationalUnit'
            />
            <ColumnFixing enabled={true} />
            <Column
              dataField=''
              minWidth={'100'}
              width={'6%'}
              cellRender={renderGridCell}
            />

            <Column dataField='Id' caption='Registro' />
            <Column
              dataField='OrganizationalUnit'
              caption='Unidad Organizativa'
            />
            <Column dataField='Nif' caption='Nif' />
            <Column dataField='Name' caption='Nombre' />
            <Column
              dataField='ExternalDossierEnvironmentId'
              caption='Entorno'
            />
          </DataGrid>
        </div>
        <Popup
          visible={showFormIdocCarOrganizationalUnit}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={'IdocCar'}
          width={'65%'}
          height={'65%'}
          showCloseButton={false}
        >
          <ToolbarItem
            widget='dxButton'
            toolbar='top'
            location='after'
            options={{
              text: '',
              icon: 'close',
              onClick: () => {
                if (isChangedState) {
                  let confirmDialog = custom({
                    title: '',

                    messageHtml:
                      '<b>Para conservar los cambios antes pulsar Guardar, Si continua perderá todos los datos cambiados</b>',
                    buttons: [
                      {
                        text: 'Continuar',
                        onClick: (e) => {
                          setShowFormIdocCarOrganizationalUnit(false);
                          setIsCreatingForm(false);
                          setIsEditingForm(false);
                          confirmDialog.hide();
                        },
                      },
                      {
                        text: 'Cancelar',
                        onClick: (e) => {
                          confirmDialog.hide();
                        },
                      },
                    ],
                  });
                  confirmDialog.show().then((dialogResult: any) => {
                    console.log(dialogResult);
                  });
                } else {
                  setShowFormIdocCarOrganizationalUnit(false);
                  setIsCreatingForm(false);
                  setIsEditingForm(false);
                };
              },
            }}
          />
          <ScrollView width='100%' height='100%'>
            <IdocCarOrganizationalUnitFormPage
              row={rowFocused}
              setShowFormIdocCarOrganizationalUnit={
                setShowFormIdocCarOrganizationalUnit
              }
              showFormIdocCarOrganizationalUnit={
                showFormIdocCarOrganizationalUnit
              }
              isCreatingForm={isCreatingForm}
              setIsCreatingForm={setIsCreatingForm}
              isEditingForm={isEditingForm}
              setIsEditingForm={setIsEditingForm}
            ></IdocCarOrganizationalUnitFormPage>
          </ScrollView>
        </Popup>
      </div>
    </div>
  );
}

export { IdocCarOrganizationalUnitPage }
