import { CompaniesStorageSizeModel } from '../models/CompaniesStorageSizeModel';
import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { NotifyType, checkStatusCode, showToast } from 'src/utils/sharedUitls';

/**
 * Método asincrónico para obtener una lista de tamaños de almacenamiento de compañías.
 * @returns Una promesa que se resuelve con una lista de modelos de tamaño de almacenamiento de compañías o null si hay un error.
 */
const _getCompaniesSizes = async (): Promise<CompaniesStorageSizeModel[] | null> => {
  try {
    // Configura el convertidor JSON
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    // URL para obtener la lista de tamaños de almacenamiento de compañías
    let url = `${process.env.REACT_APP_API_URL_CORE}/Companies/Sizes`;

    // Realiza una solicitud GET a la API
    const response = await axiosInstance.get(url);
    
    // Verifica si la respuesta tiene un estado exitoso (código 200)
    if (response.status !== 200) {
      checkStatusCode(response.status);
      // Retorna null si la respuesta no es exitosa
      return null;
    }

    // Deserializa el objeto JSON de la respuesta a una lista de modelos de tamaño de almacenamiento de compañías
    const companySizes: CompaniesStorageSizeModel[] = jsonConvert.deserializeArray(
      response.data,
      CompaniesStorageSizeModel
    );

    // Retorna la lista de tamaños de almacenamiento de compañías
    return companySizes;
  } catch (error) {
    // Captura y registrar cualquier error que ocurra durante la ejecución del método
    console.error(error);
    showToast('No se han podido cargar los datos', NotifyType.error, 5000)
    // Retorna null en caso de error
    return null;
  }
};

export { _getCompaniesSizes };
