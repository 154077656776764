import React, { ReactElement } from 'react'
import { RequestsList } from './pages/RequestsList'
import NotFound from 'src/components/notFound/notFound'
import { RootState } from 'src/redux/combineReducers'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import { isSuperAdmin } from 'src/utils/allowAuthUtil'

function RequestsPage(): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()

  return (
    <>
      {!userState!.isLoggedIn && navigate('/Login')}
      {IsModuloAllowed(userState!.user!, Modules.SOLICITUDES) &&
        (!isSuperAdmin(userState!.user!) ? (
          <RequestsList />
        ) : (
          <div
            style={{
              borderColor: '#f4f9ff',
              borderWidth: '2px',
              margin: '0.3%',
              padding: '0.16%',
            }}
          >
            <NotFound data={{ type: 1 }} />
          </div>
        ))}
    </>
  )
}

export { RequestsPage }
