import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CalculadoraTransferenciaModel')
class CalculatorTransferModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0;

  @JsonProperty('CuotaTurismos', Number)
  CuotaTurismos: number = 0;

  @JsonProperty('CuotaMotocicletas', Number)
  CuotaMotocicletas: number = 0;

  @JsonProperty('CuotaCiclomotores', Number)
  CuotaCiclomotores: number = 0;

  @JsonProperty('Mensaje', String)
  Mensaje: string = '';

  @JsonProperty('Key', String)
  Key: string = '';
};

export { CalculatorTransferModel };
