import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { addIsAlreadyLogged } from '../../pages/loginPage/redux/authActions';
import { customLog, logType } from 'src/utils/sharedUitls';
import { UserModel } from 'src/models/usuario/userModel';
import { store } from '../store';
import { setTokenAuth } from 'src/utils/axios';
import { setTokenInformaAuth } from 'src/utils/serviciosRuanoAxios';
import { setTokenFirmaDigitalAuth } from 'src/utils/firmadigitalaxios';

/*
Verifica si existe información de usuario en el almacenamiento local,
intenta deserializarla y realiza acciones relacionadas con la autenticación.
Configura tokens y dispatcha una acción de Redux si la autenticación es exitosa.
En caso de error, limpia la información del usuario y registra el error.
@returns {boolean} Retorna true si el usuario está autenticado, false si hay problemas.
 */
function isAlreadyLoggedUser() {
  if (localStorage.getItem('user')) {
    try {
      let jsonConvert: JsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
      let usuario: UserModel = jsonConvert.deserializeObject(
        JSON.parse(localStorage.getItem('user')!),
        UserModel,
      );
      setTokenAuth(usuario.Token);
      setTokenInformaAuth(usuario.Token);
      setTokenFirmaDigitalAuth(usuario.Token);
      store.dispatch(addIsAlreadyLogged(usuario));
      return true;
    } catch (error: any) {
      localStorage.removeItem('user');
      customLog(error.message, logType.ERROR);
    };
  };
  return false;
};

export { isAlreadyLoggedUser };