import { Box, Form, Popup, RadioGroup, ScrollView } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { ToolbarItem } from 'devextreme-react/date-box'
import { AsyncRule, GroupItem, SimpleItem } from 'devextreme-react/form'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { RestarSaldo } from '../../utils/SaldoUtils'
import FooterSaldoSection from '../FooterSaldoSection/footerSaldoSection'
import { setCompanySaldo } from '../../services/infomaservices'
import { useMutation, useQueryClient } from 'react-query'
import { StockCompanyModel } from '../../models/StockCompanyModel'

interface Props {
  cerrar: Function
  stockEmpresa: StockCompanyModel
}

export default function EditSaldoFormSection({ cerrar, stockEmpresa }: Props) {
  const userState = useSelector((state: RootState) => state.user)
  const saldoState = useSelector((state: RootState) => state.Informa.saldo)
  const aumentarReducirSaldo = useRef(-1)
  const cantidadSaldo = useRef(0)
  const formref: MutableRefObject<any> = useRef(null)

  const queryClient = useQueryClient()
  const actualizarSaldo = useMutation(
    async function () {
      await setCompanySaldo(userState!.user!, saldoState!)
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries('StockCompany')
        queryClient.invalidateQueries('StockGeneral')
      },
    },
  )

  async function setSaldo() {
    saldoState!.Quantity = cantidadSaldo.current
    actualizarSaldo.mutate()
    cerrar()
  }

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        cerrar()
      }
    })
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          cerrar()
        }
      })
    }
  }, [])

  return (
    <Box direction='row' width='100%' align='start' crossAlign='start'>
      <Item ratio={0} baseSize={'50%'}>
        <Popup
          visible={true}
          hideOnOutsideClick={false}
          showCloseButton={false}
          height='40%'
          width='36%'
          title={'Actualizar Saldo'}
        >
          <ToolbarItem
            widget='dxButton'
            location='after'
            options={{
              icon: 'close',
              onClick: () => cerrar(),
            }}
          />
          <ScrollView>
            <React.Fragment>
              <Box align='center' crossAlign='center'>
                <Item ratio='1'>
                  <Form
                    formData={saldoState}
                    validationGroup='customerData'
                    ref={formref}
                  >
                    <GroupItem colCount='1'>
                      <SimpleItem
                        editorType={'dxTextBox'}
                        label={{ text: 'Empresa' }}
                        editorOptions={{
                          mode: 'text',
                          readOnly: true,
                          hint: 'Nombre de la empresa',
                          value: stockEmpresa.companyName,
                        }}
                      ></SimpleItem>
                    </GroupItem>
                    <GroupItem></GroupItem>
                    <GroupItem></GroupItem>
                    <GroupItem></GroupItem>
                    <GroupItem colCount={2}>
                      <SimpleItem>
                        <RadioGroup
                          items={[
                            { id: 0, text: 'Incrementar Saldo' },
                            { id: 1, text: 'Restar Saldo' },
                          ]}
                          layout='horizontal'
                          onValueChanged={(e: any) => {
                            aumentarReducirSaldo.current = e.value.id
                            formref!
                              .current!.instance.getEditor('Quantity')
                              .option('disabled', false)
                          }}
                        />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={3}>
                      <SimpleItem></SimpleItem>
                      <SimpleItem
                        dataField={'Quantity'}
                        editorType={'dxNumberBox'}
                        label={{ text: 'Cantidad' }}
                        editorOptions={{
                          mode: 'number',
                          value: 0,
                          disabled: true,
                          onValueChanged: (e: any) => {
                            if (aumentarReducirSaldo.current === 0) {
                              cantidadSaldo.current = Math.abs(Number(e.value))
                            } else {
                              cantidadSaldo.current =
                                Math.abs(Number(e.value)) * -1
                            }
                          },
                        }}
                      >
                        <AsyncRule
                          message='No puedes restar una cantidad mayor que el saldo que dispones'
                          validationCallback={(e: any) => {
                            return RestarSaldo(
                              e.value,
                              stockEmpresa.stock!,
                              aumentarReducirSaldo.current,
                            )
                          }}
                        />
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={1}>
                      <FooterSaldoSection
                        actualizar={setSaldo}
                      ></FooterSaldoSection>
                    </GroupItem>
                  </Form>
                </Item>
              </Box>
            </React.Fragment>
          </ScrollView>
        </Popup>
      </Item>
    </Box>
  )
}
