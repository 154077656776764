export const fields = [
  {
    IdPlantilla: 28,
    IdCampo: 1,
    Nombre: 'IdEmpresa',
    Visible: true,
    Orden: 1,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 2,
    Nombre: 'SerieExpediente',
    Visible: true,
    Orden: 3,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 3,
    Nombre: 'NumeroExpediente',
    Visible: true,
    Orden: 4,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 4,
    Nombre: 'FechaInicio',
    Visible: true,
    Orden: 5,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 5,
    Nombre: 'Descripcion',
    Visible: true,
    Orden: 6,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 6,
    Nombre: 'Comentario',
    Visible: true,
    Orden: 7,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 7,
    Nombre: 'Referencia',
    Visible: true,
    Orden: 8,
    Ancho: 20,
  },
  {
    IdPlantilla: 28,
    IdCampo: 8,
    Nombre: 'TotalAnticipos',
    Visible: true,
    Orden: 9,
    Ancho: 20,
  },
]
