// Importación de componentes y utilidades desde devextreme-react y otros módulos
import { Box, Item } from 'devextreme-react/box';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ExpedientFieldModel } from '../../models/ExpedienteFieldModels';
import { useQuery, useQueryClient } from 'react-query';
import { getDocumentTypeFieldRelations } from '../../services/fieldsServices';
import { RelationsField } from '../../models/IRelationsField';
import { TableRelationsDocumentTypeSection } from './TableRelationsDocumentTypeSection';
import { useDispatch, useSelector } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';
import { RootState } from 'src/redux/combineReducers';
import { NotifyType, showToast } from 'src/utils/sharedUitls';

// Interfaz de propiedades del componente
interface Props {
  fields: Array<String>;
  availableFields: Array<ExpedientFieldModel>;
  idDocumentType: number;
  documentName: String;
}

// Definición del componente principal
function FieldsSection({
  fields,
  availableFields,
  idDocumentType,
  documentName,
}: Props) {

  // Obtener el estado del cargador desde el almacenamiento global
  const loaderState = useSelector(
    (state: RootState) => state.config.visibleLoader,
  )
  // Dispatcher para acciones redux
  const dispatch = useDispatch();
  
  // Estado local para indicar si se debe realizar una actualización
  const [send, setSend] = useState(false);

  // Estado para gestionar el ancho de los campos
  const [widthFields, setWidthFields]: any = useState(800);

  // Estado para almacenar campos filtrados sin relaciones
  const [filteredFields, setFilteredFields] = useState(Array<String>());

  // Estado para gestionar el formulario de texto personalizado
  const [formCustomText, setFormCustomText] = useState(false);

  // Estado para gestionar el formulario de asociación de etiquetas
  const [formAsociacionEtiquetas, setFormAssociationLabels] = useState(true);

  // Estado para almacenar el ID del campo actualmente seleccionado
  const [idfield, setIdField] = useState(-1);

  // Instancia del cliente de consulta
  const queryClient = useQueryClient();

  // Estado para almacenar las relaciones de campo
  const [relationsField, setRelationsField] = useState(
    Array<RelationsField>(),
  );

  // Referencia mutable para el área de texto (no parece usarse)
  const textAreaRef: MutableRefObject<any> = useRef(null);

  // Lista de etiquetas (no se utiliza)
  const [labelList, setLabelList] = useState(Array<string>());

 
  // Consulta para obtener las relaciones de campos asociadas con un tipo de documento
  useQuery(['getFieldDocumentType', idDocumentType], 
    getDocumentTypeFieldRelations, {
      onSuccess: (data: RelationsField[]) => {
        if (data  != null ) {
          setRelationsField(data);
        }
        dispatch(addShowLoader(false));
      },
      refetchOnWindowFocus: false,
  });

  // Función para cambiar el ancho de los campos en función del ancho de la ventana
  function changeWidthFields() {
    if (window.innerWidth > 880) {
      setWidthFields(800);
    } else {
      setWidthFields('auto');
    }
  }

  // Efecto secundario para gestionar eventos y actualizaciones
  useEffect(() => {
    // Agregar un event listener para el evento resize
    window.addEventListener('resize', changeWidthFields)
    
    // Filtrar campos disponibles sin relaciones
    if (fields.length > 0) {
      let filteredFields: String[] = [];
      fields.forEach((field) => {
        if (
          relationsField.find(
            (fieldsRelation) => fieldsRelation.documentLabel === field,
          ) === undefined
        ) {
          filteredFields.push(field);
        }
      });
      setFilteredFields(filteredFields);
    }

    // Gestionar el estado del cargador global
    if (loaderState) {
      dispatch(addShowLoader(false));
    }

    // Cleanup: Remover el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', changeWidthFields);
    }
  }, [fields, availableFields, relationsField, idDocumentType]);

  // Renderizado del componente
  return (
    <>
      {/* Contenedor principal del componente */}
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        height={40}
        id='boxcampos'
      >
        <Item ratio={0} baseSize={'auto'}></Item>
      </Box>

      {/* Renderizar el componente TableRelationsDocumentTypeSection con las propiedades necesarias */}
      <TableRelationsDocumentTypeSection
        documentName={documentName}
        idDocumentType={idDocumentType}
        widthFields={widthFields}
        fields={fields}
        availableFields={availableFields}
        filteredFields={filteredFields}
        setLabelList={setLabelList}
        labelList={labelList}
        relationsFields={relationsField}
        formsLabels={formCustomText}
        formCustomText={formCustomText}
      ></TableRelationsDocumentTypeSection>
    </>
  );
};

// Exportar el componente
export { FieldsSection };
