import {
  ADD_SIZE,
  FooterSizeActionsTypes,
  FooterSizeInitialState,
} from '../types/FooterSizeChangeTypes'

const FooterSizeState: FooterSizeInitialState = {
  size: `${window.innerHeight - 90}px`,
}

export const FooterSizeReducer = (
  state = FooterSizeState,
  actions: FooterSizeActionsTypes,
): FooterSizeInitialState => {
  switch (actions.type) {
    case ADD_SIZE: {
      return { ...state, size: actions.payload }
    }

    default:
      return state
  }
}
