import { LinkModel } from 'src/models/company/linkModel';

export interface LinkState {
  links: Partial<LinkModel[]> | LinkModel[] | undefined;
  parentLinks: Partial<LinkModel[]> | LinkModel[] | undefined;
}

export const ADD_LINKS = 'ADD_ENLACES';
export const ADD_PARENT_LINKS = 'ADD_PARENT_ENLACES';
export const CLEA_LINKS = '@todo/CLEAR_ENLACE';

interface AddLinksRequest {
  type: typeof ADD_LINKS;
  payload: Partial<LinkModel[]> | LinkModel[] | undefined;
}

interface AddParentLinksRequest {
  type: typeof ADD_PARENT_LINKS;
  payload: Partial<LinkModel[]> | LinkModel[] | undefined;
}

export type LinkActionsTypes = AddLinksRequest | AddParentLinksRequest;
