import { Box, Form } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { _getRolePermissions } from '../services/permissionsService'
import { RolePermissionsModel } from '../models/rolePermissionsModel'
import { ModulesPermissionsSection } from 'src/pages/permissions/permissionsSectionsModules/ModulesPermissionsSection'
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'
import { addSize } from 'src/layouts/footer/redux/footerSizeChange/actions/FooterSizeChangeActions'

function PermissionsRolePage() {
  const dispatch = useDispatch()
  const [rolesPermissions, setRolesPermissions] = useState(
    Array<RolePermissionsModel>,
  )
  const [idRole, setIdRole] = useState(0)

  const [widthPage, setWidthPage] = useState(1887)

  useQuery(['getRolsPermisos'], _getRolePermissions, {
    onSuccess: (data: Array<RolePermissionsModel> | []) => {
      setRolesPermissions(data)
    },
    refetchOnWindowFocus: false,
  })

  function cambioWidthVentana() {
    setWidthPage(window.innerWidth)
  }

  useEffect(() => {
    window.onresize = cambioWidthVentana
  }, [rolesPermissions])

  return (
    <div id='container-body'>
      <h2>Permisos por rol</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          direction='row'
          align='center'
          crossAlign='center'
          width={'100%'}
          height={80}
        >
          <Item ratio={0} baseSize={idRole === 0 ? 293 : 200}>
            <Form labelLocation='left'>
              <GroupItem colCount={1}>
                <SimpleItem
                  label={{ text: 'Rol' }}
                  editorType='dxSelectBox'
                  editorOptions={{
                    hint: 'Rol',
                    onValueChanged: (e: any) => {
                      if (e.event) {
                        dispatch(addShowLoader(true))

                        dispatch(addSize('auto'))
                        if (e.value !== undefined && e.value  != null ) {
                          setIdRole(e.value)
                        }
                      }
                    },
                    dataSource: rolesPermissions,
                    displayExpr: 'Name',
                    valueExpr: 'Id',
                    value: idRole,
                  }}
                ></SimpleItem>
              </GroupItem>
            </Form>
          </Item>
        </Box>
        <Box direction='row' align='center' crossAlign='center' width={'100%'}>
          <Item ratio={0} baseSize={'auto'}>
            {idRole !== 0 && (
              <ModulesPermissionsSection
                id={idRole}
              ></ModulesPermissionsSection>
            )}
          </Item>
        </Box>
      </div>
    </div>
  )
}

export { PermissionsRolePage }
