import {
  ADD_CALCULATOR_TRANSFERS,
  CalculatorTransfersActionsTypes,
  CalculatorTransfersState,
  CLEAR_CALCULATOR_TRANSFERS,
} from './ICalculatorTransfersType'

const initialState: CalculatorTransfersState = {
  calculatorTransfers: undefined,
}

const calculatorTransfersReducer = (
  state = initialState,
  action: CalculatorTransfersActionsTypes,
) : CalculatorTransfersState | undefined => {
  switch (action.type) {
    case ADD_CALCULATOR_TRANSFERS: {
      return { ...state, calculatorTransfers: action.payload }
    }
    case CLEAR_CALCULATOR_TRANSFERS: {
      return { ...state, calculatorTransfers: action.payload }
    }
    default:
      return state
  }
}

export { calculatorTransfersReducer };
