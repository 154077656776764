import { JsonObject, JsonProperty } from 'json2typescript'
/* import { SolicitudListModel } from './solicitudListModel' */

@JsonObject('ColumnModelSolicitudesCore')
class ColumnModelSolicitudesCore {
  @JsonProperty('Nombre', String)
  Nombre: string = ''
  @JsonProperty('Tipo', String)
  Tipo: string = ''
  @JsonProperty('Size', Number)
  Size: number = 0
  @JsonProperty('Orden', Number)
  Orden: number = 0
  @JsonProperty('Texto', String)
  Texto: string = ''
}

@JsonObject('SolicitudListCampoDatoModel')
export class SolicitudListCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelSolicitudesCore] || [])
  Campos: ColumnModelSolicitudesCore[] = []
  /*   @JsonProperty('Datos', [SolicitudListModel] || [])
  Datos: SolicitudListModel[] = [] */
}
