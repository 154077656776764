import { RoleModel } from 'src/models/usuariosPage/roleModel';
import { UsuarioCampoDatoModel } from 'src/models/usuariosPage/usuarioCampoDatoModel';
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel';
import {
  ADD_ROLES,
  ADD_USUARIO,
  ADD_USUARIOS_LIST,
  CLEAR_USUARIO,
  SHOW_FORM,
  UsuariosActionsTypes,
} from './usuariosType';

export const addUsuariosList = (
  usuarios: UsuarioCampoDatoModel,
): UsuariosActionsTypes => ({
  type: ADD_USUARIOS_LIST,
  payload: usuarios,
})

export const addUsuario = (usuario: UsuarioModel): UsuariosActionsTypes => ({
  type: ADD_USUARIO,
  payload: usuario,
})

export const clearUsuario = (): UsuariosActionsTypes => ({
  type: CLEAR_USUARIO,
})

export const addRoles = (roles: RoleModel[]): UsuariosActionsTypes => ({
  type: ADD_ROLES,
  payload: roles,
})

export const showForm = (show: boolean): UsuariosActionsTypes => ({
  type: SHOW_FORM,
  payload: show,
})
