import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UsuarioModel')
class UsuarioModel {
  @JsonProperty('Id', Number, 0)
  Id: number = 0;

  @JsonProperty('NombreUsuario', String)
  NombreUsuario: string = '';

  @JsonProperty('Password', String)
  Password: string = '';

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('Apellidos', String)
  Apellidos: string = '';

  @JsonProperty('RutaPrincipal', String)
  RutaPrincipal: string = ''

  @JsonProperty('IdentificationNumber', String)
  IdentificationNumber: string = '';

  @JsonProperty('Telefono', String)
  Telefono: string = '';

  @JsonProperty('Email', String)
  Email: string = '';

  @JsonProperty('Enabled', Boolean)
  Enabled: boolean = false;

  @JsonProperty('IdSujeto', Number)
  IdSujeto: number = 0;

  @JsonProperty('SujetoIdentification', String)
  SujetoIdentification: string = '';

  @JsonProperty('IdTipoUsuario', Number)
  IdTipoUsuario: number = 0;


  @JsonProperty('RolesTipoCuenta', [Number])
  RolesTipoCuenta: number[] = [];

  @JsonProperty('IdPerfil', Number, 0)
  IdPerfil?: number = 0;

  @JsonProperty('AllowedChangePassword', Boolean)
  AllowedChangePassword: boolean = false;

  @JsonProperty('IdIdioma', Number)
  IdIdioma: number = 0;

  @JsonProperty('FechaUltimoAcceso', String)
  FechaUltimoAcceso: string = '';

  @JsonProperty('IsParentSucursal', Boolean)
  IsParentSucursal: boolean = false;

  @JsonProperty('SucursalesIds', [Number])
  SucursalesIds: number[] = [];

  @JsonProperty('IdGrupoPlantillaCustom', Number)
  IdGrupoPlantillaCustom: number = 0;
};

export { UsuarioModel };
