import React, { useEffect, useState } from 'react'
import { Box, Item } from 'devextreme-react/box'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'

// ----------------------------------------------------------------------------

function Footer() {
  const [legal, setLegal] = useState()
  const [cookies, setCookies] = useState()
  const [RGPD, setRGPD] = useState()
  const [sizefooter, setsizefooter] = useState()
  const size = useSelector((state: RootState) => state.footerSize.size)

  let EnlaceLegal: any
  let EnlacePrivcacidadCookies: any
  let EnlaceRGPD: any

  const exitStorage = localStorage.getItem('parentEnlaces')
    ? localStorage.getItem('parentEnlaces')
    : null
  if (exitStorage  != null ) {
    EnlaceLegal = JSON.parse(localStorage.getItem('parentEnlaces')!)[0]
    EnlacePrivcacidadCookies = JSON.parse(
      localStorage.getItem('parentEnlaces')!,
    )[1]
    EnlaceRGPD = JSON.parse(localStorage.getItem('parentEnlaces')!)[2]
  } else {
    EnlaceLegal = ''
    EnlacePrivcacidadCookies = ''
    EnlaceRGPD = ''
  }

  useEffect(() => {
    setLegal(EnlaceLegal !== undefined ? EnlaceLegal.EnlaceValue : '')
    setCookies(
      EnlacePrivcacidadCookies !== undefined
        ? EnlacePrivcacidadCookies.EnlaceValue
        : '',
    )
    setRGPD(EnlaceRGPD !== undefined ? EnlaceRGPD.EnlaceValue : '')
  }, [EnlaceLegal, EnlacePrivcacidadCookies, EnlaceRGPD])
  // Solo se vuelve a ejecutar si count cambia

  return (
    <footer>
      <Box
        height='100%'
        crossAlign='center'
        style={{ textAlign: 'center', alignItems: 'center', paddingTop: '2rem' }}
      >
        <Item ratio={1}>
          <a href={cookies} target='__blank'>
            Política de privacidad y cookies
          </a>
        </Item>
        <Item ratio={1}>
          <a href={legal} target='__blank'>
            Legal
          </a>
        </Item>
        <Item ratio={1}>
          <a href={RGPD} target='__blank'>
            Reglamento general de protección de datos
          </a>
        </Item>
      </Box>
    </footer>
  )
}

export { Footer }
