import {
  ADD_LOGS_COMPANY,
  LogActionsTypes,
  LogState,
} from '../types/LogsActionTypes'

const logState: LogState = {
  logcompanies: undefined,
}

export const LogsReducer = (
  state = logState,
  action: LogActionsTypes,
): LogState => {
  switch (action.type) {
    case ADD_LOGS_COMPANY: {
      return { ...state, logcompanies: action.payload }
    }

    default:
      return state
  }
}
