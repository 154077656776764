import { NotifyType, showToast } from 'src/utils/sharedUitls';
import { DocumentTypeGridModel } from '../models/documentTypeGridModel';
import firmaDigitalAxios from 'src/utils/firmadigitalaxios';
import { DocumentTypeFormModel } from '../models/documentTypeModelsForm';

export async function getDocumentType(): Promise<Array<DocumentTypeGridModel> | []> {
  let documentos: Array<DocumentTypeGridModel> | [] = [];
  try {
    const response = await firmaDigitalAxios.get('DocumentTypes');
    documentos = response.data;
  } catch (error) {
    showToast(
      'No se han podido obtener los documentos.',
      NotifyType.error,
      5000,
    );
  }
  return documentos;
}


export async function postDocumentType(document: DocumentTypeFormModel) {
  await firmaDigitalAxios
    .post('DocumentTypes', document)
    .then(() => {
      showToast('Tipo de documento Creado', NotifyType.success, 5000)
    })
    .catch((error: any) => {
      showToast(
        'No se han podido crear el tipo de documento.',
        NotifyType.error,
        5000,
      )
    })
}

export async function putDocumentType(document: DocumentTypeFormModel) {
  await firmaDigitalAxios
    .put('DocumentTypes', document)
    .then(() => {
      showToast('Tipo de documento editado', NotifyType.success, 5000)
    })
    .catch((error: any) => {
      showToast(
        'No se han podido editar el tipo de documento.',
        NotifyType.error,
        5000,
      )
    })
}

export async function deleteDocumentType(id: number) {
  try {
    await firmaDigitalAxios.delete(`DocumentTypes/${id}`);
    showToast('Tipo de documento eliminado', NotifyType.success, 5000);
  } catch (error) {
    showToast('No se han podido eliminar el tipo de documento.', NotifyType.error, 5000);
    throw error;
  }
}