export function formatoFecha(formdate: string): Date {
  let fecha = Date.parse(formdate);
  return new Date(fecha);
};

export function dateToFormatDate(fecha: string): string {
  let fechaalReves = fecha.split('/');
  return `${fechaalReves[2]}-${fechaalReves[1]}-${fechaalReves[0]}`;
};

export function diferenciaAñosFechas(hoy: any, Nacimiento: any): number {
  let añosNacimientos: any = new Date(Nacimiento);
  let resultado = Math.ceil(
    Math.abs(hoy - añosNacimientos) / (1000 * 60 * 60 * 24 * 365),
  );
  return resultado;
};

export function formdataToCreate(fecha: string): string {
  let fechaalReves = fecha.split('/');
  return `${fechaalReves[2]}/${fechaalReves[1]}/${fechaalReves[0]}`;
};
