import { ADD_REQUEST, ADD_REQUESTS, CLEAR_REQUEST, 
  RequestActionsTypes, RequestState } from './IRequestState';

const initialState: RequestState = {
  request: undefined,
  requests: undefined,
};

const requestsReducer = (
  state = initialState,
  action: RequestActionsTypes,
): RequestState | undefined => {
  switch (action.type) {
    case ADD_REQUESTS: {
      return { ...state, requests: action.payload };
    };
    case ADD_REQUEST: {
      return { ...state, request: action.payload };
    };
    case CLEAR_REQUEST: {
      return { ...state, request: action.payload };
    };
    default:
      return state;
  };
};

export { requestsReducer };