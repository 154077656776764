import { SucursalModel } from 'src/models/sucursal/sucursalModel'

export interface SucursalState {
  sucursales: SucursalModel[]
}

export const ADD_SUCURSALES = 'sucursal@ADD_SUCURSALES'

interface AddSucursalesRequest {
  type: typeof ADD_SUCURSALES
  payload: SucursalModel[]
}

export type SucursalActionsTypes = AddSucursalesRequest
