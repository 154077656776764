import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CompanyModuloModel')
class CompanyModuloModel {
  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0;

  @JsonProperty('IdModulo', Number)
  IdModulo: number | null = null;

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('Code', String)
  Code: string = '';
};

export { CompanyModuloModel };