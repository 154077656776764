import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('UserModel')
class UserModel {
  @JsonProperty('Expedientes', [String]) // TODO cambiar cuando se reciba como vehiculos
  Expedientes: [] = []

  @JsonProperty('Administracion', [String])
  Administracion: [] = []

  @JsonProperty('Calculadora', [String])
  Calculadora: [] = []

  @JsonProperty('ExpedientesEscrituras', [String])
  ExpedientesEscrituras: [] = []
  
  @JsonProperty('ExpedientesVehiculos', [String])
  ExpedientesVehiculos: [] = []

  @JsonProperty('Microdatos', [String])
  Microdatos: [] = []

  @JsonProperty('DatosDeEmpresas', [String])
  DatosDeEmpresas: [] = []
  
  @JsonProperty('RGPD', [String])
  RGPD: [] = []
  
  @JsonProperty('InformesVehiculos', [String])
  InformesVehiculos: [] = []
  
  @JsonProperty('FirmaDigital', [String])
  FirmaDigital: [] = []
  
  @JsonProperty('iDocCar', [String])
  iDocCar: [] = []
  
  @JsonProperty('Token', String)
  Token: string = ''

  @JsonProperty('UserId', String)
  UserId: string = ''

  @JsonProperty('CompanyId', String)
  CompanyId: string = ''

  @JsonProperty('SujetoId', String)
  SujetoId: string = ''

  @JsonProperty('UserName', String)
  UserName: string = ''

  @JsonProperty('CodigoCalculadora', String)
  CodigoCalculadora: string = ''

  @JsonProperty('role', String)
  role: string = ''
  user: UserModel | undefined

  @JsonProperty('RutaPrincipal', String)
  RutaPrincipal: string = ''
}

export { UserModel }
