import { Box, Button, Form, RadioGroup } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import React, { MutableRefObject, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'

interface Props {
  actualizarbusqueda: Function
}
export default function BuscadorFormSection({ actualizarbusqueda }: Props) {
  const dispatch = useDispatch()
  const formref: MutableRefObject<any> = useRef(null)
  const busqueda = useRef(null)
  const [filtroBusqueda, setfiltroBusqueda] = useState({ id: -1, text: '' })
  let realizarbusqueda = false
  const filtrados = [
    { id: 0, text: 'NIF' },
    // { id: 1, text: 'Nombre' },
  ]

  return (
    <Form validationGroup='customerData' ref={formref}>
      <GroupItem>
        <SimpleItem>
          <Box
            direction='row'
            align='center'
            width={'100%'}
            crossAlign='center'
          >
            <Item ratio={0} baseSize={300}>
              <RadioGroup
                items={filtrados}
                layout='horizontal'
                value={filtrados[0]}
                // onValueChanged={(e: any) => {
                //   if (e.value  != null ) {
                //     filtroBusqueda!.id = e.value.id
                //     filtroBusqueda!.text = e.value.text
                //     formref!
                //       .current!.instance.getEditor('textoFiltrador')
                //       .option('placeholder', `Busqueda por ${e.value.text}`)

                //     formref!
                //       .current!.instance.getEditor('textoFiltrador')
                //       .option('disabled', false)
                //   }
                // }}
              />
            </Item>
          </Box>
        </SimpleItem>
      </GroupItem>

      <GroupItem colCount={1}>
        <SimpleItem
          dataField={'textoFiltrador'}
          label={{ text: 'Buscador' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre/NIF a buscar',
            placeholder: 'Busqueda',
            onValueChanged: (e: any) => {
              busqueda.current = e.value
              if (e.prevValue !== e.value) {
                realizarbusqueda = true
              } else {
                realizarbusqueda = false
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>

      <GroupItem>
        <SimpleItem>
          <Box direction='row' align='end' width={'100%'} crossAlign='end'>
            <Item ratio={0} baseSize={100}>
              <Button
                text={'Buscar'}
                type='default'
                onClick={(e: any) => {
                  e.event.preventDefault()
                  if (realizarbusqueda) {
                    dispatch(addShowLoader(true))
                    setfiltroBusqueda(filtroBusqueda)
                    actualizarbusqueda(busqueda.current)
                  }
                }}
              />
            </Item>
          </Box>
        </SimpleItem>
      </GroupItem>
    </Form>
  )
}
