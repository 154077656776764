import firmaDigitalAxios from 'src/utils/firmadigitalaxios'
import { StateHistorySignedDataModel } from '../models/stateHistorySignedDataModel'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import { isAdmin, isEmployeeUser } from 'src/utils/allowAuthUtil'
import { UserModel } from 'src/models/usuario/userModel'
import { EstadoPeticionModel } from '../models/estadoPeticionesModel'
import { NotificacionSolicitudModel } from '../models/notificacionSolicitudModel'

export async function getListHistorySigned(): Promise<
  Array<StateHistorySignedDataModel> | []
> {
  let peticiones: Array<StateHistorySignedDataModel> | [] = []

  await firmaDigitalAxios
    .get(`SignRequests`)
    .then((response: any) => {
      peticiones = response.data
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener las peticiones.' + error,
        NotifyType.error,
        5000,
      )
    })

  return peticiones
}

export async function getStateHistorySigned(
  id: number,
): Promise<Array<EstadoPeticionModel> | []> {
  let historico: Array<EstadoPeticionModel> | [] = []

  await firmaDigitalAxios
    .get(`SignRequests/${id}/Status`)
    .then((response: any) => {
      historico = response.data
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener el histórico.' + error,
        NotifyType.error,
        5000,
      )
    })

  return historico
}

export async function getStateHistorySignedByExpedienteId({
  queryKey,
}: any): Promise<Array<StateHistorySignedDataModel> | []> {
  let historicoExpediente: Array<StateHistorySignedDataModel> | [] = []

  if (
    (IsModuloAllowed(queryKey[1], Modules.FIRMADIGITAL) &&
      queryKey[2]  != null  &&
      isAdmin(queryKey[1])) ||
    isEmployeeUser(queryKey[1])
  ) {
    await firmaDigitalAxios
      .get(`SignRequests/Expediente/${queryKey[2]}`)
      .then((response: any) => {
        historicoExpediente = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener el estado de la firma del expediente.' +
            error,
          NotifyType.error,
          5000,
        )
      })
  }

  return historicoExpediente
}

export async function deleteStateHistorySignedByExpedienteId(
  user: UserModel,
  id: number,
): Promise<Boolean> {
  let cancelarFirma: boolean = false

  if (
    (IsModuloAllowed(user, Modules.FIRMADIGITAL) &&
      id  != null  &&
      isAdmin(user)) ||
    isEmployeeUser(user)
  ) {
    await firmaDigitalAxios
      .delete(`SignRequests/${id}`)
      .then((response: any) => {
        cancelarFirma = true
      })
      .catch((error: any) => {
        showToast(
          'No se han podido cancelar la firma.' + error,
          NotifyType.error,
          5000,
        )
      })
  }

  return cancelarFirma
}

export async function getNotificacionesSolicitud({
  queryKey,
}: any): Promise<Array<NotificacionSolicitudModel> | []> {
  let listaNotificaciones: Array<NotificacionSolicitudModel> | [] = []

  await firmaDigitalAxios
    .get(`SignRequests/${queryKey[1]}/Notifications`)
    .then((response: any) => {
      listaNotificaciones = response.data
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener las notificaciones' + error,
        NotifyType.error,
        5000,
      )
    })

  return listaNotificaciones
}

export async function postNotificacionesSolicitud({
  queryKey,
}: any): Promise<boolean> {
  let listaNotificaciones: boolean = false

  if (queryKey[3]) {
    await firmaDigitalAxios
      .post(`SignRequests/${queryKey[1]}/Notifications`, queryKey[2])
      .then((response: any) => {
        listaNotificaciones = true
        showToast(
          'Reenvio de la notifcacion realizado correctamente',
          NotifyType.success,
          5000,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido relizar el reenvio de la notifcacion' + error,
          NotifyType.error,
          5000,
        )
      })
  }

  return listaNotificaciones
}
