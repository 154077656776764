import React, { useCallback, useEffect, useRef, useState } from 'react'
// devextreme
import { Button, Drawer, LoadPanel } from 'devextreme-react'
import { Template } from 'devextreme-react/core/template'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { Footer } from 'src/layouts/footer/FooterNew'
import { Header } from 'src/layouts/header/HeaderNew'
import { Menu } from './menu/Menu'
import { useScreenSize } from '../utils/media-query'
import { useDispatch } from 'react-redux'
import { addSize } from './footer/redux/footerSizeChange/actions/FooterSizeChangeActions'

// ----------------------------------------------------------------------------
const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
}

// ----------------------------------------------------------------------------

function Layout({ children }: { children: React.ReactNode }) {
  const { visibleLoader } = useSelector((state: RootState) => state.config)
  const { isXSmall, isLarge } = useScreenSize()
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Opened)
  const [separacionsub1, setSeparacionSub1] = useState(1)
  const [separacionsub2, setSeparacionSub2] = useState(1.6)
  const [widthsubmenu, setWidthSubmenu] = useState(220)
  const [fijo, setFijo] = useState(false)
  const height = useSelector((state: RootState) => state.footerSize.size)
  const dispatch = useDispatch()
  function anclarMenu() {
    setFijo(!fijo)
  }

  function cambiarWidthSubmenu(espacio: number) {
    setWidthSubmenu(espacio)
  }

  function editarPositionSubMenu() {
    setSeparacionSub1(0)
    setSeparacionSub2(0)
  }

  const defectoPositionSubMenu = useCallback(() => {
    setSeparacionSub1(1)
    setSeparacionSub2(1.6)
  }, [])

  const toggleMenu = useCallback(({ event }: any) => {
    // setMenuStatus((prevMenuStatus) =>
    //   prevMenuStatus === MenuStatus.Closed
    //     ? MenuStatus.Opened
    //     : MenuStatus.Closed,
    // )
    event.stopPropagation()
  }, [])

  const temporaryOpenMenu = useCallback(() => {
    // setMenuStatus((prevMenuStatus) =>
    //   prevMenuStatus === MenuStatus.Closed
    //     ? MenuStatus.TemporaryOpened
    //     : prevMenuStatus,
    // )
  }, [])

  const temporaryClosedMenu = useCallback(() => {
    // setMenuStatus(MenuStatus.Closed)
  }, [])

  const onOutsideClick = useCallback(() => {
    // setMenuStatus((prevMenuStatus) =>
    //   prevMenuStatus !== MenuStatus.Closed && !isLarge
    //     ? MenuStatus.Closed
    //     : prevMenuStatus,
    // )
    return isLarge
  }, [isLarge])

  useEffect(() => {
    dispatch(
      addSize(
        document.getElementById('container-body')?.clientHeight! < 912
          ? `${window.innerHeight}px`
          : 'auto',
      ),
    )
  }, [document.getElementById('container-body')?.clientHeight])

  const [drawerClass, setDrawerClass] = useState('drawer-closed')

  const openMenu = useCallback(() => {
    setMenuStatus(MenuStatus.Opened)
    setDrawerClass('drawer-opened')
  }, [])

  const closeMenu = useCallback(() => {
    setMenuStatus(MenuStatus.Closed)
    setDrawerClass('drawer-closed')
  }, [])

  return (
    <Drawer
      id={'drawerApp'}
      className={`menu-component ${drawerClass}`}
      activeStateEnabled
      closeOnOutsideClick={onOutsideClick}
      maxSize={widthsubmenu}
      minSize={isXSmall ? 0 : 72}
      opened={menuStatus !== MenuStatus.Closed}
      openedStateMode={isLarge ? 'shrink' : 'push'}
      revealMode={isXSmall ? 'slide' : 'expand'}
      shading={!isLarge}
      template={'menu'}
      animationDuration={500}
    >
      <Template name={'menu'}>
        <Menu
          closeMenu={temporaryClosedMenu}
          compactMode={menuStatus === MenuStatus.Closed}
          menuStatus={menuStatus !== MenuStatus.Closed}
          openMenu={temporaryOpenMenu}
          sub1={separacionsub1}
          sub2={separacionsub2}
          editarsubmenu={editarPositionSubMenu}
          defecto={defectoPositionSubMenu}
          espacio={cambiarWidthSubmenu}
          fijo={fijo}
        >
          <Toolbar id={'navigation-header'}>
            <Item location={'before'}>
              <Button
                icon='chevrondoubleright'
                visible={menuStatus !== MenuStatus.Opened}
                onClick={openMenu}
                style={{ marginLeft: '0.8vh' }}
              />
            </Item>

            <Item location={'after'}>
              <Button
                icon={'chevrondoubleleft'}
                visible={menuStatus === 1 ? false : true}
                onClick={closeMenu}
              />
            </Item>
          </Toolbar>
        </Menu>
      </Template>

      <Header toggleMenu={openMenu} />

      <div style={{ height: height }}>{children}</div>
      <Footer />

      <LoadPanel shadingColor='rgba(192,192,192,0.9)' visible={visibleLoader} />
    </Drawer>
  )
}

export { Layout }
