import { CalculatorTransferModel } from '../../../../../models/company/calculatorTransferModel';
import { ADD_CALCULATOR_TRANSFERS, CalculatorTransfersActionsTypes } from './ICalculatorTransfersType';

const addCalculatorTransfers = (
  calculadoraTransferencias:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
) : CalculatorTransfersActionsTypes => ({
  type: ADD_CALCULATOR_TRANSFERS,
  payload: calculadoraTransferencias,
});

export { 
  addCalculatorTransfers,
};