import { JsonObject, JsonProperty } from 'json2typescript'
import { DireccionModels as Direccion } from './direccionModels'

@JsonObject('SujetoModel')
export class SujetoModel {

    @JsonProperty('Nif', String, '')
    Nif: string = ''

    @JsonProperty('Name', String, '')
    Name: string = ''

    @JsonProperty('Surname1', String, '')
    Surname1: string = ''

    @JsonProperty('Surname2', String, '')
    Surname2: string = ''

    @JsonProperty('BirthDate', String, '')
    BirthDate: string = ''

    @JsonProperty('Genre', String, '')
    Genre: string = ''


    @JsonProperty('Address', Direccion)
    Address:Direccion = new Direccion()




}