import {
  CosteCampoDatoModel,
  DetalleCosteCampoDatoModel,
} from '../../models/costeModel'
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel'
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel'
import { AyuntamientoCampoDatoModel } from '../../models/escritura/ayuntamientoCampoDatoModel'
import { BienCampoDatoModel } from '../../models/escritura/bienCampoDatoModel'
import { BienesCampoDatoModel } from '../../models/escritura/bienesCampoDatoModel'
import { CatastroCampoDatoModel } from '../../models/escritura/catastroCampoDatoModel'
import { DefectoCampoDatoModel } from '../../models/escritura/defectoCampoDatoModel'
import { HaciendaCampoDatoModel } from '../../models/escritura/haciendaCampoDatoModel'
import { NotariaCampoDatoModel } from '../../models/escritura/notariaCampoDatoModel'
import { RegistroCampoDatoModel } from '../../models/escritura/registroCampoDatoModel'
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel'

export interface ExpEscrituraState {
  ayuntamiento: AyuntamientoCampoDatoModel
  bien: BienCampoDatoModel
  bienes: BienesCampoDatoModel
  catastro: CatastroCampoDatoModel
  costes: CosteCampoDatoModel
  defecto: DefectoCampoDatoModel
  defectos: DefectoCampoDatoModel
  detalleExpediente: DetailExpedientFieldDataModel
  detalleCoste: DetalleCosteCampoDatoModel
  documentos: DocumentoCampoDatoModel
  hacienda: HaciendaCampoDatoModel
  notaria: NotariaCampoDatoModel
  registro: RegistroCampoDatoModel
  registros: RegistroCampoDatoModel
  tareasPendientes: TareaPendienteCampoDatoModel
}

export const ADD_AYTO = '@escritura/ADD_AYTO'
export const ADD_BIEN = '@escritura/ADD_BIEN'
export const ADD_BIENES = '@escritura/ADD_BIENES'
export const ADD_CATASTRO = '@escritura/ADD_CATASTRO'
export const ADD_COSTES = '@escritura/ADD_COSTES'
export const ADD_DEFECTO = '@escritura/ADD_DEFECTO'
export const ADD_DEFECTOS = '@escritura/ADD_DEFECTOS'
export const ADD_DETALLE_EXPEDIENTE = '@escritura/ADD_DETALLE_EXPEDIENTE'
export const ADD_DETALLE_COSTE = '@escritura/ADD_DETALLE_COSTE'
export const ADD_DOCUMENTOS = '@escritura/ADD_DOCUMENTOS'
export const ADD_HACIENDA = '@escritura/ADD_HACIENDA'
export const ADD_NOTARIA = '@escritura/ADD_NOTARIA'
export const ADD_REGISTRO = '@escritura/ADD_REGISTRO'
export const ADD_REGISTROS = '@escritura/ADD_REGISTROS'
export const ADD_TAREAS_PENDIENTES = '@escritura/ADD_TAREAS_PENDIENTES'

interface AddDetalleExpedienteRequest {
  type: typeof ADD_DETALLE_EXPEDIENTE
  payload: DetailExpedientFieldDataModel
}

interface AddDetalleCosteRequest {
  type: typeof ADD_DETALLE_COSTE
  payload: DetalleCosteCampoDatoModel
}

interface AddBienesRequest {
  type: typeof ADD_BIENES
  payload: BienesCampoDatoModel
}

interface AddCostesRequest {
  type: typeof ADD_COSTES
  payload: CosteCampoDatoModel
}

interface AddTareasPendientesRequest {
  type: typeof ADD_TAREAS_PENDIENTES
  payload: TareaPendienteCampoDatoModel
}

interface AddDocumentosRequest {
  type: typeof ADD_DOCUMENTOS
  payload: DocumentoCampoDatoModel
}

interface AddBienRequest {
  type: typeof ADD_BIEN
  payload: BienCampoDatoModel
}

interface AddNotariaRequest {
  type: typeof ADD_NOTARIA
  payload: NotariaCampoDatoModel
}

interface AddHaciendaRequest {
  type: typeof ADD_HACIENDA
  payload: HaciendaCampoDatoModel
}

interface AddRegistroRequest {
  type: typeof ADD_REGISTRO
  payload: RegistroCampoDatoModel
}

interface AddRegistrosRequest {
  type: typeof ADD_REGISTROS
  payload: RegistroCampoDatoModel
}

interface AddDefectoRequest {
  type: typeof ADD_DEFECTO
  payload: DefectoCampoDatoModel
}

interface AddDefectosRequest {
  type: typeof ADD_DEFECTOS
  payload: DefectoCampoDatoModel
}

interface AddAyuntamientoRequest {
  type: typeof ADD_AYTO
  payload: AyuntamientoCampoDatoModel
}

interface AddCatastroRequest {
  type: typeof ADD_CATASTRO
  payload: CatastroCampoDatoModel
}

export type ExpEscrituraActionsTypes =
  | AddAyuntamientoRequest
  | AddBienesRequest
  | AddBienRequest
  | AddCatastroRequest
  | AddCostesRequest
  | AddDefectoRequest
  | AddDefectosRequest
  | AddDetalleCosteRequest
  | AddDetalleExpedienteRequest
  | AddDocumentosRequest
  | AddHaciendaRequest
  | AddNotariaRequest
  | AddRegistroRequest
  | AddRegistrosRequest
  | AddTareasPendientesRequest
