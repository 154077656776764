import { ProfileModel } from '../../../../models/perfilPage/profileModel';

import {
  SujetActionsTypes,
  ADD_PROFILE,
  ADD_PROFILES,
} from './IProfileType'

const addProfile = (
  profile: Partial<ProfileModel> | ProfileModel | undefined,
): SujetActionsTypes => ({
  type: ADD_PROFILE,
  payload: profile,
});

const clearProfile = (
  clearProfile: ProfileModel | undefined,
): SujetActionsTypes => ({
  type: ADD_PROFILE,
  payload: clearProfile,
});

const addProfiles = (
  profiles: ProfileModel[] | undefined,
): SujetActionsTypes => ({
  type: ADD_PROFILES,
  payload: profiles,
});

export { 
  addProfile,
  clearProfile,
  addProfiles
};