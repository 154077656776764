import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'devextreme-react'
import { SimpleItem } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSize } from '../../../../utils/media-query'
import {
  getIdocCarConfigList,
  postIdocCarConfig,
  putIdocCarConfig,
} from '../service/idocCarConfigService'
import { refreshGrid } from '../../../../utils/sharedUitls'
import { addIdocCarConfigAll } from '../redux/idocCarConfigActions'
import { ButtonsForm } from 'src/components/buttonsForm/ButtonsForm'

function IdocCarConfigFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const idocCarConfigState = useSelector(
    (state: RootState) => state.idocCarConfig,
  )
  const companyParent = useSelector(
    (state: RootState) => state.company?.parentCompany,
  )
  const {  isLarge } = useScreenSize()
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const { handleSubmit } = useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {}
    fetchData()
  })

  const onSubmit = async (data: any) => {
    const { Url, Username, Password } =
      formData.current as any
    let formDataParsed: JSON | null = null

    if (idocCarConfigState?.idocCarConfig?.Id) {
      formDataParsed = JSON.parse(
        JSON.stringify({
          Id: idocCarConfigState.idocCarConfig?.Id,
          IdCompany: companyParent?.Id,
          DossierOriginType: 2,
          Url: Url,
          Username: Username,
          Password: Password,
        }),
      )
      let solicitud = await putIdocCarConfig(userState!.user!, formDataParsed!)
      if (solicitud) {
        dispatch(
          addIdocCarConfigAll(await getIdocCarConfigList(userState!.user!)),
        )
      }
      refreshGrid(props.setShowFormIdocCarConfig, true)

      props.setShowFormIdocCarConfig(false)
    } else {
      let formDataParsed: JSON = JSON.parse(
        JSON.stringify({
          IdCompany: companyParent?.Id,
          DossierOriginType: 2,
          Url: Url,
          Username: Username,
          Password: Password,
        }),
      )

      const solicitud = await postIdocCarConfig(
        userState!.user!,
        formDataParsed!,
      )
      if (solicitud) {
        refreshGrid(props.setShowFormIdocCarConfig, true)
        dispatch(
          addIdocCarConfigAll(await getIdocCarConfigList(userState!.user!)),
        )
        props.setShowFormIdocCarConfig(false)
      }
    }
  }

  const handleCancelClick = () => {
    props.setShowFormIdocCarConfig(false)
    props.setIsCreatingForm(false)
    props.setIsEditingForm(false)
  }

  const renderButtons = () => {
    return ButtonsForm(`idocCarConfigData`, handleCancelClick, false)
  }

  return (
    <>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              formData={formData.current}
              disabled={loading}
              //ref={formSujetoRef}
              readOnly={
                props.isCreatingForm || props.isEditingForm ? false : true
              }
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='idocCarConfigData'
              showColonAfterLabel={true}
            >
              <SimpleItem
                dataField='Id'
                editorType={'dxNumberBox'}
                label={{ text: 'Registro' }}
                visible={false}
                editorOptions={{
                  value: idocCarConfigState.idocCarConfig?.Id
                    ? idocCarConfigState.idocCarConfig?.Id
                    : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField='IdCompany'
                editorType={'dxNumberBox'}
                label={{ text: 'Nº Compañía' }}
                visible={false}
                editorOptions={{
                  readOnly: true,
                  value: idocCarConfigState.idocCarConfig?.IdCompany
                    ? idocCarConfigState.idocCarConfig?.IdCompany
                    : null,
                }}
              ></SimpleItem>

              <SimpleItem
                dataField={'Url'}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'text',
                  value: idocCarConfigState.idocCarConfig?.Url
                    ? idocCarConfigState.idocCarConfig?.Url
                    : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'Username'}
                editorType={'dxTextBox'}
                label={{ text: 'Usuario' }}
                editorOptions={{
                  mode: 'text',
                  value: idocCarConfigState.idocCarConfig?.Username
                    ? idocCarConfigState.idocCarConfig?.Username
                    : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'Password'}
                label={{ text: 'Contraseña' }}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'text',
                  value: idocCarConfigState.idocCarConfig?.Password
                    ? idocCarConfigState.idocCarConfig?.Password
                    : null,
                }}
              ></SimpleItem>
              {props.isCreatingForm || props.isEditingForm ? (
                <SimpleItem render={renderButtons} cssClass='alignBottom' />
              ) : false}
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export default IdocCarConfigFormPage
