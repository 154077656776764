import { 
  ADD_SOLICITUDES_LIST, 
  ADD_SOLICITUD_LIST, 
  CLEAR_SOLICITUD_LIST, 
  SolicitudListActionsTypes, 
  SolicitudListState 
} from "./solicitudType";


const initialState: SolicitudListState = {
  solicitudList: undefined,
  solicitudesList: undefined,
};

export const solicitudListReducer = (
  state = initialState,
  action: SolicitudListActionsTypes,
): SolicitudListState => {
  switch (action.type) {
    case ADD_SOLICITUD_LIST: {
      return { ...state, solicitudList: action.payload };
    }
    case ADD_SOLICITUDES_LIST: {
      return { ...state, solicitudesList: action.payload };
    }
    case CLEAR_SOLICITUD_LIST: {
      return { ...state, solicitudList: action.payload };
    }
    default:
      return state;
  };
};
