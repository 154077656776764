export function getValidationRules(
  fieldName: string,
  value: any,
): string | null {
  let errorMessage: string | null = null

  if (value === '' || value === undefined || value == null ) {
    return errorMessage
  }
  //FECHAS
  if (fieldName.indexOf('dia') !== -1) {
    if (!/^[0-9]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta números'
    } else if (value.length > 2) {
      errorMessage = 'Este campo solo puede tener 2 caracteres'
    } else if (Number(value) > 31) {
      errorMessage = 'Este campo solo acepta números hasta 31'
    }
  } else if (fieldName.indexOf('mes') !== -1) {
    if (!/^[0-9]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta números'
    } else if (value.length > 2) {
      errorMessage = 'Este campo solo puede tener 2 caracteres'
    } else if (Number(value) > 12) {
      errorMessage = 'Este campo solo acepta números hasta 12'
    }
  } else if (fieldName.indexOf('año') !== -1) {
    if (!/^[0-9]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta números'
    } else if (value.length > 4) {
      errorMessage = 'Este campo solo puede tener 4 caracteres'
    }
  } else if (fieldName.indexOf('numero' && 'numero') !== -1) {
    if (!/^[0-9]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta números'
    }
  } else if (
    fieldName.includes('apellidos') ||
    fieldName.includes('representante') ||
    fieldName.includes('tipo')
  ) {
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta letras'
    }
  }

  //IDENTIFICACION
  if (
    ['nif', 'dni', 'nie', 'cif'].some(
      (keyword) => fieldName.indexOf(keyword) !== -1,
    )
  ) {
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
    const cifRexp = /^[ABCDEFGHJNPQRSUVW]{1}[0-9]{7}[0-9A-J]{1}$/i
    const str = value.toString().toUpperCase()

    if (!nifRexp.test(str) && !nieRexp.test(str) && !cifRexp.test(str)) {
      errorMessage = 'Formato no válido'
    } else {
      if (cifRexp.test(str)) {
        // Validación del CIF
        const match = str.match(cifRexp)
        if (match) {
          const letter = match[1]
          const digits = match[2]
          const control = match[3]

          if (letter && digits && control) {
            const evenSum = Array.from(digits.substr(0, 7), (v, i) =>
              i % 2 === 0 ? parseInt(v as string) : 0,
            ).reduce((a, b) => a + b, 0)
            const oddSum = Array.from(digits.substr(0, 7), (v, i) =>
              i % 2 !== 0 ? 2 * parseInt(v as string) : 0,
            )
              .map((v) => (v > 9 ? v - 9 : v))
              .reduce((a, b) => a + b, 0)
            const sum = evenSum + oddSum
            const lastDigit = sum % 10
            const calculatedControl = lastDigit > 0 ? 10 - lastDigit : 0

            if (control !== calculatedControl.toString()) {
              errorMessage = 'CIF no válido'
            }
          }
        }
      } else {
        // Validación del NIF/NIE
        const nie = str
          .replace(/^[X]/, '0')
          .replace(/^[Y]/, '1')
          .replace(/^[Z]/, '2')
        const letterOrDigit = str.substr(-1)
        const charIndex = parseInt(nie.substr(0, 8)) % 23

        if (isNaN(parseInt(letterOrDigit))) {
          const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKET'
          if (validLetters.charAt(charIndex) !== letterOrDigit) {
            errorMessage = 'Letra no válida'
          }
        } else {
          if (charIndex !== parseInt(letterOrDigit)) {
            errorMessage = 'Número no válido'
          }
        }
      }
    }
    //BASTIDOR
  } else if (fieldName.indexOf('bastidor') !== -1) {
    const bastidorRexp = /^[A-HJ-NPR-Z0-9]{17}$/
    if (!bastidorRexp.test(value)) {
      errorMessage = 'Bastidor no válido'
    }
    //CORREO
  } else if (fieldName.includes('correo') || fieldName.includes('email')) {
    const emailRexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRexp.test(value)) {
      errorMessage = 'Correo no válido'
    }
    //CODIGO DE CONFIRMACION
  } else if (fieldName === 'confirmationCode') {
    if (!/^[0-9]*$/.test(value)) {
      errorMessage = 'Este campo solo acepta números'
    } else if (value.length !== 6) {
      errorMessage = 'Este campo debe tener 6 caracteres'
    }
  }
  return errorMessage
}

//CONVERTIR ARRAYBUFFER A BASE64

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = ''
  let bytes = new Uint8Array(buffer)
  let len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function base64ToByteArray(base64: string): Uint8Array {
  const binaryString = atob(base64);
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray;
}

export function dataURItoFile(dataURI: string, filename: string): File {
  const arr = dataURI.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}
