import { CosteCampoDatoModel, DetalleCosteCampoDatoModel } from '../../models/costeModel';
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel';
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel';
import { AyuntamientoCampoDatoModel } from '../../models/escritura/ayuntamientoCampoDatoModel';
import { BienCampoDatoModel } from '../../models/escritura/bienCampoDatoModel';
import { BienesCampoDatoModel } from '../../models/escritura/bienesCampoDatoModel';
import { CatastroCampoDatoModel } from '../../models/escritura/catastroCampoDatoModel';
import { DefectoCampoDatoModel } from '../../models/escritura/defectoCampoDatoModel';
import { HaciendaCampoDatoModel } from '../../models/escritura/haciendaCampoDatoModel';
import { NotariaCampoDatoModel } from '../../models/escritura/notariaCampoDatoModel';
import { RegistroCampoDatoModel } from '../../models/escritura/registroCampoDatoModel';
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel';
import {
  ADD_AYTO,
  ADD_BIEN,
  ADD_BIENES,
  ADD_CATASTRO,
  ADD_COSTES,
  ADD_DEFECTO,
  ADD_DEFECTOS,
  ADD_DETALLE_COSTE,
  ADD_DETALLE_EXPEDIENTE,
  ADD_DOCUMENTOS,
  ADD_HACIENDA,
  ADD_NOTARIA,
  ADD_REGISTRO,
  ADD_REGISTROS,
  ADD_TAREAS_PENDIENTES,
  ExpEscrituraActionsTypes,
} from './escrituraType';

export const addDetalleExpediente = (
  expediente: DetailExpedientFieldDataModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_DETALLE_EXPEDIENTE,
  payload: expediente,
});

export const addDetalleCoste = (
  detalleCoste: DetalleCosteCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_DETALLE_COSTE,
  payload: detalleCoste,
});

export const addBienes = (
  bienes: BienesCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_BIENES,
  payload: bienes,
});

export const addCostes = (
  costes: CosteCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_COSTES,
  payload: costes,
});

export const addTareasPendientes = (
  tareasPendientes: TareaPendienteCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_TAREAS_PENDIENTES,
  payload: tareasPendientes,
});

export const addDocumentos = (
  documentos: DocumentoCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_DOCUMENTOS,
  payload: documentos,
});

export const addBien = (
  bien: BienCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_BIEN,
  payload: bien,
});

export const addNotaria = (
  notaria: NotariaCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_NOTARIA,
  payload: notaria,
});

export const addHacienda = (
  hacienda: HaciendaCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_HACIENDA,
  payload: hacienda,
});

export const addRegistro = (
  registro: RegistroCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_REGISTRO,
  payload: registro,
});

export const addRegistros = (
  registros: RegistroCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_REGISTROS,
  payload: registros,
});

export const addDefecto = (
  defecto: DefectoCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_DEFECTO,
  payload: defecto,
});

export const addDefectos = (
  defectos: DefectoCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_DEFECTOS,
  payload: defectos,
});

export const addAyuntamiento = (
  ayuntamiento: AyuntamientoCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_AYTO,
  payload: ayuntamiento,
});

export const addCatastro = (
  catastro: CatastroCampoDatoModel,
): ExpEscrituraActionsTypes => ({
  type: ADD_CATASTRO,
  payload: catastro,
});