import { ADD_MODULE, ADD_TEMPLATE, ADD_TEMPLATE_FIELDS, TemplateActionsTypes } from './ITemplateType'
import { TemplateModel } from '../../models/templateModel/templateModel'
import { TemplateFieldsModel } from '../../models/templateFieldsModel/templateFieldsModel'

const addTemplate = (
  template: Partial<TemplateModel> | undefined,
): TemplateActionsTypes => ({
  type: ADD_TEMPLATE,
  payload: template,
})

const addTemplatesFields = (
  templateFields: TemplateFieldsModel[] | undefined,
): TemplateActionsTypes => ({
  type: ADD_TEMPLATE_FIELDS,
  payload: templateFields,
})

const addModule = (
  module: number | undefined,
): TemplateActionsTypes => ({
  type: ADD_MODULE,
  payload: module,
})

export { 
  addTemplate, 
  addTemplatesFields, 
  addModule 
};
