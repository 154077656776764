import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
class TemplateModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('EsGenerica', Boolean)
  EsGenerica: boolean = false;

  @JsonProperty('IdPlantillaParent', Number)
  IdPlantillaParent: number = 0;

  @JsonProperty('IdPerfil', Number)
  IdPerfil: number = 0;
}

export { TemplateModel };
