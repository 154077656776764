import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DossierTypeModel')
class DossierTypeModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0

  @JsonProperty('Name', String)
  Name: String = ''

  @JsonProperty('IdTramiteElGestor', String)
  IdTramiteElGestor: String = ''

  @JsonProperty('DossierSerie', String)
  DossierSerie: String = ''

  @JsonProperty('DossierOriginType', Number)
  DossierOriginType: Number = 0

  @JsonProperty('DossierRemoteTypeCode', String)
  DossierRemoteTypeCode: String = 'MAT'

  @JsonProperty('DossierRemoteTypeDescription', String)
  DossierRemoteTypeDescription: String = ''

  @JsonProperty('InputsDocumentacion', Array<InputsDocumentacion>)
  InputsDocumentacion: Array<InputsDocumentacion> = []
}

@JsonObject('SolicitudGridModel')
class DossierTypeGridModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('Name', String)
  Name: string = ''

  @JsonProperty('Slogan', String)
  Slogan: String = ''

  @JsonProperty('IdentificationNumber', String)
  IdentificationNumber: String = ''

  @JsonProperty('ContactInformation', String)
  ContactInformation: String = ''

  @JsonProperty('NotificationsEmail', String)
  NotificationsEmail: String = ''
}

@JsonObject('InputsDocumentacion')
class InputsDocumentacion {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('DossierTypeId', Number)
  DossierTypeId: number = 0

  @JsonProperty('InputName', String)
  InputName: string = ''

  @JsonProperty('Required', Boolean)
  Required: boolean = false

  @JsonProperty('Comentario', String)
  Comentario: string = ''

  @JsonProperty('FileName', String)
  FileName?: string = ''
}

export { DossierTypeModel, DossierTypeGridModel, InputsDocumentacion }
