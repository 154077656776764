import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  checkStatusCode,
  customLog,
  logType,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { ConfiguracionEnvioEmailModel } from 'src/models/company/configuracionEnvioEmailModel'
import { UserModel } from 'src/models/usuario/userModel'

const serviceName = 'EmailService'

const getEmail = async (
  user: UserModel,
  idCompany: number,
): Promise<ConfiguracionEnvioEmailModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ConfiguracionEnvioEmail/GetByCompany/` + idCompany
    let EmailData: ConfiguracionEnvioEmailModel =
      new ConfiguracionEnvioEmailModel()
    await axiosInstance
      .get(url)
      .then((response: any) => {
        EmailData = jsonConvert.deserializeObject(
          response.data,
          ConfiguracionEnvioEmailModel,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener la configuración del Email.',
          NotifyType.error,
          5000,
        )
      })
    return EmailData
  } catch (err) {
    console.error(err)
  }
}

const postEmail = async (
  user: UserModel,
  email:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined,
  idCompany: number,
): Promise<ConfiguracionEnvioEmailModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ConfiguracionEnvioEmail`
    let EmailData: ConfiguracionEnvioEmailModel =
      new ConfiguracionEnvioEmailModel()

    await axiosInstance
      .post(url, {
        IdCompany: idCompany,
        ServidorSMTP: email?.ServidorSMTP,
        PuertoSMTP: email?.PuertoSMTP,
        UsaSSL: email?.UsaSSL,
        NombreMostrar: email?.NombreMostrar,
        DireccionEmail: email?.DireccionEmail,
        UsuarioEmail: email?.UsuarioEmail,
        PasswordEmail: email?.PasswordEmail,
      })
      .then((response: any) => {
        checkStatusCode(response.status)
        EmailData = jsonConvert.deserializeObject(
          response.data,
          ConfiguracionEnvioEmailModel,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido Guardar la configuración del Email.',
          NotifyType.error,
          5000,
        )
      })

    return EmailData
  } catch (err) {
    console.error(err)
  }
}

const putEmail = async (
  user: UserModel,
  email:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined,
  idCompany: number,
): Promise<ConfiguracionEnvioEmailModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ConfiguracionEnvioEmail`
    let EmailData: ConfiguracionEnvioEmailModel =
      new ConfiguracionEnvioEmailModel()

    await axiosInstance
      .put(url, email)
      .then(async (response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Configuración de email editada => ${response.data.Id}`,
          logType.ERROR,
          serviceName,
          putEmail.name,
          methodsEndPointType.PUT,
        )
      })
      .catch((error: any) => {
        customLog(
          `Error al guardar ConfiguracionEnvioEmail => ${error.message}`,
          logType.ERROR,
          serviceName,
          putEmail.name,
          methodsEndPointType.PUT,
        )
      })
    return EmailData
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

const postEmailPrueba = async (
  user: UserModel,
  emailData: {
    emailDestinatario: string
  },
): Promise<any> => {
  try {
    let url = `ConfiguracionEnvioEmail/PruebaEmail`

    const emailDataPrueba = {
      email: emailData.emailDestinatario,
      asunto: 'E-mail de prueba configuracion de la gestoría',
      mensaje:
        'Este es un correo de prueba para verificar que la configuracion del servidor SMTP esta correcta.',
    }

    let response = await axiosInstance.post(url, emailDataPrueba)

    checkStatusCode(response.status)

    return response.data
  } catch (error) {
    showToast('Error al enviar el correo de prueba.', NotifyType.error, 5000)
    throw error
  }
}

export { getEmail, postEmail, putEmail, postEmailPrueba }
