import {Modules} from "./allowModuloUtil";

interface RutasUtil {
  title: string
  path: string
  module: Modules
}

export const rutas: RutasUtil[] = [
  {
    title: 'Calculadora',
    path: '/accessOnlyCalculatorPage',
    module: Modules.CALCULADORA,
  },
  {
    title: 'Consulta expedientes escrituras',
    path: '/expedientes/escrituras',
    module: Modules.SOLICITUDES
  },
  {
    title: 'Consulta expedientes vehiculos',
    path: '/expedientes/vehiculos',
    module: Modules.SOLICITUDES,
  },
  {
    title: 'Solicitudes',
    path: '/solicitudes/list',
    module: Modules.SOLICITUDES,
  },
  {
    title: 'Consulta bastidores',
    path: '/microdatos/consulta-bastidores',
    module: Modules.MICRODATOS,
  },
  {
    title: 'Consulta datos de empresa',
    path: '/consultaempresa',
    module: Modules.DATOSDEEMPRESA,
  },
]
