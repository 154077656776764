import { Form } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import {
  AsyncRule,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
} from 'devextreme-react/form'
import FooterDocumentTypeSection from './footerDocumentTypeSection';
import { NotifyType, showToast } from 'src/utils/sharedUitls';
import {
  postDocumentType,
  putDocumentType,
} from '../../services/documentTypeServices';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentTypeFormModel } from '../../models/documentTypeModelsForm';
import { FormFieldsSection } from '../../../Fields/sections/FieldsSection/FormFieldsSection';
import { validarEmailsSeparadosPorComas } from 'src/utils/validators';
import { useDispatch } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';

interface Props {
  documento: DocumentTypeFormModel
  cerrarPopup: Function
  setdocumento: Function
}

export default function FormDocumentosSection({
  documento,
  cerrarPopup,
  setdocumento,
}: Props) {
  const [editar, setEditar] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // Crear una mutación para la creación del documento
  const crearDocumentType = useMutation(
    async function () {
      dispatch(addShowLoader(true));
      await postDocumentType(documento);
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries('ConsultaFirmaDocumentType');
        setdocumento(new DocumentTypeFormModel());
        cerrarPopup(false);
        dispatch(addShowLoader(false));
      },
      onError: function () {
        dispatch(addShowLoader(false));
      },
    },
  );

  // Crear una mutación para la edición del documento
  const editarDocumentType = useMutation(
    async function () {
      dispatch(addShowLoader(true));
      await putDocumentType(documento);
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries('ConsultaFirmaDocumentType');
        setdocumento(new DocumentTypeFormModel());
        cerrarPopup(false);
        dispatch(addShowLoader(false));
      },
      onError: function () {
        dispatch(addShowLoader(false));
      },
    },
  );

  // Función para manejar el envío del formulario
  async function SubmitForm() {
    try {
      if (!editar) {
        crearDocumentType.mutate();
      } else {
        editarDocumentType.mutate();
      }
    } catch (error: any) {
      showToast(
        !editar
          ? 'Error al editar el documenttype'
          : 'Error al crear el documenttype',
        NotifyType.error,
        5000,
      );
    }
  }

  // Efecto para detectar si se está editando
  useEffect(() => {
    if (documento.id !== 0) {
      setEditar(true);
    } else {
      setEditar(false);
    }
  }, [documento.id]);

  return (
    <>
      <Form
        formData={documento}
        labelLocation={'left'}
        showValidationSummary={false}
        validationGroup='documentTypeData'
      >
        <TabbedItem>
          <Tab title={'Datos Generales'}>
            <Form
              formData={documento}
              labelLocation={'left'}
              showValidationSummary={false}
              validationGroup='documentTypeData'
            >
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'name'}
                  label={{ text: 'Nombre' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Nombre del documento',
                    value: documento.name,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.name = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                >
                  <RequiredRule
                    message={'El campo name es necesario'}
                  ></RequiredRule>
                </SimpleItem>
                <SimpleItem
                  dataField={'viafirmaPlantillaId'}
                  label={{ text: 'Identificador de plantilla' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Identificador De Plantilla del documento',
                    value: documento.viafirmaPlantillaId,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.viafirmaPlantillaId = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                >
                  <RequiredRule
                    message={'El campo viafirmaPlantillaId es necesario'}
                  ></RequiredRule>
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'requiresDocumentUpload'}
                  label={{ text: 'Requiere el envío del documento' }}
                  editorType={'dxCheckBox'}
                  editorOptions={{
                    autoFocus: true,
                    defaultValue: false,
                    hint: 'Requiere el envío del documento',
                    value: documento.requiresDocumentUpload,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.requiresDocumentUpload = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField={'allowsDocumentUpload'}
                  label={{ text: 'Permitir enviar el documento' }}
                  editorType={'dxCheckBox'}
                  editorOptions={{
                    autoFocus: true,
                    defaultValue: false,
                    hint: 'Permitir enviar el documento',
                    value: documento.allowsDocumentUpload,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.allowsDocumentUpload = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
              </GroupItem>

              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'formRequired'}
                  label={{ text: 'Mostar formulario al firmar' }}
                  editorType={'dxCheckBox'}
                  editorOptions={{
                    autoFocus: true,
                    defaultValue: false,
                    hint: 'Mostar formulario al firmar',
                    value: documento.formRequired,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.formRequired = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField={'formDisabledForUpdates'}
                  label={{
                    text: 'Añadir solo la información que falte en el formulario',
                  }}
                  editorType={'dxCheckBox'}
                  editorOptions={{
                    autoFocus: true,
                    defaultValue: false,
                    hint: 'Añadir solo la información que falte en el formulario',
                    value: documento.formDisabledForUpdates,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.formDisabledForUpdates = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
              </GroupItem>

              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'notificationText'}
                  label={{ text: 'Mensaje de notificación al usuario' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Mensaje de notificación al usuario',
                    value: documento.notificationText,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.notificationText = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField={'emailSubjectText'}
                  label={{ text: 'Asunto del email' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Asunto del email del usuario',
                    value: documento.emailSubjectText,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.emailSubjectText = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'emailBodyText'}
                  label={{ text: 'Texto del email' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Texto del email del usuario',
                    value: documento.emailBodyText,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.emailBodyText = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField={'signPageText'}
                  label={{ text: 'Texto pantalla de firma' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Texto pantalla de firma del documento',
                    value: documento.signPageText,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.signPageText = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField={'watermarkText'}
                  label={{ text: 'Texto marca agua' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Texto marca agua en la previsualización del documento',
                    value: documento.watermarkText,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.watermarkText = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField={'additionalEmails'}
                  label={{ text: 'Emails adicionales' }}
                  editorType={'dxTextArea'}
                  editorOptions={{
                    autoFocus: true,
                    mode: 'text',
                    hint: 'Emails adicionales',
                    placeholder: 'Emails adicionales separados por comas',
                    value: documento.additionalEmails,
                    onValueChanged: (e: any) => {
                      if (e.value !== undefined && e.value  != null ) {
                        documento.additionalEmails = e.value
                        setdocumento(documento)
                      }
                    },
                  }}
                >
                  <AsyncRule
                    message={
                      'los emails deben tener un formato correcto y separado por comas'
                    }
                    validationCallback={(e: any) => {
                      return validarEmailsSeparadosPorComas(e.value)
                    }}
                  />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                {FooterDocumentTypeSection({
                  validation: 'documentTypeData',
                  editar: editar,
                  enviar: SubmitForm,
                  documento: documento,
                })}
              </GroupItem>
            </Form>
          </Tab>

          {editar && (
            <Tab title={'Campos Relacionados'}>
              <FormFieldsSection documento={documento}></FormFieldsSection>
            </Tab>
          )}
        </TabbedItem>
      </Form>
    </>
  );
}
