import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import {
  checkScreenSize,
  CURRENCY_FORMAT,
  getAlignment,
  getColumnType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import NotFound from 'src/components/notFound/notFound'
import { isRolAllowed, ClaimsModule } from 'src/utils/allowAuthUtil'
import { Modules } from 'src/utils/allowModuloUtil'
import { useScreenSize, useScreenSizeGrid } from 'src/utils/media-query'
import ConsultaVehiculo from './consultaVehiculo/ConsultaVehiculo'
import { showPopup } from 'src/redux/actions/sharedUtilsActions'
import {
  addCostes,
  addDetalleCoste,
  addDetalleExpediente,
  addDetalleVehiculo,
  addStructure,
  addTareasPendientes,
} from '../../redux/vehiculo/vehiculoActions'
import { StructureExpedienteModel } from '../../models/structureExpedienteModel'
import {
  getDetalleVehiculo,
  getExpediente,
  getStructure,
  getVehiculos,
} from '../../services/vehiculo/vehiculoService'
import DataSource from 'devextreme/data/data_source'
import { getCostes, getDetalleCoste } from '../../services/costeService'
import { getTareasPendientesByExpediente } from '../../services/tareaService'
import { OnExporting } from 'src/utils/export/ExportExcel'
import { useQuery } from 'react-query'
import { getIdocCarDossierStatusRelationList } from 'src/pages/companyAdminPages/idocCarRelationshipsStatesFiles/service/idocCarRelacionesEstadosExpedientesService'

const options = [
  'filter',
  'skip',
  'take',
  'requireTotalCount',
  'sort',
  'totalSummary',
  'group',
]

const _getStore = (user: any) =>
  new DataSource({
    store: new CustomStore({
      key: 'IdKey',
      load: async (loadOptions: any) =>
        await getVehiculos(user, loadOptions, options),
    }),
  })

export class Ids {
  id: number | null = null
  idvehiculo: number | null = null
}

function VehiclesList() {
  const dataGridRef = useRef<DataGrid | null>(null)
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user?.user)
  const store = React.useMemo(() => _getStore(userState), [userState])

  const [structure, setStructure] = useState(new StructureExpedienteModel())
  const [datos, setDatos] = useState(new Ids())
  const [showPopupExpedienteVehiculos, setShowPopupExpedienteVehiculos] =
    useState(false)

  const { isLarge } = useScreenSize()

  /* Obtener el valor almacenado en 'gridExpedientesComponent' en localStorage.
  Obtener la estructura de campos desde algún lugar (probablemente una función externa).
  Verificar si hay algún valor en 'gridExpedientesComponent' en localStorage.
  Parsear el valor a un objeto.
  Iterar sobre las columnas almacenadas en 'gridExpedientesComponent'.
  Buscar la columna correspondiente en la estructura de campos.
  Verificar si la visibilidad de la columna almacenada difiere de la visibilidad en la estructura.
  Si hay una diferencia, se establece 'plantillasReload' en 'true' en localStorage.
  Se recarga la página. */
  async function UpdatedPlantillas() {
    const value = localStorage.getItem('gridExpedientesComponent')
    const structure = await getStructure(userState!)
    if (value) {
      const gridExpedientesComponent = JSON.parse(value ?? '')
      let shouldReload = false

      gridExpedientesComponent.columns.map((itemStorage: any) => {
        const columnFind = structure?.Campos?.find(
          (itemStructure) => itemStructure.Nombre === itemStorage.name,
        )
        if (itemStorage.visible !== columnFind?.Visible) {
          localStorage.setItem('plantillasReload', 'true')
          shouldReload = true
        }
      })
      if (shouldReload) {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const structure = await getStructure(userState!)
        setStructure(structure)
        dispatch(addStructure(structure))

        const value = localStorage.getItem('gridExpedientesComponent')
        if (value) {
          const gridExpedientesComponent = JSON.parse(value ?? '')
          gridExpedientesComponent['pageIndex'] = 0

          gridExpedientesComponent.columns.map((itemStorage: any) => {
            const columnFind = structure?.Campos?.find(
              (itemStructure) => itemStructure.Nombre === itemStorage.name,
            )

            const plantillasReload = localStorage.getItem('plantillasReload')
            if (plantillasReload === 'true') {
              localStorage.setItem('plantillasReload', 'false')
              showToast('Plantillas actualizadas', NotifyType.info, 5000)
            } else if (itemStorage.visible !== columnFind?.Visible) {
              showToast('Plantillas actualizadas', NotifyType.info, 5000)
            }

            itemStorage.visible = columnFind?.Visible
          })

          localStorage.setItem(
            'gridExpedientesComponent',
            JSON.stringify(gridExpedientesComponent),
          )
          // Actualiza las plantillas después de obtener la estructura
          await UpdatedPlantillas()
        }
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [dispatch, userState])

  /* Insertar dos botones en la barra de herramientas al principio.
  Botón de Filtros Personalizados.
  Cuando se hace clic en el botón de filtro,
  se intenta mostrar el popup del constructor de filtros.
  Si existe una referencia al grid (expedienteGridRef),
  se actualizan las opciones del grid para mostrar el popup del constructor de filtros.
  Se llama a la función UpdatedPlantillas para verificar y actualizar plantillas.
  Se actualiza la estructura y se despacha una acción para almacenarla en algún lugar (redux, por ejemplo).
  Si existe una referencia al grid (expedienteGridRef),
  se actualiza el contenido del grid llamando al método refresh().

  Botón de Refrescar Contenido
  Cuando se hace clic en el botón de refresco de contenido,
  se obtiene el valor del almacenamiento local y la estructura de campos.
  Se llama a la función UpdatedPlantillas para verificar y actualizar plantillas.
  Se actualiza la estructura y se despacha una acción para almacenarla en algún lugar (redux, por ejemplo).
  se actualiza el contenido del grid llamando al método refresh().*/

  const OnToolbarPreparing = (event: any) => {
    event.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: (_e: any) => {
            dataGridRef
              ? dataGridRef!.current!.instance.option(
                  'filterBuilderPopup.visible',
                  true,
                )
              : showToast(
                  'No se ha podido recargar, inténtelo de nuevo mas tarde',
                  NotifyType.error,
                  5000,
                )
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            const value = localStorage.getItem('gridExpedientesComponent')
            const structure = await getStructure(userState!)
            UpdatedPlantillas()

            setStructure(structure)

            dataGridRef
              ? dataGridRef!.current!.instance.refresh()
              : showToast(
                  'No se ha podido recargar, inténtelo de nuevo mas tarde',
                  NotifyType.error,
                  5000,
                )
          },
        },
      },
    )
  }

  const OpenExpediente = async (data: any) => {
    const { Id, IdVehiculo } = data
    setDatos({ ...datos, id: Id, idvehiculo: IdVehiculo })
    // setDatos(prevDatos => ({ ...prevDatos, id: Id, idvehiculo: IdVehiculo }));
    setShowPopupExpedienteVehiculos(true)
    dispatch(showPopup(true))
    await Promise.all([
      getExpediente(Id, userState!),
      getDetalleCoste(Id, userState!),
      getDetalleVehiculo(IdVehiculo, userState!),
      getCostes(Id, userState!),
      getTareasPendientesByExpediente(Id, userState!),
    ]).then(
      ([
        expediente,
        detallecostes,
        detallevehiculo,
        costes,
        tareaspendientes,
      ]) => {
        dispatch(addDetalleExpediente(expediente))
        dispatch(addDetalleCoste(detallecostes))
        dispatch(addDetalleVehiculo(detallevehiculo))
        dispatch(addCostes(costes))
        dispatch(addTareasPendientes(tareaspendientes))
      },
    )
  }

  const CloseExpediente = () => {
    setShowPopupExpedienteVehiculos(false)
    dispatch(showPopup(false))
  }

  const HandlePropertyChange = (e: any) => {
    // Evita realizar acciones específicas en eventos loadPanel y export
    if (e.name.trim() === 'loadPanel' || e.name.trim() === 'export') {
      return
    }
  }

  interface EstadoColor {
    IdDossierStatus: string
    EstadoGestor: string
    EstadoPortal: string
    Color: string
  }

  const { data: estadosYColores } = useQuery<EstadoColor[] | undefined>(
    'estadosYColores',
    async () => {
      const response = await getIdocCarDossierStatusRelationList(
        userState!.user!,
      )
      if (response && Array.isArray(response)) {
        return response.map((item) => ({
          IdDossierStatus: item.IdDossierStatus,
          EstadoGestor: item.DossierStatusElGestor.Description,
          EstadoPortal: item.DossierStatus.Name,
          Color: item.DossierStatusElGestor.Color,
        }))
      }
      return
    },
    {
      enabled: !!userState?.user,
      staleTime: Infinity,
    },
  )

  const getColorForStatus = useCallback(
    (idDossierStatus: string) => {
      const estado = estadosYColores?.find(
        (e) =>
          e.EstadoPortal === idDossierStatus ||
          e.EstadoGestor === idDossierStatus,
      )
      return estado && estado.Color && estado.Color.trim() !== ''
        ? estado.Color
        : null
    },
    [estadosYColores],
  )

  return (
    <div id='container-body'>
      {isRolAllowed(userState!, Modules.EXPEDIENTES_VEHICULOS, [
        ClaimsModule.VIEW,
      ]) ? (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.16%',
            minHeight: '290px',
            maxHeight: 'calc(100vh - 8rem - 10px)',
          }}
        >
          <h3 style={{ margin: 0, padding: 0 }}>
            Consulta expedientes vehículos
          </h3>
          {structure?.Campos !== undefined && structure?.Campos.length > 0 && (
            <DataGrid
              id={'gridExpedientesComponent'}
              ref={dataGridRef}
              dataSource={store}
              style={{ width: '100%', minHeight: '250px' }}
              height='calc(105vh - 8rem - 10px)'
              showBorders
              showRowLines
              focusedRowEnabled={false}
              wordWrapEnabled={false}
              rowAlternationEnabled
              allowColumnReordering
              allowColumnResizing
              remoteOperations={{ filtering: true, sorting: true }}
              columnResizingMode={'widget'}
              columnAutoWidth
              columnHidingEnabled={!checkScreenSize()}
              onOptionChanged={HandlePropertyChange}
              filterPanel={{ visible: true }}
              filterRow={{ visible: true }}
              onRowDblClick={({ data }: any) => {
                OpenExpediente(data)
              }}
              onToolbarPreparing={(event: any) => {
                OnToolbarPreparing({ ...event })
              }}
              export={{
                allowExportSelectedData: true,
                enabled: true,
              }}
              onExporting={OnExporting}
              headerFilter={{ visible: true, allowSearch: true }}
              searchPanel={{
                visible: true,
                width: checkScreenSize() ? 240 : 120,
              }}
            >
              <StateStoring
                enabled={true}
                type='localStorage'
                storageKey='gridVehiculosList'
              />
              <Scrolling
                mode='virtual'
                rowRenderingMode='virtual'
                showScrollbar='always'
                scrollByThumb
                preloadEnabled
              />
              <SearchPanel visible width={checkScreenSize() ? 240 : 100} />
              <Sorting mode={'multiple'} />
              <Paging pageSize={1000} />
              <Selection
                mode='multiple'
                showCheckBoxesMode={isLarge ? 'always' : 'never'}
                allowSelectAll
              />
              {structure.Campos !== undefined &&
                structure.Campos.map((value) => (
                  <Column
                    defaultSortOrder={
                      value.Nombre === 'FechaInicio' ? 'desc' : undefined
                    }
                    visible={value.Visible}
                    key={value.Nombre}
                    dataField={value.Nombre}
                    caption={value.Texto}
                    dataType={value.Tipo}
                    allowGrouping={value.Nombre !== 'TotalFactura'}
                    alignment={getAlignment(value.Tipo)}
                    format={getColumnType(value.Tipo, value.Format)}
                    allowHeaderFiltering={value.Tipo !== 'date'}
                    allowSorting
                    showInColumnChooser={value.Visible}
                    cellRender={
                      value.Nombre === 'Estado'
                        ? (cellData: any) => {
                            if (
                              !cellData.value ||
                              cellData.value.trim() === ''
                            ) {
                              return null
                            }
                            const backgroundColor = getColorForStatus(
                              cellData.value,
                            )
                            if (!backgroundColor) {
                              return (
                                <div style={{ textAlign: 'center' }}>
                                  {cellData.value}
                                </div>
                              )
                            }
                            return (
                              <div
                                style={{
                                  backgroundColor,
                                  textAlign: 'center',
                                  padding: '5px',
                                  borderRadius: '5px',
                                  boxShadow:
                                    '-1.5px -1.5px 4px rgba(0, 0, 0, 0.2)',
                                  color: 'white',
                                }}
                              >
                                {cellData.value}
                              </div>
                            )
                          }
                        : undefined
                    }
                  />
                ))}
              <Summary>
                <TotalItem column='NumeroExpediente' summaryType='count' />
                <TotalItem
                  column='TotalFactura'
                  summaryType='sum'
                  valueFormat={CURRENCY_FORMAT}
                  alignment='center'
                  displayFormat='{0}'
                />
              </Summary>
            </DataGrid>
          )}
          {showPopupExpedienteVehiculos && (
            <ConsultaVehiculo
              datos={datos}
              setDatos={setDatos}
              close={CloseExpediente}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.6%',
          }}
        >
          <NotFound data={{ type: 1 }} />
        </div>
      )}
    </div>
  )
}

export { VehiclesList }
