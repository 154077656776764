import React, { useEffect, useState } from 'react'
import { Box, Form } from 'devextreme-react'
import moment from 'moment'
import { formatDatePrettier, isNotEmpty } from 'src/utils/sharedUitls'
import { useScreenSize } from 'src/utils/media-query'
import { GroupItem, SimpleItem, Tab, TabbedItem } from 'devextreme-react/form'

import { addShowLoader } from 'src/redux/actions/configActions'
import { useDispatch } from 'react-redux'
import { MicrodatosModel } from 'src/pages/microdatos/models/microdatosModel'
import {
  numDecimals,
  numMiles,
} from '../../../../microdatos/consultaBastidores'
import { Item } from 'devextreme-react/box'
import informaAxios from '../../../../../utils/serviciosRuanoAxios'

function MicrodatosBastidores({
  bastidorVehiculo,
  datosBastidor,
}: {
  bastidorVehiculo: any
  datosBastidor: any
}) {
  const dispatch = useDispatch()
  const [dataRes, setDataRes] = useState(new MicrodatosModel())
  const [catVE, setcatVE]: any = useState([])
  const [clTramite, setClTramite] = useState([])
  const [codigoClaseMatricula, setCodigoClaseMatricula] = useState([])
  const [codigoPropulsion, setCodigoPropulsion] = useState([])
  const [codigoProcedencia, setCodigoProcedencia] = useState([])
  const [codigoProvincia, setCodigoProvincia] = useState([])
  const [codigoProvinciaMatriculacion, setCodigoProvinciaMatriculacion] =
    useState([])
  const [indicadorBajaDefinitiva, setIndicadorBajaDefinitiva] = useState([])
  const [codigoTipo, setCodigoTipo] = useState([])
  const { isLarge } = useScreenSize()

  useEffect(() => {
    if (isNotEmpty(bastidorVehiculo) && isNotEmpty(datosBastidor)) {
      onSubmit(bastidorVehiculo.bastidor, datosBastidor)
    }
  }, [bastidorVehiculo, datosBastidor])

  const onSubmit = async (value: any, data: any) => {
    let res = data
    let allPromise = []
    setDataRes(res)
    for (let key in res) {
      if (/\s/.test(res[key])) {
        res[key] = res[key].trim()
      }
      if (res[key] == null ) {
        res[key] = ''
      }
    }

    res.categoriaVehiculoElectrico = res.categoriaVehiculoElectrico
      ? res.categoriaVehiculoElectrico
      : ''
    if (res.categoriaVehiculoElectrico !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCategoriaVehElectrico/GetByCodigo?categoriaVehElectrico=${res.categoriaVehiculoElectrico}`,
        ),
      )
    }
    res.claveTramite = res.claveTramite ? res.claveTramite : ''
    if (res.claveTramite !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtClaveTramite/GetByCodigo?claveTramite=${res.claveTramite}`,
        ),
      )
    }
    res.codClaseMat = res.codClaseMat ? res.codClaseMat : ''
    if (res.codClaseMat !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCodClaseMat/GetByCodigo?codClaseMat=${res.codClaseMat}`,
        ),
      )
    }
    res.codPropulsionItv = res.codPropulsionItv ? res.codPropulsionItv : ''
    if (res.codPropulsionItv !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCodPropulsion/GetByCodigo?codPropulsion=${res.codPropulsionItv}`,
        ),
      )
    }
    res.codProcedenciaITV = res.codProcedenciaITV ? res.codProcedenciaITV : ''
    if (res.codProcedenciaITV !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCodProcedencia/GetByCodigo?codProcedencia=${res.codProcedenciaITV}`,
        ),
      )
    }
    res.codProvinciaVehiculo = res.codProvinciaVehiculo
      ? res.codProvinciaVehiculo
      : ''
    if (res.codProvinciaVehiculo !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCodProvinciaVeh/GetByCodigo?codProvinciaVeh=${res.codProvinciaVehiculo}`,
        ),
      )
    }
    res.codProvinciaMatriculacion = res.codProvinciaMatriculacion
      ? res.codProvinciaMatriculacion
      : ''
    if (res.codProvinciaMatriculacion !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtCodProvinciaMat/GetByCodigo?codProvinciaMat=${res.codProvinciaMatriculacion}`,
        ),
      )
    }
    res.codTipo = res.codTipo ? res.codTipo : ''
    if (res.codTipo !== '') {
      allPromise.push(
        informaAxios.get(`eDgtCodTipo/GetByCodigo?codTipo=${res.codTipo}`),
      )
    }
    res.indBajaDefinitiva = res.indBajaDefinitiva ? res.indBajaDefinitiva : ''
    if (res.indBajaDefinitiva !== '') {
      allPromise.push(
        informaAxios.get(
          `eDgtIndBajaDefinitiva/GetByCodigo?indBajaDefinitiva=${res.indBajaDefinitiva}`,
        ),
      )
    }

    try {
      const result = await Promise.all(allPromise)
      result
        .map((r) => r.data)
        .forEach((r: any) => {
          if (r.codClaseMat) {
            setCodigoClaseMatricula(r.descripcion)
          }
          if (r.codPropulsion) {
            setCodigoPropulsion(r.descripcion)
          }
          if (r.codPropulsionItv) {
            setCodigoProcedencia(r.descripcion)
          }
          if (r.codProcedenciaITV) {
            setCodigoProcedencia(r.descripcion)
          }
          if (r.codProvinciaVehiculo) {
            setCodigoProvincia(r.descripcion)
          }
          if (r.codProvinciaMatriculacion) {
            setCodigoProvinciaMatriculacion(r.descripcion)
          }
          if (r.codTipo) {
            setCodigoTipo(r.descripcion)
          }
          if (r.claveTramite) {
            setClTramite(r.descripcion)
          }
          if (r.categoriaVehiculoElectrico) {
            setcatVE(r.descripcion)
          }
          if (r.indBajaDefinitiva) {
            setIndicadorBajaDefinitiva(r.descripcion)
          }
        })
    } catch (e) {
      console.log(e)
    }

    dispatch(addShowLoader(false))
  }

  return (
    <div id='container-body'>
      <React.Fragment>
        <div>
          <Box
            align='center'
            crossAlign='stretch'
            style={{ alignItems: 'center' }}
          >
            <Item ratio='1'>
              <Form
                id='tabs'
                readOnly={true}
                labelLocation={isLarge ? 'left' : 'top'}
                showColonAfterLabel={true}
              >
                <TabbedItem>
                  <Tab title='Datos Generales'>
                    <GroupItem colCount={2}>
                      <SimpleItem
                        label={{ text: 'Bastidor' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.bastidor,
                          value: dataRes.bastidor,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Marca' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.marcaItv,
                          value: dataRes.marcaItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Modelo' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.modeloItv,
                          value: dataRes.modeloItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Cilindrada' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.cilindradaItv,
                          value:
                            dataRes.cilindradaItv !== undefined
                              ? numMiles(dataRes.cilindradaItv)
                              : dataRes.cilindradaItv,
                        }}
                      ></SimpleItem>

                      <SimpleItem
                        label={{ text: 'Potencia' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: Math.round,
                          value:
                            dataRes.kwItv !== undefined
                              ? numDecimals(Math.round(dataRes.kwItv * 1.36)) +
                                'CV (' +
                                numDecimals(dataRes.kwItv) +
                                ' kW)'
                              : Math.round(dataRes.kwItv * 1.36) +
                                'CV (' +
                                dataRes.kwItv +
                                ' kW)',
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Plazas' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.numPlazas,
                          value: dataRes.numPlazas,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Emisiones' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.co2Itv,
                          value:
                            dataRes.co2Itv !== undefined
                              ? numMiles(dataRes.co2Itv)
                              : dataRes.co2Itv + ' g/km',
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Nivel de emisiones' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.nivelEmisionesEuroItv,
                          value: dataRes.nivelEmisionesEuroItv,
                        }}
                      ></SimpleItem>
                      {/* {tempPaisCoche.current !== '' && (
                              <SimpleItem
                                label={{ text: 'Fabricación' }}
                                render={verPais}
                                cssClass={'imgpais'}
                              ></SimpleItem>
                            )} */}
                    </GroupItem>
                  </Tab>
                  <Tab title='Datos Técnicos'>
                    <GroupItem colCount={2}>
                      <SimpleItem
                        label={{ text: 'Variante' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.varianteItv,
                          value: dataRes.varianteItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Versión' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.versionItv,
                          value: dataRes.versionItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Fabricante' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.fabricanteItv,
                          value: dataRes.fabricanteItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Masa en circulación (tara)' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.tara,
                          value:
                            dataRes.tara !== undefined
                              ? numMiles(dataRes.tara)
                              : dataRes.tara,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Masa máxima' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.pesoMaximo,
                          value:
                            dataRes.pesoMaximo !== undefined
                              ? numMiles(dataRes.pesoMaximo)
                              : dataRes.pesoMaximo,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Categoría homologación europea' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.categoriaHomoligacionEuItv,
                          value: dataRes.categoriaHomoligacionEuItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Carrocería' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.carroceria,
                          value: dataRes.carroceria,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Distancia entre ejes' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.distanciaEjes12Itv,
                          value:
                            dataRes.distanciaEjes12Itv !== undefined
                              ? numMiles(dataRes.distanciaEjes12Itv)
                              : dataRes.distanciaEjes12Itv + '  mm',
                        }}
                      ></SimpleItem>

                      <SimpleItem
                        label={{ text: 'Vía anterior' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.viaAnteriorItv,
                          value: dataRes.viaAnteriorItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Vía posterior' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.viaPosteriorItv,
                          value: dataRes.viaPosteriorItv,
                        }}
                      ></SimpleItem>
                    </GroupItem>
                  </Tab>
                  <Tab title='Propietarios'>
                    <GroupItem colCount={2}>
                      <SimpleItem
                        label={{ text: 'Numero de titulares' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.numTitulares,
                          value:
                            dataRes.numTitulares !== undefined
                              ? numMiles(dataRes.numTitulares)
                              : dataRes.numTitulares,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Primera matriculación' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: moment(dataRes.fecMatricula).format(
                            'DD-MM-YYYY',
                          ),
                          value: moment(dataRes.fecMatricula).format(
                            'DD-MM-YYYY',
                          ),
                        }}
                      ></SimpleItem>
                    </GroupItem>
                  </Tab>
                  <Tab title='Otros Datos'>
                    <GroupItem colCount={3}>
                      <SimpleItem
                        label={{ text: 'Autonomía vehículo eléctrico' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.autonomiaVehiculoElectrico,
                          value: dataRes.autonomiaVehiculoElectrico,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Categoría vehículo eléctrico' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: catVE,
                          value: catVE,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Baja telemática' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.bajaTelematica,
                          value: dataRes.bajaTelematica,
                        }}
                      ></SimpleItem>

                      <SimpleItem
                        label={{ text: 'Clasificación vehículos' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.clasificacionRegVehiculosItv,
                          value: dataRes.clasificacionRegVehiculosItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Clase matrícula' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoClaseMatricula,
                          value: codigoClaseMatricula,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Propulsión' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoPropulsion,
                          value: codigoPropulsion,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Procedencia' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoProcedencia,
                          value: codigoProcedencia,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Provincia vehículo' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoProvincia,
                          value: codigoProvincia,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Provincia matriculación' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoProvinciaMatriculacion,
                          value: codigoProvinciaMatriculacion,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Clave trámite' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: clTramite,
                          value: clTramite,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Tipo de vehículo' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: codigoTipo,
                          value: codigoTipo,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Código eco' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.codigoEcoItv,
                          value: dataRes.codigoEcoItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Código itv' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.codigoItv,
                          value: dataRes.codigoItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Consumo kw' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.consumoWhKwItv,
                          value:
                            dataRes.consumoWhKwItv !== undefined
                              ? numMiles(dataRes.consumoWhKwItv)
                              : dataRes.consumoWhKwItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Constraseña homologación' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.contrasenaHomologacionItv,
                          value: dataRes.contrasenaHomologacionItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Eco innovación itv' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.ecoInnovacionItv,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Fabricante vehículo' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.fabricanteVehiculoBase,
                          value: dataRes.fabricanteVehiculoBase,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Fecha proceso' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.fecProceso,
                          value: dataRes.fecProceso
                            ? formatDatePrettier(dataRes.fecProceso)
                            : '',
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Fecha tramitación' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.fecTramitacion,
                          value: dataRes.fecTramitacion
                            ? formatDatePrettier(dataRes.fecTramitacion)
                            : '',
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Fecha trámite' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.fecTramite,
                          value: dataRes.fecTramite
                            ? formatDatePrettier(dataRes.fecTramite)
                            : '',
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Baja definitiva' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: indicadorBajaDefinitiva,
                          value: indicadorBajaDefinitiva,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Baja temporal' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indBajaTemporal,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Código tutela' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indCodTutela,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Embargo' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indEmbargo,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Precinto' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indPrecinto,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Renting' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indRenting,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Sustracción' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indSustraccion,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Usado' }}
                        editorType={'dxCheckBox'}
                        editorOptions={{
                          value: dataRes.indUsado,
                        }}
                        cssClass='checkboxes'
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Localidad vehículo' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.localidadVehiculo,
                          value: dataRes.localidadVehiculo,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Marca vehículo base' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.marcaVehiculoBase,
                          value: dataRes.marcaVehiculoBase,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{
                          text: 'Masa máxima tecnicamente admisible',
                        }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.masaMaximaTecnicamenteAdmisibleItv,
                          value: dataRes.masaMaximaTecnicamenteAdmisibleItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Masa orden marcha' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.masaOrdenMarchaItv,
                          value: dataRes.masaOrdenMarchaItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Matrícula' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.matricula,
                          value: dataRes.matricula,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Municipio' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.municipio,
                          value: dataRes.municipio,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Nive' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.nive,
                          value: dataRes.nive,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Notas' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.notas,
                          value: dataRes.notas,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Número máximo plazas' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.numPlazasMaximo,
                          value:
                            dataRes.numPlazasMaximo !== undefined
                              ? numMiles(dataRes.numPlazasMaximo)
                              : dataRes.numPlazasMaximo,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Número  transmisiones' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.numTransmisiones,
                          value:
                            dataRes.numTransmisiones !== undefined
                              ? numMiles(dataRes.numTransmisiones)
                              : dataRes.numTransmisiones,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Persona física' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.personaFisicaJuridica,
                          value: dataRes.personaFisicaJuridica,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Plazas pie' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.plazasPie,
                          value:
                            dataRes.plazasPie !== undefined
                              ? numMiles(dataRes.plazasPie)
                              : dataRes.plazasPie,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Potencia' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.potenciaItv,
                          value:
                            dataRes.potenciaItv !== undefined
                              ? numDecimals(dataRes.potenciaItv)
                              : dataRes.potenciaItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Reducción eco' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.reduccionEcoItv,
                          value: dataRes.reduccionEcoItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Servicio' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.servicio,
                          value: dataRes.servicio,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Tipo alimentación' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.tipoAlimentacionItv,
                          value: dataRes.tipoAlimentacionItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Tipo itv' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.tipoItv,
                          value: dataRes.tipoItv,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Tipo itv base' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.tipoItvBase,
                          value: dataRes.tipoItvBase,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Variante vehículo base' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.varianteVehiculoBase,
                          value: dataRes.varianteVehiculoBase,
                        }}
                      ></SimpleItem>
                      <SimpleItem
                        label={{ text: 'Versión vehículo base' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          hint: dataRes.versionVehiculoBase,
                          value: dataRes.versionVehiculoBase,
                        }}
                      ></SimpleItem>
                    </GroupItem>
                  </Tab>
                </TabbedItem>
              </Form>
            </Item>
          </Box>
        </div>
      </React.Fragment>
    </div>
  )
}

export default MicrodatosBastidores
