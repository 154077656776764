import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { deleteFakeDates } from 'src/utils/dateUtils';
import { checkStatusCode, NotifyType, showToast } from 'src/utils/sharedUitls';
import { TareaPendienteCampoDatoModel } from '../models/tareaPendienteModel';

export const getTareasPendientesByExpediente = async (
  expedienteId: number,
  user: UserModel,
): Promise<TareaPendienteCampoDatoModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `TareaPendiente/getByExpediente/${expedienteId}`;
    let tareaPendienteGrid = new TareaPendienteCampoDatoModel();

    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status);
      tareaPendienteGrid = jsonConvert.deserializeObject(
        {
          ...response.data,
          Datos: response.data.Datos.map(deleteFakeDates),
        },
        TareaPendienteCampoDatoModel,
      )
    })
    .catch((error: any) => {
      showToast('No se han podido obtener los historicos', 
      NotifyType.error, 5000);
    });
    return tareaPendienteGrid;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  };
  return new TareaPendienteCampoDatoModel();
};
