import { GroupItem, SimpleItem } from 'devextreme-react/form'
import React from 'react'

export default function DatosBasicosEmpresaSection() {
  return (
    <GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'nif'}
          label={{ text: 'Nif' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nif Empresa',
            fontWeight: 'bold',
            color: 'black',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'domicilioSocial'}
          label={{ text: 'Domicilio social' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Domicilio Social Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'email'}
          label={{ text: 'Email' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Email Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'localidad'}
          label={{ text: 'Localidad' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Localidad Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'denominacion'}
          label={{ text: 'Denominación' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Denominación Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'telefono'}
          label={{ text: 'Teléfono' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Telefono Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'nombreComercial'}
          label={{ text: 'Nombre comercial' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre Comercial Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'formaJuridica'}
          label={{ text: 'Forma jurídica' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Forma Jurídica Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'web'}
          label={{ text: 'Web' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Web Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'fax'}
          label={{ text: 'Fax' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Fax Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
    </GroupItem>
  )
}
