import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
export class SendFormFieldsModel {
  @JsonProperty('id', Number, 0)
  id: number = 0;

  @JsonProperty('name', String)
  name: string = '';

  @JsonProperty('internalFormat', String)
  internalFormat: string = '';

  @JsonProperty('readableFormat', String)
  readableFormat: string = '';

  @JsonProperty('expedientFieldsIds', Array<String>())
  expedientFieldsIds: string[] = [];
}
