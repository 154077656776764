import { Button, DataGrid } from 'devextreme-react';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import React, { MutableRefObject, useCallback, useEffect,
  useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';
import { useScreenSize } from 'src/utils/media-query';
import { NotifyType, checkScreenSize, getColumnAlignment,
  showToast } from 'src/utils/sharedUitls';
import { AddCompanyCodigoViaFirma, addCompanies,
  addCompany, addCompanyModulos } from '../redux/companiesActions';
import { deleteCompany, getCompaniesGrid,
  getCompany, getCompanyModulos } from '../services/companiesServices';
import { RootState } from 'src/redux/combineReducers';
import { addCalculatorTransfers } from '../redux/calculatorTranfers/addCalculatorTransfers';
import { _getCalculadora } from '../services/calculadoraService';
import { addLinks } from '../redux/link/linkActions';
import { getLinks } from '../services/linksService';
import { addEmail } from '../redux/email/emailActions';
import { getEmail } from '../services/emailService';
import { addDataFormChange } from 'src/redux/actions/sharedUtilsActions';
import { isSuperAdmin } from 'src/utils/allowAuthUtil';
import { CompanyModel } from 'src/models/company/companyModel';
import { CalculatorTransferModel } from 'src/models/company/calculatorTransferModel';
import { ConfiguracionEnvioEmailModel } from 'src/models/company/configuracionEnvioEmailModel';
import { CompanyCampoDatoGridModel } from 'src/models/company/companyCampoDatoModel';
import { confirm } from 'devextreme/ui/dialog';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { getCompanyViaFirma } from 'src/pages/firmadigital/services/firmaServices';
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil';

interface Props {
  verpopup: Function;
  estadoGrid: any;
  actualizar: Function;
  crear: Function;
}
function CompanyListPage({
  verpopup,
  estadoGrid,
  actualizar,
  crear,
}: Props) {
  const dispatch = useDispatch();
  const [structure, setStructure] = useState(new CompanyCampoDatoGridModel());
  const { isLarge } = useScreenSize();
  const [rowSelected, setRow] = useState(0);
  const userState = useSelector((state: RootState) => state.user);
  const dataGridSolicitudesListRef: MutableRefObject<any> = useRef(null);

  let getAlignment = function getAlignment(type: any) {
    switch (type) {
      case 'currency':
        return 'right'

      default:
        return 'left'
    }
  }

  async function _getCompanies() {
    let companias = await getCompaniesGrid(userState!.user!);
    dispatch(addCompanies(companias));
    setStructure(companias!);
  }

  useEffect(() => {
    _getCompanies();
  }, [estadoGrid]);

  function getCurrency() {
    let location = window.localStorage.getItem('lenguage')
    let defaultCurrency = {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      minimumSignificantDigits: 4,
      type: 'currency',
      precision: 2,
    }
  }

  let getColumnType = function getColumnType(type: any, format: any) {
    switch (type) {
      case 'currency':
        return getCurrency();
      case 'string':
        return null;
      case 'number':
        if (format === 'decimal') {
          return getCurrency();
        }
        return null;
      case 'date':
        return 'dd/MM/yyyy';
      case 'datetime':
        return 'dd/MM/yyyy';
      case 'boolean':
        return 'boolean';
      case 'decimal':
        return getCurrency();
      case null:
        if (format === 'float') {
          return getCurrency();
        } else {
          return null;
        }
      default:
        return null;
    }
  }

  const openCreatePopup = async () => {
    if (isSuperAdmin(userState!.user!)) {
      dispatch(addCompany(new CompanyModel()));
      dispatch(addLinks([]));
      dispatch(
        addCalculatorTransfers(new CalculatorTransferModel()),
      );
      dispatch(addEmail(new ConfiguracionEnvioEmailModel()));
      dispatch(addDataFormChange(false));
      crear(true);
      verpopup();
    } else {
      showToast(
        'Solo los administradores propietarios de la aplicación pueden crear nuevas gestorías',
        NotifyType.info, 5000
      );
    };
  };

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'Crear Compañía ',
        location: 'after',
        widget: 'dxButton',
        visible: openCreatePopup == null  ? false : true,
        options: {
          hint: 'Crear Compañia ',
          icon: 'add',
          onClick: function onClick(_e: any) {
            dispatch(AddCompanyCodigoViaFirma(''))
            openCreatePopup()
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: function onClick(_e: any) {
            dataGridSolicitudesListRef.current!.instance.refresh(true);
          },
        },
      },
    );
  };

  function exportExcell(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Gestorias.xlsx',
        );
      });
    });
    e.cancel = true;
  }

  const loadState = useCallback(() => {
    if (structure.Datos.length > 0) {
      return JSON.parse(localStorage.getItem('companyList')!);
    }
  }, [structure.Datos]);

  const saveState = useCallback((state: { columns: string | any[] }) => {
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null;
      };
    };
    localStorage.setItem('companyList', JSON.stringify(state));
  }, []);

  return (
    <DataGrid
      id='usuariosDataGrid'
      keyExpr='Id'
      ref={dataGridSolicitudesListRef}
      dataSource={structure.Datos}
      style={{ width: '100%', minHeight: '250px' }}
      height='calc(100vh - 8rem - 46px)'
      allowColumnReordering
      allowColumnResizing
      cacheEnabled
      columnAutoWidth
      focusedRowEnabled
      remoteOperations
      repaintChangesOnly
      rowAlternationEnabled
      showBorders
      showRowLines
      columnChooser={{ enabled: true }}
      columnHidingEnabled={!checkScreenSize()}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
      headerFilter={{ visible: false }}
      loadPanel={{ enabled: true }}
      paging={{ enabled: false }}
      sorting={{ mode: 'multiple', showSortIndexes: false }}
      export={{
        allowExportSelectedData: true,
        enabled: true,
      }}
      searchPanel={{
        visible: true,
        width: checkScreenSize() ? 240 : 120,
      }}
      selection={{
        mode: 'multiple',
        showCheckBoxesMode: isLarge ? 'always' : 'none',
      }}
      scrolling={{
        mode: 'standard',
        scrollByContent: true,
        scrollByThumb: true,
        showScrollbar: 'always',
      }}
      onToolbarPreparing={(event: any) => {
        onToolbarPreparing(event)
      }}
      onExporting={exportExcell}
    >
      <StateStoring
        enabled={true}
        type='custom'
        storageKey={'companyList'}
        customLoad={loadState}
        customSave={saveState}
      />
      <Column
        name={'buttonEditCompany'}
        caption={'Editar'}
        type='buttons'
        width={55}
        cellRender={(row: any) => (
          <Button
            width='32px'
            text=''
            icon='edit'
            hint='Editar'
            onClick={async () => {
              dispatch(addShowLoader(true));
              setRow(row.data);
              dispatch(addCompany(await getCompany(userState!.user!, row.data.Id)));
              dispatch(addCalculatorTransfers(await _getCalculadora(userState?.user!, row.data.Id)));
              dispatch(addLinks(await getLinks(userState!.user!, row.data.Id)));
              dispatch(addEmail(await getEmail(userState!.user!, row.data.Id)));
              dispatch(addCompanyModulos(await getCompanyModulos(userState!.user!, row.data.Id)));

              if (IsModuloAllowed(userState!.user!, Modules.FIRMADIGITAL) 
                && isSuperAdmin(userState!.user!)) {
                  dispatch(AddCompanyCodigoViaFirma(await getCompanyViaFirma(row.data.Id)))
              }

              dispatch(addDataFormChange(false));
              crear(false);
              verpopup();
              dispatch(addShowLoader(false));
            }}
          />
        )}
      />
      <Column
        caption={'Eliminar'}
        type='buttons'
        width={50}
        cellRender={(row: any) => (
          <Button
            width='32px'
            text=''
            icon='trash'
            hint='Eliminar'
            onClick={async () => {
              setRow(row.data)
              let confirmDialog = confirm(
                '<i>¿Está seguro de que quiere eliminar esta gestoría por completo?</i>',
                'Confirma los cambios',
              )
              confirmDialog.then(async (dialogResult) => {
                if (dialogResult) {
                  dispatch(addShowLoader(true));
                  await deleteCompany(userState!.user!, row.data.Id);
                  const refreshButton = document.getElementsByClassName(
                    'dx-icon dx-icon-refresh',
                  )[0] as HTMLElement
                  refreshButton.click();
                  actualizar();
                  dispatch(addShowLoader(false));
                } else {
                }
              });
            }}
          />
        )}
      />
      {structure.Campos.map((value: any) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Texto}
          dataType={value.Tipo}
          alignment={getColumnAlignment(value.Tipo)}
          allowFiltering={value.Nombre !== 'TipoUsuario'}
          allowGrouping={value.Nombre !== 'Totfactura'}
          allowHeaderFiltering={value.Tipo !== 'date'}
          format={getColumnType(value.Tipo, value.Format)}
        />
      ))}
    </DataGrid>
  )
}

export { CompanyListPage };