import React from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling, StateStoring, Summary, TotalItem } from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { CURRENCY_FORMAT, getColumnAlignment, getColumnType } from 'src/utils/sharedUitls';
// -------------------------------------------------------------------------------------------------

export default function CostesTabEscritura() {
  const costesState = useSelector((state: RootState) => state.escrituras.costes)

  return (
    <DataGrid
      dataSource={costesState.Datos}
      keyExpr='Id'
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      focusedRowEnabled
      rowAlternationEnabled
      showBorders
      showRowLines
    >
      <Scrolling showScrollbar='always' />
      <StateStoring
        enabled
        type='localStorage'
        storageKey='gridCostesEscrituras'
      />
      {costesState.Campos.map((value) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Texto}
          dataType={value.Tipo}
          format={getColumnType(value.Tipo, value.Format)}
          alignment={getColumnAlignment(value.Tipo)}
          visible={value.Nombre === 'Id' ? false : value.Visible}
        />
      ))};

      <Summary>
        <TotalItem
          column='Importe'
          summaryType='sum'
          valueFormat={CURRENCY_FORMAT}
        />
        <TotalItem
          column='CuotaIva'
          summaryType='sum'
          valueFormat={CURRENCY_FORMAT}
        />
        <TotalItem
          column='TotalLinea'
          summaryType='sum'
          valueFormat={CURRENCY_FORMAT}
        />
      </Summary>
    </DataGrid>
  );
};