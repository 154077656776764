import React, { ReactElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../redux/combineReducers'
import { isAlreadyLoggedUser } from '../../../redux/utils/reduxUtils'
import { TemplatePage } from './pages/TemplatePage'

function PlantillasPage(): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showUserLinks, setShowUserLinks] = useState(true)

  const obtenerAncho = () => {}
  if (userState!.isLoggedIn === false) {
    isAlreadyLoggedUser()
  }
  const usuarioState = useSelector((state: RootState) => state.user)

  const handleHideShowUserLinks = () => {
    setShowUserLinks(false)
    localStorage.setItem('acceptedLinksUsers', 'true')
  }

  return (
    <>
      {/*
       **Modificación TODO Probar, Esto ya deberia de funcionar
       *TODO cuando dejo esto activo en el menu o el login afectan a que no funcione el debugger de VS */}

      {userState!.isLoggedIn === false ? navigate('/Login') : null}
      <TemplatePage></TemplatePage>
      {obtenerAncho}
    </>
  )
}

export { PlantillasPage }
