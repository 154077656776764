import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('SucursalModel')
export class SucursalModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0

  @JsonProperty('Codigo', String)
  Codigo: string = ''

  @JsonProperty('Descripcion', String)
  Descripcion: string = ''
}
