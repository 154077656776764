import React, { MutableRefObject, useRef, useState } from 'react';
import { Box, Button, DataGrid } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { isSuperAdmin } from 'src/utils/allowAuthUtil';
import { checkScreenSize } from 'src/utils/sharedUitls';
import { getCompanyConsultaDates, getHistoryCompany } from '../services/infomaservices';
import { CompanyConsultaModel } from '../models/CompanyConsultaModel';
import { AddInformaConsultaHistorico, AddInformaConsultaHistoricoGrid } from '../redux/actions/InformaActions';
import DatosHistoricoCompanySection from '../sections/DatosHistoricoCompany/DatosHistoricoCompanySection';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { addShowLoader } from 'src/redux/actions/configActions';

function HistoricoPage() {
  const userState = useSelector((state: RootState) => state.user);
  const isSuperAdministrator = isSuperAdmin(userState!.user!);

  useQuery(
    ['ConsultaHistorico', userState!.user, isSuperAdmin],
    getCompanyConsultaDates,
    {
      onSuccess: (data: Array<CompanyConsultaModel> | null) => {
        if (data  != null ) {
          dispatch(AddInformaConsultaHistorico(data));
          dispatch(addShowLoader(false));
        } else {
          dispatch(addShowLoader(false));
        }
      },
      onError: () => {
        dispatch(addShowLoader(false));
      },
      refetchOnWindowFocus: false,
    },
  );

  const dispatch = useDispatch();
  const listaConsultaHistoricoState = useSelector(
    (state: RootState) => state.Informa.companyhistory,
  );
  const informaConsultaHistoricoDataGrid: MutableRefObject<any> = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  function Cerrar() {
    setShowPopup(false);
  }

  async function obtenerDatosHistoricoCompany(id: number) {
    const datosHistoricoCompany = await getHistoryCompany(
      userState!.user!,
      id,
      isSuperAdministrator,
    )

    if (datosHistoricoCompany  != null ) {
      dispatch(AddInformaConsultaHistoricoGrid(datosHistoricoCompany))
      setShowPopup(true);
    }
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            informaConsultaHistoricoDataGrid.current!.instance.refresh(true);
          },
        },
      },
    );
  }

  function exportExcell(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Historico Consultas.xlsx',
        )
      })
    });
    e.cancel = true;
  }

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>Histórico de consultas</h3>
        <Box direction='row' width='auto' align='center' crossAlign='center'>
          <Item ratio={0} baseSize={750}>
            <DataGrid
              id='informaHistoricoConsultaDataGrid'
              keyExpr='companyId'
              ref={informaConsultaHistoricoDataGrid}
              dataSource={listaConsultaHistoricoState}
              style={{ width: '100%', minHeight: '250px' }}
              height='calc(100vh - 8rem - 46px)'
              allowColumnReordering
              allowColumnResizing
              columnAutoWidth
              focusedRowEnabled
              remoteOperations
              repaintChangesOnly
              rowAlternationEnabled
              showBorders
              showRowLines
              columnChooser={{ enabled: true }}
              columnHidingEnabled={!checkScreenSize()}
              filterPanel={{ visible: true }}
              filterRow={{ visible: true }}
              headerFilter={{ visible: false }}
              loadPanel={{ enabled: true }}
              paging={{ enabled: false }}
              sorting={{ mode: 'multiple', showSortIndexes: false }}
              export={{
                allowExportSelectedData: false,
                enabled: true,
              }}
              searchPanel={{
                visible: true,
                width: checkScreenSize() ? 240 : 120,
              }}
              selection={{
                mode: 'single',
              }}
              scrolling={{
                mode: 'standard',
                scrollByContent: true,
                scrollByThumb: true,
                showScrollbar: 'always',
              }}
              onToolbarPreparing={(event: any) => {
                onToolbarPreparing(event);
              }}
              onExporting={exportExcell}
            >
              <StateStoring
                enabled={true}
                type={'localStorage'}
                storageKey={'HistoricoConsultaList'}
              />

              <Column
                width={'12%'}
                type='buttons'
                cellRender={(row: any) => (
                  <Button
                    icon='find'
                    onClick={(e: any) => {
                      obtenerDatosHistoricoCompany(row.key);
                    }}
                  />
                )}
              />

              <Column
                width={'50%'}
                caption={'Nombre De Empresa'}
                dataField={'companyName'}
                alignment='left'
              />
              <Column
                width={'50%'}
                caption={'Consultas'}
                dataField={'consultas'}
                alignment='right'
              />
            </DataGrid>
          </Item>
        </Box>
        {showPopup && <DatosHistoricoCompanySection cerrar={Cerrar} />}
      </div>
    </div>
  );
}

export { HistoricoPage };