import firmaDigitalAxios from 'src/utils/firmadigitalaxios';
import { NotifyType, logType, showToast } from 'src/utils/sharedUitls';
import { ExpedientFieldModel } from '../models/ExpedienteFieldModels';
import { RelationsField } from '../models/IRelationsField';
import { FieldsModel } from '../models/FieldsModel';
import { SendFormFieldsModel } from '../models/SendFormFieldsModel';

// Función para enviar y actualizar un archivo
async function postUpdateFile(
  file: FormData,
): Promise<Array<String> | []> {
  let fields: Array<String> | [] = []
  await firmaDigitalAxios
    .post('/Documents/Fields', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => {
      fields = response.data.fields
      if (response.data.fields.length === 0) {
        showToast(
          'No existen etiquetas para este documento',
          NotifyType.info, 5000
        );
      }
    })
    .catch((error: any) => {
      showToast('No se ha podido subir el fichero.', 
        NotifyType.error, 5000
      );
    });
  return fields;
}

// Función para obtener los campos configurables
async function getFields(): Promise<Array<ExpedientFieldModel> | []> {
  let fieldsconfigurables: Array<ExpedientFieldModel> | [] = []
  await firmaDigitalAxios
    .get('Fields')
    .then((response: any) => {
      fieldsconfigurables = response.data
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los campos configurables.',
        NotifyType.error, 5000
      );
    });
  return fieldsconfigurables;
}

/**
 * Realiza una solicitud POST para crear una relación entre campos y un tipo de documento.
 * @param associations - Las asociaciones de campos a crear.
 * @param idDocumentType - El ID del tipo de documento al que se agregarán las relaciones de campos.
 * @returns Una promesa que indica si la operación fue exitosa (true) o no (false/null).
 */
async function postFieldDocumentType(
  associations: { 
    id: number; 
    name: String; 
    expedientFieldsIds: string[]; 
    internalFormat: string; 
    readableFormat: string; 
  }[],
  idDocumentType: number
): Promise<Boolean | null> {
  let relacionCreada: Boolean | null = false;

  try {
    // Formatea las asociaciones para que coincidan con la estructura de la API
    const formattedAssociations = associations.map((assoc) => ({
      name: assoc.name,
      internalFormat: assoc.internalFormat,
      readableFormat: assoc.readableFormat,
      expedientFieldsIds: assoc.expedientFieldsIds,
    }));

    // Realiza la solicitud POST para crear las relaciones de campos
    await firmaDigitalAxios.post(`DocumentTypes/${idDocumentType}/Fields`, formattedAssociations);
    relacionCreada = true;

    showToast('La relación entre campos fue creada con éxito.', NotifyType.success, 5000);
  } catch (error) {
    showToast('No se ha podido crear la relación entre campos.', NotifyType.error, 5000);
  }

  return relacionCreada;
}


/**
 * Realiza una solicitud PUT para actualizar una relación específica entre campos y un tipo de documento.
 * @param association - La asociación de campos a actualizar.
 * @param idDocumentType - El ID del tipo de documento al que pertenecen las relaciones de campos.
 * @returns Una promesa que indica si la operación fue exitosa (true) o no (false/null).
 */
async function putFieldDocumentType(
  association: { 
    id: number; 
    name: String; 
    expedientFieldsIds: string[]; 
    internalFormat: string; 
    readableFormat: string; 
  },
  idDocumentType: number
): Promise<Boolean | null> {
  let relacionCreada: Boolean | null = null;

  try {
    // Formatea la asociación para que coincida con la estructura de la API
    const formattedAssociation = {
      id: association.id,
      name: association.name,
      internalFormat: association.internalFormat,
      readableFormat: association.readableFormat,
      expedientFieldsIds: association.expedientFieldsIds,
    };

    // Realiza la solicitud PUT para actualizar la relación de campos específica
    await firmaDigitalAxios.put(`DocumentTypes/${idDocumentType}/Fields/${association.id}`, formattedAssociation);
    relacionCreada = true;

    showToast('La relación entre campos fue actualizada con éxito.', NotifyType.success, 5000);
  } catch (error) {
    showToast('No se ha podido actualizar la relación entre campos.', NotifyType.error, 5000);
  }

  return relacionCreada;
}


// Función para obtener las asociaciones de campos de un tipo de documento
async function getDocumentTypeFieldRelations({
  queryKey,
}: any): Promise<RelationsField[]> {
  let associations: RelationsField[] = [];

  // Verifica si queryKey existe y tiene al menos dos elementos
  if (queryKey && queryKey.length >= 2) {
    const idDocumentType = queryKey[1];

    // Verifica si idDocumentType es válido
    if (idDocumentType !== undefined 
        && idDocumentType  != null  
        && idDocumentType !== -1
        && idDocumentType !== 0
      ) {
      try {
        const response = await firmaDigitalAxios.get(
          `DocumentTypes/${idDocumentType}/Fields`);
        
        response.data.forEach((field: FieldsModel) => {
          if (associations.find((fieldData) =>
            fieldData.documentLabel === field.name &&
            field.expedientFields.find((lista) =>
              fieldData.originalLabel.find((field) => field === lista.description)
            )
          ) === undefined) {
            associations.push({
              id: field.id,
              documentLabel: field.name,
              originalLabel: field.expedientFields.map(
                (expediente) => expediente.description),
              value: field.readableFormat,
            });
          }
        });
      } catch (error) {
        showToast('No se han podido obtener las asociaciones.', NotifyType.error, 5000);
      }
    }
  }

  return associations;
}

/**
 * Realiza una solicitud DELETE para eliminar una asociación específica entre campos y un tipo de documento.
 * @param idDocumentType - El ID del tipo de documento al que pertenece la asociación de campos.
 * @param id - El ID de la asociación de campos a eliminar.
 * @returns Una promesa que indica si la operación de eliminación fue exitosa (true) o no (false).
 */
async function deleteFields(idDocumentType: number, id: number): Promise<Boolean> {

  // Inicializa la variable que indicará si la operación de eliminación fue exitosa
  let deleteAssociation = false;

  // Verifica que el ID sea válido antes de realizar la operación de eliminación
  if (id !== -1) {
    try {
      // Realiza la solicitud DELETE para eliminar la asociación de campos específica
      await firmaDigitalAxios.delete(`DocumentTypes/${idDocumentType}/Fields/${id}`);
      deleteAssociation = true;

    } catch (error: any) {
      showToast(
        'No se han podido borrar la asociación.',
        NotifyType.error, 5000
      );
    }
  }

  return deleteAssociation;
}


/**
 * Realiza una solicitud DELETE para eliminar todas las relaciones entre campos asociadas a un tipo de documento.
 * @param idTypeDocument - El ID del tipo de documento del cual se eliminarán las relaciones entre campos.
 * @returns Una promesa que indica si la operación de eliminación fue exitosa (true) o no (false).
 */
async function deleteFieldsAssociation(idTypeDocument: number): Promise<Boolean> {
  // Inicializa la variable que indicará si la operación de eliminación fue exitosa
  let deleteAssociation = false;

  try {
    // Realiza la solicitud DELETE para eliminar todas las relaciones entre campos asociadas al tipo de documento
    await firmaDigitalAxios.delete(`/DocumentTypes/${idTypeDocument}/Fields`);
    
    // Marca la operación como exitosa
    deleteAssociation = true;
    showToast('Las relaciones entre campos fueron eliminadas con éxito.', 
      NotifyType.success, 5000
    );
  } catch (error: any) {
    showToast(
      'No se han podido borrar las relaciones entre campos.',
      NotifyType.error,
      5000,
    );
  }

  return deleteAssociation;
}



// Función para obtener una asociación de campos para editar
async function getFieldForEdit(
  idTypeDocument: number,
  id: number,
): Promise<SendFormFieldsModel> {
  let associations: SendFormFieldsModel = new SendFormFieldsModel();

  await firmaDigitalAxios
    .get(`DocumentTypes/${idTypeDocument}/Fields/${id}`)
    .then((response) => {
      associations.id = parseInt(response.data.id, 10);
      associations.name = response.data.name
      associations.internalFormat = response.data.internalFormat
      associations.readableFormat = response.data.readableFormat
      associations.expedientFieldsIds = response.data.expedientFields.map(
        (field: ExpedientFieldModel) => {
          return field.id;
        },
      );
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener la asociación.',
        NotifyType.error, 5000
      );
    });
  return associations;
};

export {
  getFields, getDocumentTypeFieldRelations, 
  getFieldForEdit, postUpdateFile,
  postFieldDocumentType, putFieldDocumentType,
  deleteFields, deleteFieldsAssociation,
};
