import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { checkStatusCode, customLog, logType, methodsEndPointType } from 'src/utils/sharedUitls';
import { CalculatorTransferModel } from '../../../../models/company/calculatorTransferModel';
import { NotifyType, showToast } from '../../../../utils/sharedUitls';
import { saveKeyCalculadora } from 'src/pages/accesOnlyCalculatorPage/utils/accessCalculatorUtils';

const serviceName = 'CalculadoraService';

export const _getCalculadora = async (
  user: UserModel,
  idCompany: number,
): Promise<CalculatorTransferModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `CalculadoraTransferencia/GetByCompany/${idCompany}`
    let CalculadoraData: CalculatorTransferModel =
      new CalculatorTransferModel();
    await axiosInstance
      .get(url)
      .then((response) => {
        CalculadoraData = jsonConvert.deserializeObject(
          response.data,
          CalculatorTransferModel,
        );
      })
      .catch((error) => {
        showToast(
          'No se han podido obtener de la calculadora.',
          NotifyType.error, 5000
        );
      });

    saveKeyCalculadora(CalculadoraData.Key)

    return CalculadoraData;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  };
};

const _postCalculator = async (
  user: UserModel,
  calculadora:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
  idCompany: number,
): Promise<CalculatorTransferModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    let url = `CalculadoraTransferencia`
    let calculadoraData: CalculatorTransferModel =
      new CalculatorTransferModel();

    await axiosInstance
      .post(url, {
        IdCompany: idCompany,
        CuotaTurismos: calculadora?.CuotaTurismos,
        CuotaMotocicletas: calculadora?.CuotaMotocicletas,
        CuotaCiclomotores: calculadora?.CuotaCiclomotores,
        Mensaje: calculadora?.CuotaMotocicletas,
      })
      .then((response: any) => {
        checkStatusCode(response.status)
        calculadoraData = jsonConvert.deserializeObject(
          response.data,
          CalculatorTransferModel,
        );
      })
      .catch((error: any) => {
        customLog(
          `Error al crear Calculadora => ${error.response.data}`,
          logType.ERROR,
          serviceName,
          _postCalculator.name,
          methodsEndPointType.POST,
        );
      });
    return calculadoraData;
  } catch (error: any) {
    showToast(error.response.data, NotifyType.error, 5000);
  };
};

const _putCalculator = async (
  user: UserModel,
  calculadora:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
  idCompany: number
  ) : Promise<CalculatorTransferModel | undefined> => {
    try {
      let jsonConvert: JsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
      let url = `CalculadoraTransferencia`;
      let calculadoraData: CalculatorTransferModel =
        new CalculatorTransferModel();

      await axiosInstance
        .put(url, { ...calculadora, IdCompany: idCompany })
        .then(async (response: any) => {
          checkStatusCode(response.status);

          customLog(
            `CalculadoraTransferencia guardada CalculadoraTransferencia =>${response.data.Id}`,
            logType.ERROR,
            serviceName,
            _putCalculator.name,
            methodsEndPointType.PUT,
          );
        })
        .catch((error: any) => {
          customLog(
            `Error al guardar CalculadoraTransferencia => ${error.message}`,
            logType.ERROR,
            serviceName,
            _putCalculator.name,
            methodsEndPointType.PUT,
          );
        });

      return calculadoraData
    } catch (error: any) {
      showToast(error.response.data, NotifyType.error, 5000);
    };
  };

export { _putCalculator, _postCalculator };
