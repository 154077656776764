import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('ListaArchivosModel')
export class ListaArchivosModel {
  @JsonProperty('Id', Number)
  Id: Number = 0
  @JsonProperty('Nombre', String)
  Nombre: String = ''
  @JsonProperty('Subido', Boolean)
  Subido: Boolean = false
}
