import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { UserModel } from 'src/models/usuario/userModel'
import { authHeader } from 'src/services/sharedServices'
import {
  checkStatusCode,
  customLog,
  logType,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { TemplateFieldsModel } from '../models/templateFieldsModel/templateFieldsModel'
import { TemplateFieldDataModel } from '../models/templateModel/templateFieldDataModel'
import { TemplateModel } from '../models/templateModel/templateModel'
import { TemplateFields } from '../components/TemplateFields'

const serviceName = 'UsuarioService'

const _getTemplates = async (
  user: UserModel,
): Promise<TemplateFieldDataModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `plantillas`

    let templates: TemplateFieldDataModel = new TemplateFieldDataModel()
    let data
    await axiosInstance
      .get(url)
      .then((response: any) => {
        templates = jsonConvert.deserializeObject(
          response.data,
          TemplateFieldDataModel,
        )
        data = response.data
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          _getTemplates.name,
          methodsEndPointType.GET,
        )
        showToast(
          'No se han podido obtener las templates.',
          NotifyType.error,
          5000,
        )
      })
    return templates
  } catch (err) {
    console.error(err)
  }
}

const _getTemplatesFields = async (
  user: UserModel,
  plantillaId: number,
): Promise<TemplateFieldsModel[] | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `PlantillaCampo/GetByPlantillaId/${plantillaId}`

    let templateFieldsModel: TemplateFieldsModel[] = []
    let datos
    await axiosInstance
      .get(url)
      .then((response: any) => {
        templateFieldsModel = jsonConvert.deserializeArray(
          response.data,
          TemplateFieldsModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          _getTemplates.name,
          methodsEndPointType.GET,
        )
        showToast(
          'No se han podido obtener PlantillaCampos.',
          NotifyType.error,
          5000,
        )
      })

    return templateFieldsModel
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}
async function _getTemplateWithChild(
  user: UserModel,
  id: number,
): Promise<TemplateModel[]> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Plantilla/PlantillaWithChild/${id}`

  let templates: TemplateModel[] = []
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      templates = jsonConvert.deserializeArray(
        response.data.Datos,
        TemplateModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getTemplateWithChild.name,
        methodsEndPointType.GET,
      )
      showToast(
        'No se han podido plantillas relacionadas',
        NotifyType.error,
        5000,
      )
    })

  return templates
}

async function _getStructure(user: UserModel): Promise<TemplateFieldDataModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Plantilla/Structure`

  let template: TemplateFieldDataModel = new TemplateFieldDataModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      template = jsonConvert.deserializeObject(
        response.data,
        TemplateFieldDataModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return template
}

async function _postTemplate(
  user: UserModel,
  templateFields: TemplateFieldsModel[],
  extraData: any,
): Promise<boolean> {
  let created = false

  try {
    let created = false
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `PlantillaCampo`
    let order = 0
    templateFields.map((templateField) => {
      order++
      return (templateField.Orden = order)
    })

    const templateFieldsBody = {
      PlantillaCampos: [...templateFields],
      ...extraData,
    }

    await axiosInstance
      .post(url, templateFieldsBody)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Plantilla created ${response.data.Id}`,
          logType.INFO,
          serviceName,
          _postTemplate.name,
          methodsEndPointType.POST,
        )
        showToast('Plantilla creada correctamente', NotifyType.success, 5000)
        created = true
      })
      .catch((error: any) => {
        showToast(error.Message, NotifyType.error, 5000)
        customLog(
          `Error create plantilla  => ${error.message}`,
          logType.ERROR,
          serviceName,
          _postTemplate.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.StatusCode)
        created = false
      })
  } catch (error: any) {
    showToast(error.message, NotifyType.error, 5000)
    customLog(
      `Error created plantilla => ${error.message}`,
      logType.ERROR,
      serviceName,
      _postTemplate.name,
      methodsEndPointType.POST,
    )
    showToast(error.message, NotifyType.error, 5000)
  }
  return created
}

async function _putTemplate(
  user: UserModel,
  plantillaCampos: TemplateFieldsModel[],
  extraData: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `PlantillaCampo`

    let orden = 0
    plantillaCampos.map((plantillaCampo) => {
      orden++
      return (plantillaCampo.Orden = orden)
    })

    const plantillaCamposBody = {
      PlantillaCampos: [...plantillaCampos],
      ...extraData,
    }

    await axiosInstance
      .put(url, plantillaCamposBody)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `plantilla modificada ${plantillaCamposBody.Id}`,
          logType.INFO,
          serviceName,
          _putTemplate.name,
          methodsEndPointType.PUT,
        )
        showToast('Plantilla editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar Plantilla => ${error.message}`,
          logType.ERROR,
          serviceName,
          _putTemplate.name,
          methodsEndPointType.PUT,
        )
      })
    return true
  } catch (error) {
    return false
  }
}

async function _deleteTemplate(user: UserModel, idPlantilla: number) {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Plantilla/${idPlantilla}`

    await axiosInstance.delete(url).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `plantilla elminada`,
        logType.INFO,
        serviceName,
        _deleteTemplate.name,
        methodsEndPointType.POST,
      )
      showToast('Plantilla elminada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    if (error.response.status === 400) {
      showToast(error.response.data, NotifyType.error, 5000)
    }
    customLog(
      `Error al eliminar Plantilla => ${error.response.data}`,
      logType.ERROR,
      serviceName,
      _deleteTemplate.name,
      methodsEndPointType.POST,
    )
    checkStatusCode(error.request.status)
    return false
  }
}

function _getMapperTemplate(usuarioData: any): TemplateModel {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let template: TemplateModel = jsonConvert.deserializeObject(
    usuarioData,
    TemplateModel,
  )
  return template
}

export {
  _getTemplates,
  _getTemplateWithChild,
  _getStructure,
  _getTemplatesFields,
  _postTemplate,
  _putTemplate,
  _deleteTemplate,
  _getMapperTemplate,
}
