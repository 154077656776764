import React, { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  deleteConsentimiento,
  getCompanyDocumentationConsentimiento,
  getConsentimientoFileLink,
  getConsentimientosByCompany,
  postUpdateFileConsentimiento,
  sendEmailConsentimiento,
  sendEmailConsentimientoFirmado,
} from '../services/ConsentimientosService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/combineReducers'
import {
  Button,
  CheckBox,
  DataGrid,
  Form,
  ScrollView,
  SelectBox,
  TextBox,
} from 'devextreme-react'
import { addShowLoader } from '../../../redux/actions/configActions'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import {
  checkScreenSize,
  getFileIcon,
  NotifyType,
  showToast,
} from '../../../utils/sharedUitls'
import { ButtonItem, ButtonOptions, Item, Label } from 'devextreme-react/form'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { getDocumentacionEmpresaFile } from '../services/MiRGPDServices'
import { Controller, useForm } from 'react-hook-form'
import '../styles/styles.css'
import {
  arrayBufferToBase64,
  base64ToByteArray,
  dataURItoFile,
  getValidationRules,
} from '../utils'
import { ItemClickEvent } from 'devextreme/ui/select_box'
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil'
import { Consentimientos } from '../models/ConsentimientosModel'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid'
import Acepto from 'src/assets/Acepto.png'
import NoAcepto from 'src/assets/NoAcepto.png'

export default function ConsentimientosPage() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm()
  const consentimientoFormRef: any = useRef(null)
  const userState = useSelector((state: RootState) => state.user?.user)
  const companyId = userState?.CompanyId!
  const [file, setFile] = useState<File | null>(null)
  const [fieldsInFile, setFieldsInFile] = useState<String[] | []>([])
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null)
  const [codigoGenerado, setCodigoGenerado] = useState('')
  const [popupVisible, setPopupVisible] = useState(false)
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false)
  const {
    control: controlCodigoConfirmacion,
    handleSubmit: handleConfirmacion,
  } = useForm()

  const [filteredConsentimientos, setFilteredConsentimientos] = useState([])
  const [columnsConsentimientos, setColumnsConsentimientos] = useState<any[]>(
    [],
  )

  const { data: consentimientos, isLoading } = useQuery({
    queryKey: ['consentimientos'],
    queryFn: () => getConsentimientosByCompany(companyId),
    staleTime: Infinity,
    onSuccess: (consentimientos: Consentimientos[]) => {
      dispatch(addShowLoader(false))

      const filteredConsentimientos: any[] = []
      const columnsConsentimientos: any[] = []

      consentimientos.forEach((consentimiento: Consentimientos) => {
        const parsedJsonData = JSON.parse(consentimiento.JsonData)
        const { Campos, JsonData, ...rest } = consentimiento
        const consentimientoFiltrado = { ...rest, ...parsedJsonData }
        filteredConsentimientos.push(consentimientoFiltrado)

        Object.keys(consentimientoFiltrado).forEach((column) => {
          if (
            !columnsConsentimientos.find((c) => c.dataField === column) &&
            ![
              'nombre_firmante',
              'correo_cliente',
              'NIF',
              'fecha_firma',
            ].includes(column)
          ) {
            columnsConsentimientos.push({
              dataField: column,
              caption: column,
              visible: false,
            })
          }
        })
      })

      setColumnsConsentimientos(columnsConsentimientos)
      setFilteredConsentimientos(filteredConsentimientos as any)
    },
  })

  const { data: documentacionEmpresa } = useQuery({
    queryKey: ['documentacionEmpresaConsentimiento'],
    queryFn: () => getCompanyDocumentationConsentimiento(companyId),
    staleTime: Infinity,
  })

  const handleToolbarPreparing = (e: any) => {
    let toolbarItems = e.toolbarOptions.items

    toolbarItems.push({
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: () => setPopupVisible(true),
      },
      location: 'after',
    })
  }

  const handleFileChange = async (e: ItemClickEvent) => {
    let selectedFile = documentacionEmpresa.find(
      (document: any) => document.Id === e.itemData.Id,
    )
    setSelectedDocument(selectedFile.Id)

    try {
      dispatch(addShowLoader(true))
      const response = await getDocumentacionEmpresaFile(
        selectedFile.Id,
        userState!.Token!,
        selectedFile,
      )
      if (response != null ) {
        const archivo = response
        setFile(archivo)
        let newFormData = new FormData()
        newFormData.append('file', archivo, archivo.name)
        const fildsInFile = await postUpdateFileConsentimiento(newFormData)
        //const checkBoxInFile = await postUpdateFileConsentimiento(newFormData)
        setFieldsInFile(fildsInFile)
      }
      dispatch(addShowLoader(false))
    } catch (error: any) {
      console.log('error', error)
    }
  }

  function descargarButton(data: ColumnCellTemplateData) {
    let myIcon = data.data.Ruta ? getFileIcon(data.data.Ruta) : 'defaultIcon'
    return (
      <Button
        icon={myIcon}
        text='Descargar'
        hint='Descarga el documento a su equipo'
        onClick={(e: any) => {
          getConsentimientoFileLink(
            data.data.Id,
            encodeURIComponent(data.data.Ruta),
            userState!.Token!,
          )
        }}
      />
    )
  }

  const onSubmit = async (data: any) => {
    if (Object.keys(errors).length > 0) {
      return
    }
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    setConfirmationPopupVisible(true)
    const formData = new FormData()
    formData.append('file', file!, file!.name)
    const autorizoEntries = Object.entries(data).filter(([key]) =>
      key.startsWith('autorizo'),
    )
    autorizoEntries.forEach(([key, value]) => {
      if (value) {
        const aceptoFile = dataURItoFile(Acepto, 'Acepto.png')
        formData.append(key, aceptoFile)
      } else {
        const noAceptoFile = dataURItoFile(NoAcepto, 'NoAcepto.png')
        formData.append(key, noAceptoFile, 'NoAcepto.png')
      }
    })
    formData.append('correo_cliente_firmante', data.correo_cliente_firmante)
    fieldsInFile.forEach((field) => {
      if (!field.includes('autorizo')) {
        formData.append(field.toString(), data[field.toString()])
      }
    })
    try {
      let codigo = generateRandomCode()
      setCodigoGenerado(codigo)
      formData.append('CodigoConfirmacion', codigo)
      const response = await sendEmailConsentimiento(
        formData,
        userState!.Token!,
      )
      if (response) {
        setFile(
          new File(
            [base64ToByteArray(response.data.base64Data)],
            response.data.name,
          ),
        )
        queryClient.invalidateQueries('consentimientos')
      }
    } catch (error) {
      console.error('Error al actualizar el archivo', error)
    }
  }

  const onConfirmationSubmit = async (data: any) => {
    if (data.ConfirmationCode === codigoGenerado) {
      showToast('Código correcto.', NotifyType.success, 2000)
      let dataFormulario = getValues()
      // Crear un nuevo documento PDF
      const fileBuffer = await file!.arrayBuffer()
      const pdfDoc = await PDFDocument.load(fileBuffer)
      const pages = pdfDoc.getPages()
      const fontSize = 10
      const color = rgb(0, 0, 0) // Black color
      const paddingHorizontal = 5 // Horizontal padding around the text
      const paddingVertical = 3 // Vertical padding around the text
      const lineHeight = fontSize * 1.2 // Line height for multi-line text
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      const formattedDateHour = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }).format(new Date())

      pages.forEach((page) => {
        const { width } = page.getSize()

        const text = [
          'El consentimiento ha sido firmado',
          `en la fecha y hora ${formattedDateHour}`,
          `por ${dataFormulario.nombre_firmante}`,
          `con el correo ${dataFormulario.correo_cliente_firmante}`,
          `con el NIF ${dataFormulario.NIF_firmante}`,
        ]

        const textWidth = Math.max(
          ...text.map((line) =>
            helveticaFont.widthOfTextAtSize(line, fontSize),
          ),
        )
        const textHeight = lineHeight * text.length

        const rectangleWidth = textWidth + paddingHorizontal * 2
        const rectangleHeight = textHeight + paddingVertical * 2

        const rectangleX = width - rectangleWidth - 10
        const rectangleY = 10

        page.drawRectangle({
          x: rectangleX,
          y: rectangleY + 5,
          width: rectangleWidth,
          height: rectangleHeight + 5,
          borderColor: color,
          borderWidth: 1,
        })

        let textX = rectangleX + paddingHorizontal
        let textY = rectangleY + rectangleHeight - paddingVertical

        text.forEach((line) => {
          page.drawText(line, {
            x: textX,
            y: textY,
            size: fontSize,
            font: helveticaFont,
            color: color,
          })
          textY -= lineHeight
        })
      })
      const formattedDate = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
        .format(new Date())
        .replace(/\//g, '_')
        .replace(', ', '-')
        .replace(':', '_')

      const pdfBytes = await pdfDoc.save()
      const base64PDF = arrayBufferToBase64(pdfBytes)
      dataFormulario.fecha_firma = formattedDateHour
      let fileName = documentacionEmpresa.find(
        (document: any) => document.Id === selectedDocument,
      ).Ruta
      const request: any = {
        JsonData: JSON.stringify(dataFormulario),
        asunto: 'Consentimiento firmado',
        mensaje:
          'Nos complace informarle que hemos recibido y procesado correctamente la documentación firmada. Su confirmación ha sido registrada con éxito.',
        emailDestinatario: dataFormulario.correo_cliente_firmante,
        archivo: {
          name: `${fileName}-${dataFormulario.nombre_firmante}-${dataFormulario.NIF_firmante}-${formattedDate}.pdf`,
          contentType: 'aplication/pdf',
          base64Data: base64PDF,
        },
      }
      const response = await sendEmailConsentimientoFirmado(request)
      if (response) {
        queryClient.invalidateQueries('consentimientos')
      }

      setConfirmationPopupVisible(false)
      setPopupVisible(false)
      reset()
      setSelectedDocument('')
    } else {
      showToast('Código incorrecto.', NotifyType.error, 2000)
    }
  }

  function generateRandomCode(): string {
    const now = new Date()
    let seed = now.getTime()
    const random = () => {
      const x = Math.sin(seed++) * 10000
      return Math.floor((x - Math.floor(x)) * 1000000)
    }

    let code = ''
    for (let i = 0; i < 6; i++) {
      code += random() % 10
    }
    return code
  }

  const handleDeleteConsentimiento = async (data: any) => {
    try {
      const response = await deleteConsentimiento(
        companyId,
        data.data.Id,
        userState!.Token!,
      )
      if (response) {
        queryClient.invalidateQueries('consentimientos')
        showToast('Consentimiento borrado correctamente', NotifyType.success)
      }
    } catch (error) {
      console.error('Error deleting consentimiento', error)
      showToast('Documentación borrada sin exito', NotifyType.error)
    }
  }

  useEffect(() => {
    if (selectedDocument == null ) {
      setDisabledSubmitButton(true)
    }
    setDisabledSubmitButton(false)
  }, [selectedDocument])

  return (
    <div style={{ overflowY: 'auto', height: '850px', margin: '0 4vh' }}>
      <h2>Consentimientos</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : consentimientos ? (
        <>
          <DataGrid
            id='gridConsentimientos'
            dataSource={filteredConsentimientos}
            rowAlternationEnabled
            showBorders
            showRowLines
            showColumnLines
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            onToolbarPreparing={handleToolbarPreparing}
            paging={{ enabled: false }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            headerFilter={{ visible: true }}
            style={{ margin: '2vh' }}
            columnChooser={{
              enabled: true,
              mode: 'select',
              height: checkScreenSize() ? 500 : 300,
              allowSearch: true,
            }}
            allowColumnReordering
          >
            <StateStoring
              enabled
              type='localStorage'
              storageKey='gridConsentimientos'
            />
            <Column
              dataField='edit'
              caption='Descargar'
              width={150}
              cellRender={(cellRenderData: any) =>
                descargarButton(cellRenderData)
              }
              allowEditing={false}
            />
            {columnsConsentimientos.map((column: any) => (
              <Column
                key={column.dataField}
                dataField={column.dataField}
                caption={column.caption
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (match: any) => match.toUpperCase())}
                visible={column.visible}
              />
            ))}
            <Column dataField={'NIF_firmante'} caption={'NIF del firmante'} allowEditing={false} />
            <Column
              dataField={'correo_cliente_firmante'}
              caption={'Email Firmante'}
              allowEditing={false}
            />
            <Column
              dataField={'nombre_firmante'}
              caption={'Nombre Firmante'}
              allowEditing={false}
            />
            <Column
              dataField={'fecha_firma'}
              caption={'Fecha Firma'}
              allowEditing={false}
            />
            {(isSuperAdmin(userState!) || isAdmin(userState!)) && (
              <Column
                dataField='delete'
                caption='Eliminar'
                width={150}
                cellRender={(cellData: any) => (
                  <Button
                    onClick={() => handleDeleteConsentimiento(cellData)}
                    style={{ margin: 'auto', display: 'flex' }}
                  >
                    <span className='dx-icon-trash'></span>
                    Eliminar
                  </Button>
                )}
                allowEditing={false}
              />
            )}
          </DataGrid>
          <Popup
            visible={popupVisible}
            dragEnabled={false}
            showTitle={true}
            title='Consentimiento'
            width={800}
            height={600}
            showCloseButton={false}
            style={{ padding: '16px', overflowY: 'auto', height: '100%' }}
          >
            <ToolbarItem
              widget='dxButton'
              toolbar='top'
              location='after'
              options={{
                icon: 'close',
                onClick: () => {
                  setPopupVisible(false)
                  setFieldsInFile([])
                  setSelectedDocument('')
                },
              }}
            />
            <ScrollView style={{ height: '100%' }}>
              <div
                style={{ padding: '10px', overflow: 'auto', height: '100%' }}
              >
                <h2>Selecciona un consentimiento</h2>
                <SelectBox
                  name='tipoDocumentacion'
                  dataSource={documentacionEmpresa}
                  valueExpr='Id'
                  displayExpr='Ruta'
                  value={selectedDocument}
                  onItemClick={(e: ItemClickEvent) => {
                    handleFileChange(e)
                  }}
                />
                <form
                  encType='multipart/form-data'
                  onSubmit={handleSubmit(onSubmit)}
                  ref={consentimientoFormRef}
                >
                  {fieldsInFile.map((campo) => {
                    const skipField = campo.toString().includes('$autorizo') // Replace 'field_to_skip' with the string you want to match
                    if (skipField) {
                      let nombre =
                        campo.match(/\$autorizo\w+/)?.[0].replace('$', '') ??
                        'autorizo'
                      return (
                        <div>
                          <Controller
                            name={nombre}
                            control={control}
                            render={({ field }) => (
                              <CheckBox
                                style={{ margin: '1rem 1rem 1rem 0' }}
                                defaultValue={
                                  field.value === undefined ? false : field.value
                                }
                                onValueChanged={(e) => field.onChange(e.value)}
                                text={campo
                                  .toString()
                                  .replace(/\$autorizo[^\s]*/g, '')
                                  .replace(/«\$empresa»/g, userState!.UserName)
                                  .replace(/\s+/g, ' ')
                                  .trim()}
                              />
                            )}
                          />
                        </div>
                      )
                    }

                    return (
                      <div>
                        <Controller
                          control={control}
                          name={campo.toString()}
                          rules={{
                            required: 'Este campo es requerido',
                            validate: (value) =>
                              getValidationRules(campo.toString(), value) ||
                              true,
                          }}
                          render={({ field: fieldProps, fieldState }) => {
                            let labelname = (fieldName: string) => {
                              return fieldName
                                .toLocaleLowerCase('es')
                                .replace(/_/g, ' ')
                                .replace(/[^a-záéíóúñ\s]/giu, '')
                                .replace(/(^|\s)\w/g, (l) => l.toUpperCase())
                            }
                            const valueChanged = (e: any) => {
                              setValue(fieldProps.name, e.value, {
                                shouldValidate: true,
                              })
                            }

                            return (
                              <div style={{ marginTop: '10px' }}>
                                <label>{labelname(fieldProps.name)}</label>
                                <TextBox
                                  key={'textBox-' + campo}
                                  name={fieldProps.name}
                                  onValueChanged={valueChanged}
                                  value={fieldProps.value || ''}
                                />
                                {fieldState.error && (
                                  <p style={{ color: 'red' }}>
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </div>
                            )
                          }}
                        />
                      </div>
                    )
                  })}
                  <Controller
                    name='correo_cliente_firmante'
                    control={control}
                    rules={{
                      required: 'Este campo es requerido',
                      validate: (value) =>
                        getValidationRules('correo_cliente', value) || true,
                    }}
                    render={({ field: fieldProps, fieldState }) => {
                      const valueChanged = (e: any) => {
                        setValue(fieldProps.name, e.value, {
                          shouldValidate: true,
                        })
                      }

                      return (
                        <div style={{ padding: '2vh 0px' }}>
                          <label
                            style={{
                              fontSize: '18px',
                              fontWeight: '500',
                            }}
                          >
                            Correo electronico del firmante
                          </label>
                          <TextBox
                            style={{ backgroundColor: '#E5E5E5' }}
                            key={'textBox-' + fieldProps.name}
                            name={fieldProps.name}
                            placeholder='Direccion de email para enviar el codigo al cliente'
                            onValueChanged={valueChanged}
                            value={fieldProps.value || ''}
                          />
                          {fieldState.error && (
                            <p style={{ color: 'red' }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )
                    }}
                  />
                  <Controller
                    name='nombre_firmante'
                    control={control}
                    rules={{
                      required: 'Este campo es requerido',
                      validate: (value) =>
                        getValidationRules('nombre_firmante', value) || true,
                    }}
                    render={({ field: fieldProps, fieldState }) => {
                      const valueChanged = (e: any) => {
                        setValue(fieldProps.name, e.value, {
                          shouldValidate: true,
                        })
                      }

                      return (
                        <div style={{ padding: '2vh 0px' }}>
                          <label
                            style={{
                              fontSize: '18px',
                              fontWeight: '500',
                            }}
                          >
                            Nombre del firmante
                          </label>
                          <TextBox
                            style={{ backgroundColor: '#E5E5E5' }}
                            key={'textBox-' + fieldProps.name}
                            name={fieldProps.name}
                            placeholder='Nombre del firmante'
                            onValueChanged={valueChanged}
                            value={fieldProps.value || ''}
                          />
                          {fieldState.error && (
                            <p style={{ color: 'red' }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )
                    }}
                  />
                  <Controller
                    name='NIF_firmante'
                    control={control}
                    rules={{
                      required: 'Este campo es requerido',
                      validate: (value) =>
                        getValidationRules('NIF', value) || true,
                    }}
                    render={({ field: fieldProps, fieldState }) => {
                      const valueChanged = (e: any) => {
                        setValue(fieldProps.name, e.value, {
                          shouldValidate: true,
                        })
                      }

                      return (
                        <div style={{ padding: '2vh 0px' }}>
                          <label
                            style={{
                              fontSize: '18px',
                              fontWeight: '500',
                            }}
                          >
                            NIF Firmante
                          </label>
                          <TextBox
                            style={{ backgroundColor: '#E5E5E5' }}
                            key={'textBox-' + fieldProps.name}
                            name={fieldProps.name}
                            placeholder='NIF para enviar el codigo al cliente'
                            onValueChanged={valueChanged}
                            value={fieldProps.value || ''}
                          />
                          {fieldState.error && (
                            <p style={{ color: 'red' }}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )
                    }}
                  />
                  <input
                    className='dx-button-mode-contained dx-button-default dx-button dx-box-item-content hoverButton'
                    type='submit'
                    value='Enviar codigo de confirmacíon'
                    disabled={disabledSubmitButton}
                    style={{
                      borderRadius: '4px',
                      padding: '5px',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                  />
                </form>
              </div>
            </ScrollView>
          </Popup>
        </>
      ) : null}
      <Popup
        visible={confirmationPopupVisible}
        dragEnabled={false}
        showTitle={true}
        showCloseButton={false}
        title='Introduce Codigo de Confirmacion'
        width={500}
        height='auto'
      >
        <ToolbarItem
          widget='dxButton'
          toolbar='top'
          location='after'
          options={{
            icon: 'close',
            onClick: () => {
              setConfirmationPopupVisible(false)
            },
          }}
        />
        <form onSubmit={handleConfirmacion(onConfirmationSubmit)}>
          <Form>
            <Item>
              <Label location='top'>Código de confirmación</Label>
              <Controller
                name='ConfirmationCode'
                control={controlCodigoConfirmacion}
                render={({ field }) => {
                  function valueChanged(e: any) {
                    field.onChange(e.value)
                  }

                  return <TextBox onValueChanged={valueChanged} />
                }}
              />
            </Item>
            <ButtonItem>
              <ButtonOptions
                useSubmitBehavior
                text='Confirmar Codigo'
                type='default'
              />
            </ButtonItem>
          </Form>
        </form>
      </Popup>
    </div>
  )
}
