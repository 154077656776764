import { ListaArchivosModel } from '../../../models/ListaArchivosModel'

export const ADD_PAGINA = 'ADD_PAGINA'
export const ADD_PAGINAS = 'ADD_PAGINAS'
export const ADD_COMPRADOR_REPRESENTANTE = 'ADD_COMPRADOR_REPRESENTANTE'
export const ADD_VENDEDOR_REPRESENTANTE = 'ADD_VENDEDOR_REPRESENTANTE'
export const ADD_TIPO_VIAS = 'ADD_TIPO_VIAS'
export const ADD_TIPO_VEHICULO = 'ADD_TIPO_VEHICULO'
export const ADD_SERVICIO_DESTINO = 'ADD_SERVICIO_DESTINO'
export const ACTUALIZAR_DATOS_SOLICITUD = 'ACTUALIZAR_DATOS_SOLICITUD'
export const ACTUALIZAR_COMPRADOR = 'ACTUALIZAR_COMPRADOR'
export const ACTUALIZAR_VENDEDOR = 'ACTUALIZAR_VENDEDOR'
export const ACTUALIZAR_DATOS_VEHICULO = 'ACTUALIZAR_DATOS_VEHICULO'
export const ACTUALIZAR_FOCUS_INPUT = 'ACTUALIZAR_DATOS_VEHICULO'
export const LISTA_ARCHIVOS = 'LISTA_ARCHIVOS'
export const ACTUALIZAR_ARCHIVOS = 'ACTUALIZAR_ARCHIVOS'

export interface FormDatosCamposUtilsState {
  pagina: number | undefined
  paginas: number | undefined
  compradorrepresentante: boolean | undefined
  vendedorrepresentante: boolean | undefined
  tipovias: Array<Object> | undefined
  tipovehiculo: Array<Object> | undefined
  serviciodestino: Array<Object> | undefined
  actualizardatossolicitud: boolean | undefined
  actualizarcomprador: boolean | undefined
  actualizarvendedor: boolean | undefined
  actualizardatosvehiculo: boolean | undefined
  actualizarfocusinput: string | undefined
  listaarchivos: Array<ListaArchivosModel> | undefined
  archivos: Array<File> | undefined
}

interface addPagina {
  type: typeof ADD_PAGINA
  payload: number | undefined
}

interface addPaginas {
  type: typeof ADD_PAGINAS
  payload: number | undefined
}

interface addCompradorRepresentante {
  type: typeof ADD_COMPRADOR_REPRESENTANTE
  payload: boolean | undefined
}
interface addVendedorRepresentante {
  type: typeof ADD_VENDEDOR_REPRESENTANTE
  payload: boolean | undefined
}

interface addTipoVias {
  type: typeof ADD_TIPO_VIAS
  payload: Array<Object> | undefined
}

interface addTipoVehiculo {
  type: typeof ADD_TIPO_VEHICULO
  payload: Array<Object> | undefined
}

interface addServicioDestino {
  type: typeof ADD_SERVICIO_DESTINO
  payload: Array<Object> | undefined
}

interface addActualizarListaArchivos {
  type: typeof LISTA_ARCHIVOS
  payload: Array<ListaArchivosModel> | undefined
}

interface actualizarFocusInput {
  type: typeof ACTUALIZAR_FOCUS_INPUT
  payload: string | undefined
}

interface actualizarArchivos {
  type: typeof ACTUALIZAR_ARCHIVOS
  payload: Array<File>
}

export type formDatosCamposUtilsActions =
  | addPagina
  | addPaginas
  | addCompradorRepresentante
  | addVendedorRepresentante
  | addTipoVias
  | addTipoVehiculo
  | addServicioDestino
  | actualizarFocusInput
  | addActualizarListaArchivos
  | actualizarArchivos
