import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { checkStatusCode, NotifyType, showToast } from 'src/utils/sharedUitls';
import { DocumentoCampoDatoModel } from '../models/documentoCampoDatoModel';

export const getDocumentosByExpediente = async (
  expedienteId: number,
  user: UserModel,
): Promise<DocumentoCampoDatoModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Documento/getByExpediente/${expedienteId}`;
    let documentoGrid = new DocumentoCampoDatoModel();

    await axiosInstance.get(url).then((response: any) => {
        checkStatusCode(response.status)
        documentoGrid = jsonConvert.deserializeObject(
          response.data, DocumentoCampoDatoModel,
        );
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los documentos', NotifyType.error, 5000
        );
      });
    return documentoGrid;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  }

  return new DocumentoCampoDatoModel();
}
