import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
class ModulesClaims {
  @JsonProperty('ModuleName', String)
  ModuleName: string = '';

  @JsonProperty('ModuleKey', String)
  ModuleKey: string = '';

  @JsonProperty('Claims', Array<Claims>)
  Claims: Claims[] = [];
}

@JsonObject('')
class Claims {
  @JsonProperty('Permission', String)
  Permission: string = '';

  @JsonProperty('IsGranted', Boolean)
  IsGranted: boolean = true;

  @JsonProperty('Editable', Boolean)
  Editable: boolean = true;
}

@JsonObject('')
class RolePermissionsModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Name', String)
  Name: string = '';

  @JsonProperty('ModulesClaims', Array<ModulesClaims>)
  ModulesClaims: ModulesClaims[] = []
}

export {
  ModulesClaims,
  Claims,
  RolePermissionsModel
}