import {
  CompanyCampoDatoGridModel,
  CompanyCampoDatoModel,
} from 'src/models/company/companyCampoDatoModel'
import { CompanyModel } from 'src/models/company/companyModel'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'

export interface CompanyState {
  company: Partial<CompanyModel> | CompanyModel | undefined
  companies: CompanyCampoDatoModel | CompanyCampoDatoGridModel | undefined
  companyModulos: CompanyModuloModel[] | undefined
  companyModulosSelected: CompanyModuloModel[] | undefined
  parentCompany: Partial<CompanyModel> | CompanyModel | undefined
  parentCompanyModulos: CompanyModuloModel[] | undefined
  codigoViaFirma: string | undefined
}

export const ADD_COMPANIES = 'ADD_COMPANIES'
export const ADD_COMPANY = 'ADD_COMPANY'
export const CLEAR_COMPANY = '@todo/CLEAR_COMPANY'
export const ADD_COMPANY_MODULO = 'ADD_COMPANY_MODULO'
export const CLEAR_COMPANY_MODULO = 'CLEAR_COMPANY_MODULO'
export const ADD_COMPANY_MODULO_SELECTED = 'ADD_COMPANY_MODULO_SELECTED'
export const ADD_PARENT_COMPANY = 'ADD_PARENT_COMPANY'
export const ADD_COMPANY_MODULO_PARENT = 'ADD_COMPANY_MODULO_PARENT'
export const ADD_COMPANY_CODIGO_VIAFIRMA = 'ADD_COMPANY_CODIGO_VIAFIRMA'

interface AddCompaniesRequest {
  type: typeof ADD_COMPANIES
  payload: CompanyCampoDatoModel | CompanyCampoDatoGridModel | undefined
}
interface AddCompanyRequest {
  type: typeof ADD_COMPANY
  payload: Partial<CompanyModel> | undefined
}
interface AddParentCompanyRequest {
  type: typeof ADD_PARENT_COMPANY
  payload: Partial<CompanyModel> | undefined
}
interface ClearCompanyRequest {
  type: typeof CLEAR_COMPANY
  payload: CompanyModel | undefined
}
interface AddCompanyModulosRequest {
  type: typeof ADD_COMPANY_MODULO
  payload: CompanyModuloModel[] | undefined
}
interface AddCompanyModulosSelectedRequest {
  type: typeof ADD_COMPANY_MODULO_SELECTED
  payload: CompanyModuloModel[] | undefined
}
interface ClearCompanyModulosRequest {
  type: typeof CLEAR_COMPANY_MODULO
  payload: CompanyModuloModel[] | undefined
}

interface AddParentCompanyModulosRequest {
  type: typeof ADD_COMPANY_MODULO_PARENT
  payload: CompanyModuloModel[] | undefined
}

interface AddCompanyCodigoViaFirma {
  type: typeof ADD_COMPANY_CODIGO_VIAFIRMA
  payload: string | undefined
}

export type CompanyActionsTypes =
  | AddCompaniesRequest
  | AddCompanyRequest
  | ClearCompanyRequest
  | AddCompanyModulosRequest
  | AddCompanyModulosSelectedRequest
  | ClearCompanyModulosRequest
  | AddParentCompanyRequest
  | AddParentCompanyModulosRequest
  | AddCompanyCodigoViaFirma
