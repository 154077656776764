import axiosInstance from '../../../utils/axios'
import {
  checkStatusCode,
  customLog,
  logType,
  methodsEndPointType,
} from '../../../utils/sharedUitls'
import { TasaModel } from '../models/TasaModel'
import {
  LineaVehiculo,
  RemesaInformes,
  VehiculoUsuario,
} from '../../tasas/interfaces/InformesVehiculosInterfaces'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

export const getAllTasasLibres = async (): Promise<TasaModel[]> => {
  try {
    const response = await axiosInstance.get<TasaModel[]>(
      '/TasasDgt/Tipo/4.1/0',
    )
    checkStatusCode(response.status)
    return response.data
  } catch (error: any) {
    customLog(error, logType.ERROR, methodsEndPointType.GET)
    return []
  }
}

export const cambiarEstadoTasas = async (
  idTasa: number,
  estado: number,
): Promise<void> => {
  try {
    const url = `/TasasDgt/${idTasa}`
    const response = await axiosInstance.patch(url, { estado })
    checkStatusCode(response.status)
  } catch (error: any) {
    customLog(error, logType.ERROR, methodsEndPointType.PATCH)
    throw error
  }
}

export const solicitarInformesVehiculosDgt = async (
  data: VehiculoUsuario[],
) => {
  try {
    const lineas = data.map((item): LineaVehiculo => {
      return {
        NumeroTasa: item.Tasa ?? '',
        IdentificacionVehiculo: item.Bastidor,
        IdentificacionSolicitante: item.NifCliente,
        NombreSolicitante: item.NombreCliente,
        ExpedienteId: item.ExpedienteId,
      }
    })

    // console.log('Datos enviados a la API:', lineas)

    const response = await axiosInstance.post('/RemesasInformes', {
      lineas: lineas,
    })

    checkStatusCode(response.status)
    return response.data
  } catch (error: any) {
    console.error('Error en solicitarInformesVehiculosDgt:', error)
    customLog(error, logType.ERROR, methodsEndPointType.GET)
    throw new Error(error.response?.data?.Message || 'Unknown error', {
      cause: error.response?.data?.ErrorType,
    })
  }
}

export const getAllSolicitudesColaborador = async () => {
  try {
    const response = await axiosInstance.get(
      '/InformesExternalDossier/Pendientes',
    )
    checkStatusCode(response.status)
    return response.data
  } catch (error: any) {
    customLog(error, logType.ERROR, methodsEndPointType.GET)
    return []
  }
}
