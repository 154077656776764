import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { IdocCarConfigModel } from '../models/idocCarConfigModel'

const serviceName = 'authService'

export const getIdocCarConfigList = async (
  user: UserModel,
): Promise<Array<IdocCarConfigModel> | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ExternalServiceSetup`
    new SolicitudListCampoDatoModel()
    let datos: any
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los registros.',
          NotifyType.error,
          5000,
        )
        checkStatusCode(error.response.status)
      })
    return datos
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

export async function postIdocCarConfig(
  user: UserModel,
  Solicitud: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `ExternalServiceSetup`

    await axiosInstance.post(url, Solicitud).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `Solicitud creado ${response.data.Id}`,
        logType.INFO,
        serviceName,
        postIdocCarConfig.name,
        methodsEndPointType.POST,
      )
      showToast('Solicitud creada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    customLog(
      `Error al crear Solicitud => ${error.message}`,
      logType.ERROR,
      serviceName,
      postIdocCarConfig.name,
      methodsEndPointType.POST,
    )
    checkStatusCode(error.status)
    showToast(`Error al crear la solicitud`, NotifyType.error, 5000)
    return false
  }
}

export async function putIdocCarConfig(
  user: UserModel,
  idocCarConfig: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ExternalServiceSetup`

    await axiosInstance
      .put(url, idocCarConfig)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud modificado ${idocCarConfig.Id}`,
          logType.INFO,
          serviceName,
          putIdocCarConfig.name,
          methodsEndPointType.PUT,
        )
        showToast('Configuración editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar caonfiguración iDocCar => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarConfig.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}

export async function deleteIdocCarConfig(
  user: UserModel,
  id: number,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `ExternalServiceSetup/` + id

    await axiosInstance
      .delete(url)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud delete ${id}`,
          logType.INFO,
          serviceName,
          putIdocCarConfig.name,
          methodsEndPointType.PUT,
        )
        showToast('Registro borrado correctamente', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        showToast('No se ha podido borrar el registro.', NotifyType.error, 5000)
        customLog(
          `Error al editar Solicitud => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarConfig.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}

export async function getStructure(
  user: UserModel,
): Promise<SolicitudListCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `ExternalDossier/GetStructure`

  let sujeto: SolicitudListCampoDatoModel = new SolicitudListCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sujeto = jsonConvert.deserializeObject(
        response.data,
        SolicitudListCampoDatoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return sujeto
}