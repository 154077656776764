import React, { MutableRefObject, useCallback, useEffect,
  useRef, useState } from 'react';
import { Button, DataGrid } from 'devextreme-react';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { useScreenSize } from 'src/utils/media-query';
import { NotifyType, checkScreenSize, getColumnAlignment,
  showToast } from 'src/utils/sharedUitls';
import { TemplateModel } from '../models/templateModel/templateModel';
import { addTemplate, addTemplatesFields,
  } from '../redux/template/TemplateActions';
import { _getMapperTemplate, _getTemplatesFields,
  _getTemplateWithChild} from '../services/plantillaService';
import { _getModulesGrid } from '../services/moduloServices';
import { FieldDataModelComponent } from '../models/modelComponent/fieldDataModelComponent';
import { OnExporting } from 'src/utils/export/ExportExcel';

interface Props {
  plantillas: TemplateModel[];
  verpopup: Function;
  estadoGrid: Boolean;
  modulo: Number;
  setplantillas: Function;
  setplantilla: Function;
};

function ModulesListPage({
  plantillas,
  verpopup,
  estadoGrid,
  modulo,
  setplantillas,
  setplantilla,
}: Props) {
  const dispatch = useDispatch();
  const [modules, setModules] = useState(new FieldDataModelComponent());
  const [rowSelected, setRow] = useState(0);
  const userState = useSelector((state: RootState) => state.user);
  const popupTemplatesRef: MutableRefObject<any> = useRef(null);
  const { isLarge, isMedium, isSmall, isXSmall } = useScreenSize();

  async function obtenerModulos() {
    let modules = await _getModulesGrid(userState!.user!, modulo);
    setModules(modules!);
  };

  useEffect(() => {
    obtenerModulos();
  }, [estadoGrid]);

  function getCurrency() {
    let defaultCurrency = {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      minimumSignificantDigits: 4,
      type: 'currency',
      precision: 2,
    }
  }
  let getColumnType = function getColumnType(type: any, format: any) {
    switch (type) {
      case 'currency':
        return getCurrency()

      case 'string':
        return null

      case 'number':
        if (format === 'decimal') {
          return getCurrency()
        }

        return null

      case 'date':
        return 'dd/MM/yyyy'

      case 'datetime':
        return 'dd/MM/yyyy'

      case 'boolean':
        return 'boolean'

      case 'decimal':
        return getCurrency()

      case null:
        if (format === 'float') {
          return getCurrency()
        } else {
          return null
        }

      default:
        return null
    }
  }

  const openCreatePopup = async () => {
    if (plantillas.length > 0) {
      dispatch(addTemplate(new TemplateModel()))
      dispatch(
        addTemplatesFields(
          await _getTemplatesFields(userState!.user!, plantillas[0].Id),
        ),
      )
      verpopup()
    } else {
      showToast(
        'Selecciona un componentes y su plantilla',
        NotifyType.info, 5000,
      );
    };
  };

  let gridheight = '600;';
  gridheight = isXSmall ? '300' : '600';
  gridheight = isSmall ? '400' : '600';
  gridheight = isMedium ? '400' : '400';
  gridheight = isLarge ? '400' : '400';
  function _onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: function onClick(_e: any) {
            popupTemplatesRef.current!.instance.refresh(true)
          },
        },
      },
    );
  };

  const loadState = useCallback(() => {
    if (modules.Datos.length > 0) {
      return JSON.parse(localStorage.getItem('modulosList')!)
    }
  }, [modules.Datos])

  const saveState = useCallback((state: { columns: string | any[] }) => {
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null
      }
    }
    localStorage.setItem('modulosList', JSON.stringify(state))
  }, [])

  return (
    <DataGrid
      id='plantillaDataGrid'
      keyExpr='Id'
      ref={popupTemplatesRef}
      dataSource={modules.Datos}
      style={{ width: '100%' }}
      height={gridheight}
      allowColumnReordering
      allowColumnResizing
      cacheEnabled
      columnAutoWidth
      focusedRowEnabled
      remoteOperations
      repaintChangesOnly
      rowAlternationEnabled
      showBorders
      showRowLines
      columnChooser={{ enabled: true }}
      columnHidingEnabled={!checkScreenSize()}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
      headerFilter={{ visible: false }}
      loadPanel={{ enabled: true }}
      paging={{ enabled: false }}
      sorting={{ mode: 'multiple', showSortIndexes: false }}
      export={{
        allowExportSelectedData: true,
        enabled: true,
      }}
      searchPanel={{
        visible: true,
        width: checkScreenSize() ? 240 : 120,
      }}
      selection={{
        mode: 'multiple',
        showCheckBoxesMode: isLarge ? 'always' : 'none',
      }}
      scrolling={{
        mode: 'standard',
        scrollByContent: true,
        scrollByThumb: true,
        showScrollbar: 'always',
      }}
      onToolbarPreparing={function onToolbarPreparing(event) {
        _onToolbarPreparing(event)
      }}
      onExporting={OnExporting}
    >
      <StateStoring
        enabled={true}
        type={'custom'}
        customLoad={loadState}
        customSave={saveState}
        storageKey={'modulosList'}
      />
      <Column
        type='buttons'
        width={50}
        cellRender={(row: any) => (
          <Button
            width='32px'
            text=''
            icon='edit'
            hint='Editar'
            onClick={async () => {
              setRow(row.data)
              const plantillasChild = await _getTemplateWithChild(
                userState!.user!,
                row.data.IdPlantilla,
              )
              setplantillas(plantillasChild)

              setplantilla(
                _getMapperTemplate(
                  plantillasChild.length > 0
                    ? plantillasChild[0]
                    : new TemplateModel(),
                ),
              )
            }}
          />
        )}
      />
      {modules.Campos.map((value: any) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Texto}
          dataType={value.Tipo}
          alignment={getColumnAlignment(value.Tipo)}
          allowFiltering={value.Nombre !== 'TipoUsuario'}
          allowGrouping={value.Nombre !== 'Totfactura'}
          allowHeaderFiltering={value.Tipo !== 'date'}
          format={getColumnType(value.Tipo, value.Format)}
        />
      ))}
    </DataGrid>
  )
}

export { ModulesListPage };
