import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { UserModel } from 'src/models/usuario/userModel'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'
import { isRolAllowed } from './allowAuthUtil'

export enum Modules {
  ADMINISTRACION = 'Administracion',
  CALCULADORA = 'Calculadora',
  EXPEDIENTES_VEHICULOS = 'ExpedientesVehiculos',
  EXPEDIENTES_ESCRITURAS = 'ExpedientesEscrituras',
  SUPERADMINISTRADOR = 'SupeAdministrador',
  SOLICITUDES = 'Solicitudes',
  SOLICITUDES_TIPOS = 'SolicitudesTipos',
  IDOCCAR = 'iDocCar',
  MICRODATOS = 'Microdatos',
  DATOSDEEMPRESA = 'DatosDeEmpresas',
  FIRMADIGITAL = 'FirmaDigital',
  RGPD = 'RGPD',
  INFORMESDEVEHICULOS = 'InformesVehiculos',
}

function IsModuloAllowed(user: UserModel, modulo: Modules): boolean {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  if (modulo === Modules.ADMINISTRACION) {
    return isRolAllowed(user, modulo, [])
  }

  const parentCompanyModules = localStorage.getItem('parentCompanyModulos')
  if (parentCompanyModules !== 'undefined' && parentCompanyModules  != null ) {
    const modulesParent = jsonConvert.deserializeArray(
      JSON.parse(localStorage.getItem('parentCompanyModulos')!),
      CompanyModuloModel,
    )
    var findModulo = modulesParent.find((x) => x.Code === modulo)
    if (!!findModulo) return true
  }
  return false
}

function getModulesAllowed(user: UserModel){
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  const parentCompanyModules = localStorage.getItem('parentCompanyModulos')
  return jsonConvert.deserializeArray(
    JSON.parse(parentCompanyModules!),
    CompanyModuloModel,
  )
}

export { IsModuloAllowed, getModulesAllowed }
  
