import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { checkStatusCode, customLog, logType, methodsEndPointType } from 'src/utils/sharedUitls';
import { NotifyType, showToast } from '../../../../utils/sharedUitls';
import { LinkModel } from 'src/models/administracionPages/LinkModel';

const serviceName = 'EnlaceService';

const getLinks = async (
  user: UserModel,
  idCompany: number,
): Promise<LinkModel[] | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Enlace/GetByCompany/${idCompany}`;
    let linksData: LinkModel[] = [];

    await axiosInstance
      .get(url)
      .then((response: any) => {
        checkStatusCode(response.status);
        linksData = jsonConvert.deserializeArray(response.data, LinkModel);
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener la configuración Legal',
          NotifyType.error, 5000
        );
      });
    return linksData;
;  } catch (err) {
    console.error(err);
  }
}

const postLinks = async (
  user: UserModel,
  links: Partial<LinkModel[]> | LinkModel[] | undefined,
  idCompany: number,
): Promise<boolean> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Enlace`;

    if (links !== undefined) {
      links = fillCompany(links as LinkModel[], idCompany)
    }

    await axiosInstance.post(url, links).then(async (response: any) => {
      checkStatusCode(response.status);
      customLog(
        `enlaces creados ${response.data.Id}`,
        logType.INFO,
        serviceName,
        postLinks.name,
        methodsEndPointType.POST,
      );
    })
    return true;
  } catch (error: any) {
    // showToast(error.response.data, NotifyType.error, 5000)
    customLog(
      `Error al crear enlaces => ${error.response.data}`,
      logType.ERROR,
      serviceName,
      postLinks.name,
      methodsEndPointType.POST,
    )
    return false;
  }
}

const putLinks = async (
  user: UserModel,
  links: Partial<LinkModel[]> | LinkModel[] | undefined,
  idCompany: number,
): Promise<boolean> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Enlace`;
    if (links !== undefined) {
      links = fillCompany(links as LinkModel[], idCompany);
    }
    await axiosInstance
      .put(url, links)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Enlaces modificados`,
          logType.INFO,
          serviceName,
          putLinks.name,
          methodsEndPointType.PUT,
        )
        showToast('Enlaces modificados', 
          NotifyType.success, 5000
        );
      })
      .catch((error: any) => {
        customLog(
          `Error al editar enlaces`,
          logType.ERROR,
          serviceName,
          putLinks.name,
          methodsEndPointType.PUT,
        )
      })
    return true;
  } catch (error) {
    return false;
  }
}

const fillCompany = (
  links: LinkModel[],
  idCompany: number,
): LinkModel[] => {
  for (const enlace of links!) {
    enlace!.IdCompany = idCompany
  }
  return links;
}

export { 
  getLinks,
  postLinks,
  putLinks
};