import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'devextreme-react';
import { Item, Label, PatternRule, RequiredRule } from 'devextreme-react/form';
import { RootState } from '../../../../redux/combineReducers';
import { useScreenSize } from '../../../../utils/media-query';
import { addLinks, addParentLinks } from '../redux/link/linkActions';
import { LinkModel } from 'src/models/administracionPages/LinkModel';
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm';
import { useForm } from 'react-hook-form';
import { addCompany } from '../redux/companiesActions';
import { postCompany, putCompany } from '../services/companiesServices';
import { addDataFormChange } from '../../../../redux/actions/sharedUtilsActions';
import { getLinks } from '../services/linksService';
import { refreshGrid } from '../../../../utils/sharedUitls';

function FormLinkComponent(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user);
  const companyState = useSelector((state: RootState) => state.company);
  const company = useSelector((state: RootState) => state.company?.company);
  const calculadoraState = useSelector(
    (state: RootState) =>
      state.calculatorTransfers?.calculatorTransfers,
  );
  const emailState = useSelector((state: RootState) => state.email?.email);
  const linkState = useSelector((state: RootState) => state.link);
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  );
  const { isLarge } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const formData = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [])
  const onSubmit = async (data: any) => {
    setLoading(true)
    let result: boolean = false
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }))
    }
    if (company?.Id) {
      result = await putCompany(
        userState!.user!,
        company,
        calculadoraState,
        linkState?.links!,
        companyState,
        emailState,
      )
    } else {
      result = await postCompany(
        userState!.user!,
        company!,
        calculadoraState,
        linkState?.links!,
        companyState,
        emailState,
      ).then(() => {
        return true
      })
    }

    if (result) {
      setLoading(false)
      refreshGrid(props.setShowForm, true)
      props.setShowForm(false)
      localStorage.setItem(
        'parentCompanyModulos',
        JSON.stringify(companyState?.companyModulos),
      )
      localStorage.setItem(
        'parentlinks',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      )
    } else {
      setLoading(false)
    }
  }
  const handleUpdatedEnlace = (property: any) => {
    dispatch(
      addLinks({
        ...linkState?.links,
        ...property,
      }),
    )
  }

  function getEnlaceValue(codigoEnlace: string) {
    let value = ''
    if (linkState?.links!) {
      linkState?.links.forEach((x: any) => {
        if (x.CodigoTipoEnlace === codigoEnlace) {
          value = x.EnlaceValue
        }
      })
    }
    return value
  }

  //comprobaremos si existe el enlace y lo añadiremos o lo updatearemos

  const handleCancelClick = () => {
    dispatch(addLinks(undefined));
    props.setShowForm(false);
  }
  const renderButtons = () => {
    return ButtonsForm(`enlacesData`, handleCancelClick, false);
  }
  const enlacesFormRef: any = useRef(null);
  if (!props.showForm) {
    if (enlacesFormRef  != null ) {
      enlacesFormRef.current?.instance.resetValues();
    }
  }
  return (
    <>
      <div
        style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.6%' }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Form
              formData={formData.current}
              disabled={loading}
              ref={enlacesFormRef}
              labelLocation={isLarge ? 'left' : 'top'}
              validationGroup='enlacesData'
            >
              <Item
                dataField={'linkLegal'}
                editorType={'dxTextBox'}
                editorOptions={{
                  disabled: false,
                  placeholder: 'Link a Legal',
                  value: getEnlaceValue('Legal'),
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      let link: LinkModel | undefined =
                        linkState?.links?.find(
                          (x: any) =>
                            x.IdTipoEnlace === 1 &&
                            x.IdCompany === companyState?.company?.Id,
                        ) as never
                      if (link === undefined) {
                        link = {
                          Id: 0,
                          IdCompany: companyState?.company?.Id!,
                          IdTipoEnlace: 1,
                          EnlaceValue: event.value,
                          CodigoTipoEnlace: 'Legal',
                        }
                      } else {
                        const index = linkState?.links!.indexOf(link)
                        link = link as LinkModel
                        link.EnlaceValue = event.value
                        linkState?.links!.splice(index!, 1)
                      }

                      linkState?.links?.push(link as never)
                      dispatch(addLinks(linkState?.links!))
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <PatternRule
                  message='Debe introducir una URL. ex: https://www.ejemplo.es '
                  pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                />
                <Label visible={true} text='Link a legal' />
              </Item>

              <Item
                dataField={'linkPrivacidadCookies'}
                editorType={'dxTextBox'}
                editorOptions={{
                  disabled: false,
                  placeholder: 'Link a términos de privacidad y cookies',
                  value: getEnlaceValue('Cookies'),
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      let link: LinkModel | undefined =
                        linkState?.links?.find(
                          (x: any) =>
                            x.IdTipoEnlace === 2 &&
                            x.IdCompany === companyState?.company?.Id,
                        ) as never
                      if (link === undefined) {
                        link = {
                          Id: 0,
                          IdCompany: companyState?.company?.Id!,
                          IdTipoEnlace: 2,
                          EnlaceValue: event.value,
                          CodigoTipoEnlace: 'Cookies',
                        }
                      } else {
                        const index = linkState?.links!.indexOf(link)
                        link = link as LinkModel
                        if (event.value === '') {
                          link.EnlaceValue = ''
                          link.CodigoTipoEnlace = ''
                        } else {
                          link.EnlaceValue = event.value
                          link.CodigoTipoEnlace = 'Cookies'
                        }
                        linkState?.links!.splice(index!, 1);
                      }
                      linkState?.links?.push(link as never);
                      dispatch(addLinks(linkState?.links!));
                      if (!isChangedState) dispatch(addDataFormChange(true));
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <PatternRule
                  message='Debe introducir una URL. ex: https://www.ejemplo.es '
                  pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                />
                <Label
                  visible={true}
                  text='Link a términos de privacidad y cookies'
                />
              </Item>
              <Item
                dataField={'LinkRGPD'}
                editorType={'dxTextBox'}
                editorOptions={{
                  disabled: false,
                  placeholder: 'Link a RGPD',
                  value: getEnlaceValue('RGPD'),
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      let link: LinkModel | undefined =
                        linkState?.links?.find(
                          (x: any) =>
                            x.IdTipoEnlace === 3 &&
                            x.IdCompany === companyState?.company?.Id,
                        ) as never
                      if (link === undefined) {
                        link = {
                          Id: 0,
                          IdCompany: companyState?.company?.Id!,
                          IdTipoEnlace: 3,
                          EnlaceValue: event.value,
                          CodigoTipoEnlace: 'RGPD',
                        }
                      } else {
                        const index = linkState?.links!.indexOf(link);
                        link = link as LinkModel;
                        link.EnlaceValue = event.value;
                        linkState?.links!.splice(index!, 1);
                      }

                      linkState?.links?.push(link as never);
                      dispatch(addLinks(linkState?.links!));
                      if (!isChangedState) dispatch(addDataFormChange(true));
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <PatternRule
                  message='Debe introducir una URL. ex: https://www.ejemplo.es '
                  pattern='https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                />
                <Label visible={true} text='Link a rgpd' />
              </Item>
            </Form>
          </form>
        </div>
      </div>
    </>
  );
}

export { FormLinkComponent };
