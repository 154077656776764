import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as events from 'devextreme/events'
import {RootState} from 'src/redux/combineReducers'
import {useSelector} from 'react-redux'
import {ClaimsModule, isAdmin, isRolAllowed, isSuperAdmin} from 'src/utils/allowAuthUtil'
import {IsModuloAllowed} from 'src/utils/allowModuloUtil'
import {TreeView} from 'devextreme-react'
import {
  filtrarRutas,
  Ruta,
  rutasAdministracion,
  rutasAplicaciones,
  rutasConfiguracion,
} from '../../utils/RutasMenu'
import {UserModel} from '../../models/usuario/userModel'
import {PopupTemas} from "./themes/PopupTemas";
import {hasUserClaimsToModule} from "../../utils/allowAuthUtil";

interface Props {
  children: React.ReactNode
  closeMenu: Function
  compactMode: boolean
  menuStatus: boolean
  openMenu: Function
  sub1: number
  sub2: number
  editarsubmenu: Function
  defecto: Function
  espacio: Function
  fijo: Boolean
}

// ----------------------------------------------------------------------------

function Menu({
                children,
                closeMenu,
                compactMode,
                menuStatus,
                openMenu,
                sub1,
                sub2,
                editarsubmenu,
                defecto,
                espacio,
                fijo,
              }: Props) {
  const userState = useSelector((state: RootState) => state.user?.user)
  const navigate = useNavigate()
  const wrapperRef = useRef()
  const [estadoFocoMenu, setFocoMenu] = useState(false)
  const [popUpThemes, setPopUpThemes] = useState(false)

  const getWrapperRef = useCallback(
      (element: any) => {
        const prevElement = wrapperRef.current
        if (prevElement) {
          events.off(prevElement, 'dxclick')
        }

        wrapperRef.current = element
        events.on(element, 'dxclick', (e: any) => {
          if (
              !e.target.className.includes('verticalaligncenter') &&
              !e.target.className.includes('dx-icon-chevrondoubleleft') &&
              !e.target.className.includes('dx-toolbar-items-container') &&
              !e.target.className.includes(
                  'dx-box-flex dx-box dx-widget dx-collection',
              ) &&
              !e.target.className.includes('version') &&
              e.target.innerText !== 'Expedientes' &&
              e.target.innerText !== 'Conectores' &&
              e.target.innerText !== 'IdocCar' &&
              e.target.innerText !== 'Consultas' &&
              e.target.innerText !== 'Datos de empresa' &&
              e.target.innerText !== 'Firma Digital' &&
              e.target.innerText !== 'Permisos' &&
              e.target.innerText !== 'RGPD'
          ) {
            closeMenu(e)
          }
        })
      },

      [openMenu],
  )

  useEffect(() => {
    if (!menuStatus) {
      editarsubmenu()
    } else {
      defecto()
    }

    setFocoMenu(!estadoFocoMenu)
  }, [menuStatus])

  const rutasAplicacionView = useMemo(() => filtrarRutas(rutasAplicaciones, userState!), [rutasAplicaciones, userState])
  const rutasAdministracionView = useMemo(() => filtrarRutas(rutasAdministracion, userState!), [rutasAdministracion, userState])
  const rutasConfiguracionView = useMemo(() => filtrarRutas(rutasConfiguracion, userState!), [rutasConfiguracion, userState])
  
  const routeRender = (item: any): React.ReactNode => {
    const handleClick = () => {
      if (item.path) {
        if (item.path === 'temas') {
          setPopUpThemes(true)
        } else {
          navigate(item.path);
        }
      }
    };

    return (
        <div style={{display: 'flex', alignItems: 'center'}} onClick={handleClick}>
          <i className={`dx-icon-${item.icon}`} style={{fontWeight: 'bold', fontSize: '23pt'}}></i>
          <span style={{fontWeight: 'bold', display: menuStatus ? 'block' : 'none'}}
                className='dx-button-text'>{item.text}</span>
        </div>
    );
  };

  return (
      <div
          id={'panelList'}
          ref={getWrapperRef}
          onMouseOver={() => {
            openMenu()
          }}
          onMouseLeave={() => {
            if (!fijo) {
              closeMenu()
              espacio(220)
            } else {
              openMenu()
            }
          }}
      >
        {children}
        <br style={{display: menuStatus ? 'none' : 'block'}}/>
        <h5 style={{
          fontWeight: 'bold',
          marginBottom: '4px',
          display: menuStatus && rutasAplicacionView.length > 0 ? 'block' : 'none'
        }}>APLICACIONES</h5>
        <TreeView visible={rutasAplicacionView.length > 0} dataSource={rutasAplicacionView} itemRender={routeRender}
                  expandEvent='click'/>
        <h5 style={{
          fontWeight: 'bold',
          marginBottom: '4px',
          display: menuStatus && rutasAdministracionView.length > 0 ? 'block' : 'none'
        }}>ADMINISTRACIÓN</h5>
        <TreeView visible={rutasAdministracionView.length > 0} dataSource={rutasAdministracionView} itemRender={routeRender}
                  expandEvent='click'/>
        <h5 style={{
          fontWeight: 'bold',
          marginBottom: '4px',
          display: menuStatus && rutasConfiguracionView.length > 0 ? 'block' : 'none'
        }}>CONFIGURACIÓN</h5>
        <TreeView
            visible={rutasConfiguracionView.length > 0}
            dataSource={rutasConfiguracionView}
            itemRender={routeRender}
            expandEvent='click'/>
        {menuStatus ? (
            <div
                className='version'
                style={{
                  fontSize: 11, color: '#0475A5', fontWeight: 'bold', marginTop: '10px',
                  display: menuStatus ? 'block' : 'none'
                }}
            >
              Versión:
              <p className='version'>
                Compilación: {process.env.REACT_APP_VERSION}
              </p>
              <p className='version'>
                Fecha: {process.env.REACT_APP_VERSION_DATE}
              </p>
            </div>
        ) : undefined}
        <PopupTemas
            visible={popUpThemes}
            closePopup={() => setPopUpThemes(false)}
        />
      </div>
  )
}

export {Menu}
