import axios, { AxiosError } from 'axios'
import { logout } from './sharedUitls'
export interface AxiosInstanceError {
  error: {
    code: number | null
    data: any
    details: any
    message: string
    valdiationErrors: any
  }
}

const firmaDigitalAxios = axios.create({
  baseURL: process.env.REACT_APP_API_FIRMADIGITAL_URL,
})

export function setTokenFirmaDigitalAuth(token: string) {
  firmaDigitalAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

firmaDigitalAxios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AxiosInstanceError>) => {
    if (error.response?.status === 401) {
      logout()
    }

    const responseError =
      (error.response && error.response?.data) ?? 'Something went wrong'
    return Promise.reject(responseError)
  },
)

export default firmaDigitalAxios
