import React from 'react'
import { Button } from 'devextreme-react'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'

export const ButtonsForm = (
  validationGroup: string,
  handleCancelClick: () => void,
  gesgestoria: boolean,
  listaModulos?: CompanyModuloModel[] | undefined,
) => {
  return (
    <>
      <Button
        icon='remove'
        text='Cancelar'
        type='normal'
        stylingMode='outlined'
        style={{
          float: 'right',
          margin: '1em',
          fontWeight: 'bold',
        }}
        onClick={handleCancelClick}
        id='cancelar'
      />

      <Button
        icon='save'
        text='Guardar'
        type='default'
        useSubmitBehavior
        validationGroup={validationGroup}
        stylingMode='contained'
        style={{ float: 'right', margin: '1em', fontWeight: 'bold' }}
        onClick={(e: any) => {
          e.event.preventDefault()
          let result = e.validationGroup.validate()

          if (
            gesgestoria &&
            (!result.isValid ||
              listaModulos === undefined ||
              listaModulos?.length === 0)
          ) {
            let texto =
              (!result.isValid && listaModulos === undefined) ||
              listaModulos?.length === 0
                ? 'Error al realizar la petición. Revise que todos los campos obligatorios estan completos y agregue al menos 1 módulo'
                : !result.isValid
                ? 'Error al realizar la petición. Revise que todos los campos obligatorios estan completos'
                : 'Debes agregar al menos 1 módulo'

            showToast(texto, NotifyType.error, 5000)
          }
          if (!result.isValid) {
            showToast(
              'Revisar que todos los campos obligatorios estén completados',
              NotifyType.error,
              5000,
            )
          }
        }}
      />
    </>
  )
}
