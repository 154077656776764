import { CalculatorTransferModel } from '../../../../../models/company/calculatorTransferModel';
import {
  ADD_CALCULATOR_TRANSFERS,
  CLEAR_CALCULATOR_TRANSFERS,
  CalculatorTransfersActionsTypes,
} from './ICalculatorTransfersType';

export const addCalculadoraTranseferencias = (
  calculatorTransfers:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
): CalculatorTransfersActionsTypes => ({
  type: ADD_CALCULATOR_TRANSFERS,
  payload: calculatorTransfers,
});

export const clearCalculadoraTranseferencias = (
  calculadoraTransferencias: CalculatorTransferModel | undefined,
): CalculatorTransfersActionsTypes => ({
  type: CLEAR_CALCULATOR_TRANSFERS,
  payload: calculadoraTransferencias,
});
