import React, { useMemo } from 'react';
// devextreme
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
// components
import GenericFormComponent from 'src/components/form/itemComponent';

function DetallesCostes() {
  const detalleCosteState = useSelector(
    (state: RootState) => state.escrituras.detalleCoste,
  );

  const datosFormulario = useMemo(() => {
    return <GenericFormComponent fields={detalleCosteState} />
  }, [detalleCosteState]);

  return <>{!!detalleCosteState.Datos.length && datosFormulario}</>
};

export { DetallesCostes };