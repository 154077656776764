import { ConfiguracionEnvioEmailModel } from 'src/models/company/configuracionEnvioEmailModel'
import { ADD_EMAIL, CLEAR_EMAIL, EmailActionsTypes } from './emailType'

export const addEmail = (
  email:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined,
): EmailActionsTypes => ({
  type: ADD_EMAIL,
  payload: email,
})

export const clearEmail = (
  email: ConfiguracionEnvioEmailModel | undefined,
): EmailActionsTypes => ({
  type: CLEAR_EMAIL,
  payload: email,
})
