import React, { MutableRefObject } from 'react';
import DatosPersonalesCompradorRepresentanteSection from './DatosPersonalesCompradorRepresentanteSection';
import { GroupItem } from 'devextreme-react/form';
import DatosDireccionCompradorRepresentanteSection from './DatosDireccionCompradorRepresentanteSection';
import { SolicitudListModel } from '../../models/solicitudListModel';

interface Props {
  solicitud: SolicitudListModel;
  sexo: Array<Object>;
  tipovia: Array<Object>;
  ubicaciontab: MutableRefObject<string>;
  formref: any;
}

function DatosCompradorRepresentanteSection({
  solicitud,
  sexo,
  tipovia,
  ubicaciontab,
  formref,
}: Props) {
  return (
    <GroupItem caption={'Representante legal'}>
      {DatosPersonalesCompradorRepresentanteSection({
        solicitud: solicitud!,
        sexo: sexo,
        formref,
      })}

      {DatosDireccionCompradorRepresentanteSection({
        solicitud: solicitud!,
        tipovia: tipovia,
      })}
    </GroupItem>
  );
};

export default DatosCompradorRepresentanteSection;
