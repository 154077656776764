import { Box, Form } from 'devextreme-react';
import React, { MutableRefObject, useRef } from 'react';
import FooterFormSection from '../FooterForm/FooterFormSection';
import DatosPersonalesVendedorSection from './DatosPersonalesVendedorSection';
import DatosDireccionVendedorSection from './DatosDireccionVendedorSection';
import DatosVendedorRepresentanteSection from './DatosVendedorRepresentanteSection';
import { GroupItem } from 'devextreme-react/form';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { Item } from 'devextreme-react/box';

interface Props {
  sexo: Array<Object>;
  validos: Function;
  formDatosVendedor: MutableRefObject<any>;
};

export default function DatosVendedorSection({
  sexo,
  validos,
  formDatosVendedor,
}: Props) {
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  );
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  );
  const paginas = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.paginas,
  );
  const tipovia = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.tipovias,
  );
  const ubicaciontab = useRef('');

  return (
    <form>
      <Form
        ref={formDatosVendedor}
        id='formVendedor'
        formData={solicitud}
        readOnly={false}
        showColonAfterLabel={true}
        showValidationSummary={true}
        validationGroup='customerData2'
      >
        {DatosPersonalesVendedorSection({
          sexo: sexo,
          ubicaciontab: ubicaciontab,
          formref: formDatosVendedor,
        })};

        {DatosDireccionVendedorSection({
          ubicaciontab: ubicaciontab,
          formref: formDatosVendedor,
        })};

        { DatosVendedorRepresentanteSection({
            solicitud: solicitud!,
            sexo: sexo,
            tipovia: tipovia!,
          })
        };

        <GroupItem>
          {FooterFormSection({ validos: validos, validacion: 'customerData2' })}
        </GroupItem>
      </Form>
      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'auto'}>
          <p className='alinearinputs'>
            Página {pagina} de {paginas}{' '}
          </p>
        </Item>
      </Box>
    </form>
  );
};
