import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { IdocCarOrganizationalUnitModel } from '../models/idocCarOrganizationalUnitModel'

import {
  ADD_IDOCCAR_ORGANIZATIONAL_UNIT,
  ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL,
  CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT,
  IdocCarOrganizationalUnitActionsTypes,
} from './idocCarOrganizationalUnitType'

export const addIdocCarOrganizationalUnit = (
  idocCarOrganizationalUnit:
    | Partial<IdocCarOrganizationalUnitModel>
    | IdocCarOrganizationalUnitModel
    | undefined,
): IdocCarOrganizationalUnitActionsTypes => ({
  type: ADD_IDOCCAR_ORGANIZATIONAL_UNIT,
  payload: idocCarOrganizationalUnit,
})

export const clearIdocCarOrganizationalUnit = (
  idocCarOrganizationalUnit: IdocCarOrganizationalUnitModel | undefined,
): IdocCarOrganizationalUnitActionsTypes => ({
  type: CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT,
  payload: idocCarOrganizationalUnit,
})
export const addIdocCarOrganizationalUnitAll = (
  idocCarOrganizationalUnitAll: IdocCarOrganizationalUnitModel[] | undefined,
): IdocCarOrganizationalUnitActionsTypes => ({
  type: ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL,
  payload: idocCarOrganizationalUnitAll,
})
