import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('ColumnModel')
export class ColumnModel {
  @JsonProperty('Nombre', String)
  Nombre: string = ''

  @JsonProperty('Tipo', String)
  Tipo: string = ''

  @JsonProperty('Texto', String)
  Texto: string = ''

  @JsonProperty('Format', String)
  Format: string = ''
}

@JsonObject('ColumnModelCore')
export class ColumnModelCore {
  @JsonProperty('Nombre', String)
  Nombre: string = ''

  @JsonProperty('Tipo', String)
  Tipo: string = ''

  @JsonProperty('Texto', String)
  Texto: string = ''

  @JsonProperty('Format', String)
  Format: string = ''

  @JsonProperty('Size', Number)
  Size: number = 0

  @JsonProperty('Visible', Boolean)
  Visible: boolean = false

  @JsonProperty('Orden', Number)
  Orden: number = 0

  // shared-models
  // @JsonProperty('Caption', String)
  // Caption: string = ''
}
