import {
  ADD_DATAFORMCHANGE,
  SHOW_POPUP,
  OPEN_POPUPSUJETOS,
  sharedUtilsActionsTypes,
  SharedUtilsState,
} from '../types/sharedUtilsType'

const initialState: SharedUtilsState = {
  showPopup: false,
  isOpenPopup: undefined,
  popupRef: undefined,
  isChanged: undefined,
}

export const sharedUtilsReducer = (
  state = initialState,
  action: sharedUtilsActionsTypes,
): SharedUtilsState => {
  switch (action.type) {
    case SHOW_POPUP: {
      return { ...state, showPopup: action.payload }
    }

    case OPEN_POPUPSUJETOS: {
      return {
        ...state,
        isOpenPopup: action.payload.isOpenPopup,
        popupRef: action.payload.popupRef,
      }
    }

    case ADD_DATAFORMCHANGE: {
      return { ...state, isChanged: action.payload }
    }

    default:
      return state
  }
}
