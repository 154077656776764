import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('BienModel')
class BienModel {
  @JsonProperty('CodigoNotario', String)
  CodigoNotario: string = ''

  @JsonProperty('FechaOtorgamiento', String)
  FechaOtorgamiento: String = ''

  @JsonProperty('FechaVctoAsiento', String)
  FechaVctoAsiento: String = ''

  @JsonProperty('FechaVctoConsolidacion', String)
  FechaVctoConsolidacion: String = ''

  @JsonProperty('FechaVctoHacienda', String)
  FechaVctoHacienda: String = ''

  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('Protocolo', String)
  Protocolo = ''

  @JsonProperty('Registro', String)
  Registro = ''

  @JsonProperty('ValorDeclarado', Number)
  ValorDeclarado = 0

  @JsonProperty('ValorSuelo', Number)
  ValorSuelo = 0

  @JsonProperty('FechaRecogidaNotaria', String)
  FechaRecogidaNotaria: String = ''

  @JsonProperty('FechaPresentacionHacienda', String)
  FechaPresentacionHacienda: String = ''

  @JsonProperty('FechaAsientoRegistro', String)
  FechaAsientoRegistro: String = ''
}

@JsonObject('BienesCampoDatoModel')
class BienesCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [BienModel])
  Datos: BienModel[] = []
}

export { BienModel, BienesCampoDatoModel }