import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { isNormalUser, redirectLogin } from 'src/utils/allowAuthUtil'
import { isAlreadyLoggedUser } from 'src/redux/utils/reduxUtils'
import { addShowLoader } from 'src/redux/actions/configActions'

export default function NotFound(config?: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const usuarioState = useSelector((state: RootState) => state.user)
  const showPopUp = useSelector(
    (state: RootState) => state.config.visibleLoader,
  )

  useEffect(() => {
    dispatch(addShowLoader(false))
  }, [showPopUp])

  return (
    <>
      <Box
        direction='col'
        align='center'
        width={'100%'}
        crossAlign='center'
        height={280}
      >
        <Item ratio={0} baseSize={'26%'}>
          <h1>¡Ops!</h1>
        </Item>
        <Item ratio={0} baseSize={'30%'}>
          <h3>
            {config?.data?.type === 1 ? (
              <h3>No tienes permisos para ver esta página.</h3>
            ) : (
              <h3>Página no encontrada...</h3>
            )}
          </h3>
        </Item>
        {config?.data?.type !== 1 ? (
          <Item ratio={0} baseSize={'23%'}>
            <p>(404)</p>
          </Item>
        ) : null}
        <Item ratio={0} baseSize={'30%'}>
          <Button
            width={120}
            text='Volver'
            type='default'
            stylingMode='contained'
            onClick={() => {
              if (usuarioState?.isLoggedIn || isAlreadyLoggedUser()) {
                if (isNormalUser(usuarioState!.user!)) {
                  navigate(
                    redirectLogin(
                      JSON.parse(localStorage.getItem('parentCompanyModulos')!),
                    ),
                  )
                } else {
                  navigate('/companies')
                }
              } else {
                navigate('/Login')
              }
            }}
          />
        </Item>
      </Box>
    </>
  )
}
