import React, { useState, ReactElement, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import NotFound from '../../../../components/notFound/notFound';
import { List } from 'devextreme-react';
import { postCompany, putCompany } from '../services/companiesServices';
import { CompanyModuloModel } from 'src/models/company/companyModuloModel';
import { addCompany, addCompanyModulos } from '../redux/companiesActions';
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm';
import { useScreenSize } from '../../../../utils/media-query';
import { useForm } from 'react-hook-form';
import { isSuperAdmin } from '../../../../utils/allowAuthUtil';
import { NotifyType, refreshGrid, showToast } from '../../../../utils/sharedUitls';
import { addParentLinks } from '../redux/link/linkActions';
import { getLinks } from '../services/linksService';

function ModuleList(props: any): ReactElement {
  const dispatch = useDispatch();
  const moduloState = useSelector((state: RootState) => state.modulo);
  const companyState = useSelector((state: RootState) => state.company);
  const userState = useSelector((state: RootState) => state.user);
  const company = useSelector((state: RootState) => state.company?.company);
  const calculadoraState = useSelector(
    (state: RootState) =>
      state.calculatorTransfers?.calculatorTransfers,
  );
  const emailState = useSelector((state: RootState) => state.email?.email);
  const enlaceState = useSelector((state: RootState) => state.link);
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  );
  const [selectedItemKeys, setSelecteItemKeys] = useState<number[] | undefined>(
    [],
  );
  const [dataModules, setDataModules] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const { isLarge } = useScreenSize();
  const formData = useRef({});
  const { register, handleSubmit } = useForm();
  var ismounted = false
  useMemo(() => {
    function fetchData() {
      try {
        if (companyState?.companyModulos) {
          setSelecteItemKeys(
            companyState?.companyModulos?.map((x: any) => x.IdModulo),
          )
        } else {
          setSelecteItemKeys([]);
        }

        if (moduloState.modules) {
          let data: any = []
          moduloState.modules.Data.map((x) => {
            return data.push({ id: x.Id, text: x.Nombre })
          })
          setDataModules(data);
        }
        ismounted = true
        if (props.crear && !companyState?.companyModulos) {
          setSelecteItemKeys([]);
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData()
  }, [props.showForm])
  const onSubmit = async (data: any) => {
    setLoading(true)
    let result: boolean = false
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }))
    }
    if (company?.Id) {
      result = await putCompany(
        userState!.user!,
        company,
        calculadoraState,
        enlaceState?.links!,
        companyState,
        emailState,
      )
    } else {
      result = await postCompany(
        userState!.user!,
        company!,
        calculadoraState,
        enlaceState?.links!,
        companyState,
        emailState,
      ).then(() => {
        return true
      })
    }

    if (result) {
      setLoading(false);
      refreshGrid(props.setShowForm, true);
      props.setShowForm(false);
      localStorage.setItem(
        'parentCompanyModulos',
        JSON.stringify(companyState?.companyModulos),
      );
      localStorage.setItem(
        'parentEnlaces',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      );
    } else {
      setLoading(false);
    }
  }
  const saveCompanyModulos = (property: any) => {
    let companyModulos: CompanyModuloModel[] = [];
    property?.map((key: any) => {
      return companyModulos.push({
        IdCompany: companyState?.company?.Id!,
        IdModulo: key,
        Nombre: moduloState?.modules?.Data.find((x) => x.Id === key)?.Nombre!,
        Code: companyState?.companyModulos?.find((x) => x.IdModulo === key)?.Code!,
      });
    });
    dispatch(addCompanyModulos(companyModulos));
    setSelecteItemKeys(companyModulos.map((x: any) => x.IdModulo));
  }
  const handleCancelClick = () => {
    dispatch(addCompanyModulos(undefined));
    props.setShowForm(false);
  }
  const renderButtons = () => {
    return ButtonsForm(`companyModuloData`, handleCancelClick, false);
  }

  const handleOptionChanged = (event: any) => {
    if (event.name === 'selectedItemKeys') {
      if (JSON.stringify(event.value) !== JSON.stringify(event.previousValue)) {
        if (isSuperAdmin(userState!.user!)) {
          saveCompanyModulos(event.value);
        } else {
          showToast(
            'No se pueden actualizar los módulos contratados, por favor ponte en contacto con tu proovedor',
            NotifyType.error, 3000
          );
        }
      }
    }
  }

  const companyModuloslistRef: any = useRef(null);

  if (!props.showForm) {
    if (companyModuloslistRef.current.current  != null ) {
      setSelecteItemKeys([]);
    }
  }

  return (
    <>
      {true ? (
        <>
          <div
            className={'content-block dx-card responsive-paddings'}
            style={{
              borderColor: '#f4f9ff',
              borderWidth: '2px',
              margin: '0.6%',
            }}
          >
            <List
              dataSource={dataModules}
              ref={companyModuloslistRef}
              height={'100%'}
              keyExpr='id'
              showSelectionControls={true}
              selectionMode={'all'}
              selectAllMode={'allPages'}
              disabled={isSuperAdmin(userState!.user!) ? false : true}
              selectedItemKeys={selectedItemKeys}
              elementAttr={{ id: 'companyModulosList' }}
              onOptionChanged={handleOptionChanged}
            ></List>
          </div>
        </>
      ) : (
        <div>
          <NotFound data={{ type: 1 }}></NotFound>
        </div>
      )}
    </>
  );
}

export { ModuleList };
