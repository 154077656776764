import { CosteCampoDatoModel, DetalleCosteCampoDatoModel } from '../../models/costeModel';
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel';
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel';
import { StructureExpedienteModel } from '../../models/structureExpedienteModel';
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel';
import { DetalleVehiculoCampoDatoModel } from '../../models/vehiculo/detalleVehiculoModel';
import {
  ADD_COSTES,
  ADD_DETALLE_COSTE,
  ADD_DETALLE_EXPEDIENTE,
  ADD_DETALLE_VEHICULO,
  ADD_DOCUMENTOS,
  ADD_STRUCTURE,
  ADD_TAREAS_PENDIENTES,
  ExpVehiculoActionsTypes,
} from './vehiculoType';

export const addStructure = (
  structure: StructureExpedienteModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_STRUCTURE,
  payload: structure,
});

export const addDetalleExpediente = (
  expediente: DetailExpedientFieldDataModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_DETALLE_EXPEDIENTE,
  payload: expediente,
});

export const addDetalleCoste = (
  detalleCoste: DetalleCosteCampoDatoModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_DETALLE_COSTE,
  payload: detalleCoste,
});

export const addDetalleVehiculo = (
  detalleVehiculo: DetalleVehiculoCampoDatoModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_DETALLE_VEHICULO,
  payload: detalleVehiculo,
});

export const addCostes = (
  costes: CosteCampoDatoModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_COSTES,
  payload: costes,
});

export const addTareasPendientes = (
  tareasPendientes: TareaPendienteCampoDatoModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_TAREAS_PENDIENTES,
  payload: tareasPendientes,
});

export const addDocumentos = (
  documentos: DocumentoCampoDatoModel,
): ExpVehiculoActionsTypes => ({
  type: ADD_DOCUMENTOS,
  payload: documentos,
});