import { DossierTypeModel } from '../models/dossierTypeModel';
import { ADD_DOSSIER_TYPE, ADD_DOSSIER_TYPES, CLEAR_DOSSIER_TYPE, 
  DossierTypeActionsTypes } from './IDossierTypeState';

const addDossierTypes = (
  dossierTypes: any | undefined,
): DossierTypeActionsTypes => ({
  type: ADD_DOSSIER_TYPES,
  payload: dossierTypes,
});

const addDossierType = (
  dossierType: Partial<DossierTypeModel> | DossierTypeModel | undefined,
): DossierTypeActionsTypes => ({
  type: ADD_DOSSIER_TYPE,
  payload: dossierType,
});

const clearDossierType = (
  dossierType: DossierTypeModel | undefined,
): DossierTypeActionsTypes => ({
  type: CLEAR_DOSSIER_TYPE,
  payload: dossierType,
});

export { 
  addDossierType,
  addDossierTypes,
  clearDossierType 
};