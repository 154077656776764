import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel';
import { SolicitudListModel } from '../models/solicitudListModel';
import {
  ADD_SOLICITUDES_LIST,
  ADD_SOLICITUD_LIST,
  SolicitudListActionsTypes,
  CLEAR_SOLICITUD_LIST,
} from './solicitudType';

export const addSolicitudList = (
  solicitudList: SolicitudListModel | undefined,
): SolicitudListActionsTypes => ({
  type: ADD_SOLICITUD_LIST,
  payload: solicitudList,
});

export const clearSolicitud = (
  solicitudList: SolicitudListModel | undefined,
): SolicitudListActionsTypes => ({
  type: CLEAR_SOLICITUD_LIST,
  payload: solicitudList,
});

export const addSolicitudesList = (
  solicitudesList: SolicitudListCampoDatoModel | undefined,
): SolicitudListActionsTypes => ({
  type: ADD_SOLICITUDES_LIST,
  payload: solicitudesList,
});
