import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { DossierStatusElGestor } from '../models/idocCarEstadosExpedientesModel'
const serviceName = 'authService'

export const getIdocCarDossierStatusElGestorList = async (
  user: UserModel,
): Promise<Array<DossierStatusElGestor> | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusElGestor`
    let datos: any
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los registros.',
          NotifyType.error,
          5000,
        )
        checkStatusCode(error.response.status)
      })

    return datos
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

export const putIdocCarDossierStatusElGestor = async (
  DossierElGestor: DossierStatusElGestor,
): Promise<DossierStatusElGestor | undefined> => {
  let datos
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  try {
    let url = `DossierStatusElGestor`
    datos = await axiosInstance.put(url, DossierElGestor)
  } catch (error: any) {
    showToast('No se ha podido editar el registro.', NotifyType.error, 5000)
    checkStatusCode(error.response.status)
  }
  return jsonConvert.deserializeObject(datos?.data, DossierStatusElGestor)
}

export const getIdocCarDossierStatusList = async (
  user: UserModel,
): Promise<SolicitudListCampoDatoModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatus`
    let datos: any
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los registros.',
          NotifyType.error,
          5000,
        )
        checkStatusCode(error.response.status)
      })

    return datos
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

export async function postIdocCarDossierStatusRelation(
  user: UserModel,
  Solicitud: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `DossierStatusRelation`

    await axiosInstance.post(url, Solicitud).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `Solicitud creado ${response.data.Id}`,
        logType.INFO,
        serviceName,
        postIdocCarDossierStatusRelation.name,
        methodsEndPointType.POST,
      )
      showToast('Solicitud creada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    customLog(
      `Error al crear Solicitud => ${error.message}`,
      logType.ERROR,
      serviceName,
      postIdocCarDossierStatusRelation.name,
      methodsEndPointType.POST,
    )
    checkStatusCode(error.response.status)
    return false
  }
}

export async function putIdocCarDossierStatusRelation(
  user: UserModel,
  idocCarConfig: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusRelation`

    await axiosInstance
      .put(url, {
        idDossierStatus: idocCarConfig.IdDossierStatus,
        idStatusElGestor: idocCarConfig.IdStatusElGestor,
        color: idocCarConfig.IdStatusElGestor.Color,
      })
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud modificado ${idocCarConfig.Id}`,
          logType.INFO,
          serviceName,
          putIdocCarDossierStatusRelation.name,
          methodsEndPointType.PUT,
        )
        showToast('Configuración editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar caonfiguración iDocCar => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarDossierStatusRelation.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}

export async function deleteIdocCarDossierStatus(
  user: UserModel,
  id: number,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusRelation/` + id

    await axiosInstance
      .delete(url)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud delete ${id}`,
          logType.INFO,
          serviceName,
          deleteIdocCarDossierStatus.name,
          methodsEndPointType.PUT,
        )
        showToast('Configuración editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        showToast('No se ha podido borrar el registro.', NotifyType.error, 5000)
        customLog(
          `Error al editar Solicitud => ${error.message}`,
          logType.ERROR,
          serviceName,
          deleteIdocCarDossierStatus.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}
