import { ModuleFieldDataModel } from '../../models/moduleModel/moduleFieldDataModel';
import { ADD_MODULES, ModuleActionsTypes } from './moduleType';

const addModules = (
  modules: ModuleFieldDataModel | undefined,
): ModuleActionsTypes => ({
  type: ADD_MODULES,
  payload: modules,
});

export { addModules };
