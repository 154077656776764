import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
export class ModelComponent {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('IdPlantilla', Number)
  IdPlantilla: number = 0;

  @JsonProperty('IdTipoComponente', Number)
  IdTipoComponente: number = 0;

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('Codigo', Number)
  Codigo: number = 0;
}
