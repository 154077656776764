import {
  ADD_INFORMA_COMPANIES_STOCK,
  InformaActionsTypes,
  InformaState,
  ADD_INFORMA_SALDO,
  ADD_INFORMA_COMPANY,
  ADD_INFORMA_CONSULTA_HISTORICO,
  ADD_INFORMA_CONSULTA_HISTORICO_GRID,
} from '../types/InformaTypesAction'

const companyState: InformaState = {
  stockcompanies: undefined,
  saldo: undefined,
  company: undefined,
  companyhistory: undefined,
  companyhistorygrid: undefined,
}

export const InformaReducer = (
  state = companyState,
  action: InformaActionsTypes,
): InformaState => {
  switch (action.type) {
    case ADD_INFORMA_COMPANIES_STOCK: {
      return { ...state, stockcompanies: action.payload }
    }

    case ADD_INFORMA_SALDO: {
      return { ...state, saldo: action.payload }
    }
    case ADD_INFORMA_COMPANY: {
      return { ...state, company: action.payload }
    }

    case ADD_INFORMA_CONSULTA_HISTORICO: {
      return { ...state, companyhistory: action.payload }
    }

    case ADD_INFORMA_CONSULTA_HISTORICO_GRID: {
      return { ...state, companyhistorygrid: action.payload }
    }

    default:
      return state
  }
}
