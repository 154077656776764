import React from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling, StateStoring } from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { CustomForm } from 'src/pages/expedientePage/components/CustomForm';
import NoData from 'src/pages/expedientePage/components/NoData';
import { getColumnAlignment, getColumnType } from 'src/utils/sharedUitls';

export default function RegistroTabBienes() {
  const { registro, registros } = useSelector(
    (state: RootState) => state.escrituras,
  );

  return !!registros.Datos.length ? (
    <>
      <div style={{ minHeight: '8rem' }}>
        <h5>Datos último registro</h5>
        <div style={{ marginBottom: '1rem' }} />
        {!!registro.Datos.length ? <CustomForm {...registro} /> : <NoData />}
      </div>
      <DataGrid
        dataSource={registros.Datos}
        keyExpr='Id'
        style={{ marginTop: '1.5em' }}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        focusedRowEnabled
        rowAlternationEnabled
        showBorders
        showRowLines
      >
      <Scrolling showScrollbar='always' />
      <StateStoring
        enabled
        type='localStorage'
        storageKey='gridRegistroEscrituras'
      />
      {!!registros.Campos.length &&
        registros.Campos.map((value) => (
          <Column
            key={value.Nombre}
            dataField={value.Nombre}
            caption={value.Texto}
            dataType={value.Tipo}
            format={getColumnType(value.Tipo, value.Format)}
            alignment={getColumnAlignment(value.Tipo)}
            visible={value.Nombre === 'Id' ? false : value.Visible}
            allowHeaderFiltering={value.Tipo !== 'date'}
          />
        ))
      };
      </DataGrid>
    </>
  ) : (<NoData />);
};
