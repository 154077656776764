import { CompanyConsultaHistoricoGridModel } from '../../models/CompanyConsultaHistoricoGridModel'
import { CompanyConsultaModel } from '../../models/CompanyConsultaModel'
import { StockCompanyModel } from '../../models/StockCompanyModel'
import { CompanyInformaModel } from '../../models/companyInformaModel'
import { SaldoInformaModel } from '../../models/saldoInformaModel'

export const ADD_INFORMA_COMPANIES_STOCK = 'ADD_INFORMA_COMPANIES'
export const ADD_INFORMA_SALDO = 'ADD_INFORMA_SALDO'
export const ADD_INFORMA_COMPANY = 'ADD_INFORMA_COMPANY'
export const ADD_INFORMA_CONSULTA_HISTORICO = 'ADD_INFORMA_CONSULTA_HISTORICO'
export const ADD_INFORMA_CONSULTA_HISTORICO_GRID =
  'ADD_INFORMA_CONSULTA_HISTORICO_GRID'

export interface InformaState {
  stockcompanies: Array<StockCompanyModel> | StockCompanyModel | undefined
  saldo: SaldoInformaModel | undefined
  company: CompanyInformaModel | undefined
  companyhistory: Array<CompanyConsultaModel> | undefined
  companyhistorygrid: Array<CompanyConsultaHistoricoGridModel> | undefined
}

interface AddInformaCompaniesStock {
  type: typeof ADD_INFORMA_COMPANIES_STOCK
  payload: Array<StockCompanyModel> | StockCompanyModel | undefined
}

interface AddInformaSaldo {
  type: typeof ADD_INFORMA_SALDO
  payload: SaldoInformaModel | undefined
}

interface AddInformaCompany {
  type: typeof ADD_INFORMA_COMPANY
  payload: CompanyInformaModel | undefined
}

interface AddInformaConsultaHistorico {
  type: typeof ADD_INFORMA_CONSULTA_HISTORICO
  payload: Array<CompanyConsultaModel> | undefined
}

interface AddInformaConsultaHistoricoGrid {
  type: typeof ADD_INFORMA_CONSULTA_HISTORICO_GRID
  payload: Array<CompanyConsultaHistoricoGridModel> | undefined
}

export type InformaActionsTypes =
  | AddInformaCompaniesStock
  | AddInformaSaldo
  | AddInformaCompany
  | AddInformaConsultaHistorico
  | AddInformaConsultaHistoricoGrid
