import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, Form, Popup, TextBox } from 'devextreme-react'
import {
  EmailRule,
  Item,
  Label,
  NumericRule,
  RequiredRule,
} from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import { addEmail } from '../../../companyAdminPages/companyPage/redux/email/emailActions'
import { useScreenSize } from '../../../../utils/media-query'
import { useForm } from 'react-hook-form'
import { addCompany } from '../redux/companiesActions'
import {
  postCompany,
  putCompany,
} from '../../../companyAdminPages/companyPage/services/companiesServices'
import { addDataFormChange } from '../../../../redux/actions/sharedUtilsActions'
import { addParentLinks } from '../../../companyAdminPages/companyPage/redux/link/linkActions'
import { getLinks } from '../../../companyAdminPages/companyPage/services/linksService'
import {
  NotifyType,
  refreshGrid,
  showToast,
} from '../../../../utils/sharedUitls'
import { useQuery } from 'react-query'
import { postEmailPrueba } from '../services/emailService'

function EmailFormComponent(props: any): ReactElement {
  const emailState = useSelector((state: RootState) => state.email?.email)
  const userState = useSelector((state: RootState) => state.user)
  const company = useSelector((state: RootState) => state.company?.company)
  const companyState = useSelector((state: RootState) => state.company)
  const calculadoraTransferenciasState = useSelector(
    (state: RootState) => state.calculatorTransfers?.calculatorTransfers,
  )
  const enlaceState = useSelector((state: RootState) => state.link)
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const dispatch = useDispatch()
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [testEmail, setTestEmail] = useState('')
  const [shouldFetch, setShouldFetch] = useState(false)
  const { isLarge } = useScreenSize()
  const { register, handleSubmit } = useForm()
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [])
  const onSubmit = async (data: any) => {
    setLoading(true)
    let result: boolean = false
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }))
    }
    if (company?.Id) {
      result = await putCompany(
        userState!.user!,
        company,
        calculadoraTransferenciasState,
        enlaceState?.links!,
        companyState,
        emailState,
      )
    } else {
      result = await postCompany(
        userState!.user!,
        company!,
        calculadoraTransferenciasState,
        enlaceState?.links!,
        companyState,
        emailState,
      ).then(() => {
        return true
      })
    }

    if (result) {
      setLoading(false)
      refreshGrid(props.setShowForm, true)
      props.setShowForm(false)
      localStorage.setItem(
        'parentCompanyModulos',
        JSON.stringify(companyState?.companyModulos),
      )
      localStorage.setItem(
        'parentEnlaces',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      )
    } else {
      setLoading(false)
    }
  }
  const handleUpdatedEmail = (property: any) => {
    dispatch(
      addEmail({
        ...emailState,
        ...property,
      }),
    )
  }

  const emailFormRef: any = useRef(null)
  if (!props.showForm) {
    emailFormRef.current.instance.repaint()
  }
  const showPopupConfirm = () => {
    setIsPopupVisible(true)
  }

  useQuery(
    ['sendTestEmail', shouldFetch],
    async () => {
      return await postEmailPrueba(userState!.user!, {
        emailDestinatario: testEmail,
      })
    },
    {
      enabled: shouldFetch,
      onSuccess: () => {
        showToast(
          'Correo de prueba enviado exitosamente.',
          NotifyType.success,
          5000,
        )
        setIsPopupVisible(false)
        setShouldFetch(false)
      },
      onError: (error) => {
        console.error('Error al enviar el correo de prueba:', error)
        showToast(
          'Error al enviar el correo de prueba.',
          NotifyType.error,
          5000,
        )
        setShouldFetch(false)
      },
    },
  )

  const enviarEmailDePrueba = () => {
    setShouldFetch(true)
  }

  return (
    <>
      {' '}
      <div
        style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.6%' }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Form
              formData={formData.current}
              disabled={loading}
              ref={emailFormRef}
              labelLocation={isLarge ? 'left' : 'top'}
            >
              <Item
                dataField={'ServidorSMTP'}
                editorType={'dxTextBox'}
                editorOptions={{
                  value: emailState?.ServidorSMTP
                    ? emailState?.ServidorSMTP
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ ServidorSMTP: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />

                <Label visible={true} text='Servidor smtp' />
              </Item>

              <Item
                dataField={'PuertoSMTP'}
                editorType={'dxNumberBox'}
                editorOptions={{
                  value: emailState?.PuertoSMTP ? emailState.PuertoSMTP : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({
                        PuertoSMTP: event.value,
                      })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <NumericRule message='El Campo debe ser numérico' />
                <Label visible={true} text='Puerto smtp' />
              </Item>
              <Item
                dataField={'UsaSSL'}
                editorType={'dxCheckBox'}
                editorOptions={{
                  value: emailState?.UsaSSL ? emailState?.UsaSSL : false,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ UsaSSL: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <Label visible={true} text='Usar ssl' />
              </Item>
              <Item
                dataField={'NombreMostrar'}
                editorType={'dxTextBox'}
                editorOptions={{
                  value: emailState?.NombreMostrar
                    ? emailState?.NombreMostrar
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ NombreMostrar: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />

                <Label visible={true} text='Nombre a mostrar' />
              </Item>
              <Item
                dataField={'DireccionEmail'}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'email',
                  value: emailState?.DireccionEmail
                    ? emailState?.DireccionEmail
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ DireccionEmail: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <EmailRule message='Debe introducir un email con un formato correcto' />
                <Label visible={true} text='Dirección de email' />
              </Item>
              <Item
                dataField={'UsuarioEmail'}
                editorType={'dxTextBox'}
                editorOptions={{
                  value: emailState?.UsuarioEmail
                    ? emailState?.UsuarioEmail
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ UsuarioEmail: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <Label visible={true} text='Usuario email' />
              </Item>
              <Item
                dataField={'PasswordEmail'}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'password',
                  value: emailState?.PasswordEmail
                    ? emailState?.PasswordEmail
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedEmail({ PasswordEmail: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <Label visible={true} text='Contraseña' />
              </Item>

              <Item>
                <Button
                  type='default'
                  text='Enviar Muestra'
                  style={{ marginLeft: '15vh', marginTop: '1vh' }}
                  onClick={() => {
                    showPopupConfirm()
                  }}
                />
              </Item>
            </Form>
          </form>
        </div>
      </div>
      <Popup
        visible={isPopupVisible}
        onHiding={() => setIsPopupVisible(false)}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title='E-mail de muestra'
        width={300}
        height={250}
      >
        <div style={{ padding: '20px' }}>
          <Label text='E-mail de muestra' />
          <TextBox
            value={testEmail}
            onValueChanged={(e) => setTestEmail(e.value)}
            placeholder='Ingrese el correo electrónico'
          />
          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <Button
              text='Enviar'
              type='default'
              onClick={enviarEmailDePrueba}
            />
            <Button
              text='Cancelar'
              onClick={() => setIsPopupVisible(false)}
              style={{ marginLeft: '10px' }}
            />
          </div>
        </div>
      </Popup>
    </>
  )
}
export { EmailFormComponent }
