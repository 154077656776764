import React, { useEffect } from 'react'; 
import { Box, Form, Popup, ScrollView } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/autocomplete';
import { Item } from 'devextreme-react/box';
import { GroupItem, SimpleItem } from 'devextreme-react/form';
import { ModulesPermissionsSection } from './ModulesPermissionsSection';
import { UserPermissionsModel } from '../models/userPermissionsModel';
import { useDispatch } from 'react-redux';

interface Props {
  userPermissions: UserPermissionsModel;
  close: Function;
  setIdSelected: Function;
  idUser: number;
}

function FormPermissionsUsersSection({
  userPermissions,
  close,
  setIdSelected,
  idUser,
}: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setIdSelected(0);
        close(false);
      };
    });

    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setIdSelected(0);
          close(false);
        };
      });
    };
  }, []);
  return (
    <Popup
      visible={true}
      showCloseButton={false}
      height='90%'
      width='90%'
      title={'Administrar Permisos'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: () => {
            setIdSelected(0)
            close(false)
          },
        }}
      />
      <ScrollView width='100%' height='100%'>
        <Box direction='row' align='center' crossAlign='center' width={'100%'}>
          <Item ratio={0} baseSize={'auto'}>
            <Form>
              <GroupItem colCount={2}>
                <SimpleItem
                  label={{ text: 'Nombre usuario' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    readOnly: true,
                    mode: 'text',
                    hint: 'Nombre del usuario',
                    value: userPermissions.Name,
                  }}
                ></SimpleItem>
                <SimpleItem
                  label={{ text: 'Rol' }}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    readOnly: true,
                    mode: 'text',
                    hint: 'Nombre del usuario',
                    value: userPermissions.Role,
                  }}
                ></SimpleItem>
              </GroupItem>
            </Form>
          </Item>
        </Box>
        <Box height={30}>
          <Item></Item>
        </Box>
        <Box align='center' crossAlign='center' height={64} width={'100%'}>
          <Item ratio={0} baseSize={'auto'}>
            <span style={{ position: 'relative', right: '33px' }}>
              <span style={{ color: 'red' }}>*</span>{' '}
              <strong>
                Los permisos que aparecen bloqueados son los que pertenecen al
                rol de dicho usuario. Solo se modifican los suyos propios{' '}
              </strong>
            </span>
          </Item>
        </Box>
        <Box direction='row' align='center' crossAlign='center' width={'100%'}>
          <Item ratio={0} baseSize={'auto'}>
            <ModulesPermissionsSection
              userPermissionData={userPermissions}
              id={idUser}
            ></ModulesPermissionsSection>
          </Item>
        </Box>
      </ScrollView>
    </Popup>
  );
};

export { FormPermissionsUsersSection };
