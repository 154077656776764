import { Box, Button, DataGrid, Template } from 'devextreme-react';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import React, { MutableRefObject, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { isSuperAdmin } from 'src/utils/allowAuthUtil';
import { checkScreenSize } from 'src/utils/sharedUitls';
import { useQuery } from 'react-query';
import { addInformaCompaniesStock, addInformaSaldo } from '../redux/actions/InformaActions';
import EditSaldoFormSection from '../sections/EditSaldoSection/editSaldoFormSection';
import { Item } from 'devextreme-react/box';
import { StockCompanyModel } from '../models/StockCompanyModel';
import { getSaldoCompanys, getStockGeneral } from '../services/infomaservices';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { addShowLoader } from './../../../redux/actions/configActions';
import { SaldoGeneralCompanyModel } from '../models/saldoGeneralCompanyModel';

function SaldoPage() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const isSuperAdministrator = isSuperAdmin(userState!.user!);
  const [totalPaquetes, setTotalPaquetes] = useState(0);
  const { isSuccess, isError } = useQuery(
    ['StockCompany', userState!.user, isSuperAdmin],
    getSaldoCompanys,
    {
      onSuccess: (
        data: Array<StockCompanyModel> | StockCompanyModel | null,
      ) => {
        if (data  != null ) {
          dispatch(addInformaCompaniesStock(data));
        }
      },
      refetchOnWindowFocus: false,
    },
  )

  useQuery(['StockGeneral', userState!.user, isSuperAdmin], getStockGeneral, {
    onSuccess: (data: SaldoGeneralCompanyModel | null) => {
      if (data  != null ) {
        setTotalPaquetes(data.stock);
      }
    },
    refetchOnWindowFocus: false,
  })

  if (isSuccess) {
    dispatch(addShowLoader(false));
  }

  if (isError) {
    dispatch(addShowLoader(false));
  }

  const listaInformaCompanyState = useSelector(
    (state: RootState) => state.Informa.stockcompanies,
  );
  const [stockEmpresa, setstockEmpresa] = useState(null);
  const informaCompanysDataGrid: MutableRefObject<any> = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  function Cerrar() {
    setShowPopup(false);
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        location: 'after',
        template: 'numeroGlobalPaquetes',
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            informaCompanysDataGrid.current!.instance.refresh(true)
          },
        },
      },
    )
  }

  function exportExcell(e: any) {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Saldos.xlsx',
        )
      })
    })
    e.cancel = true
  }

  function numeroPaquetes() {
    return (
      isSuperAdministrator && (
        <Box direction='row' align='start' crossAlign='start' width={'auto'}>
          <Item ratio={0} baseSize={'auto'}>
            <div>Número Total De Paquetes {totalPaquetes}</div>
          </Item>
        </Box>
      )
    )
  }

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>Consulta saldos</h3>
        <Box direction='row' width='auto' align='center' crossAlign='center'>
          <Item ratio={0} baseSize={750}>
            <DataGrid
              id='informaSaldoDataGrid'
              keyExpr='companyId'
              ref={informaCompanysDataGrid}
              dataSource={
                isSuperAdministrator
                  ? (listaInformaCompanyState as Array<StockCompanyModel>)
                  : [listaInformaCompanyState as StockCompanyModel]
              }
              style={{ width: 'auto', minHeight: '250px' }}
              height='calc(100vh - 8rem - 46px)'
              allowColumnReordering
              allowColumnResizing
              columnAutoWidth
              focusedRowEnabled
              remoteOperations
              repaintChangesOnly
              rowAlternationEnabled
              showBorders
              showRowLines
              columnChooser={{ enabled: true }}
              columnHidingEnabled={!checkScreenSize()}
              filterPanel={{ visible: true }}
              filterRow={{ visible: true }}
              headerFilter={{ visible: false }}
              loadPanel={{ enabled: true }}
              paging={{ enabled: false }}
              sorting={{ mode: 'multiple', showSortIndexes: false }}
              export={{
                allowExportSelectedData: false,
                enabled: true,
              }}
              searchPanel={{
                visible: true,
                width: checkScreenSize() ? 240 : 120,
              }}
              selection={{
                mode: 'single',
              }}
              scrolling={{
                mode: 'standard',
                scrollByContent: true,
                scrollByThumb: true,
                showScrollbar: 'always',
              }}
              onToolbarPreparing={(event: any) => {
                onToolbarPreparing(event)
              }}
              onExporting={exportExcell}
            >
              <StateStoring
                enabled={true}
                type={'localStorage'}
                storageKey={'saldoList'}
              />

              {isSuperAdministrator && (
                <Column
                  alignment='center'
                  type='buttons'
                  cellRender={(row: any) => (
                    <Button
                      type='default'
                      text='Saldo'
                      onClick={(e: any) => {
                        e.event.preventDefault()
                        setstockEmpresa(row.data)
                        dispatch(
                          addInformaSaldo({
                            companyId: row.data.companyId,
                            Quantity: row.data.stock,
                          }),
                        )
                        setShowPopup(true)
                      }}
                    />
                  )}
                />
              )}

              <Column
                width={'50%'}
                caption={'Nombre De Empresa'}
                dataField={'companyName'}
                alignment='left'
              />
              <Column
                width={'50%'}
                caption={'Saldo'}
                dataField={'stock'}
                alignment='right'
              />
              <Template name={'numeroGlobalPaquetes'} render={numeroPaquetes} />
            </DataGrid>
          </Item>
        </Box>
        {showPopup && (
          <EditSaldoFormSection stockEmpresa={stockEmpresa!} cerrar={Cerrar} />
        )}
      </div>
    </div>
  );
}

export { SaldoPage };