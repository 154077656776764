import {
  ADD_SUCURSALES,
  SucursalActionsTypes,
  SucursalState,
} from './sucursalType'

const initialState: SucursalState = {
  sucursales: [],
}

export const sucursalReducer = (
  state = initialState,
  action: SucursalActionsTypes,
): SucursalState => {
  switch (action.type) {
    case ADD_SUCURSALES: {
      return { ...state, sucursales: action.payload }
    }
    default:
      return state
  }
}
