import React, { MutableRefObject, useCallback, useEffect,
  useRef, useState } from 'react';
import { Button, DataGrid } from 'devextreme-react';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { useScreenSize } from 'src/utils/media-query';
import { checkScreenSize, getColumnAlignment } from 'src/utils/sharedUitls';
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil';
import { addProfile } from '../redux/profileActions';
import { ProfileModel } from 'src/models/perfilPage/profileModel';
import { addDataFormChange, isOpenPopup } from 'src/redux/actions/sharedUtilsActions';
import { _getProfile, _getProfileGrid } from '../service/profileService';
import { PerfilesCampoDatoModel } from 'src/models/perfilPage/perfilCampoDatoModel';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

interface Props {
  verpopup: Function;
  estadoGrid: any;
  popupref: MutableRefObject<any>;
  showform: boolean;
}

function ProfileListPage({
  verpopup,
  estadoGrid,
  popupref,
  showform,
}: Props) {
  const dispatch = useDispatch();
  const [perfiles, setPerfiles] = useState(new PerfilesCampoDatoModel());
  const [rowSelected, setRow] = useState(0);
  const userState = useSelector((state: RootState) => state.user);
  const popupPerfilRef: MutableRefObject<any> = useRef(null);
  const { isLarge, isMedium, isSmall, isXSmall } = useScreenSize();

  let gridheight = '600;'
  gridheight = isXSmall ? '300' : '600'
  gridheight = isSmall ? '400' : '600'
  gridheight = isMedium ? '600' : '600'
  gridheight = isLarge ? '700' : '600'
  let getAlignment = function getAlignment(type: any) {
    switch (type) {
      case 'currency':
        return 'right'

      default:
        return 'left'
    };
  };

  async function _getProfiles() {
    let companias = await _getProfileGrid(userState!.user!);
    setPerfiles(companias!);
  }

  useEffect(() => {
    _getProfiles();
  }, [estadoGrid]);

  function getCurrency() {
    let location = window.localStorage.getItem('lenguage');
    let defaultCurrency = {
      style: 'currency',
      currency: 'EUR',
      useGrouping: true,
      minimumSignificantDigits: 4,
      type: 'currency',
      precision: 2,
    };
  };

  let getColumnType = function getColumnType(type: any, format: any) {
    switch (type) {
      case 'currency':
        return getCurrency();
      case 'string':
        return null;
      case 'number':
        if (format === 'decimal') {
          return getCurrency();
        }
        return null;
      case 'date':
        return 'dd/MM/yyyy';
      case 'datetime':
        return 'dd/MM/yyyy';
      case 'boolean':
        return 'boolean';
      case 'decimal':
        return getCurrency();
      case null:
        if (format === 'float') {
          return getCurrency();
        } else {
          return null;
        }
      default:
        return null;
    };
  };

  const openCreatePopup = async () => {
    if (
      isAdmin(userState!.user!) ||
      isSuperAdmin(userState!.user!)
    ) {
      dispatch(addProfile(new ProfileModel()));
      dispatch(addDataFormChange(false));

      dispatch(isOpenPopup(!showform, popupref));
      verpopup(true);
    };
  };

  function _onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'Crear Perfil ',
        location: 'after',
        widget: 'dxButton',
        visible: openCreatePopup == null  ? false : true,
        options: {
          hint: 'Crear Compañia ',
          icon: 'add',
          onClick: function onClick(_e: any) {
            openCreatePopup();
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: function onClick(_e: any) {
            popupPerfilRef.current!.instance.refresh(true);
          },
        },
      },
    );
  };

  function exportExcell(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Perfiles.xlsx',
        );
      });
    });
    e.cancel = true;
  };

  const loadState = useCallback(() => {
    if (perfiles.Datos.length > 0) {
      return JSON.parse(localStorage.getItem('perfilList')!);
    }
  }, [perfiles.Datos]);

  const saveState = useCallback((state: { columns: string | any[] }) => {
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null;
      };
    };
    localStorage.setItem('perfilList', JSON.stringify(state));
  }, []);
  
  return (
    <DataGrid
      id='perfilDataGrid'
      keyExpr='Id'
      ref={popupPerfilRef}
      dataSource={perfiles.Datos}
      style={{ width: '100%' }}
      height={gridheight}
      allowColumnReordering
      allowColumnResizing
      cacheEnabled
      columnAutoWidth
      focusedRowEnabled
      remoteOperations
      repaintChangesOnly
      rowAlternationEnabled
      showBorders
      showRowLines
      columnChooser={{ enabled: true }}
      columnHidingEnabled={!checkScreenSize()}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
      headerFilter={{ visible: false }}
      loadPanel={{ enabled: true }}
      paging={{ enabled: false }}
      sorting={{ mode: 'multiple', showSortIndexes: false }}
      export={{
        allowExportSelectedData: true,
        enabled: true,
      }}
      searchPanel={{
        visible: true,
        width: checkScreenSize() ? 240 : 120,
      }}
      selection={{
        mode: 'multiple',
        showCheckBoxesMode: isLarge ? 'always' : 'none',
      }}
      scrolling={{
        mode: 'standard',
        scrollByContent: true,
        scrollByThumb: true,
        showScrollbar: 'always',
      }}
      onToolbarPreparing={function onToolbarPreparing(event) {
        _onToolbarPreparing(event);
      }}
      onExporting={exportExcell}
    >
      <StateStoring
        enabled={true}
        type={'custom'}
        customLoad={loadState}
        customSave={saveState}
        storageKey={'perfilList'}
      />
      <Column
        type='buttons'
        width={50}
        cellRender={(row: any) => (
          <Button
            width='32px'
            text=''
            icon='edit'
            hint='Editar'
            onClick={async () => {
              setRow(row.data)
              const perfil: ProfileModel = await _getProfile(
                userState!.user!,
                row.data.Id,
              );
              dispatch(
                addProfile(await _getProfile(userState!.user!, row.data.Id)),
              );
              verpopup(true);
            }}
          />
        )}
      />

      {perfiles.Campos.map((value: any) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Texto}
          dataType={value.Tipo}
          alignment={getColumnAlignment(value.Tipo)}
          allowFiltering={value.Nombre !== 'TipoUsuario'}
          allowGrouping={value.Nombre !== 'Totfactura'}
          allowHeaderFiltering={value.Tipo !== 'date'}
          format={getColumnType(value.Tipo, value.Format)}
        />
      ))}
    </DataGrid>
  );
};

export { ProfileListPage };
