import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
class ClaimsUpdateModel {
  @JsonProperty('ClaimName', String)
  ClaimName: string = '';

  @JsonProperty('ClaimValue', String)
  ClaimValue: string = '';
}

export { ClaimsUpdateModel };