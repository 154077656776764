import React, { ReactElement, useRef, useState } from 'react';
import Form, { SimpleItem, Tab, TabbedItem } from 'devextreme-react/form';
import { Box, Item } from 'devextreme-react/box';
import { useNavigate } from 'react-router-dom';
import { CompanyFormPage } from './CompanyFormPage';
import { FormLinkComponent } from '../components/FormLinkComponent';
import { CalculatorFormComponent } from '../components/CalculatorFormComponent';
import { EmailFormComponent } from '../components/EmailFormComponent';
import { useDispatch, useSelector } from 'react-redux';
import { addParentLinks } from '../redux/link/linkActions';
import { addCompany, addCompanyModulos } from '../redux/companiesActions';
import { ModuleList } from '../components/ModuleList';
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm';
import { useScreenSize } from '../../../../utils/media-query';
import { RootState } from '../../../../redux/combineReducers';
import { useForm } from 'react-hook-form';
import { postCompany, putCompany } from '../services/companiesServices';
import { getLinks } from '../services/linksService';
import { CompanyModel } from 'src/models/company/companyModel';
import { addShowLoader } from '../../../../redux/actions/configActions';
import { custom } from 'devextreme/ui/dialog';
import { Modules } from 'src/utils/allowModuloUtil';
import { postCompanyViaFirma } from 'src/pages/firmadigital/services/firmaServices';
import { isSuperAdmin } from 'src/utils/allowAuthUtil';

function CompanyFormTabsPage(props: any): ReactElement {
  const navigate = useNavigate();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const userState = useSelector((state: RootState) => state.user);
  const company = useSelector((state: RootState) => state.company?.company);
  const companyState = useSelector((state: RootState) => state.company)
  const codigoViaFirmaState = useSelector(
    (state: RootState) => state.company?.codigoViaFirma,
  );
  const calculadoraState = useSelector(
    (state: RootState) =>
      state.calculatorTransfers?.calculatorTransfers,
  );
  const emailState = useSelector((state: RootState) => state.email?.email);
  const linkState = useSelector((state: RootState) => state.link);
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  );
  const { isLarge } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [pattern, setPattern] = useState('NIF');
  const formData = useRef({});
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const renderButtons = () => {
    return ButtonsForm(
      `companyData`,
      handleCancelClick,
      true,
      companyState?.companyModulos,
    );
  };
  const handleCancelClick = () => {
    let confirmDialog = custom({
      title: '',
      messageHtml:
        '<b>Para conservar los cambios antes pulsar Guardar, Si continúa perderá todos los datos cambiados</b>',
      buttons: [
        {
          text: 'Continuar',
          onClick: (e) => {
            dispatch(addCompany(new CompanyModel()))
            dispatch(addCompanyModulos(undefined))
            props.setShowForm(false)
            confirmDialog.hide()
          },
        },
        {
          text: 'Cancelar',
          onClick: (e) => {
            props.setShowForm(true)
            confirmDialog.hide()
          },
        },
      ],
    });
    if (isChangedState) {
      confirmDialog.show().then((dialogResult: any) => {
        console.log(dialogResult)
      });
    }
    props.setShowForm(false)
  };
  const onSubmit = async (data: any) => {
    setLoading(true)
    dispatch(addShowLoader(true))
    let result: boolean = false
    let resultasociacionviafirma: boolean = true
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }));
    }
    if (
      companyState?.companyModulos !== undefined &&
      companyState?.companyModulos?.length !== 0
    ) {
      if (company?.Id) {
        await putCompany(
          userState!.user!,
          company,
          calculadoraState,
          linkState?.links!,
          companyState,
          emailState,
        ).then(async function (response) {
          result = response
          if (
            isSuperAdmin(userState!.user!) &&
            codigoViaFirmaState !== undefined &&
            codigoViaFirmaState !== ''
          ) {
            await postCompanyViaFirma(
              company!.Id!,
              company!.Name!,
              codigoViaFirmaState!,
            ).then((response) => {
              resultasociacionviafirma = response
            })
          }
        });
      } else {
        await postCompany(
          userState!.user!,
          company!,
          calculadoraState,
          linkState?.links!,
          companyState,
          emailState,
        ).then(async function (response) {
          result = true
          if (
            isSuperAdmin(userState!.user!) &&
            codigoViaFirmaState !== undefined &&
            codigoViaFirmaState !== ''
          ) {
            await postCompanyViaFirma(
              response as number,
              company!.Name!,
              codigoViaFirmaState!,
            ).then((response) => {
              resultasociacionviafirma = response
            });
          };
        });
      };
    };

    if (result && resultasociacionviafirma) {
      setLoading(false);
      props.setShowForm(false);
      props.actualizar();
      localStorage.setItem(
        'parentEnlaces',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      );
    } else {
      setLoading(false);
      dispatch(addShowLoader(false));
    }
    dispatch(addShowLoader(false));
  }
  const companyFormRef: any = useRef(null);
  const companyTabRef: any = useRef(null);

  if (!props.showForm) {
    if (companyTabRef  != null ) {
    }
  }
  function selectedIndexClosed() {
    return props.showForm ? undefined : 1;
  }

  return (
    <React.Fragment>
      <>
        <div id={props.id}>
          <Box
            align='center'
            crossAlign='stretch'
            style={{ alignItems: 'center' }}
          >
            <Item ratio='1'>
              <form
                onSubmit={handleSubmit(onSubmit)}
                encType='multipart/form-data'
                style={{ width: '100%' }}
              >
                <Form
                  ref={companyFormRef}
                  id='tabs'
                  showValidationSummary={false}
                  validationGroup='companyData'
                >
                  <TabbedItem
                    ref={companyTabRef}
                    //tabPanelOptions={{ deferRendering:true  }}
                  >
                    <Tab title='Datos Principales'>
                      <Box direction='col' width='100%'>
                        <Item ratio='0' baseSize='120%'>
                          <CompanyFormPage
                            row={props.rowSelected}
                            setShowForm={props.setShowForm}
                            showForm={props.showForm}
                            companyTabRef={companyFormRef}
                            tempTieneArchivos={props.tempTieneArchivos}
                            crear={props.crear}
                          ></CompanyFormPage>
                        </Item>
                      </Box>
                    </Tab>

                    <Tab title='Configuración Calculadora'>
                      <Box direction='row' width='100%'>
                        <Item ratio='0' baseSize='120%'>
                          <CalculatorFormComponent
                            row={props.rowSelected}
                            setShowForm={props.setShowForm}
                            showForm={props.showForm}
                            companyTabRef={companyFormRef}
                          ></CalculatorFormComponent>
                        </Item>
                      </Box>
                    </Tab>
                    <Tab title='Enlaces'>
                      <Box direction='row' width='100%'>
                        <Item ratio='0' baseSize='120%'>
                          <FormLinkComponent
                            row={props.rowSelected}
                            setShowForm={props.setShowForm}
                            showForm={props.showForm}
                            companyTabRef={companyFormRef}
                          ></FormLinkComponent>
                        </Item>
                      </Box>
                    </Tab>

                    <Tab title='Email'>
                      <Box direction='row' width='100%'>
                        <Item ratio='0' baseSize='120%'>
                          <EmailFormComponent
                            row={props.rowSelected}
                            setShowForm={props.setShowForm}
                            showForm={props.showForm}
                            companyTabRef={companyTabRef}
                          ></EmailFormComponent>
                        </Item>
                      </Box>
                    </Tab>
                    <Tab title='Módulos'>
                      <Box direction='row' width='100%'>
                        <Item ratio='0' baseSize='120%'>
                          <ModuleList
                            row={props.rowSelected}
                            setShowForm={props.setShowForm}
                            showForm={props.showForm}
                            companyTabRef={companyFormRef}
                            crear={props.crear}
                          ></ModuleList>
                        </Item>
                      </Box>
                    </Tab>
                  </TabbedItem>
                  <SimpleItem render={renderButtons}></SimpleItem>
                </Form>
              </form>
            </Item>
          </Box>
        </div>
      </>
    </React.Fragment>
  );
};

export { CompanyFormTabsPage };
