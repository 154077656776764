import axiosInstance from '../../../utils/axios'
import {
  checkStatusCode,
  methodsEndPointType,
  NotifyType,
  showToast,
} from '../../../utils/sharedUitls'
import {
  LineaVehiculo,
  RemesaInformesLinea,
} from '../interfaces/InformesVehiculosInterfaces'
import axios from 'axios'

async function getAllRemesas() {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RemesasInformes`
  try {
    const response = await axiosInstance.get(url)
    checkStatusCode(response.status)
    return response.data
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    return null
  }
}

async function postRemesas(formData: RemesaInformesLinea) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RemesasInformesLinea`
  try {
    const response = await axiosInstance.post(url, formData)
    checkStatusCode(response.status)
    showToast('Remesas importadas con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error en postRemesas:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    throw error
  }
}

async function putRemesas(id: string, formData: LineaVehiculo[]) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RemesasInformes/${id}`
  try {
    const response = await axiosInstance.put(url, { lineas: formData })
    checkStatusCode(response.status)
    showToast('Remesa actualizada con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error al actualizar la remesa:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    throw error
  }
}

async function deleteRemesaLinea(id: number) {
  let url = `${
    process.env.REACT_APP_API_URL_CORE
  }/RemesasInformes/${id.toString()}`
  try {
    const response = await axiosInstance.delete(url)
    checkStatusCode(response.status)
    showToast('Remesa eliminada con éxito', NotifyType.success, 2000)
    return response.data
  } catch (error: any) {
    console.error('Error al eliminar la remesa:', error)
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 2000)
    }
    throw error
  }
}

async function getInformeFileLink(id: number, token: string) {
  try {
    const urlInfo = `${process.env.REACT_APP_API_URL_CORE}/Documento/fileinfo/${id}`
    const response = await axiosInstance.get(urlInfo)

    checkStatusCode(response.status)
    downloadBase64File(response.data.Base64Data, response.data.Name)
  } catch (error: any) {
    handleError(error)
  }
}

function downloadBase64File(base64Data: string, fileName: string) {
  // Decode base64 string to binary data
  const binaryString = window.atob(base64Data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  // Create a Blob from the binary data
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Generate a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
}


function handleError(error: any) {
  if (error.response && error.response.data && error.response.data.Message) {
    showToast(error.response.data.Message, NotifyType.error, 5000)
  } else {
    showToast(
      'El archivo no existe o ya no está disponible',
      NotifyType.error,
      5000,
    )
  }
  checkStatusCode((error as { request?: any })?.request?.status)
  console.error('Error descargando el archivo:', error)
}

export {
  getAllRemesas,
  postRemesas,
  deleteRemesaLinea,
  putRemesas,
  getInformeFileLink,
}
