import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from '../../../../models/columnModel';
import { DateConverter } from 'src/utils/dateUtils';

@JsonObject('')
class DetalleVehiculoModel {
  @JsonProperty('Bastidor', String)
  Bastidor: string = '';

  @JsonProperty('FechaFabricacion', DateConverter)
  FechaFabricacion: undefined;

  @JsonProperty('FechaITV', DateConverter)
  FechaITV: undefined;

  @JsonProperty('FechaMatricula', DateConverter)
  FechaMatricula: undefined;

  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Marca', String)
  Marca: string = '';

  @JsonProperty('Matricula', String)
  Matricula: string = '';

  @JsonProperty('Modelo', String)
  Modelo: string = '';

  @JsonProperty('Observaciones', String)
  Observaciones: string = '';
};

@JsonObject('')
export class DetalleVehiculoCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [DetalleVehiculoModel])
  Datos: DetalleVehiculoModel[] = [];
};
