import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ConfiguracionEnvioEmailModel')
class ConfiguracionEnvioEmailModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0;

  @JsonProperty('ServidorSMTP', String)
  ServidorSMTP: string = '';

  @JsonProperty('PuertoSMTP', Number)
  PuertoSMTP: number = 0;

  @JsonProperty('UsaSSL', Boolean)
  UsaSSL: boolean = false;

  @JsonProperty('NombreMostrar', String)
  NombreMostrar: string = '';

  @JsonProperty('DireccionEmail', String)
  DireccionEmail: string = '';

  @JsonProperty('UsuarioEmail', String)
  UsuarioEmail: string = '';

  @JsonProperty('PasswordEmail', String)
  PasswordEmail: string = '';
};

export { ConfiguracionEnvioEmailModel };
