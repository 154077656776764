import React from 'react';
import { GroupItem } from 'devextreme-react/form';
import DatosPersonalesVendedorRepresentante from './DatosPersonalesVendedorRepresentanteSection';
import DatosDireccionVendedorRepresentante from './DatosDireccionVendedorRepresentanteSection';
import { SolicitudListModel } from '../../models/solicitudListModel';
import { isCheckVendedorRepresentanteState } from 'src/recoil/sharesAtoms/atoms';
import { useRecoilValue } from 'recoil';

interface Props {
  solicitud: SolicitudListModel;
  sexo: Array<Object>;
  tipovia: Array<Object>;
}
function DatosVendedorRepresentanteSection({
  solicitud,
  sexo,
  tipovia,
}: Props) {
  const isCheckVendedorRepresentante = useRecoilValue(isCheckVendedorRepresentanteState);
  
  return (
    <GroupItem caption={'Representante legal'} visible={isCheckVendedorRepresentante}>
      
      {DatosPersonalesVendedorRepresentante({
        solicitud: solicitud,
        sexo: sexo,
      })}

      {DatosDireccionVendedorRepresentante({
        solicitud: solicitud!,
        tipovia: tipovia,
      })}
      
    </GroupItem>
  );
};

export default DatosVendedorRepresentanteSection;
