import { CompanyConsultaHistoricoGridModel } from '../../models/CompanyConsultaHistoricoGridModel'
import { CompanyConsultaModel } from '../../models/CompanyConsultaModel'
import { StockCompanyModel } from '../../models/StockCompanyModel'
import { CompanyInformaModel } from '../../models/companyInformaModel'
import { SaldoInformaModel } from '../../models/saldoInformaModel'
import {
  ADD_INFORMA_COMPANIES_STOCK,
  ADD_INFORMA_COMPANY,
  ADD_INFORMA_CONSULTA_HISTORICO,
  ADD_INFORMA_CONSULTA_HISTORICO_GRID,
  ADD_INFORMA_SALDO,
  InformaActionsTypes,
} from '../types/InformaTypesAction'

export const addInformaCompaniesStock = (
  stockcompanies: Array<StockCompanyModel> | StockCompanyModel | undefined,
): InformaActionsTypes => ({
  type: ADD_INFORMA_COMPANIES_STOCK,
  payload: stockcompanies,
})

export const addInformaSaldo = (
  saldo: SaldoInformaModel | undefined,
): InformaActionsTypes => ({
  type: ADD_INFORMA_SALDO,
  payload: saldo,
})

export const addInformaCompany = (
  company: CompanyInformaModel | undefined,
): InformaActionsTypes => ({
  type: ADD_INFORMA_COMPANY,
  payload: company,
})

export const AddInformaConsultaHistorico = (
  companyhistory: Array<CompanyConsultaModel> | undefined,
): InformaActionsTypes => ({
  type: ADD_INFORMA_CONSULTA_HISTORICO,
  payload: companyhistory,
})

export const AddInformaConsultaHistoricoGrid = (
  companyhistorygrid: Array<CompanyConsultaHistoricoGridModel> | undefined,
): InformaActionsTypes => ({
  type: ADD_INFORMA_CONSULTA_HISTORICO_GRID,
  payload: companyhistorygrid,
})
