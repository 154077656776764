import React from 'react';
import Form, { Item } from 'devextreme-react/form';
import { ColumnModelCore } from 'src/models/columnModel';
import { getColumnAlignment, getColumnType, getDxType, getValueType } from 'src/utils/sharedUitls';
import { useScreenSize } from 'src/utils/media-query';
// ----------------------------------------------------------------------------

interface Props {
  Campos: ColumnModelCore[];
  Datos: any[];
}

// ----------------------------------------------------------------------------
var optionPercentage = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

var formatterPercentage = new Intl.NumberFormat('es-ES', optionPercentage);
var optionCurrency = {
  style: 'currency',
  currency: 'EUR',
};

var formatterCurrency = new Intl.NumberFormat('de-DE', optionCurrency);

function CustomForm({ Campos, Datos }: Props) {
  function checkType(field: any) {
    if (field.Nombre === 'NumeroExpediente') {
      return 'dxTextBox';
    }
    if (field.Size === 3) {
      return 'dxTextArea';
    }
    if (field.Tipo === 'boolean') {
      return 'dxCheckBox';
    }
    return getDxType(field?.Type);
  }

  function checkTypeDateTimeFix(field: any) {
    if (field.Nombre === 'NumeroExpediente') {
      return 'dxTextBox';
    }
    if (field.Size === 3) {
      return 'dxTextArea';
    }
    if (field.Tipo === 'boolean') {
      return 'dxCheckBox';
    }
    if (field.Tipo === 'datetime') {
      return 'dxTextBox';
    }
    return getDxType(field?.Type);
  }

  // const formatterPercentage = new Intl.NumberFormat('es-ES', optionPercentage)

  // const formatterCurrency = new Intl.NumberFormat('de-DE', optionCurrency)

  const { isSmall } = useScreenSize();

  return (
    <Form id='form' colCount={2}>
      {Campos.map((campo: ColumnModelCore) => {
        var value = null
        if (Datos[0]  != null ) {
          value = Datos[0][campo.Nombre]
        };

        return (
          <Item
            key={campo.Nombre}
            dataField={campo.Nombre}
            colSpan={campo.Size < 3 ? campo.Size : isSmall ? 2 : 1}
            cssClass={
              getColumnAlignment(campo.Tipo) === 'Right' ? 'numberAlign' : ''
            }
            label={{ text: campo.Texto }}
            visible={
              campo.Tipo === 'date' && campo.Nombre == null 
                ? false
                : campo.Visible
            }
            editorOptions={{
              readOnly: true,
              format: getColumnType(campo.Tipo, campo.Format),
              fontWeight: 'bold',
              color: 'black',
              value: getValueType(campo.Format, campo.Tipo, value),
              //   value:
              //     value  != null 
              //       ? campo.Format === 'currency'
              //         ? formatterCurrency.format(value)
              //         : campo.Format === 'percent'
              //         ? formatterPercentage.format(value / 100)
              //         : campo.Tipo === 'datetime'
              //         ? value.toString() === new Date(0).toString()
              //           ? ''
              //           : value.toLocaleDateString('es-ES', {
              //               year: 'numeric',
              //               month: '2-digit',
              //               day: '2-digit',
              //             })
              //         : value
              //       : '',
              //   hint: value ?? campo.Texto + ': ' + value,
            }}
            editorType={checkType(campo)}
            itemType
          />
        );
      })};
    </Form>
  );
};

export { CustomForm };