import { Box, Button, Popup, ScrollView } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import DataGrid, { Column, StateStoring } from 'devextreme-react/data-grid'
import { ToolbarItem } from 'devextreme-react/date-box'
import { now } from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { checkScreenSize } from 'src/utils/sharedUitls'
import { onToolbarPreparing } from 'src/utils/tableUtils'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'

interface Props {
  cerrar: Function
}
export default function DatosHistoricoCompanySection({ cerrar }: Props) {
  const datosConsultaHistoricoState = useSelector(
    (state: RootState) => state.Informa.companyhistorygrid,
  )
  function exportExcell(e: any) {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Datos Consulta Histórico.xlsx',
        )
      })
    })
    e.cancel = true
  }

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        cerrar()
      }
    })
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          cerrar()
        }
      })
    }
  }, [])

  return (
    <Popup
      visible={true}
      hideOnOutsideClick={false}
      showCloseButton={false}
      width={window.innerWidth > 500 ? '40%' : '94%'}
      title={'Datos consulta histórico'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: () => cerrar(),
        }}
      />
      <ScrollView>
        <React.Fragment>
          <Box direction='row' width='100%'>
            <Item ratio={0} baseSize={'auto'}>
              <DataGrid
                id='informaHistoricoConsultaCompanyDataGrid'
                keyExpr='userId'
                dataSource={datosConsultaHistoricoState}
                style={{ width: '100%', minHeight: '250px' }}
                height={680}
                allowColumnReordering
                allowColumnResizing
                columnAutoWidth
                focusedRowEnabled
                remoteOperations
                repaintChangesOnly
                rowAlternationEnabled
                showBorders
                showRowLines
                columnChooser={{ enabled: true }}
                columnHidingEnabled={!checkScreenSize()}
                filterPanel={{ visible: true }}
                filterRow={{ visible: true }}
                headerFilter={{ visible: false }}
                loadPanel={{ enabled: true }}
                paging={{ enabled: false }}
                sorting={{ mode: 'multiple', showSortIndexes: false }}
                export={{
                  allowExportSelectedData: false,
                  enabled: true,
                }}
                onExporting={exportExcell}
                searchPanel={{
                  visible: true,
                  width: checkScreenSize() ? 240 : 120,
                }}
                selection={{
                  mode: 'single',
                }}
                scrolling={{
                  mode: 'standard',
                  scrollByContent: true,
                  scrollByThumb: true,
                  showScrollbar: 'always',
                }}
                onToolbarPreparing={(event: any) => {
                  onToolbarPreparing(event)
                }}
              >
                <StateStoring
                  enabled={true}
                  type={'localStorage'}
                  storageKey={'HistoricoDatesList'}
                />

                {/* <Column
                      width={'12%'}
                      type='drag'
                      alignment='center'
                      caption='Ver'
                      // cellRender={(row: any) => (
                      //   <Button
                      //     icon='edit'
                      //     onClick={(e: any) => {

                      //     }}
                      //   />
                      // )}
                    /> */}
                <Column
                  width={'50%'}
                  caption={'Nombre De Usuario'}
                  dataField={'userName'}
                  alignment='left'
                />
                <Column
                  width={'50%'}
                  caption={'Nombre De Empresa'}
                  dataField={'companyName'}
                  alignment='left'
                />
                <Column
                  width={'50%'}
                  caption={'Consultas'}
                  dataField={'consultas'}
                  alignment='right'
                />
              </DataGrid>
            </Item>
          </Box>
        </React.Fragment>
      </ScrollView>
    </Popup>
  )
}
