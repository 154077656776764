import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';
import { PerfilModelGrid } from './perfilModelGrid';

@JsonObject('PerfilesCampoDatoModel')
class PerfilesCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore] || [])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [PerfilModelGrid] || [])
  Datos: PerfilModelGrid[] = [];
};

export { PerfilesCampoDatoModel };
