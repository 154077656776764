import React from 'react';
// devextreme
import { Button, DataGrid } from 'devextreme-react';
import { Column, Scrolling, StateStoring } from 'devextreme-react/data-grid';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
// utils
import { getColumnAlignment, getColumnType, getFileIcon } from 'src/utils/sharedUitls';
import { getExpedienteFile } from 'src/pages/expedientePage/services/vehiculo/vehiculoService';

export default function DocumentosTabEscritura() {
  const documentosState = useSelector(
    (state: RootState) => state.escrituras.documentos,
  );
  const userState = useSelector((state: RootState) => state.user);

  function cellRender(data: any): any {
    let myIcon = getFileIcon(data.data.NombreArchivoOriginal);
    return (
      <Button
        text='Descargar'
        icon={myIcon}
        hint='Descarga el documento a su equipo'
        onClick={(event: any) => {
          getExpedienteFile(
            data.data.Id,
            encodeURIComponent(data.data.NombreArchivoOriginal),
            userState!.user!,
          );
        }}
      />
    );
  };

  return (
    <DataGrid
      dataSource={documentosState.Datos}
      keyExpr='Id'
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      focusedRowEnabled
      rowAlternationEnabled
      showBorders
      showRowLines
    >
      <StateStoring
        enabled
        type='localStorage'
        storageKey='gridDocumentosEscrituras'
      />
      <Scrolling showScrollbar='always' />
      <Column
        type='buttons'
        cellRender={(cellRenderData: any) => cellRender(cellRenderData)}
      />
      {documentosState.Campos.map((value) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Texto}
          dataType={value.Tipo}
          format={getColumnType(value.Tipo, value.Format)}
          alignment={getColumnAlignment(value.Tipo)}
          visible={value.Nombre === 'Id' ? false : value.Visible}
        />
      ))};
    </DataGrid>
  );
};
