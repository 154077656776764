// import { DateConverter } from 'src/utils/dateUtils';
import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';

@JsonObject('')
class TareaPendienteGridModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('FechaInicio', String)
  FechaInicio: string = '';

  @JsonProperty('FechaFin', String)
  FechaFin: string = '';

  @JsonProperty('Descripcion', String)
  Descripcion: string = '';

  @JsonProperty('Observaciones', String)
  Observaciones: string = '';

  @JsonProperty('ClaveExterna', Number)
  ClaveExterna: number = 0;
};

@JsonObject('')
export class TareaPendienteCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore] || [])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [TareaPendienteGridModel] || [])
  Datos: TareaPendienteGridModel[] = [];
};
