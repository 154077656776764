import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from 'src/utils/dateUtils'
import { SujetoModel as Sujeto } from './sujetoModel'
import { VehicleModel as Vehiculo } from './vehiculoModels'
import { DestinoVehiculosModels as Destino } from './DestinoVehiculosModels'

//Estructura de la peticion con sus respectivos objetos

@JsonObject('SolicitudListModel')
export class SolicitudListModel {
  @JsonProperty('IdExpediente', Number, 0)
  IdExpediente: number = 0

  @JsonProperty('OriginType', Number, 1)
  OriginType: number = 1

  @JsonProperty('DossierType', String, 'MAT')
  DossierType: string = 'MAT'

  @JsonProperty('Client', Sujeto)
  Client: Sujeto = new Sujeto()

  @JsonProperty('ClientLegalRep', Sujeto)
  ClientLegalRep: Sujeto = new Sujeto()

  @JsonProperty('Seller', Sujeto)
  Seller: Sujeto = new Sujeto()

  @JsonProperty('SellerLegalRep', Sujeto)
  SellerLegalRep: Sujeto = new Sujeto()

  @JsonProperty('Vehicle', Vehiculo)
  Vehicle: Vehiculo = new Vehiculo()

  @JsonProperty('DealerNumber', String, '')
  DealerNumber: string = ''

  @JsonProperty('TotalInvoiceAmount', Number)
  TotalInvoiceAmount: number = -1

  @JsonProperty('Base576Amount', Number)
  Base576Amount: number = -1

  @JsonProperty('DestinationService', Destino)
  DestinationService: Destino = new Destino()

  @JsonProperty('FamiliaNumerosa', Boolean)
  FamiliaNumerosa: boolean = false

  @JsonProperty('Minusvalido', Boolean)
  Minusvalido: boolean = false

  @JsonProperty('OrganismoOficial', Boolean)
  OrganismoOficial: boolean = false

  @JsonProperty('Taxi', Boolean)
  Taxi: boolean = false

  @JsonProperty('Comment', String, '')
  Comment: string = ''

  @JsonProperty('GenerarInformeDgt', Boolean)
  GenerarInformeDgt: boolean = false

  @JsonProperty('InputsDocumentacion', Array<InputsDocumentacion>)
  InputsDocumentacion: Array<InputsDocumentacion> = []
}

@JsonObject('InputsDocumentacion')
class InputsDocumentacion {
  @JsonProperty('DossierTypeId', Number)
  DossierTypeId: number = 0

  @JsonProperty('InputName', String)
  InputName: string = ''

  @JsonProperty('Required', Boolean)
  Required: boolean = false

  @JsonProperty('Comentario', String)
  Comentario: string = ''
}

