import { JsonObject, JsonProperty } from 'json2typescript'
import { DestinoTipoVehiculo } from './tipodestinovehiculointerface';

@JsonObject('DestinoVehiculosModels')

export class DestinoVehiculosModels implements DestinoTipoVehiculo {

    @JsonProperty('Id', Number)
    Id: number = 0
    @JsonProperty('IdElGestor', Number)
    IdElGestor: number = 0
    @JsonProperty('Descripcion', String, '')
    Descripcion: string = ''
}