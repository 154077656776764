import {
  ADD_LINKS,
  ADD_PARENT_LINKS,
  LinkActionsTypes,
  LinkState,
} from './IlinkType'

const initialState: LinkState = {
  links: undefined,
  parentLinks: undefined,
}

export const linkReducer = (
  state = initialState,
  action: LinkActionsTypes,
) : LinkState | undefined => {
  switch (action.type) {
    case ADD_LINKS: {
      return { ...state, links: action.payload };
    }
    case ADD_PARENT_LINKS: {
      return { ...state, parentLinks: action.payload };
    }
    default:
      return state;
  }
}
