import {
  SujetActionsTypes,
  ADD_PROFILE,
  CLEAR_PROFILE,
  ProfileState as ProfileState,
  ADD_PROFILES,
} from './IProfileType'

const initialState: ProfileState = {
  profile: undefined,
  profiles: undefined,
}

const profileReducer = (
  state = initialState,
  action: SujetActionsTypes,
): ProfileState => {
  switch (action.type) {
    case ADD_PROFILE: {
      return { ...state, profile: action.payload };
    }
    case ADD_PROFILES: {
      return { ...state, profiles: action.payload };
    }
    case CLEAR_PROFILE: {
      return { ...state, profile: action.payload };
    }
    default:
      return state;
  };
};

export { profileReducer };
