import React, { useEffect, useRef, useState } from 'react'
import informaAxios from '../../utils/serviciosRuanoAxios'
import { Button, Form, Popup, SelectBox } from 'devextreme-react'
import moment from 'moment'
import {
  checkScreenSize,
  formatDatePrettier,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import { useScreenSize } from 'src/utils/media-query'
import {
  GroupItem,
  SimpleItem,
  TabbedItem,
  Tab,
  ButtonItem,
  ButtonOptions,
} from 'devextreme-react/form'
import { Box, Item } from 'devextreme-react/box';
import ScrollView from 'devextreme-react/scroll-view'
import { ToolbarItem } from 'devextreme-react/popup'
import { useForm } from 'react-hook-form'
import { addShowLoader } from 'src/redux/actions/configActions'
import { useDispatch, useSelector } from 'react-redux'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import * as XLSX from 'xlsx'
import DataSource from 'devextreme/data/data_source'
import { darPaisBastidor } from 'src/utils/PaisPorBastidor/sacarPaisBastidor'
import { RootState } from 'src/redux/combineReducers'
import { DatosHistorialMicrodatos } from 'src/utils/ConsultaVehiculoBastidor/ConsultaVehiculoBastidor'
import {
  MicrodatosModel,
  MicrodatosVehiculosAntiguosModel,
} from './models/microdatosModel'

interface usuarioBusqueda {
  nombre: string
  bastidores: Array<string>
  idbusqueda: number
}

/**
   * Funcion para establecer los puntos para los miles
   */

export function numMiles(num: number): string {
  var numeroMapeado: string = num.toString()

  var cifras = numeroMapeado.split('')

  var cantidadPuntos: number =
    cifras.length / 3 > 0 ? Math.trunc(cifras.length / 3) : 0
  var cantidadCifras: number = cifras.length

  while (cantidadPuntos > 0) {
    cantidadCifras -= 3
    if (cantidadCifras !== 0) {
      cifras.splice(cantidadCifras, 0, '.')
    }

    cantidadPuntos--
  }

  numeroMapeado = cifras.join('')

  return numeroMapeado
}

/**
 * Funcion para sustituir los puntos
 * por comas
 */
export function numDecimals(num: number): string {
  var numWithCommas: string = num.toString()

  return numWithCommas.replaceAll('.', ',')
}

function MicrodatosBastidores() {
  let noagregar: boolean = true
  let cochedato: any = {}
  let listavehiculosanteriores: any = []
  const { handleSubmit } = useForm()
  const dispatch = useDispatch()
  const formData = useRef({})
  const [dataRes, setDataRes] = useState(new MicrodatosModel())
  const [catVE, setcatVE]: any = useState([])
  const [clTramite, setClTramite] = useState([])
  const [codigoClaseMatricula, setCodigoClaseMatricula] = useState([])
  const [codigoPropulsion, setCodigoPropulsion] = useState([])
  const [codigoProcedencia, setCodigoProcedencia] = useState([])
  const [codigoProvincia, setCodigoProvincia] = useState([])
  const [codigoProvinciaMatriculacion, setCodigoProvinciaMatriculacion] =
    useState([])
  const [indicadorBajaDefinitiva, setIndicadorBajaDefinitiva] = useState([])
  const userState = useSelector((state: RootState) => state.user)

  const [codigoTipo, setCodigoTipo] = useState([])
  const [bearerToken, setBearerToken] = useState(String)
  const [value, setValue] = useState('')
  const { isLarge } = useScreenSize()
  const [showPopup, setShowPopup] = useState(false)
  const refshowPopup2 = false
  const showPopup2 = useRef(refshowPopup2)
  const [listaBastidores, setListaBastidores] = useState<usuarioBusqueda>({bastidores: [], nombre: '', idbusqueda: 0})
  const [datosGrid, setDatosGrid] = useState(
    DatosHistorialMicrodatos(listaBastidores.bastidores),
  )
  const listarcoches = false
  let errorBusqueda = false
  const reflistarcoches = useRef(listarcoches)
  const datosvehiantiguos: any = []
  const [tiposvehiculosantiguos, setTiposVehiculosAntiguos] = useState({})
  const refdatosvehiantiguos = useRef(datosvehiantiguos)
  const [refcocheelegido, setCocheElegido] = useState(
    new MicrodatosVehiculosAntiguosModel(),
  )
  const tempPaisCoche = useRef('')

  useEffect(() => {
    const storedBusqueda = localStorage.getItem('busquedas')
    if(storedBusqueda){
      setListaBastidores(JSON.parse(storedBusqueda))
      setDatosGrid(DatosHistorialMicrodatos(JSON.parse(storedBusqueda).bastidores))
    }
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setShowPopup(false)
        showPopup2.current = false
        setCocheElegido(new MicrodatosVehiculosAntiguosModel())
      }
    })
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowPopup(false)
          showPopup2.current = false
          setCocheElegido(new MicrodatosVehiculosAntiguosModel())
        }
      })
    }
  }, [])

  const renderButtons = () => {
    return (
      <Button
        icon='find'
        text='Buscar'
        type='default'
        useSubmitBehavior
        stylingMode='contained'
        style={{ float: 'right', margin: '2em' }}
      />
    )
  }

  const onSubmit = async (e: any) => {
    if (value !== '') {
      reflistarcoches.current = false

      let busquedaRepetida = false

      let consulta = `eDgtVehiculos/GetByBastidor?bastidor=${value.trim()}`

      if (noagregar) {
      } else {
        consulta = `eDgtVehiculos/GetByBastidor?bastidor=${value.trim()}`
      }

      const res: any = await informaAxios.get(consulta);

      if (res.status === 200) {
        dispatch(addShowLoader(true))

        setDataRes(res.data)

        res.data.categoriaVehiculoElectrico = res.data
          .categoriaVehiculoElectrico
          ? res.data.categoriaVehiculoElectrico.trim()
          : ''
        res.data.paisDeOrigen = darPaisBastidor(value.trim())


        if (res.data.categoriaVehiculoElectrico != '') {
          const catVehElec: any = await informaAxios
            .get(
              `eDgtCategoriaVehElectrico/GetByCodigo?categoriaVehElectrico=${res.data.categoriaVehiculoElectrico.trim()}`,
            )
            .then((response) => setcatVE(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.claveTramite = res.data.claveTramite
          ? res.data.claveTramite.trim()
          : ''
        if (res.data.claveTramite != '') {
          const clTramite: any = await informaAxios
            .get(
              `eDgtClaveTramite/GetByCodigo?claveTramite=${res.data.claveTramite}`,
            )
            .then((response) => setClTramite(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codClaseMat = res.data.codClaseMat
          ? res.data.codClaseMat.trim()
          : ''
        if (res.data.codClaseMat != '') {
          const codClasMatricula: any = await informaAxios
            .get(
              `eDgtCodClaseMat/GetByCodigo?codClaseMat=${res.data.codClaseMat.trim()}`,
            )
            .then((response) =>
              setCodigoClaseMatricula(response.data.descripcion),
            )
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codPropulsionItv = res.data.codPropulsionItv
          ? res.data.codPropulsionItv.trim()
          : ''
        if (res.data.codPropulsionItv != '') {
          const codProp: any = await informaAxios
            .get(
              `eDgtCodPropulsion/GetByCodigo?codPropulsion=${res.data.codPropulsionItv.trim()}`,
            )
            .then((response) => setCodigoPropulsion(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codProcedenciaITV = res.data.codProcedenciaITV
          ? res.data.codProcedenciaITV.trim()
          : ''
        if (res.data.codProcedenciaITV != '') {
          const codProc: any = await informaAxios
            .get(
              `eDgtCodProcedencia/GetByCodigo?codProcedencia=${res.data.codProcedenciaITV.trim()}`,
            )
            .then((response) => setCodigoProcedencia(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codProvinciaVehiculo = res.data.codProvinciaVehiculo
          ? res.data.codProvinciaVehiculo.trim()
          : ''
        if (res.data.codProvinciaVehiculo != '') {
          const codProv: any = await informaAxios
            .get(
              `eDgtCodProvinciaVeh/GetByCodigo?codProvinciaVeh=${res.data.codProvinciaVehiculo.trim()}`,
            )
            .then((response) => setCodigoProvincia(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codProvinciaMatriculacion = res.data.codProvinciaMatriculacion
          ? res.data.codProvinciaMatriculacion.trim()
          : ''
        if (res.data.codProvinciaMatriculacion != '') {
          const codProvMat: any = await informaAxios
            .get(
              `eDgtCodProvinciaMat/GetByCodigo?codProvinciaMat=${res.data.codProvinciaMatriculacion.trim()}`,
            )
            .then((response) =>
              setCodigoProvinciaMatriculacion(response.data.descripcion),
            )
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.codTipo = res.data.codTipo ? res.data.codTipo.trim() : ''
        if (res.data.codTipo != '') {
          const codTipoo: any = await informaAxios
            .get(`eDgtCodTipo/GetByCodigo?codTipo=${res.data.codTipo.trim()}`)
            .then((response) => setCodigoTipo(response.data.descripcion))
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        res.data.indBajaDefinitiva = res.data.indBajaDefinitiva
          ? res.data.indBajaDefinitiva.trim()
          : ''
        if (res.data.indBajaDefinitiva != '') {
          const indBaja: any = await informaAxios
            .get(
              `eDgtIndBajaDefinitiva/GetByCodigo?indBajaDefinitiva=${res.data.indBajaDefinitiva.trim()}`,
            )
            .then((response) =>
              setIndicadorBajaDefinitiva(response.data.descripcion.trim()),
            )
            .catch((error) => {
              errorBusqueda = true
              showToast('Error al buscar el bastidor', NotifyType.error, 500)
            })
        }
        tempPaisCoche.current = darPaisBastidor(value)
        if (!errorBusqueda) {
          setShowPopup(true)
        }
        dispatch(addShowLoader(false))
      } else if(res.status === 204){
        listaBastidores.bastidores = listaBastidores.bastidores.map(
            (bastidor) => (bastidor === value ? "" : bastidor)
        )
        errorBusqueda = true
        dispatch(addShowLoader(false))
        showToast('Bastidor No Encontrado.', NotifyType.error, 5000)
      } else {
        let consulta = `eDgtVehiculos/GetByBastidorParcial?bastidor=${value.trim()}`
        if (!noagregar) {
          consulta = `eDgtVehiculos/GetByBastidorParcial?bastidor=${value.trim()}`
        }

        dispatch(addShowLoader(true))
        const vehiculosanteriores: any = await informaAxios
          .get(consulta)
          .catch((error) => {
            errorBusqueda = true
            showToast('Error al buscar el bastidor', NotifyType.error, 500)
          })

        if (vehiculosanteriores.data.length > 0) {
          refdatosvehiantiguos.current = vehiculosanteriores.data

          vehiculosanteriores.data.map((coche: any) => {
            listavehiculosanteriores.push({
              Id: coche.bastidor,
              Nombre: `${String(coche.marcaItv).trim()}/${String(
                coche.modeloItv,
              ).trim()}/${
                numDecimals(Math.round(coche.kwItv * 1.36)) +
                'CV(' +
                numDecimals(coche.kwItv) +
                ' kW)'
              }`.trim(),
            })
          })

          const selectBoxDataSource = new DataSource({
            store: {
              type: 'array',
              data: listavehiculosanteriores,
              key: 'Id',
            },
          })

          reflistarcoches.current = true

          setTiposVehiculosAntiguos(selectBoxDataSource)

          dispatch(addShowLoader(false))
        } else {
          dispatch(addShowLoader(false))
          showToast('Bastidor No Encontrado.', NotifyType.error, 5000)
        }
      }

      if (!errorBusqueda) {
        actualizarBusquedaBastidores(res.data.bastidor)
      }
      noagregar = true
      errorBusqueda = false
    } else {
      errorBusqueda = false
      showToast('Debes introducir un bastidor', NotifyType.error, 5000)
    }
  }

  function actualizarBusquedaBastidores(bastidor: string): void {
    //checck if its already in the list
    let index = listaBastidores.bastidores.indexOf(bastidor)
    if (index === -1) {
      listaBastidores.bastidores.push(bastidor)
    }
    localStorage.setItem('busquedas', JSON.stringify(listaBastidores))
    setDatosGrid(DatosHistorialMicrodatos(listaBastidores.bastidores))
  }

  function exportarDatos() {
    try {
      let nombreFichero = `datos bastidor ${dataRes.bastidor}.xlsx`
      let datos = [JSON.parse(JSON.stringify(dataRes))]

      let datosmapeados = datos.map((dato) => ({
        Bastidor: dato.bastidor,
        Marca: dato.marcaItv,
        Modelo: dato.modeloItv,
        Cilindrada: dato.cilindradaItv,
        Potencia: dato.kwItv,
        Plazas: dato.numPlazas,
        Emisiones: dato.co2Itv,
        'Nivel De Emisiones': dato.nivelEmisionesEuroItv,
        Variante: dato.varianteItv,
        Fabricante: dato.fabricanteItv,
        'Masa Máxima': dataRes.pesoMaximo,
        Carrocería: dato.carroceria,
        'Vía Anterior': dato.viaAnteriorItv,
        Versión: dato.versionItv,
        'Masa en circulación (Tara):': dato.tara,
        'Categoría Homologación Europea': dato.categoriaHomoligacionEuItv,
        'Distancia entre ejes': dato.distanciaEjes12Itv,
        'Vía Posterior': dato.viaPosteriorItv,
        'Numero de Titulares': dato.numTitulares,
        'Primera Matriculación': moment(dato.fecMatricula).format('DD-MM-YYYY'),
        'Autonomía Vehículo Eléctrico': dato.autonomiaVehiculoElectrico,
        'Categoría Vehículo Eléctrico': catVE,
        'Baja Telemática': dato.bajaTelematica,
        'Clasificación Vehículos': dato.clasificacionRegVehiculosItv,
        'Clase Matrícula': codigoClaseMatricula,
        Propulsión: codigoPropulsion,
        Procedencia: codigoProcedencia,
        'Provincia Vehículo': codigoProvincia,
        'Provincia Matriculación': codigoProvinciaMatriculacion,
        'Clave Trámite': clTramite,
        'Tipo de vehículo': codigoTipo,
        'Código Eco': dato.codigoEcoItv,
        'Código ITV': dato.codigoItv,
        'Consumo Kw': dato.consumoWhKwItv,
        'Constraseña Homologación': dato.contrasenaHomologacionItv,
        'Eco Innovación ITV': dato.ecoInnovacionItv ? 'Si' : 'No',
        'Fabricante Vehículo': dato.fabricanteVehiculoBase,
        'Fecha Proceso': formatDatePrettier(dato.fecProceso),
        'Fecha Tramitación': formatDatePrettier(dato.fecTramitacion),
        'Fecha Tramite': formatDatePrettier(dato.fecTramite),
        'Baja Definitiva': indicadorBajaDefinitiva,
        'Baja Temporal': dato.indBajaTemporal ? 'Si' : 'No',
        'Código Tutela': dato.indCodTutela ? 'Si' : 'No',
        Embargo: dato.indEmbargo ? 'Si' : 'No',
        Precinto: dato.indPrecinto ? 'Si' : 'No',
        Renting: dato.indRenting ? 'Si' : 'No',
        Sustracción: dato.indSustraccion ? 'Si' : 'No',
        Usado: dato.indUsado ? 'Si' : 'No',
        'Localidad Vehículo': dato.localidadVehiculo,
        'Marca Vehículo Base': dato.marcaVehiculoBase,
        'Masa Maxima Tecnicamente Admisible':
          dato.masaMaximaTecnicamenteAdmisibleItv,
        'Masa Orden Marcha': dato.masaOrdenMarchaItv,
        Matrícula: dato.matricula,
        Municipio: dato.municipio,
        'Plazas Pie': dataRes.plazasPie,
        Nivel: dato.nivelEmisionesEuroItv,
        Notas: dato.notas,
        'Numero Máximo Plazas': dato.numPlazasMaximo,
        'Número de Transmisiones': dato.numTransmisiones,
        'Persona Física': dato.personaFisicaJuridica,
        'Reducción ECO': dato.reduccionEcoItv,
        Servicio: dato.servicio,
        'Tipo Alimentación': dato.tipoAlimentacionItv,
        'Tipo ITV': dato.tipoItv,
        'Tipo ITV Base': dato.tipoItvBase,
        'Variante Vehículo Base': dato.varianteVehiculoBase,
        'Versión Vehículo Base:': dato.versionVehiculoBase,
      }))

      let WorkSheet = XLSX.utils.json_to_sheet(datosmapeados)
      let WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(WorkBook, WorkSheet, 'Datos Bastidor')
      XLSX.writeFile(WorkBook, nombreFichero)
    } catch (e) {
      alert('no es posible exportar los datos ' + e)
    }
  }

  /**
   * Cambio estructura a tipo tabla para ver el contenido bien distribuido
   *
   *
   */

  const renderImage = (data: any) => {
    const nombrePais = data.data.PaisDeOrigen

    return (
      data.value.foto !== undefined &&
      data.value.foto !== null &&
      data.value.foto !== '' && (
        <>
          <p>{nombrePais}</p>
          <img
            src={data.value.foto}
            style={{ width: '60px', height: '40px' }}
          ></img>
        </>
      )
    )
}
  const renderBastidor = (data: any) => {
    const paisDeOrigen = data.value.foto !== undefined && data.value.foto !== null && data.value.foto !== '' ? true : false
    return (
      <p
        onClick={(e) => {
          noagregar = false
          onSubmit(e.currentTarget.textContent!)
        }}
        style={{ cursor: 'pointer', paddingTop: paisDeOrigen ? '0px' : '25px'}}
      >
        {data.value}
        
      </p>
    )
    
  }

  const verPais = () => {
    const pais = tempPaisCoche.current;
    const foto =
      pais !== ''
        ? require('./imgs/' + pais + '.jpg')
        : ''
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{pais}</span>
        <img
          src={foto}
          width={60}
          height={40}
          style={{ marginLeft: '10px' }}
        ></img>
      </div>
    )
}

  return (
    <div id='container-body'>
      <Popup
        visible={showPopup}
        hideOnOutsideClick={false}
        showCloseButton={false}
        height='90%'
        width='90%'
        title='Consulta'
      >
        <ToolbarItem
          widget='dxButton'
          location='after'
          options={{
            icon: 'close',
            onClick: () => {
              setShowPopup(false)
            },
          }}
        />
        <ScrollView>
          <React.Fragment>
            <div>
              <Box
                align='center'
                crossAlign='stretch'
                style={{ alignItems: 'center' }}
              >
                <Item ratio='1'>
                  <Form
                    id='tabs'
                    readOnly={true}
                    labelLocation={isLarge ? 'left' : 'top'}
                    showColonAfterLabel={true}
                  >
                    <TabbedItem>
                      <Tab title='Datos Generales'>
                        <GroupItem colCount={2}>
                          <SimpleItem
                            label={{ text: 'Bastidor' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.bastidor,
                              value: dataRes.bastidor,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Marca' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.marcaItv,
                              value: dataRes.marcaItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Modelo' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.modeloItv,
                              value: dataRes.modeloItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Cilindrada' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.cilindradaItv,
                              value:
                                dataRes.cilindradaItv !== undefined
                                  ? numMiles(dataRes.cilindradaItv)
                                  : dataRes.cilindradaItv,
                            }}
                          ></SimpleItem>

                          <SimpleItem
                            label={{ text: 'Potencia' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: Math.round,
                              value:
                                dataRes.kwItv !== undefined
                                  ? numDecimals(
                                      Math.round(dataRes.kwItv * 1.36),
                                    ) +
                                    'CV (' +
                                    numDecimals(dataRes.kwItv) +
                                    ' kW)'
                                  : Math.round(dataRes.kwItv * 1.36) +
                                    'CV (' +
                                    dataRes.kwItv +
                                    ' kW)',
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Plazas' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.numPlazas,
                              value: dataRes.numPlazas,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Emisiones' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.co2Itv,
                              value:
                                dataRes.co2Itv !== undefined
                                  ? numMiles(dataRes.co2Itv)
                                  : dataRes.co2Itv + ' g/km',
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Nivel de emisiones' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.nivelEmisionesEuroItv,
                              value: dataRes.nivelEmisionesEuroItv,
                            }}
                          ></SimpleItem>
                          {tempPaisCoche.current !== '' && (
                            <SimpleItem
                              label={{ text: 'Fabricación' }}
                              render={verPais}
                              cssClass={'imgpais'}
                            ></SimpleItem>
                          )}
                        </GroupItem>
                      </Tab>
                      <Tab title='Datos Técnicos'>
                        <GroupItem colCount={2}>
                          <SimpleItem
                            label={{ text: 'Variante' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.varianteItv,
                              value: dataRes.varianteItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Versión' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.versionItv,
                              value: dataRes.versionItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Fabricante' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.fabricanteItv,
                              value: dataRes.fabricanteItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Masa en circulación (tara)' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.tara,
                              value:
                                dataRes.tara !== undefined
                                  ? numMiles(dataRes.tara)
                                  : dataRes.tara,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Masa máxima' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.pesoMaximo,
                              value:
                                dataRes.pesoMaximo !== undefined
                                  ? numMiles(dataRes.pesoMaximo)
                                  : dataRes.pesoMaximo,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Categoría homologación europea' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.categoriaHomoligacionEuItv,
                              value: dataRes.categoriaHomoligacionEuItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Carrocería' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.carroceria,
                              value: dataRes.carroceria,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Distancia entre ejes' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.distanciaEjes12Itv,
                              value:
                                dataRes.distanciaEjes12Itv !== undefined
                                  ? numMiles(dataRes.distanciaEjes12Itv)
                                  : dataRes.distanciaEjes12Itv + '  mm',
                            }}
                          ></SimpleItem>

                          <SimpleItem
                            label={{ text: 'Vía anterior' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.viaAnteriorItv,
                              value: dataRes.viaAnteriorItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Vía posterior' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.viaPosteriorItv,
                              value: dataRes.viaPosteriorItv,
                            }}
                          ></SimpleItem>
                        </GroupItem>
                      </Tab>
                      <Tab title='Propietarios'>
                        <GroupItem colCount={2}>
                          <SimpleItem
                            label={{ text: 'Numero de titulares' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.numTitulares,
                              value:
                                dataRes.numTitulares !== undefined
                                  ? numMiles(dataRes.numTitulares)
                                  : dataRes.numTitulares,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Primera matriculación' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: moment(dataRes.fecMatricula).format(
                                'DD-MM-YYYY',
                              ),
                              value: moment(dataRes.fecMatricula).format(
                                'DD-MM-YYYY',
                              ),
                            }}
                          ></SimpleItem>
                        </GroupItem>
                      </Tab>
                      <Tab title='Otros Datos'>
                        <GroupItem colCount={3}>
                          <SimpleItem
                            label={{ text: 'Autonomía vehículo eléctrico' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.autonomiaVehiculoElectrico,
                              value: dataRes.autonomiaVehiculoElectrico,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Categoría vehículo eléctrico' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: catVE,
                              value: catVE,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Baja telemática' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.bajaTelematica,
                              value: dataRes.bajaTelematica,
                            }}
                          ></SimpleItem>

                          <SimpleItem
                            label={{ text: 'Clasificación vehículos' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.clasificacionRegVehiculosItv,
                              value: dataRes.clasificacionRegVehiculosItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Clase matrícula' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoClaseMatricula,
                              value: codigoClaseMatricula,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Propulsión' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoPropulsion,
                              value: codigoPropulsion,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Procedencia' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoProcedencia,
                              value: codigoProcedencia,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Provincia vehículo' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoProvincia,
                              value: codigoProvincia,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Provincia matriculación' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoProvinciaMatriculacion,
                              value: codigoProvinciaMatriculacion,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Clave trámite' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: clTramite,
                              value: clTramite,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Tipo de vehículo' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: codigoTipo,
                              value: codigoTipo,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Código eco' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.codigoEcoItv,
                              value: dataRes.codigoEcoItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Código itv' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.codigoItv,
                              value: dataRes.codigoItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Consumo kw' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.consumoWhKwItv,
                              value:
                                dataRes.consumoWhKwItv !== undefined
                                  ? numMiles(dataRes.consumoWhKwItv)
                                  : dataRes.consumoWhKwItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Constraseña homologación' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.contrasenaHomologacionItv,
                              value: dataRes.contrasenaHomologacionItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Eco innovación itv' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.ecoInnovacionItv,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Fabricante vehículo' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.fabricanteVehiculoBase,
                              value: dataRes.fabricanteVehiculoBase,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Fecha proceso' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.fecProceso,
                              value: dataRes.fecProceso
                                ? formatDatePrettier(dataRes.fecProceso)
                                : '',
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Fecha tramitación' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.fecTramitacion,
                              value: dataRes.fecTramitacion
                                ? formatDatePrettier(dataRes.fecTramitacion)
                                : '',
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Fecha trámite' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.fecTramite,
                              value: dataRes.fecTramite
                                ? formatDatePrettier(dataRes.fecTramite)
                                : '',
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Baja definitiva' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: indicadorBajaDefinitiva,
                              value: indicadorBajaDefinitiva,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Baja temporal' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indBajaTemporal,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Código tutela' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indCodTutela,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Embargo' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indEmbargo,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Precinto' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indPrecinto,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Renting' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indRenting,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Sustracción' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indSustraccion,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Usado' }}
                            editorType={'dxCheckBox'}
                            editorOptions={{
                              value: dataRes.indUsado,
                            }}
                            cssClass='checkboxes'
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Localidad vehículo' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.localidadVehiculo,
                              value: dataRes.localidadVehiculo,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Marca vehículo base' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.marcaVehiculoBase,
                              value: dataRes.marcaVehiculoBase,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{
                              text: 'Masa máxima tecnicamente admisible',
                            }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.masaMaximaTecnicamenteAdmisibleItv,
                              value: dataRes.masaMaximaTecnicamenteAdmisibleItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Masa orden marcha' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.masaOrdenMarchaItv,
                              value: dataRes.masaOrdenMarchaItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Matrícula' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.matricula,
                              value: dataRes.matricula,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Municipio' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.municipio,
                              value: dataRes.municipio,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Nive' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.nive,
                              value: dataRes.nive,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Notas' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.notas,
                              value: dataRes.notas,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Número máximo plazas' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.numPlazasMaximo,
                              value:
                                dataRes.numPlazasMaximo !== undefined
                                  ? numMiles(dataRes.numPlazasMaximo)
                                  : dataRes.numPlazasMaximo,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Número  transmisiones' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.numTransmisiones,
                              value:
                                dataRes.numTransmisiones !== undefined
                                  ? numMiles(dataRes.numTransmisiones)
                                  : dataRes.numTransmisiones,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Persona física' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.personaFisicaJuridica,
                              value: dataRes.personaFisicaJuridica,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Plazas pie' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.plazasPie,
                              value:
                                dataRes.plazasPie !== undefined
                                  ? numMiles(dataRes.plazasPie)
                                  : dataRes.plazasPie,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Potencia' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.potenciaItv,
                              value:
                                dataRes.potenciaItv !== undefined
                                  ? numDecimals(dataRes.potenciaItv)
                                  : dataRes.potenciaItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Reducción eco' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.reduccionEcoItv,
                              value: dataRes.reduccionEcoItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Servicio' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.servicio,
                              value: dataRes.servicio,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Tipo alimentación' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.tipoAlimentacionItv,
                              value: dataRes.tipoAlimentacionItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Tipo itv' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.tipoItv,
                              value: dataRes.tipoItv,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Tipo itv base' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.tipoItvBase,
                              value: dataRes.tipoItvBase,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Variante vehículo base' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.varianteVehiculoBase,
                              value: dataRes.varianteVehiculoBase,
                            }}
                          ></SimpleItem>
                          <SimpleItem
                            label={{ text: 'Versión vehículo base' }}
                            editorType={'dxTextBox'}
                            editorOptions={{
                              mode: 'text',
                              hint: dataRes.versionVehiculoBase,
                              value: dataRes.versionVehiculoBase,
                            }}
                          ></SimpleItem>
                        </GroupItem>
                      </Tab>
                    </TabbedItem>
                    <ButtonItem>
                      <ButtonOptions
                        text={'Exportar datos'}
                        type='default'
                        onClick={() => {
                          exportarDatos()
                        }}
                      ></ButtonOptions>
                    </ButtonItem>
                  </Form>
                </Item>
              </Box>
            </div>
          </React.Fragment>
        </ScrollView>
      </Popup>
      <br></br>
      <Box direction='col' align='start' crossAlign='start' width={'100%'}>
        <Item ratio={0} baseSize={'auto'}>
          <h2>Consulta vehículos por bastidor</h2>
        </Item>
      </Box>
      <Box direction='row' align='center' crossAlign='center' width={'100%'}>
        <Item ratio={0} baseSize={'auto'}>
          <h3>Introduzca un número de bastidor</h3>
        </Item>
      </Box>
      <Box direction='row' width={'auto'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'49%'}>
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
            <Form
              formData={formData.current}
              labelLocation={isLarge ? 'left' : 'top'}
              validationGroup='findVin'
            >
              <SimpleItem
                editorType='dxTextBox'
                editorOptions={{
                  value: value,
                  maxLength: 17,
                  placeholder: 'Indicar el número de bastidor sin I, O, Q, Ñ',
                  mode: 'search',
                  showClearButton: true,
                  onValueChanged: (event: any) => {
                    if (
                      event.event &&
                      event.value !== undefined &&
                      event.value !== null
                    ) {
                      setValue(event.value.toUpperCase())
                    }
                  },
                  onKeyDown: (event: any) => {
                    if (event.event.keyCode === 37) {
                      let inicio = event.event.currentTarget.selectionStart - 1

                      event.event.currentTarget.focus()
                      event.event.currentTarget.setSelectionRange(
                        inicio,
                        inicio,
                        'backward',
                      )
                    } else if (event.event.keyCode === 39) {
                      let inicio = event.event.currentTarget.selectionStart + 1
                      event.event.currentTarget.focus()
                      event.event.currentTarget.setSelectionRange(
                        inicio,
                        inicio,
                        'forward',
                      )
                    }
                  },
                }}
              ></SimpleItem>
              <SimpleItem render={renderButtons} cssClass='alignBottom' />
              {reflistarcoches.current && (
                <SimpleItem
                  cssClass={'separacionitem'}
                  label={{ text: 'Vehículo' }}
                >
                  <SelectBox
                    dataSource={tiposvehiculosantiguos}
                    displayExpr='Nombre'
                    valueExpr='Id'
                    searchEnabled={true}
                    searchMode={'contains'}
                    searchExpr={'Nombre'}
                    minSearchLength={0}
                    onValueChanged={(e) => {
                      if (e.value !== undefined) {
                        refdatosvehiantiguos.current.forEach((coche: any) => {
                          if (coche.bastidor === e.value) {
                            cochedato = coche
                          }
                        })

                        showPopup2.current = true
                        tempPaisCoche.current = darPaisBastidor(e.value)
                        setCocheElegido(cochedato)
                        reflistarcoches.current = false
                      }
                    }}
                  ></SelectBox>
                </SimpleItem>
              )}
            </Form>
          </form>
        </Item>
      </Box>

      <div style={{ textAlign: 'center' }}>
        <div>
          <form
            style={{
              width: '46%',
            }}
          >
            {showPopup2.current && (
              <Popup
                visible={true}
                hideOnOutsideClick={false}
                showCloseButton={false}
                height='90%'
                width='90%'
                title='Datos del vehículo'
              >
                <ToolbarItem
                  widget='dxButton'
                  location='after'
                  options={{
                    icon: 'close',
                    onClick: () => {
                      showPopup2.current = false
                      setCocheElegido(new MicrodatosVehiculosAntiguosModel())
                    },
                  }}
                />
                <Form>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Bastidor' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.bastidor
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.bastidor
                            : null,
                      }}
                    ></SimpleItem>

                    <SimpleItem
                      label={{ text: 'Marca' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.marcaItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.marcaItv
                            : null,
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Modelo' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.modeloItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.modeloItv
                            : null,
                      }}
                    ></SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Cilindrada' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.cilindradaItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? numMiles(refcocheelegido.cilindradaItv)
                            : null,
                      }}
                    ></SimpleItem>

                    <SimpleItem
                      label={{ text: 'Potencia' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.kwItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? numDecimals(
                                Math.round(refcocheelegido.kwItv * 1.36),
                              ) +
                              'CV (' +
                              numDecimals(refcocheelegido.kwItv) +
                              ' kW)'
                            : null,
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Tara' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.tara
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.tara
                            : null,
                      }}
                    ></SimpleItem>
                  </GroupItem>

                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Peso máximo' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.pesoMaximo
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? numMiles(refcocheelegido.pesoMaximo)
                            : null,
                      }}
                    ></SimpleItem>

                    <SimpleItem
                      label={{ text: 'Plazas' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.numPlazas
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.numPlazas
                            : null,
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Codigo itv' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.codigoItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.codigoItv
                            : null,
                      }}
                    ></SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Emisiones' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.co2Itv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? numMiles(refcocheelegido.co2Itv)
                            : null,
                      }}
                    ></SimpleItem>

                    <SimpleItem
                      label={{ text: 'Variante' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.varianteItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.varianteItv
                            : null,
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Versión' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.versionItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.versionItv
                            : null,
                      }}
                    ></SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={3}>
                    <SimpleItem
                      label={{ text: 'Fabricante' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.fabricanteItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.fabricanteItv
                            : null,
                      }}
                    ></SimpleItem>

                    <SimpleItem
                      label={{ text: 'Nivel emisones' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.nivelEmisionesEuroItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.nivelEmisionesEuroItv
                            : null,
                      }}
                    ></SimpleItem>
                    <SimpleItem
                      label={{ text: 'Contraseña homologación' }}
                      editorType={'dxTextBox'}
                      editorOptions={{
                        mode: 'text',
                        readOnly: true,
                        hint:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.contrasenaHomologacionItv
                            : null,
                        value:
                          refcocheelegido !== undefined &&
                          refcocheelegido !== null
                            ? refcocheelegido.contrasenaHomologacionItv
                            : null,
                      }}
                    ></SimpleItem>
                  </GroupItem>

                  {tempPaisCoche.current !== '' && (
                    <GroupItem colCount={1}>
                      <SimpleItem
                        label={{ text: 'Fabricación' }}
                        render={verPais}
                      ></SimpleItem>
                    </GroupItem>
                  )}
                </Form>
              </Popup>
            )}
          </form>
        </div>
      </div>
      <Box direction='col' height={105}>
        <Item ratio={0}></Item>
      </Box>
      <br></br>
      <Box
        direction='row'
        width='auto'
        align='center'
        crossAlign='center'
        height={120}
      >
        <Item ratio={0} baseSize={'50%'}>
          <h3 style={{ textAlign: 'center' }}>Últimos bastidores buscados</h3>
          <DataGrid
            showBorders={true}
            dataSource={datosGrid}
            showRowLines
            allowColumnReordering
            allowColumnResizing
            cacheEnabled
            columnAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            columnHidingEnabled={!checkScreenSize()}
          >
            <Column
              caption={'Fabricación'}
              alignment='center'
              dataField={'Fabricacion'}
              cellRender={renderImage}
            />
            <Column
              caption={'Bastidor'}
              alignment='center'
              dataField={'Bastidor'}
              cellRender={renderBastidor}
            />
          </DataGrid>
        </Item>
      </Box>
      <Box direction='col' height={283}>
        <Item ratio={0}></Item>
      </Box>
    </div>
  )
}

export default MicrodatosBastidores
