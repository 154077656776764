import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { BienModel, BienesCampoDatoModel } from '../../models/escritura/bienesCampoDatoModel';
import { checkStatusCode, customLog, logType, methodsEndPointType, NotifyType, showToast } from 'src/utils/sharedUitls';
import { AyuntamientoCampoDatoModel } from '../../models/escritura/ayuntamientoCampoDatoModel';
import { BienCampoDatoModel } from '../../models/escritura/bienCampoDatoModel';
import { CatastroCampoDatoModel } from '../../models/escritura/catastroCampoDatoModel';
import { DefectoCampoDatoModel } from '../../models/escritura/defectoCampoDatoModel';
import { HaciendaCampoDatoModel } from '../../models/escritura/haciendaCampoDatoModel';
import { NotariaCampoDatoModel } from '../../models/escritura/notariaCampoDatoModel';
import { RegistroCampoDatoModel } from '../../models/escritura/registroCampoDatoModel';
import { deleteFakeDatesType } from 'src/utils/dateUtils';

const serviceName = 'BienesService';

export const getBienesByExpediente = async (
  id: number,
): Promise<BienesCampoDatoModel> => {
  let bienes = new BienesCampoDatoModel();
  const url = `Escritura/GetEscriturasDetalle/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    const response = await axiosInstance.get(url);

    checkStatusCode(response.status);

    //if (response.data.Datos && Array.isArray(response.data.Datos) && response.data.Datos.length > 0) {
      // Si Datos tiene datos, realiza la operación con deserializeArray
     // console.log("bienes.Datos: if", bienes.Datos);
      
      bienes.Datos = jsonConvert.deserializeArray(response.data.Datos.map(deleteFakeDatesType), BienModel);
   // } else {
   //   console.log("Datos en else:", response.data.Datos);
    
      
   // }

  } catch (error: any) {
    customLog(
      null,
      logType.ERROR,
      serviceName,
      getBienesByExpediente.name,
      methodsEndPointType.GET,
    );
    checkStatusCode((error as { request?: any })?.request?.status);
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
    }
  }

  return bienes;
};

// Tab de bien
export const getBienByExpediente = async (
  id: number,
  user: UserModel,
): Promise<BienCampoDatoModel> => {
  let bien = new BienCampoDatoModel();
  const url = `escritura/GetEscriturasDetalleExt/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status);
        bien = jsonConvert.deserializeObject(response.data, BienCampoDatoModel);
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getBienByExpediente.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      })
  } catch (e) {};
  return bien;
};

// Tab de notaria
export const getNotariaByExpediente = async (
  id: number,
  user: UserModel,
): Promise<NotariaCampoDatoModel> => {
  let notaria = new NotariaCampoDatoModel();
  const url = `notaria/getbyescritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
        notaria = jsonConvert.deserializeObject(
          response.data,
          NotariaCampoDatoModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getNotariaByExpediente.name,
          methodsEndPointType.GET,
        );
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      })
  } catch (e) {};
  return notaria;
};

// Tab de hacienda
export const getHaciendaByExpediente = async (
  id: number,
  user: UserModel,
): Promise<HaciendaCampoDatoModel> => {
  let hacienda = new HaciendaCampoDatoModel();
  const url = `hacienda/getbyescritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance
      .get(url)
      .then((response: any) => {
        checkStatusCode(response.status);
        hacienda = jsonConvert.deserializeObject(
          response.data,
          HaciendaCampoDatoModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getHaciendaByExpediente.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      });
  } catch (e) {};
  return hacienda;
};

// Tab de registro (form)
export const getRegistroByExpediente = async (
  id: number,
  user: UserModel,
): Promise<RegistroCampoDatoModel> => {
  let registro = new RegistroCampoDatoModel();
  const url = `Historicoasientoregistro/getbyescritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance
      .get(url)
      .then((response: any) => {
        checkStatusCode(response.status);

        registro = jsonConvert.deserializeObject(
          response.data,
          RegistroCampoDatoModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getRegistroByExpediente.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      })
  } catch (e) {};
  return registro;
};

// Tab de registro (grid)
export const getRegistrosByExpediente = async (
  id: number,
  user: UserModel,
): Promise<RegistroCampoDatoModel> => {
  let registro = new RegistroCampoDatoModel();
  const url = `Historicoasientoregistro/GetHistoricoAsientoRegistrosByEscritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    await axiosInstance.get(url).then((response: any) => {
        checkStatusCode(response.status);
        registro = jsonConvert.deserializeObject(
          {
            ...response.data,
            Datos: response.data.Datos.map(deleteFakeDatesType),
          },
          RegistroCampoDatoModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getRegistrosByExpediente.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      });
  } catch (e) {};
  return registro;
};

// Tab de defecto (form)
export const getDefectoByExpediente = async (
  id: number,
  user: UserModel,
): Promise<DefectoCampoDatoModel> => {
  let defecto = new DefectoCampoDatoModel()
  const url = `historicodefecto/GetByEscritura/${id}`

  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status);
      defecto = jsonConvert.deserializeObject(
        response.data,
        DefectoCampoDatoModel,
      );
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getDefectoByExpediente.name,
        methodsEndPointType.GET,
      );
      checkStatusCode(error.request.status);
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
    })
  } catch (e) {};
  return defecto;
};

// Tab de defecto (grid)
export const getDefectosByExpediente = async (
  id: number,
  user: UserModel,
): Promise<DefectoCampoDatoModel> => {
  let defecto = new DefectoCampoDatoModel();
  const url = `historicodefecto/GetHistoricoDefectosByEscritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance.get(url).then((response: any) => {
        checkStatusCode(response.status)
        defecto = jsonConvert.deserializeObject(
          {
            ...response.data,
            Datos: response.data.Datos.map(deleteFakeDatesType),
          },
          DefectoCampoDatoModel,
        );
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getDefectosByExpediente.name,
          methodsEndPointType.GET,
        );
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      });
  } catch (e) {};
  return defecto;
};

// Tab de ayto/catastro (ayto)
export const getAyuntamientoByExpediente = async (
  id: number,
  user: UserModel,
): Promise<AyuntamientoCampoDatoModel> => {
  let ayuntamiento = new AyuntamientoCampoDatoModel();
  const url = `ayuntamiento/GetByEscritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance.get(url).then((response: any) => {
        checkStatusCode(response.status);
        ayuntamiento = jsonConvert.deserializeObject(
          response.data,
          AyuntamientoCampoDatoModel,
        );
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getAyuntamientoByExpediente.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      })
  } catch (e) {};
  return ayuntamiento;
};

// Tab de ayto/catastro (catastro)
export const getCatastroByExpediente = async (
  id: number,
  user: UserModel,
): Promise<CatastroCampoDatoModel> => {
  let catastro = new CatastroCampoDatoModel();
  const url = `Catastro/GetByEscritura/${id}`;

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    await axiosInstance.get(url).then((response: any) => {
        checkStatusCode(response.status)
        catastro = jsonConvert.deserializeObject(
          response.data,
          CatastroCampoDatoModel,
        );
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getDefectoByExpediente.name,
          methodsEndPointType.GET,
        );
        checkStatusCode(error.request.status);
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000);
      });
  } catch (e) {};
  return catastro;
};
