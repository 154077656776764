import { Button, DataGrid, Popup, TextBox } from 'devextreme-react'
import React, { useState } from 'react'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import { Controller, useForm } from 'react-hook-form'
import { ToolbarItem } from 'devextreme-react/popup'
import { LineaInforme } from '../../models/TasaModel'
import {
  NIFValidator,
  validateMatricula,
  validateNIF,
} from '../../../../utils/validators'
import {
  deleteRemesaLinea,
  getInformeFileLink,
  putRemesas,
} from '../../../tasas/service/RemesasService'
import {
  checkScreenSize,
  NotifyType,
  showToast,
} from '../../../../utils/sharedUitls'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import ConsultaVehiculo from './consultaVehiculo/ConsultaVehiculo'
import { Firmar } from '../../../companyAdminPages/requestPage/types/firmarType'
import {
  getByBastidor,
  getExpediente,
} from '../../services/vehiculo/vehiculoService'
import { getCostes, getDetalleCoste } from '../../services/costeService'
import { getTareasPendientesByExpediente } from '../../services/tareaService'
import {
  addCostes,
  addDetalleCoste,
  addDetalleExpediente,
  addDetalleVehiculo,
  addTareasPendientes,
} from '../../redux/vehiculo/vehiculoActions'
import { DetalleVehiculoCampoDatoModel } from '../../models/vehiculo/detalleVehiculoModel'

export const RemesasDetail = (props: any) => {
  const { Lineas } = props.data.data
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user?.user)
  const [editPopupVisible, setEditPopupVisible] = useState(false)
  const [deletePopupVisible, setDeletePopupVisible] = useState(false)
  const [showExpedientePopup, setShowExpedientePopup] = useState(false)
  const [editLineaData, setEditLineaData] = useState<LineaInforme>({
    DocumentoId: 0,
    ExpedienteId: 0,
    FechaAsignacion: '',
    Id: 0,
    IdentificacionSolicitante: '',
    IdentificacionVehiculo: '',
    NombreSolicitante: '',
    RemesaInformesId: 0,
    Resultado: '',
    SerieNumeroExpediente: '',
    Tasa: '',
  })
  const [firmaDocumento, setFirmaDocumento] = useState({
    realizarfirma: false,
    idexpediente: '',
    idvehiculo: '',
  } as Firmar)
  const { handleSubmit, control, reset } = useForm()
  const solicitarInfome = (props: any) => {
    const actualDate = new Date()
    const date = new Date(props.data.FechaAsignacionTasa)
    const diferencia = actualDate.getTime() - date.getTime()
    const diferenciaEnHoras = diferencia / (1000 * 60 * 60)
    if (diferenciaEnHoras < 48) {
      return (
        <Button type='default' onClick={(e) => handelEditLinea(props)}>
          Solicitar informe
        </Button>
      )
    } else {
      return <div>No disponible</div>
    }
  }
  const handleDeleteLinea = async (props: any) => {
    if (props.data.Resultado === 'Error') {
      await deleteRemesaLinea(props.Linea)
    } else {
      showToast(
        `No puedes eliminar una remesea que esta con el estado ${props.data.Resultado}`,
        NotifyType.error,
        2000,
      )
    }
  }

  const handelEditLinea = (props: any) => {
    setEditLineaData(props.data)
    setEditPopupVisible(true)
    reset(props.data)
  }

  const handleEditSubmit = async (data: any) => {
    const remesaId = props.data.data.Id

    await putRemesas(remesaId, [
      {
        Id: editLineaData.Id,
        NombreSolicitante: data.NombreSolicitante,
        IdentificacionSolicitante: data.IdentificacionSolicitante,
        ExpedienteId: editLineaData.ExpedienteId,
        IdentificacionVehiculo: data.IdentificacionVehiculo,
        NumeroTasa: editLineaData.Tasa,
      },
    ])
    setEditPopupVisible(false)
  }
  const descargarInformeRenderer = (data: any) => {
    if (data.data.DocumentoId == null ) {
      return <div>No disponible</div>
    }
    return (
      <Button
        icon='pdffile'
        text='Descargar'
        hint='Descarga el documento a su equipo'
        onClick={(e: any) => {
          getInformeFileLink(data.data.DocumentoId, userState!.Token!)
        }}
      />
    )
  }

  const renderExpediente = (data: any) => {
    if (data.data.ExpedienteId == null ) return data.value
    return (
      <Button
        key={data.data.Id}
        type='default'
        onClick={() => {
          OpenExpediente(data.data)
          setShowExpedientePopup(true)
          setFirmaDocumento({
            realizarfirma: false,
            idexpediente: data.data.DocumentoId,
            idvehiculo: '',
          })
        }}
      >
        Ver expediente
      </Button>
    )
  }

  const OpenExpediente = async (data: any) => {
    let vehiculoGrid = new DetalleVehiculoCampoDatoModel()
    await Promise.all([
      getExpediente(data.ExpedienteId, userState!),
      getDetalleCoste(data.ExpedienteId, userState!),
      getByBastidor(userState!, data.IdentificacionVehiculo),
      getCostes(data.ExpedienteId, userState!),
      getTareasPendientesByExpediente(data.ExpedienteId, userState!),
    ]).then(
      ([
        expediente,
        detallecostes,
        detallevehiculo,
        costes,
        tareaspendientes,
      ]) => {
        vehiculoGrid.Datos = [
          {
            Bastidor: detallevehiculo.bastidor,
            FechaFabricacion: detallevehiculo.FechaFabricacion,
            FechaITV: undefined,
            FechaMatricula: detallevehiculo.fecMatricula,
            Id: 0,
            Marca: detallevehiculo.marcaItv,
            Matricula: detallevehiculo.matricula,
            Modelo: detallevehiculo.modeloItv,
            Observaciones: detallevehiculo.notas,
          },
        ]
        vehiculoGrid.Campos = [
          {
            Nombre: 'Bastidor',
            Tipo: 'string',
            Texto: 'Bastidor',
            Format: '',
            Size: 0,
            Visible: true,
            Orden: 0,
          },
          {
            Nombre: 'Marca',
            Tipo: 'string',
            Texto: 'Marca',
            Format: '',
            Size: 0,
            Visible: true,
            Orden: 5,
          },
          {
            Nombre: 'Matricula',
            Tipo: 'string',
            Texto: 'Matrícula',
            Format: '',
            Size: 0,
            Visible: true,
            Orden: 6,
          },
          {
            Nombre: 'Modelo',
            Tipo: 'string',
            Texto: 'Modelo',
            Format: '',
            Size: 0,
            Visible: true,
            Orden: 7,
          },
          {
            Nombre: 'Observaciones',
            Tipo: 'string',
            Texto: 'Observaciones',
            Format: '',
            Size: 0,
            Visible: true,
            Orden: 8,
          },
        ]
        dispatch(addDetalleExpediente(expediente))
        dispatch(addDetalleCoste(detallecostes))
        dispatch(addDetalleVehiculo(vehiculoGrid))
        dispatch(addCostes(costes))
        dispatch(addTareasPendientes(tareaspendientes))
      },
    )
  }

  return (
    <React.Fragment>
      <DataGrid
        id='gridRemesasDetails'
        dataSource={Lineas}
        showBorders={true}
        columnAutoWidth={true}
        filterPanel={{ visible: true }}
        searchPanel={{
          visible: true,
          width: checkScreenSize() ? 240 : 120,
        }}
        repaintChangesOnly={true}
        filterRow={{ visible: true }}
      >
        <StateStoring
          enabled
          type='localStorage'
          storageKey='gridRemesasDetails'
        />
        <Column caption='Descargar' cellRender={descargarInformeRenderer} />
        <Column dataField='Resultado' caption='Estado Informe' />
        <Column dataField='Tasa' caption='Tasa' name='tasa' />
        <Column
          dataField='FechaAsignacionTasa'
          caption='Fecha Asignación Tasa'
          cellRender={(params) => {
            return new Date(params.value).toLocaleDateString()
          }}
        />
        <Column
          dataField='IdentificacionVehiculo'
          caption='Identificación Vehiculo'
        />
        <Column
          dataField='IdentificacionSolicitante'
          caption='Identificación Solicitante'
        />
        <Column dataField='NombreSolicitante' caption='Nombre Solicitante' />
        <Column caption={'Serie'} cellRender={
          (cellData: any) => {
            return cellData.data.SerieNumeroExpediente.split('/')[0]          }
        } />
        <Column caption='Expediente' cellRender={
          (cellData: any) => {
            return cellData.data.SerieNumeroExpediente.split('/')[1]
          }
        } />
        <Column
          caption='Expediente'
          cellRender={(cellData: any) => renderExpediente(cellData)}
        />
        <Column
          dataField='solicitar'
          caption='Solicitar Informe'
          cellRender={solicitarInfome}
        />
        <Column
          dataField='delete'
          caption='Editar'
          cellRender={(cellData: any) => {
            if (cellData.data.Resultado === 'Procesando') {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button
                    icon='trash'
                    hint='Eliminar linea'
                    onClick={() => {
                      setEditLineaData(cellData)
                      setDeletePopupVisible(true)
                    }}
                    style={{ margin: 'auto', display: 'flex' }}
                  />
                  <Button
                    icon='edit'
                    hint='Editar linea'
                    onClick={(e) => handelEditLinea(cellData)}
                    style={{ margin: 'auto', display: 'flex' }}
                  />
                </div>
              )
            }
          }}
        />
      </DataGrid>
      <Popup
        visible={editPopupVisible}
        title='Editar Línea'
        height={425}
        width={500}
        showCloseButton={false}
      >
        <ToolbarItem
          widget='dxButton'
          toolbar='top'
          location='after'
          options={{
            icon: 'close',
            onClick: () => {
              setEditPopupVisible(false)
            },
          }}
        />
        <form onSubmit={handleSubmit(handleEditSubmit)}>
          <Controller
            name='Tasa'
            defaultValue={editLineaData.Tasa}
            control={control}
            render={({ field, fieldState }) => {
              const valueChanged = (e: any) => {
                field.onChange(e)
              }
              return (
                <div style={{ marginTop: '15px' }}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Tasa
                  </label>
                  <TextBox
                    value={field.value}
                    onValueChange={valueChanged}
                    mask={'000000000000'}
                    maskInvalidMessage={'Maximo 12 digitos'}
                  />
                </div>
              )
            }}
          />
          <Controller
            name='IdentificacionVehiculo'
            control={control}
            defaultValue={editLineaData.IdentificacionVehiculo}
            render={({ field }) => {
              const valueChanged = (e: any) => {
                field.onChange(e)
              }
              return (
                <div style={{ marginTop: '15px' }}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Identificación Vehículo
                  </label>
                  <TextBox
                    value={field.value}
                    onValueChange={valueChanged}
                    isValid={validateMatricula(field.value)}
                  />
                </div>
              )
            }}
          />
          <Controller
            name='NombreSolicitante'
            control={control}
            defaultValue={editLineaData.NombreSolicitante}
            render={({ field }) => {
              const valueChanged = (e: any) => {
                field.onChange(e)
              }
              return (
                <div style={{ marginTop: '15px' }}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Nombre Solicitante
                  </label>
                  <TextBox value={field.value} onValueChange={valueChanged} />
                </div>
              )
            }}
          />
          <Controller
            name='IdentificacionSolicitante'
            control={control}
            defaultValue={editLineaData.IdentificacionSolicitante}
            render={({ field }) => {
              const valueChanged = (e: any) => {
                field.onChange(e)
              }
              return (
                <div style={{ marginTop: '15px' }}>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                    }}
                  >
                    Identificación Solicitante
                  </label>
                  <TextBox
                    value={field.value}
                    onValueChange={valueChanged}
                    isValid={validateNIF(field.value)}
                    maskRules={NIFValidator.maskRules}
                    maskInvalidMessage={NIFValidator.maskInvalidMessage}
                  />
                </div>
              )
            }}
          />

          <Button
            type='default'
            style={{ marginTop: '15px', float: 'right' }}
            text='Editar y solicitar informe'
            useSubmitBehavior
          />
        </form>
      </Popup>
      <Popup
        visible={deletePopupVisible}
        title='Eliminar Línea'
        height={300}
        width={400}
        showCloseButton={false}
      >
        <ToolbarItem
          widget='dxButton'
          toolbar='top'
          location='after'
          options={{
            icon: 'close',
            onClick: () => {
              setDeletePopupVisible(false)
            },
          }}
        />
        <p style={{ fontWeight: 'bold' }}>
          ¿Estas seguro de eliminar remesa {editLineaData.Tasa}
        </p>
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: '10px',
          }}
        >
          <Button
            type='default'
            text='Eliminar linea remesa'
            onClick={async () => {
              await handleDeleteLinea(editLineaData)
            }}
          />
          <Button
            type='default'
            text='Cancelar'
            onClick={() => setDeletePopupVisible(false)}
          />
        </div>
      </Popup>
      {firmaDocumento.idexpediente !== '' && (
        <ConsultaVehiculo
          datos={{
            id: firmaDocumento.idexpediente,
            idvehiculo: firmaDocumento.idvehiculo,
          }}
          popupview={showExpedientePopup}
          setpopup={setShowExpedientePopup}
          firma={firmaDocumento}
          setfirma={setFirmaDocumento}
        />
      )}
    </React.Fragment>
  )
}
