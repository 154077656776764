import React, { useEffect, useRef, useState } from 'react'
import { Button, DataGrid, Popup } from 'devextreme-react'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import { checkScreenSize, NotifyType, showToast } from 'src/utils/sharedUitls'
import { ImportarTasas } from './ImportarTasas'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  deleteTasas,
  getAllTasas,
  patchTasas,
} from '../service/AlmacenDeTasasService'
import { getAllRemesas } from '../service/RemesasService'
import { addShowLoader } from 'src/redux/actions/configActions'
import { useDispatch } from 'react-redux'
import { useScreenSize } from 'src/utils/media-query'

const AlmacenDeTasas = () => {
  const [isPopupVisible, setPopupVisible] = useState(false)
  const queryClient = useQueryClient()
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const dispatch = useDispatch()
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [isConfirmReleasePopupVisible, setIsConfirmReleasePopupVisible] =
    useState(false)
  const { isLarge } = useScreenSize()
  const selectedRowsRef = useRef<number[]>([])
  const [joinedData, setJoinedData] = useState([])

  const { data: tasas } = useQuery({
    queryKey: ['tasas'],
    queryFn: () => getAllTasas(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const { data: RemesasInformes } = useQuery({
    queryKey: ['RemesasInformes'],
    queryFn: () => getAllRemesas(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const liberarTasasMutation = useMutation(
    async function ({ idTasa }: { idTasa: number }) {
      await patchTasas(idTasa)
    },
    {
      onSuccess: () => {
        dispatch(addShowLoader(false))
      },
    },
  )

  const deleteMutation = useMutation(deleteTasas, {
    onSuccess: () => {
      queryClient.invalidateQueries('tasas')
    },
  })

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    const remesasLinea = RemesasInformes?.flatMap(
      (remesa: any) => remesa.Lineas,
    )
    let joined = tasas
      ? tasas.map((tasa: any) => {
          const { Id, ...restTasa } = tasa
          const remesa = remesasLinea
            ? remesasLinea.find((remesa: any) => remesa.Tasa === tasa.Tasa && tasa.Estado !== 0)
            : undefined
          if (remesa) {
            const { Id: IdLinea, ...restRemesa } = remesa
            return { IdTasa: Id, ...restTasa, IdLinea, ...restRemesa }
          } else {
            return { IdTasa: Id, ...restTasa }
          }
        })
      : []
    setJoinedData(joined)
  }, [tasas, RemesasInformes])

  const handleImportarTasasClick = () => {
    setPopupVisible(true)
  }

  const handlePopupHiding = () => {
    setPopupVisible(false)
  }

  const handleDeleteClick = (idTasa: any) => {
    setSelectedId(idTasa)
    setIsConfirmPopupVisible(true)
  }

  const handleConfirmDelete = () => {
    if (selectedId) {
      deleteMutation.mutate(selectedId)
      setSelectedId(null)
    } else if (selectedRowsRef.current.length > 0) {
      selectedRows.forEach((row: any) => {
        deleteMutation.mutate(row.IdTasa)
      })
    }
    setIsConfirmPopupVisible(false)
  }

  const handleSelectionChanged = ({
    selectedRowKeys,
  }: {
    selectedRowKeys: number[]
  }) => {
    selectedRowsRef.current = selectedRowKeys
  }

  const handleDeleteSelectedClick = (idTasa: any) => {
    dispatch(addShowLoader(true))
    if (selectedRowsRef.current.length > 0) {
      setSelectedRows(selectedRowsRef.current)
      setIsConfirmPopupVisible(true)
    } else {
      showToast('No ha seleccionado las filas a eliminar', NotifyType.error)
    }
    dispatch(addShowLoader(false))
  }

  const getEstadoString = (estado: any) => {
    switch (estado) {
      case 0:
        return 'Libre'
      case 1:
        return 'En proceso'
      case 2:
        return 'Usada'
      case 99:
        return 'Error'
      default:
        return 'Unknown'
    }
  }

  const handleReleaseClick = () => {
    if (selectedRowsRef.current.length > 0) {
      setSelectedRows(selectedRowsRef.current)
      setIsConfirmReleasePopupVisible(true)
    } else {
      showToast('No ha seleccionado las filas a liberar', NotifyType.error)
    }
  }

  const handleConfirmRelease = async () => {
    dispatch(addShowLoader(true))
    const mutationPromises = selectedRowsRef.current.map((row: any) => {
      if (row.IdTasa !== undefined) {
        return liberarTasasMutation.mutateAsync({ idTasa: row.IdTasa })
      } else {
        console.error('Row id is undefined:', row)
        return Promise.resolve()
      }
    })
    setIsConfirmReleasePopupVisible(false)
    try {
      await Promise.all(mutationPromises)
    } catch (error) {
      console.error('Error al liberar la tasa:', error)
    }
    queryClient.invalidateQueries('tasas')
    queryClient.invalidateQueries('RemesasInformes')
    dispatch(addShowLoader(false))
  }
  return (
    <div>
      <h2>Almacen de tasas</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: isLarge ? 'row' : 'column',
          justifyContent: 'center',
          gap: isLarge ? '5vh' : '2vh',
          marginBottom: '5vh',
        }}
      >
        <Button
          text='Almacen de tasas'
          stylingMode='contained'
          type='normal'
          style={{ border: '1px solid rgb(212,212,212)' }}
        />
        <Button
          text='Importar tasas'
          stylingMode='contained'
          type='default'
          onClick={handleImportarTasasClick}
        />
        <Button
          text='Liberar tasas'
          stylingMode='contained'
          type='default'
          onClick={handleReleaseClick}
        />
        <Button
          text='Borrar seleccion'
          stylingMode='contained'
          type='default'
          onClick={handleDeleteSelectedClick}
        />
      </div>
      <DataGrid
        id='gridAlmacenDeTasas'
        dataSource={joinedData}
        rowAlternationEnabled
        showBorders
        showRowLines
        showColumnLines
        loadPanel={{ enabled: true }}
        searchPanel={{
          visible: true,
          width: checkScreenSize() ? 240 : 120,
        }}
        style={{ margin: '2vh' }}
        columnAutoWidth={false}
        onSelectionChanged={handleSelectionChanged}
        selection={{ mode: 'multiple' }}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        paging={{ pageSize: 12 }}
        repaintChangesOnly={true}
        headerFilter={{ visible: true, allowSearch: true }}
      >
        <StateStoring
          enabled
          type='localStorage'
          storageKey='gridAlmacenDeTasas'
        />
        <Column
          dataField='delete'
          caption=''
          width={40}
          cellRender={(cellData) => (
            <div
              style={{
                display: 'flex',
                paddingRight: '5vh',
              }}
            >
              <Button
                icon='trash'
                onClick={() => handleDeleteClick(cellData.data.IdTasa)}
              />
            </div>
          )}
          dataType={'string'}
        />
        <Column dataField='Select' caption='' type='selection' width={40} />
        <Column
          dataField='Tasa'
          caption='Codigo de Tasa'
          width={'auto'}
          dataType={'string'}
        />
        <Column
          dataField='FechaAsignacion'
          caption='Fecha de asignacion'
          width={'auto'}
          cellRender={(cellData) => {
            if (cellData.value == null ) {
              return ''
            }
            return formatDate(cellData.value)
          }}
        />
        <Column
          dataField='Estado'
          caption='Estado de Tasa'
          width={'auto'}
          cellRender={(cellData) => (
            <div style={{ textAlign: 'center' }}>
              {getEstadoString(cellData.data.Estado)}
            </div>
          )}
          headerFilter={{
            dataSource: [
              {
                text: 'Libre',
                value: 0,
              },
              {
                text: 'En proceso',
                value: 1,
              },
              {
                text: 'Usada',
                value: 2,
              },
              {
                text: 'Error',
                value: 99,
              },
            ],
          }}
        />
        <Column
          dataField='Resultado'
          caption='Estado de Informe'
          width={'auto'}
        />
        <Column dataField='Tramite' caption='Tramite' width={'auto'} />
        <Column dataField='DocumentoId' caption='Serie' width={'auto'} />
        <Column dataField='ExpedienteId' caption='Expediente' width={'auto'} />
        <Column dataField='IdLinea' caption='Numero de Linea' width={'auto'} />
        <Column
          dataField='CodigoAutoliquidacion'
          caption='Numero autoliquidacion'
          width={'auto'}
        />
        <Column
          dataField='FechaCompra'
          caption='Fecha compra'
          width={'auto'}
          cellRender={(cellData) => formatDate(cellData.value)}
        />
        <Column
          dataField='GrupoPrincipal'
          caption='Grupo Principal'
          width={'auto'}
        />
        <Column
          dataField='TipoPrincipal'
          caption='Tipo Principal'
          width={'auto'}
        />
        <Column dataField='Precio' caption='Precio Tasa' width={'auto'} />
        <Column
          dataField='IdentificacionSolicitante'
          caption='Nif Cliente'
          width={'auto'}
        />
        <Column
          dataField='IdentificacionVehiculo'
          caption='Identificacion vehículo'
          width={'auto'}
        />
        <Column
          dataField='NombreSolicitante'
          caption='Solicitante'
          width={'auto'}
        />
      </DataGrid>
      <Popup
        visible={isPopupVisible}
        showCloseButton={true}
        onHiding={handlePopupHiding}
        dragEnabled={false}
        showTitle={true}
        title='Importar Tasas'
        width={'700'}
        height={'800'}
      >
        <ImportarTasas closePopup={handlePopupHiding} />
      </Popup>
      <Popup
        visible={isConfirmPopupVisible}
        onHiding={() => setIsConfirmPopupVisible(false)}
        dragEnabled={false}
        showTitle={true}
        title='Confirm Delete'
        width={300}
        height={200}
      >
        <p>¿Está seguro que desea eliminar esta selección?</p>
        <Button type='default' text='Yes' onClick={handleConfirmDelete} />
        <Button
          type='normal'
          text='No'
          style={{ border: '1px solid rgb(212,212,212)', marginLeft: '2vh' }}
          onClick={() => setIsConfirmPopupVisible(false)}
        />
      </Popup>
      <Popup
        visible={isConfirmReleasePopupVisible}
        onHiding={() => setIsConfirmReleasePopupVisible(false)}
        dragEnabled={false}
        showTitle={true}
        title='Confirmar Liberación'
        width={300}
        height={200}
      >
        <p>¿Está seguro que desea liberar esta seleccion de tasas?</p>
        <Button type='default' text='Sí' onClick={handleConfirmRelease} />
        <Button
          type='normal'
          text='No'
          style={{ border: '1px solid rgb(212,212,212)', marginLeft: '2vh' }}
          onClick={() => setIsConfirmReleasePopupVisible(false)}
        />
      </Popup>
    </div>
  )
}

export default AlmacenDeTasas
