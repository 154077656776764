import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'src/redux/combineReducers'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import {
  isRolAllowed,
  isSuperAdmin,
  ClaimsModule,
} from 'src/utils/allowAuthUtil'
import NotFound from 'src/components/notFound/notFound'
import { EscriturasList } from './sections/EscriturasList'

export default function EscrituraPage() {
  const { user, isLoggedIn } = useSelector((state: RootState) => state.user!)
  const navigate = useNavigate()

  return (
    <>
      {!isLoggedIn && navigate('/Login')}
      {IsModuloAllowed(user!, Modules.EXPEDIENTES_ESCRITURAS) &&
      isRolAllowed(user!, Modules.EXPEDIENTES_ESCRITURAS, [
        ClaimsModule.VIEW,
      ]) &&
      !isSuperAdmin(user!) ? (
        <EscriturasList />
      ) : (
        <NotFound data={{ type: 1 }} />
      )}
    </>
  )
}
