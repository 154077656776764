import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DocumentTypeFormModel')
export class DocumentTypeFormModel {
  @JsonProperty('id', Number, 0)
  id: number = 0
  @JsonProperty('name', String)
  name: string = ''
  @JsonProperty('viafirmaPlantillaId', String)
  viafirmaPlantillaId: string = ''
  @JsonProperty('requiresDocumentUpload', Boolean)
  requiresDocumentUpload: boolean = false
  @JsonProperty('allowsDocumentUpload', Boolean)
  allowsDocumentUpload: boolean = false
  @JsonProperty('formRequired', Boolean)
  formRequired: boolean = false
  @JsonProperty('formDisabledForUpdates', Boolean)
  formDisabledForUpdates: boolean = false
  @JsonProperty('notificationText', String)
  notificationText: string = ''
  @JsonProperty('emailSubjectText', String)
  emailSubjectText: string = ''
  @JsonProperty('emailBodyText', String)
  emailBodyText: string = ''
  @JsonProperty('signPageText', String)
  signPageText: string = ''
  @JsonProperty('watermarkText', String)
  watermarkText: string = ''
  @JsonProperty('additionalEmails', String)
  additionalEmails: string = ''
}
