import {
  ADD_LOGIN_SUCCESS,
  ADD_LOGIN_FAIL,
  ADD_LOGIN_ISALREADYLOGGED,
  UserActionsTypes,
  ADD_LOGIN_RESETPASSWORD,
  UPDATE_THEME,
} from './authType'

import { ForgotPasswordModel } from '../../../models/loginPage/forgotPasswordModel'
import { ConfigModel } from '../../../models/usuario/configModel'
import { UserModel } from 'src/models/usuario/userModel'

export const addLoggedUser = (
  user: UserModel | undefined,
): UserActionsTypes => ({
  type: ADD_LOGIN_SUCCESS,
  payload: user,
})

export const addUnLoggedUser = (): UserActionsTypes => ({
  type: ADD_LOGIN_FAIL,
})

export const addIsAlreadyLogged = (
  user: UserModel | undefined,
): UserActionsTypes => ({
  type: ADD_LOGIN_ISALREADYLOGGED,
  payload: user,
})

export const addRestablecerContraseña = (
  user: ForgotPasswordModel | undefined,
): UserActionsTypes => ({
  type: ADD_LOGIN_RESETPASSWORD,
  payload: user,
})

export const addUpdateTheme = (
  user: ConfigModel | undefined,
): UserActionsTypes => ({
  type: UPDATE_THEME,
  payload: user,
})
