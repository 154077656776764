import { Box, Button } from 'devextreme-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addPagina } from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { addSolicitudList } from '../../redux/solicitudActions'
import { Item } from 'devextreme-react/box'

interface Props {
  validos: Function
  validacion: string
}
export default function FooterFormSection({ validos, validacion }: Props) {
  const dispatch = useDispatch()
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  return (
    <Box direction='row' height={60} align='end' crossAlign='end'>
      <Item ratio={0} baseSize='auto'>
        <Button
          text={'Siguiente'}
          type='default'
          validationGroup={validacion}
          useSubmitBehavior={pagina !== 1}
          onClick={async (e: any) => {
            dispatch(addSolicitudList(solicitud))
            dispatch(addPagina(pagina! + 1))
          }}
        />
      </Item>
    </Box>
  )
}
