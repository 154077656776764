import {
  ADD_COMPANIES,
  ADD_COMPANY,
  CLEAR_COMPANY,
  ADD_COMPANY_MODULO,
  ADD_COMPANY_MODULO_SELECTED,
  CompanyActionsTypes,
  CompanyState,
  CLEAR_COMPANY_MODULO,
  ADD_PARENT_COMPANY,
  ADD_COMPANY_MODULO_PARENT,
  ADD_COMPANY_CODIGO_VIAFIRMA,
} from './companiesType'

const initialState: CompanyState = {
  company: undefined,
  companies: undefined,
  companyModulos: undefined,
  companyModulosSelected: undefined,
  parentCompany: undefined,
  parentCompanyModulos: undefined,
  codigoViaFirma: undefined,
}

export const companyReducer = (
  state = initialState,
  action: CompanyActionsTypes,
): CompanyState | undefined => {
  switch (action.type) {
    case ADD_COMPANIES: {
      return { ...state, companies: action.payload }
    }
    case ADD_COMPANY: {
      return { ...state, company: action.payload }
    }
    case ADD_PARENT_COMPANY: {
      return { ...state, parentCompany: action.payload }
    }
    case CLEAR_COMPANY: {
      return { ...state, company: action.payload }
    }
    case ADD_COMPANY_MODULO: {
      return { ...state, companyModulos: action.payload }
    }
    case ADD_COMPANY_MODULO_SELECTED: {
      return { ...state, companyModulosSelected: action.payload }
    }
    case CLEAR_COMPANY_MODULO: {
      return { ...state, companyModulos: action.payload }
    }
    case ADD_COMPANY_MODULO_PARENT: {
      return { ...state, parentCompanyModulos: action.payload }
    }
    case ADD_COMPANY_CODIGO_VIAFIRMA: {
      return { ...state, codigoViaFirma: action.payload }
    }

    default:
      return state
  }
}
