import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('RegistroModel')
class RegistroModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('FechaAsiento', DateConverter)
  FechaAsiento: Date = new Date()

  @JsonProperty('FechaEntradaRegistro', String)
  FechaEntradaRegistro: Date | String = ''

  @JsonProperty('FechaVencimiento', String)
  FechaVencimiento: Date | String = ''

  @JsonProperty('NombreRegistro', String)
  NombreRegistro: string = ''

  @JsonProperty('NumeroAsiento', String)
  NumeroAsiento: string = ''
}

@JsonObject('RegistroCampoDatoModel')
export class RegistroCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [RegistroModel])
  Datos: RegistroModel[] = []
}
