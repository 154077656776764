import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel';
import { SolicitudListModel } from '../models/solicitudListModel';

export interface SolicitudListState {
  solicitudList: SolicitudListModel | undefined;
  solicitudesList: SolicitudListCampoDatoModel | undefined;
};

export interface SolicitudesListState {
  solicitudesList: SolicitudListCampoDatoModel | undefined;
}

export const ADD_SOLICITUDES_LIST = 'ADD_SOLICITUDES_LIST';
export const ADD_SOLICITUD_LIST = 'ADD_SOLICITUD_LIST';
export const CLEAR_SOLICITUD_LIST = 'CLEAR_SOLICITUD';

interface AddSolicitudListRequest {
  type: typeof ADD_SOLICITUD_LIST;
  payload: SolicitudListModel | undefined;
};

interface AddSolicitudeslistRequest {
  type: typeof ADD_SOLICITUDES_LIST;
  payload: SolicitudListCampoDatoModel | undefined;
};

interface ClearSolicitudesListRequest {
  type: typeof CLEAR_SOLICITUD_LIST;
  payload: SolicitudListModel | undefined;
};

export type SolicitudListActionsTypes =
  | ClearSolicitudesListRequest
  | AddSolicitudeslistRequest
  | AddSolicitudListRequest;
