
interface patronWMI {

    continente: string,
    pais: Array<string>,
    nombrePais: string

}


const wmiInternacional: Array<patronWMI> = [

    {

        continente: '6',
        pais: ['A','W'],
        nombrePais: 'Australia'

    },
    
    {

        continente: '7',
        pais: ['A','Z','0','3'],
        nombrePais: 'Nueva Zelanda'

    },
    
    {
        continente: '8',
        pais: ['A','E','3','9'],
        nombrePais: 'Argentina'

    },
    
    {

        continente: '8',
        pais: ['F','J'],
        nombrePais: 'Chile'

    },
    
    {

        continente: '8',
        pais: ['L','R'],
        nombrePais: 'Ecuador'

    },
    
    {

        continente: '8',
        pais: ['S','W'],
        nombrePais: 'Peru'

    },
    
    {

        continente: '8',
        pais: ['X','Z','1','2'],
        nombrePais: 'Venezuela'

    },

    {

        continente: '9',
        pais: ['A','E','3','9'],
        nombrePais: 'Brasil'

    },
    {

        continente: '9',
        pais: ['F','J'],
        nombrePais: 'Colombia'

    },
    {

        continente: '9',
        pais: ['L','R'],
        nombrePais: 'Paraguay'

    },
    {

        continente: '9',
        pais: ['S','W'],
        nombrePais: 'Urugay'

    },
    {

        continente: 'A',
        pais: ['A','H'],
        nombrePais: 'Sudafrica'

    },
    {

        continente: 'K',
        pais: ['L','R'],
        nombrePais: 'Republica Corea'

    },
    {

        continente: 'L',
        pais: ['A','Z','0','9'],
        nombrePais: 'China'

    },
    {

        continente: 'M',
        pais: ['A','E'],
        nombrePais: 'India'

    },
    {

        continente: 'M',
        pais: ['F','K'],
        nombrePais: 'Indonesia'

    },
    {

        continente: 'M',
        pais: ['L','R'],
        nombrePais: 'Tailandia'

    },

    {

        continente: 'S',
        pais: ['A','M'],
        nombrePais: 'Reino Unido'

    },
    
    {

        continente: 'V',
        pais: ['F','R'],
        nombrePais: 'Francia'

    },

    {

        continente: 'V',
        pais: ['S','W'],
        nombrePais: 'Espana'

    },

    {

        continente: 'W',
        pais: ['A','Z','0','9'],
        nombrePais: 'Alemania'

    },
    {
        continente: 'X',
        pais: ['A','E'],
        nombrePais: 'Bulgaria'

    },
    {

        continente: 'X',
        pais: ['F','K'],
        nombrePais: 'Grecia'

    },
    {

        continente: 'X',
        pais: ['L','R'],
        nombrePais: 'Holanda'

    },
    {

        continente: 'X',
        pais: ['S','W','3','9'],
        nombrePais: 'Rusia'

    },
    {

        continente: 'Y',
        pais: ['A','E'],
        nombrePais: 'Belgica'

    },
    {

        continente: 'Y',
        pais: ['S','W'],
        nombrePais: 'Suecia'

    },
    {
        continente: 'Z',
        pais: ['A','R'],
        nombrePais: 'Italia'

    }
]

export function darPaisBastidor(digitos: string): string {

    let resultado = '';
    let digito1=digitos[0].toUpperCase();
    let digito2=digitos.length>1 && digitos[1]!==''?digitos[1].toUpperCase():'';

    switch (digito1) {
        case '1':
    
            resultado='EEUU'
            
            break;
    
            case '2':
    
            resultado='Canada'
            
            break;
    
            case '3':
    
            resultado='Mexico'
            
            break;
            case '4':
    
            resultado='EEUU'
            
            break;
            case '5':
    
            resultado='EEUU'
            
            break;
            case 'J':
    
            resultado='Japon'
            
            break;
      
        default:
            resultado=''
            break;
      }

    if(resultado==='' && digito2!==''){
    

   wmiInternacional.forEach((wmi,index)=>{

        if(wmi.continente===digito1){

            for(let i=1;i<=(wmi.pais.length-1);i+=2){

                if(digito2>=wmi.pais[(i-1)] && digito2<=wmi.pais[(i)] ){

                   resultado=wmi.nombrePais
                }

            }

        }

    })

}

    return resultado;


}