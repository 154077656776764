import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, SelectBox } from 'devextreme-react'
import { RequiredRule, SimpleItem } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSize } from '../../../../utils/media-query'
import {
  getIdocCarDossierStatusRelationList,
  postIdocCarDossierStatusRelation,
  putIdocCarDossierStatusRelation,
} from '../service/idocCarRelacionesEstadosExpedientesService'
import { ButtonsForm } from 'src/components/buttonsForm/ButtonsForm'
import { addIdocCarDossierStatusRelationAll } from '../redux/idocCarRelacionesEstadosExpedientesActions'
import { addIdocCarDossierStatusElGestorAll } from '../../idocCarStatesFiles/redux/idocCarEstadosExpedientesActions'
import {
  getIdocCarDossierStatusElGestorList,
  getIdocCarDossierStatusList,
  putIdocCarDossierStatusElGestor,
} from '../../idocCarStatesFiles/service/idocCarEstadosExpedientesService'

function IdocCarRelacionesEstadosExpedientesFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const idocCarDossierStatusRelationState = useSelector(
    (state: RootState) => state.idocCarDossierStatusRelation,
  )
  const idocCarDossierStatusElGestorState = useSelector(
    (state: RootState) => state.idocCarDossierStatusElGestor,
  )
  const { isLarge } = useScreenSize()
  const formData = useRef({})
  const { handleSubmit } = useForm()
  const [selectDossier, setSelectDossier]: any = useState()
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {
      setSelectDossier(await getIdocCarDossierStatusList(userState!.user!))
      dispatch(
        addIdocCarDossierStatusElGestorAll(
          await getIdocCarDossierStatusElGestorList(userState!.user!),
        ),
      )
    }
    fetchData()
  }, [props.showFormIdocCarDossierStatusRelation])

  const onSubmit = async (data: any) => {
    const { IdDossierStatus, IdStatusElGestor, Color } = formData.current as any
    console.log('Relation seleccionado:', idocCarDossierStatusRelationState)
    let dossierGestor =
      idocCarDossierStatusElGestorState.idocCarDossierStatusElGestorAll?.find(
        (dossierGestor) => dossierGestor.Id === IdStatusElGestor,
      )
    if (dossierGestor?.Color !== Color) {
      dossierGestor!.Color = Color
      await putIdocCarDossierStatusElGestor(dossierGestor!)
    }

    if (!props.isCreatingForm) {
      let formDataParsed: JSON = JSON.parse(
        JSON.stringify({
          IdDossierStatus: IdDossierStatus,
          IdStatusElGestor: IdStatusElGestor,
        }),
      )
      let solicitud = await putIdocCarDossierStatusRelation(
        userState!.user!,
        formDataParsed,
      )
      if (solicitud) {
        dispatch(
          addIdocCarDossierStatusRelationAll(
            await getIdocCarDossierStatusRelationList(userState!.user!),
          ),
        )
      }
    } else {
      let formDataParsed: JSON = JSON.parse(
        JSON.stringify({
          IdDossierStatus: IdDossierStatus,
          IdStatusElGestor: IdStatusElGestor,
        }),
      )

      const solicitud = await postIdocCarDossierStatusRelation(
        userState!.user!,
        formDataParsed!,
      )
      if (solicitud) {
        dispatch(
          addIdocCarDossierStatusRelationAll(
            await getIdocCarDossierStatusRelationList(userState!.user!),
          ),
        )
      }
      handleCancelClick()
    }
  }
  const handleCancelClick = () => {
    props.setShowFormIdocCarDossierStatusRelation(false)
    props.setIsCreatingForm(false)
    props.setIsEditingForm(false)
  }

  const renderButtons = () => {
    return ButtonsForm(
      `idocCarDossierStatusElGestorData`,
      handleCancelClick,
      false,
    )
  }
  console.log(props)
  const selectedBoxElPortalOptions = {
    items: selectDossier,
    placeholder: 'Seleccione un Estado',
    displayExpr: 'Name',
    valueExpr: 'Id',
    value: idocCarDossierStatusRelationState.idocCarDossierStatusRelation
      ?.IdDossierStatus
      ? idocCarDossierStatusRelationState.idocCarDossierStatusRelation
          ?.IdDossierStatus
      : null,
  }
  const selectedBoxElGestorOptions = {
    items: idocCarDossierStatusElGestorState.idocCarDossierStatusElGestorAll,
    placeholder: 'Seleccione un Estado',
    displayExpr: 'Description',
    valueExpr: 'Id',
    value: idocCarDossierStatusRelationState.idocCarDossierStatusRelation
      ?.IdStatusElGestor
      ? idocCarDossierStatusRelationState.idocCarDossierStatusRelation
          ?.IdStatusElGestor
      : null,
    readOnly: !!props.isEditingForm,
  }

  return (
    <>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              formData={formData.current}
              readOnly={!(props.isCreatingForm || props.isEditingForm)}
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='idocCarDossierStatusElGestorData'
              showColonAfterLabel={true}
            >
              <SimpleItem
                dataField={'IdDossierStatus'}
                editorType={'dxSelectBox'}
                label={{ text: 'Estado El Portal' }}
                editorOptions={selectedBoxElPortalOptions}
              >
                <RequiredRule message={'campo obligatorio'}></RequiredRule>
              </SimpleItem>

              <SimpleItem
                dataField={'IdStatusElGestor'}
                editorType={'dxSelectBox'}
                label={{ text: 'Estado El Gestor' }}
                editorOptions={selectedBoxElGestorOptions}
              >
                <RequiredRule message={'campo obligatorio'}></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField={'Color'}
                editorType={'dxSelectBox'}
                label={{ text: 'Añadir Color al estado' }}
                editorOptions={{
                  items: [
                    { name: 'Rojo', value: '#EB4B39' },
                    { name: 'Azul', value: '#1CB1EB' },
                    { name: 'naranja', value: '#EB7B17' },
                    { name: 'Verde', value: '#70E858' },
                    { name: 'Amarillo', value: '#EADE57' },
                    { name: 'Purpura', value: '#8928EB' },
                    { name: 'Gris', value: '#C1C1C1' },
                    { name: 'Rosa', value: '#EBACC5' },
                  ],
                  value: props.row?.DossierStatusElGestor?.Color
                    ? props.row.DossierStatusElGestor.Color
                    : '',
                  displayExpr: 'name',
                  valueExpr: 'value',
                  placeholder: 'Seleccione un color',
                  fieldRender: (field: any) => {
                    const selectedItem = field.component.option('selectedItem')
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedItem && (
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: selectedItem.value,
                              marginRight: '10px',
                              border: '1px solid #ccc',
                            }}
                          />
                        )}
                        <SelectBox {...field} />
                      </div>
                    )
                  },
                  itemTemplate: (itemData: any, _: any, itemElement: any) => {
                    const div = document.createElement('div')
                    div.style.display = 'flex'
                    div.style.alignItems = 'center'

                    const colorBox = document.createElement('div')
                    colorBox.style.width = '20px'
                    colorBox.style.height = '20px'
                    colorBox.style.backgroundColor = itemData.value
                    colorBox.style.marginRight = '10px'

                    const span = document.createElement('span')
                    span.textContent = itemData.name

                    div.appendChild(colorBox)
                    div.appendChild(span)
                    itemElement.appendChild(div)
                  },
                }}
              />
              <SimpleItem render={renderButtons} cssClass='alignBottom' />
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export default IdocCarRelacionesEstadosExpedientesFormPage
