import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'devextreme-react'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import { confirm } from 'devextreme/ui/dialog'
import { useForm } from 'react-hook-form'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSize } from '../../../../utils/media-query'
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm'
import { TemplateFields } from '../components/TemplateFields'
import { addTemplate } from '../redux/template/TemplateActions'
import { addProfile } from '../../../adminPages/profilePages/redux/profileActions'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import {
  _getTemplateWithChild,
  _postTemplate,
  _putTemplate,
} from '../services/plantillaService'
import { TemplateFieldsModel } from '../models/templateFieldsModel/templateFieldsModel'
import { addShowLoader } from 'src/redux/actions/configActions'

function TemplateFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const perfilState = useSelector((state: RootState) => state.profile)
  const templateState = useSelector((state: RootState) => state.template)
  const { isXSmall, isLarge } = useScreenSize()
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {}
    fetchData()
  }, [])

  const onSubmit = async (data: any) => {
    setLoading(true)

    let confirmDialog = confirm(
      '<i>¿Está seguro de que quiere guardar la información?</i>',
      'Confirma los cambios',
    )
    confirmDialog.then(async (dialogResult) => {
    dispatch(addShowLoader(true))
      if (dialogResult) {
        if (
          templateState?.template?.Nombre !== undefined &&
          templateState?.template?.Nombre !== '' &&
          perfilState.profile !== undefined
        ) {
          const extraData = {
            IdPlantillaParent: templateState?.templateFields![0]!.IdPlantilla,
            IdPerfil: perfilState.profile?.Id,
            Nombre: templateState?.template?.Nombre,
          }
          let isCreateTemplate: boolean = false
          if (templateState?.template?.Id === 0) {
            isCreateTemplate = await _postTemplate(
              userState!.user!,
              templateState!.templateFields! as TemplateFieldsModel[],
              extraData,
            )
          } else {
            isCreateTemplate = await _putTemplate(
              userState!.user!,
              templateState!.templateFields! as TemplateFieldsModel[],
              extraData,
            )
          }

          if (isCreateTemplate) {
            const templatesChild = await _getTemplateWithChild(
              userState!.user!,
              templateState?.templateFields![0]!.IdPlantilla as number,
            )
            props.setTemplates(templatesChild)
            setLoading(false)
            dispatch(addShowLoader(false))
            props.setShowForm(!props.showForm)
          } else {
            setLoading(false)
            dispatch(addShowLoader(false))
            props.setShowForm(false)
          }
        } else {
          setLoading(false)
          dispatch(addShowLoader(false))
          showToast(
            'La plantilla no tiene nombre o no tiene asignado un perfil',
            NotifyType.error,
            5000,
          )
        }
      } else {
        setLoading(false)
        dispatch(addShowLoader(false))
      }
    })
  }

  const handleCancelClick = () => {
    props.setShowForm(false)
  }

  const renderButtons = () => {
    return ButtonsForm(`plantillaData`, handleCancelClick, false)
  }

  const perfilBoxOptions = {
    items: perfilState.profiles,
    value:
      perfilState.profile?.Id !== undefined
        ? perfilState.profile?.Id
        : templateState.template?.IdPerfil,
    placeholder: 'Seleccione un perfil',
    displayExpr: 'Nombre',
    valueExpr: 'Id',
    onValueChanged: (event: any) => {
      if (event.event) {
        dispatch(
          addProfile(
            perfilState?.profiles!.find(
              (profile) => profile.Id === event.value,
            ),
          ),
        )
      }
    },
  }

  // const handleUpdatedSujeto = (property: any) => {
  // dispatch(
  //   addSujeto({
  //     ...sujetoState,
  //     ...property,
  //   }),
  // )
  // }
  //const formSujetoRef=useRef(null);
  return (
    <>
      <div>
        <div>
          <h4>
            Plantilla de donde se hereda:{' '}
            {props['plantillaParent'] !== undefined
              ? props['plantillaParent'].Nombre
              : ''}
          </h4>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              formData={formData.current}
              disabled={loading}
              //ref={formSujetoRef}
              readOnly={false}
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='plantillaData'
              showColonAfterLabel={true}
            >
              <GroupItem colCount='2'>
                <SimpleItem
                  dataField={'Nombre'}
                  editorType={'dxTextBox'}
                  editorOptions={{
                    placeholder: 'Nombre Plantillas',
                    mode: 'text',
                    value: templateState.template?.Nombre
                      ? templateState.template?.Nombre
                      : '',
                    onValueChanged: (event: any) => {
                      if (event.event) {
                        dispatch(
                          addTemplate({
                            ...templateState.template,
                            Nombre: event.value,
                          }),
                        )
                      }
                    },
                  }}
                ></SimpleItem>
                <SimpleItem
                  dataField='Perfiles'
                  editorType='dxSelectBox'
                  editorOptions={perfilBoxOptions}
                ></SimpleItem>
              </GroupItem>

              <GroupItem colCount='1'>
                <SimpleItem>
                  <TemplateFields />
                </SimpleItem>
              </GroupItem>
              <SimpleItem render={renderButtons} cssClass='alignBottom' />
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export { TemplateFormPage }
