import { JsonObject, JsonProperty } from 'json2typescript'
import { NotificationInfoModel } from './notificationInfoModels'

@JsonObject('FirmaExpedienteModel')
export class FirmaExpedienteModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('lineaProcesoId', Number)
  lineaProcesoId: number | null = null;

  @JsonProperty('expedienteId', Number)
  expedienteId: number | null = null;

  @JsonProperty('documentTypeId', Number)
  documentTypeId: number = 0;

  @JsonProperty('documentId', Number)
  documentId: number | null = null;

  @JsonProperty('sujetoId', Number)
  sujetoId: number = 0;

  @JsonProperty('additionalEmails', String)
  additionalEmails: string = '';

  @JsonProperty('notificationInfo', NotificationInfoModel)
  notificationInfo: NotificationInfoModel = new NotificationInfoModel();
}
