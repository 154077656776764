import { Box, Button, Form } from 'devextreme-react'
import {
  EmailRule,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import React from 'react'
import { ReenviarNotificacionModel } from '../models/reenviarNotificacionModel'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { Item } from 'devextreme-react/box'
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'

interface Props {
  datosformulario: ReenviarNotificacionModel
  setdatosformulario: Function
  enviarformulario: Function
  reenviar: Function
}

export default function FormNotificacionSection({
  datosformulario,
  setdatosformulario,
  enviarformulario,
  reenviar,
}: Props) {
  function enviarFormulario() {
    enviarformulario()
  }

  const dispatch = useDispatch()

  return (
    <Form
      labelLocation='top'
      validationGroup='notificacionValidation'
      width={
        document.body.scrollWidth > 1368
          ? document.body.scrollWidth - 1200
          : 'auto'
      }
    >
      <GroupItem colCount={2}>
        <SimpleItem
          cssClass={'labelCheck'}
          label={{ text: 'Enviar Por Email' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            value: datosformulario.sendNotificationByEmail,
            mode: 'text',
            onValueChanged: (event: any) => {
              if (event.event) {
                setdatosformulario({
                  ...datosformulario,
                  sendNotificationByEmail: event.value,
                })
              }
            },
          }}
        ></SimpleItem>
        <SimpleItem
          label={{ text: 'Email' }}
          editorType={'dxTextBox'}
          editorOptions={{
            disabled: !datosformulario.sendNotificationByEmail ? true : false,
            mode: 'text',
            hint: 'Email Sujeto',
            value: datosformulario.email,

            onValueChanged: (event: any) => {
              if (event.event) {
                setdatosformulario({
                  ...datosformulario,
                  email: event.value,
                })
              }
            },
          }}
        >
          {datosformulario.sendNotificationByEmail && (
            <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
          )}
          <EmailRule message={'Formato de correo incorrecto'}></EmailRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={2}>
        <SimpleItem
          cssClass={'labelCheck'}
          label={{ text: 'Enviar Por Teléfono' }}
          editorType={'dxCheckBox'}
          editorOptions={{
            value: datosformulario.sendNotificationBySms,
            mode: 'text',
            onValueChanged: (event: any) => {
              if (event.event) {
                setdatosformulario({
                  ...datosformulario,
                  sendNotificationBySms: event.value,
                })
              }
            },
          }}
        ></SimpleItem>
        <SimpleItem
          label={{ text: 'Teléfono' }}
          editorType={'dxTextBox'}
          editorOptions={{
            disabled: !datosformulario.sendNotificationBySms ? true : false,
            mode: 'text',
            hint: 'Email Sujeto',
            value: datosformulario.phoneNumber,

            onValueChanged: (event: any) => {
              if (event.event) {
                setdatosformulario({
                  ...datosformulario,
                  phoneNumber: event.value,
                })
              }
            },
          }}
        >
          {datosformulario.sendNotificationBySms && (
            <RequiredRule message={'Campo Obligatorio'}></RequiredRule>
          )}
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={1}>
        <Box direction='row' align='end' crossAlign='end' width={'100%'}>
          <Item ratio={0} baseSize={'auto'}>
            <Button
              text={'Reenviar'}
              type='default'
              validationGroup='notificacionValidation'
              onClick={(e: any) => {
                e.event.preventDefault()
                let result = e.validationGroup.validate()
                if (result.isValid) {
                  if (
                    datosformulario.sendNotificationByEmail ||
                    datosformulario.sendNotificationBySms
                  ) {
                    dispatch(addShowLoader(true))
                    reenviar(true)
                    enviarFormulario()
                  } else {
                    showToast(
                      'Debes indicar almenos un email/teléfono',
                      NotifyType.error,
                      5000,
                    )
                  }
                }
              }}
            />
          </Item>
        </Box>
      </GroupItem>
    </Form>
  )
}
