import { DataGrid } from 'devextreme-react'
import {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
  Selection,
  Sorting,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showPopup } from 'src/redux/actions/sharedUtilsActions'
import { RootState } from 'src/redux/combineReducers'
import { useScreenSize } from 'src/utils/media-query'
import {
  checkScreenSize,
  CURRENCY_FORMAT,
  getAlignment,
  getColumnType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import { StructureExpedienteModel } from '../../models/structureExpedienteModel'
import {
  addBienes,
  addCostes,
  addDetalleCoste,
  addDetalleExpediente,
  addDocumentos,
  addTareasPendientes,
} from '../../redux/escritura/escrituraActions'
import { getCostes, getDetalleCoste } from '../../services/costeService'
import { getDocumentosByExpediente } from '../../services/documentosService'
import { getBienesByExpediente } from '../../services/escritura/bienService'
import {
  getDetalleEscritura,
  getEscrituras,
  getEstructuraEscrituras,
} from '../../services/escritura/escrituraService'
import { getTareasPendientesByExpediente } from '../../services/tareaService'
import ConsultaEscritura from './consultaEscritura/ConsultaEscritura'
import { OnExporting } from 'src/utils/export/ExportExcel'
import { useQuery } from 'react-query'
import { getIdocCarDossierStatusRelationList } from 'src/pages/companyAdminPages/idocCarRelationshipsStatesFiles/service/idocCarRelacionesEstadosExpedientesService'

// ----------------------------------------------------------------------

const options = [
  'filter',
  'skip',
  'take',
  'requireTotalCount',
  'sort',
  'totalSummary',
  'group',
]

const getStore = (user: any) =>
  new DataSource({
    store: new CustomStore({
      key: 'IdKey',
      load: async (loadOptions: any) =>
        await getEscrituras(user, loadOptions, options),
    }),
  })

// --------------------------------------------------------------------------

function EscriturasList() {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user?.user!)
  const [structure, setStructure] = useState(new StructureExpedienteModel())
  const store = React.useMemo(() => getStore(userState), [userState])
  const dataGridRef: any = useRef(null)
  const { isLarge } = useScreenSize()
  const [loading, setLoading] = useState(false)

  async function updatedPlantillas() {
    const value = localStorage.getItem('gridEscriturasComponent')
    const structure = await getEstructuraEscrituras(userState!)
    if (value) {
      const gridExpedientesComponent = JSON.parse(value ?? '')

      gridExpedientesComponent.columns.map((itemStorage: any) => {
        const columnFind = structure?.Campos?.find(
          (itemStructure) => itemStructure.Nombre === itemStorage.name,
        )

        if (itemStorage.visible !== columnFind?.Visible) {
          localStorage.setItem('plantillasEscriturasReload', 'true')
          window.location.reload()
        }
      })
    }
  }

  const onToolbarPreparing = (event: any) => {
    event.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: (_e: any) => {
            dataGridRef
              ? dataGridRef!.current!.instance.option(
                  'filterBuilderPopup.visible',
                  true,
                )
              : showToast(
                  'No se ha podido recargar, inténtelo de nuevo mas tarde',
                  NotifyType.error,
                  5000,
                )
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            const value = localStorage.getItem('gridEscriturasComponent')
            const structure = await getEstructuraEscrituras(userState!)
            updatedPlantillas()

            setStructure(structure)

            dataGridRef
              ? dataGridRef!.current!.instance.refresh()
              : showToast(
                  'No se ha podido recargar, inténtelo de nuevo mas tarde',
                  NotifyType.error,
                  5000,
                )
          },
        },
      },
    )
  }

  useEffect(() => {
    async function fetchData() {
      try {
        updatedPlantillas()
        const structure = await getEstructuraEscrituras(userState)
        const value = localStorage.getItem('gridEscriturasComponent')

        if (value) {
          const gridExpedientesComponent = JSON.parse(value ?? '')
          gridExpedientesComponent['pageIndex'] = 0

          gridExpedientesComponent.columns.map((itemStorage: any) => {
            const columnFind = structure?.Campos?.find(
              (itemStructure) => itemStructure.Nombre === itemStorage.name,
            )

            const plantillasReload = localStorage.getItem(
              'plantillasEscriturasReload',
            )
            if (plantillasReload === 'true') {
              localStorage.setItem('plantillasEscriturasReload', 'false')
              showToast('Plantillas actualizadas', NotifyType.info, 5000)
            } else if (itemStorage.visible !== columnFind?.Visible) {
              showToast('Plantillas actualizadas', NotifyType.info, 5000)
            }

            itemStorage.visible = columnFind?.Visible
          })

          localStorage.setItem(
            'gridEscriturasComponent',
            JSON.stringify(gridExpedientesComponent),
          )
        }

        setStructure(structure)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [dispatch, userState])

  const openExpediente = async ({ Id }: any) => {
    setLoading(true)
    dispatch(showPopup(true))

    try {
      const [detalle, coste, bienes, costes, tareas, documentos] =
        await Promise.all([
          getDetalleEscritura(Id, userState!),
          getDetalleCoste(Id, userState!),
          getBienesByExpediente(Id),
          getCostes(Id, userState!),
          getTareasPendientesByExpediente(Id, userState!),
          getDocumentosByExpediente(Id, userState!),
        ])

      dispatch(addDetalleExpediente(detalle))
      dispatch(addDetalleCoste(coste))
      dispatch(addBienes(bienes))
      dispatch(addCostes(costes))
      dispatch(addTareasPendientes(tareas))
      dispatch(addDocumentos(documentos))
    } catch (error) {
      console.error('Error al procesar datos:', error)
    } finally {
      setLoading(false)
    }
  }

  const HandlePropertyChange = (e: any) => {
    // Evita realizar acciones específicas en eventos loadPanel y export
    if (e.name.trim() === 'loadPanel' || e.name.trim() === 'export') {
      return
    }
  }

  const isLongText = (nombre: string) => {
    const longTexts = ['Notas', 'NotasGestion', 'Superficie']
    return longTexts.includes(nombre)
  }

  interface EstadoColor {
    IdDossierStatus: string
    EstadoGestor: string
    EstadoPortal: string
    Color: string
  }

  const { data: estadosYColores } = useQuery<EstadoColor[] | undefined>(
    'estadosYColores',
    async () => {
      const response = await getIdocCarDossierStatusRelationList(
        userState!.user!,
      )
      if (response && Array.isArray(response)) {
        return response.map((item) => ({
          IdDossierStatus: item.IdDossierStatus,
          EstadoGestor: item.DossierStatusElGestor.Description,
          EstadoPortal: item.DossierStatus.Name,
          Color: item.DossierStatusElGestor.Color,
        }))
      }
      return
    },
    {
      staleTime: Infinity,
    },
  )

  const getColorForStatus = useCallback(
    (idDossierStatus: string) => {
      const estado = estadosYColores?.find(
        (e) =>
          e.EstadoPortal === idDossierStatus ||
          e.EstadoGestor === idDossierStatus,
      )
      return estado && estado.Color && estado.Color.trim() !== ''
        ? estado.Color
        : null
    },
    [estadosYColores],
  )

  return (
    <div
      id='container-body'
      style={{
        borderColor: '#f4f9ff',
        borderWidth: '2px',
        margin: '0.3%',
        padding: '0.16%',
        minHeight: '290px',
        maxHeight: 'calc(110vh - 8rem - 10px)',
      }}
    >
      <h3 style={{ margin: 2, padding: 0, marginLeft: 4 }}>
        Consulta expedientes escrituras
      </h3>
      {!!structure.Campos.length && (
        <DataGrid
          id={'gridEscriturasComponent'}
          ref={dataGridRef}
          dataSource={store}
          style={{ width: '100%', minHeight: '250px' }} // Para sobreescribir un max-width que le viene por...
          height='calc(100vh - 8rem - 10px)'
          showBorders
          showRowLines
          focusedRowEnabled={false}
          wordWrapEnabled={false}
          remoteOperations
          rowAlternationEnabled
          allowColumnReordering
          allowColumnResizing
          columnResizingMode={'widget'}
          columnAutoWidth
          columnChooser={{ enabled: false }}
          columnHidingEnabled={!checkScreenSize()}
          onOptionChanged={HandlePropertyChange}
          filterPanel={{ visible: true }}
          filterRow={{ visible: true }}
          onRowDblClick={({ data }: any) => {
            openExpediente(data)
          }}
          onToolbarPreparing={(event: any) => {
            onToolbarPreparing({ ...event })
          }}
          onKeyDown={({ event }: any) => {
            if (event.key === 'Enter') {
              const grid = dataGridRef.current!.instance
              const focusedRowKey = grid.state().focusedRowKey
              const rowIndex = grid.getRowIndexByKey(focusedRowKey)
              const rows = grid.getVisibleRows()
              const data = rows[rowIndex].data
              openExpediente(data)
            }
          }}
          export={{
            allowExportSelectedData: true,
            enabled: true,
          }}
          onExporting={OnExporting}
        >
          <StateStoring
            enabled
            type='localStorage'
            storageKey='gridEscriturasComponent'
          />
          <Scrolling
            mode='virtual'
            rowRenderingMode='virtual'
            showScrollbar='always'
            scrollByThumb
            preloadEnabled
          />
          <SearchPanel visible width={checkScreenSize() ? 240 : 100} />
          <Sorting mode={'multiple'} />
          <Paging pageSize={1000} />
          <Selection
            mode='multiple'
            showCheckBoxesMode={isLarge ? 'always' : 'none'}
            allowSelectAll
          />
          {structure.Campos.map((value) => (
            <Column
              defaultSortOrder={
                value.Nombre === 'FechaInicio' ? 'desc' : undefined
              }
              width={isLongText(value.Nombre) ? '300px' : 'auto'}
              visible={value.Visible}
              key={value.Nombre}
              dataField={value.Nombre}
              caption={value.Texto}
              dataType={value.Tipo}
              allowGrouping={value.Nombre !== 'TotalFactura'}
              alignment={getAlignment(value.Tipo)}
              format={getColumnType(value.Tipo, value.Format)}
              allowHeaderFiltering={value.Tipo !== 'date'}
              allowSorting
              showInColumnChooser={value.Visible}
              cellRender={
                value.Nombre === 'Estado'
                  ? (cellData: any) => {
                      if (!cellData.value || cellData.value.trim() === '') {
                        return null
                      }
                      const backgroundColor = getColorForStatus(cellData.value)
                      if (!backgroundColor) {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            {cellData.value}
                          </div>
                        )
                      }
                      return (
                        <div
                          style={{
                            backgroundColor: backgroundColor,
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '5px',
                            boxShadow: '-1.5px -1.5px 4px rgba(0, 0, 0, 0.2)',
                            color: 'white',
                          }}
                        >
                          {cellData.value}
                        </div>
                      )
                    }
                  : undefined
              }
            />
          ))}
          <Summary>
            <TotalItem column='NumeroExpediente' summaryType='count' />
            <TotalItem
              column='TotalFactura'
              summaryType='sum'
              valueFormat={CURRENCY_FORMAT}
              alignment='center'
              displayFormat='{0}'
            />
          </Summary>
        </DataGrid>
      )}
      <ConsultaEscritura loading={loading} setLoading={setLoading} />
    </div>
  )
}

export { EscriturasList }
