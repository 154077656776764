import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  logType,
  methodsEndPointType,
  customLog,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { RoleModel } from '../models/usuariosPage/roleModel'
import { NotifyType, showToast } from '../utils/sharedUitls'

const serviceName = 'authService'

export const getRoles = async (user: UserModel): Promise<RoleModel[]> => {
  let roles: RoleModel[] = []
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Role`
    await axiosInstance
      .get(url)
      .then((response: any) => {
        roles = jsonConvert.deserializeArray(response.data, RoleModel)
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getRoles.name,
          methodsEndPointType.GET,
        )
        showToast(
          'No se han podido obtener los sujetos.',
          NotifyType.error,
          5000,
        )
      })
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
  return roles
}
