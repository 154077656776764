import React, { useEffect, useRef, useState } from 'react'
import { Box, Item } from 'devextreme-react/box'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { DocumentTypeGridModel } from 'src/pages/firmadigital/DocumentType/models/documentTypeGridModel'
import { getDocumentType } from 'src/pages/firmadigital/DocumentType/services/documentTypeServices'
import { getFields, postUpdateFile } from '../../services/fieldsServices'
import { FieldsSection } from './FieldsSection'
import { ExpedientFieldModel } from '../../models/ExpedienteFieldModels'
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'
import { FileUploader } from 'devextreme-react'

function FormFieldsSection(props: any) {
  const dispatch = useDispatch()
  const [selectDocumentType, setSelectDocumentType] = useState(
    new Array<DocumentTypeGridModel>(),
  )
  const queryClient = useQueryClient()
  const selectedDocument =
    props.documento !== undefined
      ? [{ id: props.documento.id, name: props.documento.name }]
      : null
  const [idFieldsSelected, setIdFieldsSelected] = useState(
    selectedDocument  != null  ? selectedDocument[0].id : -1,
  )
  const fileExtensions = ['.pdf', '.docx']
  const [fileName, setFileName] = useState('')
  const uploadedfile: any = useRef()
  const [fields, setFields] = useState(new Array<String>())
  const [availableFields, setAvailableFields] = useState(
    new Array<ExpedientFieldModel>(),
  )

  // Consulta para obtener los tipos de documento
  useQuery({
    queryKey: ['ConsultaFirmaDocumentType'],
    queryFn: () => getDocumentType,
    onSuccess: (data: Array<DocumentTypeGridModel> | []) => {
      if (data.length > 0) {
        setSelectDocumentType(data)
      }
    },
    refetchOnWindowFocus: false,
  })

  // Consulta para obtener los campos configurables
  useQuery(['CamposConfigurables'], getFields, {
    onSuccess: (data: Array<ExpedientFieldModel> | []) => {
      if (data.length > 0) {
        setAvailableFields(data)
      }
    },
    refetchOnWindowFocus: false,
  })

  // Mutación para actualizar el archivo
  const updateFile = useMutation(
    async function () {
      setFields(await postUpdateFile(uploadedfile.current))
    },
    {
      onSuccess: function () {
        // Invalidar la consulta para forzar una recarga de los campos configurables
        queryClient.invalidateQueries('CamposConfigurables')
      },
    },
  )

  useEffect(() => {
    // Actualizar el estado cuando cambia el documento seleccionado
    if (selectedDocument  != null ) {
      setIdFieldsSelected(selectedDocument[0].id)
      if (selectedDocument[0].id === 0 && fields.length > 0) {
        setFields(new Array<String>())
      }
    } else {
      // Manejar el caso cuando selectedDocument es null
      setIdFieldsSelected(null)
      if (fields.length > 0) {
        setFields(new Array<String>())
      }
    }
  }, [selectedDocument, idFieldsSelected])
  return (
    <>
      <Box
        direction='row'
        align='start'
        crossAlign='start'
        width={'100%'}
        height={118}
      >
        <Item ratio={0} baseSize={'auto'}>
          <FileUploader
            multiple={false}
            uploadMode='useForm'
            allowedFileExtensions={fileExtensions}
            onValueChanged={(e: any) => {
              if (e.value.length > 0) {
                const formData = new FormData()
                formData.append('file', e.value[0])
                uploadedfile.current = formData

                let extension = e.value[0].name.split('.')

                if (
                  extension[extension.length - 1] === 'pdf' ||
                  extension[extension.length - 1] === 'docx'
                ) {
                  updateFile.mutate()
                  setFileName(e.value[0].name)
                  dispatch(addShowLoader(true))
                }
              } else {
                setFileName('')
              }
            }}
          />
          <span>
            {'Extensiones permitidas: '}
            <span>.pdf y .docx</span>.
          </span>
        </Item>
      </Box>

      <FieldsSection
        fields={fields}
        availableFields={availableFields}
        idDocumentType={idFieldsSelected}
        documentName={fileName}
      ></FieldsSection>
    </>
  )
}

export { FormFieldsSection }
