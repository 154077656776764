import React, { useCallback, useEffect, useState } from 'react';
// devextreme
import { DataGrid, LoadPanel } from 'devextreme-react';
import { Column, Scrolling, StateStoring } from 'devextreme-react/data-grid';
import Form, { Tab, TabbedItem } from 'devextreme-react/form';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { addAyuntamiento, addBien, addCatastro, addDefecto,
  addDefectos, addHacienda, addNotaria, addRegistro, addRegistros
} from 'src/pages/expedientePage/redux/escritura/escrituraActions';
// components
import AytoCatastroTabBienes from './tabsBien/AytoCatastroTabBienes';
import BienTabBienes from './tabsBien/BienTabBienes';
import DefectosTabBienes from './tabsBien/DefectosTabBienes';
import HaciendaTabBienes from './tabsBien/HaciendaTabBienes';
import NotariaTabBienes from './tabsBien/NotariaTabBienes';
import RegistroTabBienes from './tabsBien/RegistroTabBienes';
// services
import { getAyuntamientoByExpediente, getBienByExpediente,
  getCatastroByExpediente, getDefectoByExpediente,
  getDefectosByExpediente, getHaciendaByExpediente, 
  getNotariaByExpediente, getRegistroByExpediente,
  getRegistrosByExpediente } from 'src/pages/expedientePage/services/escritura/bienService';
// utils
import { getColumnAlignment, getColumnType } from 'src/utils/sharedUitls';

export default function BienesTabEscritura() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user!.user!);
  const bienesState = useSelector((state: RootState) => state.escrituras.bienes);
  const { showPopup } = useSelector((state: RootState) => state.sharedUtils);
  const [{ focusedRow, showLoader }, setState] = useState({
    focusedRow: 0,
    showLoader: false,
  });

  const fetch = useCallback(fetchData, [dispatch, focusedRow, userState]);

  useEffect(() => {
    showPopup && !!focusedRow && fetch()
  }, [showPopup, focusedRow, fetch]);

  const handleOnFocusedRowKeyChange = (Id: number) => {
    Id && setState((state) => ({ ...state, focusedRow: Id }));
  };

  async function fetchData() {
    setState((state) => ({ ...state, showLoader: true }));
    await Promise.all([
      getBienByExpediente(focusedRow, userState),
      getNotariaByExpediente(focusedRow, userState),
      getHaciendaByExpediente(focusedRow, userState),
      getRegistroByExpediente(focusedRow, userState),
      getRegistrosByExpediente(focusedRow, userState),
      getDefectoByExpediente(focusedRow, userState),
      getDefectosByExpediente(focusedRow, userState),
      getAyuntamientoByExpediente(focusedRow, userState),
      getCatastroByExpediente(focusedRow, userState),
    ]).then(
      ([
        bien,
        notaria,
        hacienda,
        registro,
        registros,
        defecto,
        defectos,
        ayto,
        catastro,
      ]) => {
        dispatch(addBien(bien));
        dispatch(addNotaria(notaria));
        dispatch(addHacienda(hacienda));
        dispatch(addRegistro(registro));
        dispatch(addRegistros(registros));
        dispatch(addDefecto(defecto));
        dispatch(addDefectos(defectos));
        dispatch(addAyuntamiento(ayto));
        dispatch(addCatastro(catastro));
      },
    );
    setState((state) => ({ ...state, showLoader: false }))
  };

  return (
    <>
      <DataGrid
        dataSource={bienesState.Datos}
        allowColumnReordering
        allowColumnResizing
        columnAutoWidth
        defaultFocusedRowIndex={0}
        focusedRowEnabled
        keyExpr='Id'
        showBorders
        showRowLines
        onFocusedRowKeyChange={handleOnFocusedRowKeyChange}
      >
      <Scrolling showScrollbar='always' />
      <StateStoring
        enabled
        type='localStorage'
        storageKey='gridBienesEscrituras'
      />
      {!!bienesState.Campos.length &&
        bienesState.Campos.map((value) => (
          <Column
            key={value.Nombre}
            dataField={value.Nombre}
            caption={value.Texto}
            //dataType={value.Tipo === 'datetime' ? 'string' : value.Tipo}
            dataType={value.Tipo}
            format={getColumnType(value.Tipo, value.Format)}
            alignment={getColumnAlignment(value.Tipo)}
            visible={value.Nombre === 'Id' ? false : value.Visible}
          />
        ))
      };
      </DataGrid>
      {bienesState.Datos.length > 0 && (
        <div style={{ height: '30rem' }}>
          <Form id='tabsBienes' style={{ marginTop: '1.5em' }} scrollingEnabled>
            <TabbedItem>
              <Tab title='Bien'>
                <BienTabBienes />
              </Tab>
              <Tab title='Notaria'>
                <NotariaTabBienes />
              </Tab>
              <Tab title='Hacienda'>
                <HaciendaTabBienes />
              </Tab>
              <Tab title='Datos del registro'>
                <RegistroTabBienes />
              </Tab>
              <Tab title='Histórico defectos'>
                <DefectosTabBienes />
              </Tab>
              <Tab title='Ayuntamiento/catastro'>
                <AytoCatastroTabBienes />
              </Tab>
            </TabbedItem>
          </Form>
        </div>
      )}
      <LoadPanel position='center' visible={showLoader} />
    </>
  );
};
