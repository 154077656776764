import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'devextreme-react';
import { GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { useForm } from 'react-hook-form';
import { RootState } from '../../../../redux/combineReducers';
import { useScreenSize } from '../../../../utils/media-query';
import { _postProfile, _putProfile } from '../service/profileService';
import { addProfile } from '../redux/profileActions';
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm';

function ProfileFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user);
  const profileState = useSelector((state: RootState) => state.profile);
  const { isXSmall, isLarge } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {}
    fetchData();
  }, [])

  const onSubmit = async (data: any) => {
    setLoading(true);

    let result: boolean = false
    if (!profileState?.profile?.Id) {
      dispatch(addProfile({ ...profileState, Id: 0 }));
    }

    if (profileState?.profile?.Id) {
      result = await _putProfile(profileState.profile);
    } else {
      result = await _postProfile(profileState.profile!);
    }

    if (result) {
      setLoading(false);
      const refreshButton = document.getElementsByClassName(
        'dx-icon dx-icon-refresh',
      )[0] as HTMLElement
      refreshButton.click();
      props.setShowForm(false);
      props.actualizarestado();
    } else {
      setLoading(false);
    }
  }

  const handleCancelClick = () => {
    props.setShowForm(false);
  }

  const renderButtons = () => {
    if (!props.showForm) {
      profileFormRef.current.instance.resetValues();
    }
    return ButtonsForm(`perfilData`, handleCancelClick, false);
  }

  const profileFormRef: any = useRef(null);
  return (
    <>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              ref={profileFormRef}
              formData={formData.current}
              disabled={loading}
              readOnly={false}
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='perfilData'
              showColonAfterLabel={true}
            >
              <GroupItem colCount='2'>
                <SimpleItem
                  dataField='Nombre'
                  helpText=''
                  editorOptions={{
                    placeholder: 'Nombre',
                    mode: 'text',
                    value: profileState?.profile?.Nombre,
                    onValueChanged: (event: any) => {
                      if (event.event) {
                        profileState.profile!.Nombre = event.value
                      }
                    },
                  }}
                >
                  <RequiredRule message='Debes introducir un nombre' />
                </SimpleItem>
                <SimpleItem
                  dataField={'Descripción'}
                  editorType={'dxTextArea'}
                  editorOptions={{
                    placeholder: 'Descripción',
                    mode: 'text',
                    value: profileState?.profile?.Descripcion
                      ? profileState.profile.Descripcion
                      : null,
                    onValueChanged: (event: any) => {
                      if (event.event) {
                        profileState.profile!.Descripcion = event.value
                      }
                    },
                  }}
                ></SimpleItem>
              </GroupItem>
              <SimpleItem render={renderButtons} cssClass='alignBottom' />
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export { ProfileFormPage }
