import { CompanyModuloModel } from 'src/models/company/companyModuloModel';
import { UserModel } from 'src/models/usuario/userModel';
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel';
import { Modules } from './allowModuloUtil';
import { rolesName } from './sharedUitls';

export enum ClaimsModule {
  EDIT = 'Edit',
  CREATE = 'Create',
  READ = 'Read',
  VIEW = 'View',
  DELETE = 'Delete',
  ALL = 'All',
}

function isRolAllowed(
  user: UserModel,
  module: Modules,
  claims: ClaimsModule[],
): boolean {
  if (user.Administracion.length > 0) {
    return true;
  } else {
    switch (module) {
      case Modules.ADMINISTRACION:
        if (user.Administracion.length > 0) {
          return true;
        }
        break;
      case Modules.EXPEDIENTES_VEHICULOS:
        if (user.Expedientes.length > 0) {
          return hasClaims(user.Expedientes, claims);
          // TODO cambiar cuando se reciba como vehiculos
        }
        break;
      case Modules.EXPEDIENTES_ESCRITURAS:
        if (user.ExpedientesEscrituras.length > 0) {
          return hasClaims(user.ExpedientesEscrituras, claims);
        }
        break;
      default:
        return false;
    }
    return false;
  }
}

function hasUserClaimsToModule(
  user: UserModel,
  module: string): boolean {

  switch (module) {
    case Modules.ADMINISTRACION:
      if (user.Administracion.length > 0) {
        return true;
      }
      break;
    case Modules.EXPEDIENTES_VEHICULOS:
      if (user.Expedientes.length > 0) {
        return true;
      }
      break;
    case Modules.EXPEDIENTES_ESCRITURAS:
      if (user.ExpedientesEscrituras.length > 0) {
        return true;
      }
      break;
    case Modules.MICRODATOS:
      if (user.Microdatos.length > 0) {
        return true;
      }
      break;
    case Modules.DATOSDEEMPRESA:
      if (user.DatosDeEmpresas.length > 0) {
        return true;
      }
      break;
    case Modules.RGPD:
      if (user.RGPD.length > 0) {
        return true;
      }
      break;
    case Modules.IDOCCAR:
      if (user.iDocCar.length > 0) {
        return true;
      }
      break;
    case Modules.FIRMADIGITAL:
      if (user.FirmaDigital.length > 0) {
        return true;
      }
      break;
    case Modules.INFORMESDEVEHICULOS:
      if (user.InformesVehiculos.length > 0) {
        return true;
      }
      break;
    case Modules.CALCULADORA:
      if (user.Calculadora.length > 0) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
}
function isSuperAdmin({ role }: UserModel) {
  return role === rolesName.SUPERADMINISTRADOR;
}

function isAdmin({ role }: UserModel) {
  return role === rolesName.ADMINISTRADOR;
}

function isNormalUser(user: UserModel) {
  // return !isSuperAdministrador(user) && !isAdministrador(user)
  return (
    user.role === rolesName.EMPLEADO ||
    user.role === rolesName.CLIENTE ||
    user.role === rolesName.TRANSMITENTE ||
    user.role === rolesName.COLABORADOR ||
    user.role
      .split(',')
      .find(
        (x) =>
          x === rolesName.CLIENTE ||
          x === rolesName.TRANSMITENTE ||
          x === rolesName.COLABORADOR,
      )
  );
}

function isEmployeeUser(user: UserModel) {
  return user.role === rolesName.EMPLEADO;
}

function isNormalUserByIdRole({ IdTipoUsuario }: UsuarioModel) {
  return !(IdTipoUsuario === 1 || IdTipoUsuario === 2);
}

function hasClaims(
  moduloClaims: string[],
  permisos: ClaimsModule[],
): boolean {
  const arrayPermisos: string[] = Object.values(permisos);
  // si el indice del elemento actual es igual al indice actual??? no hace nada...
  // moduloClaims = moduloClaims.filter((x, i, a) => a.indexOf(x) === i)
  // devuelve si todos los elementos de moduloClaims se encuentran en permisos
  return arrayPermisos.every((x) => moduloClaims.includes(x));
  // if (array.length === moduloClaims.filter((x) => array.includes(x)).length)
  //   return true
  // return false
}

function redirectLogin(companyModules: CompanyModuloModel[]): string {
  companyModules.forEach(module => {
    if (module.Code === Modules.EXPEDIENTES_ESCRITURAS) {
      return '/expedientes/escrituras';
    }
  })
  // companyModules.map((modulo) => {
  //   switch (modulo.Code) {
  //     case Modules.EXPEDIENTES_ESCRITURAS:
  //       return '/expedientes/escrituras';
  //   }
  // });
  return '/expedientes/vehiculos';
}

export {
  isRolAllowed,
  isSuperAdmin,
  isAdmin,
  isNormalUser,
  isEmployeeUser,
  isNormalUserByIdRole,
  hasClaims,
  redirectLogin,
  hasUserClaimsToModule
};
