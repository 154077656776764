import {
  ADD_IDOCCAR_DOSSIER_STATUS_ALL,
  ADD_IDOCCAR_DOSSIER_STATUS,
  // CLEAR_IDOCCAR_DOSSIER_STATUS,
  IdocCarDossierStatusElGestorActionsTypes,
  IdocCarDossierStatusElGestorState,
} from './idocCarEstadosExpedientesType'

const initialState: IdocCarDossierStatusElGestorState = {
  idocCarDossierStatusElGestor: undefined,
  idocCarDossierStatusElGestorAll: undefined,
}

export const idocCarDossierStatusElGestorReducer = (
  state = initialState,
  action: IdocCarDossierStatusElGestorActionsTypes,
): IdocCarDossierStatusElGestorState => {
  switch (action.type) {
    case ADD_IDOCCAR_DOSSIER_STATUS: {
      return { ...state, idocCarDossierStatusElGestor: action.payload }
    }
    case ADD_IDOCCAR_DOSSIER_STATUS_ALL: {
      return { ...state, idocCarDossierStatusElGestorAll: action.payload }
    }
    // case CLEAR_IDOCCAR_DOSSIER_STATUS: {
    //   return { ...state, idocCarDossierStatusElGestor: action.payload }
    // }
    default:
      return state
  }
}
