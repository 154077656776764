import React from 'react';
// devextreme
import Form, { Tab, TabbedItem } from 'devextreme-react/form';
// tabs
import BienesTabEscritura from './tabsEscritura/BienesTabEscritura';
import CostesTabEscritura from './tabsEscritura/CostesTabEscritura';
import DocumentosTabEscritura from './tabsEscritura/DocumentosTabEscritura';
import HistorialTabEscritura from './tabsEscritura/HistorialTabEscritura';

function TabsDeed() {
  return (
    <div style={{ marginTop: '0.3em' }}>
      <Form id='tabsEscrituras'>
        <TabbedItem>
          <Tab title='Bienes'>
            <BienesTabEscritura />
          </Tab>
          <Tab title='Costes'>
            <CostesTabEscritura />
          </Tab>
          <Tab title='Historial'>
            <HistorialTabEscritura />
          </Tab>
          <Tab title='Documentos'>
            <DocumentosTabEscritura />
          </Tab>
        </TabbedItem>
      </Form>
    </div>
  );
};
export { TabsDeed };