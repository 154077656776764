import { GroupItem, SimpleItem } from 'devextreme-react/form'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import '../../utils/alinearInputsValoresNumericos.css'

export default function OtrosDatosEmpresaSection() {
  const companyDataState = useSelector(
    (state: RootState) => state.Informa.company,
  )
  return (
    <GroupItem caption={'Otros'}>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'situacion'}
          label={{ text: 'Situación' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Situación Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'cnae'}
          label={{ text: 'CNAE' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Cnae Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'identificativo'}
          label={{ text: 'Identificativo' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Identificativo Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'fechaUltimoBalance'}
          label={{ text: 'Fecha último balance' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Fecha último balance Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'cargoPrincipal'}
          label={{ text: 'Cargo principal' }}
          editorType={'dxTextArea'}
          editorOptions={{
            mode: 'text',
            hint: 'Cargo Principal Empresa',
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Capital social' }}
          cssClass={'alinearInputs'}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Capital Social Empresa',
            value:
              companyDataState !== undefined
                ? Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(companyDataState?.capitalSocial)
                : null,
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Ventas' }}
          cssClass={'alinearInputs'}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Ventas Empresa',
            value:
              companyDataState !== undefined
                ? Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(companyDataState?.ventas)
                : null,
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'fechaConstitucion'}
          label={{ text: 'Fecha constitución' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Fecha Constitución Empresa',
          }}
        ></SimpleItem>
        <SimpleItem
          label={{ text: 'Ventas año' }}
          cssClass={'alinearInputs'}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Ventas año Empresa',
            value:
              companyDataState !== undefined
                ? Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(companyDataState?.anioVentas)
                : null,
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'empleados'}
          label={{ text: 'Empleados' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Empleados Empresa',
          }}
        ></SimpleItem>
      </GroupItem>
    </GroupItem>
  )
}
