import React, { MutableRefObject, useRef, useState } from 'react'
import {
  Box,
  Button,
  DataGrid,
  Popup,
  ScrollView,
  Template,
} from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { ToolbarItem } from 'devextreme-react/popup'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import { formatoFechaHistoricoEstado } from 'src/utils/FormatoFecha/formatoFecha'
import { checkScreenSize } from 'src/utils/sharedUitls'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { StateHistorySignedDataModel } from '../models/stateHistorySignedDataModel'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  deleteStateHistorySignedByExpedienteId,
  getStateHistorySigned,
  getStateHistorySignedByExpedienteId,
} from '../services/stateHistorySignedServices'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addShowLoader } from 'src/redux/actions/configActions'
import { EstadoPeticionModel } from '../models/estadoPeticionesModel'
import NotificacionesFirmaSection from './notificacionesFirmaSection'
import { custom } from 'devextreme/ui/dialog'

interface Props {
  idexpediente: number
  cerrar: Function
  verpopup: boolean
  texto: Function
}

export default function DatosPeticionEstado({
  idexpediente,
  cerrar,
  verpopup,
  texto,
}: Props) {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const StatePeticionHistoricoGridRef: MutableRefObject<any> = useRef()
  const [historicoEstadoFirmaGenerica, setHistoricoEstadoFirmaGenerica] =
    useState(Array<StateHistorySignedDataModel | EstadoPeticionModel>)
  const [StatusSolicitud, setStatusSolicitud] = useState(false)
  const [historicoEstadoFirma, setHistoricoEstadoFirma] = useState(false)
  const [verNotificaciones, setVerNotifaciones] = useState(false)
  const [idSolicitud, setIdSolicitud] = useState(0)

  const [textPopup, setTextPopUp] = useState('Estado Solicitud')

  useQuery(
    ['HistoricoFirmaDigitalExpediente', userState!.user, idexpediente],
    getStateHistorySignedByExpedienteId,
    {
      onSuccess: (data: Array<StateHistorySignedDataModel> | []) => {
        setHistoricoEstadoFirmaGenerica(data)
        setVerNotifaciones(false)
        dispatch(addShowLoader(false))
      },
      onError: () => {
        dispatch(addShowLoader(false))
      },

      refetchOnWindowFocus: false,
    },
  )

  function formatoFechas(rowData: any) {
    const pasarFechaFormateada = new Date(
      rowData.statusDateTimeUtc !== undefined
        ? rowData.statusDateTimeUtc
        : rowData.statusSetAtUtc,
    ).toISOString()
    return formatoFechaHistoricoEstado(pasarFechaFormateada)
  }
  function exportExcell(e: any) {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Estado Petición Histórico.xlsx',
        )
      })
    })
    e.cancel = true
  }
  const onHanleOnToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: () => {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        name: 'refrescar',
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async () => {
            StatePeticionHistoricoGridRef.current.instance.refresh()
          },
        },
      },
    )
  }

  const cancelarFirma = useMutation(
    async function (id: number) {
      dispatch(addShowLoader(true))
      await deleteStateHistorySignedByExpedienteId(userState!.user!, id!)
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries('HistoricoFirmaDigitalExpediente')
        dispatch(addShowLoader(false))
      },
    },
  )

  function cancelarFirmaRender(data: any): any {
    return (
      data.data.status !== 'RESPONSED' && (
        <Button
          text='Cancelar'
          type='default'
          onClick={() => {
            const confirmDialog = custom({
              messageHtml: '¿Desear cancelar la solicitud?',

              buttons: [
                {
                  text: 'Si',
                  onClick: (e) => {
                    cancelarFirma.mutate(data.data.id)
                    confirmDialog.hide()
                  },
                },
                {
                  text: 'No',
                  elementAttr: {
                    id: 'usuarioCancelButton',
                    class: 'usuarioCancelButtonClass',
                  },
                  onClick: (e) => confirmDialog.hide(),
                },
              ],
            })

            confirmDialog.show()
          }}
        />
      )
    )
  }

  function SolicitudStatusRender(data: any): any {
    return (
      <Button
        text='Histórico'
        type='default'
        onClick={() => {
          dispatch(addShowLoader(true))
          obtenerHistoricoFirma(data.data.id)
          setHistoricoEstadoFirma(true)
        }}
      />
    )
  }

  function verNotificacionesRender(data: any): any {
    return (
      <Button
        text='Notificaciones'
        type='default'
        onClick={() => {
          dispatch(addShowLoader(true))
          setTextPopUp('Notificaciones Solicitud')
          setIdSolicitud(data.data.id)
          setVerNotifaciones(true)
        }}
      />
    )
  }

  async function obtenerHistoricoFirma(id: number) {
    await getStateHistorySigned(id)
      .then((response) => {
        setTextPopUp('Histórico Solicitud')
        setHistoricoEstadoFirmaGenerica(response)
        setStatusSolicitud(true)
        dispatch(addShowLoader(false))
      })
      .catch((error) => dispatch(addShowLoader(false)))
  }

  return (
    <div hidden={verpopup ? false : true}>
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width='100%'
        height={730}
      >
        <Item ratio={0} baseSize={'auto'}>
          {!verNotificaciones && (
            <DataGrid
              id='informaHistoricoConsultaCompanyDataGrid'
              keyExpr='id'
              dataSource={historicoEstadoFirmaGenerica}
              style={{ width: '100%', minHeight: '250px' }}
              height={680}
              allowColumnReordering
              allowColumnResizing
              columnAutoWidth
              focusedRowEnabled
              remoteOperations
              repaintChangesOnly
              rowAlternationEnabled
              showBorders
              showRowLines
              columnChooser={{ enabled: true }}
              columnHidingEnabled={!checkScreenSize()}
              filterPanel={{ visible: true }}
              filterRow={{ visible: true }}
              headerFilter={{ visible: false }}
              loadPanel={{ enabled: true }}
              paging={{ enabled: false }}
              sorting={{ mode: 'multiple', showSortIndexes: false }}
              export={{
                allowExportSelectedData: false,
                enabled: true,
              }}
              onExporting={exportExcell}
              searchPanel={{
                visible: true,
                width: checkScreenSize() ? 240 : 120,
              }}
              selection={{
                mode: 'single',
              }}
              scrolling={{
                mode: 'standard',
                scrollByContent: true,
                scrollByThumb: true,
                showScrollbar: 'always',
              }}
              onToolbarPreparing={(event: any) => {
                onHanleOnToolbarPreparing(event)
              }}
            >
              <StateStoring
                enabled={true}
                type={'localStorage'}
                storageKey={'EstadoHistoryPeticionState'}
              />

              {!StatusSolicitud && (
                <Column
                  width={'34%'}
                  type='buttons'
                  cellRender={(cellRenderData: any) =>
                    SolicitudStatusRender(cellRenderData)
                  }
                />
              )}

              {!StatusSolicitud && (
                <Column
                  width={'30%'}
                  type='buttons'
                  cellRender={(cellRenderData: any) =>
                    cancelarFirmaRender(cellRenderData)
                  }
                />
              )}

              {!StatusSolicitud && (
                <Column
                  width={'39%'}
                  type='buttons'
                  cellRender={(cellRenderData: any) =>
                    verNotificacionesRender(cellRenderData)
                  }
                />
              )}

              <Column
                width={'50%'}
                caption={'Estado'}
                dataField={'status'}
                alignment='left'
              />

              {!StatusSolicitud && (
                <Column
                  width={'50%'}
                  caption={'Nombre Tipo Documento'}
                  dataField={'documentTypeName'}
                  alignment='left'
                />
              )}

              <Column
                width={'50%'}
                caption={'Fecha/Hora Estado'}
                dataField={'statusSetAtUtc'}
                alignment='left'
                calculateCellValue={formatoFechas}
              />
            </DataGrid>
          )}
        </Item>
        <Item>
          {verNotificaciones && (
            <NotificacionesFirmaSection
              idsolicitud={idSolicitud}
              cerrar={setVerNotifaciones}
              textopoup={setTextPopUp}
            ></NotificacionesFirmaSection>
          )}
        </Item>
      </Box>
      <Box direction='row' align='end' crossAlign='end' width={'100%'}>
        <Item ratio={0} baseSize={'auto'}>
          <Button
            text='Cancelar'
            type='default'
            onClick={() => {
              setStatusSolicitud(false)
              if (historicoEstadoFirma) {
                if (!StatusSolicitud || !historicoEstadoFirma) cerrar(false)
                setHistoricoEstadoFirma(false)
              }
              // si no esta en el historico de firma y no esta en notificaciones es
              // porque quiere llegar a la pantalla principal
              if (!verNotificaciones && !historicoEstadoFirma) cerrar(false)
              queryClient.invalidateQueries('HistoricoFirmaDigitalExpediente')
            }}
            visible={!StatusSolicitud || !verNotificaciones}
          />
        </Item>
      </Box>
    </div>
  )
}
