import React, { useEffect, useState } from 'react'
import { Box, Button, LoadPanel, Popup, ScrollView } from 'devextreme-react'
import { ToolbarItem } from 'devextreme-react/popup'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import DetallesCostes from './components/DetallesCostes'
import DetallesExpediente from './components/DetallesExpediente'
import DetallesVehiculo from './components/DetallesVehiculo'
import TabsVehiculo from './components/TabsVehiculo'
import { Item } from 'devextreme-react/box'
import FirmarExpediente from 'src/pages/firmadigital/pages/firmarExpediente'
import { FirmaExpedienteModel } from 'src/pages/firmadigital/models/firmaExpedienteModels'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import { isAdmin, isEmployeeUser } from 'src/utils/allowAuthUtil'
import DatosPeticionEstado from 'src/pages/firmadigital/StateHistorySigned/sections/datosPeticionEstadoSection'
import { useQuery } from 'react-query'
import { getDocumentType } from 'src/pages/firmadigital/DocumentType/services/documentTypeServices'
import { DocumentTypeGridModel } from 'src/pages/firmadigital/DocumentType/models/documentTypeGridModel'
import { getSujetosByExpediente } from 'src/pages/usuariosPage/service/sujetoService'
import { AddSujetosByExpediente } from 'src/pages/usuariosPage/redux/sujetoActions'
import { showPopup } from 'src/redux/actions/sharedUtilsActions'
import { addShowLoader } from '../../../../../redux/actions/configActions'

export type SelectSujeto = {
  id: number
  name: string
}

export default function ConsultaVehiculo(props: any) {
  const dispatch = useDispatch()
  const [showPopUpFirma, setShowPopUpFirma] = useState(false)

  const [estadoFirma, setEstadoFirma] = useState(new FirmaExpedienteModel())
  const showPopupState = useSelector(
    (state: RootState) => state.sharedUtils.showPopup,
  )
  const [datosSujetos, setDatosSujetos] = useState(new Array<SelectSujeto>())
  const userState = useSelector((state: RootState) => state.user)
  const [showPopupEstadoFirma, setShowPopupEstadoFirma] = useState(false)
  const [listaDocumentType, setListaDocumentType] = useState(
    new Array<DocumentTypeGridModel>(),
  )

  const [texto, setTexto] = useState('Consulta expedientes de vehículos')

  const { isFetching } = useQuery(['ConsultaFirmaDocumentType'], getDocumentType, {
    onSuccess: (data: Array<DocumentTypeGridModel> | []) => {
      if (data.length > 0) {
        let fieldRequiresDocumentUpload = data.filter((documenttype) => {
          if (estadoFirma.documentId  != null  && estadoFirma.documentId !== 0) {
            if (
              documenttype.requiresDocumentUpload ||
              documenttype.allowsDocumentUpload
            ) {
              return documenttype
            }
          } else {
            if (!documenttype.requiresDocumentUpload) {
              return documenttype
            }
          }
        })
        setListaDocumentType(
          fieldRequiresDocumentUpload as Array<DocumentTypeGridModel>,
        )
      }
      dispatch(addShowLoader(false))
    },
    enabled: showPopUpFirma,
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if(isFetching) dispatch(addShowLoader(true))
  }, [isFetching])

  async function fetchData() {
    try {
      const datosSujetosByExpediente = await getSujetosByExpediente(
        props.datos.id as number,
      )
      const listarSujetos = datosSujetosByExpediente.map((response) => ({
        id: response.Id,
        name: `${response.Nombre  != null  ? response.Nombre : ''} ${
          response.Apellido1  != null  ? response.Apellido1 : ''
        } ${response.Apellido2  != null  ? response.Apellido2 : ''}`,
      }))
      dispatch(AddSujetosByExpediente(datosSujetosByExpediente))
      setDatosSujetos(listarSujetos)
    } catch (error) {
      // Handle error
    }
  }

  function cerrarPopup(e: any) {
    if (e.key === 'Escape') {
      dispatch(showPopup(false))
      if (props.setfirma) {
        props.setfirma({
          realizarfirma: false,
          idexpediente: '',
          idvehiculo: '',
        })
      }
      if (props.popupview === undefined || props.popupview == null ) {
        props.close(false)
        props.setDatos({ id: null, idvehiculo: null })
      } else {
        props.setpopup(!props.popupview)
      }
    }
  }

  useEffect(() => {
    if (props.datos.id  != null ) {
      fetchData()
    }
    document.addEventListener('keydown', function (e) {
      cerrarPopup(e)
    })
    setEstadoFirma({ ...estadoFirma, expedienteId: props.datos.id })
  }, [props.datos])

  return (
    <>
      <Popup
        title={texto}
        visible={
          props.popupview !== undefined && props.popupview  != null 
            ? props.popupview
            : true
        }
        showCloseButton={true}
        resizeEnabled
        width='95%'
        height='95%'
        titleRender={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {texto}
            </div>
            <Button
              icon='close'
              onClick={() => {
                if (props.setfirma) {
                  props.setfirma({
                    realizarfirma: false,
                    idexpediente: '',
                    idvehiculo: '',
                  })
                }
                if (props.popupview === undefined || props.popupview == null ) {
                  props.close(false)
                  props.setDatos({ id: null, idvehiculo: null })
                } else {
                  props.setpopup(!props.popupview)
                }
              }}
            />
          </div>
        )}
      >
        <ToolbarItem
          widget='dxButton'
          location='after'
          options={{
            icon: 'close',
            onClick: () => {
              if (props.setfirma) {
                props.setfirma({
                  realizarfirma: false,
                  idexpediente: '',
                  idvehiculo: '',
                })
              }
              if (props.popupview === undefined || props.popupview == null ) {
                props.close(false)
                props.setDatos({ id: null, idvehiculo: null })
              } else {
                props.setpopup(!props.popupview)
              }
            },
          }}
        />
        <ScrollView>
          <div hidden={showPopUpFirma ? true : false}>
            <div hidden={showPopupEstadoFirma ? true : false}>
              <>
                {
                  <Box
                    direction='row'
                    width={'100%'}
                    align='center'
                    crossAlign='center'
                  >
                    <Item ratio={0} baseSize={'auto'}>
                      {(IsModuloAllowed(
                        userState!.user!,
                        Modules.FIRMADIGITAL,
                      ) ||
                        isAdmin(userState!.user!) ||
                        isEmployeeUser(userState!.user!)) && (
                        <Button
                          text={'Firmar plantilla'}
                          type='default'
                          onClick={() => {
                            setTexto('Proceso solicitud de firma')
                            setShowPopUpFirma(true)
                          }}
                        />
                      )}
                    </Item>
                    <Item ratio={0} baseSize={20}></Item>
                    <Item ratio={0} baseSize={'auto'}>
                      {(IsModuloAllowed(
                        userState!.user!,
                        Modules.FIRMADIGITAL,
                      ) ||
                        isAdmin(userState!.user!) ||
                        isEmployeeUser(userState!.user!)) && (
                        <Button
                          text={'Ver firmas'}
                          type='default'
                          onClick={() => {
                            setTexto('Estado de la solicitud')
                            setShowPopupEstadoFirma(true)
                          }}
                        />
                      )}
                    </Item>
                  </Box>
                }
              </>
              <div style={{ borderBottomStyle: 'solid' }}>
                Detalles expediente
              </div>
              <DetallesExpediente datos={props.datos} />
              <div style={{ borderBottomStyle: 'solid', marginTop: '1.5em' }}>
                Detalles costes
              </div>
              <DetallesCostes datos={props.datos} />
              <div style={{ borderBottomStyle: 'solid', marginTop: '1.5em' }}>
                Detalles del vehículo
              </div>
              <DetallesVehiculo datos={props.datos} />
              <div style={{ height: '20em', marginTop: '1.5em' }}>
                <TabsVehiculo
                  datos={props.datos}
                  estadofirma={estadoFirma}
                  realizarfirma={setShowPopUpFirma}
                  texto={setTexto}
                />
              </div>
            </div>
          </div>
          {(IsModuloAllowed(userState!.user!, Modules.FIRMADIGITAL) ||
            isAdmin(userState!.user!) ||
            isEmployeeUser(userState!.user!)) && (
            <FirmarExpediente
              verpopup={showPopUpFirma}
              closePopUp={setShowPopUpFirma}
              datos={props.datos}
              estadoFirma={estadoFirma}
              setEstadoFirma={setEstadoFirma}
              listaDocumentType={listaDocumentType}
              setListaDocumentType={setListaDocumentType}
              datosSujetos={datosSujetos}
              texto={setTexto}
            ></FirmarExpediente>
          )}
          {(IsModuloAllowed(userState!.user!, Modules.FIRMADIGITAL) ||
            isAdmin(userState!.user!) ||
            isEmployeeUser(userState!.user!)) && (
            <DatosPeticionEstado
              idexpediente={props.datos.id}
              cerrar={setShowPopupEstadoFirma}
              verpopup={showPopupEstadoFirma}
              texto={setTexto}
            ></DatosPeticionEstado>
          )}
        </ScrollView>
      </Popup>
      <LoadPanel
        shadingColor='rgba(192,192,192,0.9)'
        visible={showPopupState}
      />
    </>
  )
}
