import { DateTime } from 'luxon'
export function dateAString(fecha: Date | null): string {
  if (fecha  != null ) {
    let año = fecha!.getFullYear()
    let mes =
      fecha!.getMonth() + 1 > 9
        ? fecha!.getMonth() + 1
        : `0${fecha!.getMonth() + 1}`
    let dia = fecha!.getDate() > 9 ? fecha!.getDate() : `0${fecha!.getDate()}`
    return `${año}-${mes}-${dia}`
  } else {
    return ''
  }
}

export function formatoFecha(rowData: any) {
  let fecha = DateTime.fromISO(rowData.concat('Z'))
    .setZone('Europe/Berlin')
    .toLocaleString({
      ...DateTime.DATE_SHORT,
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

  return fecha
}
export function formatoFechaHistoricoEstado(rowData: any) {
  let fecha = DateTime.fromISO(rowData)
    .setZone('Europe/Berlin')
    .toLocaleString({
      ...DateTime.DATE_SHORT,
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

  return fecha
}
