import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from 'file-saver-es';

const OnExporting = (e: any) => {
    if (e.selectedRowsOnly) {
        ExportSelectedRows(e);
    } else {
        ExportAllRows(e);
    };
};

const ExportAllRows = (e: any) => {
  const { component } = e;
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');
  
  exportDataGrid({
      component: component,
      worksheet: worksheet,
      autoFilterEnabled: true,
  }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'TotalDeExpedientes.xlsx',
      );
      });
  });
  
  // Verifica si e.cancel está definido antes de asignar
  if ('cancel' in e) {
      e.cancel = true;
  };
};

const ExportSelectedRows = (e: any) => {
  if (!e.component) return;

  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');    

  // Obtiene las columnas visibles
  const columns = e.component.getVisibleColumns();

  // Agrega encabezados de columnas
  const headerRow: { header: any; key: any }[] = [];
  columns.forEach((column: any) => {
    headerRow.push({
      header: column.caption,
      key: column.dataField,
    });
  });

  worksheet.columns = headerRow;

  // Obtiene las claves seleccionadas
  const selectedRowKeys = e.component.getSelectedRowKeys();

  if (selectedRowKeys && selectedRowKeys.length > 0) {
    // Obtiene datos de la fuente de datos
    const dataSource = e.component.getDataSource()?.items() || [];
    
    // Filtra las filas seleccionadas
    const selectedRows = dataSource.filter((row: any) =>
      selectedRowKeys.includes(row['IdKey'])
    );
    
    if (columns.length > 0) {
      // Agrega datos
      selectedRows.forEach((row: any) => {
        const rowData: any = {};
        columns.forEach((column: any) => {
          const cellValue = row[column.dataField];
          if (cellValue !== undefined && cellValue  != null ) {
            rowData[column.dataField] = cellValue;
          }
        });
        worksheet.addRow(rowData);
      });
    };

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: e.autoFilterEnabled,
    });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'ExpedientesSeleccionados.xlsx',
        );
      });
    
    // Verifica si e.cancel está definido antes de asignar
    if ('cancel' in e) {
      e.cancel = true;
    };
  };
};

export { OnExporting };