import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Popup } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterPanel,
  HeaderFilter,
  LoadPanel,
  SearchPanel,
  StateStoring,
} from 'devextreme-react/data-grid'
import { getIdocCarConfigList } from '../service/idocCarConfigService'
import {
  addIdocCarConfig,
  addIdocCarConfigAll,
  clearIdocCarConfig,
} from '../redux/idocCarConfigActions'
import Button from 'devextreme-react/button'
import { ToolbarItem } from 'devextreme-react/popup'
import { custom } from 'devextreme/ui/dialog'
import IdocCarConfigFormPage from './idocCarConfigFormPage'
import { OnExporting } from 'src/utils/export/ExportExcel'
import { checkScreenSize } from 'src/utils/sharedUitls'

function IdocCarConfigPage(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const idocCarConfigState = useSelector(
    (state: RootState) => state.idocCarConfig,
  )
  const [rowFocused, setRowFocused] = useState('')
  const [showForm] = useState(false)
  const [showFormIdocCarConfig, setShowFormIdocCarConfig] = useState(false)
  const [isCreatingForm, setIsCreatingForm] = useState(false)
  const [isEditingForm, setIsEditingForm] = useState(false)

  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const dataGridIdocCarConfigRef: MutableRefObject<any> = useRef(null)
  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setShowFormIdocCarConfig(!showForm)
        setShowFormIdocCarConfig(false)
        setIsCreatingForm(false)
        setIsEditingForm(false)
      }
    })

    async function fetchData() {
      try {
        dispatch(
          addIdocCarConfigAll(await getIdocCarConfigList(userState!.user!)),
        )
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowFormIdocCarConfig(!showForm)
          setShowFormIdocCarConfig(false)
          setIsCreatingForm(false)
          setIsEditingForm(false)
        }
      })
    }
  }, [dispatch, showForm, userState])

  const renderGridCell = (data: any) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon='edit'
          type='normal'
          stylingMode='contained'
          onClick={(e) => {
            if (data.key) {
              setRowFocused(data.key)
              dispatch(addIdocCarConfig(data.data))
            }
            setIsEditingForm(true)
            setShowFormIdocCarConfig(true)
          }}
        />
        <Button
          icon='trash'
          style={{ marginLeft: '2%' }}
          type='normal'
          stylingMode='contained'
          onClick={async (e) => {
            setShowFormIdocCarConfig(false)
            const confirmDialog = custom({
              messageHtml: '¿Desear eliminar este registro?',

              buttons: [
                {
                  text: 'Continuar',
                  onClick: async (e) => {
                    dispatch(addIdocCarConfigAll(undefined))
                    dispatch(
                      addIdocCarConfigAll(
                        await getIdocCarConfigList(userState!.user!),
                      ),
                    )
                    confirmDialog.hide()
                  },
                },
                {
                  text: 'Cancelar',
                  elementAttr: {
                    id: 'usuarioCancelButton',
                    class: 'usuarioCancelButtonClass',
                  },
                  onClick: (e) => confirmDialog.hide(),
                },
              ],
            })
            confirmDialog.show()
          }}
        />
      </div>
    )
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear',
          icon: 'add',
          // visible: false,
          onClick: (_e: any) => {
            dispatch(clearIdocCarConfig(undefined))
            setShowFormIdocCarConfig(true)
            setIsCreatingForm(true)
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            dispatch(
              addIdocCarConfigAll(await getIdocCarConfigList(userState!.user!)),
            )
          },
        },
      },
    )
  }

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>iDocCar - configuración</h3>
        <div>
          <DataGrid
            dataSource={idocCarConfigState.idocCarConfigAll}
            keyExpr='Id'
            allowColumnResizing={true}
            ref={dataGridIdocCarConfigRef}
            style={{ width: '100%', minHeight: '250px' }}
            height='calc(110vh - 8rem - 46px)'
            allowColumnReordering
            remoteOperations
            repaintChangesOnly
            rowAlternationEnabled
            showBorders
            showRowLines
            paging={{ pageSize: 5 }}
            columnChooser={{ enabled: true }}
            columnHidingEnabled={!checkScreenSize()}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            headerFilter={{ visible: false }}
            loadPanel={{ enabled: true }}
            sorting={{ mode: 'multiple', showSortIndexes: true }}
            export={{
              allowExportSelectedData: false,
              enabled: true,
            }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event)
            }}
            onExporting={OnExporting}
          >
            <Export enabled={true} />
            <SearchPanel visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <LoadPanel enabled />
            <StateStoring
              enabled={true}
              type='localStorage'
              storageKey='idoccarConfig'
            />
            <ColumnFixing enabled={true} />
            <HeaderFilter visible={true} />
            <Column
              dataField=''
              minWidth={'100'}
              width={'6%'}
              cellRender={renderGridCell}
            />

            <Column dataField='Id' caption='Registro' visible={false} />
            <Column
              dataField='IdCompany'
              caption='Nº Compañía'
              visible={false}
            />
            <Column dataField='DossierOriginType' caption='Tipo' />
            <Column dataField='Url' caption='Url' />
            <Column dataField='Username' caption='Usuario' />
            <Column dataField='Password' caption='Contraseña' />
          </DataGrid>
        </div>
        <Popup
          visible={showFormIdocCarConfig}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={'IdocCar'}
          width={'65%'}
          height={'65%'}
          showCloseButton={false}
        >
          <ToolbarItem
            widget='dxButton'
            toolbar='top'
            location='after'
            options={{
              text: '',
              icon: 'close',
              onClick: () => {
                if (isChangedState) {
                  let confirmDialog = custom({
                    title: '',

                    messageHtml:
                      '<b>Para conservar los cambios antes pulsar Guardar, Si continúa perderá todos los datos cambiados</b>',
                    buttons: [
                      {
                        text: 'Continuar',
                        onClick: (e) => {
                          setShowFormIdocCarConfig(false)
                          setIsCreatingForm(false)
                          setIsEditingForm(false)
                          confirmDialog.hide()
                        },
                      },
                      {
                        text: 'Cancelar',
                        onClick: (e) => {
                          confirmDialog.hide()
                        },
                      },
                    ],
                  })
                  confirmDialog.show().then((dialogResult: any) => {
                    console.log(dialogResult)
                  })
                } else {
                  setShowFormIdocCarConfig(!showForm)
                  setShowFormIdocCarConfig(false)
                  setIsCreatingForm(false)
                  setIsEditingForm(false)
                }
              },
            }}
          />
          <ScrollView width='100%' height='100%'>
            <IdocCarConfigFormPage
              row={rowFocused}
              setShowFormIdocCarConfig={setShowFormIdocCarConfig}
              showFormIdocCarConfig={showFormIdocCarConfig}
              isCreatingForm={isCreatingForm}
              setIsCreatingForm={setIsCreatingForm}
              isEditingForm={isEditingForm}
              setIsEditingForm={setIsEditingForm}
            ></IdocCarConfigFormPage>
          </ScrollView>
        </Popup>
      </div>
    </div>
  )
}

export { IdocCarConfigPage }
