import { JsonObject, JsonProperty } from 'json2typescript';
import { ModulesClaims } from './rolePermissionsModel';

@JsonObject('')
class UserPermissionsModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('UserName', String)
  UserName: string = '';

  @JsonProperty('Name', String)
  Name: string = '';

  @JsonProperty('CompanyId', Number)
  CompanyId: number = 0;

  @JsonProperty('CompanyName', String)
  CompanyName: string = '';

  @JsonProperty('Role', String)
  Role: string = '';

  @JsonProperty('ModulesClaims', Array<ModulesClaims>)
  ModulesClaims: ModulesClaims[] = [];
};

export { UserPermissionsModel };