import { Box, Button, Form, Popup } from 'devextreme-react';
import React, { ReactElement, useEffect } from 'react';
import { ToolbarItem } from 'devextreme-react/popup';
import { Item } from 'devextreme-react/box';
import { GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { NotifyType, showToast } from 'src/utils/sharedUitls';
import { RestorePassword } from '../service/authService';
import { useDispatch } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';

interface Props {
  verpopup: boolean;
  close: Function;
}

function RecoverPassword({
  verpopup,
  close,
}: Props): ReactElement {
  let nameUser = '';
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        close();
      }
    })

    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          close();
        }
      })
    }
  }, [])
  return (
    <Popup
      visible={verpopup}
      hideOnOutsideClick={false}
      showCloseButton={false}
      height='60%'
      width='30%'
      title={'Recuperar contraseña'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: () => close(),
        }}
      />
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        height={150}
      >
        <Item ratio={0} baseSize={290}>
          <img src={require('src/assets/newlogo.png')}></img>
        </Item>
      </Box>
      <Box direction='row' align='center' crossAlign='center' width={'100%'}>
        <Item ratio={0} baseSize={390}>
          <Form
            showValidationSummary={false}
            validationGroup='recuperarPassDate'
          >
            <GroupItem colCount={1}>
              <SimpleItem
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'text',
                  hint: '',
                  placeholder: 'Usuario*',
                  onValueChanged: ({ value }: any) => {
                    if (value !== undefined && value  != null ) {
                      nameUser = value.trim();
                    }
                  },
                }}
              >
                <RequiredRule
                  message={'Nombre de usuario obligatorio'}
                ></RequiredRule>
              </SimpleItem>
            </GroupItem>
            <GroupItem colCount={1}>
              <Box direction='row' align='end' crossAlign='end' width={'auto'}>
                <Item ratio={0} baseSize={160}>
                  <Button
                    text={'Enviar'}
                    type='default'
                    validationGroup={'recuperarPassDate'}
                    useSubmitBehavior={true}
                    onClick={(e: any) => {
                      e.event.preventDefault()
                      let result = e.validationGroup.validate();
                      if (result.isValid) {
                        dispatch(addShowLoader(true));
                        RestorePassword(nameUser);
                        dispatch(addShowLoader(false));
                      } else {
                        showToast(
                          'El campo usuario es obligatorio',
                          NotifyType.error, 5000
                        );
                      };
                    }}
                  />
                </Item>
              </Box>
            </GroupItem>
          </Form>
        </Item>
      </Box>
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        height={300}
      >
        <Item ratio={0} baseSize={'auto'}>
          <p>
            Copyright ©{' '}
            <a href='https://www.ruano.com/' className='links'>
              {' '}
              Ruano Informática, S.L.
            </a>{' '}
            {new Date().getFullYear()};
          </p>
        </Item>
      </Box>
    </Popup>
  )
}

export { RecoverPassword };
