import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('')
class usuarioModelGrid {
  @JsonProperty('Id', Number, 0)
  Id: number = 0;

  @JsonProperty('NombreUsuario', String)
  NombreUsuario: string = '';

  @JsonProperty('Email', String)
  Email: string = '';

  @JsonProperty('IdentificationNumber', String)
  IdentificationNumber: string = '';

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('Apellidos', String)
  Apellidos: string = '';

  @JsonProperty('Telefono', String)
  Telefono: string = '';

  @JsonProperty('TipoUsuario', String)
  TipoUsuario: string = '';
};

export { usuarioModelGrid };
