import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';

@JsonObject('DocumentoModel')
class DocumentoModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('NombreArchivoCliente', String)
  NombreArchivoCliente: string = '';

  @JsonProperty('NombreArchivoOriginal', String)
  NombreArchivoOriginal: string = '';

  @JsonProperty('Observaciones', String)
  Observaciones: string = '';
}

@JsonObject('')
export class DocumentoCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [DocumentoModel])
  Datos: DocumentoModel[] = [];
}
