import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DireccionModels')
export class DireccionModels {
  @JsonProperty('FullAddress', String, '')
  FullAddress: string = ''
  @JsonProperty('StreetType', String, '')
  StreetType: string = ''

  @JsonProperty('StreetName', String, '')
  StreetName: string = ''
  @JsonProperty('Km', String, '')
  Km: string = ''
  @JsonProperty('Hm', String, '')
  Hm: string = ''
  @JsonProperty('Number', String, '')
  Number: string = ''
  @JsonProperty('Portal', String, '')
  Portal: string = ''
  @JsonProperty('Stairs', String, '')
  Stairs: string = ''
  @JsonProperty('Floor', String, '')
  Floor: string = ''
  @JsonProperty('Door', String, '')
  Door: string = ''
  @JsonProperty('Letter', String, '')
  Letter: string = ''
  @JsonProperty('ZipCode', String, '')
  ZipCode: string = ''
  @JsonProperty('City', String, '')
  City: string = ''
  @JsonProperty('PhoneNumber', String, '')
  PhoneNumber: string = ''
  @JsonProperty('Email', String, '')
  Email: string = ''
}
