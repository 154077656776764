import { Box, Button, DataGrid, Template } from 'devextreme-react'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import React, { MutableRefObject, useRef, useState } from 'react'
import { NotifyType, checkScreenSize, showToast } from 'src/utils/sharedUitls'
import { NotificacionSolicitudModel } from '../models/notificacionSolicitudModel'
import { Item } from 'devextreme-react/box'
import { useQuery, useQueryClient } from 'react-query'
import {
  getNotificacionesSolicitud,
  postNotificacionesSolicitud,
} from '../services/stateHistorySignedServices'
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'
import { ReenviarNotificacionModel } from '../models/reenviarNotificacionModel'
import FormNotificacionSection from './formNotificacionSection'

interface Props {
  idsolicitud: number
  cerrar: Function
  textopoup: Function
}

export default function NotificacionesFirmaSection({
  idsolicitud,
  cerrar,
  textopoup,
}: Props) {
  const dispatch = useDispatch()
  const [listNotificaciones, setListNotificaciones] = useState(
    Array<NotificacionSolicitudModel>,
  )
  const notificacionSolicitudGridRef: MutableRefObject<any> = useRef()

  const queryClient = useQueryClient()

  const [dataForm, setDataForm] = useState(new ReenviarNotificacionModel())

  const [formReenviar, setFormReenviar] = useState(false)

  const [sendReenviar, setSendReenviar] = useState(false)

  useQuery(
    ['NotificacionesSolicitudState', idsolicitud],
    getNotificacionesSolicitud,
    {
      onSuccess: (data: Array<NotificacionSolicitudModel> | []) => {
        setListNotificaciones(data)
        dispatch(addShowLoader(false))
      },

      onError: () => {
        dispatch(addShowLoader(false))
      },

      refetchOnWindowFocus: false,
    },
  )

  useQuery(
    ['postNotificacionesSolicitud', idsolicitud, dataForm, sendReenviar],
    postNotificacionesSolicitud,
    {
      onSuccess: (data: boolean) => {
        if (data) {
          textopoup('Estado Solicitud')
          cerrar(false)
        }

        setSendReenviar(false)
        dispatch(addShowLoader(false))
      },

      onError: () => {
        dispatch(addShowLoader(false))
        setSendReenviar(false)
      },

      refetchOnWindowFocus: false,
    },
  )

  const onHanleOnToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: () => {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        name: 'refrescar',
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async () => {
            notificacionSolicitudGridRef.current.instance.refresh()
          },
        },
      },
    )
  }

  function reenviarNotificacionRender(data: any): any {
    return (
      <Button
        text='Reenviar'
        type='default'
        onClick={() => {
          setDataForm({
            ...dataForm,
            sendNotificationByEmail: data.data.email.length > 0 ? true : false,
            sendNotificationBySms:
              data.data.phoneNumber.length > 0 ? true : false,
            email: data.data.email,
            phoneNumber: data.data.phoneNumber,
          })

          textopoup('Formulario reenvio notificación')

          setFormReenviar(true)
        }}
      />
    )
  }

  async function añadirReenvio() {
    queryClient.invalidateQueries('postNotificacionesSolicitud')
  }

  return (
    <Box direction='row' width={'100%'}>
      <Item ratio={0} baseSize={'auto'}>
        {!formReenviar && (
          <DataGrid
            id='notificacionesSolicitudGrid'
            keyExpr='id'
            dataSource={listNotificaciones}
            style={{ width: '100%', minHeight: '250px' }}
            height={680}
            allowColumnReordering
            allowColumnResizing
            columnAutoWidth
            remoteOperations
            repaintChangesOnly
            rowAlternationEnabled
            showBorders
            showRowLines
            columnChooser={{ enabled: true }}
            columnHidingEnabled={!checkScreenSize()}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            headerFilter={{ visible: false }}
            loadPanel={{ enabled: true }}
            paging={{ enabled: false }}
            sorting={{ mode: 'multiple', showSortIndexes: false }}
            export={{
              allowExportSelectedData: false,
              enabled: true,
            }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            selection={{
              mode: 'single',
            }}
            scrolling={{
              mode: 'standard',
              scrollByContent: true,
              scrollByThumb: true,
              showScrollbar: 'always',
            }}
            onToolbarPreparing={(event: any) => {
              onHanleOnToolbarPreparing(event)
            }}
          >
            <StateStoring
              enabled={true}
              type={'localStorage'}
              storageKey={'notificacionesSolicitudState'}
            />

            <Column
              width={'39%'}
              type='buttons'
              cellRender={(cellRenderData: any) =>
                reenviarNotificacionRender(cellRenderData)
              }
            />

            <Column
              width={'50%'}
              caption={'Email'}
              dataField={'email'}
              alignment='left'
            />

            <Column
              width={'50%'}
              caption={'Número De Teléfono'}
              dataField={'phoneNumber'}
              alignment='left'
            />
          </DataGrid>
        )}
      </Item>
      <Item ratio={0} baseSize={'auto'}>
        {formReenviar && (
          <FormNotificacionSection
            datosformulario={dataForm}
            setdatosformulario={setDataForm}
            enviarformulario={añadirReenvio}
            reenviar={setSendReenviar}
          ></FormNotificacionSection>
        )}
      </Item>
    </Box>
  )
}
