import {
  ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL,
  ADD_IDOCCAR_DOSSIER_STATUS_RELATION,
  CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION,
  IdocCarDossierStatusRelationActionsTypes,
  IdocCarDossierStatusRelationState,
} from './idocCarRelacionesEstadosExpedientesType'

const initialState: IdocCarDossierStatusRelationState = {
  idocCarDossierStatusRelation: undefined,
  idocCarDossierStatusRelationAll: undefined,
}

export const idocCarDossierStatusRelationReducer = (
  state = initialState,
  action: IdocCarDossierStatusRelationActionsTypes,
): IdocCarDossierStatusRelationState => {
  switch (action.type) {
    case ADD_IDOCCAR_DOSSIER_STATUS_RELATION: {
      return { ...state, idocCarDossierStatusRelation: action.payload }
    }
    case ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL: {
      return { ...state, idocCarDossierStatusRelationAll: action.payload }
    }
    case CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION: {
      return { ...state, idocCarDossierStatusRelation: action.payload }
    }
    default:
      return state
  }
}
