import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';
import { usuarioModelGrid } from './usuarioModelGrid';

@JsonObject('UsuarioCampoDatoModel')
class UsuarioCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [usuarioModelGrid])
  Datos: usuarioModelGrid[] = [];
};

export { UsuarioCampoDatoModel };