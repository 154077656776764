import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { SujetoModel } from './sujetoModel'

@JsonObject('SujetosCampoDatoModel')
export class SujetosCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore] || [])
  Campos: ColumnModelCore[] = []
  @JsonProperty('Datos', [SujetoModel] || [])
  Datos: SujetoModel[] = []
}
