export const SHOW_LOADER = '@utils/SHOW_LOADER';

export interface configState {
  visibleLoader: boolean;
}

interface AddShowLoader {
  type: typeof SHOW_LOADER;
  payload: boolean;
}

export type ConfigActionsTypes = AddShowLoader;
