import { ConfiguracionEnvioEmailModel } from 'src/models/company/configuracionEnvioEmailModel'

export interface EmailState {
  email:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined
}

export const ADD_EMAIL = 'ADD_EMAIL'

export const CLEAR_EMAIL = '@todo/CLEAR_EMAIL'

interface AddEmailRequest {
  type: typeof ADD_EMAIL
  payload: Partial<ConfiguracionEnvioEmailModel> | undefined
}
interface ClearEmailRequest {
  type: typeof CLEAR_EMAIL
  payload: ConfiguracionEnvioEmailModel | undefined
}

export type EmailActionsTypes = AddEmailRequest | ClearEmailRequest
