import { Box } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React, { useEffect, useState } from 'react'
import BuscadorFormSection from '../sections/BuscadorFormSection/buscadorFormSection'
import DatosEmpresaSection from '../sections/DatosEmpresaSection/DatosEmpresaSection'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'

export default function ConsultaEmpresaPage() {
  const companyDataState = useSelector(
    (state: RootState) => state.Informa.company,
  )
  const queryClient = useQueryClient()

  const [busqueda, setBusqueda] = useState('')

  function actualizarBusqueda(busqueda: string) {
    setBusqueda(busqueda)
  }

  useEffect(() => {
    queryClient.invalidateQueries('Company')
  }, [companyDataState])
  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0, height: 100 }}>Consulta Empresa</h3>
        <Box direction='row' width='100%' align='center' crossAlign='center'>
          <Item ratio={0} baseSize={'40%'}>
            <BuscadorFormSection
              actualizarbusqueda={actualizarBusqueda}
            ></BuscadorFormSection>
          </Item>
        </Box>
        <Box height={35}>
          <Item />
        </Box>
        <Box direction='row' width='100%' align='center' crossAlign='center'>
          <Item ratio={0} baseSize={'80%'}>
            <DatosEmpresaSection busqueda={busqueda}></DatosEmpresaSection>
          </Item>
        </Box>
        <Box height={165}>
          <Item />
        </Box>
      </div>
    </div>
  )
}
