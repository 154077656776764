import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'src/redux/combineReducers'
import { PermissionsByPageUsers } from './pages/PermissionsByPageUsers'
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil'

function PermissionsByUsersIndex() {
  const userState = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  return (
    <>
      {!userState!.isLoggedIn && navigate('/Login')}
      {(isSuperAdmin(userState!.user!) || isAdmin(userState!.user!)) && (
        <PermissionsByPageUsers />
      )}
    </>
  )
}

export { PermissionsByUsersIndex }
