import React from 'react'
import { RouteObject } from 'react-router-dom'
import { AccessOnlyCalculatorPage } from './pages/accesOnlyCalculatorPage/accessOnlyCalculatorPage'
import { CompaniesPage } from './pages/companyAdminPages/companyPage/pages/CompaniesPage'
import { CompanyFormPage } from './pages/companyAdminPages/companyPage/pages/CompanyFormPage'
import EscriturasPage from './pages/expedientePage/escrituras/EscriturasPage'
import { Login } from './pages/loginPage/login'
import NotFound from './components/notFound/notFound'
import { ProfilePage } from './pages/adminPages/profilePages/pages/ProfilePage'
import { PlantillasPage } from './pages/companyAdminPages/templatesAdmin/Index'
import { UsuariosPage } from './pages/usuariosPage/UsuariosPage'
import { VehiculosPage } from './pages/expedientePage/vehiculos/VehiculosPage'
import { IdocCarOrganizationalUnitPage } from './pages/companyAdminPages/idocCarOrganizationalUnitPage/pages/idocCarOrganizationalUnitPage'
import { IdocCarConfigPage } from './pages/companyAdminPages/idocCarConfigPage/pages/idocCarConfigPage'
import IdocCarEstadosExpedientesPage from './pages/companyAdminPages/idocCarStatesFiles/pages/idocCarEstadosExpedientesPage'
import { IdocCarRelationshipsStatesFilesPage } from './pages/companyAdminPages/idocCarRelationshipsStatesFiles/pages/idocCarRelacionesEstadosExpedientesPage'
import MicrodatosBastidores from './pages/microdatos/consultaBastidores'
import { RequestsPage } from './pages/companyAdminPages/requestPage/RequestsPage'
import SaldoIndex from './pages/informa/saldoIndex'
import { HistoricoIndex } from './pages/informa/historicoIndex'
import { ConsultaEmpresaIndex } from './pages/informa/consultaEmpresaIndex'
import LogsIndex from './pages/logsPage/logsIndex'
import { ChangePassword } from './pages/loginPage/components/changePassword'
import DocumentTypeIndex from './pages/firmadigital/DocumentType/pages/documentTypePage'
import { PermissionsRoleIndex } from './pages/permissions/PermissionsRoleIndex'
import { PermissionsByUsersIndex } from './pages/permissions/PermissionsByUsersIndex'
import { DossierTypePage } from './pages/dossierType/pages/DossierTypePage'
import { CompaniesStorageSize } from './pages/companiesStorageSize/pages/CompaniesStorageSize'
import RequestsOptions from './pages/companyAdminPages/requestPage/pages/RequestsOptions'
import AlmacenDeTasas from './pages/tasas/components/AlmacenDeTasas'
import { ImportarTasas } from './pages/tasas/components/ImportarTasas'
import RemesasInformes from './pages/expedientePage/vehiculos/RemesasInformes'
import MiRGPD from './pages/rgpd/pages/MiRGPD'
import ConsentimientosPage from './pages/rgpd/pages/ConsentimientosPage'

export const routes: RouteObject[] = [
  {
    element: <RequestsPage />,
    path: '/',
  },
  {
    element: <Login />,
    path: '/Login',
  },
  {
    element: <ChangePassword />,
    path: '/ResetPassword',
  },
  {
    element: <AccessOnlyCalculatorPage />,
    path: '/accessOnlyCalculatorPage',
  },
  {
    element: <CompaniesPage />,
    path: '/companies',
  },
  {
    element: <CompanyFormPage />,
    path: '/editCompany',
  },
  {
    element: <EscriturasPage />,
    path: '/expedientes/escrituras',
  },
  {
    element: <ProfilePage />,
    path: '/perfiles',
  },
  {
    element: <PlantillasPage />,
    path: '/plantillas',
  },
  {
    element: <UsuariosPage />,
    path: '/usuarios',
  },
  {
    element: <VehiculosPage />,
    path: '/expedientes/vehiculos',
  },
  {
    element: <RequestsPage />,
    path: '/solicitudes/list',
  },
  {
    element: <IdocCarOrganizationalUnitPage />,
    path: '/idoccar/configuration/organizational/unit',
  },

  {
    element: <IdocCarConfigPage />,
    path: '/idoccar/configuration',
  },
  {
    element: <IdocCarEstadosExpedientesPage />,
    path: '/idoccar/dossier/status',
  },
  {
    element: <IdocCarRelationshipsStatesFilesPage />,
    path: '/idoccar/dossier/status/relation',
  },
  {
    element: <MicrodatosBastidores />,
    path: '/microdatos/consulta-bastidores',
  },
  {
    element: <CompaniesStorageSize />,
    path: '/almacenamiento',
  },
  {
    element: <RequestsPage />,
    path: '/solicitudes/list',
  },
  {
    element: <RequestsOptions />,
    path: '/solicitudes/list/opcionesSolicitudes',
  },
  {
    element: <RequestsOptions />,
    path: '/solicitudes/crearSolicitud',
  },
  {
    element: <RemesasInformes />,
    path: '/solicitudes/remesaInformes',
  },
  {
    element: <SaldoIndex />,
    path: '/saldo',
  },
  {
    element: <LogsIndex />,
    path: '/logs/company',
  },
  {
    element: <DossierTypePage />,
    path: '/solicitudesTipos',
  },
  {
    element: <HistoricoIndex />,
    path: '/historicoconsultas',
  },
  {
    element: <ConsultaEmpresaIndex />,
    path: '/consultaempresa',
  },
  {
    element: <DocumentTypeIndex />,
    path: '/firmadigital/documentos',
  },
  {
    element: <PermissionsRoleIndex />,
    path: '/permisos/rol',
  },
  {
    element: <PermissionsByUsersIndex />,
    path: '/permisos/usuario',
  },
  {
    element: <AlmacenDeTasas />,
    path: '/tasas/almacenTasas',
  },
  {
    element: (
      <ImportarTasas
        closePopup={() => {
          return
        }}
      />
    ),
    path: '/tasas/importarTasas',
  },
  {
    element: <MiRGPD />,
    path: '/mirgpd',
  },
  {
    element: <ConsentimientosPage />,
    path: '/mirgpd/consentimientos',
  },
  {
    element: <NotFound />,
    path: '*',
  },
  {
    element: <ConsentimientosPage />,
    path: '/mirgpd/consentimientos',
  },
]
