import { Box, Button } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React from 'react'
import CookieConsent from 'react-cookie-consent'

interface Props {
  actualizarestado: Function
}
export default function Cookies({ actualizarestado }: Props) {
  return (
    <Box direction='row' width={'100%'}>
      <Item ratio={0} baseSize={2000}>
        <CookieConsent
          disableStyles
          location='none'
          cookieName='politicacookie'
          ButtonComponent={() => {
            return (
              <Box
                direction='row'
                align='center'
                crossAlign='center'
                width={'100%'}
                height={60}
                className='Cookiesstyle'
              >
                <Item ratio={0} baseSize={480}>
                  <p style={{ color: 'white', fontSize: 16 }}>
                    {' '}
                    Este sitio usa cookies para más información visite estos
                    enlaces:
                  </p>
                </Item>

                <Item ratio={0} baseSize={148}>
                  <a
                    href='https://www.elgestor.com/mas-informacion-sobre-las-cookies/'
                    className='links'
                    style={{ fontSize: 16 }}
                  >
                    Política de cookies
                  </a>
                </Item>

                <Item ratio={0} baseSize={240}>
                  <a
                    href='https://www.elgestor.com/politica-privacidad/'
                    style={{ fontSize: 16 }}
                    className='links'
                  >
                    Política de privacidad
                  </a>
                </Item>

                <Item ratio={0} baseSize={120}>
                  <Button
                    text={'Aceptar'}
                    type='default'
                    onClick={(e: any) => {
                      e.event.preventDefault()
                      actualizarestado('true')
                    }}
                  />
                </Item>
                <Item baseSize={10}></Item>

                <Item ratio={0} baseSize={120}>
                  <Button
                    text={'Rechazar'}
                    type='danger'
                    onClick={(e: any) => {
                      e.event.preventDefault()
                      actualizarestado('false')
                    }}
                  />
                </Item>
              </Box>
            )
          }}
        ></CookieConsent>
      </Item>
    </Box>
  )
}
