import {
  CosteCampoDatoModel,
  DetalleCosteCampoDatoModel,
} from '../../models/costeModel'
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel'
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel'
import { AyuntamientoCampoDatoModel } from '../../models/escritura/ayuntamientoCampoDatoModel'
import { BienCampoDatoModel } from '../../models/escritura/bienCampoDatoModel'
import { BienesCampoDatoModel } from '../../models/escritura/bienesCampoDatoModel'
import { CatastroCampoDatoModel } from '../../models/escritura/catastroCampoDatoModel'
import { DefectoCampoDatoModel } from '../../models/escritura/defectoCampoDatoModel'
import { HaciendaCampoDatoModel } from '../../models/escritura/haciendaCampoDatoModel'
import { NotariaCampoDatoModel } from '../../models/escritura/notariaCampoDatoModel'
import { RegistroCampoDatoModel } from '../../models/escritura/registroCampoDatoModel'
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel'
import {
  ADD_AYTO,
  ADD_BIEN,
  ADD_BIENES,
  ADD_CATASTRO,
  ADD_COSTES,
  ADD_DEFECTO,
  ADD_DEFECTOS,
  ADD_DETALLE_COSTE,
  ADD_DETALLE_EXPEDIENTE,
  ADD_DOCUMENTOS,
  ADD_HACIENDA,
  ADD_NOTARIA,
  ADD_REGISTRO,
  ADD_REGISTROS,
  ADD_TAREAS_PENDIENTES,
  ExpEscrituraActionsTypes,
  ExpEscrituraState,
} from './escrituraType'

const initialState: ExpEscrituraState = {
  ayuntamiento: new AyuntamientoCampoDatoModel(),
  bien: new BienCampoDatoModel(),
  bienes: new BienesCampoDatoModel(),
  catastro: new CatastroCampoDatoModel(),
  costes: new CosteCampoDatoModel(),
  defecto: new DefectoCampoDatoModel(),
  defectos: new DefectoCampoDatoModel(),
  detalleCoste: new DetalleCosteCampoDatoModel(),
  detalleExpediente: new DetailExpedientFieldDataModel(),
  documentos: new DocumentoCampoDatoModel(),
  hacienda: new HaciendaCampoDatoModel(),
  notaria: new NotariaCampoDatoModel(),
  registro: new RegistroCampoDatoModel(),
  registros: new RegistroCampoDatoModel(),
  tareasPendientes: new TareaPendienteCampoDatoModel(),
}

export const expedienteEscrituraReducer = (
  state = initialState,
  action: ExpEscrituraActionsTypes,
): ExpEscrituraState => {
  switch (action.type) {
    case ADD_DETALLE_EXPEDIENTE: {
      return { ...state, detalleExpediente: action.payload }
    }
    case ADD_DETALLE_COSTE: {
      return { ...state, detalleCoste: action.payload }
    }
    case ADD_BIENES: {
      return { ...state, bienes: action.payload }
    }
    case ADD_COSTES: {
      return { ...state, costes: action.payload }
    }
    case ADD_TAREAS_PENDIENTES: {
      return { ...state, tareasPendientes: action.payload }
    }
    case ADD_DOCUMENTOS: {
      return { ...state, documentos: action.payload }
    }
    case ADD_BIEN: {
      return { ...state, bien: action.payload }
    }
    case ADD_NOTARIA: {
      return { ...state, notaria: action.payload }
    }
    case ADD_HACIENDA: {
      return { ...state, hacienda: action.payload }
    }
    case ADD_REGISTRO: {
      return { ...state, registro: action.payload }
    }
    case ADD_REGISTROS: {
      return { ...state, registros: action.payload }
    }
    case ADD_DEFECTO: {
      return { ...state, defecto: action.payload }
    }
    case ADD_DEFECTOS: {
      return { ...state, defectos: action.payload }
    }
    case ADD_AYTO: {
      return { ...state, ayuntamiento: action.payload }
    }
    case ADD_CATASTRO: {
      return { ...state, catastro: action.payload }
    }
    default:
      return state
  }
}
