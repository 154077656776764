import { darPaisBastidor } from "src/utils/PaisPorBastidor/sacarPaisBastidor";

export const sacarPaisTable = (bastidor: string) => {


    let pais = darPaisBastidor(bastidor);


    const foto = pais !== '' ? require("../../../pages/microdatos/imgs/" + pais+ ".jpg") : '';

      return (  
       {foto}
      )
    }