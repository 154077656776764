import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('')
class TemplateFieldsModel {
  // @JsonProperty('Id', Number)
  // Id: number = 0;

  // @JsonProperty('IdTemplate', Number)
  // IdTemplate: number = 0;

  // @JsonProperty('IdField', Number)
  // IdField: number = 0;

  // @JsonProperty('Name', String)
  // Name: string = '';

  // @JsonProperty('Visible', Boolean)
  // Visible: boolean = false;

  // @JsonProperty('Order', Number)
  // Order: number = 0;

  // @JsonProperty('Width', Number)
  // Width: number = 0;
  @JsonProperty('Id', Number)
  Id: number = 0
  @JsonProperty('IdPlantilla', Number)
  IdPlantilla: number = 0
  @JsonProperty('IdCampo', Number)
  IdCampo: number = 0
  @JsonProperty('Nombre', String)
  Nombre: string = ''
  @JsonProperty('Visible', Boolean)
  Visible: boolean = false
  @JsonProperty('Orden', Number)
  Orden: number = 0
  @JsonProperty('Ancho', Number)
  Ancho: number = 0
}

export { TemplateFieldsModel }
