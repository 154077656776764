import React, { useState } from 'react'
import { Button } from 'devextreme-react'
import { ImportarTasasForm } from './ImportarTasasForm'
import { ImportarTasasFile } from './ImportarTasasFile'
import { ImportarTasasProps } from '../interfaces/InformesVehiculosInterfaces'

const ImportarTasas: React.FC<ImportarTasasProps> = ({
  closePopup
}) => {
  const [showForm, setShowForm] = useState(false)
  const [showFile, setShowFile] = useState(false)
  const [buttonColor, setButtonColor] = useState('normal')

  const handleManualImport = () => {
    setShowForm(true)
    setShowFile(false)
    setButtonColor('manual')
  }

  const handleFileImport = () => {
    setShowFile(true)
    setShowForm(false)
    setButtonColor('file')
  }

  return (
    <div style={{ minHeight: '1200px', height: 'auto' }}>
      <h2 style={{ textAlign: 'center' }}>
        Importar Tasas
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10vh',
            gap: '5vh',
          }}
        >
          <Button
            text='Crear Manualmente'
            stylingMode='contained'
            type={buttonColor === 'manual' ? 'normal' : 'default'}
            style={
              buttonColor === 'manual'
                ? { border: '1px solid rgb(212,212,212)' }
                : {}
            }
            onClick={handleManualImport}
          />
          <Button
            text='Importar Archivo'
            stylingMode='contained'
            type={buttonColor === 'file' ? 'normal' : 'default'}
            style={
              buttonColor === 'file'
                ? { border: '1px solid rgb(212,212,212)' }
                : {}
            }
            onClick={handleFileImport}
          />
        </div>
        {showForm && (
          <ImportarTasasForm
            closePopup={closePopup}
          />
        )}
        {showFile && (
          <ImportarTasasFile
            closePopup={closePopup}
          />
        )}
      </h2>
    </div>
  )
}

export { ImportarTasas }
