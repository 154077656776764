import { Box, Button, Form } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { SimpleItem } from 'devextreme-react/form'
import React, { useMemo } from 'react'
import { ModulesClaims } from 'src/pages/permissions/models/rolePermissionsModel'
import { UseMutationResult } from 'react-query'
import { ClaimsUpdateModel } from '../models/claimsUpdateModel'

interface Props {
  permisosModulo: ClaimsUpdateModel
  setpermisomodulo: Function
  actualizarPermisos: UseMutationResult<void, unknown, boolean, unknown>
  modulo: ModulesClaims
  todospermisos: ClaimsUpdateModel[]
}

export default function FormListModulesSection({
  permisosModulo,
  setpermisomodulo,
  actualizarPermisos,
  modulo,
  todospermisos,
}: Props) {
  const renderFormRol = useMemo(() => {
    return (
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'100%'}
        style={{
          position: 'relative',
          left: '12px',
          justifyContent: 'center !important',
          alignItems: 'center !important',
        }}
      >
        <Item ratio={0} baseSize={80}>
          <Form>
            <SimpleItem
              label={{ text: 'Ver' }}
              editorType={'dxCheckBox'}
              cssClass={
                modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'View' && permiso.Editable,
                ) === undefined && 'checkboxsinpermisos'
              }
              editorOptions={{
                disabled:
                  modulo.Claims.find(
                    (permiso) =>
                      permiso.Permission === 'View' && permiso.Editable,
                  ) !== undefined
                    ? false
                    : true,
                value: modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'View' && permiso.IsGranted,
                )
                  ? true
                  : false,
                mode: 'text',
                onValueChanged: (e: any) => {
                  if (e.event) {
                    setpermisomodulo({
                      ...permisosModulo,
                      ClaimValue: 'View',
                      ClaimName: modulo.ModuleKey,
                    })
                    actualizarPermisos.mutate(e.value)
                  }
                },
              }}
            ></SimpleItem>
          </Form>
        </Item>
        <Item ratio={0} baseSize={93}>
          <Form>
            <SimpleItem
              label={{ text: 'Leer' }}
              editorType={'dxCheckBox'}
              cssClass={
                modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Read' && permiso.Editable,
                ) === undefined && 'checkboxsinpermisos'
              }
              editorOptions={{
                disabled:
                  modulo.Claims.find(
                    (permiso) =>
                      permiso.Permission === 'Read' && permiso.Editable,
                  ) !== undefined
                    ? false
                    : true,
                value: modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Read' && permiso.IsGranted,
                )
                  ? true
                  : false,
                mode: 'text',
                onValueChanged: (e: any) => {
                  if (e.event) {
                    setpermisomodulo({
                      ...permisosModulo,
                      ClaimValue: 'Read',
                      ClaimName: modulo.ModuleKey,
                    })
                    actualizarPermisos.mutate(e.value)
                  }
                },
              }}
            ></SimpleItem>
          </Form>
        </Item>
        <Item ratio={0} baseSize={93}>
          <Form>
            <SimpleItem
              label={{ text: 'Editar' }}
              editorType={'dxCheckBox'}
              cssClass={
                modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Edit' && permiso.Editable,
                ) === undefined && 'checkboxsinpermisos'
              }
              editorOptions={{
                disabled:
                  modulo.Claims.find(
                    (permiso) =>
                      permiso.Permission === 'Edit' && permiso.Editable,
                  ) !== undefined
                    ? false
                    : true,
                value: modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Edit' && permiso.IsGranted,
                )
                  ? true
                  : false,
                mode: 'text',
                onValueChanged: (e: any) => {
                  if (e.event) {
                    setpermisomodulo({
                      ...permisosModulo,
                      ClaimValue: 'Edit',
                      ClaimName: modulo.ModuleKey,
                    })
                    actualizarPermisos.mutate(e.value)
                  }
                },
              }}
            ></SimpleItem>
          </Form>
        </Item>
        <Item ratio={0} baseSize={93}>
          <Form>
            <SimpleItem
              label={{ text: 'Crear' }}
              editorType={'dxCheckBox'}
              cssClass={
                modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Create' && permiso.Editable,
                ) === undefined && 'checkboxsinpermisos'
              }
              editorOptions={{
                disabled:
                  modulo.Claims.find(
                    (permiso) =>
                      permiso.Permission === 'Create' && permiso.Editable,
                  ) !== undefined
                    ? false
                    : true,
                value: modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Create' && permiso.IsGranted,
                )
                  ? true
                  : false,
                mode: 'text',
                onValueChanged: (e: any) => {
                  if (e.event) {
                    setpermisomodulo({
                      ...permisosModulo,
                      ClaimValue: 'Create',
                      ClaimName: modulo.ModuleKey,
                    })
                    actualizarPermisos.mutate(e.value)
                  }
                },
              }}
            ></SimpleItem>
          </Form>
        </Item>
        <Item ratio={0} baseSize={93}>
          <Form>
            <SimpleItem
              label={{ text: 'Eliminar' }}
              editorType={'dxCheckBox'}
              cssClass={
                modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Delete' && permiso.Editable,
                ) === undefined && 'checkboxsinpermisos'
              }
              editorOptions={{
                disabled:
                  modulo.Claims.find(
                    (permiso) =>
                      permiso.Permission === 'Delete' && permiso.Editable,
                  ) !== undefined
                    ? false
                    : true,
                value: modulo.Claims.find(
                  (permiso) =>
                    permiso.Permission === 'Delete' && permiso.IsGranted,
                )
                  ? true
                  : false,
                mode: 'text',
                onValueChanged: (e: any) => {
                  if (e.event) {
                    setpermisomodulo({
                      ...permisosModulo,
                      ClaimValue: 'Delete',
                      ClaimName: modulo.ModuleKey,
                    })
                    actualizarPermisos.mutate(e.value)
                  }
                },
              }}
            ></SimpleItem>
          </Form>
        </Item>
      </Box>
    )
  }, [modulo])
  return (
    <>
      <Box direction='row' align='center' crossAlign='center' height={100}>
        <Item ratio={0} baseSize={1000}>
          <div
            style={{
              height: '50px',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderLeft: '1px solid',
              borderRight: '1px solid',
            }}
          >
            <Box direction='row' width={1400} align='start' crossAlign='start'>
              <Item ratio={0} baseSize={'50%'}>
                <h3
                  style={{
                    position: 'relative',
                    left: '15px',
                    bottom: `10px`,
                  }}
                >
                  {modulo.ModuleName}
                </h3>
              </Item>
              <Item ratio={0} baseSize={80}>
                <Button
                  type='default'
                  text='Todos'
                  style={{ position: 'relative', top: `10px` }}
                  onClick={() => {
                    modulo.Claims.forEach((claim) => {
                      let permiso = new ClaimsUpdateModel()
                      permiso.ClaimValue = claim.Permission
                      permiso.ClaimName = modulo.ModuleKey
                      if (!claim.IsGranted && claim.Editable) {
                        todospermisos.push(permiso)
                      }
                    })

                    actualizarPermisos.mutate(true)
                  }}
                />
              </Item>
              <Item ratio={0} baseSize={20}></Item>
              <Item ratio={0} baseSize={96}>
                <Button
                  type='danger'
                  text='Ninguno'
                  style={{ position: 'relative', top: `10px` }}
                  onClick={() => {
                    modulo.Claims.forEach((claim) => {
                      let permiso = new ClaimsUpdateModel()
                      permiso.ClaimValue = claim.Permission
                      permiso.ClaimName = modulo.ModuleKey
                      if (claim.IsGranted && claim.Editable) {
                        todospermisos.push(permiso)
                      }
                    })

                    actualizarPermisos.mutate(false)
                  }}
                />
              </Item>
            </Box>
          </div>
        </Item>
      </Box>
      {renderFormRol}
    </>
  )
}
