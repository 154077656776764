import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('NotificationInfoModel')
export class NotificationInfoModel {
  @JsonProperty('sendNotificationByEmail', Boolean)
  sendNotificationByEmail: boolean = false

  @JsonProperty('sendNotificationBySms', Boolean)
  sendNotificationBySms: boolean = false

  @JsonProperty('email', String)
  email: string = ''

  @JsonProperty('phoneNumber', String)
  phoneNumber: string = ''
}
