import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  checkStatusCode,
  customLog,
  logType,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { UsuarioCampoDatoModel } from 'src/models/usuariosPage/usuarioCampoDatoModel'
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel'
import { UserModel } from 'src/models/usuario/userModel'
import { SucursalModel } from 'src/models/sucursal/sucursalModel'

const serviceName = 'UsuarioService'

/**
 * Obtiene la información de un usuario por su ID.
 * @param user Objeto UserModel que representa al usuario actual.
 * @param id ID del usuario que se desea obtener.
 * @returns Promise que resuelve a un objeto UsuarioModel con la información del usuario.
 */
export async function getUsuario(
  user: UserModel,
  id: number,
): Promise<UsuarioModel> {
  // Se crea una instancia de la clase JsonConvert
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  // Se construye la URL para la solicitud GET
  let url = `usuario/${id}`

  // Se inicializa un objeto UsuarioModel
  let usuario: UsuarioModel = new UsuarioModel()

  // Se realiza la solicitud GET usando axios
  await axiosInstance
    .get(url)
    .then((response: any) => {
      // Se verifica el código de estado de la respuesta
      checkStatusCode(response.status)

      // Se deserializa el objeto de respuesta a un UsuarioModel
      usuario = jsonConvert.deserializeObject(
        response.data.Datos[0],
        UsuarioModel,
      )

      // Se ajusta la propiedad Enabled según la lógica deseada
      usuario.Enabled = !usuario.Enabled
    })
    .catch((error: any) => {
      // En caso de error, se registra un mensaje de error en el log
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getUsuario.name,
        methodsEndPointType.GET,
      )

      // Se muestra una notificación de error
      showToast('No se ha podido obtener el usuario.', NotifyType.error, 5000)
      checkStatusCode(error.request?.status)
    })

  // Retorna el objeto UsuarioModel obtenido
  return usuario
}

/**
 * Obtiene la información de un usuario .
 * @param user Objeto UserModel que representa al usuario actual.
 * @returns Devuelve true o false si el usuario pertenece o no a Ruano.
 */
export function isRuanoUser(user: UserModel): boolean {
  return user.CompanyId === '1'
}

/**
 * Realiza una solicitud POST para crear un nuevo usuario.
 * @param user Objeto UsuarioModel que contiene los datos del usuario a crear.
 * @returns Promise que resuelve a un objeto UsuarioModel con la información del usuario creado o null en caso de error.
 */
export async function postUsuario(
  user: UsuarioModel,
): Promise<UsuarioModel | null> {
  // Inicializa la variable que almacenará los datos del usuario creado
  let usuarioData: UsuarioModel | null = null

  try {
    // Se crea una instancia de la clase JsonConvert
    const jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    // Se establece la URL para la solicitud POST
    const url = `usuario`

    // Se ajusta la propiedad Enabled según la lógica deseada
    user.Enabled = !user.Enabled

    // Se evalúa el tipo de usuario y realiza ajustes necesarios en sus propiedades
    evaluateTypeUser(user)

    // Se realiza la solicitud POST usando axios
    await axiosInstance
      .post(url, user)
      .then((response: any) => {
        // Se verifica el código de estado de la respuesta
        checkStatusCode(response.request.status)

        // Se muestra una notificación de éxito
        showToast('Usuario creado', NotifyType.success, 5000)

        // Se registra un mensaje de información en el log
        customLog(
          `Usuario created ${response.data.Id}`,
          logType.INFO,
          serviceName,
          postUsuario.name,
          methodsEndPointType.POST,
        )

        // Se realiza la conversión del objeto de respuesta a UsuarioModel
        usuarioData = jsonConvert.deserializeObject(response.data, UsuarioModel)
      })
      .catch((error: any) => {
        // Se verifica el código de estado del error
        checkStatusCode(error.response.request.status)

        // Se muestra una notificación de error
        showToast(
          'Error al crear el usuario, revise que los datos introducidos son correctos',
          NotifyType.error,
          5000,
        )

        // Se registra un mensaje de error en el log
        customLog(
          `Error create user  => ${error.response.data.Message}`,
          logType.ERROR,
          serviceName,
          postUsuario.name,
          methodsEndPointType.POST,
        )
      })
  } catch (error: any) {
    // En caso de error, se muestra una notificación y se registra un mensaje de error en el log
    showToast(
      'Error al crear el usuario, revise que los datos introducidos son correctos',
      NotifyType.error,
      5000,
    )
    customLog(
      `Error created user => ${error.message}`,
      logType.ERROR,
      serviceName,
      postUsuario.name,
      methodsEndPointType.POST,
    )
  }
  // Retorna el objeto UsuarioModel o null en caso de error
  return usuarioData
}

/**
 * Evalúa y ajusta propiedades del objeto UsuarioModel según ciertas condiciones.
 * Invoca la función evaluateUser para realizar ajustes generales.
 * @param user Objeto UsuarioModel que se evaluará y ajustará.
 */
function evaluateTypeUser(user: UsuarioModel) {
  evaluateUser(user)
  // Si se asigna tipo cuenta "Empleado"
  if (user.IdTipoUsuario === 3) {
    // SujetoIdentification es vacío
    user.SujetoIdentification = ''
    // Obtiene SujetoId del localStorage y lo convierte a número si no es nulo
    const localStorageSujetoId = localStorage.getItem('SujetoId')
    user.IdSujeto = localStorageSujetoId ? +localStorageSujetoId : 0
  }
}

/**
 * Evalúa y ajusta propiedades del objeto UsuarioModel según ciertas condiciones.
 * @param user Objeto UsuarioModel que se evaluará y ajustará.
 */
function evaluateUser(user: UsuarioModel) {
  // Si no selecciona ningun perfil, se asigna undefined
  if (user.IdPerfil === 0) user.IdPerfil = undefined
}

/**
 * Realiza una solicitud PUT para actualizar un usuario en el sistema.
 * @param user Objeto UsuarioModel con los datos actualizados del usuario.
 * @returns Una promesa que resuelve en un objeto UsuarioModel actualizado o nulo si hay un error.
 */
export async function putUsuario(
  user: UsuarioModel,
): Promise<UsuarioModel | null> {
  // Se inicializa la variable para almacenar los datos del usuario
  let userData: UsuarioModel | null = new UsuarioModel()

  try {
    // Se crea una instancia de la clase JsonConvert
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    // Se establece la URL para la solicitud PUT
    let url = `Usuario`

    // Se ajusta la propiedad Enabled según la lógica deseada
    user.Enabled = !user.Enabled // TODO si se llama más de una vez...

    // Se evalúan y ajustan propiedades del usuario según ciertas condiciones
    evaluateTypeUser(user)

    // Se realiza la solicitud PUT usando axios
    await axiosInstance
      .put(url, {
        ...user,
        RolesTipoCuenta: user.RolesTipoCuenta || [],
      })
      .then((response: any) => {
        // Se verifica el código de estado de la respuesta
        checkStatusCode(response.status)

        // Se realiza la conversión del objeto de respuesta a UsuarioModel
        userData = jsonConvert.deserializeObject(response.data, UsuarioModel)
        customLog(
          `User updated ${user.Id}`,
          logType.INFO,
          serviceName,
          putUsuario.name,
          methodsEndPointType.PUT,
        )

        // Se muestra una notificación de éxito
        showToast('Usuario editado', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        // En caso de error, se maneja la excepción
        showToast(
          'Error al editar el usuario, revise que los datos introducidos son correctos',
          NotifyType.error,
          5000,
        )

        // Se registra un mensaje de error en el log
        customLog(
          `Error updated user  => ${error.message}`,
          logType.ERROR,
          serviceName,
          putUsuario.name,
          methodsEndPointType.PUT,
        )

        // Se verifica el código de estado del error
        checkStatusCode(error.request.status)

        // Se asigna null a la variable userData en caso de error
        userData = null
      })
  } catch (error: any) {
    // En caso de error, se maneja la excepción
    showToast(
      'Error al editar el usuario, revise que los datos introducidos son correctos',
      NotifyType.error,
      5000,
    )
  }

  // Se devuelve el objeto UsuarioModel actualizado o nulo en caso de error
  return userData
}

/**
 * Realiza una solicitud GET para obtener la estructura de campos de datos de usuario.
 * @param user Objeto UserModel que representa al usuario que realiza la solicitud.
 * @returns Una promesa que resuelve en un objeto UsuarioCampoDatoModel con la estructura de campos de datos o un objeto vacío si hay un error.
 */
export async function getStructure(
  user: UserModel,
): Promise<UsuarioCampoDatoModel> {
  // Se inicializa la instancia de JsonConvert para manejar la conversión de datos
  let jsonConvert: JsonConvert = new JsonConvert()

  // Se configura el modo de verificación para permitir valores nulos
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  // Se establece la URL para la solicitud GET
  let url = `Usuario/Structure`

  // Se inicializa un objeto UsuarioCampoDatoModel para almacenar la estructura de campos de datos
  let usuario: UsuarioCampoDatoModel = new UsuarioCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      usuario = jsonConvert.deserializeObject(
        response.data,
        UsuarioCampoDatoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      checkStatusCode(error.request.status)
      return {}
    })

  return usuario
}

export async function deleteUsuario(user: UserModel, idUsuario: number) {
  try {
    // Se crea una instancia de la clase JsonConvert
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    // Se establecen las URL para las solicitudes DELETE
    let url = `Usuario/RelatedDeleteUser/${idUsuario}`
    let url2 = `Usuario/${idUsuario}`

    // Se realiza la primera solicitud DELETE usando axios
    await axiosInstance.delete(url).then(async (response: any) => {
      // Se realiza la segunda solicitud DELETE usando axios
      await axiosInstance.delete(url2).then((response: any) => {
        // Se verifica el código de estado de la respuesta
        checkStatusCode(response.status)

        // Se registra un mensaje de información en el log
        customLog(
          `usuario elminado`,
          logType.INFO,
          serviceName,
          deleteUsuario.name,
          methodsEndPointType.DELETE,
        )
        // Se muestra una notificación de éxito
        showToast('Usuario elminado', NotifyType.success, 5000)
      })
    })

    // Se devuelve true indicando que la operación fue exitosa
    return true
  } catch (error: any) {
    // En caso de error, se maneja la excepción
    showToast('Error al eliminar el usuario', NotifyType.error, 5000)

    // Se registra un mensaje de error en el log
    customLog(
      `Error al eliminar Compañia => ${error.response?.data}`,
      logType.ERROR,
      serviceName,
      deleteUsuario.name,
      methodsEndPointType.DELETE,
    )

    // Se verifica el código de estado del error
    checkStatusCode(error.request?.status)

    // Se devuelve false indicando que hubo un error en la operación
    return false
  }
}

export async function getUsuariosList(
  user: UserModel,
): Promise<UsuarioCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  let url = `Usuario/Grid`

  let usuariosList: UsuarioCampoDatoModel = new UsuarioCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      usuariosList = jsonConvert.deserializeObject(
        response.data,
        UsuarioCampoDatoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      checkStatusCode(error.request.status)
      return {}
    })

  return usuariosList
}

export async function getSucursales(user: UserModel): Promise<SucursalModel[]> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  const url = `sucursal/GetByCompany`

  let sucursales: SucursalModel[] = []
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sucursales = jsonConvert.deserializeArray(response.data, SucursalModel)
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getSucursales.name,
        methodsEndPointType.GET,
      )
      checkStatusCode(error.request.status)
    })

  return sucursales
}
