import { createContext } from 'react'

interface ConfiguracionSolicitudesContextProps {
  popUpVisible: boolean
  setPopUpVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ConfigracionSolicitudesContext =
  createContext<ConfiguracionSolicitudesContextProps>({
    setPopUpVisible: value => {},
    popUpVisible: false,
  })
