import { JsonObject, JsonProperty } from 'json2typescript'
@JsonObject('MicrodatosModel')
export class MicrodatosModel {
  forEach(arg0: (value: any) => void) {
    throw new Error('Method not implemented.')
  }
  @JsonProperty('bastidor', String, '')
  bastidor: string = ''
  @JsonProperty('nive', String, '')
  nive: string = ''
  @JsonProperty('matricula', String, '')
  matricula: string = ''
  @JsonProperty('fecMatricula', String, '')
  fecMatricula: string = ''
  @JsonProperty('codClaseMat', String, '')
  codClaseMat: string = ''
  @JsonProperty('fecTramitacion', String, '')
  fecTramitacion: string = ''
  @JsonProperty('marcaItv', String, '')
  marcaItv: string = ''
  @JsonProperty('modeloItv', String, '')
  modeloItv: string = ''
  @JsonProperty('codProcedenciaItv', String, '')
  codProcedenciaItv: string = ''
  @JsonProperty('codTipo', String, '')
  codTipo: string = ''
  @JsonProperty('codPropulsionItv', Number)
  codPropulsionItv: number = 0
  @JsonProperty('cilindradaItv', Number)
  cilindradaItv: number = 0
  @JsonProperty('potenciaItv', Number)
  potenciaItv: number = 0
  @JsonProperty('tara', Number)
  tara: number = 0
  @JsonProperty('pesoMaximo', Number)
  pesoMaximo: number = 0
  @JsonProperty('numPlazas', Number)
  numPlazas: number = 0
  @JsonProperty('indPrecinto', Boolean)
  indPrecinto: boolean = false
  @JsonProperty('indEmbargo', Boolean)
  indEmbargo: boolean = false
  @JsonProperty('numTransmisiones', Number)
  numTransmisiones: number = 0
  @JsonProperty('numTitulares', Number)
  numTitulares: number = 0
  @JsonProperty('localidadVehiculo', String, '')
  localidadVehiculo: string = ''
  @JsonProperty('codProvinciaVehiculo', String, '')
  codProvinciaVehiculo: string = ''
  @JsonProperty('codProvinciaMatriculacion', String, '')
  codProvinciaMatriculacion: string = ''
  @JsonProperty('claveTramite', String, '')
  claveTramite: string = ''
  @JsonProperty('fecTramite', String, '')
  fecTramite: string = ''
  @JsonProperty('codPostal', String, '')
  codPostal: string = ''
  @JsonProperty('fecPrimeraMatriculacion', String, '')
  fecPrimeraMatriculacion: string = ''
  @JsonProperty('indUsado', Boolean)
  indUsado: boolean = false
  @JsonProperty('personaFisicaJuridica', String, '')
  personaFisicaJuridica: string = ''
  @JsonProperty('codigoItv', String, '')
  codigoItv: string = ''
  @JsonProperty('servicio', String, '')
  servicio: string = ''
  @JsonProperty('codMunicipioIneVehiculo', String, '')
  codMunicipioIneVehiculo: string = ''
  @JsonProperty('municipio', String, '')
  municipio: string = ''
  @JsonProperty('kwItv', Number)
  kwItv: number = 0
  @JsonProperty('numPlazasMaximo', Number)
  numPlazasMaximo: number = 0
  @JsonProperty('co2Itv', Number)
  co2Itv: number = 0
  @JsonProperty('indRenting', Boolean)
  indRenting: boolean = false
  @JsonProperty('indCodTutela', Boolean)
  indCodTutela: boolean = false
  @JsonProperty('codPosesion', String, '')
  codPosesion: string = ''
  @JsonProperty('indBajaDefinitiva', String, '')
  indBajaDefinitiva: string = ''
  @JsonProperty('indBajaTemporal', Boolean)
  indBajaTemporal: boolean = false
  @JsonProperty('indSustraccion', Boolean)
  indSustraccion: boolean = false
  @JsonProperty('bajaTelematica', String, '')
  bajaTelematica: string = ''
  @JsonProperty('tipoItv', String, '')
  tipoItv: string = ''
  @JsonProperty('varianteItv', String, '')
  varianteItv: string = ''
  @JsonProperty('versionItv', String, '')
  versionItv: string = ''
  @JsonProperty('fabricanteItv', String, '')
  fabricanteItv: string = ''
  @JsonProperty('masaOrdenMarchaItv', String, '')
  masaOrdenMarchaItv: string = ''
  @JsonProperty('masaMaximaTecnicamenteAdmisibleItv', String, '')
  masaMaximaTecnicamenteAdmisibleItv: string = ''
  @JsonProperty('categoriaHomoligacionEuItv', String, '')
  categoriaHomoligacionEuItv: string = ''
  @JsonProperty('carroceria', String, '')
  carroceria: string = ''
  @JsonProperty('plazasPie', Number)
  plazasPie: number = 0
  @JsonProperty('nivelEmisionesEuroItv', String, '')
  nivelEmisionesEuroItv: string = ''
  @JsonProperty('consumoWhKwItv', Number)
  consumoWhKwItv: number = 0
  @JsonProperty('clasificacionRegVehiculosItv', String, '')
  clasificacionRegVehiculosItv: string = ''
  @JsonProperty('categoriaVehiculoElectrico', String, '')
  categoriaVehiculoElectrico: string = ''
  @JsonProperty('autonomiaVehiculoElectrico', String, '')
  autonomiaVehiculoElectrico: string = ''
  @JsonProperty('marcaVehiculoBase', String, '')
  marcaVehiculoBase: string = ''
  @JsonProperty('fabricanteVehiculoBase', String, '')
  fabricanteVehiculoBase: string = ''
  @JsonProperty('tipoItvBase', String, '')
  tipoItvBase: string = ''
  @JsonProperty('varianteVehiculoBase', String, '')
  varianteVehiculoBase: string = ''
  @JsonProperty('versionVehiculoBase', String, '')
  versionVehiculoBase: string = ''
  @JsonProperty('distanciaEjes12Itv', Number)
  distanciaEjes12Itv: number = 0
  @JsonProperty('viaAnteriorItv', String, '')
  viaAnteriorItv: string = ''
  @JsonProperty('viaPosteriorItv', String, '')
  viaPosteriorItv: string = ''
  @JsonProperty('tipoAlimentacionItv', String, '')
  tipoAlimentacionItv: string = ''
  @JsonProperty('contrasenaHomologacionItv', String, '')
  contrasenaHomologacionItv: string = ''
  @JsonProperty('ecoInnovacionItv', Boolean)
  ecoInnovacionItv: boolean = false
  @JsonProperty('reduccionEcoItv', String, '')
  reduccionEcoItv: string = ''
  @JsonProperty('codigoEcoItv', String, '')
  codigoEcoItv: string = ''
  @JsonProperty('fecProceso', String, '')
  fecProceso: string = ''
  @JsonProperty('notas', String, '')
  notas: string = ''
  @JsonProperty('fecAlta', String, '')
  fecAlta: string = ''
  @JsonProperty('fecModificacion', String, '')
  fecModificacion: string = ''
}

@JsonObject('MicrodatosVehiculosAntiguosModel')
export class MicrodatosVehiculosAntiguosModel {
  @JsonProperty('bastidor', String, '')
  bastidor: string = ''
  @JsonProperty('marcaItv', String, '')
  marcaItv: string = ''
  @JsonProperty('modeloItv', String, '')
  modeloItv: string = ''
  @JsonProperty('cilindradaItv', Number)
  cilindradaItv: number = 0
  @JsonProperty('potenciaItv', Number)
  potenciaItv: number = 0
  @JsonProperty('tara', Number)
  tara: number = 0
  @JsonProperty('pesoMaximo', Number)
  pesoMaximo: number = 0
  @JsonProperty('numPlazas', Number)
  numPlazas: number = 0
  @JsonProperty('localidadVehiculo', String, '')
  localidadVehiculo: string = ''
  @JsonProperty('codProvinciaVehiculo', String, '')
  codProvinciaVehiculo: string = ''
  @JsonProperty('codigoItv', String, '')
  codigoItv: string = ''
  @JsonProperty('kwItv', Number)
  kwItv: number = 0
  @JsonProperty('co2Itv', Number)
  co2Itv: number = 0
  @JsonProperty('varianteItv', String, '')
  varianteItv: string = ''
  @JsonProperty('versionItv', String, '')
  versionItv: string = ''
  @JsonProperty('fabricanteItv', String, '')
  fabricanteItv: string = ''
  @JsonProperty('nivelEmisionesEuroItv', String, '')
  nivelEmisionesEuroItv: string = ''
  @JsonProperty('contrasenaHomologacionItv', String, '')
  contrasenaHomologacionItv: string = ''
}
