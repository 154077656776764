import { RequestFieldDataModel } from '../../models/requestFieldDataModel';
import { RequestModel } from '../../models/requestModel';

interface RequestState {
  request: Partial<RequestModel> | RequestModel | undefined;
  requests: RequestFieldDataModel | undefined;
}

const ADD_REQUESTS = 'ADD_REQUESTS';
const ADD_REQUEST = 'ADD_REQUEST';
const CLEAR_REQUEST = '@todo/CLEAR_REQUEST';
const ADD_REQUEST_MODULE = 'ADD_REQUEST_MODULE';
const CLEAR_REQUEST_MODULE = 'CLEAR_REQUEST_MODULE';
const ADD_REQUEST_MODULE_SELECTED = 'ADD_REQUEST_MODULE_SELECTED';
const ADD_PARENT_REQUEST = 'ADD_PARENT_REQUEST';
const ADD_REQUEST_MODULE_PARENT = 'ADD_REQUEST_MODULE_PARENT';

interface AddRequests {
  type: typeof ADD_REQUESTS;
  payload: RequestFieldDataModel | undefined;
}

interface AddRequest {
  type: typeof ADD_REQUEST;
  payload: Partial<RequestModel> | undefined;
}

interface AddParentRequest {
  type: typeof ADD_PARENT_REQUEST;
  payload: Partial<RequestModel> | undefined;
}

interface ClearRequest {
  type: typeof CLEAR_REQUEST;
  payload: RequestModel | undefined;
}

export type RequestActionsTypes =
  | AddRequests
  | AddRequest
  | ClearRequest
  | AddParentRequest

export { type RequestState };
export { 
  ADD_REQUESTS,
  ADD_REQUEST,
  CLEAR_REQUEST,
  ADD_REQUEST_MODULE,
  CLEAR_REQUEST_MODULE,
  ADD_REQUEST_MODULE_SELECTED,
  ADD_PARENT_REQUEST,
  ADD_REQUEST_MODULE_PARENT
};