import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'

import { NotifyType, showToast } from '../../../utils/sharedUitls'

import { SujetosCampoDatoModel } from '../models/sujetosCampoDatoModel'
import { SujetoModel } from '../models/sujetoModel'
import { authHeader } from 'src/services/sharedServices'
import { SujetoByIdExpedienteModel } from '../models/sujetoByIdExpedienteModel'

const serviceName = 'authService'

export const getSujetos = async (
  user: UserModel,
): Promise<SujetosCampoDatoModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `sujeto`
    let SujetosData: SujetosCampoDatoModel = new SujetosCampoDatoModel()
    let datos
    await axiosInstance
      .get(url)
      .then((response: any) => {
        SujetosData = jsonConvert.deserializeObject(
          response.data,
          SujetosCampoDatoModel,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los sujetos.',
          NotifyType.error,
          5000,
        )
      })

    return SujetosData
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}
export async function getSujeto(
  user: UserModel,
  id: number,
): Promise<SujetoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Sujeto/${id}`

  let sujeto: SujetoModel = new SujetoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sujeto = jsonConvert.deserializeObject(
        response.data.Datos[0],
        SujetoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getSujeto.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return sujeto
}
export async function postSujeto(
  user: UserModel,
  sujeto: SujetoModel,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `Sujeto`

    await axiosInstance.post(url, sujeto).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `sujeto creado ${response.data.Id}`,
        logType.INFO,
        serviceName,
        putSujeto.name,
        methodsEndPointType.POST,
      )
      showToast('Compañia creada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    customLog(
      `Error al crear Sujeto => ${error.message}`,
      logType.ERROR,
      serviceName,
      postSujeto.name,
      methodsEndPointType.POST,
    )
    return false
  }
}
export async function putSujeto(
  user: UserModel,
  sujeto: SujetoModel,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Sujeto`

    const headers = {
      Authorization: 'Bearer my-token',
      'My-Custom-Header': 'foobar',
    }
    await axiosInstance
      .put(url, sujeto)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `sujeto modificado ${sujeto.Id}`,
          logType.INFO,
          serviceName,
          putSujeto.name,
          methodsEndPointType.PUT,
        )
        showToast('Compañia editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar Sujeto => ${error.message}`,
          logType.ERROR,
          serviceName,
          putSujeto.name,
          methodsEndPointType.PUT,
        )
      })
    return true
  } catch (error) {
    return false
  }
}
export async function getStructure(
  user: UserModel,
): Promise<SujetosCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Sujeto/Structure`

  let sujeto: SujetosCampoDatoModel = new SujetosCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sujeto = jsonConvert.deserializeObject(
        response.data,
        SujetosCampoDatoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return sujeto
}

export function getMapperSujeto(sujetoData: any): SujetoModel {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let sujeto: SujetoModel = jsonConvert.deserializeObject(
    sujetoData,
    SujetoModel,
  )
  return sujeto
}

export const getSujetosByExpediente = async (
  id: number,
): Promise<SujetoByIdExpedienteModel[]> => {
  let sujetosbyidexpediente: SujetoByIdExpedienteModel[] = []
  await axiosInstance
    .get(`Sujeto/GetByExpediente/${id}`)
    .then((response: any) => {
      sujetosbyidexpediente = response.data
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener los sujetos del expediente',
        NotifyType.error,
        5000,
      )
    })

  return sujetosbyidexpediente
}

//TODO arreglar esto para que sea generio o directamente coger el user de redux
export const apiUrl = () => {
  return 'https://portal2020api.azurewebsites.net/api/'
}

export default {
  createSujeto: postSujeto,
  getSujetos,
  apiUrl,
}
