import React, { useState } from 'react';
import { Box, Button, DataGrid, LoadPanel, Template, TextArea } from 'devextreme-react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';
import { NotifyType, checkScreenSize, showToast } from 'src/utils/sharedUitls';
import { RelationsField } from '../../models/IRelationsField';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Item } from 'devextreme-react/box';
import {
  deleteFields,
  deleteFieldsAssociation,
  getDocumentTypeFieldRelations,
  getFieldForEdit,
  postFieldDocumentType,
  putFieldDocumentType,
} from '../../services/fieldsServices';
import { useDispatch } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';
import { custom } from 'devextreme/ui/dialog';
import { SendFormFieldsModel } from '../../models/SendFormFieldsModel';
import { ExpedientFieldModel } from '../../models/ExpedienteFieldModels';
import { FormLinesTextCustom } from './FormLinesTextCustom';
import { Lines } from '../../models/ILines';
import { showPopup } from 'src/redux/actions/sharedUtilsActions';
import firmaDigitalAxios from 'src/utils/firmadigitalaxios';

interface Props {
  documentName: String;
  idDocumentType: number;
  widthFields: Number;
  fields: Array<String>;
  availableFields: Array<ExpedientFieldModel>;
  filteredFields: Array<String>;
  setLabelList: Function;
  relationsFields: Array<RelationsField>;
  formsLabels: Boolean;
  formCustomText: Boolean;
  labelList: Array<String>;
}

function TableRelationsDocumentTypeSection({
  documentName,
  idDocumentType,
  fields,
  availableFields,
  filteredFields,
  relationsFields,
}: Props) {
  // Estado local
  const [sendFormfield, setFormField] = useState(new SendFormFieldsModel());
  const [idField, setIdField] = useState(-1);
  const [popupFormEtiquetaPorEtiqueta, setPopupFormEtiquePorEtiqueta] = useState(false);
  const [enviarFormEtiqueta, setEnviarFormEtiqueta] = useState(false);
  const [editarFormAsociacionEtiqueta, seteditarFormAsociacionEtiqueta] = useState(false);
  const [loadPanelVisible, setLoadPanelVisible] = useState(false)
  const [relation, setRelation] = useState<RelationsField[]>([]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const baseURL = process.env.REACT_APP_API_FIRMADIGITAL_URL;

  // Función que se ejecuta al preparar la barra de herramientas en el DataGrid
  const onHanleOnToolbarPreparing = (e: any) => {
    if (documentName !== '' && fields.length > 0) {
      e.toolbarOptions.items.unshift(
        { location: 'after', template: 'asociacionesAutomaticas' },
        { location: 'after', template: 'agregarAsociacionTemplate' },
        { location: 'after', template: 'eliminarAsociacionTemplate' },
      )
    }
  }

  // Define una consulta para obtener las relaciones de campos
  const { data: relations } = useQuery(['getFieldDocumentType', idDocumentType], getDocumentTypeFieldRelations);
  // Calcula si hay relaciones de campos
  const hasRelations = relations && relations.length > 0;
  
  const { mutate } = useMutation(
    async (idDocumentType: number) => {
      const data = await getDocumentTypeFieldRelations(idDocumentType);
      setRelation(data); // Actualiza el estado local
      return data;
    },
    {
      onSuccess: (data: RelationsField[]) => {
        queryClient.setQueryData(['getFieldDocumentType', idDocumentType], data);
      },
    }
  );
  

  // Renderización del botón para eliminar asociaciones
  function eliminarAsociacionRender() {
    return (
      <Box direction='row' align='start' crossAlign='start' width={'auto'}>
        <Item ratio={0} baseSize={'auto'}>
          <Button
            text='Eliminar relaciones'
            type='danger'
            disabled={!hasRelations}  // Deshabilita el botón si no hay relaciones
            stylingMode='contained'
            onClick={() => {
              const confirmDialog = custom({
                title: 'Eliminar relaciones',
                messageHtml:
                  '¿Desear eliminar todas relacionados de los campos?',
                buttons: [
                  {
                    text: 'Si',
                    onClick: (e) => {
                      borrarAsociacion.mutate()
                      confirmDialog.hide()
                    },
                  },
                  {
                    text: 'No',
                    elementAttr: {
                      id: 'usuarioCancelButton',
                      class: 'usuarioCancelButtonClass',
                    },
                    onClick: (e) => confirmDialog.hide(),
                  },
                ],
              })
              confirmDialog.show()
            }}
          />
        </Item>
      </Box>
    )
  }

  // Renderización del botón para agregar asociaciones
  function agregarAsociacionRender() {
    return (
      <Box direction='row' align='start' crossAlign='start' width={'auto'}>
        <Item ratio={0} baseSize={'auto'}>
          <Button
            text='Relación manual'
            type='default'
            stylingMode='contained'
            onClick={() => {
              if (
                idDocumentType !== 0 &&
                availableFields.length > 0 &&
                fields.length > 0
              ) {
                setPopupFormEtiquePorEtiqueta(true)
                setLineas([{ text: '', line: 1 }])
                setEditarTextoPersonalizado(false)
                seteditarFormAsociacionEtiqueta(false)
                dispatch(showPopup(true))
              }
            }}
          />
        </Item>
      </Box>
    )
  }

  // Renderización del botón para asociar automáticamente
  function asociacionAutomaticaRender() {
    return (
      <Box direction='row' align='start' crossAlign='start' width={'auto'}>
        <Item ratio={0} baseSize={'auto'}>
          <Button
            text='Relación automática'
            type='default'
            stylingMode='contained'
            onClick={() => {           
              setLoadPanelVisible(true);
              dispatch(addShowLoader(true));
              associatingFields();
              dispatch(addShowLoader(false));
            }}
          />
        </Item>
      </Box>
    )
  }

  // Estado local para manejar líneas de texto
  const [lineas, setLineas] = useState<Array<Lines>>([{ text: '', line: 1 }]);
  const [texto, setTexto] = useState('');
  const [editarTextoPersonalizado, setEditarTextoPersonalizado] = useState(false);

  // Mutación para borrar todas las asociaciones
  const borrarAsociacion = useMutation(
    async function () {
      dispatch(addShowLoader(true));
      await deleteFieldsAssociation(idDocumentType);
    },
    {
      onSuccess: function () {
        queryClient.invalidateQueries('getFieldDocumentType');
        setFormField(new SendFormFieldsModel());
      },
      onError: function () {
        dispatch(addShowLoader(false));
      },
    },
  )

  // Función para cerrar el popup de asociación de campos
  function cerrarPopUpAsocionCampos() {
    dispatch(showPopup(false));
    if (editarFormAsociacionEtiqueta) {
      setFormField(new SendFormFieldsModel());
      setLineas([]);
    }
    setPopupFormEtiquePorEtiqueta(false);
    seteditarFormAsociacionEtiqueta(false);
    setEditarTextoPersonalizado(false);
    seteditarFormAsociacionEtiqueta(false);
  }

  // Función para asociar líneas
  function asociarLineas(editLineas: SendFormFieldsModel) {
    let establecerLineas: Array<Lines> = [];
    let listaIds = ordenarIds(editLineas);
    let textoAuxiliar = editLineas.internalFormat;

    listaIds.forEach((lista, index) => {
      let parteTexto = textoAuxiliar.split(`{{${lista}}}`);

      establecerLineas.push({
        text: parteTexto[0].trim(),
        line: index + 1,
        label: availableFields.find((campo) => campo.id === lista)?.description,
        id: lista,
      })
      textoAuxiliar = parteTexto[1];
      if (
        index === listaIds.length - 1 &&
        textoAuxiliar.length > 0 &&
        textoAuxiliar !== '  '
      ) {
        establecerLineas.push({
          text: textoAuxiliar,
          line: establecerLineas[establecerLineas.length - 1].line + 1,
          label: '',
        })
      }
    })
    setLineas(establecerLineas);
  }

  // Función para enviar la asociación
  function setEnviarAsociacion() {
    setEnviarFormEtiqueta(true);
  }

  // Función para ordenar ids
  function ordenarIds(ids: SendFormFieldsModel) {
    let listaIds: Array<{}> = []

    ids.expedientFieldsIds.forEach((id) => {
      listaIds.push({
        value: id,
        orden: ids.internalFormat.indexOf(`{{${id}}}`),
      });
    })

    let listaOrdenadaIds = listaIds.sort(function (value1: any, value2: any) {
      if (value1.orden > value2.orden) {
        return 1;
      }
      if (value1.orden < value2.orden) {
        return -1;
      }
      return 0;
    })

    return listaOrdenadaIds.map((id: any) => {
      return id.value;
    })
  }

  // Método para armar las asociaciones
  function buildAssociations(associations: {
    id: number;
    name: string;
    expedientFieldsIds: string[];
    internalFormat: string;
    readableFormat: string;
  }[]): {
    id: number;
    name: string;
    expedientFieldsIds: string[];
    internalFormat: string;
    readableFormat: string;
  }[] {
    const builtAssociations = associations.map((association) => {
      return {
        id: 0,
        name: association.name,
        expedientFieldsIds: [association.internalFormat],
        internalFormat: `{{${association.internalFormat}}}`,
        readableFormat: `{{${
          association ? association.readableFormat : 'No encontrado'
        }}}`,
      };
    });

    return builtAssociations;
  }

  // Asocia los campos relacionados ejecutando automática o manualmente
  async function associatingFields() {
    try {
      let associations = findAssociations();

      if (associations.length > 0) {
        let assoc = buildAssociations(associations);
        sendAssociations(assoc).then(()=>{ setLoadPanelVisible(false) });
      }
    } catch (error) {
      showToast('Error al asociar campos.', NotifyType.error, 5000);
    }
  }
  
  // Método para encontrar asociaciones entre campos disponibles y filtrados
  function findAssociations(): any[] {
    const foundAssociations = []

    // Utilizando availableFields y filteredFields para encontrar asociaciones
    for (const field of availableFields) {
      const association = filteredFields.find(
        (filteredField) =>
          filteredField.toLowerCase() === field.description.toLowerCase(),
      );
      try {
        if (association !== undefined) {
          //expedientFieldsIds es un id interno, funciona como clave primaria única
          foundAssociations.push({
            id: 0,
            name: association,
            expedientFieldsIds: [field.id],
            internalFormat: `${field.id}`,
            readableFormat: `${field ? field.description : 'No encontrado'}`,
          });
        } 
      } catch (error) {
        showToast('Error al asociar campos.', NotifyType.error, 5000);
      }
    }
    // Mostrar mensaje si no se encontraron asociaciones
    if (foundAssociations.every((association) => association === undefined)) {
      showToast('No existen campos que puedan relacionarse, utilice la forma manual.', NotifyType.info, 5000);
    }
  
    return foundAssociations.filter((association) => association !== undefined);
  }

 /**
 * Envía las asociaciones de campos para su creación o actualización.
 * @param associations - Las asociaciones de campos a enviar.
 */
async function sendAssociations(associations: any) {

  try {
    let relacionCreada: Boolean | null = null;

    // Verifica si se debe crear una nueva asociación o actualizar una existente
    if (!editarFormAsociacionEtiqueta) {
      // Llama al método para realizar la operación de creación (POST)
      relacionCreada = await postFieldDocumentType(associations, idDocumentType);
    } else {
      // Si está actualizando, toma el primer elemento del arreglo de asociaciones
      const [firstAssociation] = associations;
      // Llama al método para realizar la operación de actualización (PUT)
      relacionCreada = await putFieldDocumentType(firstAssociation, idDocumentType);
    }

    // Realiza acciones adicionales si la operación fue exitosa
    if (relacionCreada) {
      queryClient.invalidateQueries('getFieldDocumentType');
      setPopupFormEtiquePorEtiqueta(false);
    } else {
      showToast('No se han podido crear o actualizar las relaciones de campos.', NotifyType.error, 5000);
    }
  } catch (error: any) {
    // Maneja errores específicos, como campos inexistentes o validaciones
    if (error.response && error.response.status === 404) {
      showToast('No existen campos que puedan relacionarse, utilice la forma manual.', NotifyType.info, 5000);
    } else {
      showToast('No se ha podido crear o actualizar la relación entre campos.', NotifyType.error, 5000);
    }
  }
}


  // Renderización del componente
  return (
    <>
      <DataGrid
        showBorders={true}
        dataSource={
          documentName !== '' && fields.length > 0 ? relationsFields : []
        }
        showRowLines
        allowColumnReordering
        allowColumnResizing
        cacheEnabled
        columnAutoWidth
        repaintChangesOnly
        rowAlternationEnabled
        wordWrapEnabled={true}
        columnHidingEnabled={!checkScreenSize()}
        onToolbarPreparing={onHanleOnToolbarPreparing}
      >
        <LoadPanel visible={loadPanelVisible} defaultVisible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Column
          width={'4%'}
          type='buttons'
          cellRender={(row: any) => (
            <Button
              icon='edit'
              onClick={async (e: any) => {
                dispatch(addShowLoader(true))
                await getFieldForEdit(idDocumentType, row.data.id).then(
                  (response) => {
                    setFormField(response)
                    asociarLineas(response)
                    setEditarTextoPersonalizado(true)
                    seteditarFormAsociacionEtiqueta(true)
                    setPopupFormEtiquePorEtiqueta(true)
                  },
                )
                dispatch(addShowLoader(false))
              }}
            />
          )}
        />
        <Column
          width={'4%'}
          type='buttons'
          cellRender={(row: any) => (
            <Button
              icon='trash'
              onClick={(e: any) => {
                const confirmDialog = custom({
                  title:'Eliminar relación',
                  messageHtml: '¿Desea eliminar esta relación de campos?',
                  buttons: [
                    {
                      text: 'Si',
                      onClick: async (e) => {
                        dispatch(addShowLoader(true));
                        setIdField(row.data.id);
                        await deleteFields(idDocumentType, row.data.id);
                        dispatch(addShowLoader(false));
                      
                        // Forzar la reejecución de la consulta
                        queryClient.invalidateQueries(['getFieldDocumentType', idDocumentType]);
                        confirmDialog.hide();
                      },
                    },
                    {
                      text: 'No',
                      elementAttr: {
                        id: 'usuarioCancelButton',
                        class: 'usuarioCancelButtonClass',
                      },
                      onClick: (e) => confirmDialog.hide(),
                    },
                  ],
                });
                confirmDialog.show();
              }}
            />
          )}
        />
        <Column
          width={'30%'}
          caption={'Etiqueta del documento'}
          alignment='center'
          dataField={'etiquetadocumento'}
          cellRender={(row: any) => (
            <div style={{ whiteSpace: 'normal' }}>
              <TextArea
                width={'auto'}
                height={'auto'}
                readOnly={true}
                autoResizeEnabled={true}
                value={row.data.documentLabel}
              />
            </div>
          )}
        />
        <Column
          caption={'Valor asociado'}
          alignment='center'
          dataField={'etiquetaoriginal'}
          cellRender={(row: any) => (
            <div style={{ whiteSpace: 'normal' }}>
              <TextArea
                width={'auto'}
                height={'auto'}
                readOnly={true}
                autoResizeEnabled={true}
                value={row.data.value}
              />
            </div>
          )}
        />
        <Template
          name={'eliminarAsociacionTemplate'}
          render={eliminarAsociacionRender}
        />
        <Template
          name={'agregarAsociacionTemplate'}
          render={agregarAsociacionRender}
        />
        <Template
          name={'asociacionesAutomaticas'}
          render={asociacionAutomaticaRender}
        />
      </DataGrid>

      {popupFormEtiquetaPorEtiqueta && (
        <FormLinesTextCustom
          text={texto}
          lines={lineas}
          setLines={setLineas}
          setText={setTexto}
          close={cerrarPopUpAsocionCampos}
          listLabels={availableFields}
          send={setEnviarAsociacion}
          sendFormField={sendFormfield}
          filteredFields={filteredFields}
          setSendFormField={setFormField}
          edit={editarTextoPersonalizado}
          sendAssociations={sendAssociations}
        ></FormLinesTextCustom>
      )}
    </>
  )
}

export { TableRelationsDocumentTypeSection }
