import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Popup,
  ScrollView,
  SelectBox,
  TreeList,
} from 'devextreme-react'
import { RootState } from '../../../../redux/combineReducers'
import { _getStructure } from '../services/componenteServices'
// import { DataGridModel } from 'src/models/dataGridModel';
// import { ButtonColumnModel } from 'src/models/buttonColumnModel';
import { useNavigate } from 'react-router-dom'
import { FieldDataModelComponent } from '../models/modelComponent/fieldDataModelComponent'
import NotFound from '../../../../components/notFound/notFound'
import { addModules } from '../redux/modulo/moduleActions'
import { _getModules } from '../services/moduloServices'
import {
  _deleteTemplate,
  _getMapperTemplate,
  _getTemplatesFields,
  _getTemplateWithChild,
} from '../services/plantillaService'
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  LoadPanel,
  SearchPanel,
} from 'devextreme-react/data-grid'
import { TemplateModel } from '../models/templateModel/templateModel'
import { isOpenPopup } from '../../../../redux/actions/sharedUtilsActions'
import { TemplateFormPage } from './TemplateFormPage'
import { _getProfiles } from '../../../adminPages/profilePages/service/profileService'
import {
  addProfile,
  addProfiles,
} from '../../../adminPages/profilePages/redux/profileActions'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'
import {
  addModule,
  addTemplate,
  addTemplatesFields,
} from '../redux/template/TemplateActions'
import { useScreenSize } from '../../../../utils/media-query'
import { isSuperAdmin } from 'src/utils/allowAuthUtil'
import { ModulesListPage } from './ModulesListPage'
import { addShowLoader } from 'src/redux/actions/configActions'

function TemplatePage(): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const [structure, setStructure] = useState(new FieldDataModelComponent())
  const profileState = useSelector((state: RootState) => state.profile)
  const module = useSelector((state: RootState) => state.template.module)

  const [showForm, setShowForm] = useState(false)
  // const [rowFocused, setRowFocused] = useState('');
  const [rowSelected, setRow] = useState(0)
  const [templates, setTemplates] = useState<TemplateModel[]>([])
  const [templateSelected, setTemplate] = useState(
    templates.length > 0 ? templates[0] : new TemplateModel(),
  )
  const templateState = useSelector((state: RootState) => state.template)
  const gridRef: any = useRef(null)
  const formData = useRef({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const popupPlantillaRef = useRef(null)
  const [inicioGrid, setInicioGrid] = useState(false)
  const [modulesParent, setModulesParent] = useState([{}])
  const { isLarge, isMedium, isSmall, isXSmall } = useScreenSize()
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

  useEffect(() => {
    async function fetchData() {
      setStructure(await _getStructure(userState!.user!))
      dispatch(addModules(await _getModules(userState?.user!)))
      dispatch(addProfiles(await _getProfiles(userState!.user!)))
      setModulesParent(
        jsonConvert.deserializeArray(
          JSON.parse(localStorage.getItem('parentCompanyModulos')!),
          CompanyModuloModel,
        ),
      )
    }
    fetchData()
  }, [])

  function verPopup() {
    setShowForm(showForm)
  }

  function actualizarEstado() {
    setInicioGrid(!inicioGrid)
  }

  const handleOpenCreatePopup = async () => {
    if (templates.length > 0) {
      templateState.templateFields = await _getTemplatesFields(
        userState!.user!,
        templates[0].Id,
      )
      dispatch(addTemplate(new TemplateModel()))
      setShowForm(!showForm)
    } else {
      showToast(
        'Selecciona un componente y su plantilla',
        NotifyType.info,
        5000,
      )
    }
  }

  const editButtonTemplates = (cellRender: any) => {
    let canEdit = true
    if (cellRender.data.EsGenerica && !isSuperAdmin(userState!.user!)) {
      canEdit = false
    }
    if (canEdit) {
      return (
        <Button
          icon='edit'
          text=''
          hint='Editar'
          onClick={async () => {
            dispatch(
              addProfile(
                profileState?.profiles!.find(
                  (profile) => profile.Id === cellRender.data.IdPerfil,
                ),
              ),
            )
            let templateFields = await _getTemplatesFields(
              userState!.user!,
              cellRender.row.key,
            )
            templateState.templateFields = templateFields
            dispatch(
              addTemplate(
                templates.find(
                  (template) => template.Id === cellRender.row.key,
                ),
              ),
            )
            setShowForm(!showForm)
          }}
        ></Button>
      )
    }
  }

  const deleteButtonTemplates = (cellRender: any) => {
    return (
      <Button
        icon='trash'
        text=''
        hint='Eliminar'
        onClick={async () => {
          if (cellRender.row.data.IdPlantillaParent) {
            dispatch(addShowLoader(true))
            await _deleteTemplate(userState!.user!, cellRender.row.key)
            const templatesChild = await _getTemplateWithChild(
              userState!.user!,
              templates[0].Id as number,
            )
            setTemplates(templatesChild)
            dispatch(addShowLoader(false))
          }
          showToast(
            'No puedes eliminar una plantilla genérica',
            NotifyType.warning,
            5000,
          )
        }}
      ></Button>
    )
  }

  // function fillDataGrid(Id: number): DataGridModel {
  //   const buttonColumn: ButtonColumnModel = new ButtonColumnModel({
  //     icon: 'edit',
  //     text: '',
  //     hint: 'Editar',
  //     cellRender: async (row: any) => {
  //       setRow(row.data)
  //       const templatesChild = await _getTemplateWithChild(
  //         userState!.user!,
  //         row.data.IdPlantilla,
  //       )
  //       setTemplates(templatesChild);

  //       setTemplate(
  //         _getMapperTemplate(
  //           templatesChild.length > 0
  //             ? templatesChild[0]
  //             : new TemplateModel(),
  //         ),
  //       )
  //     },
  //   })
  //   let gridheight = '600;'
  //   gridheight = isXSmall ? '300' : '600'
  //   gridheight = isSmall ? '400' : '600'
  //   gridheight = isMedium ? '400' : '400'
  //   gridheight = isLarge ? '400' : '400'
  //   let dataGridModel = new DataGridModel({
  //     dataGridName: 'componenteGridComponent',
  //     height: gridheight,
  //     ref: gridRef,
  //     rowFocused: rowFocused,
  //     columns: structure.Fields,
  //     openRow: ({}) => {},
  //     openRowEnterKey: ({}) => {},
  //     focusEventChanged: (e: any) => {
  //       if (e.row) {
  //         setRowFocused(e.row.key);
  //       }
  //     },
  //     focusEventChanging: ({}) => {},
  //     openCreatePopup: undefined,
  //     user: userState!.user!,
  //     url: `${process.env.REACT_APP_API_URL_CORE}/ModuloComponente/` + Id,
  //     urlAux: `${process.env.REACT_APP_API_URL_CORE}/ModuloComponente/` + Id,
  //     buttonsColumns: [buttonColumn],
  //     filterRow: true,
  //     filterPanel: true,
  //     columnChooser: true,
  //     headerFilter: true,
  //     exportData: true,
  //     showSearchPanel: true,
  //     showToolbar: true,
  //     history: navigate,
  //     singularName: 'Componente',
  //     pluralName: 'Componentes',
  //     pageName: 'ModuloComponente',
  //     openedPopup: showForm,
  //   })

  //   return dataGridModel
  // }

  return (
    <div id='container-body'>
      {true ? (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.16%',
          }}
        >
          {structure?.Campos !== undefined && structure?.Campos.length > 0 ? (
            <div id='container-body'>
              <h2>Plantillas</h2>
              <SelectBox
                items={modulesParent}
                displayExpr='Nombre'
                valueExpr='IdModulo'
                placeholder='Selecciona uno de los módulos contratados'
                onValueChanged={async (e: any) => {
                  dispatch(addModule(e.value))
                  actualizarEstado()
                  const refreshButton = document.getElementsByClassName(
                    'dx-icon dx-icon-refresh',
                  )[0] as HTMLElement
                  refreshButton.click()
                }}
              />
              <h5>Vistas cargadas del módulo</h5>
              <ScrollView direction='vertical'>
                <div style={{ marginBottom: '6vh' }}>
                  <ModulesListPage
                    plantillas={templates}
                    verpopup={verPopup}
                    estadoGrid={inicioGrid}
                    modulo={module!}
                    setplantillas={setTemplates}
                    setplantilla={setTemplate}
                  ></ModulesListPage>
                </div>
              </ScrollView>
              <div>
                <Button
                  icon='save'
                  text='Nueva Plantillas'
                  type='default'
                  stylingMode='contained'
                  style={{ float: 'right', margin: '1em' }}
                  onClick={handleOpenCreatePopup}
                />
              </div>
              <h5>
                Nueva plantilla hereda de:
                <b>
                  {templates.length > 0
                    ? templates[0].Nombre
                    : '(Selecciona una vista para poder cargar sus plantillas)'}
                </b>
              </h5>
              <TreeList
                dataSource={templates ?? []}
                focusedRowEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                defaultExpandedRowKeys={[1]}
                defaultSelectedRowKeys={[1]}
                keyExpr='Id'
                parentIdExpr='IdPlantillaParent'
                id='Id'
                onFocusedRowChanged={(e: any) => {
                  setTemplate(_getMapperTemplate(e.row.data))
                }}
              >
                <SearchPanel visible={true} width={250} />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} />
                <LoadPanel enabled />
                <Column
                  type='buttons'
                  cellRender={(cellRenderData: any) => {
                    return editButtonTemplates(cellRenderData)
                  }}
                />
                <Column
                  type='buttons'
                  cellRender={(cellRenderData: any) => {
                    return deleteButtonTemplates(cellRenderData)
                  }}
                />
                <Column dataField='Nombre' />
                <Column dataField='EsGenerica' />
              </TreeList>

              <Popup
                visible={showForm}
                ref={popupPlantillaRef}
                onHiding={({}) => {
                  setShowForm(!showForm)
                  dispatch(isOpenPopup(false, popupPlantillaRef))
                }}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title='Creación Plantilla'
                resizeEnabled={true}
                showCloseButton={true}
                width='95%'
                height='95%'
              >
                <ScrollView width='100%' height='100%'>
                  <TemplateFormPage
                    row={rowSelected}
                    setShowForm={setShowForm}
                    showForm={showForm}
                    setTemplates={setTemplates}
                    plantillaParent={templates.find(
                      (x) => x.EsGenerica === true,
                    )}
                  ></TemplateFormPage>
                </ScrollView>
              </Popup>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <NotFound data={{ type: 1 }}></NotFound>
        </div>
      )}
    </div>
  )
}

export { TemplatePage }
