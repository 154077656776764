import { LogModel } from '../../models/logsModels'

export const ADD_LOGS_COMPANY = 'ADD_LOGS_COMPANY'

export interface LogState {
  logcompanies: Array<LogModel> | undefined
}

interface AddLogCompany {
  type: typeof ADD_LOGS_COMPANY
  payload: Array<LogModel> | undefined
}

export type LogActionsTypes = AddLogCompany
