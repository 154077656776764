import axiosInstance from 'src/utils/axios';
import { NotifyType, showToast } from 'src/utils/sharedUitls';
import { RolePermissionsModel } from '../models/rolePermissionsModel';
import { ModuleModel } from 'src/pages/companyAdminPages/templatesAdmin/models/moduleModel/moduleModel';
import { UserPermissionsModel } from '../models/userPermissionsModel';
import { ClaimsUpdateModel } from '../models/claimsUpdateModel';

async function _getRolePermissions(): Promise<Array<RolePermissionsModel> | []> {
  const rolePermissionsDates = await axiosInstance
  .get(`Permisos/Roles`)
    .then((data: any) => {
      if (data.status === 200) {
        return data.data;
      } else {
        showToast(
          'No se ha podido obtener los roles para los permisos.',
          NotifyType.error, 5000
        );
        return [];
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener los roles para los permisos.',
        NotifyType.error, 5000
      );
      return [];
    });
  return rolePermissionsDates;
};

async function _getPermissionsById({
  queryKey,
}: any): Promise<RolePermissionsModel | null> {
  if (queryKey[1] !== undefined) {
    const rolPermisosById = await axiosInstance
      .get(`Permisos/Roles/${queryKey[1]}`)
      .then((data: any) => {
        if (data.status === 200) {
          return data.data;
        } else {
          showToast(
            'No se ha podido obtener los permisos.',
            NotifyType.error, 5000
          );
          return null;
        };
      })
      .catch((error: any) => {
        showToast(
          'No se ha podido obtener los permisos.',
          NotifyType.error, 5000
        );
        return null;
      });
    return rolPermisosById;
  } else {
    return null;
  };
};

async function _getAllModules(): Promise<Array<ModuleModel> | []> {
  const modulos = await axiosInstance
    .get(`Modulo`)
    .then((data: any) => {
      if (data.status === 200) {
        return data.data.Datos;
      } else {
        showToast(
          'No se ha podido obtener todos los módulos.',
          NotifyType.error, 5000
        );
        return [];
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener todos los módulos.',
        NotifyType.error, 5000
      );
      return [];
    });
  return modulos;
};

async function _postPermissions(
  id: number,
  permiso: ClaimsUpdateModel,
): Promise<Boolean> {
  const agregar = await axiosInstance
    .post(`Permisos/Roles/${id}`, permiso)
    .then((data: any) => {
      if (data.status === 200) {
        return true;
      } else {
        showToast(
          'No se ha podido agregar el permiso.', 
          NotifyType.error, 5000
        );
        return false;
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido agregar el permiso.',
         NotifyType.error, 5000
      );
      return false;
    });
  return agregar;
};

async function _deletePermissions(
  id: number,
  permiso: ClaimsUpdateModel,
): Promise<Boolean> {
  const eliminar = await axiosInstance
    .delete(`Permisos/Roles/${id}`, { data: permiso })
    .then((data: any) => {
      if (data.status === 200) {
        return true;
      } else {
        showToast(
          'No se ha podido eliminar el permiso.',
          NotifyType.error, 5000
        );
        return false;
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido eliminar el permiso.', 
        NotifyType.error, 5000
      );
      return false;
    });
  return eliminar;
}

async function _getUserPermissions(): Promise<
  Array<UserPermissionsModel> | []
> {
  const usuariosPermisosDates = await axiosInstance
    .get(`Permisos/Users`)
    .then((data: any) => {
      if (data.status === 200) {
        return data.data;
      } else {
        showToast(
          'No se ha podido obtener los permisos por usuario.',
          NotifyType.error, 5000
        );
        return [];
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener los permisos por usuario.',
        NotifyType.error, 5000
      );
      return [];
    });
  return usuariosPermisosDates;
}

async function _getPermissionsUserById({
  queryKey,
}: any): Promise<UserPermissionsModel | null> {
  if (queryKey[1] === 0) {
    return null;
  }

  const rolPermisosById = await axiosInstance
    .get(`Permisos/Users/${queryKey[1]}`)
    .then((data: any) => {
      if (data.status === 200) {
        return data.data;
      } else {
        showToast(
          'No se ha podido obtener los permisos.',
          NotifyType.error,5000
        );
        return null;
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener los permisos.', 
        NotifyType.error, 5000
      );
      return null;
    });
  return rolPermisosById;
};

async function _postPermissionsByUser(
  id: number,
  permiso: ClaimsUpdateModel,
): Promise<Boolean> {
  const agregar = await axiosInstance
    .post(`Permisos/Users/${id}`, permiso)
    .then((data: any) => {
      if (data.status === 200) {
        return true;
      } else {
        showToast(
          'No se ha podido agregar el permiso.', 
          NotifyType.error, 5000
        );
        return false;
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido agregar el permiso.', 
        NotifyType.error, 5000
      );
      return false;
    });
  return agregar;
};

async function _deletePermissionsByUser(
  id: number,
  permiso: ClaimsUpdateModel,
): Promise<Boolean> {
  const eliminar = await axiosInstance
    .delete(`Permisos/Users/${id}`, { data: permiso })
    .then((data: any) => {
      if (data.status === 200) {
        return true;
      } else {
        showToast(
          'No se ha podido eliminar el permiso.',
          NotifyType.error, 5000
        );
        return false;
      };
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido eliminar el permiso.', 
        NotifyType.error, 5000
      );
      return false;
    });
  return eliminar;
};

export {
  _getRolePermissions,
  _getPermissionsById,
  _getAllModules,
  _postPermissions, 
  _deletePermissions,
  _getUserPermissions,
  _getPermissionsUserById,
  _postPermissionsByUser,
  _deletePermissionsByUser
 };
