import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { CosteCampoDatoModel, DetalleCosteCampoDatoModel } from '../models/costeModel';
import { checkStatusCode, NotifyType, showToast } from '../../../utils/sharedUitls';

export const getDetalleCoste = async (
  expedienteId: number,
  user: UserModel,
): Promise<DetalleCosteCampoDatoModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Coste/GetDetalleByExpediente/${expedienteId}`;
    let coste = new DetalleCosteCampoDatoModel();
    
    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status);
      coste = jsonConvert.deserializeObject(
        response.data, DetalleCosteCampoDatoModel,
      )
    })
    .catch((error: any) => {
      showToast('No se han podido cargar los costes', 
      NotifyType.error, 5000);
    });

    return coste
  } catch (err) {
    // Handle Error Here
  };
  return new DetalleCosteCampoDatoModel();
};

export const getCostes = async (
  expedienteId: number,
  user: UserModel,
): Promise<CosteCampoDatoModel> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    let url = `Coste/getCostesByExpediente/${expedienteId}`;
    let costes = new CosteCampoDatoModel();
    await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status);
      costes = jsonConvert.deserializeObject(
        response.data, CosteCampoDatoModel,
      )
    }).catch((error: any) => {
      showToast('No se han podido cargar los costes', 
      NotifyType.error, 5000);
    });

    return costes;
  } catch (err) {
    // Handle Error Hereeee
  };
  return new CosteCampoDatoModel();
}
