import { Box, Button, Form } from 'devextreme-react'
import React, { useCallback, useRef, useState } from 'react'
import DatosBasicosEmpresaSection from './DatosBasicosEmpresaSection'
import OtrosDatosEmpresaSection from './OtrosDatosEmpresaSection'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { getCompanyDates } from '../../services/infomaservices'
import { CompanyInformaModel } from '../../models/companyInformaModel'
import { addInformaCompany } from './../../redux/actions/InformaActions'
import { addShowLoader } from 'src/redux/actions/configActions'
import * as events from 'devextreme/events'
import * as XLSX from 'xlsx'
import { GroupItem } from 'devextreme-react/form'
import { Item } from 'devextreme-react/box'

interface Props {
  busqueda: string
}
export default function DatosEmpresaSection({ busqueda }: Props) {
  const userState = useSelector((state: RootState) => state.user)
  const companyDataState = useSelector(
    (state: RootState) => state.Informa.company,
  )

  const [data, setData]: any = useState(null)

  const itemWebRef = useRef()

  const dispatch = useDispatch()
  useQuery(['Company', userState!.user, busqueda], getCompanyDates, {
    onSuccess: (data: CompanyInformaModel | null) => {
      dispatch(addShowLoader(false))
      if (data  != null ) {
        dispatch(addInformaCompany(data))
        setData(data)
      } else {
        dispatch(addInformaCompany(undefined))
      }
    },

    refetchOnWindowFocus: false,
  })
  const getClick = useCallback(
    (element: any) => {
      const prevElement = itemWebRef.current
      if (prevElement) {
        events.off(prevElement, 'dxclick')
      }

      itemWebRef.current = element
      events.on(element, 'dxclick', (e: any) => {
        if (e.target.name === 'web') {
          if (data  != null  && data.web[0].toString() !== '') {
            window.open(data.web[0].toString(), '_blank')
          }
        } else if (e.target.name === 'email') {
          if (data  != null  && data.email !== '') {
            window.location.href = `mailto:${data.email}`
          }
        }
      })
    },

    [data],
  )

  function exportarDatos() {
    try {
      let nombreFichero = `datos empresa ${busqueda}.xlsx`
      let datos = [JSON.parse(JSON.stringify(companyDataState))]

      let datosmapeados = datos.map((dato) => ({
        Nif: dato.nif,
        'Domicilio Social': dato.domicilioSocial,
        Email: dato.email,
        Localidad: dato.localidad,
        Denominación: dato.denominacion,
        Teléfono: dato.telefono,
        'Nombre Comercial': dato.nombreComercial,
        'Forma Jurídica': dato.formaJuridica,
        Web: dato.web[0],
        Fax: dato.fax[0],
        Situación: dato.situacion,
        CNAE: dato.cnae,
        Identificativo: dato.identificativo,
        'Fecha Último Balance': dato.fechaUltimoBalance,
        'Cargo Principal': dato.cargoPrincipal,
        'Capital Social':
          dato.capitalSocial !== undefined ? dato.capitalSocial : '',
        Ventas: dato.ventas !== undefined ? dato.ventas : '',
        'Fecha Constitución': dato.fechaConstitucion,
        'Ventas Año': dato.anioVentas !== undefined ? dato.anioVentas : '',
        Empleados: dato.empleados,
      }))

      let WorkSheet = XLSX.utils.json_to_sheet(datosmapeados)

      let WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(
        WorkBook,
        WorkSheet,
        'Datos Consulta Empresa',
      )
      XLSX.writeFile(WorkBook, nombreFichero)
    } catch (e) {
      alert('no es posible exportar los datos ' + e)
    }
  }
  const cabezeraRender = () => {
    return (
      <>
        <Box direction='row' align='start' crossAlign='start' width={'auto'}>
          <Item ratio={0} baseSize={'50%'}>
            <h3>Básico</h3>
          </Item>
          {companyDataState !== undefined && (
            <Item ratio={0} baseSize={'auto'}>
              <Button
                text={'Exportar'}
                type='default'
                onClick={async (e: any) => {
                  exportarDatos()
                }}
                style={{ position: 'relative', top: '15px' }}
              />
            </Item>
          )}
        </Box>
        <div
          style={{
            position: 'relative',
            bottom: '20px',
            borderBottomStyle: 'solid',
          }}
        ></div>
      </>
    )
  }

  return (
    <div ref={getClick}>
      {cabezeraRender()}
      <Form readOnly={true} formData={companyDataState} labelLocation='top'>
        {DatosBasicosEmpresaSection()}
        {OtrosDatosEmpresaSection()}
      </Form>
    </div>
  )
}
