import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Form } from 'devextreme-react'
import { Item, Label, NumericRule, RequiredRule } from 'devextreme-react/form'
import { checkScreenSize } from 'src/utils/sharedUitls'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import { addCalculadoraTranseferencias } from '../redux/calculatorTranfers/calculadoraTransferenciasActions'
import { CalculatorTransferModel } from '../../../../models/company/calculatorTransferModel'
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm'
import { useForm } from 'react-hook-form';
import { addCompany } from '../redux/companiesActions'
import { postCompany, putCompany } from '../../../companyAdminPages/companyPage/services/companiesServices'
import { addDataFormChange } from '../../../../redux/actions/sharedUtilsActions'
import { refreshGrid } from '../../../../utils/sharedUitls'
import { addParentLinks } from '../../../companyAdminPages/companyPage/redux/link/linkActions'
import { getLinks } from '../../../companyAdminPages/companyPage/services/linksService'

function CalculatorFormComponent(props: any): ReactElement {
  const calculadoraTransferenciasState = useSelector(
    (state: RootState) =>
      state.calculatorTransfers?.calculatorTransfers,
  )
  const userState = useSelector((state: RootState) => state.user)
  const company = useSelector((state: RootState) => state.company?.company)
  const companyState = useSelector((state: RootState) => state.company)
  const emailState = useSelector((state: RootState) => state.email?.email)
  const enlaceState = useSelector((state: RootState) => state.link)
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const [loading, setLoading] = useState(false)
  const formData = useRef({})
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [props.showForm])
  const onSubmit = async (data: any) => {
    setLoading(true)
    let result: boolean = false
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }))
    }
    if (company?.Id) {
      result = await putCompany(
        userState!.user!,
        company,
        calculadoraTransferenciasState,
        enlaceState!.links,
        companyState,
        emailState,
      )
    } else {
      result = await postCompany(
        userState!.user!,
        company!,
        calculadoraTransferenciasState,
        enlaceState?.links!,
        companyState,
        emailState,
      ).then(() => {
        return true
      })
    }

    if (result) {
      setLoading(false)
      refreshGrid(props.setShowForm, true)
      props.setShowForm(false)
      localStorage.setItem(
        'parentCompanyModulos',
        JSON.stringify(companyState?.companyModulos),
      )
      localStorage.setItem(
        'parentEnlaces',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      )
    } else {
      setLoading(false)
    }
  }

  const handleUpdatedCalculadora = (property: any) => {
    dispatch(
      addCalculadoraTranseferencias({
        ...calculadoraTransferenciasState,
        ...property,
      }),
    )
  }
  const handleCancelClick = () => {
    dispatch(addCalculadoraTranseferencias(new CalculatorTransferModel()))
    props.setShowForm(false)
    /*  calculadoraFormRef.current.instance.resetValues() */
  }
  const renderButtons = () => {
    if (!props.showForm) {
      calculadoraFormRef.current.instance.resetValues()
    }
    return ButtonsForm(`calculadoraData`, handleCancelClick, false)
  }

  const calculadoraFormRef: any = useRef(null)

  if (!props.showForm) {
    if (calculadoraFormRef  != null ) {
      calculadoraFormRef.current?.instance.resetValues()
    }
  }
  return (
    <>
      {' '}
      <div
        style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.6%' }}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Form
              formData={formData.current}
              ref={calculadoraFormRef}
              disabled={loading}
              labelLocation={checkScreenSize() ? 'left' : 'top'}
              validationGroup='calculadoraData'
            >
              <Item
                dataField={'CuotaTurismos'}
                editorType={'dxNumberBox'}
                editorOptions={{
                  format: {
                    style: 'currency',
                    currency: 'EUR',
                    useGrouping: true,
                  },
                  placeholder:
                    'Cuota de turismos/todoterrenos/comerciales (Ej. 21,60)',
                  value: calculadoraTransferenciasState?.CuotaTurismos,
                  min: 0,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCalculadora({ CuotaTurismos: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <NumericRule message='El Campo debe ser numérico' />
                <Label visible={true} text='Cuota coches' />
              </Item>

              <Item
                dataField={'CuotaCiclomotores'}
                editorType={'dxNumberBox'}
                editorOptions={{
                  format: {
                    style: 'currency',
                    currency: 'EUR',
                    useGrouping: true,
                  },
                  placeholder: 'Cuota de ciclomotores',
                  value: calculadoraTransferenciasState?.CuotaCiclomotores,
                  min: 0,                  
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCalculadora({
                        CuotaCiclomotores: event.value,
                      })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <NumericRule message='El Campo debe ser numérico' />
                <Label visible={true} text='Cuota ciclomotores' />
              </Item>
              <Item
                dataField={'CuotaMotocicletas'}
                editorType={'dxNumberBox'}
                editorOptions={{
                  format: {
                    style: 'currency',
                    currency: 'EUR',
                    useGrouping: true,
                  },
                  placeholder:
                    'Cuota de motocicletas/ciclomotores/quads (Ej. 21,60)',
                    value: calculadoraTransferenciasState?.CuotaMotocicletas,
                    min: 0,
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCalculadora({
                        CuotaMotocicletas: event.value,
                      })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <NumericRule message='El Campo debe ser numérico' />
                <Label text={'Cuota motocicletas'} visible={true} />
              </Item>
              <Item
                dataField={'Mensaje'}
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Mensaje de responsabilidad legal',
                  value: calculadoraTransferenciasState?.Mensaje
                    ? calculadoraTransferenciasState?.Mensaje
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCalculadora({ Mensaje: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <Label visible={true} text='Mensaje legal' />
              </Item>
              <Item
                dataField={'key'}
                editorType={'dxTextBox'}
                visible={
                  calculadoraTransferenciasState?.Key  != null  &&
                  calculadoraTransferenciasState?.Key !== ''
                    ? true
                    : false
                }
                editorOptions={{
                  readOnly: true,
                  placeholder: 'Url Calculadora',
                  value: calculadoraTransferenciasState?.Key
                    ? `${process.env.REACT_APP_CALCULADORA_URL}?key=${calculadoraTransferenciasState?.Key}`
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCalculadora({ Key: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <Label visible={true} text='Url calculadora' />
              </Item>
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}
export { CalculatorFormComponent };
