import { TemplateFieldsModel } from '../../models/templateFieldsModel/templateFieldsModel'
import { TemplateModel } from '../../models/templateModel/templateModel'

interface TemplateState {
  template: Partial<TemplateModel> | TemplateModel | undefined
  templateFields:
    | Partial<TemplateFieldsModel[]>
    | TemplateFieldsModel[]
    | undefined

  module: number | undefined
}

const ADD_TEMPLATE = '@todo/ADD_TEMPLATE'
const ADD_TEMPLATE_FIELDS = '@todo/ADD_TEMPLATE_FIELDS'
const ADD_MODULE = 'ADD_MODULE'

interface AddTemplateRequest {
  type: typeof ADD_TEMPLATE
  payload: Partial<TemplateModel> | undefined
}
interface AddTemplateFieldsRequest {
  type: typeof ADD_TEMPLATE_FIELDS
  payload: Partial<TemplateFieldsModel[]> | undefined
}
interface AddModule {
  type: typeof ADD_MODULE
  payload: number | undefined
}

export { type TemplateState };

export {
  ADD_TEMPLATE,
  ADD_TEMPLATE_FIELDS,
  ADD_MODULE
};

export type TemplateActionsTypes =
  | AddTemplateRequest
  | AddTemplateFieldsRequest
  | AddModule;