import { RoleModel } from 'src/models/usuariosPage/roleModel';
import { UsuarioCampoDatoModel } from 'src/models/usuariosPage/usuarioCampoDatoModel';
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel';

export interface UsuariosState {
  usuariosList: UsuarioCampoDatoModel;
  usuario: UsuarioModel;
  roles: RoleModel[];
  showForm: boolean;
}

export const ADD_ROLES = 'ADD_ROLES';
export const ADD_USUARIO = 'ADD_USUARIO';
export const ADD_USUARIOS_LIST = 'ADD_USUARIOS_LIST';
export const CLEAR_USUARIO = 'CLEAR_USUARIO';
export const SHOW_FORM = 'SHOW_FORM';

interface AddUsuariosListRequest {
  type: typeof ADD_USUARIOS_LIST;
  payload: UsuarioCampoDatoModel;
}
interface AddUsuarioRequest {
  type: typeof ADD_USUARIO;
  payload: UsuarioModel;
}
interface ClearUsuarioRequest {
  type: typeof CLEAR_USUARIO;
}

interface AddRolesRequest {
  type: typeof ADD_ROLES;
  payload: RoleModel[];
}

interface ShowFormRequest {
  type: typeof SHOW_FORM;
  payload: boolean;
}

export type UsuariosActionsTypes =
  | AddUsuariosListRequest
  | AddUsuarioRequest
  | ClearUsuarioRequest
  | AddRolesRequest
  | ShowFormRequest
