// import React, { useEffect, useMemo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getExpediente } from 'src/pages/expedientePage/services/vehiculo/vehiculoService';
// import { addDetalleExpediente } from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import GenericFormComponent from 'src/components/form/itemComponent';

export default function DetallesExpediente(props: any) {
  const expedienteState = useSelector(
    (state: RootState) => state.vehiculos);
  return (
    <GenericFormComponent 
      fields={expedienteState.detalleExpediente} 
    />
  ); 
}
