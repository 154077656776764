// import React, { useEffect, useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { addDetalleCoste } from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions'
// import { getDetalleCoste } from 'src/pages/expedientePage/services/costeService'
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import GenericFormComponent from 'src/components/form/itemComponent';

export default function DetallesCostes(props: any) {
  const expedienteState = useSelector(
    (state: RootState) => state.vehiculos);
  return (
    <GenericFormComponent 
      fields={expedienteState.detalleCoste} 
     />
  );
}

