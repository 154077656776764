import { getModulesAllowed, Modules } from './allowModuloUtil'
import { UserModel } from '../models/usuario/userModel'
import {
  isAdmin,
  isEmployeeUser,
  isSuperAdmin,
} from './allowAuthUtil'
import { CompanyModuloModel } from '../models/company/companyModuloModel'

export interface Ruta {
  path?: string
  text: string
  hint?: string
  icon: string
  isAdmin?: boolean
  isSuperAdmin?: boolean
  isEmpleado?: boolean
  expanded?: boolean
  modules?: Array<Modules>
  items?: Array<Ruta>
  show?: boolean
}

function deepCopyRuta(ruta: Ruta): Ruta {
  return {
    ...ruta,
    items: ruta.items ? ruta.items.map(deepCopyRuta) : [],
  }
}

export const filtrarRutas = (
  rutas: Array<Ruta>,
  userState: UserModel,
): Array<Ruta> => {
  let rutasFiltradas = rutas.map(deepCopyRuta)
  const esSuperAdmin = isSuperAdmin(userState)
  const esAdmin = isAdmin(userState)
  const esEmpleado = isEmployeeUser(userState)
  const allowedModules = getModulesAllowed(userState)

  const hasAllowedModule = (
    route: Ruta,
    allowedModules: Array<CompanyModuloModel>,
  ) =>
    route.modules &&
    route.modules.some((module) =>
      allowedModules.some((allowedModule) => allowedModule.Code === module),
    )

  const filterRouteItems = (route: Ruta) => {
    if (route.items && route.items.length > 0) {
      route.items = route.items.filter((item) =>
        hasAllowedModule(item, allowedModules),
      )
    }
    return route
  }

  return rutasFiltradas.filter((route) => {
    if (route.modules) {
      const hasModule = hasAllowedModule(route, allowedModules)
      if (hasModule) {
        filterRouteItems(route)
      }
      return hasModule
    } else {
      if (route.show) return true
      if (esSuperAdmin && route.isSuperAdmin) return true
      if (esAdmin && route.isAdmin) return true
      if (esEmpleado && route.isEmpleado) return true
      if ((route.isAdmin || route.isSuperAdmin) && !esAdmin && !esSuperAdmin) {
        return false
      }
    }
    return false
  })
}

export const rutasAplicaciones: Array<Ruta> = [
  {
    path: '/accessOnlyCalculatorPage',
    text: 'Calculadora',
    hint: 'Calculadora',
    icon: 'percent',
    modules: [Modules.CALCULADORA],
  },
  {
    text: 'Vehículos',
    hint: 'Vehiculos',
    icon: 'car',
    modules: [Modules.EXPEDIENTES_VEHICULOS, Modules.INFORMESDEVEHICULOS],
    items: [
      // {
      //   name: 'Ficha Vehiculos'
      // },
      {
        text: 'Crear Solicitud',
        icon: 'add',
        hint: 'Crear Solicitud',
        path: '/solicitudes/crearSolicitud',
        modules: [Modules.EXPEDIENTES_VEHICULOS, Modules.SOLICITUDES],
      },
      {
        text: 'Expedientes',
        icon: 'car',
        hint: 'Vehículos',
        path: 'expedientes/vehiculos',
        modules: [Modules.SOLICITUDES],
      },
      {
        text: 'Solicitudes',
        icon: 'activefolder',
        hint: 'Listar Solicitudes',
        path: '/solicitudes/list',
        modules: [Modules.SOLICITUDES],
      },
      {
        path: '/solicitudes/remesaInformes',
        icon: 'toolbox',
        text: 'Remesa Informes',
        hint: 'Remesa Informes',
        isAdmin: true,
        isSuperAdmin: true,
        modules: [Modules.INFORMESDEVEHICULOS],
      },
    ],
  },
  {
    text: 'Escrituras',
    hint: 'Escrituras',
    icon: 'home',
    modules: [Modules.EXPEDIENTES_ESCRITURAS],
    path: 'expedientes/escrituras',
  },
  {
    text: 'RGPD',
    hint: 'RGPD',
    icon: 'file',
    isAdmin: true,
    isSuperAdmin: true,
    isEmpleado: true,
    modules: [Modules.RGPD],
    items: [
      {
        text: 'Mi RGPD',
        hint: 'Mi RGPD',
        path: '/mirgpd',
        icon: 'folder',
        isAdmin: true,
        isSuperAdmin: true,
        isEmpleado: true,
        modules: [Modules.RGPD],
      },
      {
        text: 'Consentimientos',
        hint: 'Consentimientos',
        path: '/mirgpd/consentimientos',
        icon: 'rename',
        isAdmin: true,
        isSuperAdmin: true,
        isEmpleado: true,
        modules: [Modules.RGPD],
      },
    ],
  },
  {
    text: 'Consultas',
    hint: 'Consultas',
    modules: [Modules.MICRODATOS, Modules.DATOSDEEMPRESA],
    icon: 'find',
    items: [
      {
        text: 'Microdatos',
        hint: 'Consulta de datos de vehículo por bastidor',
        path: '/microdatos/consulta-bastidores',
        icon: 'file',
        modules: [Modules.MICRODATOS],
      },
      {
        text: 'Datos de Empresa',
        hint: 'Consulta de datos de empresas por su nif',
        path: '/consultaempresa',
        icon: 'card',
        modules: [Modules.DATOSDEEMPRESA],
      },
      {
        text: 'Saldos',
        hint: 'Consulta de saldos de vehículos',
        path: '/saldo',
        icon: 'money',
        isAdmin: true,
        isSuperAdmin: true,
        modules: [Modules.DATOSDEEMPRESA],
      },
      {
        text: 'Hist Consultas',
        hint: 'Consulta de historico de empresas',
        path: '/historicoconsultas',
        icon: 'save',
        modules: [Modules.DATOSDEEMPRESA],
      },
    ],
  },
  {
    text: 'Firma Digital',
    hint: 'FIRMA DIGITAL',
    icon: 'rename',
    modules: [Modules.FIRMADIGITAL],
    items: [
      {
        text: 'Tipos de Documentos',
        hint: 'Tipos de Documentos',
        path: '/firmadigital/documentos',
        icon: 'file',
        modules: [Modules.FIRMADIGITAL],
        isAdmin: true,
        isSuperAdmin: true,
      },
    ],
  },
]
export const rutasAdministracion: Array<Ruta> = [
  {
    text: 'Almacenamiento',
    hint: 'Almacenamiento',
    icon: 'chart',
    path: '/almacenamiento',
    isAdmin: true,
    isSuperAdmin: true,
    modules: [Modules.ADMINISTRACION],
  },
  {
    text: 'Gestorías',
    hint: 'Gestorías',
    icon: 'toolbox',
    path: '/companies',
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Usuarios',
    hint: 'Usuarios',
    icon: 'group',
    path: '/usuarios',
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Logs',
    hint: 'Logs',
    icon: 'file',
    path: '/logs/company',
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Tasas',
    hint: 'Tasas',
    icon: 'money',
    isAdmin: true,
    isSuperAdmin: true,
    modules: [Modules.INFORMESDEVEHICULOS],
    items: [
      {
        text: 'Importar tasas',
        hint: 'Importar tasas',
        icon: 'upload',
        path: '/tasas/importarTasas',
        isAdmin: true,
        isSuperAdmin: true,
        modules: [Modules.INFORMESDEVEHICULOS],
      },
      {
        text: 'Almacen de Tasas',
        hint: 'Almacen de Tasas',
        icon: 'folder',
        path: '/tasas/almacenTasas',
        isAdmin: true,
        isSuperAdmin: true,
        modules: [Modules.INFORMESDEVEHICULOS],
      },
    ],
  },
]
export const rutasConfiguracion: Array<Ruta> = [
  {
    text: 'Solicitudes',
    hint: 'Configurar Solicitudes',
    icon: 'activefolder',
    path: '/solicitudesTipos',
    modules: [Modules.SOLICITUDES, Modules.IDOCCAR],
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Plantillas',
    hint: 'Plantillas',
    icon: 'cellproperties',
    path: '/plantillas',
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Perfiles',
    hint: 'Perfiles',
    icon: 'smalliconslayout',
    path: '/perfiles',
    isAdmin: true,
    isSuperAdmin: true,
  },
  {
    text: 'Ud Organizativa',
    hint: 'IDocCar - Unidades Organizativas',
    icon: 'detailslayout',
    path: '/idoccar/configuration/organizational/unit',
    isSuperAdmin: true,
  },
  {
    text: 'Conectores',
    hint: 'Configuración IDocCar',
    icon: 'verticalaligncenter',
    isAdmin: true,
    isSuperAdmin: true,
    modules: [Modules.IDOCCAR, Modules.SOLICITUDES],
    items: [
      {
        text: 'IDocCar',
        hint: 'Configuración IDocCar',
        icon: 'export',
        isAdmin: true,
        isSuperAdmin: true,
        modules: [Modules.IDOCCAR, Modules.SOLICITUDES],
        items: [
          {
            text: 'Configuracion',
            hint: 'Configuracion IDocCar',
            icon: 'preferences',
            isAdmin: true,
            isSuperAdmin: true,
            path: '/idoccar/configuration',
            modules: [Modules.IDOCCAR, Modules.SOLICITUDES],
          },
          {
            text: 'Relaciones',
            hint: 'relaciones - Estados del expediente',
            icon: 'decreaselinespacing',
            isAdmin: true,
            isSuperAdmin: true,
            path: '/idoccar/dossier/status/relation',
            modules: [Modules.IDOCCAR, Modules.SOLICITUDES],
          },
          {
            text: 'Id. Organizativas',
            hint: 'IDocCar - Unidades Organizativas',
            icon: 'detailslayout',
            isSuperAdmin: true,
            path: '/idoccar/configuration/organizational/unit',
            modules: [Modules.IDOCCAR, Modules.SOLICITUDES],
          }
        ],
      },
    ],
  },
  {
    text: 'Permisos',
    hint: 'Permisos',
    icon: 'inserttable',
    isAdmin: true,
    isSuperAdmin: true,
    items: [
      {
        text: 'Por Rol',
        hint: 'Consulta de permisos por roles',
        icon: 'bulletlist',
        path: '/permisos/rol',
        isAdmin: true,
        isSuperAdmin: true,
      },
      {
        text: 'Por Usuario',
        hint: 'Consulta de permisos por usuarios',
        icon: 'user',
        path: '/permisos/usuario',
        isAdmin: true,
        isSuperAdmin: true,
      },
    ],
  },
  {
    text: 'Temas',
    hint: 'Temas',
    icon: 'palette',
    path: 'temas',
    show: true,
  },
]
