import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form} from 'devextreme-react'
import { SimpleItem } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSize } from '../../../../utils/media-query'
import {
  getIdocCarOrganizationalUnitList,
  postIdocCarOrganizationalUnit,
  putIdocCarOrganizationalUnit,
} from '../service/idocCarOrganizationalUnitService'
import { ButtonsForm } from 'src/components/buttonsForm/ButtonsForm'
import { refreshGrid } from '../../../../utils/sharedUitls'
import { addIdocCarOrganizationalUnitAll } from '../redux/idocCarOrganizationalUnitActions'

function IdocCarOrganizationalUnitFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user)
  const idocCarOrganizationalUnitState = useSelector(
    (state: RootState) => state.idocCarOrganizationalUnit,
  )
  const {  isLarge } = useScreenSize()
  const [loading] = useState(false)
  const formData = useRef({})
  const { handleSubmit } = useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchData() {}
    fetchData()
  })

  const onSubmit = async (data: any) => {
    const { OrganizationalUnit, Nif, Name, ExternalDossierEnvironmentId } =
      formData.current as any

    if (idocCarOrganizationalUnitState?.idocCarOrganizationalUnit?.Id) {
      let formDataParsed = JSON.stringify({
        Id: idocCarOrganizationalUnitState.idocCarOrganizationalUnit?.Id,
        OrganizationalUnit: OrganizationalUnit,
        Nif: Nif,
        Name: Name,
        ExternalDossierEnvironmentId: ExternalDossierEnvironmentId,
      })
      let solicitud = await putIdocCarOrganizationalUnit(
        userState!.user!,
        formDataParsed,
      )
      if (solicitud) {
        dispatch(
          addIdocCarOrganizationalUnitAll(
            await getIdocCarOrganizationalUnitList(userState!.user!),
          ),
        )
      }
      refreshGrid(props.setShowFormIdocCarOrganizationalUnit, true)

      props.setShowFormIdocCarOrganizationalUnit(false)
    } else {
      let formDataParsed = JSON.stringify({
        OrganizationalUnit: OrganizationalUnit,
        Nif: Nif,
        Name: Name,
        ExternalDossierEnvironmentId: ExternalDossierEnvironmentId,
      })

      const solicitud = await postIdocCarOrganizationalUnit(
        userState!.user!,
        formDataParsed!,
      )
      if (solicitud) {
        refreshGrid(props.setShowFormIdocCarOrganizationalUnit, true)
        dispatch(
          addIdocCarOrganizationalUnitAll(
            await getIdocCarOrganizationalUnitList(userState!.user!),
          ),
        )
        props.setShowFormIdocCarOrganizationalUnit(false)
      }
    }
  }

  const handleCancelClick = () => {
    props.setShowFormIdocCarOrganizationalUnit(false)
    props.setIsCreatingForm(false)
    props.setIsEditingForm(false)
  }

  const renderButtons = () => {
    return ButtonsForm(
      `idocCarOrganizationalUnitData`,
      handleCancelClick,
      false,
    )
  }

  const entornos = [
    { Id: 1, Nombre: 'Expedients' },
    { Id: 2, Nombre: 'Sparta' },
  ]
  return (
    <>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              formData={formData.current}
              disabled={loading}
              //ref={formSujetoRef}
              readOnly={
                props.isCreatingForm || props.isEditingForm ? false : true
              }
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='idocCarOrganizationalUnitData'
              showColonAfterLabel={true}
            >
              <SimpleItem
                dataField='Id'
                editorType={'dxNumberBox'}
                visible={props.isCreatingForm ? false : true}
                editorOptions={{
                  value: idocCarOrganizationalUnitState
                    .idocCarOrganizationalUnit?.Id
                    ? idocCarOrganizationalUnitState.idocCarOrganizationalUnit
                        ?.Id
                    : null,
                  onValueChanged: (event: any) => {
                    /*  if (event.event) {
                        handleUpdatedSujeto({ NIF: event.value })
                      } */
                  },
                  /*   ...NIFValidator, */
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'OrganizationalUnit'}
                editorType={'dxNumberBox'}
                label={{ text: 'Ud organizativa' }}
                editorOptions={{
                  mode: 'text',
                  value: idocCarOrganizationalUnitState
                    .idocCarOrganizationalUnit?.OrganizationalUnit
                    ? idocCarOrganizationalUnitState.idocCarOrganizationalUnit
                        ?.OrganizationalUnit
                    : null
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'Nif'}
                editorType={'dxTextBox'}
                editorOptions={{
                  mode: 'text',
                  value: idocCarOrganizationalUnitState
                    .idocCarOrganizationalUnit?.Nif
                    ? idocCarOrganizationalUnitState.idocCarOrganizationalUnit
                        ?.Nif
                    : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'Name'}
                editorType={'dxTextBox'}
                label={{ text: 'Nombre' }}
                editorOptions={{
                  mode: 'text',
                  value: idocCarOrganizationalUnitState
                    .idocCarOrganizationalUnit?.Name
                    ? idocCarOrganizationalUnitState.idocCarOrganizationalUnit
                        ?.Name
                    : null,
                }}
              ></SimpleItem>
              <SimpleItem
                dataField={'ExternalDossierEnvironmentId'}
                label={{ text: 'Entorno' }}
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: entornos,
                  displayExpr: 'Nombre',
                  valueExpr: 'Id',
                  hint: 'Entorno',
                  value: idocCarOrganizationalUnitState
                    .idocCarOrganizationalUnit?.ExternalDossierEnvironmentId
                    ? idocCarOrganizationalUnitState.idocCarOrganizationalUnit
                        ?.ExternalDossierEnvironmentId
                    : null,
                }}
              ></SimpleItem>

              {props.isCreatingForm || props.isEditingForm ? (
                <SimpleItem render={renderButtons} cssClass='alignBottom' />
              ) : false}
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export default IdocCarOrganizationalUnitFormPage
