export const STRINGS = {
  DIALOG:
    '<b>Para conservar los cambios antes pulsar Guardar. Si continua perderá todos los datos cambiados</b>',
  LOGIN: {
    PROP: 'NombreUsuario',
    LABEL: 'Login usuario',
    PLACEHOLDER: 'Login usuario',
    PATTERN: '^[0-9a-zA-ZñÑáéíóúÁÉÍÓÚ]*$', // pattern="^[ A-Za-z0-9_@./#&+-]*$"
    NO_MATCH: 'Caracteres no válidos para el usuario',
    REQ: 'Debes introducir un nombre de usuario, debe ser único',
  },
  NIF: {
    PROP: 'IdentificationNumber',
    LABEL: 'Nif/Nie/Cif',
    PLACEHOLDER: 'Número de identificación(NIF,NIE,CIF...)',
    PATTERN: '^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$',
    NO_MATCH: 'Debes introducir un numero de identificación NIF/CIF/NIE',
    REQ: 'Debes introducir un numero de identificación NIF/CIF/NIE',
  },
  NOMBRE: {
    PROP: 'Nombre',
    LABEL: 'Nombre',
    PLACEHOLDER: 'Nombre',
    REQ: 'Debes introducir un Nombre',
  },
  APELLIDOS: {
    PROP: 'Apellidos',
    LABEL: 'Apellidos',
    PLACEHOLDER: 'Apellidos',
  },
  EMAIL: {
    PROP: 'Email',
    LABEL: 'Email',
    PLACEHOLDER: 'Email',
    NO_MATCH: 'Debe introducir un email con un formato correcto',
    REQ: 'El campo es requerido',
  },
  TELEFONO: {
    PROP: 'Telefono',
    LABEL: 'Teléfono',
    PLACEHOLDER: 'Teléfono',
  },
  TIPO_USUARIO: {
    PROP: 'IdTipoUsuario',
    LABEL: 'Tipo usuario',
    PLACEHOLDER: 'Seleccione un tipo',
    REQ: 'El campo tipo de usuario es obligatorio',
  },
  GESTORIA: {
    PROP: 'IdSujeto',
    LABEL: 'Gestoría',
    PLACEHOLDER: 'Seleccione una compañía',
    REQ: 'Debes elegir al menos una Compañía',
  },
  TIPO_CUENTA: {
    PROP: 'RolesTipoCuenta',
    LABEL: 'Tipo cuenta',
    REQ: 'Seleccione al menos un tipo de cuenta',
  },
  RELACION: {
    PROP: 'SujetoIdentification',
    LABEL: 'Relación con',
    PLACEHOLDER: 'Número de Identificación',
    REQ: 'Debes seleccionar relación o insertar un NIF valido',
  },
  COMPANIA: {
    PROP: 'SujetoIdentification',
    LABEL: 'Compañía',
    PLACEHOLDER: 'No seleccionado',
    REQ: 'Debes elegir al menos una Compañía',
  },
  CONTRASENA: {
    PROP: 'Password',
    LABEL: 'Contraseña',
    REQ: 'El campo es requerido',
  },
  PERFILES: {
    PROP: 'IdPerfil',
    LABEL: 'Perfiles',
    PLACEHOLDER: 'Seleccione un perfil',
    REQ: 'El campo es requerido',
  },
  BLOQUEADO: {
    PROP: 'Enabled',
    LABEL: 'Bloqueado',
  },
  SUCURSALES: {
    LABEL: 'Gestión sucursales',
    CHECK: {
      PROP: 'IsParentSucursal',
      LABEL: 'Todas las sucursales',
    },
    SELECT: {
      PROP: 'SucursalesIds',
      PLACEHOLDER: 'Añadir sucursal',
    },
  },
  TYPES: {
    TEXT_BOX: 'dxTextBox',
    SELECT_BOX: 'dxSelectBox',
    CHECK_BOX: 'dxCheckBox',
    TAG_BOX: 'dxTagBox',
    // 'dxAutocomplete' | 'dxCalendar' | 'dxCheckBox' | 'dxColorBox' | 'dxDateBox' | 'dxDropDownBox' | 'dxHtmlEditor' | 'dxLookup' | 'dxNumberBox' | 'dxRadioGroup' | 'dxRangeSlider' | 'dxSelectBox' | 'dxSlider' | 'dxSwitch' | 'dxTagBox' | 'dxTextArea' | 'dxTextBox'
  },
}
