import React, {ReactElement, useEffect, useState} from 'react'
import {getDestinoTipoVehiculo, getTipoCalle, getTipoVehiculo,} from '../service/solicitudService'
import DataSource from 'devextreme/data/data_source'
import {RootState} from '../../../../redux/combineReducers'
import { batch, useDispatch, useSelector } from 'react-redux'
import {useLocation} from 'react-router-dom'
import {clearSolicitud} from '../redux/solicitudActions'
import {SolicitudListModel} from '../models/solicitudListModel'
import {Button, Popup} from 'devextreme-react'
import {Box, Item} from 'devextreme-react/box'
import {
  actualizarArchivos,
  addActualizarListaArchivos,
  addCompradorRepresentante,
  addPagina,
  addVendedorRepresentante,
} from '../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import RequestForm from './RequestForm'
import {ToolbarItem} from 'devextreme-react/autocomplete'
import SolicitudInformeDgt from 'src/pages/expedientePage/vehiculos/SolicitudInformeDgt'
import {useScreenSize} from 'src/utils/media-query'
import {IsModuloAllowed, Modules} from "../../../../utils/allowModuloUtil";
import {hasUserClaimsToModule, isAdmin} from "../../../../utils/allowAuthUtil";
import { addDossierType } from '../../../dossierType/redux/dossierTypeActions'
import { DossierTypeModel } from '../../../dossierType/models/dossierTypeModel'

function RequestsOptions(): ReactElement {
  const solicitudState = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )

  const location = useLocation().state
  //Recogemos el estado del usuario para las autentificaciones de las llamadas
  const userState = useSelector((state: RootState) => state.user)
  //Establecer si se va a ver o no el popup del formulario
  const [formulario, setFormulario] = useState('')
  //Recogemos la informacion tipovehiculos
  const [tiposvehiculos, setTiposVehiculos] = useState({})
  //Recogemos la informacion de los destinotipovehiculos
  const [destinovehiculos, setDestinosVehiculos] = useState({})
  //Recogemos la  información del tipo de calle
  const [tipocalle, setTipoCalle] = useState({})
  const dispatch = useDispatch()
  const {  isLarge, isMedium } = useScreenSize()

  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const hasPermission = IsModuloAllowed(userState?.user!, Modules.INFORMESDEVEHICULOS) && hasUserClaimsToModule(userState?.user!, Modules.INFORMESDEVEHICULOS) && isAdmin(userState?.user!)

  //Cuando se inicie la página que envie los datos a tiposvehiculos y destinotipovehiculos
  useEffect(() => {
    darVehiculos()
    darDestinoVehiculos()
    darTipoCalle()
  }, [])

  //Cerrar los popups al no establecer el tipo de formulario
  function cerrarPopUp(): void {
    setFormulario('')
    resetear()
  }

  //Recogemos la informacion y la añadimos a la constante
  async function darVehiculos() {
    let datos = await getTipoVehiculo(userState!.user!)

    const selectBoxDataSource = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setTiposVehiculos(selectBoxDataSource)
  }

  //Recogemos la informacion y la añadimos a la constante

  async function darDestinoVehiculos() {
    let datos = await getDestinoTipoVehiculo(userState!.user!)

    const selectBoxDataSource2 = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setDestinosVehiculos(selectBoxDataSource2)
  }

  async function darTipoCalle() {
    let datos = await getTipoCalle(userState!.user!)

    const selectBoxDataSource3 = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setTipoCalle(selectBoxDataSource3)
  }

  function resetear() {
    dispatch(clearSolicitud(new SolicitudListModel()))
    let tienecompradorrepresentante =
      solicitudState!.ClientLegalRep !== undefined &&
      solicitudState!.ClientLegalRep != null &&
      solicitudState!.ClientLegalRep.Nif != null &&
      solicitudState!.ClientLegalRep.Nif !== ''
    let tienevendedorrepresentante =
      solicitudState!.SellerLegalRep !== undefined &&
      solicitudState!.SellerLegalRep != null &&
      solicitudState!.SellerLegalRep.Nif != null &&
      solicitudState!.SellerLegalRep.Nif !== ''
    batch(() => {
      dispatch(addPagina(1))
      dispatch(addDossierType(new DossierTypeModel()))
      dispatch(addCompradorRepresentante(tienecompradorrepresentante))
      dispatch(addVendedorRepresentante(tienevendedorrepresentante))
      dispatch(addActualizarListaArchivos([]))
      dispatch(actualizarArchivos([]))
    })
  }

  return (
    <React.Fragment>
      <h2>Tipo solicitudes</h2>
      <div
        style={
          isLarge
            ? {
                display: hasPermission ? 'grid' : 'flex',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '1fr 1fr',
                paddingBottom: '250px',
                justifyContent: 'center',
              }
            : isMedium
            ? {
                display: hasPermission ? 'grid' : 'flex',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '1fr 1fr',
                paddingBottom: '250px',
                justifyContent: 'center',
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '50px',
                alignItems: 'center',
                justifyContent: 'center',
              }
        }
      >
        <Box
          direction='col'
          style={{
            margin: '10px',
            width: window.innerWidth > 500 ? 290 : 350,
            justifySelf: 'end',
          }}
        >
          <Item ratio={0} baseSize={'auto'}>
            <img src={require('../imgs/Matriculaciones.png')} />
          </Item>
          <Item ratio={0} baseSize={'auto'}>
            <Button
              text='Matriculación'
              type='default'
              onClick={() => {
                dispatch(clearSolicitud(new SolicitudListModel()))
                setFormulario('MAT')
              }}
            />
          </Item>
        </Box>

        <Box
          direction='col'
          style={{
            margin: '10px',
            width: window.innerWidth > 500 ? 290 : 350,
            justifySelf: 'start',
          }}
        >
          <Item ratio={0} baseSize={'auto'}>
            <img src={require('../imgs/Transferencias.png')} />
          </Item>
          <Item ratio={0} baseSize={'auto'}>
            <Button
              text='Transferencia'
              type='default'
              onClick={() => {
                dispatch(clearSolicitud(new SolicitudListModel()))
                setFormulario('CTI')
              }}
            />
          </Item>
        </Box>

        <Box
          direction='col'
          style={{
            margin: '10px',
            width: window.innerWidth > 500 ? 290 : 350,
            justifySelf: 'end',
          }}
        >
          <Item ratio={0} baseSize={'auto'}>
            <img src={require('../imgs/BajasVehiculo.png')} />
          </Item>
          <Item ratio={0} baseSize={'auto'}>
            <Button
              text={window.innerWidth > 500 ? 'Baja temporal' : 'B.Temporal'}
              type='default'
              onClick={() => {
                dispatch(clearSolicitud(new SolicitudListModel()))
                setFormulario('ENT')
              }}
            />
          </Item>
        </Box>
        { hasPermission && (
          <Box
              direction='col'
              style={{
                margin: '10px',
                width: window.innerWidth > 500 ? 290 : 350,
                justifySelf: 'start',
              }}
          >
            <Item ratio={0} baseSize={'auto'}>
              <img
                  src={require('../imgs/InformeVehiculos.png')}
                  style={{ height: '235px' }}
              />
            </Item>
            <Item ratio={0} baseSize={'auto'}>
              <Button
                  text={
                    window.innerWidth > 500 ? 'Informe Vehículos' : 'I.Vehículos'
                  }
                  type='default'
                  onClick={() => {
                    dispatch(clearSolicitud(new SolicitudListModel()))
                    setIsPopupVisible(true) // Abre el Popup
                  }}
              />
            </Item>
          </Box>)
        }
      </div>

      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        showCloseButton={false}
        height='90%'
        width='90%'
        title='Solicitar Informe de Vehículos'
      >
        <ToolbarItem
          widget='dxButton'
          toolbar='top'
          location='after'
          options={{
            icon: 'remove',
            onClick: () => setIsPopupVisible(false),
          }}
        />
        <SolicitudInformeDgt />
      </Popup>

      {
        //Pasamos la informacion que tendra los select y para cerrar formulario
        formulario !== '' && (
          <RequestForm
            cerrar={cerrarPopUp}
            vehiculos={tiposvehiculos}
            destinos={destinovehiculos}
            calles={tipocalle}
            tipo={formulario === 'TR' ? 'CTI' : formulario}
            firmardocumento={
              location && location.firmardocumento
                ? location.firmardocumento
                : {
                    realizarfirma: false,
                    idexpediente: '',
                    idvehiculo: '',
                  }
            }
          ></RequestForm>
        )
      }
    </React.Fragment>
  )
}

export default RequestsOptions
