import { AsyncRule, EmailRule, GroupItem, RequiredRule, SimpleItem, StringLengthRule } from 'devextreme-react/form'
import React, { MutableRefObject } from 'react'
import { validandoDocumento, validoCif, validoDni } from 'src/utils/NifDni/validation'
import {
  dateToFormatDate,
  diferenciaAñosFechas,
  formatoFecha,
  formdataToCreate,
} from '../../utils/dates/formdateformat'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addVendedorRepresentante } from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { dateAString } from 'src/utils/FormatoFecha/formatoFecha'
import { useRecoilState } from 'recoil'
import { isCheckVendedorRepresentanteState } from '../../../../../recoil/sharesAtoms/atoms'

interface Props {
  sexo: Array<Object>;
  ubicaciontab: MutableRefObject<string>;
  formref: any;
};

export default function DatosPersonalesVendedorSection({
  sexo,
  ubicaciontab,
  formref,
}: Props) {
  const dispatch = useDispatch();
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  );
  const representante = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.vendedorrepresentante,
  );
  const [isCheckVendedorRepresentante, setIsCheckVendedorRepresentante] = useRecoilState(isCheckVendedorRepresentanteState);

  return (
    <GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Nif'}
          label={{ text: 'Nif/Nie/Cif' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'NIF/NIE/CIF Comprador',
            value: solicitud!.Seller.Nif !== '' ? solicitud!.Seller.Nif : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Nif = value.trim()
                //dispatch(addSolicitudList(solicitud))
                if (
                  !validoDni(solicitud!.Seller.Nif) &&
                  validoCif(solicitud!.Seller.Nif)
                ) {
                  solicitud!.Seller.Genre = 'X'
                  solicitud!.Seller.BirthDate = ''
                  //dispatch(addSolicitudList(solicitud))
                  setIsCheckVendedorRepresentante(true)
                  dispatch(addVendedorRepresentante(true))
                  formref.current.instance
                    .getEditor(ubicaciontab.current)!
                    .focus()
                } else {
                  solicitud!.Seller.Genre = ''
                  //dispatch(addSolicitudList(solicitud))
                  if (
                    solicitud!.Seller.BirthDate !== '' &&
                    diferenciaAñosFechas(
                      new Date(),
                      formatoFecha(solicitud!.Seller.BirthDate),
                    ) < 18
                  ) {
                    setIsCheckVendedorRepresentante(true)
                    dispatch(addVendedorRepresentante(true))
                    formref.current.instance
                      .getEditor(ubicaciontab.current)!
                      .focus()
                  } else if (
                    representante !== true ||
                    (representante === true && solicitud!.Seller.Genre === '')
                  ) {
                    dispatch(addVendedorRepresentante(false))
                    formref.current.instance
                      .getEditor(ubicaciontab.current)!
                      .focus()
                  }
                }
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Nif'
            },
          }}
        >
          <AsyncRule
            message='El campo numero de identificacion tiene que tener un formato valido.'
            validationCallback={(e: any) => {
              return validandoDocumento(e.value)
            }}
          />

          <RequiredRule
            message={'El campo número de identificación es necesario'}
          ></RequiredRule>

          <StringLengthRule
            message={
              'La longitud del campo número de identificación no debe ser superior a 15'
            }
            max={15}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          dataField={'Seller.Name'}
          label={{ text: 'Nombre/Razón social' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre comprador',
            value:
              solicitud!.Seller.Name !== '' ? solicitud!.Seller.Name : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Name = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Name'
            },
          }}
        >
          <RequiredRule message={'El campo nombre es necesario'}></RequiredRule>

          <StringLengthRule
            message={'La longitud del campo nombre  no debe ser superior a 150'}
            max={150}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Genre'}
          label={{ text: 'Sexo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource: sexo,
            displayExpr: 'Nombre',
            valueExpr: 'Id',
            hint: 'Sexo Comprador',
            value: solicitud!.Seller.Genre,
            onValueChanged: (e: any) => {
              if (e.value !== undefined) {
                solicitud!.Seller.Genre = e.value
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Genre'
            },
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        {solicitud!.Seller.Genre !== 'X' && (
          <SimpleItem
            dataField={'Seller.BirthDate'}
            label={{ text: 'F.Nacimiento' }}
            editorType='dxDateBox'
            editorOptions={{
              hint: 'Fecha Nacimiento Cliente',
              value:
                solicitud!.Seller.BirthDate  != null  &&
                solicitud!.Seller.BirthDate.split('-')[0] !== '0001' &&
                solicitud!.Seller.BirthDate !== ''
                  ? formatoFecha(solicitud!.Seller.BirthDate)
                  : null,
              onValueChanged: (e: any) => {
                if (
                  e.value !== undefined &&
                  e.value  != null  &&
                  e.value !== ''
                ) {
                  let año = e.value.getFullYear()
                  let mes =
                    e.value.getMonth() + 1 > 9
                      ? e.value.getMonth() + 1
                      : `0${e.value.getMonth() + 1}`
                  let dia =
                    e.value.getDate() > 9
                      ? e.value.getDate()
                      : `0${e.value.getDate()}`

                  solicitud!.Seller.BirthDate = `${año}-${mes}-${dia}`
                  //dispatch(addSolicitudList(solicitud))
                }
              },
              onKeyPress: (e: any) => {
                if (e.event.key === 'Enter') {
                  e.event.preventDefault()
                }
              },
              onFocusIn: (e: any) => {
                ubicaciontab.current = 'Seller.BirthDate'
              },
              onFocusOut: (e: any) => {
                let diferenciaAños, hoy, nacimiento
                hoy = new Date()
                nacimiento =
                  e.event.target.value  != null  && e.event.target.value !== ''
                    ? new Date(formdataToCreate(e.event.target.value))
                    : null

                diferenciaAños = diferenciaAñosFechas(hoy, nacimiento)

                if (
                  diferenciaAños < 18 &&
                  dateAString(formatoFecha(e.previousValue)) !==
                    dateAString(nacimiento)
                ) {
                  setIsCheckVendedorRepresentante(true)
                  dispatch(addVendedorRepresentante(true))
                } else if (
                  dateAString(formatoFecha(e.previousValue)) !==
                  dateAString(nacimiento)
                ) {
                  dispatch(addVendedorRepresentante(false))
                }
                formref.current.instance
                  .getEditor(ubicaciontab.current)!
                  .option(
                    'value',
                    e.event.target.value  != null  && e.event.target.value !== ''
                      ? formatoFecha(dateToFormatDate(e.event.target.value))
                      : null,
                  )
                formref.current.instance.getEditor('Seller.Surname1')!.focus()
              },
              showClearButton: true,
              useMaskBehavior: true,
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
            }}
          ></SimpleItem>
        )}

        {solicitud!.Seller.Genre !== 'X' && (
          <SimpleItem
            dataField={'Seller.Surname1'}
            label={{ text: 'Apellido 1' }}
            editorType={'dxTextBox'}
            editorOptions={{
              mode: 'text',
              hint: 'Apellido 1 Comprador',
              value:
                solicitud!.Seller.Surname1 !== ''
                  ? solicitud!.Seller.Surname1
                  : null,
              onValueChanged: ({ value }: any) => {
                if (value !== undefined && value  != null ) {
                  solicitud!.Seller.Surname1 = value.trim()
                  //dispatch(addSolicitudList(solicitud))
                }
              },
              onKeyPress: (e: any) => {
                if (e.event.key === 'Enter') {
                  e.event.preventDefault()
                }
              },
              onFocusIn: (e: any) => {
                ubicaciontab.current = 'Seller.Surname1'
              },
            }}
          >
            <StringLengthRule
              message={
                'La longitud del campo apellido no debe ser superior a 50'
              }
              max={50}
            ></StringLengthRule>
          </SimpleItem>
        )}
        {solicitud!.Seller.Genre !== 'X' && (
          <SimpleItem
            dataField={'Seller.Surname2'}
            label={{ text: 'Apellido 2' }}
            editorType={'dxTextBox'}
            editorOptions={{
              mode: 'text',
              hint: 'Apellido 2 comprador',
              value:
                solicitud!.Seller.Surname2 !== ''
                  ? solicitud!.Seller.Surname2
                  : null,
              onValueChanged: ({ value }: any) => {
                if (value !== undefined && value  != null ) {
                  solicitud!.Seller.Surname2 = value.trim()
                  //dispatch(addSolicitudList(solicitud))
                }
              },
              onKeyPress: (e: any) => {
                if (e.event.key === 'Enter') {
                  e.event.preventDefault()
                }
              },
              onFocusIn: (e: any) => {
                ubicaciontab.current = 'Seller.Surname2'
              },
            }}
          >
            <StringLengthRule
              message={
                'La longitud del campo apellido no debe ser superior a 50'
              }
              max={50}
            ></StringLengthRule>
          </SimpleItem>
        )}
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Address.Email'}
          label={{ text: 'Email' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Email Compador',
            value:
              solicitud!.Seller.Address.Email !== ''
                ? solicitud!.Seller.Address.Email
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Email = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Email'
            },
          }}
        >
          <EmailRule
            message={
              'El campo email tiene que tener un formato valido, ex: test@test.com'
            }
          ></EmailRule>

          <StringLengthRule
            message='La longitud del campo email no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.PhoneNumber'}
          label={{ text: 'Teléfono' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Telefono Comprador',
            value:
              solicitud!.Seller.Address.PhoneNumber !== ''
                ? solicitud!.Seller.Address.PhoneNumber
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.PhoneNumber = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.PhoneNumber'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo teléfono no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
    </GroupItem>
  );
};
