import React from 'react'
// devextreme
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { SelectBox } from 'devextreme-react'
// redux
import { useDispatch } from 'react-redux'
import { addShowLoader } from 'src/redux/actions/configActions'
// data
import { dataSourceTemas } from './dataSourceTemas'

import themes from 'devextreme/ui/themes'

// ----------------------------------------------------------------------------

interface Props {
  visible: boolean
  closePopup: Function
}

// ----------------------------------------------------------------------------

export function PopupTemas({ visible, closePopup }: Props) {
  const dispatch = useDispatch()

  return (
    <Popup
      title='Selección  tema'
      height='fit-content'
      maxWidth='300px'
      showCloseButton={false}
      visible={visible}
      onHiding={() => closePopup()}
    >
      <ToolbarItem
        location='after'
        widget='dxButton'
        options={{ icon: 'close', onClick: () => closePopup() }}
      />
      <SelectBox
        dataSource={dataSourceTemas}
        valueExpr='ID'
        displayExpr='Name'
        searchEnabled
        placeholder={
          localStorage.getItem('dx-theme')
            ? localStorage.getItem('dx-theme')!.replace(/\./g, ' ')!
            : 'Elige un tema'
        }
        onValueChanged={({ value }: any) => {
          dispatch(addShowLoader(true))
          window.localStorage.setItem('dx-theme', value)
          window.location.reload()
          themes.current(
            window.localStorage.getItem('dx-theme') || 'generic.light',
          )
        }}
      />
    </Popup>
  )
}
