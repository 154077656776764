import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';
import { DateConverter } from 'src/utils/dateUtils';

@JsonObject('DetalleExpedienteModel')
class DetailExpedientModel {
  @JsonProperty('SerieExpediente', String)
  SerieExpediente: string = '';

  @JsonProperty('NumeroExpediente', Number)
  NumeroExpediente: number = 0

  @JsonProperty('Descripcion', String)
  Descripcion: string = '';

  @JsonProperty('Estado', String)
  Estado: string = '';

  @JsonProperty('FechaInicio', DateConverter)
  FechaInicio: Date = new Date();

  @JsonProperty('FechaFinalPrevista', DateConverter)
  FechaFinalPrevista: Date = new Date();

  @JsonProperty('FechaFinalReal', DateConverter)
  FechaFinalReal: Date = new Date();

  @JsonProperty('FechaFin', DateConverter)
  FechaFin: Date = new Date();

  @JsonProperty('CodigoCliente', String)
  CodigoCliente: string = '';

  @JsonProperty('NombreCliente', String)
  NombreCliente: string = '';

  @JsonProperty('Colaborador', String)
  Colaborador: string = '';

  @JsonProperty('NombreColaborador', String)
  NombreColaborador: string = '';

  @JsonProperty('Transmitente', String)
  Transmitente: string = '';

  @JsonProperty('NombreTransmitente', String)
  NombreTransmitente: string = '';

  @JsonProperty('Referencia', String)
  Referencia: string = '';

  @JsonProperty('Comentario', String)
  Comentario: string = '';

  @JsonProperty('Agente', String)
  Agente: string = '';

  @JsonProperty('CodAgente', Number)
  CodAgente: Number = 0;

  @JsonProperty('Sucursal', String)
  Sucursal: string = '';
}

@JsonObject('DetalleExpedienteCampoDatoModel')
class DetailExpedientFieldDataModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [DetailExpedientModel])
  Datos: DetailExpedientModel[] = [];
}

export { DetailExpedientFieldDataModel};//, type DetailExpedientModel };