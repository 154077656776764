export function validarNifDni(documento: string): boolean {
  let verificado = false

  if (validoDni(documento)) {
    verificado = true
  }

  if (validoCif(documento)) {
    verificado = true
  }

  return verificado
}

export function validandoDocumento(documento: string) {
  return new Promise((resolve) => {
    let resultado =
      documento  != null  && documento.length !== 0
        ? validarNifDni(documento.toUpperCase())
        : true
    resolve(resultado)
  })
}

export function validoDni(dni: string): boolean {
  let numerodni
  let letradni
  let letra
  let formatodni = /^[KLMXYZ]?\d{5,8}[A-Z]$/
  dni = dni.toUpperCase()

  if (formatodni.test(dni)) {
    numerodni = dni.substr(0, dni.length - 1)
    numerodni = numerodni.replace('X', 0 as any)
    numerodni = numerodni.replace('Y', 1 as any)
    numerodni = numerodni.replace('Z', 2 as any)
    numerodni =
      dni.substr(0, 1) !== 'K' &&
      dni.substr(0, 1) !== 'L' &&
      dni.substr(0, 1) !== 'M'
        ? numerodni
        : numerodni.substring(1, dni.length - 1)
    letradni = dni.substr(dni.length - 1, 1)
    numerodni = Number(numerodni) % 23
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letra = letra.substring(numerodni, numerodni + 1)

    if (letra !== letradni) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function validoCif(cif: string): boolean {
  if (!cif || cif.length !== 9) {
    return false
  }

  let letras = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  let digitos = cif.substr(1, cif.length - 2)
  let letra = cif.substr(0, 1)
  let digitocontrol = cif.substr(cif.length - 1)
  let suma = 0
  let digito

  if (!letra.match(/[A-Z]/)) {
    return false
  }

  for (var i = 0; i < digitos.length; ++i) {
    digito = parseInt(digitos[i])

    if (isNaN(digito)) {
      return false
    }

    if (i % 2 === 0) {
      digito *= 2
      if (digito > 9) {
        digito = Math.trunc(digito / 10) + (digito % 10)
      }

      suma += digito
    } else {
      suma += digito
    }
  }

  suma %= 10
  if (suma !== 0) {
    digito = 10 - suma
  } else {
    digito = suma
  }

  if (letra.match(/[ABEH]/)) {
    return String(digito) === digitocontrol
  }
  if (letra.match(/[NPQRSW]/)) {
    return letras[digito] === digitocontrol
  }

  return String(digito) === digitocontrol || letras[digito] === digitocontrol
}
