import { useState, ReactElement, useEffect } from 'react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { RootState } from '../../../redux/combineReducers'
import { custom } from 'devextreme/ui/dialog'
import DataGrid, {
  Column,
  Form,
  StateStoring,
} from 'devextreme-react/data-grid'
import {
  _deleteDossierType,
  _getDossierTypes,
  _putDossierType,
} from '../services/dossierTypeServices'
import {
  addDossierType,
  addDossierTypes,
  clearDossierType,
} from '../redux/dossierTypeActions'
import { DossierTypeForm } from '../components/DossierTypeForm'
import Button from 'devextreme-react/button'
import { DossierTypeModel } from '../models/dossierTypeModel'
import { NotifyType, checkScreenSize, showToast } from 'src/utils/sharedUitls'
import { addShowLoader } from 'src/redux/actions/configActions'
import { OnExporting } from 'src/utils/export/ExportExcel'

function DossierTypePage(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const dossierTypesState: any = useSelector(
    (state: RootState) => state.dossierTypes,
  )
  const [showFormDossierTypes, setShowFormDossierTypes] = useState(false)
  async function fetchData() {
    dispatch(addDossierTypes(await _getDossierTypes(userState!.user!)))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSubmit = async (formData: Form) => {
    try {
      const result = await _putDossierType(formData)

      if (result) {
        showToast('Solicitud guardada exitosamente', NotifyType.success, 5000)
        await fetchData()
        setShowFormDossierTypes(false)
      } else {
        showToast('Error al guardar la solicitud', NotifyType.error, 5000)
      }
    } catch (error) {
      console.error('Error en onSubmit:', error)
    }
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear',
          icon: 'add',
          onClick: (_e: any) => {
            dispatch(addDossierType({ ...new DossierTypeModel() }))
            setShowFormDossierTypes(true)
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            await fetchData()
          },
        },
      },
    )
  }

  const renderGridCell = (data: any) => {
    const handleEditClick = () => {
      const selectedDossierType = dossierTypesState?.dossierTypes.find(
        (dossierType: any) => dossierType.Id === data.data.Id,
      )
      if (selectedDossierType) {
        dispatch(addDossierType(selectedDossierType))
      }
      setShowFormDossierTypes(true)
    }

    const handleDeleteClick = async (data: any) => {
      const confirmDialog = custom({
        title: 'Eliminar solicitud',
        messageHtml: '¿Desear eliminar este registro?',
        buttons: [
          {
            text: 'Cancelar',
            elementAttr: {
              class: 'usuarioCancelButtonClass',
            },
            onClick: (e) => confirmDialog.hide(),
          },
          {
            text: 'Confirmar',
            onClick: async (e) => {
              try {
                dispatch(addShowLoader(true))
                await _deleteDossierType(Number(data.data.Id))
                // Espera a que se complete la eliminación antes de actualizar el estado
                await fetchData()
                dispatch(
                  addDossierTypes(await _getDossierTypes(userState!.user!)),
                )
              } catch (error) {
                console.error('Error al eliminar la solicitud:', error)
              } finally {
                dispatch(addShowLoader(false))
                confirmDialog.hide()
              }
            },
          },
        ],
      })
      confirmDialog.show()
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon='edit'
          type='normal'
          stylingMode='contained'
          onClick={handleEditClick}
        />
        <Button
          icon='trash'
          style={{ marginLeft: '2%' }}
          type='normal'
          stylingMode='contained'
          onClick={handleDeleteClick}
        />
      </div>
    )
  }

  const renderGridTypeCell = (data: any) => {
    let parsedType = ''
    switch (data.data.DossierOriginType) {
      case 0:
        parsedType = 'ElGestor'
        break
      case 1:
        parsedType = 'Solicitudes'
        break
      case 2:
        parsedType = 'iDocCar'
        break
      default:
        break
    }
    return <div style={{ textAlign: 'center' }}>{parsedType}</div>
  }
  return (
    <>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0, textAlign: 'left' }}>
          Configuración solicitudes
        </h3>

        <div>
          <DataGrid
            height={window.innerWidth > 500 ? 800 : 'auto'}
            width={'100%'}
            dataSource={
              Array.isArray(dossierTypesState?.dossierTypes)
                ? dossierTypesState?.dossierTypes
                : []
            }
            keyExpr='Id'
            id='dossierTypesDataGrid'
            allowColumnResizing={true}
            columnResizingMode='nextColumn'
            columnMinWidth={25}
            columnAutoWidth={false}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event)
            }}
            showBorders={true}
            columnHidingEnabled={false}
            showRowLines
            paging={{ enabled: false }}
            showColumnLines
            columnChooser={{ enabled: true }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            remoteOperations={{ filtering: true, sorting: true }}
            headerFilter={{ visible: true, allowSearch: true }}
            loadPanel={{ enabled: true }}
            sorting={{ mode: 'multiple', showSortIndexes: true }}
            export={{
              allowExportSelectedData: false,
              enabled: true,
            }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            onExporting={OnExporting}
            scrolling={{ mode: 'standard' }}
          >
            <StateStoring
              enabled={true}
              type='localStorage'
              storageKey='dossierTypesDataGrid'
            />
            <Column
              dataField=''
              minWidth={'100'}
              width={'6%'}
              cellRender={renderGridCell}
            />
            <Column dataField='Id' caption='Registro' alignment='center' />
            <Column dataField='Name' caption='Nombre' alignment='center' />
            <Column
              dataField='IdTramiteElGestor'
              caption='Trámite'
              alignment='center'
            />
            <Column
              dataField='DossierSerie'
              caption='Serie'
              alignment='center'
            />
            <Column
              dataField='DossierOriginType'
              caption='Tipo'
              alignment='center'
              cellRender={renderGridTypeCell}
            />
            <Column
              dataField='DossierRemoteTypeDescription'
              caption='Tipo remoto'
              alignment='center'
            />
          </DataGrid>
        </div>
        <Popup
          visible={showFormDossierTypes}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={'Solicitud'}
          width={'50%'}
          height={'55%'}
          position={'center'}
          showCloseButton={false}
        >
          <ToolbarItem
            widget='dxButton'
            toolbar='top'
            location='after'
            options={{
              text: '',
              icon: 'close',
              onClick: (_e: any) => {
                dispatch(clearDossierType(new DossierTypeModel()))
                setShowFormDossierTypes(false)
              },
            }}
          />
          <DossierTypeForm
            setShowFormDossierTypes={() => setShowFormDossierTypes(false)}
            showFormDossierTypes={showFormDossierTypes}
            onSubmit={onSubmit}
          ></DossierTypeForm>
        </Popup>
      </div>
    </>
  )
}

export { DossierTypePage }
