import { usuarioModelGrid } from 'src/models/usuariosPage/usuarioModelGrid'

export const patronMatricula =
  /^\d\d\d\d[BCDFGHJKLMNPRSTVWXYZ][BCDFGHJKLMNPRSTVWXYZ][BCDFGHJKLMNPRSTVWXYZ]$/
export const patronMatriculaAntigua = /^[A-Z]{1,2}\d\d\d\d[A-Z]{1,2}$/

// Expresión regular para validar bastidor (VIN)
export const patronBastidor = /^[A-HJ-NPR-Z0-9]{17}$/i

// Expresión regular para validar NIVE
export const patronNIVE = /^[A-HJ-NPR-Z0-9]{32}$/i

export const PhoneValidator = {
  mask: '+1 (X00) 000-0000',
  maskRules: {
    X: /[02-9]/,
  },
  useMaskedValue: true,
  maskInvalidMessage: 'El telefono debe tener un formato correcto',
}

export const NIFValidator = {
  mask: '00000000A' || 'A00000000',
  maskRules: {
    X: /^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$/,
  },
  useMaskedValue: false,
  maskInvalidMessage:
    'El numero de identificacion tiene que tener un formato valido.',
}
export const EmailValidator = {
  mask: '+1 (X00) 000-0000',
  maskRules: {
    X: /[02-9]/,
  },
  useMaskedValue: true,
  maskInvalidMessage:
    'El Email tiene que tener un formato valido, ex: test@test.com',
}

export function esRequerido(valor: string): boolean {
  return valor.length > 0
}

export function longitudMaxima(valor: string, maximo: number): boolean {
  return valor.length <= maximo
}

export function esNumerico(valor: string) {
  return !isNaN(Number(valor))
}

export function esEmail(valor: string) {
  let verificado = false
  let email = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/

  if (email.test(valor)) {
    verificado = true
  }

  return verificado
}

export function samePassword(password: string, repetirpassword: string) {
  return new Promise((resolve) => {
    let resultado = password === repetirpassword ? true : false
    resolve(resultado)
  })
}

export function tieneTipoUsuario(id: number | null) {
  return new Promise((resolve) => {
    let resultado = id  != null  && id !== 0 ? true : false

    resolve(resultado)
  })
}

export function mismoNombreAdmin(
  nombre: string,
  nombreuserstate: string,
  esadmin: Boolean,
) {
  return new Promise((resolve) => {
    let resultado = esadmin ? (nombre !== nombreuserstate ? true : false) : true

    resolve(resultado)
  })
}

export function tieneEspaciosEnBlanco(nombre: string) {
  return new Promise((resolve) => {
    let resultado = true

    for (let index = 0; index <= nombre.length; index++) {
      if (nombre[index] === ' ') {
        resultado = false
      }
    }

    resolve(resultado)
  })
}

export function nombreRepetido(
  nombre: string,
  listausuarios: usuarioModelGrid[],
) {
  return new Promise((resolve) => {
    let resultado = true

    listausuarios.forEach((usuario) => {
      if (usuario.NombreUsuario.toLowerCase() === nombre.toLowerCase()) {
        resultado = false
      }
    })

    resolve(resultado)
  })
}

export function validarEmailsSeparadosPorComas(emails: string) {
  const emailValido = /^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
  const emailList =
    emails  != null  && emails.length > 0
      ? emails.split(',').map((email) => email.trim())
      : []
  return new Promise((resolve) => {
    resolve(
      emailList.length > 0
        ? emailList.every((email) => emailValido.test(email))
        : true,
    )
  })
}

export const validateMatricula = (value: string): boolean => {
  // Expresión regular para validar matrícula española
  const matricula = value
  if (
    patronMatricula.test(matricula) ||
    patronBastidor.test(matricula) ||
    patronNIVE.test(matricula) ||
    patronMatriculaAntigua.test(matricula)
  ) {
    return true
  } else {
    return false
  }
}

export const validateNIF = (value: string) => {
  const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  const str = value.toString().toUpperCase()

  if (!nifRexp.test(str) && !nieRexp.test(str)) {
    return false
  } else {
    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2')
    const letter = str.substr(-1)
    const charIndex = parseInt(nie.substr(0, 8)) % 23

    const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKET'
    if (validLetters.charAt(charIndex) !== letter) {
      return false
    }
  }
  return true
}

export const validateTasa = (value: string) => {
  const regex = /^\d{12}$/
  return regex.test(value)
  return regex.test(value)
}

export const validateFecha = (value: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(value)
}

export const validateNotEmpty = (value: string) => {
  return value.trim() !== ''
}

export const validateNumericInput = (value: string) => {
  const regex = /^\d{1,2}(\.\d{1,2})?$/
  return regex.test(value)
}
