import { atom } from 'recoil';

// Define el átomo Recoil
export const loadingStateAtom = atom({
  key: 'loadingStateAtom',
  default: false,
});

export const isCheckVendedorRepresentanteState = atom({
  key: 'isCheckVendedorRepresentanteState', // identificador único
  default: false, // valor por defecto
});

export const isCheckCompradorRepresentanteState = atom({
  key: 'isCheckCompradorRepresentanteState',
  default: false,
});