import axiosInstance from '../../../utils/axios'
import {
  checkStatusCode,
  methodsEndPointType,
  NotifyType,
  showToast,
} from '../../../utils/sharedUitls'
import axios from 'axios'
import firmaDigitalAxios from '../../../utils/firmadigitalaxios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { getTiposDocumentacion } from './MiRGPDServices'

async function getConsentimientosByCompany(companyId: string) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/Consentimiento/company/${companyId}`
  let response = null
  try {
    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function getConsentimientos() {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/Consentimiento`
  let response = null
  try {
    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data
    })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function getConsentimientoFileLink(
  id: number,
  fileName: string,
  token: string,
) {
  let response = null
  try {
    let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/Consentimiento/file/${id}`
    response = axios({
      url: url,
      method: methodsEndPointType.GET,
      headers: { Authorization: 'Bearer ' + token },
      responseType: 'blob', // important
    })
      .then((response: any) => {
        checkStatusCode(response.status)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error: any) => {
        showToast(
          'El archivo no existe o ya no está disponible',
          NotifyType.error,
          5000,
        )
      })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function postUpdateFileConsentimiento(
  file: FormData,
): Promise<Array<string> | []> {
  let fields: Array<string> | [] = []
  await firmaDigitalAxios
    .post('/Documents/Consentimiento/Fields', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: any) => {
      fields = response.data.fields
      if (response.data.fields.length === 0) {
        showToast(
          'No existen etiquetas para este documento',
          NotifyType.info,
          5000,
        )
      }
    })
    .catch((error: any) => {
      showToast('No se ha podido subir el fichero.', NotifyType.error, 5000)
    })
  return fields
}

async function sendEmailConsentimiento(formData: FormData, token: string) {
  let response = null
  try {
    response = await firmaDigitalAxios.post(
      '/Documents/ConsentimientoFirma',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return response
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function sendEmailConsentimientoFirmado(data: any) {
  let response = null
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/Consentimiento/sendConfirmationEmail`
  try {
    response = await axiosInstance.post(url, data)
    return response
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function deleteConsentimiento(
  companyId: string,
  consentimientoId: string,
  token: string,
) {
  let response = null
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/Consentimiento/company/${companyId}/${consentimientoId}`
  try {
    response = await axiosInstance.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

async function getCompanyDocumentationConsentimiento(companyId: string) {
  let url = `${process.env.REACT_APP_API_URL_CORE}/RGPD/DocumentacionEmpresa/company/${companyId}`
  let response = null
  const tipoDeDocumentacion = await getTiposDocumentacion()
  const consentimientoId = tipoDeDocumentacion.filter(
    (item: any) => item.Descripcion === 'Consentimiento',
  )[0].Id
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    response = await axiosInstance.get(url).then((response: any) => {
      checkStatusCode(response.status)
      return response.data.filter(
        (item: any) => item.RgpdTipoDocumentacionId === consentimientoId,
      )
    })
  } catch (error: any) {
    checkStatusCode((error as { request?: any })?.request?.status)
    if (error.response && error.response.data && error.response.data.Message) {
      showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
    }
  }
  return response
}

export {
  getConsentimientosByCompany,
  getConsentimientoFileLink,
  postUpdateFileConsentimiento,
  sendEmailConsentimiento,
  deleteConsentimiento,
  getCompanyDocumentationConsentimiento,
  sendEmailConsentimientoFirmado,
  getConsentimientos
}
