import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Popup } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  LoadPanel,
  SearchPanel,
  StateStoring,
} from 'devextreme-react/data-grid'
import {
  addDossierStatusRelation,
  addIdocCarDossierStatusRelationAll,
  clearDossierStatusRelation,
} from '../redux/idocCarRelacionesEstadosExpedientesActions'
import Button from 'devextreme-react/button'
import { ToolbarItem } from 'devextreme-react/popup'
import { custom } from 'devextreme/ui/dialog'
import IdocCarEstadosExpedientesFormPage from './idocCarRelacionesEstadosExpedientesFormPage'
import {
  deleteIdocCarRelationDossierStatus,
  getIdocCarDossierStatusRelationList,
} from '../service/idocCarRelacionesEstadosExpedientesService'
import { OnExporting } from 'src/utils/export/ExportExcel'
import { checkScreenSize } from 'src/utils/sharedUitls'

function IdocCarRelationshipsStatesFilesPage(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const idocCarDossierStatusRelationState = useSelector(
    (state: RootState) => state.idocCarDossierStatusRelation,
  )
  const [rowFocused, setRowFocused] = useState('')
  const [
    showFormIdocCarDossierStatusRelation,
    setShowFormIdocCarDossierStatusRelation,
  ] = useState(false)
  const [isCreatingForm, setIsCreatingForm] = useState(false)
  const [isEditingForm, setIsEditingForm] = useState(false)
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const dataGridIdocCarDossierStatusRelationRef: MutableRefObject<any> =
    useRef(null)

  useEffect(() => {
    async function fetchData() {
      document.addEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowFormIdocCarDossierStatusRelation(false)
        }
      })
      try {
        dispatch(
          addIdocCarDossierStatusRelationAll(
            await getIdocCarDossierStatusRelationList(userState!.user!),
          ),
        )
        await getIdocCarDossierStatusRelationList(userState!.user!)
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowFormIdocCarDossierStatusRelation(false)
        }
      })
    }
  }, [dispatch, userState])

  const renderStatusPortalCell = (data: any) => {
    let dossierStatusName = data.data.DossierStatus.Name
    return (
      <div style={{ textAlign: 'center' }}>
        {dossierStatusName ? dossierStatusName : 'Sin estado'}
      </div>
    )
  }
  const renderStatusElGestorCell = (data: any) => {
    let dossierStatusName = data.data.DossierStatusElGestor.Description
    return (
      <div style={{ textAlign: 'center' }}>
        {dossierStatusName ? dossierStatusName : 'Sin estado'}
      </div>
    )
  }

  const renderGridCell = (data: any) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          icon='edit'
          type='normal'
          stylingMode='contained'
          onClick={(e: any) => {
            if (data.key) {
              setRowFocused(data.row.data)
              dispatch(addDossierStatusRelation(data.data))
            }
            setIsEditingForm(true)
            setShowFormIdocCarDossierStatusRelation(true)
          }}
        />
        <Button
          icon='trash'
          style={{ marginLeft: '2%' }}
          type='normal'
          stylingMode='contained'
          onClick={async (e: any) => {
            setRowFocused(data.key)
            setShowFormIdocCarDossierStatusRelation(false)
            const confirmDialog = custom({
              messageHtml: '¿Desear eliminar este registro?',
              buttons: [
                {
                  text: 'Continuar',
                  onClick: async (e) => {
                    await deleteIdocCarRelationDossierStatus(
                      userState!.user!,
                      parseInt(data.data.IdStatusElGestor),
                    )
                    dispatch(addIdocCarDossierStatusRelationAll(undefined))
                    dispatch(
                      addIdocCarDossierStatusRelationAll(
                        await getIdocCarDossierStatusRelationList(
                          userState!.user!,
                        ),
                      ),
                    )
                    confirmDialog.hide()
                  },
                },
                {
                  text: 'Cancelar',
                  elementAttr: {
                    id: 'usuarioCancelButton',
                    class: 'usuarioCancelButtonClass',
                  },
                  onClick: (e) => confirmDialog.hide(),
                },
              ],
            })
            confirmDialog.show()
          }}
        />
      </div>
    )
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear',
          icon: 'add',
          onClick: (_e: any) => {
            dispatch(clearDossierStatusRelation(undefined))
            setShowFormIdocCarDossierStatusRelation(true)
            setIsCreatingForm(true)
          },
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            dispatch(
              addIdocCarDossierStatusRelationAll(
                await getIdocCarDossierStatusRelationList(userState!.user!),
              ),
            )
          },
        },
      },
    )
  }

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>
          iDocCar - Relaciones Estados de Expedientes
        </h3>
        <div>
          <DataGrid
            dataSource={
              idocCarDossierStatusRelationState.idocCarDossierStatusRelationAll
            }
            keyExpr={[
              'DossierStatus.Name',
              'DossierStatusElGestor.DossierStatus.Name',
            ]}
            allowColumnResizing
            columnResizingMode='nextColumn'
            columnMinWidth={25}
            ref={dataGridIdocCarDossierStatusRelationRef}
            style={{ width: '100%', minHeight: '250px' }}
            height='calc(110vh - 8rem - 46px)'
            allowColumnReordering
            remoteOperations
            repaintChangesOnly
            rowAlternationEnabled
            showBorders
            showRowLines
            paging={{ pageSize: 10 }}
            columnChooser={{ enabled: true }}
            columnHidingEnabled={!checkScreenSize()}
            loadPanel={{ enabled: true }}
            sorting={{ mode: 'multiple', showSortIndexes: true }}
            export={{
              allowExportSelectedData: false,
              enabled: true,
            }}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event)
            }}
            onExporting={OnExporting}
          >
            <Export enabled={true} />
            <SearchPanel visible={true} />
            <ColumnChooser enabled={true} />
            <LoadPanel enabled />
            <StateStoring
              enabled={true}
              type='localStorage'
              storageKey='idoccarDossierStatusRelation'
            />
            <ColumnFixing enabled={true} />

            <Column
              dataField=''
              minWidth={'100'}
              width={'6%'}
              cellRender={renderGridCell}
            />
            <Column
              dataField='IdDossierStatus'
              caption='Número Estado'
              visible={false}
            />
            <Column
              dataField='IdStatusElGestor'
              caption='Número Estado El portal'
              visible={false}
            />
            <Column
              dataField='DossierStatus.Name'
              caption='Estado El Portal'
              cellRender={renderStatusPortalCell}
              allowSorting={true}
              allowSearch={true}
              defaultSortOrder='asc'
            />
            <Column
              dataField='DossierStatusElGestor.Description'
              caption='Estado El Gestor'
              cellRender={renderStatusElGestorCell}
              allowSorting={true}
              allowSearch={true}
              defaultSortOrder='asc'
            />
          </DataGrid>
        </div>
        <Popup
          visible={showFormIdocCarDossierStatusRelation}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={'IdocCar'}
          width={'65%'}
          height={'65%'}
          showCloseButton={false}
        >
          <ToolbarItem
            widget='dxButton'
            toolbar='top'
            location='after'
            options={{
              text: '',
              icon: 'close',
              onClick: () => {
                if (isChangedState) {
                  let confirmDialog = custom({
                    title: '',

                    messageHtml:
                      '<b>Para conservar los cambios antes pulsar Guardar, Si continúa perderá todos los datos cambiados</b>',
                    buttons: [
                      {
                        text: 'Continuar',
                        onClick: (e) => {
                          setShowFormIdocCarDossierStatusRelation(false)
                          setIsCreatingForm(false)
                          setIsEditingForm(false)
                          confirmDialog.hide()
                        },
                      },
                      {
                        text: 'Cancelar',
                        onClick: (e) => {
                          confirmDialog.hide()
                        },
                      },
                    ],
                  })
                  confirmDialog.show().then((dialogResult: any) => {
                    console.log(dialogResult)
                  })
                } else {
                  setShowFormIdocCarDossierStatusRelation(false)
                  setIsCreatingForm(false)
                  setIsEditingForm(false)
                }
              },
            }}
          />
          <ScrollView width='100%' height='100%'>
            <IdocCarEstadosExpedientesFormPage
              row={rowFocused}
              setShowFormIdocCarDossierStatusRelation={
                setShowFormIdocCarDossierStatusRelation
              }
              showFormIdocCarDossierStatusRelation={
                showFormIdocCarDossierStatusRelation
              }
              isCreatingForm={isCreatingForm}
              setIsCreatingForm={setIsCreatingForm}
              isEditingForm={isEditingForm}
              setIsEditingForm={setIsEditingForm}
            />
          </ScrollView>
        </Popup>
      </div>
    </div>
  )
}

export { IdocCarRelationshipsStatesFilesPage }
