import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PwaInstallPopupIOSComponent from '../../components/pwaIos/pwaIos'
import { RootState } from '../../redux/combineReducers'
import { addLoggedUser, addUnLoggedUser } from './redux/authActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NotifyType, showToast } from '../../utils/sharedUitls'
import { LoginUser } from './service/authService'
import {
  addParentCompany,
  addParentCompanyModulos,
} from '../companyAdminPages/companyPage/redux/companiesActions'
import {
  getCompany,
  getCompanyModulos,
} from '../companyAdminPages/companyPage/services/companiesServices'
import { addParentLinks } from '../companyAdminPages/companyPage/redux/link/linkActions'
import { getLinks } from '../companyAdminPages/companyPage/services/linksService'
import { isNormalUser, redirectLogin } from '../../utils/allowAuthUtil'
import Cookies from 'src/components/cookies/Cookies'
import { StringToBoolean } from 'src/utils/StringToBoolean'
import {
  actualizarCookieConsent,
  getCookieConsent,
} from './utils/CookiesDelUsuario/UserCookie'
import { addCalculatorTransfers } from '../companyAdminPages/companyPage/redux/calculatorTranfers/addCalculatorTransfers'
import { _getCalculadora } from '../companyAdminPages/companyPage/services/calculadoraService'
import { Box, Button, CheckBox, Form } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { GroupItem, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { RecoverPassword } from './components/recoverPassword'
import { setTokenAuth } from 'src/utils/axios'
import { setTokenInformaAuth } from '../../utils/serviciosRuanoAxios'
import { setTokenFirmaDigitalAuth } from 'src/utils/firmadigitalaxios'
import { useCacheBuster } from 'react-cache-buster'
import { UserModel } from '../../models/usuario/userModel'

class InicioSesion {
  username: string = ''
  password: string = ''
}

function Login(): ReactElement {
  let navigate = useNavigate()
  const [iniciosesion, setInicioSesion] = useState(new InicioSesion())
  // const [UsuarioResetPasswd, setUsuarioResetPasswd] = useState('');
  // const [emailResetPasswd, setEmailResetPasswd] = useState('');
  const [checkedIsTrue, setCheckedIsTrue] = useState(false)
  const usuarioState = useSelector((state: RootState) => state.user)
  const userState = useSelector((state: RootState) => state.user)
  const [cookiesacept, setCookiesAcept] = useState(false)
  //Control del versiones
  const { checkCacheStatus } = useCacheBuster()
  //recordar usuario
  let recordarFalse = 'false'
  const [popUpResetPassword, setPopUpResetPassword] = useState(false)
  localStorage.setItem('recordarUsuario', recordarFalse)
  const dispatch = useDispatch()
  //variable para el popup
  const [usecookiesconsent, setCookiesConsent] = useState(getCookieConsent())

  function cerrarPopupResetearPass() {
    setPopUpResetPassword(false)
  }
  const enviarLogin = async () => {
    checkCacheStatus()
    try {
      let usuarioLogeado = await LoginUser(
        iniciosesion.username,
        iniciosesion.password,
      )

      if (!usuarioLogeado || !usuarioLogeado.Token) {
        // document.cookie =
        //   encodeURIComponent(name) + '=' + encodeURIComponent(value)
        dispatch(addUnLoggedUser())
      } else {
        showToast(
          '¡Usuario Correcto! Iniciando sesión...',
          NotifyType.success,
          5000,
        )

        setTokenAuth(usuarioLogeado.Token)
        setTokenInformaAuth(usuarioLogeado.Token)
        setTokenFirmaDigitalAuth(usuarioLogeado.Token)

        dispatch(
          addParentCompany(
            await getCompany(
              usuarioLogeado,
              parseInt(usuarioLogeado.CompanyId),
            ),
          ),
        )
        localStorage.setItem(
          'parentEnlaces',
          JSON.stringify(
            dispatch(
              addParentLinks(
                await getLinks(
                  usuarioLogeado,
                  parseInt(usuarioLogeado.CompanyId),
                ),
              ),
            ).payload,
          ),
        )

        const companyModulos = await getCompanyModulos(
          usuarioLogeado,
          parseInt(usuarioLogeado.CompanyId),
        )
        localStorage.setItem(
          'parentCompanyModulos',
          JSON.stringify(
            dispatch(addParentCompanyModulos(companyModulos)).payload,
          ),
        )

        dispatch(addLoggedUser(usuarioLogeado))
        if (usuarioLogeado.role !== 'Superadministrador') {
          dispatch(
            addCalculatorTransfers(
              await _getCalculadora(
                usuarioLogeado,
                parseInt(usuarioLogeado.CompanyId),
              ),
            ),
          )
        }
        const redirectUser = (user: UserModel) => {
          const expedienteUrl =
            window.location.pathname + window.location.search
          if (
            expedienteUrl.includes('idExpediente') &&
            expedienteUrl.includes('idVehiculo')
          ) {
            navigate(expedienteUrl)
          } else if (user.RutaPrincipal) {
            navigate(user.RutaPrincipal)
          } else if (isNormalUser(user)) {
            navigate(redirectLogin(companyModulos!))
          } else {
            navigate('/companies')
          }
        }

        redirectUser(usuarioLogeado)
      }
      if (checkedIsTrue) {
        let recordarTrue = 'true'
        localStorage.setItem('recordarUsuario', recordarTrue)
        setCheckedIsTrue(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  function actualizarCookiesUser(estado: string) {
    setCookiesConsent('true')

    if (StringToBoolean(estado)) {
      actualizarCookieConsent()
    }
  }
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    const user = queryParameters.get('user')
    const password = queryParameters.get('password')
    if (user  != null  && password  != null ) {
      iniciosesion.password = password
      iniciosesion.username = user
      enviarLogin()
    }
    if (usuarioState?.isLoggedIn) {
      if (isNormalUser(userState!.user!)) {
        navigate(
          redirectLogin(
            JSON.parse(localStorage.getItem('parentCompanyModulos')!),
          ),
        )
      } else {
        navigate('/companies')
      }
    }
  }, [])

  return (
    <>
      <PwaInstallPopupIOSComponent />
      <Box
        direction='row'
        align='center'
        crossAlign='center'
        width={'96%'}
        height={884}
      >
        <Item ratio={0} baseSize={300}>
          <Box direction='row' width={330} height={580} className='loginform'>
            <Item ratio={0} baseSize={25}></Item>
            <Item>
              <Box direction='col' width={285} height={500}>
                <Item baseSize={140}>
                  <img src={require('../../assets/newlogo.png')} alt='' />
                </Item>
                <Item ratio={0} baseSize={350}>
                  <Form
                    labelLocation='top'
                    validationGroup='customerData'
                    width={'auto'}
                  >
                    <GroupItem colCount={1}>
                      <SimpleItem
                        label={{ text: 'Nombre Usuario' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'text',
                          value: iniciosesion.username,
                          hint: 'Nombre De Usuario',
                          onValueChanged: (e: any) => {
                            iniciosesion.username = e.value
                          },
                        }}
                      >
                        <RequiredRule
                          message={'el nombre de usuario es obligatorio'}
                        ></RequiredRule>
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={1}>
                      <SimpleItem
                        label={{ text: 'Contraseña' }}
                        editorType={'dxTextBox'}
                        editorOptions={{
                          mode: 'password',
                          value: iniciosesion.password,
                          hint: 'Contraseña',
                          onValueChanged: (e: any) => {
                            iniciosesion.password = e.value
                          },
                        }}
                      >
                        <RequiredRule
                          message={'el nombre de usuario es obligatorio'}
                        ></RequiredRule>
                      </SimpleItem>
                    </GroupItem>
                    <GroupItem colCount={1}>
                      <Box
                        direction='row'
                        align='start'
                        crossAlign='start'
                        height={70}
                      >
                        <Item ratio={0} baseSize={220}>
                          <CheckBox
                            defaultValue={cookiesacept}
                            text='Acepto la política de cookies'
                            onValueChanged={(e: any) => {
                              setCookiesAcept(e.value)
                            }}
                          />
                        </Item>
                      </Box>
                    </GroupItem>
                    <GroupItem colCount={1}>
                      <Box
                        direction='row'
                        align='center'
                        crossAlign='center'
                        height={60}
                      >
                        <Item ratio={0} baseSize={160}>
                          <Button
                            text={'Acceder'}
                            type='default'
                            disabled={
                              !(
                                iniciosesion.username &&
                                iniciosesion.password &&
                                cookiesacept
                              )
                            }
                            onClick={(e: any) => {
                              let result = e.validationGroup.validate()
                              if (result.isValid) {
                                enviarLogin()
                              } else {
                                showToast(
                                  `Usuario y Contraseña Deben Estar Rellenos`,
                                  NotifyType.error,
                                  5000,
                                )
                              }
                            }}
                          />
                        </Item>
                      </Box>
                    </GroupItem>
                    <GroupItem colCount={1}>
                      <Box
                        direction='row'
                        align='center'
                        crossAlign='center'
                        height={40}
                      >
                        <Item ratio={0} baseSize={190}>
                          <Button
                            text={'Recuperar Contraseña'}
                            type='default'
                            onClick={(e: any) => {
                              setPopUpResetPassword(!popUpResetPassword)
                            }}
                          />
                        </Item>
                      </Box>
                    </GroupItem>
                  </Form>
                </Item>
                <Item>
                  <Box
                    direction='row'
                    width={'auto'}
                    align='center'
                    crossAlign='center'
                  >
                    <Item baseSize={35}></Item>

                    <Item ratio={0} baseSize={'auto'}>
                      <a
                        href='https://www.elgestor.com/aviso-legal/'
                        className='links'
                      >
                        Aviso Legal
                      </a>
                    </Item>

                    <Item ratio={0} baseSize={'auto'}>
                      <a
                        href='https://www.elgestor.com/politica-privacidad/'
                        className='links'
                      >
                        Política Privacidad
                      </a>
                    </Item>

                    <Item ratio={0} baseSize={'auto'}>
                      <a
                        href='https://www.elgestor.com/politica-corporativa/'
                        className='links'
                      >
                        {' '}
                        Política Corporativa
                      </a>
                    </Item>
                  </Box>
                </Item>
              </Box>
            </Item>
          </Box>
        </Item>
      </Box>
      <RecoverPassword
        verpopup={popUpResetPassword}
        close={cerrarPopupResetearPass}
      ></RecoverPassword>
      {!StringToBoolean(usecookiesconsent!) && (
        <Cookies actualizarestado={actualizarCookiesUser} />
      )}
      ;
    </>
  )
}

export { Login }
