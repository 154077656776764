import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Box, Button, Form, Popup, ScrollView, SelectBox, TextArea } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { ToolbarItem } from 'devextreme-react/date-box';
import { Lines } from '../../models/ILines';
import { addLineText, deleteLineText } from '../../utils/textoCustomUtil';
import { ExpedientFieldModel } from '../../models/ExpedienteFieldModels';
import { SendFormFieldsModel } from '../../models/SendFormFieldsModel';
import { GroupItem, SimpleItem } from 'devextreme-react/form';
import { NotifyType, showToast } from 'src/utils/sharedUitls';
import { useDispatch } from 'react-redux';

// Definición de las propiedades que se esperan en el componente
interface Props {
  text: string;
  lines: Lines[];
  setLines: Function;
  setText: Function;
  close: Function;
  listLabels: ExpedientFieldModel[];
  send: Function;
  sendFormField: SendFormFieldsModel;
  filteredFields: String[];
  setSendFormField: Function;
  edit: Boolean;
  sendAssociations: Function;
}

// Clase que define el tamaño de fuente para cada línea
class fontSize {
  font: string[] = [];
  size: number[] = [];
}

function FormLinesTextCustom({
  lines,
  setLines,
  close,
  listLabels,
  send,
  sendFormField,
  filteredFields,
  setSendFormField,
  edit,
  sendAssociations,
}: Props) {
  const textBoxRef: MutableRefObject<any> = useRef([]);
  const boxRef: MutableRefObject<any> = useRef([]);
  const cajaRef: MutableRefObject<any> = useRef([]);
  const dispatch = useDispatch();
  const [listSizes, setListSizes]: any = useState(
    lines.map(() => {
      return new fontSize();
    }),
  );
  const [responsiveColBox, setResponsiveColBox] = useState(false);
  const [widthInputs, setWidthInputs] = useState(0);

  // Función que convierte las líneas de texto y etiquetas en un formato de texto para vista previa
  function breakDownText(): string {
    let textToDisplay = '';
    let idsText = '';
    lines.forEach((line) => {
      let label: string =
        line.label !== undefined && line.label.length > 0
          ? ` {{${line.label}}} ` : '';
      textToDisplay += line.text + label + ' ';
      let originalLabel =
        line.label !== undefined && line.label.length > 0
          ? ` {{${
              listLabels.find(
                (label) => label.description === line.label,
              )?.id
            }}} `
          : '';

      idsText += line.text + originalLabel + ' ';
    });
    sendFormField.readableFormat = textToDisplay;
    sendFormField.internalFormat = idsText;
    return textToDisplay;
  }

  // Función que maneja la respuesta al cambio de tamaño de la ventana
  function responsivePopup(e: any) {
    let difference = 0

    if (document.getElementById('bodyContent')) {
      difference = Math.trunc(
        Math.floor(document.getElementById(
          'bodyContent')!.clientWidth * 96) / 100,
      );
    }

    if (window.innerWidth <= 955) {
      setResponsiveColBox(true);
      setWidthInputs(difference);
    } else {
      setResponsiveColBox(false);
    }
  }

  useEffect(() => {
    // Agrega un escuchador de eventos para cambios en el tamaño de la ventana
    window.addEventListener('resize', responsivePopup);

    // Agrega un escuchador de eventos para la tecla Escape
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setLines(new Array<Lines>());
        close();
      }
    });

    // Limpia los escuchadores de eventos al desmontar el componente
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setLines(new Array<Lines>());
          close();
        }
      });
    }
  }, []);

  return (
    <Popup
      id='bodyContent'
      visible={true}
      showCloseButton={false}
      width={'88%'}
      title={'Establecer valor etiqueta del documento'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: () => {
            setLines(new Array<Lines>());
            close();
          },
        }}
      />
      <ScrollView>
        <React.Fragment>
          {/* Área de vista previa del texto */}
          <Box
            direction='row'
            width={'100%'}
            height={80}
            align='start'
            crossAlign='start'
          >
            <Item ratio={0} baseSize={'100%'}>
              <Form>
                <GroupItem>
                  <SimpleItem label={{ text: 'Vista previa' }}>
                    <TextArea
                      readOnly={true}
                      autoResizeEnabled={true}
                      value={breakDownText()}
                    />
                  </SimpleItem>
                </GroupItem>
              </Form>
            </Item>
          </Box>

           {/* Área de selección de etiquetas y campos */}
          <Box
            direction='row'
            height={40}
            width={'100%'}
            align={'start'}
            crossAlign={'start'}
          >
            <Item ratio={0} baseSize={'38%'}>
              <Form>
                <GroupItem>
                  <SimpleItem label={{ text: 'Etiqueta del documento' }}>
                    {!edit && (
                      <SelectBox
                        dataSource={filteredFields}
                        searchEnabled={true}
                        searchMode='contains'
                        onValueChange={(e) => {
                          setSendFormField({ ...sendFormField, name: e });
                        }}
                        value={sendFormField.name}
                      ></SelectBox>
                    )}
                    {edit && (
                      <SelectBox
                        readOnly={true}
                        dataSource={[sendFormField.name]}
                        value={sendFormField.name}
                      ></SelectBox>
                    )}
                  </SimpleItem>
                </GroupItem>
              </Form>
            </Item>
          </Box>

          {/* Botón de Guardar y mensaje de ayuda */}
          <Box direction='row' width={'100%'} align={'end'} crossAlign={'end'}>
            <Item ratio={0} baseSize={'auto'}>
              <Button
                text='Guardar'
                type='default'
                onClick={() => {
                  
                  if (sendFormField.name !== '') {
                    let envioDatos = sendFormField;
                    // if (!edit) { 
                    envioDatos.expedientFieldsIds = [];
                    // } 
                    let valorrepetido: String[] = [];

                    lines.forEach((line) => {
                      if (line.id && line.id !== '') {
                        envioDatos.expedientFieldsIds.push(line.id)
                      } 
                            
                      let repeticion = 0;
                      lines.forEach((segundavuelta) => {
                        if (
                          line.label !== undefined &&
                          line.label !== '' &&
                          segundavuelta.label !== undefined &&
                          segundavuelta.label !== '' &&
                          segundavuelta.label === line.label
                        ) {
                          repeticion += 1;
                        }
                      });

                      if (repeticion > 1) {
                        valorrepetido.push(line.label!)
                      }
                    });

                    if (
                      valorrepetido.length === 0 &&
                      sendFormField.expedientFieldsIds.length > 0
                    ) {
                      setSendFormField([envioDatos]);
                      sendAssociations([envioDatos]);
                    } else {
                      envioDatos.expedientFieldsIds = [];
                      setSendFormField(envioDatos);
                      showToast(
                        ` Las etiquetas ${valorrepetido
                          .filter((label, index) => {
                            return valorrepetido.indexOf(label) === index;
                          })
                          .toString()} están repetidas o debes añadir al menos una.`,
                        NotifyType.error, 5000
                      );
                    }
                  } else {
                    showToast(
                      'Debes añadir la etiqueta del documento',
                      NotifyType.error, 5000
                    );
                  }
                }}
              />
            </Item>
          </Box>

          {/* Espaciador (mala práctica) */}
          <Box height={60}>
            <Item></Item>
          </Box>

          {/* Mensaje de ayuda */}
          <Box
            direction='row'
            width={'100%'}
            align='start'
            crossAlign='start'
            height={60}
          >
            <Item ratio={0} baseSize={'auto'}>
              <p>
                Indique el valor de la etiqueta del documento seleccionada,
                siendo texto y/o cualquier campo del expediente:
              </p>
            </Item>
          </Box>

          {/* Lista de líneas de texto personalizadas */}
          <div
            id='bodyTextoCustoms'
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              margin: '4px',
            }}
          >
            {lines.map((line) => {
              return (
                <Box
                  direction='row'
                  align='start'
                  crossAlign='start'
                  width={'auto'}
                  style={{
                    margin: '6px',
                  }}
                >
                  {/* Botón para eliminar la línea de texto */}
                  <Item ratio={0} baseSize={'auto'}>
                    <Box
                      direction='row'
                      align='end'
                      crossAlign='end'
                      width={'auto'}
                    >
                      <Item ratio={0} baseSize={'auto'}>
                        {line.line !== 1 && (
                          <Button
                            type='default'
                            icon='close'
                            onClick={() => {
                              let newLines: Lines[] = deleteLineText(
                                line.line,
                                lines,
                              )
                              setLines(newLines)
                            }}
                          />
                        )}
                      </Item>


                     {/* Separador (mala práctica) */}
                      <Item ratio={0} baseSize={'auto'}>
                        <span style={{ width: '6px' }}></span>
                      </Item>

                      {/* Botón para añadir una nueva línea de texto */}
                      <Item ratio={0} baseSize={'auto'}>
                        <Button
                          type='default'
                          icon='add'
                          onClick={() => {
                            let newLines: Lines[] = addLineText(
                              line.line,
                              lines,
                            );
                            let listaDeTamaños = listSizes;
                            newLines.forEach((line) => {
                              if (
                                listaDeTamaños[line.line - 1] === undefined ||
                                listaDeTamaños[line.line - 1] == null 
                              ) {
                                listaDeTamaños[line.line - 1] = [];
                              }
                            });
                            setListSizes(listaDeTamaños);
                            setLines(newLines);
                          }}
                        />
                      </Item>
                    </Box>

                    {/* Área para el texto y la etiqueta */}
                    <Box
                      width={'100%'}
                      id={`boxLinea ${line.line}`}
                      ref={(element) =>
                        (cajaRef.current[line.line - 1] = element)
                      }
                      direction={'row'}
                      align='start'
                      crossAlign='start'
                      style={{
                        padding: '4px',
                        margin: '6px',
                        borderTop: '1px solid',
                        borderBottom: '1px solid',
                        borderLeft: '1px solid',
                        borderRight: '1px solid',
                      }}
                    >
                      <Item
                        ref={(element) =>
                          (boxRef.current[line.line - 1] = element)
                        }
                        ratio={0}
                        baseSize={'100%'}
                      >
                        <Box
                          width={'100%'}
                          direction={!responsiveColBox ? 'row' : 'col'}
                          align='start'
                          crossAlign='start'
                          style={{ margin: '4px' }}
                        >

                          {/* Área para el texto */}
                          <Item ratio={0} baseSize={'100%'} visible={true}>
                            <TextArea
                              autoResizeEnabled={true}
                              id={`textBox ${line.line - 1}`}
                              ref={(element) =>
                                (textBoxRef.current[line.line - 1] = element)
                              }
                              onValueChanged={(e) => {
                                let textLines = lines.map((textLine) => {
                                  if (textLine.line === line.line) {
                                    textLine.text = e.value

                                    return textLine
                                  } else {
                                    return textLine
                                  }
                                })
                                setLines(textLines)
                              }}
                              value={line.text}
                              width={!responsiveColBox ? 378 : widthInputs}
                            />
                          </Item>

                          {/* Área para la etiqueta */}
                          <Item ratio={0} baseSize={'100%'}>
                            <SelectBox
                              id={`select ${line.line}`}
                              width={!responsiveColBox ? 380 : widthInputs}
                              dataSource={listLabels}
                              displayExpr={'description'}
                              displayValue={'id'}
                              searchEnabled={true}
                              searchMode='contains'
                              searchExpr={['description']}
                              onValueChanged={(e) => {
                                if (e.event) {
                                  let newLabel = lines.map(
                                    (textLine) => {
                                      if (textLine.line === line.line) {
                                        textLine.label =
                                          e.value.description
                                        textLine.id = e.value.id
                                        return textLine
                                      } else {
                                        return textLine
                                      }
                                    },
                                  )

                                  setLines(newLabel)
                                }
                              }}
                              value={listLabels.find(
                                (label) =>
                                  label.description === line.label,
                              )}
                            />
                          </Item>
                        </Box>
                      </Item>
                    </Box>

                    {/* Separador (mala práctica) */}
                    <Box width={'4%'}>
                      <Item ratio={0} baseSize={'auto'}></Item>
                    </Box>
                  </Item>
                </Box>
              )
            })}
            <Item ratio={0} baseSize={'14%'}></Item>
          </div>
        </React.Fragment>
      </ScrollView>
    </Popup>
  );
};

export { FormLinesTextCustom };

