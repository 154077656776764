import React, { MutableRefObject, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { isSuperAdmin } from 'src/utils/allowAuthUtil';
import { getLogsCompany } from '../services/LogsServices';
import { LogModel } from '../models/logsModels';
import { AddLogCompany } from '../redux/actions/LogsAction';
import { checkScreenSize } from 'src/utils/sharedUitls';
import { Box, DataGrid } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { formatoFecha } from 'src/utils/FormatoFecha/formatoFecha';
import { useScreenSize } from 'src/utils/media-query';
import { addShowLoader } from 'src/redux/actions/configActions';

function LogsCompanyPage() {
  const userState = useSelector((state: RootState) => state.user);
  const { isLarge } = useScreenSize();
  const isSuperAdministrator = isSuperAdmin(userState!.user!);
  const logsDataGridRef: MutableRefObject<any> = useRef(null);

  useQuery(['LogsDeEmpresas', userState!.user, isSuperAdmin], getLogsCompany, {
    onSuccess: (data: Array<LogModel> | null) => {
      if (data  != null ) {
        dispatch(AddLogCompany(data));
      }
      dispatch(addShowLoader(false));
    },
    onError: () => {
      dispatch(addShowLoader(false));
    },
    refetchOnWindowFocus: false,
  });

  const dispatch = useDispatch();
  const logsCompanyState = useSelector(
    (state: RootState) => state.logscompany.logcompanies,
  );
  const companyName =
    logsCompanyState !== undefined 
      && logsCompanyState.length !== 0
      ? logsCompanyState[0]!.CompanyName!
      : null;

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click();
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            logsDataGridRef.current!.instance.refresh(true);
          },
        },
      },
    );
  }

  function exportExcell(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Logs.xlsx',
        );
      });
    });
    e.cancel = true;
  }

  function darFormatoFecha(rowData: any) {
    return formatoFecha(rowData.AccessDate);
  }

  return (
    <div id='container-body'>
      <Box direction='col' width='auto' align='start' crossAlign='start'>
        {companyName  != null  && (
          <Item ratio={0} baseSize={'auto'}>
            <h3>
              {isSuperAdministrator ? 'Logs empresas' : `Log empresa ${companyName}`}
            </h3>
          </Item>
        )}
        <Item ratio={0} baseSize={'auto'}>
          <DataGrid
            id='informaHistoricoConsultaDataGrid'
            keyExpr='AccessDate'
            ref={logsDataGridRef}
            dataSource={logsCompanyState}
            style={{ width: 'auto', minHeight: '250px' }}
            height='calc(100vh - 8rem - 46px)'
            allowColumnReordering
            allowColumnResizing
            cacheEnabled
            columnAutoWidth
            focusedRowEnabled
            remoteOperations = {{filtering: true}}
            repaintChangesOnly
            rowAlternationEnabled
            showBorders
            showRowLines
            columnChooser={{ enabled: true }}
            columnHidingEnabled={!checkScreenSize()}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            headerFilter={{ visible: true, allowSearch: true }}
            loadPanel={{ enabled: true }}
            paging={{ enabled: false }}
            sorting={{ mode: 'multiple', showSortIndexes: false }}
            export={{
              allowExportSelectedData: false,
              enabled: true,
            }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            selection={{
              mode: 'single',
            }}
            scrolling={{
              mode: 'standard',
              scrollByContent: true,
              scrollByThumb: true,
              showScrollbar: 'always',
            }}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event)
            }}
            onExporting={exportExcell}
          >
            <StateStoring
              enabled={true}
              type={'localStorage'}
              storageKey={'LogsCompany'}
            />

            <Column
              caption={'Fecha Accesos'}
              dataField={'AccessDate'}
              alignment='left'
              calculateCellValue={darFormatoFecha}
            />
            <Column
              caption={'Nombre Usuario'}
              dataField={'UserName'}
              alignment='left'
            />
            <Column
              caption={'Login'}
              dataField={'CorrectLogin'}
              alignment='left'
            />

            <Column
              caption={'CompanyId'}
              dataField={'CompanyId'}
              alignment='right'
              visible={false}
            />

            <Column
              width={isSuperAdministrator ? '13%' : 'auto'}
              caption={'Gestoria'}
              dataField={'CompanyName'}
              alignment='left'
            />
            <Column
              caption={'UserId'}
              dataField={'UserId'}
              alignment='right'
              visible={false}
            />
            <Column caption={'Ip'} dataField={'Ip'} alignment='left' />
            <Column caption={'Host'} dataField={'Host'} alignment='left' />
            <Column
              width={'48%'}
              caption={'UserAgent'}
              dataField={'UserAgent'}
              alignment='left'
            />
          </DataGrid>
        </Item>
      </Box>
    </div>
  );
}
 export { LogsCompanyPage };