import { SujetoByIdExpedienteModel } from '../models/sujetoByIdExpedienteModel'
import { SujetoModel } from '../models/sujetoModel'
import { SujetosCampoDatoModel } from '../models/sujetosCampoDatoModel'
import {
  SujetoActionsTypes,
  ADD_SUJETOS,
  ADD_SUJETO,
  CLEAR_SUJETO,
  ADD_SUJETOS_BY_EXPEDIENTE,
} from './sujetoType'

export const addSujeto = (
  sujeto: SujetoModel | undefined,
): SujetoActionsTypes => ({
  type: ADD_SUJETO,
  payload: sujeto,
})

export const clearSujeto = (
  sujeto: SujetoModel | undefined,
): SujetoActionsTypes => ({
  type: CLEAR_SUJETO,
  payload: sujeto,
})
export const addSujetos = (
  sujetos: SujetosCampoDatoModel | undefined,
): SujetoActionsTypes => ({
  type: ADD_SUJETOS,
  payload: sujetos,
})

export const AddSujetosByExpediente = (
  sujetosbyexpediente: SujetoByIdExpedienteModel[] | undefined,
): SujetoActionsTypes => ({
  type: ADD_SUJETOS_BY_EXPEDIENTE,
  payload: sujetosbyexpediente,
})
