import React, { useState } from 'react';
import { Button, DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { getColumnAlignment, getColumnType, getFileIcon } from 'src/utils/sharedUitls'
import { getExpedienteFile } from '../../../../../services/vehiculo/vehiculoService';
import { FirmaExpedienteModel } from 'src/pages/firmadigital/models/firmaExpedienteModels';
import { StateHistorySignedDataModel } from 'src/pages/firmadigital/StateHistorySigned/models/stateHistorySignedDataModel';
import { Ids } from '../../../VehiculosList';
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil';
import { UserModel } from 'src/models/usuario/userModel';

interface Props {
  firma: FirmaExpedienteModel;
  realizarfirma: Function;
  estadoFirmaDocumentos: StateHistorySignedDataModel[];
  texto: Function;
  datos: Ids;
  selectedButton: String,

}

export default function DocumentosTabVehiculo({
  firma,
  realizarfirma,
  estadoFirmaDocumentos,
  texto,
  datos,
  selectedButton,
}: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const expedienteState = useSelector((state: RootState) => state.vehiculos);
  const isFirmarAllowed = IsModuloAllowed(userState!.user!, Modules.FIRMADIGITAL);

  function cellRender(data: any): any {
    let myIcon = getFileIcon(data.data.NombreArchivoOriginal);
    return (
      <Button
        text='Descargar'
        icon={myIcon}
        hint='Descarga el documento a su equipo'
        onClick={(event: any) => {
          getExpedienteFile(
            data.data.Id,
            encodeURIComponent(data.data.NombreArchivoOriginal),
            userState!.user!,
          );
        }}
      />
    );
  };

  function firmarRender(data: any, user: UserModel): any {
    let extensionDocumento = data.data.NombreArchivoOriginal.split('.');

    return (
      ((estadoFirmaDocumentos.find(
        (expediente) =>
          expediente.documentId === data.data.Id && expediente.finished,
      ) === undefined &&
        extensionDocumento[extensionDocumento.length - 1] === 'pdf') ||
        extensionDocumento[extensionDocumento.length - 1] === 'docx') && (
          <Button
            text='Firmar'
            type='default'
            onClick={() => {
              texto('Proceso Solicitud De Firma');
              firma.documentId = data.data.Id;
              firma.expedienteId = datos.id;
              realizarfirma(true);
              selectedButton="Firmar"
            }}
          />
      )
    );
  };
  
  return (
    <DataGrid
      dataSource={expedienteState?.documentos?.Datos}
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      rowAlternationEnabled
      showBorders
      showRowLines
    >
      <Scrolling showScrollbar='always' />
      {isFirmarAllowed && (
        <Column
          type='buttons'
          cellRender={(cellRenderData: any) =>
            firmarRender(cellRenderData, userState!.user!)
          }
        />
      )}
      <Column
        type='buttons'
        cellRender={(cellRenderData: any) => cellRender(cellRenderData)}
      />
      {expedienteState?.documentos?.Datos !== undefined &&
        expedienteState?.documentos?.Campos.map((value) => (
          <Column
            key={value.Nombre}
            dataField={value.Nombre}
            caption={value.Texto}
            dataType={value.Tipo}
            format={getColumnType(value.Tipo, value.Format)}
            alignment={getColumnAlignment(value.Tipo)}
            visible={value.Nombre === 'Id' ? false : value.Visible}
          />
        ))}
    </DataGrid>
  )
}
