import axiosInstance from 'src/utils/axios'
import { authHeader } from 'src/services/sharedServices'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { LogModel } from '../models/logsModels'

export async function getLogsCompany({
  queryKey,
}: any): Promise<Array<LogModel> | null> {
  const logsDates = await axiosInstance
    .get(`/Logs`)
    .then((data: any) => {
      if (data.status === 200) {
        return data.data
      } else {
        showToast(
          'No se ha podido encontrar los logs de las empresas.',
          NotifyType.error,
          5000,
        )
        return null
      }
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido encontrar los logs de la empresa.',
        NotifyType.error,
        5000,
      )
      return null
    })

  return logsDates
}
