import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { CustomForm } from 'src/pages/expedientePage/components/CustomForm';
import NoData from 'src/pages/expedientePage/components/NoData';

export default function HaciendaTabBienes() {
  const { hacienda } = useSelector((state: RootState) => state.escrituras);
  return !!hacienda.Datos.length ? <CustomForm {...hacienda} /> : <NoData />
}
