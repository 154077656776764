import { Popup, ScrollView } from 'devextreme-react'
import { Box, Item } from 'devextreme-react/box'
import { ToolbarItem } from 'devextreme-react/date-box'
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { useNavigate } from 'react-router-dom'
import { postDocument, postSolicitud } from '../service/solicitudService'
import { DocumentModel } from '../models/documentsModel'
import DatosSolicitudSection from '../sections/datosSolicitud/datosSolicitudSection'
import TitulosSection from '../sections/nombreTitulos/TitulosSection'
import DatosCompradorSection from '../sections/datosComprador/DatosCompradorSection'
import DatosVendedorSection from '../sections/datosVendedor/DatosVendedorSection'
import DatosVehiculoSection from '../sections/datosVehiculo/datosVehiculo.page'
import {
  addCompradorRepresentante,
  addPagina,
  addPaginas,
  addServicioDestino,
  addTipoVehiculo,
  addTipoVias,
  addVendedorRepresentante,
} from '../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { addShowLoader } from 'src/redux/actions/configActions'
import {
  getDetalleVehiculo,
  getExpediente,
} from 'src/pages/expedientePage/services/vehiculo/vehiculoService'
import {
  getCostes,
  getDetalleCoste,
} from 'src/pages/expedientePage/services/costeService'
import { getTareasPendientesByExpediente } from 'src/pages/expedientePage/services/tareaService'
import {
  addCostes,
  addDetalleCoste,
  addDetalleExpediente,
  addDetalleVehiculo,
  addTareasPendientes,
} from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions'
import { useResetRecoilState } from 'recoil'
import { isCheckVendedorRepresentanteState } from 'src/recoil/sharesAtoms/atoms'
import { useQuery } from 'react-query'
import { _getDossierTypes } from '../../../dossierType/services/dossierTypeServices'
import {
  DossierTypeModel,
  InputsDocumentacion,
} from '../../../dossierType/models/dossierTypeModel'
import {addSolicitudList} from "../redux/solicitudActions";
import { addDossierType } from '../../../dossierType/redux/dossierTypeActions'

function RequestForm(props: any): ReactElement {
  const numpaginas = ['CTI', 'CTI-FE', 'CTI-FN'].includes(props.tipo) ? 4 : 3
  const dispatch = useDispatch()
  const solicitudState = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const userState = useSelector((state: RootState) => state.user)
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  const [GroupValidations, setGroupValidations] = useState(
    numpaginas === 4 ? [true, false, false, false] : [true, false, false],
  )
  const formFile = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.archivos,
  )
  const tienevendedor = ['CTI', 'CTI-FE', 'CTI-FN'].includes(props.tipo)
  const listaarchivos = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.listaarchivos,
  )
  const navigate = useNavigate()

  const formDatosSolicitud: MutableRefObject<any> = useRef(null)
  const formDatosComprador: MutableRefObject<any> = useRef(null)
  const formDatosVendedor: MutableRefObject<any> = useRef(null)
  const formDatosVehiculo: MutableRefObject<any> = useRef(null)
  const [formValidate, setFormValidate]: any = useState()
  const [inputsDocumentacion, setInputsDocumentacion] = useState<
    InputsDocumentacion[]
  >([])

  const {
    data: allDossierTypes,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['allDossierTypeS', props.tipo],
    queryFn: () => _getDossierTypes(userState?.user!),
    staleTime: Infinity,
  })
  useLayoutEffect(() => {
    if (isSuccess && allDossierTypes) {
      const dossierType = allDossierTypes.find((config: DossierTypeModel) => config.DossierRemoteTypeCode === props.tipo && config.DossierOriginType === 1)
      if (dossierType) {
        setInputsDocumentacion(dossierType.InputsDocumentacion)
        let solicitud = solicitudState!
        solicitud.DossierType = dossierType.DossierRemoteTypeCode
        solicitud.InputsDocumentacion = dossierType.InputsDocumentacion
        dispatch(addSolicitudList(solicitud))
      }
    }
  }, [isSuccess, allDossierTypes, props.tipo])

  const resetIsCheckVendedorRepresentante = useResetRecoilState(
    isCheckVendedorRepresentanteState,
  )
  const handleClose = () => {
    resetIsCheckVendedorRepresentante()
  }

  useEffect(() => {
    return () => {
      resetIsCheckVendedorRepresentante()
    }
  }, [])

  const sexo = [
    { Id: 'V', Nombre: 'Hombre' },
    { Id: 'M', Nombre: 'Mujer' },
    { Id: 'X', Nombre: 'Otros' },
  ]

  function sacarTipo(): string {
    let tipo = ''
    switch (props.tipo) {
      case 'MAT':
        tipo = 'Matriculación'
        break
      case 'CTI':
        tipo = 'Transferencia'
        break
      case 'ENT':
        tipo = 'Baja temporal'
        break
      case 'INV':
        tipo = 'Informe vehículo'
        break
    }

    return tipo
  }

useEffect(() => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      Cerrar();
    }
  };

  document.addEventListener('keydown', handleKeyDown);

  if (props.editar) {
    setGroupValidations(numpaginas === 4 ? [true, true, true, true] : [true, true, true]);
  }

  const hasValidRep = (rep: any) => rep && rep.Nif  != null  && rep.Nif !== '';

  let tienecompradorrepresentante = hasValidRep(solicitudState?.ClientLegalRep);
  let tienevendedorrepresentante = hasValidRep(solicitudState?.SellerLegalRep);

  batch(() => {
    dispatch(addPaginas(numpaginas));
    dispatch(addTipoVias(props.calles));
    dispatch(addTipoVehiculo(props.vehiculos));
    dispatch(addDossierType(props.tipo));
    dispatch(addServicioDestino(props.destinos));
    dispatch(addCompradorRepresentante(tienecompradorrepresentante));
    dispatch(addVendedorRepresentante(tienevendedorrepresentante));
  });

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, []);


  function Cerrar() {
    props.cerrar()
  }

  function actualizarValidacion(pagina: number, esvalido: boolean) {
    GroupValidations[pagina] = esvalido
    setGroupValidations([...GroupValidations])
  }

  async function enviarSolicitud() {
    dispatch(addShowLoader(true))
    solicitudState!.DossierType = props.tipo
    let checkDocumentacion = true
    if(solicitudState!.InputsDocumentacion.length > 0){
      (formFile!.length !== 0 || listaarchivos!.length !== 0) ? checkDocumentacion = true : checkDocumentacion = false
    }
    
    if (
      solicitudState!.Client.Nif !== '' &&
      solicitudState!.Client.Name !== '' &&
      (checkDocumentacion)
    ) {
      await postSolicitud(userState!.user!, solicitudState!, props.editar).then(
        async (response) => {
          dispatch(addShowLoader(true))
          if (response  != null  && response.length > 0) {
            props.firmardocumento.idexpediente = response[0]
            props.firmardocumento.idvehiculo = response[1]
            if (formFile!.length !== 0) {
              await addDocumentacion(response[0])
            } else {
              if (!props.firmardocumento.realizarfirma) {
                if (listaarchivos!.length !== 0) {
                  props.cerrar()
                }

                dispatch(addShowLoader(false))

                navigate('/solicitudes/list', {
                  state: {
                    nuevoestadofirmardocumento: props.firmardocumento,
                  },
                })

                dispatch(addPagina(1))
              }
            }
          } else {
            dispatch(addShowLoader(false))
            // showToast(
            //   !props.editar
            //     ? 'Fallo al crear la solicitud.'
            //     : 'Fallo al editar la solicitud.',
            //   NotifyType.error,
            //   5000,
            // )
          }
        },
      )
    } else {
      dispatch(addShowLoader(false))
      showToast(
        'Rellene los campos que faltan para enviar la solicitud.',
        NotifyType.error,
        5000,
      )
    }
  }

  async function obtenerDatosExpedientes() {
    await Promise.all([
      getExpediente(props.firmardocumento.idexpediente, userState!.user!),
      getDetalleCoste(props.firmardocumento.idexpediente, userState!.user!),
      getDetalleVehiculo(props.firmardocumento.idvehiculo, userState!.user!),
      getCostes(props.firmardocumento.idexpediente, userState!.user!),
      getTareasPendientesByExpediente(
        props.firmardocumento.idexpediente,
        userState!.user!,
      ),
    ]).then(
      ([
        expediente,
        detallecostes,
        detallevehiculo,
        costes,
        tareaspendientes,
      ]) => {
        dispatch(addDetalleExpediente(expediente))
        dispatch(addDetalleCoste(detallecostes))
        dispatch(addDetalleVehiculo(detallevehiculo))
        dispatch(addCostes(costes))
        dispatch(addTareasPendientes(tareaspendientes))
      },
    )
  }

  const addDocumentacion = async (id: string | number) => {
    let listadocumentos: DocumentModel[] = []
    formFile!.map((file: any) => {
      let filereader = new FileReader()
      let datosdocumentacion = new DocumentModel()
      filereader.readAsDataURL(file)
      filereader.onload = async function () {
        let pasara64 = []
        let base64: any = filereader.result ?? []
        pasara64 = base64.split(',')
        datosdocumentacion.Content = pasara64[1]
        let ubicacionextension = file.name.lastIndexOf('.')
        datosdocumentacion.Format = file.name.substring(
          ubicacionextension + 1,
          file.name.length,
        )
        datosdocumentacion.DocumentType = file.name
        listadocumentos.push(datosdocumentacion)
        if (listadocumentos.length === formFile!.length) {
          await postDocument(userState!.user!, listadocumentos, id)
            .then(async () => {
              if (props.firmardocumento.realizarfirma) {
                await obtenerDatosExpedientes()
                  .then(() => {
                    props.cerrar()

                    dispatch(addShowLoader(false))

                    navigate('/solicitudes/list', {
                      state: {
                        nuevoestadofirmardocumento: props.firmardocumento,
                      },
                    })

                    dispatch(addPagina(1))
                  })
                  .catch(() => {
                    props.cerrar()
                    dispatch(addShowLoader(false))

                    navigate('/solicitudes/list')

                    dispatch(addPagina(1))
                    showToast(
                      'Error al obtener los datos del expediente',
                      NotifyType.error,
                      5000,
                    )
                  })
              } else {
                if (listaarchivos!.length !== 0) {
                  props.cerrar()
                }

                dispatch(addShowLoader(false))

                navigate('/solicitudes/list', {
                  state: {
                    nuevoestadofirmardocumento: props.firmardocumento,
                  },
                })

                dispatch(addPagina(1))
              }
            })
            .catch(() => {
              dispatch(addShowLoader(false))
              showToast(
                !props.editar
                  ? 'Fallo al crear la solicitud.'
                  : 'Fallo al editar la solicitud.',
                NotifyType.error,
                5000,
              )
            })
        }
      }
    })
  }

  const renderDatosSolicitud = useMemo(() => {
    return (
      <DatosSolicitudSection
        inputsDocumentacion={inputsDocumentacion}
        validos={actualizarValidacion}
        formDatosSolicitud={formDatosSolicitud}
        editar={props.editar}
        archivosagregados={props.documentosagregados}
      />
    )
  }, [
    isLoading,
    isSuccess,
    inputsDocumentacion,
    props.editar,
    props.documentosagregados,
  ])

  const renderDatosComprador = useMemo(() => {
    return (
      <DatosCompradorSection
        sexo={sexo}
        validos={actualizarValidacion}
        formDatosComprador={formDatosComprador}
      />
    )
  }, [])

  const renderDatosVendedor = useMemo(() => {
    return (
      <DatosVendedorSection
        sexo={sexo}
        validos={actualizarValidacion}
        formDatosVendedor={formDatosVendedor}
      />
    )
  }, [])

  const renderDatosVehiculos = useMemo(() => {
    return (
      <DatosVehiculoSection
        validos={actualizarValidacion}
        enviarsolicitud={enviarSolicitud}
        validacion={GroupValidations}
        editar={props.editar}
        firmardocumento={props.firmardocumento}
        formDatosVehiculo={formDatosVehiculo}
      />
    )
  }, [formFile, GroupValidations])

function obtenerFormDate() {
  const isFormValid = (form: MutableRefObject<any>) => form.current !== undefined && form.current  != null ;

  const updateFormValidate = (form: MutableRefObject<any>) => {
    if (isFormValid(form) && formValidate !== form) {
      setFormValidate(form);
    }
  };

  updateFormValidate(formDatosSolicitud);
  updateFormValidate(formDatosComprador);
  updateFormValidate(formDatosVendedor);
  updateFormValidate(formDatosVehiculo);
}

  return (
    <div>
      <Popup
        visible={true}
        hideOnOutsideClick={false}
        showCloseButton={false}
        height='90%'
        width='90%'
        title={sacarTipo()}
        onHiding={handleClose}
      >
        <ToolbarItem
          widget='dxButton'
          location='after'
          options={{
            icon: 'close',
            onClick: () => Cerrar(),
          }}
        />
        <ScrollView>
          <React.Fragment>
            <Box direction='col' width={'100%'} height={'100%'}>
              <Item ratio='0' baseSize={'100%'}>
                <TitulosSection
                  validos={GroupValidations}
                  vendedor={tienevendedor}
                  comprobarform={formValidate}
                  establecerFormValidate={obtenerFormDate}
                ></TitulosSection>
              </Item>
              <Item ratio={0} baseSize={'100%'}>
                {pagina === 1 && renderDatosSolicitud}

                {pagina === 2 && renderDatosComprador}

                {numpaginas === 4 && pagina === 3 && renderDatosVendedor}

                {pagina === (numpaginas === 4 ? 4 : 3) && renderDatosVehiculos}
              </Item>
            </Box>
          </React.Fragment>
        </ScrollView>
      </Popup>
    </div>
  )
}

export default RequestForm
