import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('CompanyViaFirmaModels')
export class CompanyViaFirmaModels {
  @JsonProperty('id', Number)
  id: number = 0

  @JsonProperty('name', String)
  name: string = ''

  @JsonProperty('groupCode', String)
  groupCode: string = ''

  constructor(id: number, name: string, groupCode: string) {
    this.id = id
    this.name = name
    this.groupCode = groupCode
  }
}
