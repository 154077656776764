import {
  CompanyCampoDatoGridModel,
  CompanyCampoDatoModel,
} from 'src/models/company/companyCampoDatoModel'
import { CompanyModel } from 'src/models/company/companyModel'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'
import {
  ADD_COMPANIES,
  ADD_COMPANY,
  CLEAR_COMPANY,
  ADD_COMPANY_MODULO,
  CompanyActionsTypes,
  CLEAR_COMPANY_MODULO,
  ADD_COMPANY_MODULO_SELECTED,
  ADD_PARENT_COMPANY,
  ADD_COMPANY_MODULO_PARENT,
  ADD_COMPANY_CODIGO_VIAFIRMA,
} from './companiesType'

export const addCompanies = (
  company: CompanyCampoDatoModel | CompanyCampoDatoGridModel | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANIES,
  payload: company,
})
export const addCompany = (
  company: Partial<CompanyModel> | CompanyModel | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANY,
  payload: company,
})

export const addParentCompany = (
  company: Partial<CompanyModel> | CompanyModel | undefined,
): CompanyActionsTypes => ({
  type: ADD_PARENT_COMPANY,
  payload: company,
})

export const clearSujeto = (
  sujeto: CompanyModel | undefined,
): CompanyActionsTypes => ({
  type: CLEAR_COMPANY,
  payload: sujeto,
})

export const clearCompanyModulos = (
  company: CompanyModuloModel[] | undefined,
): CompanyActionsTypes => ({
  type: CLEAR_COMPANY_MODULO,
  payload: company,
})

export const addCompanyModulos = (
  company: CompanyModuloModel[] | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANY_MODULO,
  payload: company,
})

export const addCompanyModulosSelected = (
  company: CompanyModuloModel[] | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANY_MODULO_SELECTED,
  payload: company,
})

export const addParentCompanyModulos = (
  company: CompanyModuloModel[] | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANY_MODULO_PARENT,
  payload: company,
})

export const AddCompanyCodigoViaFirma = (
  codigo: string | undefined,
): CompanyActionsTypes => ({
  type: ADD_COMPANY_CODIGO_VIAFIRMA,
  payload: codigo,
})
