import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('DefectoModel')
class DefectoModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('CausasDefecto', String)
  CausasDefecto: string = ''

  @JsonProperty('FechaDefecto', String)
  FechaDefecto: Date | String = ''

  @JsonProperty('FechaEntradaNotaria', String)
  FechaEntradaNotaria: Date | String = ''

  @JsonProperty('FechaRetiradaNotariaSubsanado', String)
  FechaRetiradaNotariaSubsanado: Date | String = ''

  @JsonProperty('IdEscritura', Number)
  IdEscritura: number = 0

  @JsonProperty('NotaDefecto', String)
  NotaDefecto: string = ''

  @JsonProperty('TieneCargas', Boolean)
  TieneCargas: boolean = false
}

@JsonObject('DefectoCampoDatoModel')
export class DefectoCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [DefectoModel])
  Datos: DefectoModel[] = []
}
