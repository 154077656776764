import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModel, ColumnModelCore } from 'src/models/columnModel';
import { CompanyGridModel, CompanyModel } from './companyModel';

@JsonObject('CompanyCampoDatoModel')
class CompanyCampoDatoModel {
  @JsonProperty('Campos', [ColumnModel])
  Campos: ColumnModel[] = [];

  @JsonProperty('Datos', [CompanyModel])
  Datos: CompanyModel[] = [];
};

@JsonObject('CompanyCampoDatoGridModel')
class CompanyCampoDatoGridModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [CompanyGridModel])
  Datos: CompanyGridModel[] = [];
};

export { 
  CompanyCampoDatoModel,
  CompanyCampoDatoGridModel
};