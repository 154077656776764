import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { FirmaExpedienteModel } from '../models/firmaExpedienteModels'
import firmaDigitalAxios from 'src/utils/firmadigitalaxios'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { CompanyViaFirmaModels } from '../models/companyViaFirmaModels'

export const postFirma = async (
  firma: FirmaExpedienteModel,
): Promise<Boolean> => {
  let jsonConvert: JsonConvert = new JsonConvert();
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  let realizadafirma = false;
  
  await firmaDigitalAxios
    .post('SignRequests', firma)
    .then(() => {
      realizadafirma = true
      showToast('Solicitud de la firma creada', NotifyType.success, 5000)
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido realizar la solicitud de la firma',
        NotifyType.error,
        5000,
      )
    })

  return realizadafirma;
}

export const postCompanyViaFirma = async (
  id: number,
  name: string,
  groupCode: string,
): Promise<boolean> => {
  let realizadaasociacion = false;

  let companyviafirma: CompanyViaFirmaModels = new CompanyViaFirmaModels(
    id,
    name,
    groupCode,
  );

  await firmaDigitalAxios
    .post('Company', companyviafirma)
    .then(() => {
      realizadaasociacion = true
      showToast('Asociación con via firma realizada', NotifyType.success, 5000)
    })
    .catch(() => {
      showToast(
        'No se ha podido realizar la asociación con via firma',
        NotifyType.error,
        5000,
      )
    });

  return realizadaasociacion;
}

export const getCompanyViaFirma = async (id: number): Promise<string> => {
  let codigo = '';

  await firmaDigitalAxios
    .get(`Company/${id}`)
    .then((response) => {
      codigo = response.data.groupCode
    })
    .catch(() => {
      showToast(
        'No se ha podido obtener el código de via firma',
        NotifyType.error,
        5000,
      )
    });

  return codigo;
}
