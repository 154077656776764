import { DossierTypeFieldDataModel } from '../models/dossierTypeFieldDataModel';
import { DossierTypeModel } from '../models/dossierTypeModel';

interface DossierTypeState {
  dossierType: Partial<DossierTypeModel> | DossierTypeModel | undefined;
  dossierTypes: DossierTypeFieldDataModel | undefined;
}

const ADD_DOSSIER_TYPES = 'ADD_DOSSIER_TYPES';
const ADD_DOSSIER_TYPE = 'ADD_DOSSIER_TYPE';
const CLEAR_DOSSIER_TYPE = '@todo/DOSSIER_TYPE';
const ADD_DOSSIER_TYPE_MODULE = 'ADD_DOSSIER_TYPE_MODULE';
const CLEAR_DOSSIER_TYPE_MODULE = 'CLEAR_DOSSIER_TYPE_MODULE';
const ADD_DOSSIER_TYPE_MODULE_SELECTED = 'ADD_DOSSIER_TYPE_MODULE_SELECTED';
const ADD_PARENT_DOSSIER_TYPE = 'ADD_PARENT_DOSSIER_TYPE';
const ADD_DOSSIER_TYPE_MODULE_PARENT = 'ADD_DOSSIER_TYPE_MODULE_PARENT';

interface AddDossierTypes {
  type: typeof ADD_DOSSIER_TYPES;
  payload: DossierTypeFieldDataModel | undefined;
}

interface AddDossierType {
  type: typeof ADD_DOSSIER_TYPE;
  payload: Partial<DossierTypeModel> | undefined;
}

interface AddParentDossierType {
  type: typeof ADD_PARENT_DOSSIER_TYPE;
  payload: Partial<DossierTypeModel> | undefined;
}

interface ClearDossierType {
  type: typeof CLEAR_DOSSIER_TYPE;
  payload: DossierTypeModel | undefined;
}

export type DossierTypeActionsTypes =
  | AddDossierTypes
  | AddDossierType
  | ClearDossierType
  | AddParentDossierType

export { type DossierTypeState };
export { 
  ADD_DOSSIER_TYPES,
  ADD_DOSSIER_TYPE,
  CLEAR_DOSSIER_TYPE,
  ADD_DOSSIER_TYPE_MODULE,
  CLEAR_DOSSIER_TYPE_MODULE,
  ADD_DOSSIER_TYPE_MODULE_SELECTED,
  ADD_PARENT_DOSSIER_TYPE,
  ADD_DOSSIER_TYPE_MODULE_PARENT
};