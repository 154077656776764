import {
  ADD_DATAFORMCHANGE,
  SHOW_POPUP,
  OPEN_POPUPSUJETOS,
  sharedUtilsActionsTypes,
} from '../types/sharedUtilsType'

export const showPopup = (show: boolean): sharedUtilsActionsTypes => ({
  type: SHOW_POPUP,
  payload: show,
})
export const isOpenPopup = (
  isOpenPopup: boolean | undefined,
  popupRef: any,
): sharedUtilsActionsTypes => ({
  type: OPEN_POPUPSUJETOS,
  payload: { isOpenPopup: isOpenPopup, popupRef: popupRef },
})

export const addDataFormChange = (
  isChanged: boolean | undefined,
): sharedUtilsActionsTypes => ({
  type: ADD_DATAFORMCHANGE,
  payload: isChanged,
})
