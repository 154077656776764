import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'src/redux/combineReducers'
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil'
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'
import { HistoricoPage } from './pages/historicoPage'
import NotFound from 'src/components/notFound/notFound'

function HistoricoIndex() {
  const userState = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  return (
    <>
      {!userState!.isLoggedIn && navigate('/Login')}
      {IsModuloAllowed(userState!.user!, Modules.DATOSDEEMPRESA) ? (
        <HistoricoPage />
      ) : (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.16%',
          }}
        >
          <NotFound data={{ type: 1 }} />
        </div>
      )}
    </>
  )
}

export { HistoricoIndex }
