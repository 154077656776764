import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModel } from 'src/models/columnModel';
import { ModuleModel } from './moduleModel';

@JsonObject('')
class ModuleFieldDataModel {
  @JsonProperty('Campos', [ColumnModel] || [])
  Campos: ColumnModel[] = [];

  @JsonProperty('Datos', [ModuleModel] || [])
  Data: ModuleModel[] = [];
}

export { ModuleFieldDataModel };