import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  _deletePermissions,
  _deletePermissionsByUser,
  _getPermissionsById,
  _postPermissions,
  _postPermissionsByUser,
} from '../services/permissionsService'
import { useDispatch } from 'react-redux'
import FormListModulesSection from './FormListModulesSection'
import { RolePermissionsModel } from '../models/rolePermissionsModel'
import { UserPermissionsModel } from '../models/userPermissionsModel'
import { ClaimsUpdateModel } from '../models/claimsUpdateModel'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { addShowLoader } from 'src/redux/actions/configActions'

interface Props {
  id?: number | undefined
  userPermissionData?: UserPermissionsModel | undefined
}

function ModulesPermissionsSection({ id, userPermissionData }: Props) {
  const dispatch = useDispatch()
  const [listaFormularioPermisos, setListaFormularioPermisos] = useState(
    new RolePermissionsModel(),
  )
  const [permisosModulo, setPermisoModulo] = useState(new ClaimsUpdateModel())
  const [todosPermisosModuloByRol, setTodosPermisosModuloByRol] = useState(
    Array<ClaimsUpdateModel>(),
  )

  const queryClient = useQueryClient()
  const actualizarPermisosByRol = useMutation(
    async function (estado: boolean) {
      if (todosPermisosModuloByRol.length > 0) {
        for (const permiso of todosPermisosModuloByRol) {
          if (estado) {
            await _postPermissions(id!, permiso)
          } else {
            await _deletePermissions(id!, permiso)
          }
        }
      } else {
        if (estado) {
          await _postPermissions(id!, permisosModulo)
        } else {
          await _deletePermissions(id!, permisosModulo)
        }
      }
    },
    {
      onSuccess: function () {
        setTodosPermisosModuloByRol([])
        queryClient.invalidateQueries('getPermisos')
        showToast('Permiso actualizado', NotifyType.success, 5000)
      },
      onError: function () {
        if (todosPermisosModuloByRol.length > 0) {
          setTodosPermisosModuloByRol([])
        }
        showToast('Permiso no actualizado', NotifyType.error, 5000)
      },
    },
  )

  const actualizarPermisosByUser = useMutation(
    async function (estado: boolean) {
      if (todosPermisosModuloByRol.length > 0) {
        for (const permiso of todosPermisosModuloByRol) {
          if (estado) {
            await _postPermissionsByUser(id!, permiso)
          } else {
            await _deletePermissionsByUser(id!, permiso)
          }
        }
      } else {
        if (estado) {
          await _postPermissionsByUser(id!, permisosModulo)
        } else {
          await _deletePermissionsByUser(id!, permisosModulo)
        }
      }
    },
    {
      onSuccess: function () {
        setTodosPermisosModuloByRol([])
        queryClient.invalidateQueries('getRolsPermisosByUsers')
        showToast('Permiso actualizado', NotifyType.success, 5000)
      },
      onError: function () {
        setTodosPermisosModuloByRol([])
        showToast('Permiso no actualizado', NotifyType.error, 5000)
      },
    },
  )

  useQuery(
    ['getPermisos', userPermissionData === undefined ? id : undefined],
    _getPermissionsById,
    {
      onSuccess: (data: RolePermissionsModel | null) => {
        if (data  != null ) {
          setListaFormularioPermisos(data)
        }
        dispatch(addShowLoader(false))
      },
      onError: () => {
        dispatch(addShowLoader(false))
      },
      refetchOnWindowFocus: false,
    },
  )
  return (
    <div>
      {listaFormularioPermisos.Id !== 0 &&
        listaFormularioPermisos.ModulesClaims.map((modulo) => {
          return (
            <>
              <FormListModulesSection
                permisosModulo={permisosModulo}
                setpermisomodulo={setPermisoModulo}
                actualizarPermisos={actualizarPermisosByRol}
                modulo={modulo}
                todospermisos={todosPermisosModuloByRol}
              ></FormListModulesSection>
            </>
          )
        })}

      {userPermissionData !== undefined &&
        userPermissionData?.Id !== 0 &&
        userPermissionData!.ModulesClaims.map((module) => {
          return (
            <>
              <FormListModulesSection
                modulo={module}
                permisosModulo={permisosModulo}
                setpermisomodulo={setPermisoModulo}
                actualizarPermisos={actualizarPermisosByUser}
                todospermisos={todosPermisosModuloByRol}
              ></FormListModulesSection>
            </>
          )
        })}
    </div>
  )
}

export { ModulesPermissionsSection }
