import { ADD_MODULE, ADD_TEMPLATE, ADD_TEMPLATE_FIELDS, 
  TemplateActionsTypes, TemplateState } from './ITemplateType';

const initialState: TemplateState = {
  template: undefined,
  templateFields: undefined,
  module: 0,
}

const templateReducer = (
  state = initialState,
  action: TemplateActionsTypes,
): TemplateState => {
  switch (action.type) {
    case ADD_TEMPLATE: {
      return { ...state, template: action.payload };
    }
    case ADD_TEMPLATE_FIELDS: {
      return { ...state, templateFields: action.payload };
    }
    case ADD_MODULE: {
      return { ...state, module: action.payload };
    }
    default:
      return state;
  };
};

export { templateReducer };
