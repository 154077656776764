import axiosInstance from 'src/utils/axios'
import { UserModel } from 'src/models/usuario/userModel'

import {
  NotifyType,
  showToast,
  customLog,
  logType,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

//import { addIsAlreadyLogged } from "../pages/loginPage/redux/authActions";

//FeedBACKService.ts

//PermisosService.ts

//UserService.ts

//AuthService.ts

const serviceName = 'authService'
export const LoginUser = async (
  username: string,
  password: string,
): Promise<UserModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `Usuarios/authenticate`
    const response = await axiosInstance.post(url, { username, password })

    let User: UserModel = jsonConvert.deserializeObject(
      response.data,
      UserModel,
    )

    if (response.status === 200) {
      customLog(
        null,
        logType.INFO,
        serviceName,
        LoginUser.name,
        methodsEndPointType.GET,
      )

      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return User
  } catch (err) {
    // Handle Error Here
    customLog(
      null,
      logType.ERROR,
      serviceName,
      LoginUser.name,
      methodsEndPointType.GET,
    )
    showToast(
      'No existe el usuario o la contaseña no coincide',
      NotifyType.error,
      5000,
    )

    console.error(err)
  }
}

export const restablecerContraseñaCall = async (
  Username: string,
  Email: string,
  Auth: string,
) => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    const changePasswd = { Username: Username, Email: Email, Auth: Auth }

    const response = await axiosInstance.post(
      `Usuarios/resetpassword`,
      changePasswd,
    )

    if (response.status === 200) {
      showToast('Email enviado correctamente', NotifyType.success, 5000)
    }

    return response.status
  } catch (err) {
    // Handle Error Here
    showToast(
      'No hemos podido enviar el Email, pruebe de nuevo.',
      NotifyType.error,
      5000,
    )
    console.log(err)
    console.error(err)
  }
}

//TODO arreglar esto para que sea generio o directamente coger el user de redux
export const authHeader = (user: UserModel) => {
  return {
    Authorization: 'Bearer ' + user.Token,
  }
}

//TODO arreglar esto para que sea generio o directamente coger el user de redux

export default {
  LoginUser,
  authHeader,
  // isAlreadyLoggedUser,
}
