import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'

@JsonObject('BienModel')
class BienModel {
  // @JsonProperty('Id', Number)
  // Id: number = 0

  @JsonProperty('Descripcion', String)
  Descripcion: string = ''

  @JsonProperty('Direccion', String)
  Direccion: string = ''

  @JsonProperty('NumFinca', String)
  NumFinca: string = ''

  @JsonProperty('Poblacion', String)
  Poblacion: string = ''

  @JsonProperty('Provincia', String)
  Provincia: string = ''

  @JsonProperty('ReferenciaCatastral', String)
  ReferenciaCatastral: string = ''
}

@JsonObject('BienCampoDatoModel')
export class BienCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [BienModel])
  Datos: BienModel[] = []
}
