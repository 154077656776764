import { DossierStatusElGestor } from '../models/idocCarEstadosExpedientesModel'

import {
  ADD_IDOCCAR_DOSSIER_STATUS,
  ADD_IDOCCAR_DOSSIER_STATUS_ALL,
  // CLEAR_IDOCCAR_DOSSIER_STATUS,
  IdocCarDossierStatusElGestorActionsTypes,
} from './idocCarEstadosExpedientesType'

export const addDossierStatusElGestor = (
  idocCarConfig:
    | Partial<DossierStatusElGestor>
    | DossierStatusElGestor
    | undefined,
): IdocCarDossierStatusElGestorActionsTypes => ({
  type: ADD_IDOCCAR_DOSSIER_STATUS,
  payload: idocCarConfig,
})

// export const clearDossierStatusElGestor = (
//   idocCarConfig: DossierStatusElGestor | undefined,
// ): IdocCarDossierStatusElGestorActionsTypes => ({
//   type: CLEAR_IDOCCAR_DOSSIER_STATUS,
//   payload: idocCarConfig,
// })
export const addIdocCarDossierStatusElGestorAll = (
  idocCarConfigAll: DossierStatusElGestor[] | undefined,
): IdocCarDossierStatusElGestorActionsTypes => ({
  type: ADD_IDOCCAR_DOSSIER_STATUS_ALL,
  payload: idocCarConfigAll,
})
