import { Box } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import React, { ReactElement, useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import { useDispatch, useSelector } from 'react-redux'
import { addShowLoader } from '../../redux/actions/configActions'
import { RootState } from 'src/redux/combineReducers'
import RequestForm from '../companyAdminPages/requestPage/pages/RequestForm'
import { clearSolicitud } from '../companyAdminPages/requestPage/redux/solicitudActions'
import { SolicitudListModel } from '../companyAdminPages/requestPage/models/solicitudListModel'
import {
  actualizarArchivos,
  addActualizarListaArchivos,
  addCompradorRepresentante,
  addPagina,
  addVendedorRepresentante,
} from '../companyAdminPages/requestPage/redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { ListaArchivosModel } from '../companyAdminPages/requestPage/models/ListaArchivosModel'
import {
  getDestinoTipoVehiculo,
  getTipoCalle,
  getTipoVehiculo,
} from '../companyAdminPages/requestPage/service/solicitudService'
import DataSource from 'devextreme/data/data_source'
import { Firmar } from '../companyAdminPages/requestPage/types/firmarType'

function AccessOnlyCalculatorPage(): ReactElement {
  const dispatch = useDispatch()
  const solicitudState = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const listaarchivos = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.listaarchivos,
  )
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  const userState = useSelector((state: RootState) => state.user)
  const [showTransferencia, setShowTransferencia] = useState(false)
  const [formulario, setFormulario] = useState('')
  const [tiposvehiculos, setTiposVehiculos] = useState({})
  //Recogemos la informacion de los destinotipovehiculos
  const [destinovehiculos, setDestinosVehiculos] = useState({})
  //Recogemos la  información del tipo de calle
  const [tipocalle, setTipoCalle] = useState({})
  const [firmaDocumento, setFirmaDocumento] = useState({
    realizarfirma: false,
    idexpediente: '',
    idvehiculo: '',
  } as Firmar)
  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'pdfGenerated') {
        dispatch(addShowLoader(true))
        dispatch(clearSolicitud(new SolicitudListModel()))
        const file = event.data.pdfBlob
        const pdf = new File([file], 'CalculoTransferencia.pdf', {
          type: 'application/pdf',
        })
        let listaArchivos = new ListaArchivosModel()
        listaArchivos.Nombre = 'CalculoTransferencia.pdf'
        listaArchivos.Subido = false
        listaarchivos?.push(listaArchivos)
        dispatch(actualizarArchivos([pdf]))
        dispatch(addActualizarListaArchivos(listaarchivos!))
        setShowTransferencia(true)
        dispatch(addShowLoader(false))
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dispatch, listaarchivos])
  useEffect(() => {
    darVehiculos()
    darDestinoVehiculos()
    darTipoCalle()
  }, [])

  async function darVehiculos() {
    let datos = await getTipoVehiculo(userState!.user!)

    const selectBoxDataSource = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setTiposVehiculos(selectBoxDataSource)
  }

  async function darDestinoVehiculos() {
    let datos = await getDestinoTipoVehiculo(userState!.user!)

    const selectBoxDataSource2 = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setDestinosVehiculos(selectBoxDataSource2)
  }

  async function darTipoCalle() {
    let datos = await getTipoCalle(userState!.user!)

    const selectBoxDataSource3 = new DataSource({
      store: {
        type: 'array',
        data: datos,
        key: 'Id',
      },
    })

    setTipoCalle(selectBoxDataSource3)
  }

  function resetear() {
    dispatch(clearSolicitud(new SolicitudListModel()))
    let tienecompradorrepresentante =
      solicitudState!.ClientLegalRep !== undefined &&
      solicitudState!.ClientLegalRep  != null  &&
      solicitudState!.ClientLegalRep.Nif  != null  &&
      solicitudState!.ClientLegalRep.Nif !== ''
        ? true
        : false
    let tienevendedorrepresentante =
      solicitudState!.SellerLegalRep !== undefined &&
      solicitudState!.SellerLegalRep  != null  &&
      solicitudState!.SellerLegalRep.Nif  != null  &&
      solicitudState!.SellerLegalRep.Nif !== ''
        ? true
        : false
    dispatch(addPagina(1))
    dispatch(addCompradorRepresentante(tienecompradorrepresentante))
    dispatch(addVendedorRepresentante(tienevendedorrepresentante))
    dispatch(addActualizarListaArchivos(new Array<ListaArchivosModel>()))
    dispatch(actualizarArchivos(Array<File>()))
  }
  function cerrarPopUp(): void {
    setFormulario('')
    setShowTransferencia(false)
    resetear()
  }

  useEffect(() => {
    dispatch(addShowLoader(true))
    setTimeout(() => {
      dispatch(addShowLoader(false))
    }, 4000)
  }, [localStorage.getItem('dx-theme')])

  return (
    <Box
      direction='row'
      align='center'
      crossAlign='center'
      height={1200}
      width={'auto'}
    >
      <Item ratio={0} baseSize={60}></Item>
      <Item ratio={0} baseSize={1200}>
        <Iframe
          allowFullScreen
          height='1200px'
          scrolling='yes'
          url={`${
            process.env.REACT_APP_CALCULADORA_URL
          }?key=${localStorage.getItem('calculadoraKey')}&theme=${
            localStorage.getItem('dx-theme')  != null 
              ? localStorage.getItem('dx-theme')
              : 'generic.light'
          }&idGestoria=${userState!.user!.CompanyId}&vienedelportal=true`}
          styles={{ border: 'none', paddingRight: '140px' }}
          id='datosConsultaIframe'
        />
      </Item>
      <Item>
        {showTransferencia ? (
          <RequestForm
            cerrar={cerrarPopUp}
            vehiculos={tiposvehiculos}
            destinos={destinovehiculos}
            calles={tipocalle}
            tipo={'CTI'}
            firmardocumento={
              {
                realizarfirma: false,
                idexpediente: '',
                idvehiculo: '',
              } as Firmar
            }
          />
        ) : null}
      </Item>
    </Box>
  )
}

export { AccessOnlyCalculatorPage }
