import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'

import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { IdocCarOrganizationalUnitModel } from '../models/idocCarOrganizationalUnitModel'
const serviceName = 'authService'

export const getIdocCarOrganizationalUnitList = async (
  user: UserModel,
): Promise<Array<IdocCarOrganizationalUnitModel> | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `IDocCarOrganizationalUnit`
    let datos: any
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los registros.',
          NotifyType.error,
          5000,
        )
        checkStatusCode(error.response.status)
      })

    return datos
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

export async function postIdocCarOrganizationalUnit(
  user: UserModel,
  Solicitud: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `IDocCarOrganizationalUnit`

    const headers = {
      Authorization: 'Bearer ' + user.Token,
      'Content-Type': 'application/json',
    }

    await axiosInstance
      .post(url, Solicitud, { headers })
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud creado ${response.data.Id}`,
          logType.INFO,
          serviceName,
          postIdocCarOrganizationalUnit.name,
          methodsEndPointType.POST,
        )
        showToast('Solicitud creada', NotifyType.success, 5000)
      })
    return true
  } catch (error: any) {
    customLog(
      `Error al crear Solicitud => ${error.message}`,
      logType.ERROR,
      serviceName,
      postIdocCarOrganizationalUnit.name,
      methodsEndPointType.POST,
    )
    checkStatusCode(error.response.status)
    return false
  }
}

export async function putIdocCarOrganizationalUnit(
  user: UserModel,
  idocCarOrganizationalUnit: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `IDocCarOrganizationalUnit`
    const headers = {
      Authorization: 'Bearer ' + user.Token,
      'Content-Type': 'application/json',
    }
    await axiosInstance
      .put(url, idocCarOrganizationalUnit, { headers })
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud modificado ${idocCarOrganizationalUnit.Id}`,
          logType.INFO,
          serviceName,
          putIdocCarOrganizationalUnit.name,
          methodsEndPointType.PUT,
        )
        showToast('Unidad Organizativa editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        showToast('No se ha podido editar el registro.', NotifyType.error, 5000)
        customLog(
          `Error al editar Solicitud => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarOrganizationalUnit.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}

export async function deleteIdocCarOrganizationalUnit(
  user: UserModel,
  id: number,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `IDocCarOrganizationalUnit/` + id
    const headers = {
      Authorization: 'Bearer ' + user.Token,
      'Content-Type': 'application/json',
    }
    await axiosInstance
      .delete(url, { headers })
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud delete ${id}`,
          logType.INFO,
          serviceName,
          putIdocCarOrganizationalUnit.name,
          methodsEndPointType.PUT,
        )
        showToast('Unidad Organizativa Borrada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar Solicitud => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarOrganizationalUnit.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}

export async function getStructure(
  user: UserModel,
): Promise<SolicitudListCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `ExternalDossier/GetStructure`

  let sujeto: SolicitudListCampoDatoModel = new SolicitudListCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sujeto = jsonConvert.deserializeObject(
        response.data,
        SolicitudListCampoDatoModel,
      )
    })
    .catch((error: any) => {
      showToast(
        'No se ha podido obtener la estructura.',
        NotifyType.error,
        5000,
      )
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return sujeto
}