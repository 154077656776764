import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'devextreme-react';
import { AsyncRule, GroupItem, Label, PatternRule, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { useForm } from 'react-hook-form';
import { RootState } from '../../../../redux/combineReducers';
import { ButtonsForm } from '../../../../components/buttonsForm/ButtonsForm';
import { useScreenSize } from '../../../../utils/media-query';
import { AddCompanyCodigoViaFirma, addCompany } from '../redux/companiesActions';
import { postCompany, putCompany } from '../services/companiesServices';
import Dropzone, { defaultClassNames, IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader';
import { logo } from '../utils/images';
import { CompanyModel } from 'src/models/company/companyModel';
import { addDataFormChange } from '../../../../redux/actions/sharedUtilsActions';
import { refreshGrid } from '../../../../utils/sharedUitls';
import { addParentLinks } from '../redux/link/linkActions';
import { getLinks } from '../services/linksService';
import { isSuperAdmin} from '../../../../utils/allowAuthUtil';
import '../styles/dropzoneStyle.css';
import * as events from 'devextreme/events';
import { NifCifExistente } from '../utils/companyUtils';
import { CompanyCampoDatoGridModel } from 'src/models/company/companyCampoDatoModel';
import { IsModuloAllowed, Modules } from 'src/utils/allowModuloUtil'; 

function CompanyFormPage(props: any): ReactElement {
  const userState = useSelector((state: RootState) => state.user);
  const company = useSelector((state: RootState) => state.company?.company);
  const codigoViaFirmaState = useSelector(
    (state: RootState) => state.company?.codigoViaFirma,
  );
  const companyState = useSelector((state: RootState) => state.company);
  const calculadoraState = useSelector(
    (state: RootState) =>
      state.calculatorTransfers?.calculatorTransfers,
  )
  const emailState = useSelector((state: RootState) => state.email?.email)
  const enlaceState = useSelector((state: RootState) => state.link)
  const companysState = useSelector(
    (state: RootState) => state.company?.companies,
  )
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const { isLarge } = useScreenSize()

  const [loading, setLoading] = useState(false)
  const [pattern, setPattern] = useState('NIF')
  const formData = useRef({})
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  const vaAgregarArchivo = useRef(false)

  const getDropRef = useCallback(
    (element: any) => {
      events.on(element, 'dxclick', (e: any) => {
        if (
          e.target.innerText !== 'Subir logo principal' ||
          e.target.innerText !== 'Subir logo secundario'
        ) {
          vaAgregarArchivo.current = true
        }
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (
      company !== undefined &&
      (company?.PrincipalLogo !== '' || company.SecondaryLogo !== '')
    ) {
      vaAgregarArchivo.current = true
    }

    if (!props.tempTieneArchivos) {
      vaAgregarArchivo.current = false
    }
    let isMounted = true
    async function fetchData() {}
    fetchData()
    return () => {
      isMounted = false
    }
  }, [props.showForm])
  var logo1 = {
    width: 'auto',
    height: 'auto',
    maxWidth: '146px',
    maxHeight: '50px',
    /*  marginTop: '-5%',
     marginRight: '1.6em', */
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    let result: boolean = false
    if (!company?.Id) {
      dispatch(addCompany({ ...company, Id: 0 }))
    }
    if (company?.Id) {
      result = await putCompany(
        userState!.user!,
        company,
        calculadoraState,
        enlaceState?.links!,
        companyState,
        emailState,
      )
    } else {
      result = await postCompany(
        userState!.user!,
        company!,
        calculadoraState,
        enlaceState?.links!,
        companyState,
        emailState,
      ).then(() => {
        return true
      })
    }

    if (result) {
      setLoading(false)
      refreshGrid(props.setShowForm, true)
      props.setShowForm(false)
      localStorage.setItem(
        'parentCompanyModulos',
        JSON.stringify(companyState?.companyModulos),
      )
      localStorage.setItem(
        'parentEnlaces',
        JSON.stringify(
          dispatch(
            addParentLinks(
              await getLinks(
                userState?.user!,
                parseInt(userState?.user!.CompanyId!),
              ),
            ),
          ).payload,
        ),
      )
    } else {
      setLoading(false)
    }
  }

  const handleCancelClick = () => {
    // let confirmDialog = custom({
    //   title: '',
    //   messageHtml:
    //     '<b>Para conservar los cambios antes pulsar Guardar, Si continua perderá todos los datos cambiados</b>',
    //   buttons: [
    //     {
    //       text: 'Continuar',
    //       onClick: (e) => {
    //         dispatch(addCompany(new CompanyModel()))
    //         props.setShowForm(false)
    //         confirmDialog.hide()
    //       },
    //     },
    //     {
    //       text: 'Cancelar',
    //       onClick: (e) => {
    //         confirmDialog.hide()
    //       },
    //     },
    //   ],
    // })
    // confirmDialog.show().then((dialogResult: any) => {
    //   console.log(dialogResult)
    // })
    dispatch(addCompany(new CompanyModel()))
    props.setShowForm(false)
  }

  const renderButtons = () => {
    if (!props.showForm) {
      companyFormRef.current.instance.resetValues()
    }
    return ButtonsForm(`companyData`, handleCancelClick, false)
  }

  const handleUpdatedCompany = (property: any) => {
    dispatch(
      addCompany({
        ...company,
        ...property,
      }),
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    if (!vaAgregarArchivo.current) {
      if (files?.length !== 0) {
        files!.forEach((f: any) => f.remove())
      }
    }

    return (
      <div>
        {previews}

        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

        {files.length > 0 && submitButton}
      </div>
    )
  }
  const handleSubmitLogo1: IDropzoneProps['onSubmit'] = (
    files: any,
    allFiles: any,
  ) => {
    console.log(
      files.map(async (file: any) => {
        var arrayBuffer = await file.file.arrayBuffer()
        var blob = new Blob([arrayBuffer])
        var reader = new FileReader()
        reader.onload = function (event) {
          const orginalBase = event.target!.result?.toString().split(',')
          const base64: string | undefined = orginalBase![1].toString()
          handleUpdatedCompany({
            PrincipalLogo: base64,
          })
        }
        reader.readAsDataURL(blob)
      }),
    )
    allFiles.forEach((f: any) => f.remove())
  }
  const handleSubmitLogo2: IDropzoneProps['onSubmit'] = (
    files: any,
    allFiles: any,
  ) => {
    console.log(
      files.map(async (file: any) => {
        var arrayBuffer = await file.file.arrayBuffer()
        var blob = new Blob([arrayBuffer])
        var reader = new FileReader()
        reader.onload = function (event) {
          const orginalBase = event.target!.result?.toString().split(',')
          const base64: string | undefined = orginalBase![1].toString()
          handleUpdatedCompany({
            SecondaryLogo: base64,
          })
        }
        reader.readAsDataURL(blob)
      }),
    )
    allFiles.forEach((f: any) => f.remove())
  }
  const companyFormRef: any = useRef(null)
  if (!props.showForm) {
    if (companyFormRef  != null ) {
      companyFormRef.current?.instance.resetValues()
    }
  }
  return (
    <>
      <div
        style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.6%' }}
      >
        <div className='form' ref={getDropRef}>
          <form encType='multipart/form-data' style={{ width: '100%' }}>
            <Form
              formData={formData.current}
              disabled={loading}
              readOnly={false}
              ref={companyFormRef}
              labelLocation={isLarge ? 'left' : 'top'}
              showValidationSummary={false}
              validationGroup='companyData'
              showColonAfterLabel={true}
            >
              {/* esto solo lo veran los superAdmins de creación de empresas */}
              {company?.Id ? (
                <SimpleItem
                  dataField='Registro'
                  editorType={'dxTextBox'}
                  editorOptions={{
                    disabled: true,
                    placeholder: 'Número de registro',
                    mode: 'text',
                    value: company?.Id ? company?.Id : '',
                  }}
                ></SimpleItem>
              ) : undefined}

              <SimpleItem
                dataField='NIF/NIE/CIF'
                editorOptions={{
                  placeholder: 'Número de identificación(NIF,NIE,CIF...)',
                  mode: 'text',
                  value: company?.IdentificationNumber
                    ? company?.IdentificationNumber
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCompany({
                        IdentificationNumber: event.value,
                      })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                  //...NIFValidator,
                }}
              >
                <RequiredRule
                  message='El campo es requerido'
                  type={'required'}
                />
                <PatternRule
                  pattern='^([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])$'
                  message='Debes introducir un número de identificación NIF/CIF/NIE'
                />

                {company?.IdentificationNumber !== '' && (
                  <AsyncRule
                    message='NIF/NIE/CIF existente'
                    validationCallback={(e: any) => {
                      return NifCifExistente(
                        e.value,
                        props.crear,
                        companysState as CompanyCampoDatoGridModel,
                      )
                    }}
                  />
                )}
              </SimpleItem>

              <SimpleItem
                dataField={'name'}
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Nombre de compañía',
                  value: company?.Name ? company?.Name : '',
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCompany({ Name: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                <Label visible={true} text='Nombre' />
              </SimpleItem>
              <SimpleItem
                dataField={'Mensaje barra superior'}
                editorType={'dxTextBox'}
                editorOptions={{
                  maxLength: 100,
                  placeholder: 'Mensaje barra superior',
                  value: company?.Slogan ? company?.Slogan : '',
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCompany({ Slogan: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' type='required' />
                <Label visible={true} text='Mensaje barra superior' />
              </SimpleItem>

              <SimpleItem
                dataField={'contactInformation'}
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Información de contacto',
                  value: company?.ContactInformation
                    ? company?.ContactInformation
                    : '',
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCompany({ ContactInformation: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />

                <Label visible={true} text='Información de contacto' />
              </SimpleItem>

              <SimpleItem
                dataField={'notificationsEmail'}
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Email para notificaciones',
                  value: company?.NotificationsEmail
                    ? company?.NotificationsEmail
                    : '',
                  mode: 'text',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      handleUpdatedCompany({ NotificationsEmail: event.value })
                      if (!isChangedState) dispatch(addDataFormChange(true))
                    }
                  },
                }}
              >
                <RequiredRule message='El campo es requerido' />
                {/* <EmailRule message='Debe introducir un email con un formato correcto' /> */}
                <Label visible={true} text='Email para notificaciones' />
              </SimpleItem>

              {isSuperAdmin(userState!.user!) &&
                IsModuloAllowed(userState!.user!, Modules.FIRMADIGITAL) && (
                  <SimpleItem
                    editorType={'dxTextBox'}
                    editorOptions={{
                      placeholder: 'Código vía firma ',
                      value: codigoViaFirmaState ? codigoViaFirmaState : '',
                      mode: 'text',
                      onValueChanged: (event: any) => {
                        if (event.event) {
                          dispatch(AddCompanyCodigoViaFirma(event.value))
                        }
                      },
                    }}
                  >
                    {/* <EmailRule message='Debe introducir un email con un formato correcto' /> */}
                    <Label visible={true} text='Código vía firma' />
                  </SimpleItem>
                )}

              {isSuperAdmin(userState!.user!) ? (
                <GroupItem colCount='2'>
                  <SimpleItem
                    dataField={'enabled'}
                    editorType={'dxCheckBox'}
                    editorOptions={{
                      value: company?.Enabled ? company?.Enabled : '',
                      mode: 'text',
                      onValueChanged: (event: any) => {
                        if (event.event) {
                          handleUpdatedCompany({
                            Enabled: event.value,
                          })
                          if (!isChangedState) dispatch(addDataFormChange(true))
                        }
                      },
                    }}
                  >
                    <Label visible={true} text='Bloqueado' />
                  </SimpleItem>
                </GroupItem>
              ) : null}

              <GroupItem colCount='2'>
                <SimpleItem>
                  <img
                    src={`data:image/jpeg;base64,${
                      company?.PrincipalLogo !== '' &&
                      company?.PrincipalLogo  != null 
                        ? company?.PrincipalLogo
                        : logo
                    }`}
                    style={logo1}
                  />
                  <Dropzone
                    LayoutComponent={Layout}
                    onSubmit={handleSubmitLogo1}
                    accept='image/*'
                    classNames={{
                      inputLabelWithFiles: defaultClassNames.inputLabel,
                    }}
                    submitButtonContent='Enviar'
                    maxFiles={1}
                    inputContent='Subir logo principal'
                    inputWithFilesContent={(files: any) =>
                      `${1 - files.length} more`
                    }
                    submitButtonDisabled={(files: any) => files.length < 1}
                    styles={{
                      dropzone: { minHeight: '6vh', maxHeight: '10vh' },
                    }}
                  />
                </SimpleItem>
                <SimpleItem>
                  <img
                    src={`data:image/jpeg;base64,${
                      company?.SecondaryLogo !== '' &&
                      company?.SecondaryLogo  != null 
                        ? company?.SecondaryLogo
                        : logo
                    }`}
                    style={logo1}
                  />
                  <Dropzone
                    LayoutComponent={Layout}
                    accept='image/*'
                    onSubmit={handleSubmitLogo2}
                    classNames={{
                      inputLabelWithFiles: defaultClassNames.inputLabel,
                    }}
                    submitButtonContent='Enviar'
                    maxFiles={1}
                    inputContent='Subir logo secundario'
                    inputWithFilesContent={(files: any) =>
                      `${1 - files.length} more`
                    }
                    submitButtonDisabled={(files: any) => files.length < 1}
                    styles={{
                      dropzone: { minHeight: '6vh', maxHeight: '10vh' },
                    }}
                  />
                </SimpleItem>
              </GroupItem>
              {/*  <SimpleItem render={renderButtons} cssClass="alignBottom" /> */}
            </Form>
          </form>
        </div>
      </div>
    </>
  )
}

export { CompanyFormPage };
