import { ProfileModel } from 'src/models/perfilPage/profileModel';

interface ProfileState {
  profile: Partial<ProfileModel> | ProfileModel | undefined;
  profiles: ProfileModel[] | undefined;
}

const ADD_PROFILE = '@todo/ADD_PROFILE';
const ADD_PROFILES = '@todo/ADD_PROFILES';
const CLEAR_PROFILE = '@todo/CLEAR_PROFILE';

interface AddProfileRequest {
  type: typeof ADD_PROFILE;
  payload: Partial<ProfileModel> | ProfileModel | undefined;
}
interface AddProfilesRequest {
  type: typeof ADD_PROFILES;
  payload: ProfileModel[] | undefined;
}
interface ClearProfileRequest {
  type: typeof CLEAR_PROFILE;
  payload: ProfileModel | undefined;
}

export type SujetActionsTypes =
  | ClearProfileRequest
  | AddProfilesRequest
  | AddProfileRequest

export { 
  ADD_PROFILE,
  ADD_PROFILES,
  CLEAR_PROFILE,
}

export { type ProfileState };