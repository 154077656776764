import {
  ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL,
  ADD_IDOCCAR_ORGANIZATIONAL_UNIT,
  CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT,
  IdocCarOrganizationalUnitActionsTypes,
  IdocCarOrganizationalUnitState,
} from './idocCarOrganizationalUnitType'

const initialState: IdocCarOrganizationalUnitState = {
  idocCarOrganizationalUnit: undefined,
  idocCarOrganizationalUnitAll: undefined,
}

export const idocCarOrganizationalUnitReducer = (
  state = initialState,
  action: IdocCarOrganizationalUnitActionsTypes,
): IdocCarOrganizationalUnitState => {
  switch (action.type) {
    case ADD_IDOCCAR_ORGANIZATIONAL_UNIT: {
      return { ...state, idocCarOrganizationalUnit: action.payload }
    }
    case ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL: {
      return { ...state, idocCarOrganizationalUnitAll: action.payload }
    }
    case CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT: {
      return { ...state, idocCarOrganizationalUnit: action.payload }
    }
    default:
      return state
  }
}
