import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';
import { DateConverter } from 'src/utils/dateUtils';

@JsonObject('')
class DetalleCosteModel {
  @JsonProperty('Anticipos', Number)
  Anticipos: number = 0;

  @JsonProperty('Facturado', Boolean)
  Facturado: boolean = false;

  @JsonProperty('Honorarios', Number)
  Honorarios: number = 0;

  @JsonProperty('IVA', Number)
  IVA: number = 0;

  @JsonProperty('Suplidos', Number)
  Suplidos: number = 0;

  @JsonProperty('Total', Number)
  Total: number = 0;
}

@JsonObject('')
export class DetalleCosteCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [DetalleCosteModel])
  Datos: DetalleCosteModel[] = [];
}

@JsonObject('')
class CosteModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('ClaveExterna', Number)
  ClaveExterna: number = 0;

  @JsonProperty('Concepto', String)
  Concepto: string = '';

  @JsonProperty('CuotaIva', Number)
  CuotaIva: number = 0;

  @JsonProperty('FacturarA', String)
  FacturarA: string = '';

  @JsonProperty('FechaTramitacion', DateConverter)
  FechaTramitacion: undefined;

  @JsonProperty('Importe', Number)
  Importe: number = 0;

  @JsonProperty('NumeroExpediente', Number)
  NumeroExpediente: number = 0;

  @JsonProperty('NumeroLinea', Number)
  NumeroLinea: number = 0;

  @JsonProperty('Observaciones', String)
  Observaciones: string = '';

  @JsonProperty('PorcentajeDescuento', Number)
  PorcentajeDescuento: number = 0;

  @JsonProperty('PorcentajeIVA', Number)
  PorcentajeIVA: number = 0;

  @JsonProperty('TipoConcepto', String)
  TipoConcepto: string = '';

  @JsonProperty('TotalLinea', Number)
  TotalLinea: number = 0;

  @JsonProperty('Unidades', Number)
  Unidades: number = 0;
}

@JsonObject('')
export class CosteCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [CosteModel])
  Datos: CosteModel[] = [];
}
