import {
  AsyncRule,
  EmailRule,
  GroupItem,
  SimpleItem,
  StringLengthRule,
} from 'devextreme-react/form'
import React from 'react'
import { validandoDocumento } from 'src/utils/NifDni/validation'
import { formatoFecha } from '../../utils/dates/formdateformat'
import { SolicitudListModel } from '../../models/solicitudListModel'

interface Props {
  solicitud: SolicitudListModel
  sexo: Array<Object>
  formref: any
}

export default function DatosPersonalesCompradorRepresentanteSection({
  solicitud,
  sexo,
  formref,
}: Props) {
  return (
    <GroupItem>
      <GroupItem colCount={3} cssClass='GItem'>
        <SimpleItem
          dataField={'ClientLegalRep.Nif'}
          label={{ text: 'Nif/Nie/Cif' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'NIF/NIE/CIF comprador representante',
            value:
              solicitud!.ClientLegalRep.Nif !== ''
                ? solicitud!.ClientLegalRep.Nif
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Nif = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <AsyncRule
            message='El campo numero de identificacion tiene que tener un formato valido.'
            validationCallback={(e: any) => {
              return validandoDocumento(e.value)
            }}
          />

          <StringLengthRule
            message={
              'La longitud del campo número de identificación no debe ser superior a 15'
            }
            max={15}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          dataField={'ClientLegalRep.Name'}
          label={{ text: 'Nombre' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre comprador representante',
            value:
              solicitud!.ClientLegalRep.Name !== ''
                ? solicitud!.ClientLegalRep.Name
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Name = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo nombre no debe ser superior a 150'}
            max={150}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          dataField={'ClientLegalRep.Genre'}
          label={{ text: 'Sexo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource: sexo,
            displayExpr: 'Nombre',
            hint: 'Sexo comprador representante',
            valueExpr: 'Id',
            value: solicitud!.ClientLegalRep.Genre,
            onValueChanged: (e: any) => {
              if (e.value !== undefined) {
                solicitud!.ClientLegalRep.Genre = e.value
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'ClientLegalRep.BirthDate'}
          label={{ text: 'F.Nacimiento' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Nacimiento Representante Cliente',
            value:
              solicitud!.ClientLegalRep.BirthDate  != null  &&
              solicitud!.ClientLegalRep.BirthDate.split('-')[0] !== '0001' &&
              solicitud!.ClientLegalRep.BirthDate !== ''
                ? formatoFecha(solicitud!.ClientLegalRep.BirthDate)
                : null,
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value  != null ) {
                let año = e.value.getFullYear()
                let mes =
                  e.value.getMonth() + 1 > 9
                    ? e.value.getMonth() + 1
                    : `0${e.value.getMonth() + 1}`
                let dia =
                  e.value.getDate() > 9
                    ? e.value.getDate()
                    : `0${e.value.getDate()}`

                solicitud!.ClientLegalRep.BirthDate = `${año}-${mes}-${dia}`
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            displayFormat: 'dd/MM/yyyy',
          }}
        ></SimpleItem>

        <SimpleItem
          dataField={'ClientLegalRep.Surname1'}
          label={{ text: 'Apellido 1' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Apellido 1 comprador representante',
            value:
              solicitud!.ClientLegalRep.Surname1 !== ''
                ? solicitud!.ClientLegalRep.Surname1
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Surname1 = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del apellido del representante del comprador no debe ser superior a 50'
            }
            max={50}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          dataField={'ClientLegalRep.Surname2'}
          label={{ text: 'Apellido 2' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Apellido 2 comprador representante',
            value:
              solicitud!.ClientLegalRep.Surname2 !== ''
                ? solicitud!.ClientLegalRep.Surname2
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Surname2 = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del apellido del representante del comprador  no debe ser superior a 50'
            }
            max={50}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'ClientLegalRep.Address.Email'}
          label={{ text: 'Email' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Email comprador representante',
            value:
              solicitud!.ClientLegalRep.Address.Email !== ''
                ? solicitud!.ClientLegalRep.Address.Email
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Address.Email = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <EmailRule
            message={
              'El campo email tiene que tener un formato valido, ex: test@test.com'
            }
          ></EmailRule>

          <StringLengthRule
            message='La longitud del campo email no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'ClientLegalRep.Address.PhoneNumber'}
          label={{ text: 'Teléfono' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Teléfono comprador representante',
            value:
              solicitud!.ClientLegalRep.Address.PhoneNumber !== ''
                ? solicitud!.ClientLegalRep.Address.PhoneNumber
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.ClientLegalRep.Address.PhoneNumber = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo teléfono no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
    </GroupItem>
  )
}
