import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { SolicitudListModel } from './solicitudListModel'
import {TableSolicitudListModelList} from './tableSolicitudesModelList'


@JsonObject('ColumnModelSolicitudesCore')
export class ColumnModelSolicitudesCore {
  @JsonProperty('Nombre', String)
  Nombre: string = ''

  @JsonProperty('Tipo', String)
  Tipo: string = ''

  @JsonProperty('Texto', String)
  Texto: string = ''

  @JsonProperty('Format', String)
  Format: string = ''

  @JsonProperty('Caption', String)
  Caption: string = ''

  @JsonProperty('Size', Number)
  Size: number = 0

  @JsonProperty('Visible', Boolean)
  Visible: boolean = false

  @JsonProperty('Orden', Number)
  Orden: number = 0
}

@JsonObject('SolicitudListCampoDatoModel')
export class SolicitudListCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelSolicitudesCore] || [])
  Campos: ColumnModelSolicitudesCore[] = []
  @JsonProperty('Datos', [TableSolicitudListModelList] || [])
  Datos: SolicitudListModel[] = []
}


