import { SujetoByIdExpedienteModel } from '../models/sujetoByIdExpedienteModel'
import { SujetoModel } from '../models/sujetoModel'
import { SujetosCampoDatoModel } from '../models/sujetosCampoDatoModel'

export interface SujetoState {
  sujeto: SujetoModel | undefined
  sujetos: SujetosCampoDatoModel | undefined
  sujetosbyexpediente: SujetoByIdExpedienteModel[] | undefined
}
export interface SujetosState {
  sujetos: SujetosCampoDatoModel | undefined
}

export const ADD_SUJETOS = '@todo/ADD_SUJETOS'
export const ADD_SUJETO = '@todo/ADD_SUJETO'
export const CLEAR_SUJETO = '@todo/CLEAR_SUJETO'
export const ADD_SUJETOS_BY_EXPEDIENTE = 'ADD_SUJETOS_BY_EXPEDIENTE'

interface AddSujetoRequest {
  type: typeof ADD_SUJETO
  payload: SujetoModel | undefined
}
interface AddSujetosRequest {
  type: typeof ADD_SUJETOS
  payload: SujetosCampoDatoModel | undefined
}
interface ClearExpedienteRequest {
  type: typeof CLEAR_SUJETO
  payload: SujetoModel | undefined
}

interface AddSujetosByExpediente {
  type: typeof ADD_SUJETOS_BY_EXPEDIENTE
  payload: SujetoByIdExpedienteModel[] | undefined
}

export type SujetoActionsTypes =
  | ClearExpedienteRequest
  | AddSujetosRequest
  | AddSujetoRequest
  | AddSujetosByExpediente
