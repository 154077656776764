import { Box, Button } from 'devextreme-react';
import React, { MutableRefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { addPagina } from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions';
import { Item } from 'devextreme-react/box';
import { addSolicitudList } from '../../redux/solicitudActions'

interface PropsTitulos {
  validos: Array<boolean>;
  vendedor: boolean;
  comprobarform: MutableRefObject<any>;
  establecerFormValidate: Function;
};

function TitulosSection({
  validos,
  vendedor,
  comprobarform,
  establecerFormValidate,
}: PropsTitulos) {
  const dispatch = useDispatch();
  const pagina = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.pagina,
  )
  const paginas = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.paginas,
  )
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )

  useEffect(() => {
    establecerFormValidate();
  })

  return (
    <React.Fragment>
      <>
        <Box
          direction={window.innerWidth > 500 ? 'row' : 'col'}
          height={window.innerWidth > 500 ? 60 : 120}
          align='center'
          crossAlign='center'
        >
        <Item
          ratio={0}
          baseSize={
            window.innerWidth > 500 && window.innerWidth > 740 ? 180 : 560
          }
        >
        <Button
          text={window.innerWidth > 500 ? 'Datos solicitud' : 'Solicitud'}
          type='default'
          hint='Datos Solicitud'
          stylingMode={pagina === 1 ? 'contained' : 'outlined'}
          onClick={() => {
            dispatch(addPagina(1))
            dispatch(addSolicitudList(solicitud))
          }}
        />
        </Item>
        <Item ratio={0} baseSize={30}>
          <br />
        </Item>
        <Item
          ratio={0}
          baseSize={
            window.innerWidth > 500 && window.innerWidth > 740 ? 180 : 600
          }
        >
          <Button
            text={window.innerWidth > 500 ? 'Datos comprador' : 'Comprador'}
            type='default'
            hint='Datos Comprador'
            stylingMode={pagina === 2 ? 'contained' : 'outlined'}
            onClick={() => {{
                dispatch(addPagina(2))
              }
            }}
          ></ Button>
        </Item>
          {vendedor && (
            <Item ratio={0} baseSize={30}>
              <br />
            </Item>
          )};

          {vendedor && (
            <Item
              ratio={0}
              baseSize={
                window.innerWidth > 500 && window.innerWidth > 740 ? 180 : 580
              }
            >
              <Button
                text={window.innerWidth > 500 ? 'Datos vendedor' : 'Vendedor'}
                type='default'
                hint='Datos Vendedor'
                stylingMode={pagina === 3 ? 'contained' : 'outlined'}
                onClick={() => { {
                    dispatch(addPagina(3))
                  }
                }}
              />
            </Item>
          )}
          <Item ratio={0} baseSize={30}>
            <br />
          </Item>
          <Item
            ratio={0}
            baseSize={
              window.innerWidth > 500 && window.innerWidth > 740 ? 180 : 520
            }
          >
            <Button
              text={window.innerWidth > 500 ? 'Datos vehículo' : 'Vehículo'}
              type='default'
              hint='Datos Vehiculo'
              stylingMode={
                pagina === (paginas === 4 ? 4 : 3) ? 'contained' : 'outlined'
              }
              onClick={() => {
                {
                  dispatch(addPagina(paginas === 4 ? 4 : 3))
                }
              }}
            />
          </Item>

          <Item ratio={0} baseSize={30}>
            <br />
          </Item>
        </Box>
        <Box height={60}>
          <Item></Item>
        </Box>
      </>
    </React.Fragment>
  )
}

export default TitulosSection;
