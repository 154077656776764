import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModel } from 'src/models/columnModel';
import { TemplateModel } from './templateModel';

@JsonObject('')
class TemplateFieldDataModel {
  @JsonProperty('Campos', [ColumnModel] || [])
  Campos: ColumnModel[] = [];

  @JsonProperty('Datos', [TemplateModel] || [])
  Datos: TemplateModel[] = [];
};

export { TemplateFieldDataModel };


