import React, { useRef } from 'react'
import { Form, Button } from 'devextreme-react'
import { Item, SimpleItem } from 'devextreme-react/form'
import { useMutation } from 'react-query'
import { postTasas } from '../service/AlmacenDeTasasService'
import { NotifyType, showToast } from 'src/utils/sharedUitls'
import { FormValues } from '../interfaces/InformesVehiculosInterfaces'
import { validateNotEmpty, validateNumericInput } from 'src/utils/validators'

const ImportarTasasForm = ({
  closePopup,
}: {
  closePopup: () => void
}) => {
  const [formData, setFormData] = React.useState<FormValues>({
    Tasa: '',
    Tramite: '',
    CodigoAutoliquidacion: '',
    FechaCompra: '',
    TipoPrincipal: '',
    TipoAsociada: '',
    GrupoPrincipal: '',
    Precio: 0,
  })

  const mutation = useMutation(postTasas)
  const formRef = useRef(null)

  const handleSubmit = async () => {
    if (
      !validateNotEmpty(formData.TipoPrincipal) ||
      !validateNotEmpty(formData.GrupoPrincipal)
    ) {
      showToast(
        'Los campos de Tasa, Tipo Principal y Grupo Principal son requeridos.',
        NotifyType.error,
        2000,
      )
      return
    }

    if (
      !validateNumericInput(formData.TipoPrincipal) ||
      !validateNumericInput(formData.GrupoPrincipal)
    ) {
      showToast(
        'Los campos de Tipo Principal y Grupo Principal solo aceptan números con un máximo de 2 dígitos y 2 decimales.',
        NotifyType.error,
        2000,
      )
      return
    }

    let formattedDate = formData.FechaCompra
    if (formData.FechaCompra) {
      const fechaCompra = new Date(formData.FechaCompra)
      formattedDate = fechaCompra.toISOString().split('T')[0]
    } else {
      const fechaActual = new Date()
      formattedDate = fechaActual.toISOString().split('T')[0]
    }
    const formattedData = {
      ...formData,
      FechaCompra: formattedDate,
      TipoPrincipal: formData.TipoPrincipal.toString(),
      GrupoPrincipal: formData.GrupoPrincipal.toString(),
    }
    try {
      await mutation.mutateAsync(formattedData)
      if (!mutation.isError) {
        closePopup()
      }
    } catch (error) {
      console.error('Error:', error)
      showToast(
        'El registro ya existe o no se pudo guardar.',
        NotifyType.error,
        2000,
      )
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Form
          ref={formRef}
          width={400}
          height={500}
          formData={formData}
          onFieldDataChanged={(e: any) =>
            setFormData({ ...formData, [e.dataField]: e.value })
          }
        >
          <Item dataField='Tasa' label={{ text: 'Tasa' }} />
          <Item dataField='Tramite' label={{ text: 'Trámite' }} />
          <Item
            dataField='CodigoAutoliquidacion'
            label={{ text: 'Código Autoliquidación' }}
          />
          <SimpleItem
            dataField='FechaCompra'
            editorType={'dxDateBox'}
            label={{ text: 'Fecha Compra' }}
            editorOptions={{
              defaultValue: new Date(),
              displayFormat: 'yyyy-MM-dd',
            }}
          />
          <Item
            dataField='GrupoPrincipal'
            label={{ text: 'Grupo Principal' }}
          />
          <Item dataField='TipoPrincipal' label={{ text: 'Tipo Principal' }} />
          <Item dataField='GrupoAsociada' label={{ text: 'Grupo Asociada' }} />
          <Item dataField='TipoAsociada' label={{ text: 'Tipo Asociada' }} />
          <Item dataField='Precio' label={{ text: 'Precio' }} />
        </Form>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-5vh',
          marginBottom: '2vh',
        }}
      >
        <Button
          text='Guardar Tasa'
          type='default'
          useSubmitBehavior={true}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

export { ImportarTasasForm }
