import { AsyncRule, EmailRule, GroupItem, SimpleItem, StringLengthRule } from 'devextreme-react/form';
import React from 'react';
import { validandoDocumento } from 'src/utils/NifDni/validation';
import { formatoFecha } from '../../utils/dates/formdateformat';
import { SolicitudListModel } from '../../models/solicitudListModel';
import { addVendedorRepresentante } from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions';
import { isCheckVendedorRepresentanteState } from 'src/recoil/sharesAtoms/atoms';
import { useDispatch } from 'react-redux'
import { useSetRecoilState } from 'recoil'

interface Props {
  solicitud: SolicitudListModel;
  sexo: Array<Object>;
}

export default function DatosPersonalesVendedorRepresentante({
  solicitud,
  sexo,
}: Props) {
  const dispatch = useDispatch()
  const setIsCheckVendedorRepresentante = useSetRecoilState(
    isCheckVendedorRepresentanteState,
  )
  
  return (
    
    <GroupItem>
      <GroupItem colCount={3} cssClass='GItem'>
        <SimpleItem
          dataField={'SellerLegalRep.Nif'}
          label={{ text: 'Nif/Nie/Cif' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'NIF/NIE/CIF vendedor representante',
            value:
              solicitud.SellerLegalRep.Nif !== ''
                ? solicitud.SellerLegalRep.Nif
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Nif = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        >
          <AsyncRule
            message='El campo numero de identificacion tiene que tener un formato valido.'
            validationCallback={(e: any) => {
              return validandoDocumento(e.value)
            }}
          />

          <StringLengthRule
            message={
              'La longitud del campo número de identificación no debe ser superior a 15'
            }
            max={15}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Nombre' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre vendedor representante',
            value:
              solicitud.SellerLegalRep.Name !== ''
                ? solicitud.SellerLegalRep.Name
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Name = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo nombre no debe ser superior a 150'}
            max={150}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Sexo' }}
          editorType='dxSelectBox'
          editorOptions={{
            dataSource: sexo,
            displayExpr: 'Nombre',
            hint: 'Sexo comprador representante',
            valueExpr: 'Id',
            value: solicitud.SellerLegalRep.Genre,
            onValueChanged: (e: any) => {
              if (e.value !== undefined) {
                solicitud.SellerLegalRep.Genre = e.value
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
          }}
        ></SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'F.Nacimiento' }}
          editorType='dxDateBox'
          editorOptions={{
            hint: 'Fecha Nacimiento Representante Cliente',
            value:
              solicitud.SellerLegalRep.BirthDate  != null  &&
              solicitud.SellerLegalRep.BirthDate.split('-')[0] !== '0001' &&
              solicitud.SellerLegalRep.BirthDate !== ''
                ? formatoFecha(solicitud.SellerLegalRep.BirthDate)
                : null,
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value  != null ) {
                let año = e.value.getFullYear()
                let mes =
                  e.value.getMonth() + 1 > 9
                    ? e.value.getMonth() + 1
                    : `0${e.value.getMonth() + 1}`
                let dia =
                  e.value.getDate() > 9
                    ? e.value.getDate()
                    : `0${e.value.getDate()}`

                solicitud.SellerLegalRep.BirthDate = `${año}-${mes}-${dia}`
                //dispatch(addSolicitudList(solicitud))
                const age = new Date().getFullYear() - año;
                
                if (age < 18) {
                  setIsCheckVendedorRepresentante(true);
                  dispatch(addVendedorRepresentante(true));
                }
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            showClearButton: true,
            useMaskBehavior: true,
            type: 'date',
            displayFormat: 'dd/MM/yyyy',
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Apellido 1' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Apellido 1 comprador representante',
            value:
              solicitud.SellerLegalRep.Surname1 !== ''
                ? solicitud.SellerLegalRep.Surname1
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Surname1 = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del apellido del representante del comprador no debe ser superior a 50'
            }
            max={50}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Apellido 2' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Apellido 2 comprador representante',
            value:
              solicitud.SellerLegalRep.Surname2 !== ''
                ? solicitud.SellerLegalRep.Surname2
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Surname2 = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del apellido del representante del comprador no debe ser superior a 50'
            }
            max={50}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Email' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Email comprador representante',
            value:
              solicitud.SellerLegalRep.Address.Email !== ''
                ? solicitud.SellerLegalRep.Address.Email
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Email = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <EmailRule
            message={
              'El campo email tiene que tener un formato valido, ex: test@test.com'
            }
          ></EmailRule>

          <StringLengthRule
            message='La longitud del campo email no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Teléfono' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Telefono comprador representante',
            value:
              solicitud.SellerLegalRep.Address.PhoneNumber !== ''
                ? solicitud.SellerLegalRep.Address.PhoneNumber
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.PhoneNumber = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo teléfono no debe ser superior a 50'
            max={50}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
    </GroupItem>
  );
};