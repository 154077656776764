import { Button, DataGrid } from 'devextreme-react'
import { Column, Editing } from 'devextreme-react/data-grid'
import React, { useContext, useRef } from 'react'
import { ConfigracionSolicitudesContext } from '../Context/dossierTypeContext'
import { addDossierType } from '../redux/dossierTypeActions'
import { useDispatch, useSelector } from 'react-redux'

export const DocumentacionASolicitar = () => {
  const dispatch = useDispatch()
  const dossierTypeState = useSelector(
    (state: any) => state.dossierTypes?.dossierType,
  )
  const dataGridRef = useRef<DataGrid>(null)
  let { setPopUpVisible } = useContext(ConfigracionSolicitudesContext)

  const saveInputsDocumentacion = (dataSource: any) => {
    dossierTypeState.InputsDocumentacion = dataSource.map((input: any) => {
      return {
        Id: input.Id,
        DossierTypeId: dossierTypeState.Id,
        InputName: input.InputName,
        Required: input.Required === undefined ? false : input.Required,
        Comentario: input.Comentario === undefined ? '' : input.Comentario,
      }
    })
    dispatch(addDossierType(dossierTypeState))
    setPopUpVisible(false)
  }

  return (
    <div>
      <div
        className='listaDocumentosSolicitados'
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '60%',
        }}
      >
        <DataGrid
          ref={dataGridRef}
          showRowLines={true}
          scrolling={{ mode: 'standard' }}
          paging={{ enabled: true, pageSize: 10 }}
          dataSource={dossierTypeState?.InputsDocumentacion}
          showBorders={true}
          columnAutoWidth={true}
          width={'auto'}
        >
          <Editing
            mode='row'
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Column dataField="Id" caption="Id" visible={false} />
          <Column dataField='InputName' caption='Documentos a solicitar' />
          <Column
            dataType='boolean'
            dataField='Required'
            caption='Obligatorio'
            width={100}
          />
          <Column dataField='Comentario' caption='Comentario' />
        </DataGrid>
      </div>
      <div style={{ marginTop: '2vh', textAlign: 'center' }}>
        <Button
          text='Guardar'
          type='default'
          onClick={() => {
            saveInputsDocumentacion(
              dataGridRef.current?.instance.getDataSource().items(),
            )
          }}
        />
      </div>
    </div>
  )
}

export default DocumentacionASolicitar
