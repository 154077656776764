import React, { useEffect, useRef, useState } from 'react'
import { Button, CheckBox, DataGrid, Popup, ScrollView } from 'devextreme-react'
import {
  Column,
  CustomRule,
  Editing,
  RequiredRule,
  StateStoring,
} from 'devextreme-react/data-grid'
import Dropzone, {
  defaultClassNames,
  IFileWithMeta,
  ILayoutProps,
} from 'react-dropzone-uploader'
import { NotifyType, showToast } from '../../../utils/sharedUitls'
import { read, utils } from 'xlsx'
import { useQuery } from 'react-query'
import {
  getAllSolicitudesColaborador,
  getAllTasasLibres,
  solicitarInformesVehiculosDgt,
} from '../services/InformeVehiculosService'
import { VehiculoUsuario } from '../../tasas/interfaces/InformesVehiculosInterfaces'
import {
  patronBastidor,
  patronMatricula,
  patronMatriculaAntigua,
  validateMatricula,
} from '../../../utils/validators'
import { validarNifDni } from '../../../utils/NifDni/validation'
import { useScreenSize } from 'src/utils/media-query'
import { SolicitudListModel } from '../../companyAdminPages/requestPage/models/solicitudListModel'
import { editSolicitud, postSolicitud } from '../../companyAdminPages/requestPage/service/solicitudService'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/combineReducers'
import { getByBastidor } from '../services/vehiculo/vehiculoService'
import { addShowLoader } from '../../../redux/actions/configActions'

export default function SolicitudInformeDgt() {
  const dispatch = useDispatch()
  const [popUpVisible, setPopupVisible] = useState(false)
  const userState = useSelector((state: RootState) => state.user)
  const [confirmacionTasasPopup, setConfirmacionTasasPopup] = useState(false)
  const [confirmarBorrarSolicitudes, setConfirmarBorrarSolicitudes] =
    useState(false)
  const [borrarSolicitudesDgt, setBorrarSolicitudesDgt] = useState<boolean>(false)
  const [dataVehiculos, setDataVehiculos] = useState<VehiculoUsuario[]>([])
  const [crearExpedienteCheckbox, setCrearExpedienteCheckbox] = useState(false)
  const { isXSmall, isMedium, isLarge } = useScreenSize()
  const dataGridRef = useRef(null)
  const { data: tasasLibres } = useQuery({
    queryKey: ['tasasLibres'],
    queryFn: () => getAllTasasLibres(),
    staleTime: Infinity,
  })

  const importExcelData = async (files: IFileWithMeta[]) => {
    const file = files[0].file
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    const workbook = read(arrayBuffer, { type: 'array' })
    const sheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[sheetName]
    const jsonData = utils.sheet_to_json(worksheet)
    jsonData.forEach((item: any) => {
      if (item['Bastidor-Matricula-Nive']) {
        item.Bastidor = item['Bastidor-Matricula-Nive']
        delete item['Bastidor-Matricula-Nive']
      }
    })
    setDataVehiculos(jsonData as VehiculoUsuario[])
    setPopupVisible(false)
  }

  const asignarTasasLibres = (data: VehiculoUsuario[]) => {
    if (tasasLibres) {
      const tasasLibresOrdenadas = [...tasasLibres].sort((a, b) => {
        return (
          new Date(a.FechaCompra).getTime() - new Date(b.FechaCompra).getTime()
        )
      })
      data.forEach((item: VehiculoUsuario) => {
        if (!item.Tasa) {
          const tasaSinAsignarIndex = tasasLibresOrdenadas.findIndex(
            (Tasa) => Tasa.Estado === 0,
          )
          if (tasasLibresOrdenadas[tasaSinAsignarIndex]) {
            item.Tasa = tasasLibresOrdenadas[tasaSinAsignarIndex].Tasa
          }
          if (tasaSinAsignarIndex !== -1)
            tasasLibresOrdenadas.splice(tasaSinAsignarIndex, 1)
        }
      })
      dispatch(addShowLoader(true))
      setDataVehiculos(data)
      ;(dataGridRef.current as any).instance.refresh()
    }
    if (tasasLibres?.length === 0) {
      showToast('No hay tasas libres.', NotifyType.error, 5000)
    }
    setTimeout(() => {
      dispatch(addShowLoader(false))
    }, 2000)
  }

  const incorporarSolicitudesColaborador = async () => {
    dispatch(addShowLoader(true))
    const lineas = await getAllSolicitudesColaborador()
    if (lineas.lenght === 0) {
      showToast('No existen solicitudes pendientes', NotifyType.error, 5000)
      return
    }
    console.log(lineas)
    setDataVehiculos(lineas)
    dispatch(addShowLoader(false))
  }

  const solicitarInformes = async () => {
    try {
      dispatch(addShowLoader(true))
      if (hasEmptyTasa(dataVehiculos) || dataVehiculos.length === 0) {
        showToast('Ningún vehículo con tasa asignada.', NotifyType.error, 5000)
        return
      }

      await handleSolicitudes(dataVehiculos)
      await solicitarInformesVehiculos(dataVehiculos)
    } catch (e) {
      dispatch(addShowLoader(false))
      console.error('Error al solicitar el informe de vehículos:', e)
      showToast(
        'Error al solicitar el informe de vehículos.',
        NotifyType.error,
        5000,
      )
    }
  }

  const hasEmptyTasa = (dataVehiculos: any) => {
    return dataVehiculos.filter((item: any) => item.Tasa === '').length > 0
  }

  const handleSolicitudes = async (data: VehiculoUsuario[]) => {
    for (let item of data) {
      if (crearExpedienteCheckbox && !item.ExpedienteId) {
        const solicitud = await createSolicitud(item)
        const response = await postSolicitud(userState?.user!, solicitud, false)
        item.ExpedienteId = response[0]
      }
    }
  }

  const createSolicitud = async (item: any) => {
    const solicitud = new SolicitudListModel()
    if (patronBastidor.test(item.Bastidor)) {
      solicitud.Vehicle.VIN = item.Bastidor
      const datosBastidor = await getByBastidor(userState?.user!, item.Bastidor)
      solicitud.Vehicle.Brand = datosBastidor.marcaItv
      solicitud.Vehicle.Model = datosBastidor.modeloItv
    } else if (
      patronMatriculaAntigua.test(item.Bastidor) ||
      patronMatricula.test(item.Bastidor)
    ) {
      solicitud.Vehicle.LicensePlate = item.Bastidor
    } else {
      solicitud.Vehicle.VIN = item.Bastidor
    }
    solicitud.OriginType = 1
    solicitud.DossierType = 'INF'
    solicitud.Client.Nif = item.NifCliente
    solicitud.Client.Name = item.NombreCliente
    solicitud.DealerNumber = item.NombreColaborador
    solicitud.TotalInvoiceAmount = 0
    solicitud.GenerarInformeDgt = false
    return solicitud
  }

  const solicitarInformesVehiculos = async (data: VehiculoUsuario[]) => {
    try {
      dispatch(addShowLoader(true))
      await solicitarInformesVehiculosDgt(data)
      showToast(
        'Solicitud de informes de vehículos enviada correctamente.',
        NotifyType.success,
        5000,
      )
      setDataVehiculos([])
      dispatch(addShowLoader(false))
    } catch (error: any) {
      console.error(
        'Error en solicitarInformesVehiculos:',
        error.cause,
        error.message,
      )
      showToast(
        'No se puede enviar solicitudes sin tasas asignadas. La tasas deben tener 12 digitos.',
        NotifyType.error,
        5000,
      )
    } finally {
      dispatch(addShowLoader(false))
    }
  }

  useEffect(() => {}, [setDataVehiculos])

  const borrarSeleccion = async () => {
    const dataGridInstance = (dataGridRef.current as any).instance;
    const selectedRowData = dataGridInstance.getSelectedRowsData();

    if (borrarSolicitudesDgt) {
      for (const obj of selectedRowData) {
        const solicitud = new SolicitudListModel()
        solicitud.IdExpediente = obj.ExpedienteId
        solicitud.GenerarInformeDgt = false
        solicitud.OriginType = 1
        solicitud.Client.Nif = obj.NifCliente
        solicitud.Vehicle.VIN = obj.Bastidor
        await editSolicitud(solicitud)
      }
    }

    const bastidores = new Set(selectedRowData.map((obj: any) => obj.Bastidor));
    const newDataVehiculos = dataVehiculos.filter((obj: VehiculoUsuario) => !bastidores.has(obj.Bastidor));

    setDataVehiculos(newDataVehiculos);
    dataGridInstance.refresh();
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    if (files.find((file: any) => file.file.size > 20000000) !== undefined) {
      showToast(
        'Revisar que los archivos no superen los 20 megas.',
        NotifyType.error,
        5000,
      )
    }
    return (
      <div>
        {previews}
        <div
          {...dropzoneProps}
          style={{ display: files.length > 0 ? 'none' : 'content' }}
        >
          {files.length < maxFiles && input}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {files.length > 0 &&
            files.find((file: any) => file.file.size > 20000000) ===
              undefined &&
            submitButton}
        </div>
      </div>
    )
  }

  return (
    <ScrollView>
      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
          marginTop: '5vh',
        }}
      >
        <div
          style={
            isLarge
              ? { display: 'flex', flexDirection: 'row' }
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
          }
          className='solicitarInformesDgt'
        >
          <DataGrid
            id='gridSolicitudInformeDgt'
            dataSource={dataVehiculos}
            showBorders={true}
            showRowLines={true}
            showColumnHeaders={true}
            style={{
              width: isLarge
                ? '850px'
                : isMedium
                ? '800px'
                : isXSmall
                ? '500px'
                : '400px',
              height: '480px',
            }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            selection={{
              mode: 'multiple',
              allowSelectAll: true,
              showCheckBoxesMode: 'always',
            }}
            wordWrapEnabled={true}
            columnAutoWidth={isXSmall}
            ref={dataGridRef}
          >
            <StateStoring
              enabled
              type='localStorage'
              storageKey='gridSolicitudInformeDgt'
            />
            <Editing
              mode='row'
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={true}
            />
            <Column dataField='Bastidor' caption='Matrícula/ Bastidor/ NIVE'>
              <RequiredRule />
              <CustomRule
                type='custom'
                message='Introduce una matricula/Bastidor/NIVE correcto.'
                validationCallback={(tasa: any) =>
                  validateMatricula(tasa.data.Bastidor.toUpperCase())
                }
              />
            </Column>
            <Column dataField='Tasa' caption='Tasa'></Column>
            <Column dataField='NifCliente' caption='Nif'>
              <RequiredRule />
              <CustomRule
                type='custom'
                validationCallback={(dni: any) =>
                  validarNifDni(dni.data.NifCliente)
                }
              />
            </Column>
            <Column dataField='NombreCliente' caption='Nombre' />
            <Column
              dataField='NombreColaborador'
              caption='Colaborador Solicitud'
            />
          </DataGrid>
          <div
            className='botonesSolicitudInformesVehiculos'
            style={{
              marginLeft: isLarge ? '50px' : '0px',
              display: isLarge ? 'flex' : 'grid',
              flexDirection: isLarge ? 'column' : 'row',
              maxWidth: '250px',
            }}
          >
            <Button
              type='default'
              style={{ marginTop: '80px' }}
              text='Asignar tasas libres'
              onClick={() => setConfirmacionTasasPopup(true)}
            />
            <Button
              type='default'
              style={{ marginTop: '10px' }}
              text='Incorporar solicitudes de colaboradores'
              onClick={() => incorporarSolicitudesColaborador()}
            />
            <Button
              type='default'
              style={{ marginTop: '10px' }}
              text='Importar Excel'
              onClick={() => setPopupVisible(!popUpVisible)}
            />
            <Button
              type='default'
              style={{ marginTop: '10px' }}
              text='Borrar selección'
              onClick={() => setConfirmarBorrarSolicitudes(true)}
            />
            <Button
              type='default'
              text='Solicitar'
              onClick={solicitarInformes}
              style={{
                width: '350px',
                height: '150px',
                textAlign: 'center',
                marginTop: '50px',
              }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: '24px',
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CheckBox
            text='No crear expediente'
            iconSize='18'
            value={!crearExpedienteCheckbox}
            onValueChange={(value) => setCrearExpedienteCheckbox(!value)}
          />
          <CheckBox
            text='Crear un expediente por vehículo'
            iconSize='18'
            style={{ marginTop: '10px' }}
            value={crearExpedienteCheckbox}
            onValueChange={(value: any) => setCrearExpedienteCheckbox(value)}
          />
        </div>

        <Popup
          title='Importar Excel'
          visible={popUpVisible}
          onVisibleChange={setPopupVisible}
          width='500'
          height='400'
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <label htmlFor=''>Plantilla de excel para tasa de vehiculos</label>
            <Button
              type='default'
              text='Descargar'
              onClick={() =>
                (window.location.href =
                  'https://www.elgestor.com/descargas/elportal/PlantillaSolicitudInformesDgt.xlsx')
              }
            >
              Descargar
            </Button>
          </div>
          <Dropzone
            LayoutComponent={Layout}
            onSubmit={importExcelData}
            classNames={{
              inputLabelWithFiles: defaultClassNames.inputLabel,
            }}
            submitButtonContent='Subir'
            maxFiles={1}
            accept={'.xlsx,.xls'}
            inputContent={'Selecciona un archivo o arrastra uno aquí'}
            inputWithFilesContent={(files) => `Agregar`}
            submitButtonDisabled={(files: any) => files.length < 1}
            styles={{
              dropzone: { minHeight: '6vh', maxHeight: '10vh' },
            }}
          />
        </Popup>
        <Popup
          visible={confirmacionTasasPopup}
          onVisibleChange={setConfirmacionTasasPopup}
          title=''
          width='500'
          height='224'
        >
          <h5 style={{ textAlign: 'justify' }}>
            ¿Estás seguro? Las tasas se aplicarán empezando por las fechas más
            antiguas hasta las más recientes.
          </h5>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: '10px',
            }}
          >
            <Button
              type='default'
              text='Aceptar'
              onClick={() => {
                setConfirmacionTasasPopup(!confirmacionTasasPopup)
                asignarTasasLibres(dataVehiculos)
              }}
            />
            <Button
              style={{ marginLeft: '10px' }}
              type='default'
              text='Cacelar'
              onClick={() => {
                setConfirmacionTasasPopup(!confirmacionTasasPopup)
              }}
            />
          </div>
        </Popup>
        <Popup
          visible={confirmarBorrarSolicitudes}
          onVisibleChange={setConfirmarBorrarSolicitudes}
          title='Borra solicitudes'
          width='500'
          height='190'
        >
          <div
            style={{
              display: 'grid',
              gridTemplateRows: '1fr',
              gridTemplateColumns: '1fr 1fr',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Button
              type='default'
              style={{ height: '6rem' }}
              onClick={async () => {
                setConfirmarBorrarSolicitudes(false)
                await borrarSeleccion()
              }}
            >
              Borrar solicitudes de la tabla
            </Button>
            <Button
              type='default'
              style={{height: '6rem' }}
              onClick={async () => {
                dispatch(addShowLoader(true))
                setBorrarSolicitudesDgt(true)
                await borrarSeleccion()
                setConfirmarBorrarSolicitudes(false)
                dispatch(addShowLoader(false))
              }}
            >
              <p style={{ textAlign: 'center', lineHeight: 'normal' }}>
              Borrar solicitudes de la tabla y no volver a incluirlas en la
              tabla de vehículos</p>
            </Button>
          </div>
        </Popup>
      </div>
    </ScrollView>
  )
}
