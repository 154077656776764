import React from 'react'
import { DataGrid } from 'devextreme-react'
import { Column, Scrolling } from 'devextreme-react/data-grid'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { getColumnAlignment, getColumnType } from 'src/utils/sharedUitls'

export default function HistorialTabVehiculo() {
  const expedienteState = useSelector((state: RootState) => state.vehiculos)

  return (
    <DataGrid
      dataSource={expedienteState?.tareasPendientes?.Datos}
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      rowAlternationEnabled
      showBorders
      showRowLines
    >
      <Scrolling showScrollbar='always' />
      <Column type='buttons' width={1} fixed={true} />
      {expedienteState?.tareasPendientes?.Campos !== undefined &&
        expedienteState?.tareasPendientes?.Campos.map((value) => (
          <Column
            key={value.Nombre}
            dataField={value.Nombre}
            caption={value.Texto}
            dataType={value.Tipo}
            format={getColumnType(value.Tipo, value.Format)}
            alignment={getColumnAlignment(value.Tipo)}
            visible={value.Nombre === 'Id' ? false : value.Visible}
          />
        ))}
    </DataGrid>
  )
}
