import { UsuarioCampoDatoModel } from 'src/models/usuariosPage/usuarioCampoDatoModel';
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel';
import {
  ADD_ROLES,
  ADD_USUARIO,
  ADD_USUARIOS_LIST,
  CLEAR_USUARIO,
  SHOW_FORM,
  UsuariosActionsTypes,
  UsuariosState,
} from './usuariosType'

const initialState: UsuariosState = {
  usuario: new UsuarioModel(),
  usuariosList: new UsuarioCampoDatoModel(),
  roles: [],
  showForm: false,
}

export const usuarioReducer = (
  state = initialState,
  action: UsuariosActionsTypes,
): UsuariosState => {
  switch (action.type) {
    case ADD_USUARIOS_LIST: {
      return { ...state, usuariosList: action.payload }
    }
    case ADD_USUARIO: {
      return { ...state, usuario: action.payload }
    }
    case CLEAR_USUARIO: {
      return { ...state, usuario: new UsuarioModel() }
    }
    case ADD_ROLES: {
      return { ...state, roles: action.payload }
    }
    case SHOW_FORM: {
      return { ...state, showForm: action.payload }
    }
    default:
      return state
  }
}
