import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { UserModel } from 'src/models/usuario/userModel';
import { NotifyType, showToast } from '../../../../utils/sharedUitls';
import { ModuleFieldDataModel } from '../models/moduleModel/moduleFieldDataModel';
import { FieldDataModelComponent } from '../models/modelComponent/fieldDataModelComponent';

const _getModules = async (
  user: UserModel,
): Promise<ModuleFieldDataModel | undefined> => {

  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    let url = `Modulo`;
    let ModuleData: ModuleFieldDataModel = new ModuleFieldDataModel();
    let data;

    await axiosInstance.get(url).then((response: any) => {
      ModuleData = jsonConvert.deserializeObject(
          response.data,
          ModuleFieldDataModel,
        )
        data = response.data;
      }
    ).catch((error: any) => {
      // showToast(
      //   'No se han podido obtener las compañias.',
      //   NotifyType.error, 5000
      // );
    });
    return ModuleData
  } catch (err) {
    console.error(err);
  };
};

const _getModulesGrid = async (
  user: UserModel,
  Id: Number,
): Promise<FieldDataModelComponent | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    let url = `ModuloComponente/` + Id
    let ModuleData: FieldDataModelComponent = new FieldDataModelComponent();
    let data;

    await axiosInstance.get(url).then((response: any) => {
      ModuleData = jsonConvert.deserializeObject(
        response.data,
        FieldDataModelComponent,
      );
      data = response.data;
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los modulos.',
        NotifyType.error, 5000
      );
    });
    return ModuleData;
  } catch (err) {
    console.error(err);
  };
};

export { 
  _getModules,
  _getModulesGrid
 };