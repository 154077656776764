import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { addShowLoader } from 'src/redux/actions/configActions';
import { addUsuariosList } from 'src/redux/usuariosPage/usuariosActions';
import { getUsuariosList } from '../../services/usuarioPage/usuarioServices';
import { isRolAllowed, ClaimsModule } from 'src/utils/allowAuthUtil';
import { Modules } from 'src/utils/allowModuloUtil';
import NotFound from 'src/components/notFound/notFound';
import UsuariosList from './sections/UsuariosList';
import UsuarioForm from './sections/UsuarioForm';

function UsuariosPage() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user!.user);
  const showForm = useSelector((state: RootState) => state.usuario.showForm);

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(addShowLoader(true));
        dispatch(addUsuariosList(await getUsuariosList(userState!)));
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [])

  return (
    <div id='container-body'>
      {isRolAllowed(userState!, Modules.ADMINISTRACION, [
        ClaimsModule.VIEW,
      ]) ? (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.2%',
          }}
        >
          <h3 style={{ margin: 0, padding: 0 }}>Usuarios</h3>
          <UsuariosList />
          {showForm && <UsuarioForm />}
        </div>
      ) : (
        <div
          style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.3%' }}
        >
          <NotFound data={{ type: 1 }} />
        </div>
      )}
    </div>
  );
}

export { UsuariosPage };
