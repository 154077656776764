import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CompaniesStorageSizeModel')
class CompaniesStorageSizeModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('NumeroExpedientes', Number)
  NumeroExpedientes: number = 0;

  @JsonProperty('AlmacenamientoArchivos', Number)
  AlmacenamientoArchivos: number = 0;
}

@JsonObject('CompanyStorageListModel')
class CompanyStorageListModel {
  @JsonProperty('companies', [CompaniesStorageSizeModel])
  companies: CompaniesStorageSizeModel[] = [];
};

export { CompanyStorageListModel, CompaniesStorageSizeModel };