import React, { useLayoutEffect } from 'react'
// devextreme
import Button from 'devextreme-react/button'
import { Template } from 'devextreme-react/core/template'
import Toolbar, { Item } from 'devextreme-react/toolbar'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addParentCompany } from 'src/pages/companyAdminPages/companyPage/redux/companiesActions'
// utils
import { useScreenSize } from 'src/utils/media-query'
// components
import { UserPanel } from './userPanel/UserPanel'
// services
import { getCompany } from 'src/pages/companyAdminPages/companyPage/services/companiesServices'
// styles

// ----------------------------------------------------------------------------

interface Props {
  title?: string
  toggleMenu: ({ event }: any) => void
}

// ----------------------------------------------------------------------------

export function Header({ title = '', toggleMenu }: Props) {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user?.user)
  const company = useSelector(
    (state: RootState) => state.company?.parentCompany,
  )
  const { isLarge, isXSmall } = useScreenSize()

  useLayoutEffect(() => {
    async function getCompanyData() {
      dispatch(
        addParentCompany(await getCompany(user!, parseInt(user?.CompanyId!))),
      )
    }
    getCompanyData()
  }, [dispatch, user])

  return (
    <Toolbar height={55} className='header-component'>
      <Item
        location={'before'}
        visible={window.innerWidth > 500 ? true : false}
      >
        <br></br>
      </Item>
      <Item visible={isXSmall} location='before' widget='dxButton'>
        <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
      </Item>

      <Item location='before' text={title} visible={!title} />

      <Item location='before' visible={isLarge}>
        <div style={{ fontWeight: 'bold', lineHeight: '3rem' }}>
          {company?.Slogan}
        </div>
      </Item>

      <Item
      // location={'before'}
      >
        {!!company?.PrincipalLogo && (
          <img
            src={`data:image/jpeg;base64,${company?.PrincipalLogo}`}
            style={{ height: '3rem', marginLeft: isXSmall ? '1rem' : '' }}
            alt=''
          />
        )}
      </Item>

      <Item
      // location='before'
      >
        {!!company?.SecondaryLogo && (
          <img
            src={`data:image/jpeg;base64,${company?.SecondaryLogo}`}
            style={{ height: '3rem' }}
            alt=''
          />
        )}
      </Item>

      <Item
        location='after'
        locateInMenu='auto'
        menuItemTemplate='userPanelTemplate'
      >
        <Button
          // width={210}
          id='userpanel'
          height='100%'
          // stylingMode='text'
        >
          <UserPanel menuMode='context' />
        </Button>
      </Item>
      <Item location={'after'} visible={window.innerWidth > 500 ? true : false}>
        <br></br>
      </Item>
      <Template name='userPanelTemplate'>
        <UserPanel menuMode='list' />
      </Template>
    </Toolbar>
  )
}
