import { ADD_EMAIL, CLEAR_EMAIL, EmailActionsTypes, EmailState } from "./emailType"

const initialState: EmailState = {
  email: undefined,
}

export const emailReducer = (
  state = initialState,
  action: EmailActionsTypes,
): EmailState | undefined => {
  switch (action.type) {
    
    case ADD_EMAIL: {
      return { ...state, email: action.payload }
    }
    case CLEAR_EMAIL: {
      return { ...state, email: action.payload }
    }
    default:
      return state
  }
}
