import { IdocCarConfigModel } from '../models/idocCarConfigModel'

import {
  ADD_IDOCCAR_CONFIG,
  ADD_IDOCCAR_CONFIG_ALL,
  CLEAR_IDOCCAR_CONFIG,
  IdocCarConfigActionsTypes,
} from './idocCarConfigType'

export const addIdocCarConfig = (
  idocCarConfig: Partial<IdocCarConfigModel> | IdocCarConfigModel | undefined,
): IdocCarConfigActionsTypes => ({
  type: ADD_IDOCCAR_CONFIG,
  payload: idocCarConfig,
})

export const clearIdocCarConfig = (
  idocCarConfig: IdocCarConfigModel | undefined,
): IdocCarConfigActionsTypes => ({
  type: CLEAR_IDOCCAR_CONFIG,
  payload: idocCarConfig,
})
export const addIdocCarConfigAll = (
  idocCarConfigAll: IdocCarConfigModel[] | undefined,
): IdocCarConfigActionsTypes => ({
  type: ADD_IDOCCAR_CONFIG_ALL,
  payload: idocCarConfigAll,
})
