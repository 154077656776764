import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { deleteIdocCarDossierStatus } from '../../idocCarStatesFiles/service/idocCarEstadosExpedientesService'

const serviceName = 'authService'

export const getIdocCarDossierStatusRelationList = async (
  user: UserModel,
): Promise<SolicitudListCampoDatoModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusRelation`
    let datos: any
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los registros.',
          NotifyType.error,
          5000,
        )
        checkStatusCode(error.response.status)
      })

    return datos
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

export async function postIdocCarDossierStatusRelation(
  user: UserModel,
  Solicitud: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `DossierStatusRelation`

    await axiosInstance.post(url, Solicitud).then((response: any) => {
      showToast('Relación creada', NotifyType.success, 5000)
      checkStatusCode(response.status)
    })
    return true
  } catch (error: any) {
    showToast('Error al crear la relacion', NotifyType.error, 5000)

    return false
  }
}

export async function putIdocCarDossierStatusRelation(
  user: UserModel,
  idocCarConfig: any,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusRelation`

    await axiosInstance
      .put(url, idocCarConfig)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud modificado ${idocCarConfig.Id}`,
          logType.INFO,
          serviceName,
          putIdocCarDossierStatusRelation.name,
          methodsEndPointType.PUT,
        )
        showToast('Configuración editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar caonfiguración iDocCar => ${error.message}`,
          logType.ERROR,
          serviceName,
          putIdocCarDossierStatusRelation.name,
          methodsEndPointType.PUT,
        )
      })
    return true
  } catch (error) {
    return false
  }
}

export async function deleteIdocCarRelationDossierStatus(
  user: UserModel,
  id: number,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierStatusRelation/` + id

    await axiosInstance
      .delete(url)
      .then((response: any) => {
        if (response.status === 200)
          showToast('Registro borrado correctamente', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        showToast('No se ha podido borrar el registro.', NotifyType.error, 5000)
        customLog(
          `Error al editar Solicitud => ${error.message}`,
          logType.ERROR,
          serviceName,
          deleteIdocCarDossierStatus.name,
          methodsEndPointType.PUT,
        )
        checkStatusCode(error.response.status)
      })
    return true
  } catch (error) {
    return false
  }
}