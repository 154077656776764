import { Button } from 'devextreme-react'
import { Box, Item } from 'devextreme-react/box';
import React, { useEffect, useState } from 'react'
import { validarEmailsSeparadosPorComas } from 'src/utils/validators'
import { DocumentTypeFormModel } from '../../models/documentTypeModelsForm'
import { NotifyType, showToast } from 'src/utils/sharedUitls'

interface Props {
  validation: string
  editar: boolean
  enviar: Function
  documento: DocumentTypeFormModel
}
export default function FooterDocumentTypeSection({
  validation,
  editar,
  enviar,
  documento,
}: Props) {
  const emailValido = /^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
  return (
    <Box direction='row' width={'100%'} align='end' crossAlign='end'>
      <Item ratio={0} baseSize={'auto'}>
        <Button
          text={!editar ? 'Crear' : 'Editar'}
          type='default'
          validationGroup={validation}
          onClick={(e: any) => {
            e.event.preventDefault()
            let result = e.validationGroup.validate()
            if (
              result.isValid &&
              (documento.additionalEmails
                .split(',')
                .map((email) => email.trim())
                .every((email) => emailValido.test(email)) ||
                documento.additionalEmails.length === 0)
            ) {
              enviar()
            } else {
              showToast('Error de validación', NotifyType.error, 5000)
            }
          }}
        />
      </Item>
    </Box>
  )
}
