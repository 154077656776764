import { Button, DataGrid, Popup, ScrollView } from 'devextreme-react';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { NotifyType, checkScreenSize, showToast } from 'src/utils/sharedUitls';
import { useScreenSize } from 'src/utils/media-query';
import { Column, StateStoring, ToolbarItem } from 'devextreme-react/data-grid';
import { DocumentTypeGridModel } from '../../models/documentTypeGridModel';
import { useQuery, useQueryClient } from 'react-query';
import { deleteDocumentType, getDocumentType } from '../../services/documentTypeServices';
import FormDocumentosSection from './FormDocumentTypeSection';
import { DocumentTypeFormModel } from '../../models/documentTypeModelsForm';
import { DigitalSignature } from 'src/pages/firmadigital/DocumentType/utils/DigitalSignature';
import { useDispatch} from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';
import { confirm } from 'devextreme/ui/dialog';

export default function TablaDocumentosSection() {
  const documentTypeGridRef: MutableRefObject<any> = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleOnclickDelete = async (row: DocumentTypeGridModel) => {
    const confirmed = await confirm(
      '¿Está seguro que desea eliminar este documento?',
      'Confirma los cambios'
    );
  
    if (confirmed) {
      dispatch(addShowLoader(true));
      try {
        await deleteDocumentType(row.id);
        setListaDocumentType((prevList) =>
        prevList.filter((item) => item.id !== row.id)
      );
        queryClient.invalidateQueries('ConsultaFirmaDocumentType');
        showToast('Documento eliminado exitosamente', NotifyType.success, 5000);
      } catch (error) {
        showToast(
          'No se ha podido eliminar el documento.',
          NotifyType.error,
          5000
        );
      } finally {
        dispatch(addShowLoader(false));
      }
    }
  };
  
  function exportExcell(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DocumentosType.xlsx',
        );
      });
    });
    e.cancel = true;
  }

  const [listaDocumentType, setListaDocumentType] = useState(new Array<DocumentTypeGridModel>());

  const [documentType, setDocumentType] = useState(new DocumentTypeFormModel());

  const { refetch, isFetching } = useQuery(['ConsultaFirmaDocumentType'], getDocumentType,{
    onSuccess: (data: Array<DocumentTypeGridModel> | []) => {
      if (data.length > 0) {
        setListaDocumentType(data);
      }
      dispatch(addShowLoader(false));
    },
    onError: () => {
      dispatch(addShowLoader(false));
    },
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (isFetching) dispatch(addShowLoader(true))
  }, [isFetching])
  const { isLarge } = useScreenSize();

  const onHanleOnToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear documento',
          icon: 'add',
          onClick: () => crearDocumentType(),
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: () => {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click();
          },
        },
      },
      {
        name: 'refrescar',
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async () => {
            try {
              dispatch(addShowLoader(true));
              refetch()
              documentTypeGridRef.current.instance.refresh();
            } catch (error) {
              showToast('Error al obtener la lista de documentos.', NotifyType.error, 5000);
            } finally {
              dispatch(addShowLoader(false));
            }
          },
        },
      },
    )
  }

  async function editarDocumentType(row: DocumentTypeGridModel) {
    let documento = new DocumentTypeFormModel();
    let resultadoFiltro = DigitalSignature(documento, row);
    
    setDocumentType(resultadoFiltro);
    setShowPopup(true);
  }

  function crearDocumentType() {
    setShowPopup(true);
  }

  function closePopUp() {
    setShowPopup(false);
    setDocumentType(new DocumentTypeFormModel());
  }

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        if (!document.getElementById('bodyContent')) {
          closePopUp()
        } else {
          document.getElementById('bodyTipoDocumento')!.focus();
        }
      }
    });
    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          if (!document.getElementById('bodyContent')) {
            closePopUp();
          } else {
            document.getElementById('bodyTipoDocumento')!.focus();
          }
        }
      });
    }
  }, []);

  return (
    <div id='bodyTipoDocumento'>
      <DataGrid
        id='documentTypeDataGrid'
        keyExpr='id'
        ref={documentTypeGridRef}
        dataSource={listaDocumentType}
        style={{ width: '100%', minHeight: '250px' }}
        height='calc(100vh - 8rem - 46px)'
        allowColumnReordering
        allowColumnResizing
        cacheEnabled
        columnAutoWidth
        remoteOperations
        repaintChangesOnly
        rowAlternationEnabled
        showBorders
        showRowLines
        columnChooser={{ enabled: true }}
        columnHidingEnabled={!checkScreenSize()}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        headerFilter={{ visible: false }}
        // loadPanel={{ enabled: true }}
        paging={{ enabled: false }}
        sorting={{ mode: 'multiple', showSortIndexes: false }}
        export={{
          allowExportSelectedData: true,
          enabled: true,
        }}
        searchPanel={{
          visible: true,
          width: checkScreenSize() ? 240 : 120,
        }}
        selection={{
          mode: 'multiple',
          showCheckBoxesMode: isLarge ? 'always' : 'none',
        }}
        scrolling={{
          mode: 'standard',
          scrollByContent: true,
          scrollByThumb: true,
          showScrollbar: 'always',
        }}
        onToolbarPreparing={onHanleOnToolbarPreparing}
        onExporting={exportExcell}
      >
        <StateStoring
          enabled={true}
          type={'localStorage'}
          storageKey={'DocumentTypeList'}
        />
        <Column
          type='buttons'
          width={50}
          cellRender={(row: any) => {
            return (
              <Button
                width='32px'
                icon='edit'
                hint='Editar'
                onClick={() => editarDocumentType(row.data)}
              />
            );
          }}
        />
        <Column
          type='buttons'
          width={46}
          cellRender={(row: any) => (
            <Button
              width='32px'
              icon='trash'
              hint='Borrar'
              onClick={() => handleOnclickDelete(row.data)}
            />
          )}
        />

        <Column
          width={'50%'}
          caption={'Name'}
          dataField={'name'}
          alignment='left'
        />
        <Column
          width={'50%'}
          caption={'ViafirmaPlantillaId'}
          dataField={'viafirmaPlantillaId'}
          alignment='left'
        />
      </DataGrid>
      {showPopup && (
        <Popup
          visible={true}
          hideOnOutsideClick={false}
          showCloseButton={false}
          height='90%'
          width='90%'
          title={
            documentType.id !== 0
              ? `Tipo de documento ${documentType.name}`
              : 'Nuevo tipo de documento'
          }
        >
          <ToolbarItem
            widget='dxButton'
            location='after'
            options={{
              icon: 'close',
              onClick: () => closePopUp(),
            }}
          />
          <ScrollView width={'100%'} height={'100%'}>
            <FormDocumentosSection
              documento={documentType}
              cerrarPopup={setShowPopup}
              setdocumento={setDocumentType}
            ></FormDocumentosSection>
          </ScrollView>
        </Popup>
      )}
    </div>
  )
}
