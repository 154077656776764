import { Popup } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view'
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/combineReducers'
import { useScreenSizeGrid } from '../../../../utils/media-query'
import DataGrid, {
  Column,
  ColumnChooser,
  ColumnFixing,
  Export,
  FilterPanel,
  LoadPanel,
  SearchPanel,
  StateStoring,
} from 'devextreme-react/data-grid'
import {
  addDossierStatusElGestor,
  addIdocCarDossierStatusElGestorAll,
} from '../redux/idocCarEstadosExpedientesActions'

import { ToolbarItem } from 'devextreme-react/popup'
import { custom } from 'devextreme/ui/dialog'
import IdocCarEstadosExpedientesFormPage from './idocCarEstadosExpedientesFormPage'
import {
  getIdocCarDossierStatusElGestorList
} from '../service/idocCarEstadosExpedientesService'

function IdocCarEstadosExpedientesPage(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const idocCarDossierStatusElGestorState = useSelector(
    (state: RootState) => state.idocCarDossierStatusElGestor,
  )
  const [rowFocused, setRowFocused] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showFormIdocCarDossierStatus, setShowFormIdocCarDossierStatus] =
    useState(false)
  const [isCreatingForm, setIsCreatingForm] = useState(false)
  const [isEditingForm, setIsEditingForm] = useState(false)
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const dataGridIdocCarDossierStatusRef: MutableRefObject<any> = useRef(null)
  const gridheight = useScreenSizeGrid()
  useEffect(() => {
    async function fetchData() {
      try {
        //dispatch(addHideLoader(true))
        dispatch(
          addIdocCarDossierStatusElGestorAll(
            await getIdocCarDossierStatusElGestorList(userState!.user!),
          ),
        )
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  })

  const renderStatusCell = (data: any) => {
    let dossierStatusName = data.data.DossierStatus.Name
    return (
      <div style={{ textAlign: 'center' }}>
        {dossierStatusName ? dossierStatusName : 'Sin estado'}
      </div>
    )
  }

  function onToolbarPreparing(event: any) {
    event.toolbarOptions.items.unshift(
      /*      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear',
          icon: 'add',
          // visible: false,
          onClick: (_e: any) => {
            dispatch(clearDossierStatusElGestor(undefined))

            setShowFormIdocCarDossierStatus(true)
            setIsCreatingForm(true)
          },
        },
      }, */
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: function onClick(_e: any) {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        name: 'id1',
        id: 'id1',
        options: {
          id: 'id1',
          name: 'id2',
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async (_e: any) => {
            dispatch(
              addIdocCarDossierStatusElGestorAll(
                await getIdocCarDossierStatusElGestorList(userState!.user!),
              ),
            )
          },
        },
      },
    )
  }

  return (
    <div id='container-body'>
      <div
        style={{
          borderColor: '#f4f9ff',
          borderWidth: '2px',
          margin: '0.3%',
          padding: '0.16%',
        }}
      >
        <h3 style={{ margin: 0, padding: 0 }}>
          iDocCar - Estados de Expedientes
        </h3>
        <div>
          <DataGrid
            dataSource={
              idocCarDossierStatusElGestorState.idocCarDossierStatusElGestorAll
            }
            keyExpr='Id'
            allowColumnResizing={true}
            columnResizingMode='nextColumn'
            columnMinWidth={25}
            ref={dataGridIdocCarDossierStatusRef}
            allowColumnReordering={true}
            height={gridheight}
            onToolbarPreparing={(event: any) => {
              onToolbarPreparing(event)
            }}
            showBorders={true}
            focusedRowEnabled={true}
            onRowDblClick={(e: any) => {
              if (e.key) {
                setRowFocused(e.key)
                dispatch(addDossierStatusElGestor(e.data))
              }
              setShowFormIdocCarDossierStatus(true)
            }}
            onRowClick={(e: any) => {
              if (e.key) {
                setRowFocused(e.key)
                dispatch(addDossierStatusElGestor(e.data))
              }
            }}
            onFocusedRowChanged={(e: any) => {
              if (e.row) {
                setRowFocused(e.row.key)
                dispatch(addDossierStatusElGestor(e.row.data))
              }
            }}
          >
            <Export enabled={true} />
            <SearchPanel visible={true} />
            <FilterPanel visible={true} />
            <ColumnChooser enabled={true} />
            <LoadPanel enabled />
            <StateStoring
              enabled={true}
              type='localStorage'
              storageKey='idoccarDossierStatus'
            />
            <ColumnFixing enabled={true} />
            <Column dataField='Id' caption='Registro' />
            <Column dataField='IdEstadoElGestor' caption='Estado El Gestor' />
            <Column dataField='Description' caption='Descripción' />
            <Column
              dataField='DossierStatus'
              caption='Estado El Portal'
              cellRender={renderStatusCell}
            />
          </DataGrid>
        </div>
        <Popup
          visible={showFormIdocCarDossierStatus}
          onHiding={(e) => {
            setShowFormIdocCarDossierStatus(!showForm)
          }}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={'IdocCar'}
          width={'65%'}
          height={'65%'}
          showCloseButton={false}
        >
          <ToolbarItem
            widget='dxButton'
            toolbar='top'
            location='after'
            options={{
              text: '',
              icon: 'close',
              onClick: () => {
                if (isChangedState) {
                  let confirmDialog = custom({
                    title: '',

                    messageHtml:
                      '<b>Para conservar los cambios antes pulsar Guardar, Si continúa perderá todos los datos cambiados</b>',
                    buttons: [
                      {
                        text: 'Continuar',
                        onClick: (e) => {
                          setShowFormIdocCarDossierStatus(false)
                          setIsCreatingForm(false)
                          setIsEditingForm(false)

                          confirmDialog.hide()
                        },
                      },
                      {
                        text: 'Cancelar',

                        onClick: (e) => {
                          confirmDialog.hide()
                        },
                      },
                    ],
                  })
                  confirmDialog.show().then((dialogResult: any) => {
                    console.log(dialogResult)
                  })
                } else {
                  setShowFormIdocCarDossierStatus(false)
                  setIsCreatingForm(false)
                  setIsEditingForm(false)
                }
              },
            }}
          />
          <ScrollView width='100%' height='100%'>
            <IdocCarEstadosExpedientesFormPage
              row={rowFocused}
              setShowFormIdocCarDossierStatus={setShowFormIdocCarDossierStatus}
              showFormIdocCarDossierStatus={showFormIdocCarDossierStatus}
              isCreatingForm={isCreatingForm}
              setIsCreatingForm={setIsCreatingForm}
              isEditingForm={isEditingForm}
              setIsEditingForm={setIsEditingForm}
            ></IdocCarEstadosExpedientesFormPage>
          </ScrollView>
        </Popup>
      </div>
    </div>
  )
}

export default IdocCarEstadosExpedientesPage
