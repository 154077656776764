import { IdocCarOrganizationalUnitModel } from '../models/idocCarOrganizationalUnitModel'

export interface IdocCarOrganizationalUnitState {
  idocCarOrganizationalUnit:
    | Partial<IdocCarOrganizationalUnitModel>
    | IdocCarOrganizationalUnitModel
    | undefined
  idocCarOrganizationalUnitAll: IdocCarOrganizationalUnitModel[] | undefined
}

export const ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL =
  'ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL'
export const ADD_IDOCCAR_ORGANIZATIONAL_UNIT = 'ADD_IDOCCAR_ORGANIZATIONAL_UNIT'
export const CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT =
  'CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT'

interface AddIdocCarOrganizationalUnitRequest {
  type: typeof ADD_IDOCCAR_ORGANIZATIONAL_UNIT
  payload:
    | Partial<IdocCarOrganizationalUnitModel>
    | IdocCarOrganizationalUnitModel
    | undefined
}
interface AddIdocCarOrganizationalUnitRequestAll {
  type: typeof ADD_IDOCCAR_ORGANIZATIONAL_UNIT_ALL
  payload: IdocCarOrganizationalUnitModel[] | undefined
}
interface ClearIdocCarOrganizationalUnitRequest {
  type: typeof CLEAR_IDOCCAR_ORGANIZATIONAL_UNIT
  payload: IdocCarOrganizationalUnitModel | undefined
}

export type IdocCarOrganizationalUnitActionsTypes =
  | ClearIdocCarOrganizationalUnitRequest
  | AddIdocCarOrganizationalUnitRequest
  | AddIdocCarOrganizationalUnitRequestAll
