import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/combineReducers';
import NotFound from '../../../../components/notFound/notFound';
import { ScrollView, SelectBox, Sortable, Switch } from 'devextreme-react';
import { fields } from '../utils/data';
import { addTemplatesFields } from '../redux/template/TemplateActions';
import { TemplateFieldsModel } from '../models/templateFieldsModel/templateFieldsModel';
import '../styles/plantillaCamposComponent.css';

function TemplateFields(props: any) {
  const dispatch = useDispatch();  
  const userState = useSelector((state: RootState) => state.user);
  const templateState = useSelector((state: RootState) => state.template);
  const [loading, setLoading] = useState(false);
  const [fieldsState, setFields] = useState([{}]);
  const [isModifyField, setIsModifyField] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setFields(fields);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  const handleUpdatedTemplate = (id: number, property: any) => {
    let templatesField: TemplateFieldsModel[] = []
    templateState.templateFields?.map((template: any) => {
      if (template.IdCampo === id) {
        template = {
          ...template,
          ...property,
        }
      }
      return templatesField.push(template)
    })

    dispatch(addTemplatesFields(templatesField))
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const dialogCall = async () => {
      let result: boolean = false

      if (result) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }
  const handleCancelClick = () => {
    props.setShowForm(false)
  }

  const onDragStart = (e: any) => {
    e.itemData = fields[e.fromIndex]
  }

  const onReorder = (e: any) => {
    const itemSelected: TemplateFieldsModel = templateState?.templateFields![
      e.fromIndex
    ] as TemplateFieldsModel
    let templateFields =
    templateState.templateFields !== undefined
        ? templateState.templateFields
        : []
    setFields([{}])
    templateFields = [
      ...templateFields!.slice(0, e.fromIndex),
      ...templateFields!.slice(e.fromIndex + 1),
    ]
    templateFields = [
      ...templateFields.slice(0, e.toIndex),
      itemSelected,
      ...templateFields.slice(e.toIndex),
    ]
    /**
     * Cuando reordenamos
     * si salta un onvaluechanged tanto en el
     * select como switch evitamos
     * que realice la actualizacion
     */
    setIsModifyField(false)
    dispatch(addTemplatesFields(templateFields as TemplateFieldsModel[]))
  }
  return (
    <>
      {true ? (
        <>
          <div
            className={'content-block dx-card responsive-paddings'}
            style={{
              borderColor: '#f4f9ff',
              borderWidth: '2px',
              margin: '0.6%',
            }}
          >
            <ScrollView width='100%' height='100%' showScrollbar='always'>
              <Sortable
                id='list'
                dropFeedbackMode={'push'}
                itemOrientation={'vertical'}
                dragDirection={'both'}
                scrollSpeed={30}
                scrollSensitivity={60}
                handle={''}
                onDragStart={(e: any) => {
                  onDragStart(e);
                }}
                onReorder={(e: any) => {
                  onReorder(e);
                }}
              >
                {templateState.templateFields?.map((campo: any, key: React.Key | null | undefined) => (
                  <div
                    key={key}
                    className='item dx-card dx-theme-text-color dx-theme-background-color'
                  >
                    <div className='settings'>
                      <div className='column'>
                        <div className='field'>
                          <div className='value'>
                            <h4>{campo.Nombre}</h4>
                          </div>
                        </div>
                      </div>
                      <div className='column'>
                        <div className='field'>
                          <div className='label'>Ancho</div>
                          &nbsp;
                          <div
                            className='value'
                            title={campo.IdCampo.toString()}
                          >
                            <SelectBox
                              dataSource={[1, 2, 3]}
                              value={campo.Ancho as number}
                              onValueChanged={(e: any) => {
                                if (isModifyField) {
                                  if (
                                    e.value === 1 ||
                                    e.value === 2 ||
                                    e.value === 3
                                  ) {
                                    handleUpdatedTemplate(
                                      parseInt(e.element.parentElement.title),
                                      {
                                        Ancho: e.value,
                                      },
                                    )
                                  }
                                }
                              }}
                              onFocusIn={(e: any) => {
                                /**
                                 *cuando pulsemos para editar al estar el foco
                                 en dicho item indicamos que cualquier cambio que se haga
                                 se actualize
                                 */
                                setIsModifyField(true)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      &nbsp;
                      <div className='column'>
                        <div className='field'>
                          <div>Visible</div>
                          &nbsp;
                          <div
                            className='value'
                            title={campo.IdCampo.toString()}
                            /**
                                 *cuando pulsemos para editar al estar el foco
                                 en dicho item indicamos que cualquier cambio que se haga
                                 se actualize
                                 */
                            onFocus={() => setIsModifyField(true)}
                          >
                            <Switch
                              value={campo.Visible as boolean}
                              onValueChanged={(e: any) => {
                                if (isModifyField) {
                                  handleUpdatedTemplate(
                                    parseInt(e.element.parentElement.title),
                                    {
                                      Visible: e.value,
                                    },
                                  )
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Sortable>
            </ScrollView>
          </div>
        </>
      ) : (
        <div>
          <NotFound data={{ type: 1 }} />
        </div>
      )}
    </>
  )
}

export { TemplateFields }