import {
  SujetoActionsTypes,
  ADD_SUJETO,
  CLEAR_SUJETO,
  SujetoState,
  ADD_SUJETOS,
  SujetosState,
  ADD_SUJETOS_BY_EXPEDIENTE,
} from './sujetoType'

const initialState: SujetoState = {
  sujeto: undefined,
  sujetos: undefined,
  sujetosbyexpediente: undefined,
}

export const sujetoReducer = (
  state = initialState,
  action: SujetoActionsTypes,
): SujetoState => {
  switch (action.type) {
    case ADD_SUJETO: {
      return { ...state, sujeto: action.payload }
    }
    case ADD_SUJETOS: {
      return { ...state, sujetos: action.payload }
    }
    case CLEAR_SUJETO: {
      return { ...state, sujeto: action.payload }
    }
    case ADD_SUJETOS_BY_EXPEDIENTE: {
      return { ...state, sujetosbyexpediente: action.payload }
    }
    default:
      return state
  }
}
