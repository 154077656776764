import React, { useEffect } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { routes } from './app-routes'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { RootState } from './redux/combineReducers'
import { store } from './redux/store'
import { Login } from './pages/loginPage/login'
import { Layout } from './layouts/Layout'
import { isAlreadyLoggedUser } from './redux/utils/reduxUtils'
import { useScreenSizeClass } from './utils/media-query'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ChangePassword } from './pages/loginPage/components/changePassword'
import './styles/dx-styles.css'
// ----------------------------------------------------------------------------

const queryClient = new QueryClient()

const Init = () => {
  const usuarioState = useSelector((state: RootState) => state.user)
  const content = useRoutes(routes)

  useEffect(() => {
    // Lógica para forzar la recarga de la caché global
    const clearCache = async () => {
      try {
        const response = await fetch(window.location.href, { cache: 'reload' })

        if (response.ok) {
          console.log('Caché borrada con éxito')
          // Éxito al cargar la aplicación, puedes realizar acciones adicionales si es necesario
        } else {
          console.error('Error al cargar la aplicación:', response.statusText)
        }
      } catch (error) {
        console.error('Error al cargar la aplicación:', error)
      }
    }

    clearCache()
  }, [])

  if (window.location.pathname === '/ResetPassword') {
    return <ChangePassword />
  }

  if (usuarioState?.isLoggedIn || isAlreadyLoggedUser()) {
    return <Layout>{content}</Layout>
  }
  return <Login />
}

// ----------------------------------------------------------------------------

function App() {
  const screenSizeClass = useScreenSizeClass()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <div className={`${screenSizeClass}`}>
            <Init />
          </div>
        </ReduxProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export { App }
