import { DossierStatusRelation } from '../models/idocCarEstadosExpedientesModel'

import {
  ADD_IDOCCAR_DOSSIER_STATUS_RELATION,
  ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL,
  CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION,
  IdocCarDossierStatusRelationActionsTypes,
} from './idocCarRelacionesEstadosExpedientesType'

const addDossierStatusRelation = (
  idocCarConfig:
    | Partial<DossierStatusRelation>
    | DossierStatusRelation
    | undefined,
): IdocCarDossierStatusRelationActionsTypes => ({
  type: ADD_IDOCCAR_DOSSIER_STATUS_RELATION,
  payload: idocCarConfig,
})

const clearDossierStatusRelation = (
  idocCarConfig: DossierStatusRelation | undefined,
): IdocCarDossierStatusRelationActionsTypes => ({
  type: CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION,
  payload: idocCarConfig,
})

const addIdocCarDossierStatusRelationAll = (
  idocCarConfigAll: any | undefined,
): IdocCarDossierStatusRelationActionsTypes => ({
  type: ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL,
  payload: idocCarConfigAll,
})

export {
  addDossierStatusRelation,
  clearDossierStatusRelation,
  addIdocCarDossierStatusRelationAll
}