import { CompanyCampoDatoGridModel } from 'src/models/company/companyCampoDatoModel'

export function NifCifExistente(
  nifcif: string,
  crearcompany: boolean,
  listacompanys: CompanyCampoDatoGridModel,
) {
  return new Promise((resolve) => {
    let resultado =
      listacompanys.Datos.find(
        (dato) => dato.IdentificationNumber === nifcif,
      ) !== undefined
        ? false
        : true

    if (!crearcompany) {
      resultado = true
    }
    resolve(resultado)
  })
}
