import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PerfilModel')
class ProfileModel {
  @JsonProperty('Id', Number, 0)
  Id: number = 0;

  @JsonProperty('Descripcion', String)
  Descripcion: string = '';

  @JsonProperty('Nombre', String)
  Nombre: string = '';

  @JsonProperty('IdSujeto', Number)
  IdSubjeto: number = 0;
}

export { ProfileModel };