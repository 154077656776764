import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { CustomForm } from 'src/pages/expedientePage/components/CustomForm';
import NoData from 'src/pages/expedientePage/components/NoData';

export default function AytoCatastroTabBienes() {
  const { ayuntamiento } = useSelector((state: RootState) => state.escrituras);
  const { catastro } = useSelector((state: RootState) => state.escrituras);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1.5rem' }}>
      <div style={{ flex: '1 1 auto' }}>
        <h5>Ayuntamiento</h5>
        <div style={{ marginBottom: '1rem' }} />
        {!!ayuntamiento.Datos.length ? (
          <CustomForm {...ayuntamiento} />
        ) : (<NoData />)
        }
      </div>

      <div style={{ flex: '1 1 auto' }}>
        <h5>Catastro</h5>
        <div style={{ marginBottom: '1rem' }} />
        {!!catastro.Datos.length ? <CustomForm {...catastro} /> : <NoData />}
      </div>
    </div>
  );
};
