import { Box, Popup } from 'devextreme-react'
import ContextMenu from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { custom } from 'devextreme/ui/dialog'
import React, { useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logoUser from 'src/assets/user.png'
import { RootState } from '../../../redux/combineReducers'
import { useNavigate } from 'react-router-dom'
import { addUnLoggedUser } from 'src/pages/loginPage/redux/authActions'
import './style/userPanel.scss'
import {
  AddInformaConsultaHistorico,
  addInformaCompaniesStock,
} from 'src/pages/informa/redux/actions/InformaActions'
import { useQueryClient, QueryCache } from 'react-query'

function getTimeLogIn() {
  var d = new Date()
  var horaInicioSesion =
    ('0' + d.getDate()).slice(-2) +
    '-' +
    ('0' + (d.getMonth() + 1)).slice(-2) +
    '-' +
    d.getFullYear() +
    ' ' +
    ('0' + d.getHours()).slice(-2) +
    ':' +
    ('0' + d.getMinutes()).slice(-2)
  return horaInicioSesion
}

export function UserPanel({ menuMode }: { menuMode: any }) {
  const userState = useSelector((state: RootState) => state.user?.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [popUpHelpVisible, setPopUpHelpVisible] = useState(false)
  const queryClient = useQueryClient()
  const queryCache = new QueryCache()

  const handleClearConfig = useCallback(() => {
    custom({
      title: 'Confirmación',
      messageHtml:
        '¿Está seguro de que quiere restaurar todas la configuraciones? Se cerrará la sesión para poder llevarlo a cabo.',
      buttons: [
        {
          text: 'Continuar',
          onClick: () => {
            localStorage.clear()
            dispatch(addUnLoggedUser())
            navigate('/Login')
          },
        },
        { text: 'Cancelar' },
      ],
    }).show()
  }, [dispatch, navigate])

  const menuItems = useMemo(
    () => [
      /*  {
        text: "Profile",
        icon: "user",
        onClick: navigateToProfile,
      }, */

      /*      {
        text: 'Mi Cuenta',
        icon: 'card',
        onClick: () => {},
      }, */
      {
        text: 'Ayuda',
        icon: 'help',
        onClick: () =>
          window.open(
            'https://wiki.ruano.com/index.php?title=Portal:_Índice',
            // 'https://wiki.ruano.com/index.php?title=Portal_2021:_%C3%8Dndice',
            '_blank',
          ),
      },
      {
        text: getTimeLogIn(),
        icon: 'clock',
        style: { fontWeight: 'bold' },
      },
      {
        text: 'Cerrar sesión y Restaurar configuración',
        icon: 'revert',
        style: { fontWeight: 'bold' },
        onClick: handleClearConfig,
      },
      {
        text: 'Cerrar Sesión',
        icon: 'runner',
        onClick: () => {
          localStorage.removeItem('user')
          localStorage.removeItem('storage')
          localStorage.removeItem('parentEnlaces')
          localStorage.removeItem('recordarUsuario')
          queryClient.removeQueries()
          queryCache.clear()
          //localStorage.removeItem('APP_VERSION')
          dispatch(addInformaCompaniesStock(undefined))
          dispatch(AddInformaConsultaHistorico(undefined))
          dispatch(addUnLoggedUser())
          navigate('/Login')
        },
      },
    ],
    [handleClearConfig, dispatch, navigate],
  )

  return (
    <>
      <div className={'user-panel'}>
        <div className={'user-info'}>
          <div className={'user-name'}>
            <b>{userState?.UserName}</b>
            {' · '}
            <i>{userState?.role}</i>
          </div>

          <div className={'image-container'}>
            <div
              className={'user-image'}
              style={{
                background: `url(${logoUser}) no-repeat #fff`,
                backgroundSize: 'cover',
              }}
            />
          </div>
        </div>
        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target={'.user-panel'}
            cssClass={'user-menu'}
            showEvent={'dxcontextmenu dxclick'}
            // width={210}
            position={{
              my: { x: 'right', y: 'bottom' },
              at: { x: 'right', y: 'top' },
            }}
          />
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menuItems} />
        )}
      </div>
      <Popup
        title='Ayuda'
        visible={popUpHelpVisible}
        onHiding={() => setPopUpHelpVisible(false)}
        resizeEnabled
        width='98%'
        height='98%'
      >
        <iframe
          id='inlineFrame'
          title=' '
          width='100%'
          height='100%'
          src='https://wiki.ruano.com/index.php?title=Portal:_%C3%8Dndice'
        />
      </Popup>
    </>
  )
}
