export interface SharedUtilsState {
  showPopup: boolean;
  isOpenPopup: boolean | undefined;
  popupRef: any;
  isChanged: boolean | undefined;
}

export const SHOW_POPUP = '@UTILS/SHOW_POPUP';
export const OPEN_POPUPSUJETOS = 'OPEN_POPUPSUJETOS';
export const ADD_DATAFORMCHANGE = 'ADD_DATAFORMCHANGE';

interface ShowPopupRequest {
  type: typeof SHOW_POPUP;
  payload: boolean;
}
interface AddPopupSujetosRequest {
  type: typeof OPEN_POPUPSUJETOS;
  payload: boolean | undefined | any;
}

interface AddDataFormChangeRequest {
  type: typeof ADD_DATAFORMCHANGE;
  payload: boolean | undefined;
}

export type sharedUtilsActionsTypes =
  | ShowPopupRequest
  | AddPopupSujetosRequest
  | AddDataFormChangeRequest
