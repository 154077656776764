import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  checkStatusCode,
  customLog,
  logType,
  methodsEndPointType,
  NotifyType,
  showToast,
} from '../../../utils/sharedUitls'
import { DossierTypeModel } from '../models/dossierTypeModel'
import { DossierTypeFieldDataModel } from '../models/dossierTypeFieldDataModel'
import { UserModel } from 'src/models/usuario/userModel'
const serviceName = 'authService'

const _getDossierTypes = async (
  user: UserModel,
): Promise<DossierTypeFieldDataModel | any | undefined> => {
  let datos: any
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierType`
    await axiosInstance
      .get(url)
      .then((response: any) => {
        datos = response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener las Solicitudes.',
          NotifyType.error,
          5000,
        )
      })
    return datos
  } catch (err) {
    console.error(err)
    return undefined
  }
}

async function _getDossierType(id: number): Promise<DossierTypeModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `DossierType/${id}`

  let dossierType: DossierTypeModel = new DossierTypeModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      dossierType = jsonConvert.deserializeObject(
        response.data.Datos[0],
        DossierTypeModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getDossierType.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return dossierType
}

async function _getDossierTypeInputs(id: number): Promise<DossierTypeModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `DossierType/${id}`

  let dossierType: DossierTypeModel = new DossierTypeModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      dossierType = response.data
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getDossierType.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return dossierType
}

async function _postDossierType(
  dossierType: Partial<DossierTypeModel> | DossierTypeModel | undefined,
): Promise<DossierTypeModel> {
  let newDossierType = new DossierTypeModel()
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `DossierType`
    const response = await axiosInstance.post(url, dossierType)
    checkStatusCode(response.status)
    newDossierType = jsonConvert.deserializeObject(
      response.data,
      DossierTypeModel,
    )
    customLog(
      `Solicitud creada ${response.data.Id}`,
      logType.INFO,
      serviceName,
      _postDossierType.name,
      methodsEndPointType.POST,
    )

    showToast('Solicitud creada', NotifyType.success, 5000)

    return newDossierType
  } catch (error: any) {
    customLog(
      `Error al crear Solicitud => ${error.message}`,
      logType.ERROR,
      serviceName,
      _postDossierType.name,
      methodsEndPointType.POST,
    )

    return newDossierType
  }
}

async function _putDossierType(dossierType: any): Promise<DossierTypeModel> {
  try {
    // Configura jsonConvert
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    // Define la URL de la solicitud
    let url = `DossierType`

    // Realiza la solicitud PUT
    const response = await axiosInstance.put(url, dossierType)

    checkStatusCode(response.status)
    customLog(
      `Solicitud modificado ${dossierType.Id}`,
      logType.INFO,
      serviceName,
      _putDossierType.name,
      methodsEndPointType.PUT,
    )

    // Actualiza la notificación y retorna verdadero para indicar éxito
    showToast('Solicitud editada', NotifyType.success, 5000)

    // Actualiza el objeto original con la nueva información del servidor
    Object.assign(dossierType, response.data)

    return dossierType
  } catch (error: any) {
    // Loguea el error y retorna falso para indicar falla
    console.error('Error en _putDossierType:', error)
    customLog(
      `Error al editar Solicitud => ${error.message}`,
      logType.ERROR,
      serviceName,
      _putDossierType.name,
      methodsEndPointType.PUT,
    )
    return dossierType
  }
}

async function _deleteDossierType(id: number): Promise<boolean> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `DossierType/${id}`
  var result = false

  await axiosInstance
    .delete(url)
    .then((response: any) => {
      if (response.status === 200) {
        showToast('Registro borrado correctamente.', NotifyType.success, 5000)
        result = false
      }
      checkStatusCode(response.status)
    })
    .catch((error: any) => {
      showToast('No se ha podido borrar el registro.', NotifyType.error, 5000)
      result = false
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getDossierType.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return result
}

async function _getStructure(): Promise<DossierTypeFieldDataModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Solicitud/Structure`
  let dossierType: DossierTypeFieldDataModel = new DossierTypeFieldDataModel()

  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      dossierType = jsonConvert.deserializeObject(
        response.data,
        DossierTypeFieldDataModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return dossierType
}

export {
  _postDossierType,
  _putDossierType,
  _deleteDossierType,
  _getDossierType,
  _getDossierTypes,
  _getStructure,
  _getDossierTypeInputs,
}
