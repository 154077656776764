import { CosteCampoDatoModel, DetalleCosteCampoDatoModel } from '../../models/costeModel';
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel';
import { DocumentoCampoDatoModel } from '../../models/documentoCampoDatoModel';
import { StructureExpedienteModel } from '../../models/structureExpedienteModel';
import { TareaPendienteCampoDatoModel } from '../../models/tareaPendienteModel';
import { DetalleVehiculoCampoDatoModel } from '../../models/vehiculo/detalleVehiculoModel';
import {
  ADD_COSTES,
  ADD_DETALLE_COSTE,
  ADD_DETALLE_EXPEDIENTE,
  ADD_DETALLE_VEHICULO,
  ADD_DOCUMENTOS,
  ADD_STRUCTURE,
  ADD_TAREAS_PENDIENTES,
  ExpVehiculoActionsTypes,
  ExpVehiculoState,
} from './vehiculoType';

const initialState: ExpVehiculoState = {
  costes: new CosteCampoDatoModel(),
  detalleCoste: new DetalleCosteCampoDatoModel(),
  detalleExpediente: new DetailExpedientFieldDataModel(),
  detalleVehiculo: new DetalleVehiculoCampoDatoModel(),
  documentos: new DocumentoCampoDatoModel(),
  structure: new StructureExpedienteModel(),
  tareasPendientes: new TareaPendienteCampoDatoModel(),
};

export const expedienteVehiculoReducer = (
  state = initialState,
  action: ExpVehiculoActionsTypes,
): ExpVehiculoState => {
  switch (action.type) {
    case ADD_STRUCTURE: {
      return { ...state, structure: action.payload };
    }
    case ADD_DETALLE_EXPEDIENTE: {
      return { ...state, detalleExpediente: action.payload };
    }
    case ADD_DETALLE_COSTE: {
      return { ...state, detalleCoste: action.payload };
    }
    case ADD_DETALLE_VEHICULO: {
      return { ...state, detalleVehiculo: action.payload };
    }
    case ADD_COSTES: {
      return { ...state, costes: action.payload };
    }
    case ADD_TAREAS_PENDIENTES: {
      return { ...state, tareasPendientes: action.payload };
    }
    case ADD_DOCUMENTOS: {
      return { ...state, documentos: action.payload };
    }
    default:
      return state;
  };
};
