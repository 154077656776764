import Form, {
  GroupItem,
  NumericRule,
  SimpleItem,
  StringLengthRule,
} from 'devextreme-react/form'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import FooterFormSection from '../FooterForm/FooterFormSection'
import {
  actualizarArchivos,
  addActualizarListaArchivos,
} from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { Box, Button, DataGrid } from 'devextreme-react'
import { Item } from 'devextreme-react/box'
import { Column } from 'devextreme-react/data-grid'
import { ListaArchivosModel } from '../../models/ListaArchivosModel'
import { InputsDocumentacion } from '../../../../dossierType/models/dossierTypeModel'
import { getFileIcon } from 'src/utils/sharedUitls'
import { useLocation } from 'react-router-dom'

interface Props {
  validos: Function
  formDatosSolicitud: MutableRefObject<any>
  editar: boolean
  archivosagregados: ListaArchivosModel[]
  inputsDocumentacion: InputsDocumentacion[]
}

function DatosSolicitudSection({
  inputsDocumentacion,
  validos,
  formDatosSolicitud,
  editar,
  archivosagregados,
}: Props) {
  const dispatch = useDispatch()
  const { solicitud, pagina, paginas, listaarchivos, formFile } = useSelector(
    (state: RootState) => ({
      solicitud: state.solicitudesList.solicitudList,
      pagina: state.formularioSolicitudDatosCamposUtil!.pagina,
      paginas: state.formularioSolicitudDatosCamposUtil!.paginas,
      listaarchivos: state.formularioSolicitudDatosCamposUtil!.listaarchivos,
      formFile: state.formularioSolicitudDatosCamposUtil!.archivos,
    }),
  )
  const isCalculadora = useLocation().pathname.includes(
    'accessOnlyCalculatorPage',
  )
  const inputsRefInterna = useRef(null)
  const inputsRefTotalFactura = useRef(null)
  const inputsRefComentario = useRef(null)
  const DocumentosDataGrid: MutableRefObject<any> = useRef(null)
  const [inputsDocumentacionGrid, setInputsDocumentacionGrid] =
    useState(inputsDocumentacion)
  useEffect(() => {
    let updatedInputs: InputsDocumentacion[] = []
    if (listaarchivos && listaarchivos.length > 0) {
      listaarchivos.forEach((file, index) => {
        if (inputsDocumentacion[index] && !editar && !isCalculadora) {
          inputsDocumentacion[index].FileName = file.Nombre.toString()
          updatedInputs.push(inputsDocumentacion[index])
        } else {
          updatedInputs.push({
            Id: Number(file.Id),
            DossierTypeId: 0,
            FileName: file.Nombre.toString(),
            InputName: 'Otro documento',
            Comentario: '',
            Required: false,
          })
        }
      })
      setInputsDocumentacionGrid([...updatedInputs, ...(editar ? [] : inputsDocumentacion)])
    } else {
      setInputsDocumentacionGrid(inputsDocumentacion)
    }
  }, [editar, isCalculadora, inputsDocumentacion, listaarchivos])

  async function borrarArchivo(nombre: String, id: Number) {
    const listaActualizada = listaarchivos?.filter((file) => {
      if (file.Nombre !== nombre) {
        return file
      }
      return false
    })

    const archivosActualizados = formFile?.filter((file) => {
      if (file.name !== nombre) {
        return file
      }
      return false
    })

    dispatch(addActualizarListaArchivos(listaActualizada!))
    dispatch(actualizarArchivos(archivosActualizados!))
  }

  const renderButtonQuitar = (row: any) => {
    if (row.data.FileName) {
      return (
        <Button
          icon='trash'
          style={{ margin: '0 1vh' }}
          visible={!editar}
          type='danger'
          onClick={async () => {
            await borrarArchivo(row.data.FileName, row.data.Id)
            setFileNames((prevFileNames) => {
              const newFileNames = { ...prevFileNames }
              newFileNames[row.rowIndex] = ''
              row.data.FileName = ''
              return newFileNames
            })
          }}
        />
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    DocumentosDataGrid.current?.instance.refresh(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaarchivos!.length])
  const [fileNames, setFileNames] = useState<{ [key: string]: string }>({})

  const handleFileUpload = (rowId: string, fileName: string) => {
    setFileNames((prevFileNames) => ({
      ...prevFileNames,
      [rowId]: fileName,
    }))
  }
  const DocumentCell = (props: any) => {
    const { onFileUpload } = props
    const handleClick = () => {
      const input = document.createElement('input')
      input.type = 'file'
      input.onchange = (event: Event) => {
        const files = (event.target as HTMLInputElement).files
        if (files && files.length > 0) {
          const file = files[0]
          onFileUpload(props.row.rowIndex, file.name)
          props.data.FileName = file.name
          props.data.Subido = false
          props.data.id = props.row.rowIndex
        }
        Array.from(files!).forEach((file: File) => formFile!.push(file))
        Array.from(files!).forEach((f: File) => {
          let listaArchivos = new ListaArchivosModel()
          listaArchivos.Nombre = f.name
          listaArchivos.Subido = false
          if (
            listaarchivos!.find((archivo) => archivo.Nombre === f.name) ===
            undefined
          ) {
            listaarchivos!.push(listaArchivos)
          }
        })
        dispatch(actualizarArchivos(formFile!))
        dispatch(addActualizarListaArchivos(listaarchivos!))
      }
      input.click()
    }
    let fileName = ''
    if (listaarchivos!.length > 0) {
      fileName = props.data.FileName
    }
    return (
      <div>
        {fileName ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i
              style={{ fontSize: '25px' }}
              className={'dx-icon-' + getFileIcon(fileName.split('.')[1])}
            />
            <p>{fileName}</p>
          </div>
        ) : (
          <div>
            <Button
              icon='upload'
              text='Subir'
              onClick={handleClick}
              style={{ border: 'rgb(212, 212, 212) solid 1px' }}
            />
          </div>
        )}
      </div>
    )
  }

  const addOtrosDocumentos = () => {
    const newData = {
      Id: inputsDocumentacionGrid.length + 1,
      DossierTypeId: 0,
      InputName: 'Otro documento',
      Comentario: '',
      Required: false,
    }
    setInputsDocumentacionGrid([...inputsDocumentacionGrid, newData])
  }

  return (
    <form>
      <Form
        id='formSolicitud'
        ref={formDatosSolicitud}
        formData={solicitud}
        readOnly={false}
        showColonAfterLabel={true}
        showValidationSummary={true}
        alignItemLabels={true}
        alignItemLabelsInAllGroups={true}
        validationGroup='customerData'
      >
        <GroupItem>
          <GroupItem colCount={3} cssClass={'alinearinputs'}>
            <SimpleItem
              dataField={'DealerNumber'}
              ref={inputsRefInterna}
              label={{ text: 'Ref.interna' }}
              editorType={'dxTextBox'}
              editorOptions={{
                mode: 'text',
                hint: 'Ref.Interna',
                value: solicitud!.DealerNumber,

                onValueChanged: (e: any) => {
                  if (e.value !== undefined && e.value != null) {
                    solicitud!.DealerNumber = e.value.trim()
                    //dispatch(addSolicitudList(solicitud))
                  }
                },
                onKeyPress: (e: any) => {
                  if (e.event.key === 'Enter') {
                    e.event.preventDefault()
                  }
                },
              }}
            >
              <StringLengthRule
                message={
                  ' La longitud del campo referencia interna de la solicitud no debe ser superior a 1000 caracteres'
                }
                max={1000}
              />
            </SimpleItem>

            <SimpleItem
              ref={inputsRefTotalFactura}
              label={{ text: 'Total factura' }}
              editorType={'dxTextBox'}
              editorOptions={{
                mode: 'text',
                hint: 'Total Factura',
                value:
                  solicitud!.TotalInvoiceAmount !== -1
                    ? solicitud!.TotalInvoiceAmount
                    : null,
                onValueChanged: ({ value }: any) => {
                  if (
                    (value !== undefined || value != null) &&
                    !isNaN(Number(value))
                  ) {
                    solicitud!.TotalInvoiceAmount = Number(value)
                  } else {
                    solicitud!.TotalInvoiceAmount = value
                  }
                },
                onKeyPress: (e: any) => {
                  if (e.event.key === 'Enter') {
                    e.event.preventDefault()
                  }
                },
              }}
            >
              <NumericRule
                message={
                  'El valor del campo total de la factura debe ser numérico'
                }
              />
            </SimpleItem>
            <SimpleItem
              ref={inputsRefComentario}
              label={{ text: 'Comentario' }}
              editorType={'dxTextBox'}
              editorOptions={{
                mode: 'text',
                hint: 'Comentario',
                value: solicitud!.Comment,
                onValueChanged: ({ value }: any) => {
                  if (value !== undefined && value != null) {
                    solicitud!.Comment = value.trim()
                    //dispatch(addSolicitudList(solicitud))
                  }
                },
                onKeyPress: (e: any) => {
                  if (e.event.key === 'Enter') {
                    e.event.preventDefault()
                  }
                },
              }}
            >
              <StringLengthRule
                message={
                  'El campo comentario no debe ser superior a 1000 caracteres'
                }
                max={1000}
              />
            </SimpleItem>
          </GroupItem>
          <GroupItem></GroupItem>
          <GroupItem></GroupItem>
          <GroupItem></GroupItem>
          <GroupItem>
            <div style={{ textAlign: 'center' }}>
              <h3>Lista de Documentos Solicitados</h3>
            </div>
            <div
              className='listaDocumentosSolicitados'
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '60%',
              }}
            >
              <Button
                style={{ alignSelf: 'end' }}
                icon='add'
                onClick={addOtrosDocumentos}
              />
              <DataGrid
                showRowLines={true}
                dataSource={inputsDocumentacionGrid}
                scrolling={{ mode: 'standard' }}
                paging={{ enabled: true, pageSize: 10 }}
                showBorders={true}
                columnAutoWidth={true}
                width={'auto'}
              >
                <Column
                  dataField='InputName'
                  caption='Documentos solicitados'
                  cellRender={(props) => (
                    <div>
                      <Button icon='file' type='normal' />
                      {props.data.InputName}
                    </div>
                  )}
                />
                <Column
                  dataType='boolean'
                  dataField='Required'
                  caption='Obligatorio'
                  width={90}
                  cellRender={(props) => (
                    <div>
                      {props.data.Required ? (
                        <div
                          style={{
                            color: 'green',
                            backgroundColor: 'rgb(192, 255, 163)',
                            borderRadius: '10px',
                            fontWeight: 'bold',
                            fontSize: '13px',
                          }}
                        >
                          Obligatorio
                        </div>
                      ) : (
                        <div
                          style={{
                            color: 'black',
                            backgroundColor: 'rgb(243, 244, 246)',
                            borderRadius: '10px',
                            fontWeight: 'bold',
                            fontSize: '13px',
                          }}
                        >
                          Opcional
                        </div>
                      )}
                    </div>
                  )}
                />
                <Column
                  dataField='Comentario'
                  caption='Comentario'
                  cellRender={(props) => <div>{props.data.Comentario}</div>}
                />
                <Column
                  caption='Añadir Documento'
                  cellRender={(props) => (
                    <DocumentCell
                      {...props}
                      rowData={{
                        ...props.data,
                        fileName: fileNames[props.data.id],
                      }}
                      onFileUpload={handleFileUpload}
                    />
                  )}
                />
                <Column
                  caption='Quitar'
                  cellRender={renderButtonQuitar}
                  width={70}
                />
                <Column visible={false} />
              </DataGrid>
            </div>
          </GroupItem>

          <GroupItem>
            {FooterFormSection({
              validos: validos,
              validacion: 'customerData',
            })}
          </GroupItem>
        </GroupItem>
      </Form>
      <Box direction='row' width={'100%'} align='center' crossAlign='center'>
        <Item ratio={0} baseSize={'auto'}>
          <p className='alinearinputs'>
            Página {pagina} de {paginas}{' '}
          </p>
        </Item>
      </Box>
    </form>
  )
}

export default DatosSolicitudSection
