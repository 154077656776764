// import { addCostes, addDocumentos, addTareasPendientes } from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions'
// import { getCostes } from 'src/pages/expedientePage/services/costeService'
// import { getTareasPendientesByExpediente } from 'src/pages/expedientePage/services/tareaService'
import React, { useEffect, useState } from 'react';
import Form, { Tab, TabbedItem } from 'devextreme-react/form';
import CostesTabVehiculo from './tabs/CostesTabVehiculo';
import HistorialTabVehiculo from './tabs/HistorialTabVehiculo';
import DocumentosTabVehiculo from './tabs/DocumentosTabVehiculo';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { addDocumentos } from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions';
import { getDocumentosByExpediente } from 'src/pages/expedientePage/services/documentosService';
import { documentoFirmadoById } from 'src/pages/expedientePage/services/vehiculo/vehiculoService';
import { StateHistorySignedDataModel } from 'src/pages/firmadigital/StateHistorySigned/models/stateHistorySignedDataModel';

export default function TabsVehiculo(props: any) {
  const userState = useSelector((state: RootState) => state.user?.user)
  const [estadoFirma, setEstadoFirma] = useState(
    Array<StateHistorySignedDataModel>,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      dispatch(
        addDocumentos(
          await getDocumentosByExpediente(props.datos.id, userState!),
        ),
      );
      await documentoFirmadoById(userState!, props.datos.id).then(
        (data: any) => {
          setEstadoFirma(data);
        },
      );
    }
    if (props.datos.id !== undefined && props.datos.id  != null ) {
      fetchData();
    }
  }, [props.datos.id])
  return (
    <Form id='tabExpediente'>
      <TabbedItem>
        <Tab title='Costes'>
          <CostesTabVehiculo />
        </Tab>
        <Tab title='Historial'>
          <HistorialTabVehiculo />
        </Tab>
        <Tab title='Documentos'>
          <DocumentosTabVehiculo
            firma={props.estadofirma}
            realizarfirma={props.realizarfirma}
            estadoFirmaDocumentos={estadoFirma}
            texto={props.texto}
            datos={props.datos}
            selectedButton={props.selectedButton}
          />
        </Tab>
      </TabbedItem>
    </Form>
  );
};
