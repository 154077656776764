import axiosInstance from 'src/utils/axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import {
  checkStatusCode,
  customLog,
  logType,
  logout,
  methodsEndPointType,
} from 'src/utils/sharedUitls'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { putSujeto } from '../../../usuariosPage/service/sujetoService'
import {
  CompanyCampoDatoGridModel,
  CompanyCampoDatoModel,
} from 'src/models/company/companyCampoDatoModel'
import { CompanyGridModel, CompanyModel } from 'src/models/company/companyModel'
import { _postCalculator, _putCalculator } from './calculadoraService'
import { postEmail, putEmail } from './emailService'
import { CompanyModuloModel } from 'src/models/company/companyModuloModel'
import { CalculatorTransferModel } from 'src/models/company/calculatorTransferModel'
import { postLinks, putLinks } from './linksService'
import { LinkModel } from 'src/models/company/linkModel'
import { CompanyState } from '../redux/companiesType'
import { ConfiguracionEnvioEmailModel } from 'src/models/company/configuracionEnvioEmailModel'
import { UserModel } from 'src/models/usuario/userModel'
import { isSuperAdmin } from '../../../../utils/allowAuthUtil'

const serviceName = 'CompaniesService'

const getCompanies = async (
  user: UserModel,
): Promise<CompanyCampoDatoModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `companies`
    let companies: CompanyCampoDatoModel = new CompanyCampoDatoModel()

    await axiosInstance
      .get(url)
      .then((response: any) => {
        companies = jsonConvert.deserializeObject(
          response.data,
          CompanyCampoDatoModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getCompanies.name,
          methodsEndPointType.GET,
        )
        showToast(
          'No se han podido obtener las compañias.',
          NotifyType.error,
          5000,
        )
        if (error.request.status !== undefined) {
          checkStatusCode(error.request.status)
        } else {
          logout()
        }
      })
    return companies
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

const getCompaniesGrid = async (
  user?: UserModel,
): Promise<CompanyCampoDatoGridModel | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Companies/Grid`
    let companies: CompanyCampoDatoGridModel = new CompanyCampoDatoGridModel()

    await axiosInstance
      .get(url)
      .then((response: any) => {
        companies = jsonConvert.deserializeObject(
          response.data,
          CompanyCampoDatoGridModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getCompanies.name,
          methodsEndPointType.GET,
        )
        showToast(
          'No se han podido obtener las compañias.',
          NotifyType.error,
          5000,
        )
        if (error.request.status !== undefined) {
          checkStatusCode(error.request.status)
        } else {
          logout()
        }
      })
    return companies
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

const getCompaniesDatos = async () => {
  const data = await getCompaniesGrid()
  if (data !== undefined) {
    return data.Datos
  }
}

async function getCompany(user: UserModel, id: number): Promise<CompanyModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Companies/${id}`
  let company: CompanyModel = new CompanyModel()

  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      company = jsonConvert.deserializeObject(response.data, CompanyModel)
      company.Enabled = company.Enabled ? false : true
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getCompany.name,
        methodsEndPointType.GET,
      )
      showToast('No se ha podido obtener las compañia.', NotifyType.error, 5000)
      if (error.request.status !== undefined) {
        checkStatusCode(error.request.status)
      } else {
        logout()
      }
    })
  return company
}

async function getStructure(
  user: UserModel,
): Promise<CompanyCampoDatoGridModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Companies/Structure`
  let company: CompanyCampoDatoGridModel = new CompanyCampoDatoGridModel()
  const config = {
    headers: { Authorization: `Bearer ${user.Token}` },
  }

  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      company = jsonConvert.deserializeObject(
        response.data,
        CompanyCampoDatoGridModel,
      )
    })
    .catch((error: any) => {
      checkStatusCode(error.request.status)
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructure.name,
        methodsEndPointType.GET,
      )
      return {}
    })
  return company
}

async function getCompanyModulos(
  user: UserModel,
  idCompany: number,
): Promise<CompanyModuloModel[] | undefined> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `CompanyModulo/${idCompany}`
    let companyModulo: CompanyModuloModel[] = []

    await axiosInstance
      .get(url)
      .then((response: any) => {
        companyModulo = jsonConvert.deserializeArray(
          response.data,
          CompanyModuloModel,
        )
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los modulos de la compañia.',
          NotifyType.error,
          5000,
        )
        if (error.request.status !== undefined) {
          checkStatusCode(error.request.status)
        } else {
          logout()
        }
        return []
      })
    return companyModulo
  } catch (err) {
    // Handle Error Here
    console.error(err)
  }
}

async function postCompany(
  user: UserModel,
  company: Partial<CompanyModel> | CompanyModel,
  calculadoraState:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
  linkState: Partial<LinkModel[]> | LinkModel[] | undefined,
  companyState: CompanyState | undefined,
  emailState:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined,
): Promise<number | boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `companies`
    let idcompany = 0
    company.Enabled = company.Enabled ? false : true

    await axiosInstance.post(url, company).then(async (response: any) => {
      idcompany = response.data.Id
      if (linkState?.length === 0) {
        linkState.push({
          Id: 0,
          IdCompany: company.Id!,
          IdTipoEnlace: 2,
          EnlaceValue: '',
          CodigoTipoEnlace: '',
        })
      }

      checkStatusCode(response.status)
      customLog(
        `compañia creada ${response.data.Id}`,
        logType.INFO,
        serviceName,
        putSujeto.name,
        methodsEndPointType.POST,
      )

      await _postCalculator(user, calculadoraState, response.data.Id)
      await postLinks(user, linkState, response.data.Id)
      await postEmail(user, emailState, response.data.Id)
      if (isSuperAdmin(user)) {
        await postCompanyModulo(
          user,
          companyState?.companyModulos,
          response.data.Id,
        )
      }
      showToast('Compañia creada', NotifyType.success, 5000)
    })
    return idcompany
  } catch (error: any) {
    showToast(error.response.data, NotifyType.error, 5000)
    customLog(
      `Error al crear Compañia => ${error.response.data}`,
      logType.ERROR,
      serviceName,
      postCompany.name,
      methodsEndPointType.POST,
    )
    if (error.request.status !== undefined) {
      checkStatusCode(error.request.status)
    } else {
      logout()
    }
    return false
  }
}

async function postCompanyModulo(
  user: UserModel,
  companyModulos: CompanyModuloModel[] | undefined,
  idCompany: number,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `CompanyModulo`

    if (companyModulos?.length! > 0) {
      companyModulos?.map((x) => {
        x.IdCompany = idCompany
      })
    }

    await axiosInstance
      .post(url, companyModulos)
      .then(async (response: any) => {
        checkStatusCode(response.status)
        customLog(
          `compañia creada ${response.data.Id}`,
          logType.INFO,
          serviceName,
          postCompanyModulo.name,
          methodsEndPointType.POST,
        )
      })
    return true
  } catch (error: any) {
    // showToast(error.response.data, NotifyType.error, 5000)
    customLog(
      `Error al crear modulos y sus gestorias => ${error.response.data}`,
      logType.ERROR,
      serviceName,
      postCompany.name,
      methodsEndPointType.POST,
    )
    if (error.request.status !== undefined) {
      checkStatusCode(error.request.status)
    } else {
      logout()
    }
    return false
  }
}

async function putCompany(
  user: UserModel,
  company: Partial<CompanyModel> | CompanyModel,
  calculadoraState:
    | Partial<CalculatorTransferModel>
    | CalculatorTransferModel
    | undefined,
  linkState: Partial<LinkModel[]> | LinkModel[] | undefined,
  companyState: CompanyState | undefined,
  emailState:
    | Partial<ConfiguracionEnvioEmailModel>
    | ConfiguracionEnvioEmailModel
    | undefined,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Companies`
    company.Enabled = !company.Enabled

    await axiosInstance
      .put(url, company)
      .then(async (response: any) => {
        checkStatusCode(response.status)
        if (calculadoraState?.Id !== 0) {
          await _putCalculator(user, calculadoraState, company!.Id!)
        } else {
          await _postCalculator(user, calculadoraState, company!.Id!)
        }
        if (emailState?.Id !== 0 && emailState?.Id !== undefined) {
          await putEmail(user, emailState, company!.Id!)
        } else {
          await postEmail(user, emailState, company!.Id!)
        }

        if (isSuperAdmin(user)) {
          await postCompanyModulo(
            user,
            companyState?.companyModulos !== undefined
              ? companyState.companyModulos
              : [],
            company!.Id!,
          )
        }

        await putLinks(user, linkState!, company!.Id!)

        customLog(
          `Compañia modificada ${company.Id}`,
          logType.INFO,
          serviceName,
          putCompany.name,
          methodsEndPointType.PUT,
        )

        showToast('Compañia editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar Compañia => ${error.message}`,
          logType.ERROR,
          serviceName,
          putCompany.name,
          methodsEndPointType.PUT,
        )
        if (error.request.status !== undefined) {
          checkStatusCode(error.request.status)
        } else {
          logout()
        }
      })
    return true
  } catch (error) {
    return false
  }
}

async function deleteCompany(user: UserModel, idCompany: number) {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `companies/${idCompany}`

    await axiosInstance.delete(url).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `compañia elminada`,
        logType.INFO,
        serviceName,
        putSujeto.name,
        methodsEndPointType.POST,
      )
      showToast('Compañia elminada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    showToast(error, NotifyType.error, 5000)
    customLog(
      `Error al crear Compañia`,
      logType.ERROR,
      serviceName,
      postCompany.name,
      methodsEndPointType.POST,
    )
    return false
  }
}

export {
  getCompanies,
  getCompaniesGrid,
  getCompany,
  getStructure,
  getCompanyModulos,
  postCompany,
  postCompanyModulo,
  putCompany,
  deleteCompany,
  getCompaniesDatos,
}
