import { IdocCarConfigModel } from '../models/idocCarConfigModel'

export interface IdocCarConfigState {
  idocCarConfig: Partial<IdocCarConfigModel> | IdocCarConfigModel | undefined
  idocCarConfigAll: IdocCarConfigModel[] | undefined
}
/* export interface IdocCarConfigState {
  sdocCarConfig: SolicitudCampoDatoModel | undefined
} */

export const ADD_IDOCCAR_CONFIG_ALL = 'ADD_IDOCCAR_CONFIG_ALL'
export const ADD_IDOCCAR_CONFIG = 'ADD_IDOCCAR_CONFIG'
export const CLEAR_IDOCCAR_CONFIG = 'CLEAR_IDOCCAR_CONFIG'

interface AddIdocCarConfigRequest {
  type: typeof ADD_IDOCCAR_CONFIG
  payload: Partial<IdocCarConfigModel> | IdocCarConfigModel | undefined
}
interface AddIdocCarConfigRequestAll {
  type: typeof ADD_IDOCCAR_CONFIG_ALL
  payload: IdocCarConfigModel[] | undefined
}
interface ClearIdocCarConfigRequest {
  type: typeof CLEAR_IDOCCAR_CONFIG
  payload: IdocCarConfigModel | undefined
}

export type IdocCarConfigActionsTypes =
  | ClearIdocCarConfigRequest
  | AddIdocCarConfigRequest
  | AddIdocCarConfigRequestAll
