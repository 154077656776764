import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil'
import { PermissionsRolePage } from './pages/PermissionsRolePage'
import { useNavigate } from 'react-router-dom'

function PermissionsRoleIndex() {
  const userState = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()

  return (
    <>
      {!userState!.isLoggedIn && navigate('/Login')}
      {(isSuperAdmin(userState!.user!) || isAdmin(userState!.user!)) && (
        <PermissionsRolePage />
      )}
    </>
  )
}

export { PermissionsRoleIndex }
