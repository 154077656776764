import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject('DossierStatus')
export class DossierStatus {
  @JsonProperty('Id', Number, 0)
  Id: number = 0
  @JsonProperty('Name', String, '')
  Name: string = ''
  @JsonProperty('Editable', Boolean)
  Editable: boolean = false
  @JsonProperty('Uploadable', Boolean)
  Uploadable: boolean = false
  @JsonProperty('IdElGestor', Number, 0)
  IdElGestor: number = 0
  @JsonProperty('IdEstadoElGestor', String, '')
  IdEstadoElGestor: string = ''
  @JsonProperty('IDocCarStatus', Number, 0)
  IDocCarStatus: number = 0
}

@JsonObject('DossierStatusElGestor')
export class DossierStatusElGestor {
  @JsonProperty('Id', Number, 0)
  Id: number = 0
  @JsonProperty('IdEstadoElGestor', String, 0)
  IdEstadoElGestor: string = ''
  @JsonProperty('Description', String)
  Descripcion: string = ''
  @JsonProperty('DossierStatus', DossierStatus)
  DossierStatus: DossierStatus = new DossierStatus()
  @JsonProperty('Color', String, '')
  Color: string = ''
}

@JsonObject('DossierStatusRelation')
export class DossierStatusRelation {
  @JsonProperty('IdDossierStatus', Number, 0)
  IdDossierStatus: number = 0
  @JsonProperty('IdStatusElGestor', Number, 0)
  IdStatusElGestor: number = 0
}
