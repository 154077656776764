import { ListaArchivosModel } from '../../../models/ListaArchivosModel'
import {
  ACTUALIZAR_ARCHIVOS,
  ACTUALIZAR_FOCUS_INPUT,
  ADD_COMPRADOR_REPRESENTANTE,
  ADD_PAGINA,
  ADD_PAGINAS,
  ADD_SERVICIO_DESTINO,
  ADD_TIPO_VEHICULO,
  ADD_TIPO_VIAS,
  ADD_VENDEDOR_REPRESENTANTE,
  LISTA_ARCHIVOS,
  formDatosCamposUtilsActions,
} from '../types/formularioCamposUtilType'

export const addPagina = (pagina: number): formDatosCamposUtilsActions => ({
  type: ADD_PAGINA,
  payload: pagina,
})

export const addPaginas = (paginas: number): formDatosCamposUtilsActions => ({
  type: ADD_PAGINAS,
  payload: paginas,
})

export const addCompradorRepresentante = (
  compradorrepresentante: boolean,
): formDatosCamposUtilsActions => ({
  type: ADD_COMPRADOR_REPRESENTANTE,
  payload: compradorrepresentante,
})

export const addVendedorRepresentante = (
  vendedorrepresentante: boolean,
): formDatosCamposUtilsActions => ({
  type: ADD_VENDEDOR_REPRESENTANTE,
  payload: vendedorrepresentante,
})

export const addTipoVias = (
  tipovias: Array<Object>,
): formDatosCamposUtilsActions => ({
  type: ADD_TIPO_VIAS,
  payload: tipovias,
})

export const addTipoVehiculo = (
  tipovehiculo: Array<Object>,
): formDatosCamposUtilsActions => ({
  type: ADD_TIPO_VEHICULO,
  payload: tipovehiculo,
})

export const addServicioDestino = (
  serviciodestino: Array<Object>,
): formDatosCamposUtilsActions => ({
  type: ADD_SERVICIO_DESTINO,
  payload: serviciodestino,
})

export const addActualizarListaArchivos = (
  actualizarlista: Array<ListaArchivosModel>,
): formDatosCamposUtilsActions => ({
  type: LISTA_ARCHIVOS,
  payload: actualizarlista,
})

export const actualizarFocusInput = (
  actualizarfocusinput: string,
): formDatosCamposUtilsActions => ({
  type: ACTUALIZAR_FOCUS_INPUT,
  payload: actualizarfocusinput,
})

export const actualizarArchivos = (
  actualizararchivos: Array<File>,
): formDatosCamposUtilsActions => ({
  type: ACTUALIZAR_ARCHIVOS,
  payload: actualizararchivos,
})
