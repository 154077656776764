import { JsonObject, JsonProperty } from 'json2typescript'
import { ColumnModelCore } from 'src/models/columnModel'
import { DateConverter } from 'src/utils/dateUtils'

@JsonObject('CatastroModel')
class CatastroModel {
  @JsonProperty('Id', Number)
  Id: number = 0

  @JsonProperty('FechaPresentacion', String)
  FechaPresentacion: Date | String = ''

  @JsonProperty('NumeroPresentacion', String)
  NumeroPresentacion: string = ''

  @JsonProperty('NumFinca', String)
  NumFinca: string = ''

  @JsonProperty('RefCatastral', String)
  RefCatastral: string = ''

  @JsonProperty('Pagado', Boolean)
  Pagado: boolean = false
}

@JsonObject('CatastroCampoDatoModel')
export class CatastroCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = []

  @JsonProperty('Datos', [CatastroModel])
  Datos: CatastroModel[] = []
}
