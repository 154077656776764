import React, { MutableRefObject, useRef, useState } from 'react';
import { Button, CheckBox, DataGrid } from 'devextreme-react';
import { Column, StateStoring } from 'devextreme-react/data-grid';
import { useQuery } from 'react-query';
import { checkScreenSize } from 'src/utils/sharedUitls';
import { UserPermissionsModel } from '../models/userPermissionsModel';
import { _getPermissionsUserById, _getUserPermissions } from '../services/permissionsService';
import { FormPermissionsUsersSection } from 'src/pages/permissions/permissionsSectionsModules/FormPermissionsUsersSection';
import { useDispatch } from 'react-redux';
import { addShowLoader } from 'src/redux/actions/configActions';

function PermissionsByPageUsers() {
  const permissionsByUsersRef: MutableRefObject<any> = useRef();
  const [listUsersPermissions, setListUsersPermissions] = useState(
    new Array<UserPermissionsModel>(),
  )
  const [idUser, setIdUser] = useState(0);
  const [userModules, setUserModules] = useState(new UserPermissionsModel());

  useQuery(
    ['getRolsPermisosByUsers', idUser === undefined ? 0 : idUser],
    _getPermissionsUserById,
    {
      onSuccess: (data: UserPermissionsModel | null) => {
        if (idUser !== undefined) {
          if (data  != null ) {
            setUserModules(data);
            setOpenPopup(true);
          };
        };
        dispatch(addShowLoader(false));
      },
      onError: () => {
        dispatch(addShowLoader(false));
      },
      refetchOnWindowFocus: false,
    },
  );
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const onHanleOnToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: () => {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        name: 'refrescar',
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async () => {
            permissionsByUsersRef.current.instance.refresh();
          },
        },
      },
    );
  };

  useQuery(['getRolsPermisosByUser'], _getUserPermissions, {
    onSuccess: (data: Array<UserPermissionsModel> | []) => {
      dispatch(addShowLoader(false));
      setListUsersPermissions(data);
    },
    onError: () => {
      dispatch(addShowLoader(false));
    },
    refetchOnWindowFocus: false,
  });

  function openEditPermisosUsuario(id: number) {
    dispatch(addShowLoader(true));
    setTimeout(() => {
      dispatch(addShowLoader(false));
    }, 1000);
    setIdUser(id);
  };

  return (
    <div id='container-body'>
      <h2>Permisos por usuario</h2>
      <DataGrid
        id='usuariosPermisosDataGrid'
        keyExpr='Id'
        ref={permissionsByUsersRef}
        dataSource={listUsersPermissions}
        style={{ width: '100%', minHeight: '250px' }}
        height='calc(100vh - 8rem - 46px)'
        allowColumnReordering
        allowColumnResizing
        cacheEnabled
        columnAutoWidth
        focusedRowEnabled
        remoteOperations
        repaintChangesOnly
        rowAlternationEnabled
        showBorders
        showRowLines
        columnChooser={{ enabled: true }}
        columnHidingEnabled={!checkScreenSize()}
        filterPanel={{ visible: true }}
        filterRow={{ visible: true }}
        headerFilter={{ visible: false }}
        loadPanel={{ enabled: true }}
        paging={{ enabled: false }}
        sorting={{ mode: 'multiple', showSortIndexes: false }}
        searchPanel={{
          visible: true,
          width: checkScreenSize() ? 240 : 120,
        }}
        scrolling={{
          mode: 'standard',
          scrollByContent: true,
          scrollByThumb: true,
          showScrollbar: 'always',
        }}
        onToolbarPreparing={onHanleOnToolbarPreparing}
      >
        <StateStoring
          enabled={true}
          type={'localStorage'}
          storageKey={'permisosPorUsuarioList'}
        />

        <Column
          type='buttons'
          width={50}
          cellRender={(row: any) => (
            <Button
              width='32px'
              icon='edit'
              hint='Editar'
              onClick={() => openEditPermisosUsuario(row.data.Id)}
            />
          )}
        />

        <Column
          caption={'Nombre de usuario'}
          dataField={'UserName'}
          alignment='left'
        />
        <Column caption={'Nombre'} dataField={'Name'} alignment='left' />
        <Column
          caption={'Empresa'}
          dataField={'CompanyName'}
          alignment='left'
        />
        <Column caption={'Rol'} dataField={'Role'} alignment='left' />
        <Column
          caption={'Permisos personalizados'}
          cellRender={(row: any) => (
            <CheckBox
              readOnly={true}
              defaultValue={row.data.HasPermissionsSet}
            />
          )}
        />
      </DataGrid>
      <div>
        {openPopup && (
          <FormPermissionsUsersSection
            close={setOpenPopup}
            userPermissions={userModules}
            setIdSelected={setIdUser}
            idUser={idUser}
          ></FormPermissionsUsersSection>
        )}
      </div>
    </div>
  );
};

export { PermissionsByPageUsers };
