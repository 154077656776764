import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import axiosInstance from 'src/utils/axios'
import {
  logType,
  methodsEndPointType,
  customLog,
  checkStatusCode,
  isNotEmpty,
} from 'src/utils/sharedUitls'
import { UserModel } from 'src/models/usuario/userModel'
import { NotifyType, showToast } from '../../../../utils/sharedUitls'
import { authHeader } from 'src/services/sharedServices'
import { SolicitudListCampoDatoModel } from '../models/solicitudCampoDatoModel'
import { SolicitudListModel } from '../models/solicitudListModel'
import { DestinoTipoVehiculo } from '../models/tipodestinovehiculointerface'
import { TipoCalle } from '../models/tipocalleinterface'
import { DocumentModel } from './../models/documentsModel'
import { ListaArchivosModel } from '../models/ListaArchivosModel'
import { deleteFakeDates } from '../../../../utils/dateUtils'

const serviceName = 'authService'
const baseURL = process.env.REACT_APP_API_URL_CORE

// Método para obtener datos de vehículos con paginación
export const getSolicitudesList = async (
  user: UserModel,
  loadOptions: any,
  options: string[],
) => {
  // Objeto que almacenará los datos de vehículos
  let vehiculos: any = {}

  // URL de la API para obtener expedientes de vehículos
  const url = `${baseURL}/ExternalDossier/ForGrid`

  try {
    let params = '?'
    options.forEach((key) => {
      if (key in loadOptions && isNotEmpty(loadOptions[key])) {
        params += `${key}=${JSON.stringify(loadOptions[key])}&`
      }
    })
    params = params.slice(0, -1)

    // Verifica si hay un filtro en loadOptions
    if (loadOptions.filter !== undefined) {
      // Realiza la primera llamada a la API con el filtro
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data
        })
        .catch((error) => console.log('error', error))

      // Estructura los datos obtenidos
      vehiculos = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }

      // Retorna los datos de vehículos
      return vehiculos
    } else {
      // Si no hay filtro, realiza la llamada a la API sin filtro
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data
        })
        .catch((error) => console.log('error', error))

      // Estructura los datos obtenidos
      vehiculos = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }

      // Retorna los datos de vehículos
      return vehiculos
    }
  } catch (e) {
    // Manejo de errores, si es necesario
  }

  // Retorna los datos de vehículos (incluso si hubo un error)
  return vehiculos
}

//añadimos la solicitud
export async function postSolicitud(
  user: UserModel,
  newSolicitud: SolicitudListModel,
  editar: boolean,
): Promise<Array<number>> {
  try {
    let datos: number[] = []
    await axiosInstance
      .post('ExternalDossier', newSolicitud)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Solicitud creada ${response.data.ProcessingAgencyDossierId}`,
          logType.INFO,
          serviceName,
          postSolicitud.name,
          methodsEndPointType.POST,
        )
        datos.push(response.data.ExpedienteId)
        datos.push(response.data.VehiculoId)
        showToast(
          !editar ? 'Solicitud creada' : 'Solicitud modificada',
          NotifyType.success,
          5000,
        )
      })
    return datos
  } catch (error: any) {
    showToast(`${error.ErrorMessage}`, NotifyType.error, 5000)
    return []
  }
}

//Añadimos la documentacion de la solicitud

export async function postDocument(
  user: UserModel,
  documentos: DocumentModel[],
  id: string | number,
): Promise<Boolean> {
  try {
    await axiosInstance
      .put(`ExternalDossier/VariosDocumentos?dossierNumber=${id}`, documentos, {
        headers: authHeader(user),
      })
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `Documentos añadidos`,
          logType.INFO,
          serviceName,
          postSolicitud.name,
          methodsEndPointType.POST,
        )
        showToast('Documentos añadidos', NotifyType.success, 5000)
      })
    return true
  } catch (error: any) {
    customLog(
      `Error al añadir la documentacion => ${error.message}`,
      logType.ERROR,
      serviceName,
      postDocument.name,
      methodsEndPointType.POST,
    )

    showToast('Documento no añadido', NotifyType.error, 5000)

    return false
  }
}

export async function getSujeto(
  user: UserModel,
  id: number,
): Promise<SolicitudListModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Sujeto/${id}`

  let sujeto: SolicitudListModel = new SolicitudListModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      sujeto = jsonConvert.deserializeObject(
        response.data.Datos[0],
        SolicitudListModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getSujeto.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return sujeto
}
export async function postSujeto(
  user: UserModel,
  sujeto: SolicitudListModel,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    let url = `ExternalDossier/ForGrid`

    await axiosInstance.post(url, sujeto).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `sujeto creado ${response.data.Id}`,
        logType.INFO,
        serviceName,
        putSujeto.name,
        methodsEndPointType.POST,
      )
      showToast('Compañia creada', NotifyType.success, 5000)
    })
    return true
  } catch (error: any) {
    customLog(
      `Error al crear Sujeto => ${error.message}`,
      logType.ERROR,
      serviceName,
      postSujeto.name,
      methodsEndPointType.POST,
    )
    return false
  }
}
export async function putSujeto(
  user: UserModel,
  sujeto: SolicitudListModel,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Sujeto`

    await axiosInstance
      .put(url, sujeto)
      .then((response: any) => {
        checkStatusCode(response.status)
        customLog(
          `sujeto modificado ${sujeto.IdExpediente}`,
          logType.INFO,
          serviceName,
          putSujeto.name,
          methodsEndPointType.PUT,
        )
        showToast('Compañia editada', NotifyType.success, 5000)
      })
      .catch((error: any) => {
        customLog(
          `Error al editar Sujeto => ${error.message}`,
          logType.ERROR,
          serviceName,
          putSujeto.name,
          methodsEndPointType.PUT,
        )
      })
    return true
  } catch (error) {
    return false
  }
}
export async function getStructureSolicitudes(): Promise<SolicitudListCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  jsonConvert.ignorePrimitiveChecks = true
  let url = `ExternalDossier/GetStructure`

  let solicitudesStructure: SolicitudListCampoDatoModel =
    new SolicitudListCampoDatoModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      solicitudesStructure = jsonConvert.deserializeObject(
        response.data,
        SolicitudListCampoDatoModel,
      )
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        getStructureSolicitudes.name,
        methodsEndPointType.GET,
      )
      return {}
    })

  return solicitudesStructure
}

export function getMapperSujeto(sujetoData: any): SolicitudListModel {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let sujeto: SolicitudListModel = jsonConvert.deserializeObject(
    sujetoData,
    SolicitudListModel,
  )
  return sujeto
}

/**
 *
 * Funcion que nos devuelve el array con todos
 * los datos de los tipos de vehiculos
 */

export async function getTipoVehiculo(
  user: UserModel,
): Promise<Array<DestinoTipoVehiculo>> {
  var tiposvehiculos: Array<DestinoTipoVehiculo> = []

  await axiosInstance
    .get('TipoVehiculo')
    .then((response: any) => {
      checkStatusCode(response.status)
      response.data.forEach((element: any) => {
        tiposvehiculos.push(element)
      })
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los tipos de vehiculos.',
        NotifyType.error,
        5000,
      )
    })
  return tiposvehiculos
}

/**
 * Funcion que nos devuelve todos los datos
 * de los destinos de tipo de vehiculos
 */

export async function getDestinoTipoVehiculo(
  user: UserModel,
): Promise<Array<DestinoTipoVehiculo>> {
  var tiposvehiculos: Array<DestinoTipoVehiculo> = []

  await axiosInstance
    .get('ServicioDestinoVehiculo')
    .then((response: any) => {
      checkStatusCode(response.status)
      response.data.forEach((element: any) => {
        tiposvehiculos.push(element)
      })
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los servicios de destino de vehiculos.',
        NotifyType.error,
        5000,
      )
    })
  return tiposvehiculos
}

//Funcion que nos devuelve los tipos de calle
export async function getTipoCalle(user: UserModel): Promise<Array<TipoCalle>> {
  const tipocalles: Array<TipoCalle> = []

  await axiosInstance
    .get('TipoVia')
    .then((response: any) => {
      response.data.forEach((element: any) => {
        tipocalles.push(element)
      })
    })
    .catch((error: any) => {
      showToast(
        'No se han podido obtener los tipos de vias.',
        NotifyType.error,
        5000,
      )
    })

  return tipocalles
}

export async function getSolicitud(
  user: UserModel,
  idexpediente: number,
): Promise<SolicitudListModel> {
  try {
    const solicitud = await axiosInstance
      .get(`ExternalDossier/${idexpediente}`)
      .then((response: any) => {
        checkStatusCode(response.status)
        return response.data
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener la solicitud.',
          NotifyType.error,
          5000,
        )
      })

    return solicitud
  } catch (error) {
    return new SolicitudListModel()
  }
}

export async function getDocumento(
  user: UserModel,
  idexpediente: number,
): Promise<Array<ListaArchivosModel> | null> {
  const documentosfiltrados = new Array<ListaArchivosModel>()

  try {
    const documento = await axiosInstance
      .get(`Documento/getByExpediente/${idexpediente}`, {
        headers: authHeader(user),
      })
      .then((response: any) => {
        checkStatusCode(response.status)
        JSON.parse(JSON.stringify(response.data.Datos)).map((dato: any) => {
          documentosfiltrados.push({
            Id: dato.Id,
            Nombre: dato.NombreArchivoOriginal,
            Subido: true,
          })
        })
      })
      .catch((error: any) => {
        showToast(
          'No se han podido obtener los documentos.',
          NotifyType.error,
          5000,
        )
      })

    return documentosfiltrados
  } catch (error) {
    return null
  }
}

export async function deleteDocumento(id: Number) {
  await axiosInstance
    .delete(`Documento/${id}`)
    .then((response: any) => {
      showToast('Documento Borrado', NotifyType.success, 5000)
    })
    .catch((error: any) => {
      showToast('No se ha podido borrar el documento.', NotifyType.error, 5000)
    })
}

export async function editSolicitud(newSolicitud: SolicitudListModel) {
  try {
    let response = await axiosInstance.post('ExternalDossier', newSolicitud)
    checkStatusCode(response.status)
    showToast('Solicitud modificada', NotifyType.success, 5000)
  } catch (error: any) {
    showToast(`${error.ErrorMessage}`, NotifyType.error, 5000)
  }
}
