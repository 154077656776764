import React, { useCallback, useEffect, useRef } from 'react'
import { Button, DataGrid } from 'devextreme-react'
import { Column, StateStoring } from 'devextreme-react/data-grid'
import { confirm } from 'devextreme/ui/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addShowLoader } from 'src/redux/actions/configActions'
import { addProfiles } from 'src/pages/adminPages/profilePages/redux/profileActions'
import { addSucursales } from 'src/redux/sucursal/sucursalActions'
import { addSujetos } from '../redux/sujetoActions'
import {
  addRoles,
  addUsuario,
  addUsuariosList,
  showForm,
} from 'src/redux/usuariosPage/usuariosActions'
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil'
import { useScreenSize } from 'src/utils/media-query'
import {
  checkScreenSize,
  getColumnAlignment,
  getColumnType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import { _getProfiles } from 'src/pages/adminPages/profilePages/service/profileService'
import { getRoles } from 'src/services/roleService'
import { getSujetos } from 'src/pages/usuariosPage/service/sujetoService'
import {
  deleteUsuario,
  getSucursales,
  getUsuario,
  getUsuariosList,
} from '../../../services/usuarioPage/usuarioServices'
import { UsuarioModel } from 'src/models/usuariosPage/usuarioModel'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'

export default function UsuariosList() {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const sujetoState = useSelector((state: RootState) => state.sujeto)
  const { Campos, Datos } = useSelector(
    (state: RootState) => state.usuario.usuariosList,
  )
  const dataGridRef = useRef<any>()
  const { isLarge } = useScreenSize()

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(addSujetos(await getSujetos(userState!.user!)))
        dispatch(addShowLoader(false))
        dispatch(addSucursales(await getSucursales(userState!.user!)))
        dispatch(addRoles(await getRoles(userState!.user!)))
        dispatch(addProfiles(await _getProfiles(userState!.user!)))
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
  }, [])

  const onHanleOnToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        name: 'crear',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Crear usuario',
          icon: 'add',
          onClick: handleOpenCreatePopup,
        },
      },
      {
        name: 'filtro',
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Filtros personalizados',
          icon: 'filter',
          onClick: () => {
            var elementFilterPanel = document.getElementsByClassName(
              'dx-datagrid-filter-panel-text',
            )[0] as HTMLElement
            elementFilterPanel.click()
          },
        },
      },
      {
        name: 'refrescar',
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Refrescar contenido',
          onClick: async () => {
            dispatch(addUsuariosList(await getUsuariosList(userState?.user!)))
            dataGridRef.current.instance.refresh()
          },
        },
      },
    )
  }

  const handleOpenCreatePopup = async () => {
    if (isAdmin(userState!.user!) || isSuperAdmin(userState!.user!)) {
      dispatch(
        addUsuario({
          ...new UsuarioModel(),
          IdSujeto: -1,
          SujetoIdentification: '',
        }),
      )
      dispatch(showForm(true))
    }
  }

  const handleOnclickEdit = async (row: any) => {
    dispatch(addUsuario(await getUsuario(userState!.user!, row.key)))
    dispatch(showForm(true))
  }

  const handleOnclickDelete = async (row: any) => {
    const confirmDialog = confirm(
      '<i>¿Está seguro que desea eliminar este usuario?</i>',
      'Confirma los cambios',
    )
    confirmDialog.then(async (dialogResult) => {
      if (dialogResult) {
        if (userState?.user!.UserName !== row.data.NombreUsuario) {
          dispatch(addShowLoader(true))
          await deleteUsuario(userState?.user!, row.key).then(
            async (confirm) => {
              if (confirm) {
                dispatch(
                  addUsuariosList(await getUsuariosList(userState?.user!)),
                )
              }
            },
          )
          dispatch(addShowLoader(false))
        } else {
          showToast(
            'No se pueden eliminar usuarios administradores, contacte con la empresa gestora de software',
            NotifyType.info,
            5000,
          )
        }
      }
    })
  }

  function exportExcell(e: any) {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Main sheet')

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Usuarios.xlsx',
        )
      })
    })
    e.cancel = true
  }

  const loadState = useCallback(() => {
    if (Datos.length > 0) {
      return JSON.parse(localStorage.getItem('usuariosList')!)
    }
  }, [Datos])

  const saveState = useCallback((state: { columns: string | any[] }) => {
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null
      }
    }
    localStorage.setItem('usuariosList', JSON.stringify(state))
  }, [])

  return (
    <DataGrid
      id='usuariosDataGrid'
      keyExpr='Id'
      ref={dataGridRef}
      dataSource={Datos}
      style={{ width: '100%', minHeight: '250px' }}
      height='calc(100vh - 8rem - 46px)'
      allowColumnReordering
      allowColumnResizing
      cacheEnabled
      columnAutoWidth
      focusedRowEnabled
      remoteOperations
      repaintChangesOnly
      rowAlternationEnabled
      showBorders
      showRowLines
      columnChooser={{ enabled: true }}
      columnHidingEnabled={!checkScreenSize()}
      filterPanel={{ visible: true }}
      filterRow={{ visible: true }}
      headerFilter={{ visible: false }}
      loadPanel={{ enabled: true }}
      paging={{ enabled: false }}
      sorting={{ mode: 'multiple', showSortIndexes: false }}
      export={{
        allowExportSelectedData: true,
        enabled: true,
      }}
      searchPanel={{
        visible: true,
        width: checkScreenSize() ? 240 : 120,
      }}
      selection={{
        mode: 'multiple',
        showCheckBoxesMode: isLarge ? 'always' : 'none',
      }}
      scrolling={{
        mode: 'standard',
        scrollByContent: true,
        scrollByThumb: true,
        showScrollbar: 'always',
      }}
      onRowDblClick={(event: any) => {
        event.event.preventDefault()
        event.event.stopPropagation()
        handleOnclickEdit(event)
      }}
      onToolbarPreparing={onHanleOnToolbarPreparing}
      onKeyDown={({ event }: any) => {
        if (event.key === 'Enter') {
          const grid = dataGridRef.current!.instance
          const focusedRowKey = grid.state().focusedRowKey
          const rowIndex = grid.getRowIndexByKey(focusedRowKey)
          const rows = grid.getVisibleRows()
          const row = rows[rowIndex]
          handleOnclickEdit(row)
        } else if (event.key === 'Delete') {
          const grid = dataGridRef.current!.instance
          const focusedRowKey = grid.state().focusedRowKey
          const rowIndex = grid.getRowIndexByKey(focusedRowKey)
          const rows = grid.getVisibleRows()
          const row = rows[rowIndex]
          handleOnclickDelete(row)
        }
      }}
      onExporting={exportExcell}
    >
      <StateStoring
        enabled={true}
        type='custom'
        storageKey={'usuariosListState'}
        customLoad={loadState}
        customSave={saveState}
      />
      <Column
        type='buttons'
        width={50}
        cellRender={(row: any) => (
          <Button
            width='32px'
            icon='edit'
            hint='Editar'
            onClick={() => handleOnclickEdit(row)}
          />
        )}
      />
      <Column
        type='buttons'
        width={50}
        cellRender={(row: any) => (
          <Button
            width='32px'
            icon='trash'
            hint='Elimimar'
            onClick={() => handleOnclickDelete(row)}
          />
        )}
      />
      {Campos.map((value: any) => (
        <Column
          key={value.Nombre}
          dataField={value.Nombre}
          caption={value.Caption}
          visible={value.Visible}
          type={value.Tipo}
          minWidth={value.Size}
          allowFiltering={true}
          allowHeaderFiltering={true}
          allowSorting={true}
          alignment={getColumnAlignment(value.Tipo)}
          format={getColumnType(value.Tipo, value.Format)}
        />
      ))}
    </DataGrid>
  )
}
