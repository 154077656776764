import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from 'src/utils/dateUtils'

//Campos que seran mostrados en la tabla con la lista de solicitudes
@JsonObject('TableSolicitudListModelList')
export class TableSolicitudListModelList {
  @JsonProperty('Id', Number, 0)
  Id: string = ''
  @JsonProperty('IdDossierStatus', Number, 0)
  IdDossierStatus: string = ''
  @JsonProperty('TipoExpediente', String, '')
  TipoExpediente: string = ''
  @JsonProperty('TipoAsunto', String, '')
  TipoAsunto: string = ''
  @JsonProperty('Editable', Boolean, false)
  Editable: boolean = false
  @JsonProperty('SerieExpediente', String, '')
  SerieExpediente: string = ''
  @JsonProperty('NumeroExpediente', Number, 0)
  NumeroExpediente: string = ''
  @JsonProperty('Descripcion', String, 0)
  Descripcion: string = ''
  @JsonProperty('Estado', String, 0)
  Estado: string = ''
  @JsonProperty('FechaInicio', DateConverter)
  FechaInicio: undefined
  @JsonProperty('FechaFinalPrevista', DateConverter)
  FechaFinalPrevista: undefined
  @JsonProperty('FechaFinalReal', DateConverter)
  FechaFinalReal: undefined
  @JsonProperty('CodigoCliente', String)
  CodigoCliente: string = ''
  @JsonProperty('NombreCliente', String)
  NombreCliente: string = ''
  @JsonProperty('Colaborador', String)
  Colaborador: string = ''
  @JsonProperty('NombreColaborador', String)
  NombreColaborador: string = ''
  @JsonProperty('Transmitente', String)
  Transmitente: string = ''
  @JsonProperty('NombreTransmitente', String)
  NombreTransmitente: string = ''
  @JsonProperty('Referencia', String)
  Referencia: string = ''
  @JsonProperty('Comentario', String)
  Comentario: string = ''
}
