import { ListaArchivosModel } from '../../../models/ListaArchivosModel'
import {
  ACTUALIZAR_ARCHIVOS,
  ACTUALIZAR_COMPRADOR,
  ACTUALIZAR_DATOS_SOLICITUD,
  ACTUALIZAR_DATOS_VEHICULO,
  ACTUALIZAR_FOCUS_INPUT,
  ADD_COMPRADOR_REPRESENTANTE,
  ADD_PAGINA,
  ADD_PAGINAS,
  ADD_SERVICIO_DESTINO,
  ADD_TIPO_VEHICULO,
  ADD_TIPO_VIAS,
  ADD_VENDEDOR_REPRESENTANTE,
  FormDatosCamposUtilsState,
  LISTA_ARCHIVOS,
  formDatosCamposUtilsActions,
} from '../types/formularioCamposUtilType'

const initialState: FormDatosCamposUtilsState = {
  pagina: 1,
  paginas: undefined,
  compradorrepresentante: undefined,
  vendedorrepresentante: undefined,
  tipovias: undefined,
  tipovehiculo: undefined,
  serviciodestino: undefined,
  actualizardatossolicitud: false,
  actualizarcomprador: false,
  actualizarvendedor: false,
  actualizardatosvehiculo: false,
  actualizarfocusinput: undefined,
  listaarchivos: new Array<ListaArchivosModel>(),
  archivos: [],
}

export const formDatosCamposUtilsReducer = (
  state = initialState,
  action: formDatosCamposUtilsActions,
): FormDatosCamposUtilsState => {
  switch (action.type) {
    case ADD_PAGINA:
      return { ...state, pagina: action.payload }

    case ADD_PAGINAS:
      return { ...state, paginas: action.payload }

    case ADD_COMPRADOR_REPRESENTANTE:
      return { ...state, compradorrepresentante: action.payload }

    case ADD_VENDEDOR_REPRESENTANTE:
      return { ...state, vendedorrepresentante: action.payload }

    case ADD_TIPO_VIAS:
      return { ...state, tipovias: action.payload }

    case ADD_TIPO_VEHICULO:
      return { ...state, tipovehiculo: action.payload }

    case ADD_SERVICIO_DESTINO:
      return { ...state, serviciodestino: action.payload }
    case LISTA_ARCHIVOS:
      return { ...state, listaarchivos: action.payload }

    case ACTUALIZAR_FOCUS_INPUT:
      return { ...state, actualizarfocusinput: action.payload }

    case ACTUALIZAR_ARCHIVOS:
      return { ...state, archivos: action.payload }

    default:
      return state
  }
}
