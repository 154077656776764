import React, { ReactElement, useEffect, useState } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { confirm, custom } from 'devextreme/ui/dialog'
import { useDispatch, useSelector } from 'react-redux'
import NotFound from '../../../../components/notFound/notFound'
import { addShowLoader } from 'src/redux/actions/configActions'
import { RootState } from '../../../../redux/combineReducers'
import { ClaimsModule, isRolAllowed } from '../../../../utils/allowAuthUtil'
import { Modules } from '../../../../utils/allowModuloUtil'
import { addModules } from '../../templatesAdmin/redux/modulo/moduleActions'
import { _getModules } from '../../../companyAdminPages/templatesAdmin/services/moduloServices'
import { CompanyCampoDatoGridModel } from 'src/models/company/companyCampoDatoModel'
import { CompanyModel } from 'src/models/company/companyModel'
import {
  addCompany,
  addCompanyModulos,
  addParentCompany,
} from '../redux/companiesActions'
import { getCompany, getStructure } from '../services/companiesServices'
import { CompanyFormTabsPage } from './CompanyFormTabsPage'
import { CompanyListPage } from './CompanyListPage'

function CompaniesPage(): ReactElement {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  const [structure, setStructure] = useState(new CompanyCampoDatoGridModel())
  const isChangedState = useSelector(
    (state: RootState) => state.sharedUtils.isChanged,
  )
  const [showForm, setShowForm] = useState(false)
  const [rowSelected, setRow] = useState(0)
  const [initGrid, setInitGrid] = useState(false)
  const [creataCompany, setCreateCompany] = useState(false)
  const [tempHaveFiles, setTempHaveFiles] = useState(false)

  function UpdaState() {
    setInitGrid(!initGrid)
  }

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        setShowForm(false)
        setTempHaveFiles(false)
        dispatch(addCompany(new CompanyModel()))
        dispatch(addCompanyModulos(undefined))
      }
    })
    async function fetchData() {
      try {
        await dispatch(addModules(await _getModules(userState!.user!)))
        setStructure(await getStructure(userState!.user!))
        await dispatch(addShowLoader(false))

        dispatch(
          addParentCompany(
            await getCompany(
              userState!.user!,
              parseInt(userState!.user?.CompanyId!),
            ),
          ),
        )
      } catch (e) {
        console.error(e)
      }
    }
    fetchData()
    setInitGrid(!initGrid)

    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          setShowForm(false)
          setTempHaveFiles(false)
          dispatch(addCompany(new CompanyModel()))
          dispatch(addCompanyModulos(undefined))
        }
      })
    }
  }, [])

  function abrirPopUp() {
    setShowForm(!showForm)
  }

  const preventClose = (data: any) => {
    let confirmDialog = confirm(
      '<i>¿Salir sin guardar?</i>',
      'Confirma los cambios',
    )
    confirmDialog.then((dialogResult) => {
      if (dialogResult) {
        setShowForm(false)
      } else {
        setShowForm(true)
      }
    })
  }

  return (
    <div id='container-body'>
      {isRolAllowed(userState!.user!, Modules.ADMINISTRACION, [
        ClaimsModule.VIEW,
      ]) ? (
        <div
          style={{
            borderColor: '#f4f9ff',
            borderWidth: '2px',
            margin: '0.3%',
            padding: '0.16%',
          }}
        >
          <h3
            style={{
              margin: 0,
              padding: 0,
              fontFamily:
                'SF Pro Text,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif',
            }}
          >
            Gestorías
          </h3>
          {structure?.Campos !== undefined && structure?.Campos.length > 0 ? (
            <CompanyListPage
              verpopup={abrirPopUp}
              estadoGrid={initGrid}
              actualizar={UpdaState}
              crear={setCreateCompany}
            ></CompanyListPage>
          ) : null}
          <Popup
            visible={showForm}
            dragEnabled={true}
            hideOnOutsideClick={false}
            showTitle={true}
            title='Gestorías'
            width={'95%'}
            height={'95%'}
            showCloseButton={false}
          >
            <ToolbarItem
              widget='dxButton'
              toolbar='top'
              location='after'
              options={{
                text: '',
                icon: 'close',
                onClick: () => {
                  if (isChangedState) {
                    const buttonAttributes = {
                      id: 'usuarioCancelButton',
                      class: 'usuarioCancelButtonClass',
                    }
                    let confirmDialog = custom({
                      title: '',
                      messageHtml:
                        '<b>Para conservar los cambios antes debe pulsar Guardar. Si continúa perderá todos los cambios realizados.</b>',
                      buttons: [
                        {
                          text: 'Continuar',
                          onClick: (e) => {
                            setShowForm(false)
                            setTempHaveFiles(false)
                            dispatch(addCompany(new CompanyModel()))
                            dispatch(addCompanyModulos(undefined))
                            confirmDialog.hide()
                          },
                        },
                        {
                          text: 'Cancelar',
                          onClick: (e) => {
                            confirmDialog.hide()
                          },
                        },
                      ],
                    })
                    confirmDialog.show().then((dialogResult: any) => {
                      console.log(dialogResult)
                    })
                  } else {
                    setShowForm(false)
                    setTempHaveFiles(false)
                    dispatch(addCompany(new CompanyModel()))
                    dispatch(addCompanyModulos(undefined))
                  }
                },
              }}
            />
            <ScrollView width='100%' height='100%'>
              <CompanyFormTabsPage
                row={rowSelected}
                setShowForm={setShowForm}
                showForm={showForm}
                id='popupCompany'
                actualizar={UpdaState}
                crear={creataCompany}
                tempTieneArchivos={tempHaveFiles}
              ></CompanyFormTabsPage>
            </ScrollView>
          </Popup>
        </div>
      ) : (
        <div
          style={{ borderColor: '#f4f9ff', borderWidth: '2px', margin: '0.6%' }}
        >
          <NotFound data={{ type: 1 }}></NotFound>
        </div>
      )}
    </div>
  )
}

export { CompaniesPage }
