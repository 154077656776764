import React, { useEffect, useRef, useState } from 'react';
import { custom } from 'devextreme/ui/dialog';
import { Accordion, Form, List } from 'devextreme-react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import DataSource from 'devextreme/data/data_source';
import ScrollView from 'devextreme-react/scroll-view';
import {
  AsyncRule, EmailRule, GroupItem,
  PatternRule, RequiredRule, SimpleItem
} from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { addSujetos } from '../redux/sujetoActions';
import { addShowLoader } from 'src/redux/actions/configActions';
import { addUsuariosList, clearUsuario, showForm } from 'src/redux/usuariosPage/usuariosActions';
import { getSujetos } from '../service/sujetoService';
import { getUsuariosList, postUsuario, putUsuario } from '../../../services/usuarioPage/usuarioServices';
import { isAdmin, isSuperAdmin } from 'src/utils/allowAuthUtil';
import { useScreenSize } from 'src/utils/media-query';
import { NotifyType, rolesName, showToast } from 'src/utils/sharedUitls';
import { ButtonsForm } from '../../../components/buttonsForm/ButtonsForm';
import { STRINGS as S } from '../constants/formStrings';
import {
  mismoNombreAdmin, nombreRepetido,
  tieneEspaciosEnBlanco, tieneTipoUsuario
} from 'src/utils/validators';
import { rutas } from '../../../utils/rutasUtil';
import {IsModuloAllowed} from "../../../utils/allowModuloUtil";

export default function UsuarioForm() {
  const dispatch = useDispatch();
  const roleState = useSelector((state: RootState) => state.usuario.roles!);
  const userState = useSelector((state: RootState) => state.user!.user!);
  const usuarioState = useSelector((state: RootState) => state.usuario.usuario);
  const sujetoState = useSelector((state: RootState) => state.sujeto);
  const perfilState = useSelector((state: RootState) => state.profile);
  const companyState = useSelector((state: RootState) => state.company);
  const sucursalState = useSelector((state: RootState) => state.sucursal.sucursales);

  const { Datos } = useSelector((state: RootState) => state.usuario.usuariosList);

  const [updatedForm, setUpdatedForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [isCuenta, setIsCuenta] = useState(usuarioState.IdTipoUsuario === 4);
  const [isColaborador, setIsColaborador] = useState(usuarioState.RolesTipoCuenta?.includes(5));
  const [closedSucursuales, setClosedSucursales] = useState(1);
  const [selectedSucursales, setSelectedSucursales] = useState(usuarioState.SucursalesIds);
  const [updateRelacionConCompania, setUpdateRelacionConCompania] = useState(false);
  const [sujetoIdentification, setSujetoIdentification] = useState(
    usuarioState.SujetoIdentification,
  )
  const [idSujeto, setIdSujeto] = useState(usuarioState.IdSujeto)
  const [rutaPrincipal, setRutaPrincipal] = useState(usuarioState.RutaPrincipal)

  const { isLarge } = useScreenSize();

  const isAdministrator = isAdmin(userState);
  const isSuperAdministrator = isSuperAdmin(userState);
  const isNew = !usuarioState.Id;
  const isEditingHimself = userState.UserName === usuarioState.NombreUsuario;
  
  const rutasPermitidas= rutas.filter((ruta) => IsModuloAllowed(userState, ruta.module))

  const formData = useRef(usuarioState);

  const usernamePattern = '^S+$';

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(addShowLoader(true));
    const newUser = { ...usuarioState, SucursalesIds: selectedSucursales}        
    if (isNew) {
      await postUsuario(newUser).then(async (response) => {
        if (response  != null ) {
          dispatch(clearUsuario());
          dispatch(addSujetos(await getSujetos(userState)));
          dispatch(addUsuariosList(await getUsuariosList(userState)));
          setShowPopup(false);
        }
      })
    } else {
      await putUsuario(newUser)
        .then(async (response) => {
          if (response  != null ) {
            dispatch(clearUsuario());
            dispatch(addUsuariosList(await getUsuariosList(userState)));
            setShowPopup(false);
          }
        })
        .catch(() => {
          showToast(
            'No se han podido editar el usuario.',
            NotifyType.error,
            5000,
          );
        })
    }
    dispatch(addShowLoader(false));
  }

  const handleCancelClick = () => {
    const salir = () => {
      dispatch(clearUsuario());
      setShowPopup(false);
    }
    if (updatedForm) {
      const confirmDialog = custom({
        messageHtml: S.DIALOG,
        buttons: [
          {
            text: 'Continuar',
            onClick: (e) => {
              salir()
              confirmDialog.hide()
            },
          },
          {
            text: 'Cancelar',
            elementAttr: {
              id: 'usuarioCancelButton',
              class: 'usuarioCancelButtonClass',
            },
            onClick: (e) => confirmDialog.hide(),
          },
        ],
      })
      confirmDialog.show();
    } else salir();
  }

  const getSujetosForm = (readAll: boolean = false) => {
    let sujetos = [];

    if (readAll) {
      sujetos.push({ Id: 0, Nombre: '' })
      sujetoState.sujetos!.Datos.map((x) => {
        return sujetos.push({ Id: x.Id, Nombre: x.Nombre, NIF: x.NIF })
      })
      return sujetos
    } else {
      if (isAdministrator) {
        sujetos = sujetoState.sujetos!.Datos.filter(
          ({ Id }) => Id === parseInt(userState.SujetoId),
        )
      } else return sujetoState.sujetos?.Datos
    }
    return sujetos;
  }

  // TODO esto no debería estar aquí
  const tiposUsuario = [
    { Id: 1, Name: 'Superadministrador' },
    { Id: 2, Name: 'Administrador' },
    { Id: 3, Name: 'Empleado' },
    { Id: 4, Name: 'Cuenta' },
  ]

  const getTipoUsuario = () => {
    if (isAdministrator) {
      return tiposUsuario.filter(
        ({ Name }) => Name === 'Empleado' || Name === 'Cuenta',
      )
    } else if (isSuperAdministrator) {
      return tiposUsuario.filter(({ Name }) => Name === 'Administrador')
    }
  }

  const getRolesTipoCuenta = () => {
    return roleState.filter(
      ({ Name }) =>
        Name === rolesName.COLABORADOR ||
        Name === rolesName.TRANSMITENTE ||
        Name === rolesName.CLIENTE,
    )
  }

  const loginOptions = {
    disabled: usuarioState.Id,
    placeholder: S.LOGIN.PLACEHOLDER,

    onValueChanged: ({ value }: any) => {
      formData.current.NombreUsuario = value.trim();
    },
  }

  const nifOptions = {
    placeholder: S.NIF.PLACEHOLDER,
  }

  const nombreOptions = {
    placeholder: S.NOMBRE.PLACEHOLDER,
    onValueChanged: ({ value }: any) => {
      formData.current.Nombre = value.trim();
    },
  }

  const apellidosOptions = {
    placeholder: S.APELLIDOS.PLACEHOLDER,
    onValueChanged: ({ value }: any) => {
      formData.current.Apellidos = value.trim();
    },
  }

  const emailOptions = {
    placeholder: S.EMAIL.PLACEHOLDER,
  }

  const telefonoOptions = {
    placeholder: S.TELEFONO.PLACEHOLDER,
  }

  const tipoUsuarioOptionsSelf = {
    readOnly: true,
    value: rolesName.ADMINISTRADOR,
  }

  const tipoUsuarioOptions = {
    displayExpr: 'Name',
    items: getTipoUsuario(),
    placeholder: isAdministrator && formData.current.IdTipoUsuario === 2 ? "Administrador" : S.TIPO_USUARIO.PLACEHOLDER,
    readOnly: isAdministrator && formData.current.IdTipoUsuario === 2,
    valueExpr: 'Id',
    searchEnabled: true,
    onValueChanged: ({ value }: any) => {
      formData.current.IdTipoUsuario = value;
      formData.current.RolesTipoCuenta = [];
      setIsCuenta(value === 4)
      if (formData.current.RolesTipoCuenta  != null ) {
        setIsColaborador(formData.current.RolesTipoCuenta.includes(5));
      }
    },
  }

  //esto solo deberia servir para cuando someo stipoUsuarioOptions uperAdmins
  const gestoriaOptions = {
    displayExpr: 'Nombre',
    items: sujetoState.sujetos?.Datos,
    placeholder: S.GESTORIA.PLACEHOLDER,
    readOnly: !isSuperAdministrator,
    value: isSuperAdministrator ? sujetoIdentification : idSujeto,
    valueExpr: isSuperAdministrator ? 'NIF' : 'Id',
    searchEnabled: true,
    onValueChanged: ({ value }: any) => {
      if (isSuperAdministrator) {
        setSujetoIdentification(value)
        // Hay que buscar el id del sujeto para asignarselo, sino no se guarda
        let idSujeto = sujetoState.sujetos?.Datos.find(
          (sujeto) => sujeto.NIF === value,
        )?.Id
        if (idSujeto  != null ) formData.current.IdSujeto = idSujeto
      } else {
        setIdSujeto(value)
      }
    },
  }

  const gestoriaNoAdminOptions = {
    displayExpr: 'Nombre',
    placeholder: S.GESTORIA.PLACEHOLDER,
    readOnly: true,
    value: companyState?.parentCompany?.Name,
  }

  const tipoCuentaOptions = {
    disabled: !isCuenta,
    displayExpr: 'Name',
    items: getRolesTipoCuenta(),
    valueExpr: 'Id',
    onValueChanged: ({ value }: any) => {
      setIsColaborador(value.includes(5))
    },
  }

  let contrasenaOptions = {
    inputAttr: { autocomplete: 'new-password' },
    mode: showPassword ? 'text' : 'password',
    buttons: [
      {
        name: 'showPass',
        options: {
          icon: 'contains',
          onClick: () => setShowPassword(!showPassword),
        },
      },
    ],
  }

  const perfilesOptions = {
    displayExpr: 'Nombre',
    items: [
      { Id: null, Nombre: 'Sin perfil asignado' },
      ...(perfilState.profiles || [])
    ],
    placeholder: S.PERFILES.PLACEHOLDER,
    valueExpr: 'Id',
    searchEnabled: true,
  }
  
  const rutasOptions = {
    displayExpr: 'title',
    items: [{ path: null, title: '' }, ...(rutasPermitidas || [])],
    placeholder: 'Seleccionar formulario principal',
    valueExpr: 'path',
    value: rutaPrincipal,
    searchEnabled: true,
    onValueChanged: ({ value }: any) => {
      console.log(value);
      formData.current.RutaPrincipal = value
      setRutaPrincipal(value)
    },
  }

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        handleCancelClick()
      }
    })

    return () => {
      document.removeEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
          handleCancelClick()
        }
      })
    }
  }, [])

  return (
    <Popup
      visible={showPopup}
      hideOnOutsideClick={false}
      showCloseButton={false}
      onHidden={() => dispatch(showForm(false))}
      height='90%'
      width='90%'
      title={usuarioState.Id ? `Usuario ${usuarioState.Id}` : 'Nuevo usuario'}
    >
      <ToolbarItem
        widget='dxButton'
        location='after'
        options={{
          icon: 'close',
          onClick: handleCancelClick,
        }}
      />
      <ScrollView width='100%' height='100%'>
        <form
          onSubmit={handleOnSubmit}
          autoComplete='off'
          style={{ width: '100%' }}
        >
          <Form
            formData={formData.current}
            labelLocation={isLarge ? 'left' : 'top'}
            showValidationSummary={false}
            validationGroup='usuarioData'
          // onFieldDataChanged={() => {
          //   setUpdatedForm(true)
          // }}
          >
            <GroupItem colCount='2'>
              {isNew === true ? (
                <SimpleItem
                  dataField={'UserName'}
                  editorType={S.TYPES.TEXT_BOX}
                  editorOptions={loginOptions}
                  label={{ text: S.LOGIN.LABEL }}
                >
                  <RequiredRule message={S.LOGIN.REQ} />
                  <AsyncRule
                    message={
                      'Este campo no debe ser igual que el nombre del administrador '
                    }
                    validationCallback={(e: any) => {
                      return mismoNombreAdmin(
                        e.value,
                        userState.UserName,
                        isAdministrator,
                      )
                    }}
                  />
                  <AsyncRule
                    message={
                      'El nombre de usuario no debe tener espacios en blanco'
                    }
                    validationCallback={(e: any) => {
                      return tieneEspaciosEnBlanco(e.value);
                    }}
                  />
                  <AsyncRule
                    message={'Este nombre de usuario ya existe'}
                    validationCallback={(e: any) => {
                      return nombreRepetido(e.value, Datos);
                    }}
                  />
                </SimpleItem>
              ) : (
                <SimpleItem
                  dataField={S.LOGIN.PROP}
                  editorType={S.TYPES.TEXT_BOX}
                  editorOptions={loginOptions}
                  label={{ text: S.LOGIN.LABEL }}
                >
                  <RequiredRule message={S.LOGIN.REQ} />
                </SimpleItem>
              )}

              <SimpleItem
                dataField={'Username'}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={loginOptions}
                label={{ text: S.LOGIN.LABEL }}
                visible={false}
              >
                <RequiredRule message={S.LOGIN.REQ} />

                <AsyncRule
                  message={
                    'El nombre de usuario no debe tener espacios en blanco'
                  }
                  validationCallback={(e: any) => {
                    return tieneEspaciosEnBlanco(e.value);
                  }}
                />
                <AsyncRule
                  message={'Este nombre de usuario ya existe'}
                  validationCallback={(e: any) => {
                    return nombreRepetido(e.value, Datos);
                  }}
                />
              </SimpleItem>

              <SimpleItem
                dataField={S.NIF.PROP}
                editorOptions={nifOptions}
                editorType={S.TYPES.TEXT_BOX}
                label={{ text: S.NIF.LABEL }}
              >
                <RequiredRule message={S.NIF.REQ} />
                <PatternRule pattern={S.NIF.PATTERN} message={S.NIF.NO_MATCH} />
              </SimpleItem>
              <SimpleItem
                dataField={S.NOMBRE.PROP}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={nombreOptions}
                label={{ text: S.NOMBRE.LABEL }}
              >
                <RequiredRule message={S.NOMBRE.REQ} />
              </SimpleItem>
              <SimpleItem
                dataField={S.APELLIDOS.PROP}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={apellidosOptions}
                label={{ text: S.APELLIDOS.LABEL }}
              />
              <SimpleItem
                dataField={S.EMAIL.PROP}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={emailOptions}
                label={{ text: S.EMAIL.LABEL }}
              >
                {/* <RequiredRule message={S.EMAIL.REQ} /> */}
                <EmailRule message={S.EMAIL.NO_MATCH} />
              </SimpleItem>
              <SimpleItem
                dataField={S.TELEFONO.PROP}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={telefonoOptions}
                label={{ text: S.TELEFONO.LABEL }}
              />
              <SimpleItem
                dataField={S.TIPO_USUARIO.PROP}
                editorType={S.TYPES.SELECT_BOX}
                editorOptions={tipoUsuarioOptions}
                label={{ text: S.TIPO_USUARIO.LABEL }}
              >
                <RequiredRule></RequiredRule>
                <AsyncRule
                  message={S.TIPO_USUARIO.REQ}
                  validationCallback={(e: any) => {
                    return tieneTipoUsuario(e.value)
                  }}
                />
              </SimpleItem>
              {/* esto solo deberia servir para cuando somes superAdmins */}
              {isSuperAdministrator ? (
                <SimpleItem
                  dataField={isSuperAdministrator ? S.COMPANIA.PROP : S.GESTORIA.PROP}
                  editorType={S.TYPES.SELECT_BOX}
                  editorOptions={gestoriaOptions}
                  label={{ text: S.GESTORIA.LABEL }}
                >
                  <RequiredRule message={S.GESTORIA.REQ} />
                </SimpleItem>
              ) : (
                // aqui debemos poner los datos de la company parent si no somos superAdmin

                <SimpleItem
                  editorType={S.TYPES.TEXT_BOX}
                  editorOptions={gestoriaNoAdminOptions}
                  label={{ text: S.GESTORIA.LABEL }}
                >
                  <RequiredRule message={S.GESTORIA.REQ} />
                </SimpleItem>
              )}
              <SimpleItem
                dataField={S.TIPO_CUENTA.PROP}
                editorType={S.TYPES.TAG_BOX}
                editorOptions={tipoCuentaOptions}
                label={{ text: S.TIPO_CUENTA.LABEL }}
                visible={isAdministrator}
              >
                {isCuenta && <RequiredRule message={S.TIPO_CUENTA.REQ} />}
              </SimpleItem>
              <GroupItem colCount='2' visible={isAdministrator}>
                <SimpleItem
                  dataField={S.RELACION.PROP}
                  editorType={S.TYPES.TEXT_BOX}
                  editorOptions={{
                    placeholder: S.RELACION.PLACEHOLDER,
                    readOnly: !isCuenta,
                    disabled: !isCuenta,
                    onValueChanged: () => {
                      setUpdateRelacionConCompania(() => !updateRelacionConCompania);
                    },
                  }}
                  label={{ text: S.RELACION.LABEL }}
                  isRequired={isCuenta}
                >
                  {isCuenta && !usuarioState.IdSujeto && (
                    <PatternRule pattern={S.NIF.PATTERN} message={S.RELACION.REQ} />
                  )}
                </SimpleItem>

                <SimpleItem
                  dataField={S.COMPANIA.PROP}
                  editorType={S.TYPES.SELECT_BOX}
                  editorOptions={{
                    dataSource: new DataSource({ store: getSujetosForm(true) }),
                    displayExpr: 'Nombre',
                    placeholder: S.COMPANIA.PLACEHOLDER,
                    readOnly: !isCuenta,
                    searchEnabled: true,
                    activeStateEnabled: true,
                    valueExpr: 'NIF',
                    onValueChanged: (e: any) => {
                      setUpdateRelacionConCompania(
                        () => !updateRelacionConCompania,
                      )
                    },
                  }}
                  label={{ visible: false }}
                >
                  {isCuenta && !usuarioState.SujetoIdentification && (
                    <RequiredRule message={S.COMPANIA.REQ} />
                  )}
                </SimpleItem>
              </GroupItem>
              <SimpleItem
                dataField={S.CONTRASENA.PROP}
                editorType={S.TYPES.TEXT_BOX}
                editorOptions={contrasenaOptions}
                label={{ text: S.CONTRASENA.LABEL }}
                visible={isNew}
              >
                <RequiredRule message={S.CONTRASENA.REQ} />
              </SimpleItem>
              <SimpleItem
                dataField={S.PERFILES.PROP}
                editorType={S.TYPES.SELECT_BOX}
                editorOptions={perfilesOptions}
                label={{ text: S.PERFILES.LABEL }}
              />
              <SimpleItem
                dataField={S.BLOQUEADO.PROP}
                editorType={S.TYPES.CHECK_BOX}
                label={{ text: S.BLOQUEADO.LABEL }}
              />
            </GroupItem>

            <GroupItem colCount='2'>
              <GroupItem>
                <Accordion
                  collapsible
                  disabled={!isColaborador}
                  dataSource={[S.SUCURSALES.LABEL]}
                  height='16rem'
                  selectedIndex={closedSucursuales}
                  onSelectedIndexChange={setClosedSucursales}
                  itemRender={() => (
                    <Form formData={formData.current}>
                      <GroupItem>
                        <SimpleItem
                          dataField={S.SUCURSALES.CHECK.PROP}
                          editorType={S.TYPES.CHECK_BOX}
                          label={{ text: S.SUCURSALES.CHECK.LABEL }}
                        />
                      </GroupItem>

                      <GroupItem>
                        <GroupItem>
                          <SimpleItem
                            editorOptions={{
                              dataSource: new DataSource({
                                store: sucursalState.filter(
                                  (sucursal) =>
                                    !selectedSucursales.includes(sucursal.Id),
                                ),
                              }),
                              displayExpr: 'Descripcion',
                              placeholder: S.SUCURSALES.SELECT.PLACEHOLDER,
                              searchEnabled: true,
                              searchExpr: ['Descripcion', 'Codigo'],
                              onItemClick: (e: any) => {
                                setSelectedSucursales((selectedSucursales) =>
                                  selectedSucursales.concat(e.itemData.Id),
                                )
                              },
                            }}
                            editorType={S.TYPES.SELECT_BOX}
                            label={{ visible: false }}
                          />
                        </GroupItem>
                        <GroupItem>
                          <List
                            allowItemDeleting
                            height='6.4rem'
                            showSelectionControls
                            dataSource={
                              new DataSource({
                                store: sucursalState.filter((sucursal) =>
                                  selectedSucursales.includes(sucursal.Id),
                                ),
                              })
                            }
                            itemRender={(data) => (
                              <div>
                                <b>{data.Descripcion}</b>
                              </div>
                            )}
                            onItemDeleted={(e) => {
                              setSelectedSucursales((selectedSucursales) =>
                                selectedSucursales.filter(
                                  (id) => id !== e.itemData.Id,
                                ),
                              )
                            }}
                          />
                        </GroupItem>
                      </GroupItem>
                    </Form>
                  )}
                />
              </GroupItem>
              <SimpleItem
                dataField={rutas}
                editorType={S.TYPES.SELECT_BOX}
                editorOptions={rutasOptions}
                label={{ text: 'Seleccionar formulario principal' }}
              />
            </GroupItem>

            <SimpleItem
              cssClass='alignBottom'
              render={() =>
                ButtonsForm('usuarioData', handleCancelClick, false)
              }
            />
          </Form>
        </form>
      </ScrollView>
    </Popup>
  )
}
