export const ADD_SIZE='ADD_SIZE'


export interface FooterSizeInitialState{
    size:string;
}



interface addSize{

    type: typeof ADD_SIZE;
    payload:string
}


export type FooterSizeActionsTypes = | addSize