// import { DateConverter } from 'src/utils/dateUtils';
import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModelCore } from 'src/models/columnModel';

@JsonObject('')
class AyuntamientoModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('FechaVencimiento', String)
  FechaVencimiento: Date | String = '';

  @JsonProperty('FechaPresentacion', String)
  FechaPresentacion: Date | String = '';

  @JsonProperty('FechaPago', String)
  FechaPago: Date | String = '';

  @JsonProperty('Poblacion', String)
  Poblacion: string = '';

  @JsonProperty('Provincia', String)
  Provincia: string = '';

  @JsonProperty('Comunidad', String)
  Comunidad: string = '';
};

@JsonObject('')
export class AyuntamientoCampoDatoModel {
  @JsonProperty('Campos', [ColumnModelCore])
  Campos: ColumnModelCore[] = [];

  @JsonProperty('Datos', [AyuntamientoModel])
  Datos: AyuntamientoModel[] = [];
};
