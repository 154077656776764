import { Lines } from '../models/ILines';

// Función para agregar una nueva línea de texto en la posición especificada
function addLineText(line: number, lines: Lines[]): Lines[] {
  // Crea una nueva línea con valores iniciales
  let nuevaLinea: Lines = {
    text: '',
    line: 0,
  };

  // Inserta la nueva línea en la posición indicada
  lines.splice(line, 0, nuevaLinea);

  // Actualiza los números de línea en el conjunto de líneas
  return lines.map((row, index) => {
    row.line = index + 1;
    return row;
  });
}

// Función para eliminar una línea de texto en la posición especificada
function deleteLineText(line: number, lines: Lines[]): Lines[] {
  // Crea un nuevo conjunto de líneas excluyendo la línea a eliminar
  let newRows: Lines[] = [];
  lines.forEach((lineText) => {
    if (lineText.line !== line) {
      newRows.push(lineText);
    }
  });

  // Actualiza los números de línea en el conjunto de líneas resultante
  return newRows.map((row, index) => {
    row.line = index + 1;
    return row;
  });
}

export { addLineText, deleteLineText };
