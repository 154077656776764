import { ADD_DOSSIER_TYPE, ADD_DOSSIER_TYPES, CLEAR_DOSSIER_TYPE, 
  DossierTypeState, DossierTypeActionsTypes } from './IDossierTypeState';

const initialState: DossierTypeState = {
  dossierType: undefined,
  dossierTypes: undefined,
};

const dossierTypesReducer = (
  state = initialState,
  action: DossierTypeActionsTypes,
): DossierTypeState | undefined => {
  switch (action.type) {
    case ADD_DOSSIER_TYPE: {
      return { ...state, dossierType: action.payload };
    };
    case ADD_DOSSIER_TYPES: {
      return { ...state, dossierTypes: action.payload };
    };
    case CLEAR_DOSSIER_TYPE: {
      return { ...state, dossierType: action.payload };
    };
    default:
      return state;
  };
};

export { dossierTypesReducer };