import { JsonObject, JsonProperty } from 'json2typescript';
import { ColumnModel } from 'src/models/columnModel';
import { DossierTypeGridModel, DossierTypeModel } from './dossierTypeModel';

@JsonObject('SolicitudCampoDatoModel')
class DossierTypeFieldDataModel {
  @JsonProperty('Campos', [ColumnModel] || [])
  Campos: ColumnModel[] = [];

  @JsonProperty('Datos', [DossierTypeModel] || [])
  Datos: DossierTypeModel[] = [];
}

@JsonObject('SolicitudCampoDatoGridModel')
class DossierTypeFieldDataGridModel {
  @JsonProperty('Campos', [ColumnModel] || [])
  Campos: ColumnModel[] = [];

  @JsonProperty('Datos', [DossierTypeGridModel] || [])
  Datos: DossierTypeGridModel[] = [];
}

export { 
  DossierTypeFieldDataModel,
  DossierTypeFieldDataGridModel
};