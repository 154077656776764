import axios from 'axios'
import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import { UserModel } from 'src/models/usuario/userModel'
import { DetailExpedientFieldDataModel } from '../../models/detalleExpedienteModel'
import { StructureExpedienteModel } from '../../models/structureExpedienteModel'
import { authHeader } from '../../../loginPage/service/authService'
import { deleteFakeDates } from 'src/utils/dateUtils'
import {
  checkStatusCode,
  customLog,
  isNotEmpty,
  logType,
  methodsEndPointType,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'

const serviceName = 'EscriturasService'
const baseURL = process.env.REACT_APP_API_URL_CORE

// Estructura del grid
export const getEstructuraEscrituras = async (
  user: UserModel,
): Promise<StructureExpedienteModel> => {
  let structure = new StructureExpedienteModel()
  const url = `${baseURL}/ExpedienteEscritura/GetStructure`

  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    await axios
      .get(url, { headers: authHeader(user) })
      .then((response: any) => {
        checkStatusCode(response.status)
        structure = jsonConvert.deserializeObject(
          response.data,
          StructureExpedienteModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getEstructuraEscrituras.name,
          methodsEndPointType.GET,
        )
        checkStatusCode(error.request.status)
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
      })
  } catch (e) {
    // logger.error('', { component: getEstructuraEscrituras.name })
  }

  return structure
}

// Datos del grid
let isLoading = false
export const getEscrituras = async (
  user: UserModel,
  loadOptions: any,
  options: string[],
) => {
  let escrituras: any = {}
  const url = `${baseURL}/ExpedienteEscritura/GetExpedientesEscritura`
  try {
    let params = '?'
    options.forEach((key) => {
      if (key in loadOptions && isNotEmpty(loadOptions[key])) {
        params += `${key}=${JSON.stringify(loadOptions[key])}&`
      }
    })
    params = params.slice(0, -1)

    if (loadOptions.filter !== undefined) {
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          isLoading = false
          return data
        })
        .catch((error) => console.log('error', error))

      escrituras = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }
      return escrituras
    } else {
      const data = await fetch(url + params, {
        method: 'GET',
        headers: authHeader(user),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          return data
        })
        .catch((error) => console.log('error', error))

      escrituras = {
        data: data.Datos.map(deleteFakeDates),
        totalCount: data.totalCount,
        summary: data.summary,
      }
      return escrituras
    }
  } catch (e) {}
}

// Detalle expediente
export const getDetalleEscritura = async (
  expedienteId: number,
  user: UserModel,
): Promise<DetailExpedientFieldDataModel> => {
  let detalleEscritura = new DetailExpedientFieldDataModel()
  const url = `${baseURL}/ExpedienteEscritura/GetDetalle/${expedienteId}`

  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

    await axios
      .get(url, { headers: authHeader(user) })
      .then((response: any) => {
        checkStatusCode(response.status)
        detalleEscritura = jsonConvert.deserializeObject(
          response.data,
          DetailExpedientFieldDataModel,
        )
      })
      .catch((error: any) => {
        customLog(
          null,
          logType.ERROR,
          serviceName,
          getDetalleEscritura.name,
          methodsEndPointType.GET,
        )
        showToast(`${error.response.data.Message}`, NotifyType.error, 5000)
      })
  } catch (e) {}
  return detalleEscritura
}
