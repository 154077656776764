import React from 'react';
import { GroupItem, SimpleItem, StringLengthRule } from 'devextreme-react/form';
import { SolicitudListModel } from '../../models/solicitudListModel';

interface Props {
  solicitud: SolicitudListModel;
  tipovia: Array<Object>;
}

export default function DatosDireccionVendedorRepresentante({
  solicitud,
  tipovia,
  // dispatch,
}: Props) {
  return (
    <GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Tipo vía' }}
          editorType='dxSelectBox'
          editorOptions={{
            hint: 'Tipo Via comprador',
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value  != null ) {
                solicitud.SellerLegalRep.Address.StreetType = e.value
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            dataSource: tipovia,
            displayExpr: 'Descripcion',
            valueExpr: 'Id',
            value: solicitud.SellerLegalRep.Address.StreetType,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: 'Descripcion',
            minSearchLength: 0,
          }}
        ></SimpleItem>

        <SimpleItem
          label={{ text: 'Nombre vía' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre Via comprador',
            value:
              solicitud.SellerLegalRep.Address.StreetName !== ''
                ? solicitud.SellerLegalRep.Address.StreetName
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.StreetName = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del campo nombre de la vía no debe ser superior a 40'
            }
            max={40}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Número' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Numero comprador',
            value:
              solicitud.SellerLegalRep.Address.Number !== ''
                ? solicitud.SellerLegalRep.Address.Number
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Number = value.trim()
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo número no debe ser superior a 15'
            max={15}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Escalera' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Escalera comprador',
            value:
              solicitud.SellerLegalRep.Address.Stairs !== ''
                ? solicitud.SellerLegalRep.Address.Stairs
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Stairs = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo escalera no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Planta' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Planta comprador',
            value:
              solicitud.SellerLegalRep.Address.Floor !== ''
                ? solicitud.SellerLegalRep.Address.Floor
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.FullAddress = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        ></SimpleItem>
        <SimpleItem
          label={{ text: 'Puerta' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Puerta comprador',
            value:
              solicitud.SellerLegalRep.Address.Door !== ''
                ? solicitud.SellerLegalRep.Address.Door
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Door = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo puerta no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Portal' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Portal comprador',
            value:
              solicitud.SellerLegalRep.Address.Portal !== ''
                ? solicitud.SellerLegalRep.Address.Portal
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Portal = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo portal no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Letra' }}
          editorType={'dxTextBox'}
          editorOptions={{
            hint: 'Letra comprador',
            value:
              solicitud.SellerLegalRep.Address.Letter !== ''
                ? solicitud.SellerLegalRep.Address.Letter
                : null,
            mode: 'text',
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Letter = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo letra no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Cod.Postal' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Cod.Postal comprador',
            value:
              solicitud.SellerLegalRep.Address.ZipCode !== ''
                ? solicitud.SellerLegalRep.Address.ZipCode
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.ZipCode = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo código postal no debe ser superior a 6'
            max={6}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          label={{ text: 'Municipio' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Municipio comprador',
            value:
              solicitud.SellerLegalRep.Address.City !== ''
                ? solicitud.SellerLegalRep.Address.City
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.City = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del campo municipio no debe ser superior a 100'
            }
            max={100}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          label={{ text: 'Km' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Km comprador',
            value:
              solicitud.SellerLegalRep.Address.Km !== ''
                ? solicitud.SellerLegalRep.Address.Km
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Km = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo km no debe ser superior a 5'}
            max={5}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          label={{ text: 'Hm' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Hm comprador',
            value:
              solicitud.SellerLegalRep.Address.Hm !== ''
                ? solicitud.SellerLegalRep.Address.Hm
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud.SellerLegalRep.Address.Hm = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo hm no debe ser superior a 2'}
            max={2}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
    </GroupItem>
  );
};
