import { DossierStatusRelation } from '../models/idocCarEstadosExpedientesModel'

export interface IdocCarDossierStatusRelationState {
  idocCarDossierStatusRelation:
    | Partial<DossierStatusRelation>
    | DossierStatusRelation
    | undefined
  idocCarDossierStatusRelationAll: any | undefined
}

export const ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL =
  'ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL'
export const ADD_IDOCCAR_DOSSIER_STATUS_RELATION =
  'ADD_IDOCCAR_DOSSIER_STATUS_RELATION'
export const CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION =
  'CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION'

interface AddIdocCarDossierStatusRequest {
  type: typeof ADD_IDOCCAR_DOSSIER_STATUS_RELATION
  payload: Partial<DossierStatusRelation> | DossierStatusRelation | undefined
}
interface AddIdocCarDossierStatusRequestAll {
  type: typeof ADD_IDOCCAR_DOSSIER_STATUS_RELATION_ALL
  payload: any | undefined
}
interface ClearIdocCarDossierStatusRelationRequest {
  type: typeof CLEAR_IDOCCAR_DOSSIER_STATUS_RELATION
  payload: DossierStatusRelation | undefined
}

export type IdocCarDossierStatusRelationActionsTypes =
  | ClearIdocCarDossierStatusRelationRequest
  | AddIdocCarDossierStatusRequest
  | AddIdocCarDossierStatusRequestAll
