import React, { ReactElement, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form } from 'devextreme-react'
import { Label, RequiredRule, SimpleItem } from 'devextreme-react/form'
import { RootState } from '../../../redux/combineReducers'
import { useScreenSize } from '../../../utils/media-query'
import {
  _postDossierType,
  _putDossierType,
} from '../services/dossierTypeServices'
import DataSource from 'devextreme/data/data_source'
import { custom } from 'devextreme/ui/dialog'
import { useForm } from 'react-hook-form'
import { ButtonsForm } from 'src/components/buttonsForm/ButtonsForm'
import { addShowLoader } from 'src/redux/actions/configActions'
import DocumentacionASolicitar from './DocumentacionASolicitar'
import { ConfigracionSolicitudesContext } from '../Context/dossierTypeContext'
import { addDossierType } from '../redux/dossierTypeActions'
import { DossierTypeModel } from '../models/dossierTypeModel'
import { ToolbarItem } from 'devextreme-react/date-box'
import { Popup } from 'devextreme-react/popup'

function DossierTypeForm(props: any): ReactElement {
  const dossierTypeState = useSelector(
    (state: RootState) => state.dossierTypes?.dossierType,
  )
  const { isLarge } = useScreenSize()
  const dispatch = useDispatch()
  const { handleSubmit } = useForm()
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const dossierTypeFormRef: any = useRef<Form>(null)
  const formData = useRef({})

  // Muestra un cuadro de diálogo de confirmación al usuario antes de guardar cambios.
  const DisplayConfirmation = async () => {
    // Crea un cuadro de diálogo personalizado con opciones de confirmación
    const confirmDialog = custom({
      title: 'Confirmar cambios',
      messageHtml: 'Estas seguro de querer guardar cambios?',
      buttons: [
        {
          text: 'Cancelar',
          elementAttr: {
            id: 'usuarioCancelButton',
            class: 'usuarioCancelButtonClass',
          },
          onClick: async () => {
            // Oculta el cuadro de diálogo al hacer clic en Cancelar
            confirmDialog.hide()
          },
        },
        {
          // Botón para confirmar y realizar la operación
          text: 'Confirmar',
          onClick: async () => {
            // Realiza la operación de envío o actualización y verifica el éxito
            dispatch(addShowLoader(true))
            const success = await PutOrPost()
            // Si la operación fue exitosa, realiza acciones adicionales y oculta el cuadro de diálogo
            success && IsSuccess()
            dispatch(addShowLoader(false))
            confirmDialog.hide()
          },
        },
      ],
    })
    confirmDialog.show()
  }
  const IsSuccess = () => {
    // Oculta el formulario al usuario
    props.setShowFormDossierTypes(false)
    // Actualiza la lista o datos que se muestran en la interfaz
    const refreshButton = document.getElementsByClassName(
      'dx-icon dx-icon-refresh',
    )[0] as HTMLElement
    refreshButton.click()
  }

  const onSubmit = async () => {
    DisplayConfirmation()
  }

  const HandleUpdatedDossierType = (property: any) => {
    dispatch(
      addDossierType({
        ...dossierTypeState,
        ...property,
      }),
    )
  }

  const PutOrPost = async () => {
    // Verifica si hay un ID para determinar si estás creando o editando
    let newDossierType = new DossierTypeModel()
    if(dossierTypeState?.Id) {
      newDossierType = await _putDossierType(dossierTypeState)
    } else {
      newDossierType = await _postDossierType(dossierTypeState)
    }
    dispatch(addDossierType(newDossierType))
    return true
  }

  // DataSource para el cuadro de selección de Tipo Remoto
  const selectBoxdossierOriginTypeRemoteData = new DataSource({
    store: [
      { Id: 'MAT', Name: 'Matriculación' },
      { Id: 'CTI', Name: 'Transferencia' },
      { Id: 'ENT', Name: 'Baja Temporal' },
      { Id: 'NOT', Name: 'Notificación Venta' },
      { Id: 'INF-DGT', Name: 'Informe de Vehículo' },
      {
        Id: 'CTI-FN',
        Name: 'Transferencia a comprador final desde Notificación Venta',
      },
      {
        Id: 'NOT-PE',
        Name: 'Notificación de Venta procedente de baja temporal',
      },
      {
        Id: 'CTI-FE',
        Name: 'Transferencia a comprador final desde baja temporal',
      },
    ],
    key: 'Id',
  })

  // Opciones para el cuadro de selección de Tipo
  const DossierOriginTypeSelectedBoxOptions = {
    items: [
      { Id: 1, Name: 'Solicitudes' },
      { Id: 2, Name: 'iDocCar' },
    ],
    value: dossierTypeState?.DossierOriginType,
    placeholder: 'Seleccione un tipo',
    displayExpr: 'Name',
    valueExpr: 'Id',
    onValueChanged: (event: any) => {
      if (event.event) {
        HandleUpdatedDossierType({ DossierOriginType: event.value })
      }
    },
  }

  // Opciones para el cuadro de selección de Tipo Remoto
  const DossierOriginTypeRemoteSelectedBoxOptions = {
    dataSource: selectBoxdossierOriginTypeRemoteData,
    value: dossierTypeState?.DossierRemoteTypeCode,
    placeholder: 'Seleccione un tipo',
    displayExpr: 'Name',
    valueExpr: 'Id',
    onValueChanged: (event: any) => {
      if (event.event) {
        HandleUpdatedDossierType({
          DossierRemoteTypeCode: event.value,
        })
      }
    },
  }

  const handleCancelClick = () => {
    props.setShowFormDossierTypes(false)
  }

  const renderButtons = () => {
    if (!props.showFormDossierTypes) {
      dossierTypeFormRef.current.instance.resetValues()
    }

    return ButtonsForm(`dossierTypeData`, handleCancelClick, false)
  }

  return (
    <ConfigracionSolicitudesContext.Provider  value={{popUpVisible: popupVisible, setPopUpVisible: setPopupVisible }}>
      <div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            encType='multipart/form-data'
            style={{ width: '100%' }}
          >
            <Form
              ref={dossierTypeFormRef}
              formData={formData.current}
              labelLocation={isLarge ? 'left' : 'top'}
              showColonAfterLabel={true}
              validationGroup='dossierTypeData'
            >
              <SimpleItem
                dataField='Name'
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Ingrese un nombre',
                  mode: 'text',
                  value: dossierTypeState?.Name?.toString()
                    ? dossierTypeState?.Name?.toString()
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      HandleUpdatedDossierType({ Name: event.value })
                    }
                  },
                }}
              >
                <Label visible={true} text='Nombre' />
                <RequiredRule message='El campo es requerido' />
              </SimpleItem>
              <SimpleItem
                dataField='IdTramiteElGestor'
                editorOptions={{
                  inputAttr: {
                    autocomplete: 'new-address',
                  },
                  placeholder: 'Ingrese un Id de Gestor',
                  mode: 'text',
                  value: dossierTypeState?.IdTramiteElGestor?.toString()
                    ? dossierTypeState?.IdTramiteElGestor?.toString()
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      HandleUpdatedDossierType({
                        IdTramiteElGestor: event.value,
                      })
                    }
                  },
                }}
              >
                <Label visible={true} text='Trámite' />
                <RequiredRule message='El campo es requerido' />
              </SimpleItem>

              <SimpleItem
                dataField={'DossierSerie'}
                editorType={'dxTextBox'}
                editorOptions={{
                  placeholder: 'Ingrese un código de serie',
                  mode: 'text',
                  value: dossierTypeState?.DossierSerie?.toString()
                    ? dossierTypeState?.DossierSerie?.toString()
                    : '',
                  onValueChanged: (event: any) => {
                    if (event.event) {
                      HandleUpdatedDossierType({ DossierSerie: event.value })
                    }
                  },
                }}
              >
                <Label visible={true} text='Serie' />
                <RequiredRule message='El campo es requerido' />
              </SimpleItem>
              <SimpleItem
                dataField='DossierOriginType'
                editorType='dxSelectBox'
                editorOptions={DossierOriginTypeSelectedBoxOptions}
              >
                <Label visible={true} text='Tipo' />
                <RequiredRule message='El campo es requerido' />
              </SimpleItem>
              <SimpleItem
                dataField='DossierRemoteTypeDescription'
                editorType='dxSelectBox'
                editorOptions={DossierOriginTypeRemoteSelectedBoxOptions}
              >
                <Label visible={true} text='Tipo remoto' />
                <RequiredRule message='El campo es requerido' />
              </SimpleItem>
              <SimpleItem render={renderButtons} cssClass='alignBottom' />
            </Form>
          </form>
          <Popup
            visible={popupVisible}
            showCloseButton={false}
            title='Documentación a solicitar'
            width={1000}
            height={'auto'}
          >
            <ToolbarItem
              widget='dxButton'
              location='after'
              options={{
                icon: 'close',
                onClick: () => setPopupVisible(false),
              }}
            />
          <DocumentacionASolicitar />
          </Popup>
          <Button
            text='Documentación a solicitar'
            onClick={() => setPopupVisible(true)}
            type='default'
          />
        </div>
      </div>
    </ConfigracionSolicitudesContext.Provider>
  )
}

export { DossierTypeForm }
