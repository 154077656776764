import React from 'react';
import { isNotEmpty, NotifyType, showToast } from 'src/utils/sharedUitls'
import { Box, Button } from 'devextreme-react';
import { Item } from 'devextreme-react/box';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import { validandoDocumento } from '../../../../../utils/NifDni/validation'
import { useRecoilValue} from 'recoil'
import {
  isCheckCompradorRepresentanteState,
  isCheckVendedorRepresentanteState,
} from '../../../../../recoil/sharesAtoms/atoms'

interface Props {
  validacion: Array<boolean>;
  enviarsolicitud: Function;
  verificacion: string;
  validos: Function;
  editar: boolean;
}
export default function FooterSendFormSection({
  validacion,
  enviarsolicitud,
  verificacion,
  validos,
  editar,
}: Props) {
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  )
  const formFile = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.archivos,
  )
  const listaarchivos = useSelector(
    (state: RootState) =>
      state.formularioSolicitudDatosCamposUtil!.listaarchivos,
  )

  const isCheckCompradorRepresentante = useRecoilValue(isCheckCompradorRepresentanteState)
  const isCheckVendedorRepresentante = useRecoilValue(isCheckVendedorRepresentanteState)

  const validarSolicitud = async () => {
    const validarNif = async (nif: string | undefined) => {
      return nif ? await validandoDocumento(nif) : false
    }

    const formateonifscifscomprador = await validarNif(solicitud?.Client?.Nif)
    const razonSocialComprador = isNotEmpty(solicitud?.Client?.Name)
    const formateonifscifscompradorrepresentante = await validarNif(solicitud?.ClientLegalRep?.Nif)
    const formateonifscifsvendedor = await validarNif(solicitud?.Seller?.Nif)
    const razonSocialVendedor = isNotEmpty(solicitud?.Seller?.Name)
    const formateonifscifsvendedorrepresentante = await validarNif(
      solicitud?.SellerLegalRep?.Nif,
    )

    if (
      solicitud!.InputsDocumentacion.length > 0 &&
      formFile?.length === 0 &&
      listaarchivos?.length === 0
    ) {
      validos(0, false)
      showToast(
        ' Debes añadir al menos 1 documento y/o archivo/s no deben superar los 20 megas',
        NotifyType.error,
        5000,
      )
    } else {
      validos(0, true)
    }

    const validarComprador = formateonifscifscomprador && razonSocialComprador && (!isCheckCompradorRepresentante || formateonifscifscompradorrepresentante)
    validos(1, validarComprador)
    if (!validarComprador) {
      showToast('Error, debe introducir el NIF/NIE/CIF o Razón social', NotifyType.error, 5000)
    }

    const validarVendedor = formateonifscifsvendedor && razonSocialVendedor && (!isCheckVendedorRepresentante || formateonifscifsvendedorrepresentante)
    if (['CTI', 'CTI-FE', 'CTI-FN'].includes(solicitud!.DossierType)) {
      validos(2, validarVendedor)
      if (!validarVendedor) {
        showToast('Error, debe introducir el NIF/NIE/CIF o Razón social', NotifyType.error, 5000)
      }
      if (solicitud!.Vehicle.VIN) {
        const bastidorRexp = /^[A-HJ-NPR-Z0-9]{17}$/
        const validarVin = bastidorRexp.test(solicitud!.Vehicle.VIN.trim())
        validos(3, validarVin)
        if (!validarVin) {
          showToast('Error el Bastidor tiene que tener 17 caracteres', NotifyType.error, 5000)
        }
      }
    } else if (solicitud!.Vehicle.VIN) {
      const bastidorRexp = /^[A-HJ-NPR-Z0-9]{17}$/
      const validarVin = bastidorRexp.test(solicitud!.Vehicle.VIN.trim())
      validos(2, validarVin)
      if (!validarVin) {
        showToast('Error el Bastidor tiene que tener 17 caracteres', NotifyType.error, 5000)
      }
    }
  }

  return (
    <Box direction='row' height={60} align='end' crossAlign='end'>
      <Item ratio={0} baseSize='auto'>
        <Button
          text={!editar ? 'Crear solicitud' : 'Modificar solicitud'}
          type='default'
          validationGroup={verificacion}
          //useSubmitBehavior={true}
          onClick={async (e: any) => {
            e.event.preventDefault()
            await validarSolicitud()
            if (validacion.indexOf(false) === -1) {
              enviarsolicitud()
            }
          }}
        />
      </Item>
    </Box>
  );
};
