import { combineReducers } from 'redux';
import { configReducer } from './reducers/configReducers';
import { sharedUtilsReducer } from './reducers/sharedUtilsReducer';
import { calculatorTransfersReducer } from 'src/pages/companyAdminPages/companyPage/redux/calculatorTranfers/calculatorTransfersReducer';
import { companyReducer } from 'src/pages/companyAdminPages/companyPage/redux/companiesReducer';
import { emailReducer } from 'src/pages/companyAdminPages/companyPage/redux/email/emailReducer';
import { linkReducer } from 'src/pages/companyAdminPages/companyPage/redux/link/linkReducer';
import { expedienteEscrituraReducer } from 'src/pages/expedientePage/redux/escritura/escrituraReducer';
import { expedienteVehiculoReducer } from 'src/pages/expedientePage/redux/vehiculo/vehiculoReducer';
import { moduleReducer } from 'src/pages/companyAdminPages/templatesAdmin/redux/modulo/moduleReducer';
import { profileReducer } from 'src/pages/adminPages/profilePages/redux/profileReducer';
import { templateReducer } from 'src/pages/companyAdminPages/templatesAdmin/redux/template/templateReducer';
import { sujetoReducer } from 'src/pages/usuariosPage/redux/sujetoReducer';
import { userReducer, userResetReducer } from 'src/pages/loginPage/redux/authReducer';
import { usuarioReducer } from 'src/redux/usuariosPage/usuariosReducer';
import { sucursalReducer } from './sucursal/sucursalReducer';
import { requestsReducer } from 'src/pages/companyAdminPages/companyPage/redux/requests/requestsReducer';
import { idocCarConfigReducer } from 'src/pages/companyAdminPages/idocCarConfigPage/redux/idocCarConfigReducer';
import { idocCarOrganizationalUnitReducer } from 'src/pages/companyAdminPages/idocCarOrganizationalUnitPage/redux/idocCarOrganizationalUnitReducer';
import { idocCarDossierStatusElGestorReducer } from 'src/pages/companyAdminPages/idocCarStatesFiles/redux/idocCarEstadosExpedientesReducer';
import { solicitudListReducer } from 'src/pages/companyAdminPages/requestPage/redux/solicitudReducer';
import { idocCarDossierStatusRelationReducer } from 'src/pages/companyAdminPages/idocCarRelationshipsStatesFiles/redux/idocCarRelacionesEstadosExpedientesReducer';
import { formDatosCamposUtilsReducer } from '../pages/companyAdminPages/requestPage/redux/FormularioSolicitudes/reducers/formDatosCamposUtilsReducer';
import { FooterSizeReducer } from 'src/layouts/footer/redux/footerSizeChange/reducers/FooterSizeReducer';
import { InformaReducer } from 'src/pages/informa/redux/reducers/InformaReducer';
import { LogsReducer } from 'src/pages/logsPage/redux/reducers/LogsReducer';
import { dossierTypesReducer } from 'src/pages/dossierType/redux/dossierTypeReducer'

const rootReducer = combineReducers({
  calculatorTransfers: calculatorTransfersReducer,
  company: companyReducer,
  config: configReducer,
  email: emailReducer,
  link: linkReducer,
  vehiculos: expedienteVehiculoReducer,
  escrituras: expedienteEscrituraReducer,
  modulo: moduleReducer,
  profile: profileReducer,
  template: templateReducer,
  resetPasswd: userResetReducer,
  sharedUtils: sharedUtilsReducer,
  sucursal: sucursalReducer,
  sujeto: sujetoReducer,
  user: userReducer,
  usuario: usuarioReducer,
  requests: requestsReducer,
  dossierTypes: dossierTypesReducer,
  solicitudesList: solicitudListReducer,
  idocCarConfig: idocCarConfigReducer,
  idocCarOrganizationalUnit: idocCarOrganizationalUnitReducer,
  idocCarDossierStatusElGestor: idocCarDossierStatusElGestorReducer,
  idocCarDossierStatusRelation: idocCarDossierStatusRelationReducer,
  formularioSolicitudDatosCamposUtil: formDatosCamposUtilsReducer,
  footerSize: FooterSizeReducer,
  Informa: InformaReducer,
  logscompany: LogsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export { rootReducer };
