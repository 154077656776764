import DataGrid, {
  Column,
  Scrolling,
  StateStoring,
  Summary,
  TotalItem,
} from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  checkScreenSize,
  getFileIcon,
  NotifyType,
  showToast,
} from 'src/utils/sharedUitls'
import {
  deleteCompanyDocument,
  getAllCompanyDocumentation,
  getCompanyDocumentation,
  getDocumentacionEmpresaFileLink,
  getTiposDocumentacion,
  postCompanyDocument,
} from '../services/MiRGPDServices'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { Popup } from 'devextreme-react/popup'
import { addShowLoader } from '../../../redux/actions/configActions'
import { Button, Form, SelectBox } from 'devextreme-react'
import { Item } from 'devextreme-react/form'
import { useForm } from 'react-hook-form'
import Dropzone from 'react-dropzone-uploader'
import { isRuanoUser } from '../../../services/usuarioPage/usuarioServices'
import { getCompaniesDatos } from '../../companyAdminPages/companyPage/services/companiesServices'

export default function MiRGPD() {
  const { handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user?.user)
  const [companyId, setCompanyId] = useState<string>(userState?.CompanyId!)
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    number | undefined
  >()
  const isRuano = isRuanoUser(userState!)
  const [documetoYDescripcion, setDocumentoYDescripcion] = useState<any>()
  const allowedFileExtensions = '.pdf, .doc, .docx'
  const [initialFiles, setInitialFiles] = useState<File[]>([])
  const [popupVisible, setPopupVisible] = useState(false)

  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getCompaniesDatos(),
    enabled: isRuano,
    staleTime: Infinity,
  })
  const { data: tiposDocumentacion } = useQuery({
    queryKey: ['tiposDocumentacion'],
    queryFn: () => getTiposDocumentacion(),
    enabled: !companies,
    staleTime: Infinity,
  })
  const { data: documentacionEmpresa, isLoading } = useQuery({
    queryKey: ['documentacionEmpresa'],
    queryFn: () => {
      if (isRuano) {
        return getAllCompanyDocumentation()
      }
      return getCompanyDocumentation(companyId)
    },
    staleTime: Infinity,
  })
  const postCompanyDocumentation = useMutation(
    async function (file: File) {
      if (file) {
        await postCompanyDocument(file, companyId, selectedDocumentType)
      }
      return showToast('Error al añadir documentación', NotifyType.error)
    },
    {
      onSuccess: function () {
        dispatch(addShowLoader(false))
        showToast('Documentación agregada correctamente', NotifyType.success)
        queryClient.invalidateQueries('documentacionEmpresa')
      },
    },
  )

  const handleToolbarPreparing = (e: any) => {
    let toolbarItems = e.toolbarOptions.items

    if (isRuano) {
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: () => setPopupVisible(true),
        },
        location: 'after',
      })
    }

    dispatch(addShowLoader(false))
  }

  const onSubmit = (props: any) => {
    let file = props[0].file
    if (file) {
      postCompanyDocumentation.mutate(file)
      setPopupVisible(false)
      setInitialFiles([])
    }
  }
  useEffect(() => {
    let descripcionyDocumento = documentacionEmpresa?.map((doc: any) => {
      if (tiposDocumentacion !== undefined) {
        return {
          id: doc.Id,
          companyId: doc.CompanyId,
          empresa_name: companies?.find(
            (company) => company.Id === doc.CompanyId,
          )?.Name,
          ruta: doc.Ruta,
          description: tiposDocumentacion.filter(
            (tipo: any) => tipo.Id === doc.RgpdTipoDocumentacionId,
          )[0].Descripcion,
        }
      }
    }, [])
    setDocumentoYDescripcion(descripcionyDocumento)
  }, [tiposDocumentacion, documentacionEmpresa, companies])

  const handleDeleteDocumentacion = async (data: any) => {
    try {
      const response = await deleteCompanyDocument(
        data.data.id,
        data.data.companyId,
      )
      if (response) {
        showToast('Documentación borrada correctamente', NotifyType.success)
        queryClient.invalidateQueries('documentacionEmpresa')
      }
    } catch (error) {
      console.error('Error deleting consentimiento', error)
    }
  }
  function cellRender(data: any) {
    let myIcon = getFileIcon(data.data.ruta)
    return (
      <Button
        icon={myIcon}
        text='Descargar'
        hint='Descarga el documento a su equipo'
        onClick={(e: any) => {
          getDocumentacionEmpresaFileLink(
            data.data.id,
            encodeURIComponent(data.data.ruta),
            userState!.Token!,
          )
        }}
      />
    )
  }

  return (
    <div style={{ height: '850px', overflow: 'auto', margin: '0 4vh' }}>
      <h2>Mi RGPD</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : documentacionEmpresa ? (
        <div>
          <DataGrid
            id='gridMiRGPD'
            dataSource={documetoYDescripcion}
            rowAlternationEnabled
            showBorders
            showRowLines
            showColumnLines
            loadPanel={{ enabled: true }}
            style={{ margin: '2vh' }}
            searchPanel={{
              visible: true,
              width: checkScreenSize() ? 240 : 120,
            }}
            onToolbarPreparing={handleToolbarPreparing}
            paging={{ enabled: false }}
            filterPanel={{ visible: true }}
            filterRow={{ visible: true }}
            headerFilter={{ visible: true }}
          >
            <StateStoring enabled type='localStorage' storageKey='gridMiRGPD' />
            <Scrolling
              mode='virtual'
              rowRenderingMode='virtual'
              showScrollbar='always'
              scrollByThumb
              preloadEnabled
            />
            <Column
              dataField='edit'
              caption=''
              width={150}
              cellRender={(cellRenderData: any) => cellRender(cellRenderData)}
            />
            {/* <Column dataField='id' caption='Id' /> */}
            <Column dataField='empresa_name' caption='Empresa' />
            <Column dataField='ruta' caption='Documento' />
            <Column dataField='description' caption='Tipo Documento' />
            {isRuano && (
              <Column
                dataField='delete'
                caption=''
                width={150}
                cellRender={(cellData: any) => (
                  <Button
                    icon='trash'
                    text='Eliminar'
                    hint='Eliminar el documento'
                    onClick={() => handleDeleteDocumentacion(cellData)}
                    style={{ margin: 'auto', display: 'flex' }}
                  />
                )}
              />
            )}
            <Summary>
              <TotalItem
                column='Id'
                summaryType='count'
                displayFormat={'Total de documentos: {0}'}
              />
            </Summary>
          </DataGrid>
          {isRuano && (
            <Popup
              visible={popupVisible}
              onHiding={() => {
                setPopupVisible(false)
                setSelectedDocumentType(undefined)
              }}
              dragEnabled={false}
              showTitle={true}
              title='Subir documento'
              width={800}
              height={450}
            >
              <div>
                <h1>Documento</h1>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  encType='multipart/form-data'
                >
                  <Form>
                    <Item
                      label={{
                        text: 'Selecciona la empresa a la que añadir documentación',
                        location: 'top',
                      }}
                    >
                      <SelectBox
                        name='empresa'
                        dataSource={companies ?? []}
                        searchEnabled
                        valueExpr='Id'
                        displayExpr='Name'
                        value={companyId}
                        onValueChanged={(e) => setCompanyId(e.value)}
                      />
                    </Item>
                    <Item
                      label={{
                        text: 'Selecciona el tipo de documentación',
                        location: 'top',
                      }}
                    >
                      <SelectBox
                        name='tipoDocumentacion'
                        dataSource={tiposDocumentacion}
                        valueExpr='Id'
                        displayExpr='Descripcion'
                        value={selectedDocumentType}
                        onValueChanged={(e) => setSelectedDocumentType(e.value)}
                      />
                    </Item>
                    <Item>
                      <Dropzone
                        initialFiles={initialFiles}
                        onSubmit={onSubmit}
                        accept={allowedFileExtensions}
                        submitButtonContent='Subir'
                        maxFiles={1}
                        maxSizeBytes={20000000}
                        inputContent={'Adjuntar documentación'}
                        inputWithFilesContent={(files) => `Agregar`}
                        submitButtonDisabled={(files: any) => files.length < 1}
                        styles={{
                          dropzone: { minHeight: '6vh', maxHeight: '5vh' },
                        }}
                      />
                    </Item>
                  </Form>
                </form>
              </div>
            </Popup>
          )}
        </div>
      ) : null}
    </div>
  )
}
