import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EnlaceModel')
class LinkModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('IdCompany', Number)
  IdCompany: number = 0;

  @JsonProperty('IdTipoEnlace', Number)
  IdTipoEnlace: number = 0;

  @JsonProperty('EnlaceValue', String)
  EnlaceValue: string = '';

  @JsonProperty('CodigoTipoEnlace', String)
  CodigoTipoEnlace: string = '';
}

export { LinkModel };