// import { addDetalleVehiculo } from 'src/pages/expedientePage/redux/vehiculo/vehiculoActions'
// import { getDetalleVehiculo } from 'src/pages/expedientePage/services/vehiculo/vehiculoService'
// import React, { useEffect, useMemo } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/combineReducers';
import GenericFormComponent from 'src/components/form/itemComponent';

export default function DetallesVehiculo(props: any) {
  const detalleExpedienteState = useSelector(
    (state: RootState) => state.vehiculos.detalleVehiculo,
  );
  return (
    <GenericFormComponent 
      fields={detalleExpedienteState} 
    />
  );
};
