import axiosInstance from 'src/utils/axios';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { logType, methodsEndPointType, customLog,
  checkStatusCode } from 'src/utils/sharedUitls';
import { UserModel } from 'src/models/usuario/userModel';
import { NotifyType, showToast } from 'src/utils/sharedUitls';
import { PerfilesCampoDatoModel } from 'src/models/perfilPage/perfilCampoDatoModel';
import { ProfileModel } from 'src/models/perfilPage/profileModel';

const serviceName = 'authService';

const _getProfiles = async (
  user: UserModel,
): Promise<ProfileModel[] | undefined> => {
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `perfil`;
    let ProfilesData: ProfileModel[] = [];
    let data;
    await axiosInstance.get(url).then((response: any) => {
      ProfilesData = jsonConvert.deserializeArray(response.data, ProfileModel);
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getProfiles.name,
        methodsEndPointType.GET,
      );
      showToast(
        'No se han podido obtener los perfiles.',
        NotifyType.error, 5000
      );
    });
    return ProfilesData;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  };
};

async function _getProfileGrid(
  user: UserModel,
) : Promise<PerfilesCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert();
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  let url = `Perfil/Grid`;
  let structure: PerfilesCampoDatoModel = new PerfilesCampoDatoModel();

  await axiosInstance.get(url).then((response: any) => {
    checkStatusCode(response.status)
    structure = jsonConvert.deserializeObject(
      response.data,
      PerfilesCampoDatoModel,
    )
  })
  .catch((error: any) => {
    customLog(
      null,
      logType.ERROR,
      serviceName,
      _getStructure.name,
      methodsEndPointType.GET,
    )
    return {}
  });
  return structure;
};

async function _getProfile(
  user: UserModel,
  id: number,
): Promise<ProfileModel> {
  let jsonConvert: JsonConvert = new JsonConvert()
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
  let url = `Perfil/${id}`

  let profile: ProfileModel = new ProfileModel()
  await axiosInstance
    .get(url)
    .then((response: any) => {
      checkStatusCode(response.status)
      profile = jsonConvert.deserializeObject(response.data, ProfileModel)
    })
    .catch((error: any) => {
      customLog(
        null,
        logType.ERROR,
        serviceName,
        _getProfile.name,
        methodsEndPointType.GET,
      )
      showToast('No se han podido obtener el perfil.', NotifyType.error, 5000)
    })
  return profile
}

async function _postProfile(
  sujeto: Partial<ProfileModel> | ProfileModel,
): Promise<boolean> {
  
  try {
    let jsonConvert: JsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    let url = `Perfil`;

    // Serializa el objeto sujeto antes de la solicitud POST
    const serializedSujeto = jsonConvert.serializeObject(sujeto);

    await axiosInstance.post(url, serializedSujeto).then((response: any) => {
      checkStatusCode(response.status)
      customLog(
        `sujeto creado ${response.data.Id}`,
        logType.INFO,
        serviceName,
        _putProfile.name,
        methodsEndPointType.POST,
      );
      showToast('Perfil creado', NotifyType.success, 5000);
    });
    return true;
  } catch (error: any) {
    customLog(
      `Error al crear Sujeto => ${error.message}`,
      logType.ERROR,
      serviceName,
      _postProfile.name,
      methodsEndPointType.POST,
    );
    return false;
  };
};

async function _putProfile(
  perfil: Partial<ProfileModel> | ProfileModel,
): Promise<boolean> {
  try {
    let jsonConvert: JsonConvert = new JsonConvert()
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL
    let url = `Perfil`

    const perfilBackend = {
      Id: perfil.Id,
      Descripcion: perfil.Descripcion,
      Nombre: perfil.Nombre,
      IdSujeto: perfil.IdSubjeto,
    };

    await axiosInstance.put(url, perfilBackend).then((response: any) => {
      checkStatusCode(response.status);
      customLog(
        `Perfil modificado ${perfil.Id}`,
        logType.INFO,
        serviceName,
        _putProfile.name,
        methodsEndPointType.PUT,
      )
      showToast(
        'Perfil editado', 
        NotifyType.success, 5000
      );
    })
    .catch((error: any) => {
      customLog(
        `Error al editar Perfil => ${error.message}`,
        logType.ERROR,
        serviceName,
        _putProfile.name,
        methodsEndPointType.PUT,
      );
    });
    return true;
  } catch (error) {
    return false;
  };
};

async function _getStructure(
  user: UserModel,
) : Promise<PerfilesCampoDatoModel> {
  let jsonConvert: JsonConvert = new JsonConvert();
  jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  let url = `Perfil/Structure`;
  let structure: PerfilesCampoDatoModel = new PerfilesCampoDatoModel();

  await axiosInstance.get(url).then((response: any) => {
    checkStatusCode(response.status);
    structure = jsonConvert.deserializeObject(
      response.data,
      PerfilesCampoDatoModel,
    );
  })
  .catch((error: any) => {
    customLog(
      null,
      logType.ERROR,
      serviceName,
      _getStructure.name,
      methodsEndPointType.GET,
    );
    return {};
  });
  return structure;
};

export { 
  _getProfiles,
  _getProfileGrid,
  _getProfile,
  _postProfile,
  _putProfile,
  _getStructure
};
