import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CompanyModel')
class CompanyModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Name', String)
  Name: string = '';

  @JsonProperty('Slogan', String)
  Slogan: string = '';

  @JsonProperty('IdentificationNumber', String)
  IdentificationNumber: string = '';

  @JsonProperty('ContactInformation', String)
  ContactInformation: string = '';

  @JsonProperty('PrincipalLogo', String)
  PrincipalLogo: string = '';

  @JsonProperty('SecondaryLogo', String)
  SecondaryLogo: string = '';

  @JsonProperty('NotificationsEmail', String)
  NotificationsEmail: string = '';

  @JsonProperty('Enabled', Boolean)
  Enabled: boolean = false;
};

@JsonObject('CompanyGridModel')
class CompanyGridModel {
  @JsonProperty('Id', Number)
  Id: number = 0;

  @JsonProperty('Name', String)
  Name: string = '';

  @JsonProperty('Slogan', String)
  Slogan: string = '';

  @JsonProperty('IdentificationNumber', String)
  IdentificationNumber: string = '';

  @JsonProperty('ContactInformation', String)
  ContactInformation: string = '';

  @JsonProperty('NotificationsEmail', String)
  NotificationsEmail: string = '';
};

export { 
  CompanyModel,
  CompanyGridModel
};
