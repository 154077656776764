import { CheckBox } from 'devextreme-react'
import { GroupItem, SimpleItem, StringLengthRule } from 'devextreme-react/form'
import React, { MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/combineReducers'
import { addVendedorRepresentante } from '../../redux/FormularioSolicitudes/actions/formularioCamposUtilActions'
import { useRecoilState } from 'recoil'
import { isCheckVendedorRepresentanteState } from 'src/recoil/sharesAtoms/atoms'

interface Props {
  ubicaciontab: MutableRefObject<string>;
  formref: any;
}
export default function DatosDireccionVendedorSection({
  ubicaciontab,
  formref,
}: Props) {
  const dispatch = useDispatch();
  const solicitud = useSelector(
    (state: RootState) => state.solicitudesList.solicitudList,
  );
  const tipovia = useSelector(
    (state: RootState) => state.formularioSolicitudDatosCamposUtil!.tipovias,
  );
  const [isCheckVendedorRepresentante, setIsCheckVendedorRepresentante] = useRecoilState(isCheckVendedorRepresentanteState);

  return (
    <GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Address.StreetType'}
          label={{ text: 'Tipo vía' }}
          editorType='dxSelectBox'
          editorOptions={{
            hint: 'Tipo Via comprador',
            onValueChanged: (e: any) => {
              if (e.value !== undefined && e.value  != null ) {
                solicitud!.Seller.Address.StreetType = e.value
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.StreetType'
            },
            dataSource: tipovia,
            displayExpr: 'Descripcion',
            valueExpr: 'Id',
            value: solicitud!.Seller.Address.StreetType,
            searchEnabled: true,
            searchMode: 'contains',
            searchExpr: 'Descripcion',
            minSearchLength: 0,
          }}
        ></SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.StreetName'}
          label={{ text: 'Nombre vía' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Nombre Via comprador',
            value:
              solicitud!.Seller.Address.StreetName !== ''
                ? solicitud!.Seller.Address.StreetName
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.StreetName = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.StreetName'
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del campo nombre de la vía no debe ser superior a 40'
            }
            max={40}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.Number'}
          label={{ text: 'Numero' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Numero comprador',
            value:
              solicitud!.Seller.Address.Number !== ''
                ? solicitud!.Seller.Address.Number
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Number = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Number'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo número no debe ser superior a 15'
            max={15}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Address.Stairs'}
          label={{ text: 'Escalera' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Escalera comprador',
            value:
              solicitud!.Seller.Address.Stairs !== ''
                ? solicitud!.Seller.Address.Stairs
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Stairs = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Stairs'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo escalera no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.Floor'}
          label={{ text: 'Planta' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Planta comprador',
            value:
              solicitud!.Seller.Address.Floor !== ''
                ? solicitud!.Seller.Address.Floor
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Floor = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.FullAddress'
            },
          }}
        ></SimpleItem>
        <SimpleItem
          dataField={'Seller.Address.Door'}
          label={{ text: 'Puerta' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Puerta comprador',
            value:
              solicitud!.Seller.Address.Door !== ''
                ? solicitud!.Seller.Address.Door
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Door = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Door'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo puerta no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Address.Portal'}
          label={{ text: 'Portal' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Portal comprador',
            value:
              solicitud!.Seller.Address.Portal !== ''
                ? solicitud!.Seller.Address.Portal
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Portal = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Door'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo portal no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.Letter'}
          label={{ text: 'Letra' }}
          editorType={'dxTextBox'}
          editorOptions={{
            hint: 'Letra comprador',
            value:
              solicitud!.Seller.Address.Letter !== ''
                ? solicitud!.Seller.Address.Letter
                : null,
            mode: 'text',
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Letter = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault();
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Letter'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo letra no debe ser superior a 2'
            max={2}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.ZipCode'}
          label={{ text: 'Cod.Postal' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Cod.Postal comprador',
            value:
              solicitud!.Seller.Address.ZipCode !== ''
                ? solicitud!.Seller.Address.ZipCode
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.ZipCode = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.ZipCode'
            },
          }}
        >
          <StringLengthRule
            message='La longitud del campo código postal no debe ser superior a 6'
            max={6}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={3}>
        <SimpleItem
          dataField={'Seller.Address.City'}
          label={{ text: 'Municipio' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Municipio comprador',
            value:
              solicitud!.Seller.Address.City !== ''
                ? solicitud!.Seller.Address.City
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.City = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.City'
            },
          }}
        >
          <StringLengthRule
            message={
              'La longitud del campo municipio no debe ser superior a 100'
            }
            max={100}
          ></StringLengthRule>
        </SimpleItem>

        <SimpleItem
          dataField={'Seller.Address.Km'}
          label={{ text: 'Km' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Km comprador',
            value:
              solicitud!.Seller.Address.Km !== ''
                ? solicitud!.Seller.Address.Km
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Km = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Km'
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo km no debe ser superior a 5'}
            max={5}
          ></StringLengthRule>
        </SimpleItem>
        <SimpleItem
          dataField={'Seller.Address.Hm'}
          label={{ text: 'Hm' }}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
            hint: 'Hm comprador',
            value:
              solicitud!.Seller.Address.Hm !== ''
                ? solicitud!.Seller.Address.Hm
                : null,
            onValueChanged: ({ value }: any) => {
              if (value !== undefined && value  != null ) {
                solicitud!.Seller.Address.Hm = value.trim();
                //dispatch(addSolicitudList(solicitud))
              }
            },
            onKeyPress: (e: any) => {
              if (e.event.key === 'Enter') {
                e.event.preventDefault()
              }
            },
            onFocusIn: (e: any) => {
              ubicaciontab.current = 'Seller.Address.Hm'
            },
          }}
        >
          <StringLengthRule
            message={'La longitud del campo hm no debe ser superior a 2'}
            max={2}
          ></StringLengthRule>
        </SimpleItem>
      </GroupItem>
      <GroupItem colCount={1}>
        <SimpleItem
          label={{ text: '¿Tiene representante?' }}
          cssClass={'GItem'}
          editorOptions={{ hint: 'Tiene Representante Comprador' }}
        >
          <CheckBox
            defaultValue={isCheckVendedorRepresentante}
            onValueChanged={(e) => {
              dispatch(addVendedorRepresentante(e.value))
              setIsCheckVendedorRepresentante(e.value)
            }}
          />
        </SimpleItem>
      </GroupItem>
    </GroupItem>
  );
};
